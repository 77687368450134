import { observable } from "mobx";
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  modelAction,
  prop,
  objectMap,
  ModelCreationData,
} from "mobx-keystone";

import MBOInfo from "../models/MBOInfo";

import * as api from "../services/api";
import Store from "./Store";

type Extra = { [key: string]: any } | null;
type Response = { errors: any; ok: boolean; extra: Extra };

const getError = (error: Error): Response => {
  try {
    return { errors: JSON.parse(error.message), ok: false, extra: null };
  } catch {
    return { errors: { detail: error.message }, ok: false, extra: null };
  }
};

const _success = { errors: { detail: null }, ok: true, extra: null };
const getSuccess = (extra?: Extra): Response =>
  extra ? { ..._success, extra } : _success;

@model("oppzo/MBOStore")
export default class MBOStore extends Model({
  mboInfo: prop(() => objectMap<MBOInfo>()),
}) {
  @observable
  loading = false;

  @modelAction
  createOrUpdateMBOInfo(data: ModelCreationData<MBOInfo>) {
    const id = `${data.id}`;
    if (this.mboInfo.has(id)) {
      this.mboInfo.get(id)!.update(data);
    } else {
      const condition = new MBOInfo(data);
      this.mboInfo.set(id, condition);
      condition.update(data);
    }
  }

  @modelAction
  removeMBOInfo(id: number) {
    this.mboInfo.delete(`${id}`);
  }

  @modelFlow
  addMBOInfo = _async(function* (this: MBOStore, data: MBOInfo) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<MBOInfo>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.addMBOInfo(token, data)));
    } catch (error: any) {
      console.warn("[DEBUG] error adding condition", error);
      return getError(error);
    }

    this.createOrUpdateMBOInfo(entities);

    this.loading = false;
    return getSuccess({ id: entities.id });
  });

  @modelFlow
  fetchMBOInfo = _async(function* (this: MBOStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let results: ModelCreationData<MBOInfo>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchMBOInfo(token)));
    } catch (error: any) {
      console.warn("[DEBUG] error fetching MBOInfo", error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateMBOInfo(data));

    this.mboInfo.forEach((loan) => {
      let flag = false;
      results.forEach((rloan) => {
        if (loan.id === rloan.id) {
          flag = true;
        }
      });
      if (!flag) {
        this.removeMBOInfo(loan.id);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateMBOInfo = _async(function* (
    this: MBOStore,
    id: number,
    data: Partial<MBOInfo>
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    const filesData = {};
    const nonFilesData = {};
    const fileFieldsNames = [
      "resumes",
      "personalFinancialStatements",
      "driversLicense",
    ];
    Object.keys(data).forEach((key: string) => {
      //@ts-ignore
      if (data[key] instanceof File) {
        //@ts-ignore
        filesData[key] = data[key];
      } else if (!fileFieldsNames.includes(key)) {
        //@ts-ignore
        nonFilesData[key] = data[key];
      }
    });

    let entities: ModelCreationData<MBOInfo>;
    if (Object.keys(nonFilesData).length > 0) {
      try {
        ({
          response: { entities },
        } = yield* _await(api.updateMBOInfo(token, id, nonFilesData)));
        this.createOrUpdateMBOInfo(entities);
      } catch (error: any) {
        console.warn("[DEBUG] error updating MBOInfo", error);
        return getError(error);
      }
    }
    if (Object.keys(filesData).length > 0) {
      try {
        ({
          response: { entities },
        } = yield* _await(api.updateMBOInfoFiles(token, id, filesData)));
        this.createOrUpdateMBOInfo(entities);
      } catch (error: any) {
        console.warn("[DEBUG] error updating MBOInfo", error);
        return getError(error);
      }
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteMBOInfo = _async(function* (this: MBOStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let response;
    try {
      response = yield* _await(api.deleteMBOInfo(token, id));
    } catch (error: any) {
      console.warn("[DEBUG] error deleting MBOInfo", error);
      return getError(error);
    }

    if (!("errors" in response)) {
      this.removeMBOInfo(id);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  getUnsigned8821Form = _async(function* (this: MBOStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<MBOInfo>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.getUnsigned8821FormForMBO(token, id)));
    } catch (error: any) {
      console.warn("[DEBUG] error getting 8821 form", error);
      return getError(error);
    }

    this.createOrUpdateMBOInfo(entities);

    this.loading = false;
    return getSuccess({
      unsigned8821Form: entities.unsigned8821Form,
    });
  });
}
