import {
  Text,
  Input,
  useStyleSheet,
  Divider,
  Icon,
  Tooltip,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { Animated, TouchableOpacity, View } from "react-native";

import { PullReportButton } from "../../containers/UnderwriterPortalCollateral/PullReportButton";
import { formatNumberWithCommas } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const TableDisplay: React.FC<any> = ({
  data,
  style,
  itemsPerRow,
  isEditing,
  rawData,
  setRawData,
  horizontalDividerBetweenRows = false,
  hideHeader = false,
  hideValues = false,
  valuesNotBold = false,
  itemStyle = {},
}) => {
  const styles = useStyleSheet(themedStyles);
  const [showInfo, setShowInfo] = useState(false);

  const InfoIcon = (props: any) => <Icon {...props} name="info-outline" />;

  const handleTooltipToggle = (tooltipKey: any) => {
    const newTooltipStatus = {
      ...tooltipsShowStatus,
    };
    newTooltipStatus[tooltipKey] = !newTooltipStatus[tooltipKey];
    setTooltipsShowStatus(newTooltipStatus);
  };

  const [tooltipsShowStatus, setTooltipsShowStatus] = useState<any>({});
  const [selectedPropIndex, setSelectedPropIndex] = useState<any>(null);
  const [value, setValue] = useState("");

  const dataToDisplay = data.filter((item: any) => !item.notDisplayed);

  const dataRows = [];
  while (dataRows.length * itemsPerRow < dataToDisplay.length) {
    dataRows.push(dataRows.length);
  }

  while (dataToDisplay.length < dataRows.length * itemsPerRow) {
    dataToDisplay.push({ header: "", displayValue: "" });
  }

  return (
    <Animated.View style={style}>
      {dataRows.map((start) => (
        <View key={"data-" + start}>
          {!hideHeader ? (
            <View style={styles.dataRow}>
              {dataToDisplay
                .slice(itemsPerRow * start, itemsPerRow * start + itemsPerRow)
                .map(
                  (prop: any, idx: number) =>
                    !prop.notDisplayed && (
                      <View
                        style={{
                          ...styles.cell,
                          ...("hoverInfo" in prop ? { zIndex: 1 } : {}),
                        }}
                        key={prop.header + "-value-" + idx}
                      >
                        <Text style={{ ...styles.headerCell, ...itemStyle }}>
                          {prop.header}{" "}
                          {!!prop.isOptional && (
                            <Tooltip
                              anchor={() => (
                                <TouchableOpacity
                                  onPress={() =>
                                    handleTooltipToggle(
                                      prop.header + "-value-" + idx
                                    )
                                  }
                                >
                                  <Icon
                                    style={styles.infoIcon}
                                    name="question-mark-circle-outline"
                                  />
                                </TouchableOpacity>
                              )}
                              visible={
                                tooltipsShowStatus[
                                  prop.header + "-value-" + idx
                                ]
                              }
                              onBackdropPress={() =>
                                handleTooltipToggle(
                                  prop.header + "-value-" + idx
                                )
                              }
                              children={() => (
                                <Text style={styles.tooltipTextLight}>
                                  Optional field for applicant
                                </Text>
                              )}
                            />
                          )}
                          {!!prop.infoTooltip && (
                            <Tooltip
                              anchor={() => (
                                <TouchableOpacity
                                  onPress={() =>
                                    handleTooltipToggle(
                                      prop.header + "-value-" + idx
                                    )
                                  }
                                >
                                  <Icon
                                    style={styles.infoIcon}
                                    name="info-outline"
                                  />
                                </TouchableOpacity>
                              )}
                              visible={
                                tooltipsShowStatus[
                                  prop.header + "-value-" + idx
                                ]
                              }
                              onBackdropPress={() =>
                                handleTooltipToggle(
                                  prop.header + "-value-" + idx
                                )
                              }
                              children={() => (
                                <Text style={styles.tooltipTextLight}>
                                  {prop.infoTooltip}
                                </Text>
                              )}
                            />
                          )}
                          {"hoverInfo" in prop && (
                            <TouchableOpacity
                              //@ts-ignore
                              onMouseEnter={() => setShowInfo(true)}
                              onMouseLeave={() => setShowInfo(false)}
                            >
                              <Icon
                                style={styles.infoIcon}
                                name={prop.iconName}
                              />
                            </TouchableOpacity>
                          )}
                          {"hoverInfo" in prop && showInfo && (
                            <Text style={styles.hoverHelpText}>
                              {prop.hoverInfo}
                            </Text>
                          )}
                          {prop.accessoryRight && prop.accessoryRight}
                        </Text>
                      </View>
                    )
                )}
            </View>
          ) : (
            <></>
          )}
          {!hideValues ? (
            <View
              style={{
                ...styles.dataRow,
                ...(showInfo ? { zIndex: -1 } : {}),
              }}
            >
              {dataToDisplay
                .slice(itemsPerRow * start, itemsPerRow * start + itemsPerRow)
                .map((prop: any, idx: number) => (
                  <View style={styles.cell} key={prop.header + "-value-" + idx}>
                    {(!isEditing || !prop.editable) && !prop.isEditableLink ? (
                      <>
                        {prop.isTextBlock ? (
                          <Input
                            style={styles.flagDescription}
                            textStyle={styles.flagDescriptionText}
                            numberOfLines={2}
                            value={prop.displayValue}
                            multiline
                            disabled
                          />
                        ) : (
                          <Text
                            style={{
                              ...(!valuesNotBold
                                ? styles.cell
                                : styles.notBoldCell),
                              ...styles.textCell,
                              ...itemStyle,
                            }}
                          >
                            {prop.displayValue && prop.prefix === "$" ? (
                              prop?.displayValue !== "?" ? (
                                new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(prop?.displayValue)
                              ) : (
                                "?"
                              )
                            ) : prop.displayValue &&
                              prop.displayValue !== "?" ? (
                              <>
                                {prop.prefix || ""}
                                {prop.isDate
                                  ? new Date(
                                      prop.displayValue
                                    ).toLocaleDateString("LT")
                                  : prop.displayValue}
                                {[" Months", " Years", "Days"].includes(
                                  prop.suffix
                                ) && `${prop.displayValue}` === "1"
                                  ? prop.suffix.substr(
                                      0,
                                      prop.suffix.length - 1
                                    )
                                  : prop.suffix || ""}
                              </>
                            ) : (
                              prop.displayValue
                            )}
                          </Text>
                        )}
                        {horizontalDividerBetweenRows &&
                        itemsPerRow * start + itemsPerRow < data.length ? (
                          <Divider style={styles.betweenCellsDivider} />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : prop.isDropdown ? (
                      <Select
                        style={styles.cellInput}
                        multiSelect={
                          prop.isMultiSelect == undefined
                            ? true
                            : prop.isMultiSelect
                        }
                        placeholder={prop.placeholder}
                        value={value}
                        selectedIndex={selectedPropIndex}
                        onSelect={(index) => {
                          setSelectedPropIndex(index);
                          // @ts-ignore
                          if (index.length == undefined) {
                            setValue(prop.options[index.row]);
                            setRawData({
                              ...rawData,
                              lender: prop.options[index.row],
                            });
                          } else {
                            const items = index.map((item: any) => {
                              return prop.options[item.row];
                            });
                            setValue(items.join(","));
                            const newRawData = { ...rawData };
                            newRawData[prop.fieldNameInResponse] =
                              items.join(",");
                            setRawData(newRawData);
                          }
                        }}
                      >
                        {prop.options.map((option: string, idx: number) => (
                          <SelectItem
                            style={styles.selectItem}
                            key={`${idx}-${prop.placeholder}-option`}
                            accessoryRight={InfoIcon}
                            title={() => (
                              <Text style={styles.selectItemText}>
                                {option}
                              </Text>
                            )}
                          />
                        ))}
                      </Select>
                    ) : prop.isEditableLink && !isEditing ? ( //only applies to business website (for now)
                      <PullReportButton
                        uri={
                          prop.displayValue.indexOf("https://") >= 0 ||
                          prop.displayValue.indexOf("http://") >= 0
                            ? prop.displayValue
                            : `https://${prop.displayValue}`
                        }
                        isLink
                        text="View Business Website"
                      />
                    ) : (
                      <Input
                        style={styles.cell}
                        textStyle={styles.cellInputText}
                        value={
                          prop.prefix === "$"
                            ? formatNumberWithCommas(
                                rawData[prop.fieldNameInResponse]
                              )
                            : rawData[prop.fieldNameInResponse]
                        }
                        onChangeText={(text) => {
                          const number = text.replace(/,/g, "");
                          if (
                            (prop.numeric || prop.prefix === "$") &&
                            (isNaN(Number(number)) ||
                              (prop.maxValue && Number(number) > prop.maxValue))
                          )
                            return;
                          const newRawData = { ...rawData };
                          if (prop.prefix === "$")
                            newRawData[prop.fieldNameInResponse] = number;
                          else newRawData[prop.fieldNameInResponse] = text;
                          setRawData(newRawData);
                        }}
                      />
                    )}
                  </View>
                ))}
            </View>
          ) : (
            <></>
          )}
        </View>
      ))}
    </Animated.View>
  );
};
