import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  //header: {
  heading1: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  //subHeader: {
  heading3: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginTop: 30,
    marginBottom: 15,
  },
  privacyPolicy: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
  },
  privacyPolicyContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  //bold: {
  strong: {
    fontWeight: "bold",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 15,
  },
  //underline: {
  em: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 15,
    textDecorationLine: "underline",
    fontStyle: "normal",
  },
  paragraph: {
    fontFamily: "Montserrat_400Regular",
    color: "white",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "justify",
  },
  linkButton: {
    backgroundColor: "none",
  },
  link: {
    color: "#26D4F3",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 15,
    textIndent: 0,
  },
});
