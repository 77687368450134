import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/core";
import {
  Button,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  ThemeProvider,
  Modal,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

// @ts-ignore
import { WebView as WebViewWeb } from "react-native-web-webview";

import Header from "../../components/Header";
import config from "../../config";
import {
  getClosingDocumentsData,
  getDocuSignAppKeys,
  retrieveSignedDocument,
} from "../../services/api/docusign";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import AuthStore from "../../stores/AuthStore";
import { NewRequestForm } from "../AdminRequestCenter/NewRequestForm";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const ClosingDocuments: React.FC<any> = ({ route }) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [noRequests, setNoRequests] = useState(false);
  const [integrationKey, setIntegrationKey] = useState("");
  const [applicationId, setApplicationId] = useState(-1);
  const [lendingDocStatus, setLendingDocStatus] = useState("");
  const [isLoanTerms, setIsLoanTerms] = useState(false);
  const [openRetrieveModal, setOpenRetrieveModal] = useState(false);
  const [openExpiredModal, setOpenExpiredModal] = useState(false);
  const [openAfterSignModal, setOpenAfterSignModal] = useState(false);
  const [docusignApiAuthUrl, setDocusignApiAuthUrl] = useState(
    "https://account.docusign.com"
  );
  const [frontendUrl, setFrontendUrl] = useState("");

  const [openLoanTermsModal, setOpenLoanTermsModal] = useState(false);
  const [openLendingDocModal, setOpenLendingDocModal] = useState(false);

  const { authStore, documentRequestsStore, loanStore } = useStore();

  const [closingDisplayList, setClosingDisplayList] = useState([]);

  const BackIcon = (props: any) => (
    <Icon {...props} name="arrow-back-outline" />
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (!res1.response.entities) {
        navigation.navigate("dashboard");
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (!res2.response.entities) {
        navigation.navigate("dashboard");
      }

      const resp = await getClosingDocumentsData(authStore?.accessToken || "");
      if (resp.response.entities) {
        setClosingDisplayList(resp.response.entities);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // await scanTemplateIds();
      // const temp = await getSentDocuSignTemplateId(authStore?.accessToken || "", {applicationId: applicationId});
      // if (temp.response.entities) {
      //   setCurrentSentTemplateId(temp.response.entities["templateId"]);
      // }
      const resp = await getDocuSignAppKeys(authStore?.accessToken || "");
      console.log(resp);
      if (resp.response.entities) {
        setIntegrationKey(resp.response.entities["integrationKey"]);
        setDocusignApiAuthUrl(resp.response.entities["docusignApiAuthUrl"]);
        setFrontendUrl(resp.response.entities["frontendUrl"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!openLendingDocModal) {
        const resp = await getClosingDocumentsData(
          authStore?.accessToken || ""
        );
        if (resp.response.entities) {
          setClosingDisplayList(resp.response.entities);
        }
      }
    })();
  }, [openLendingDocModal]);

  useEffect(() => {
    if (selectedItem !== -1) {
      setApplicationId(closingDisplayList[selectedItem]["id"]);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (openLoanTermsModal) {
      const url = `${frontendUrl}/user-loan-terms-modal/?state=${applicationId}`;
      WebBrowser.openBrowserAsync(url);
      setOpenLoanTermsModal(false);
    }
  }, [openLoanTermsModal]);

  useEffect(() => {
    if (openLendingDocModal) {
      const url = `${frontendUrl}/lending-document-modal/?state=${applicationId}`;
      WebBrowser.openBrowserAsync(url);
      setOpenLendingDocModal(false);
    }
  }, [openLendingDocModal]);

  useEffect(() => {
    if (openRetrieveModal) {
      (async () => {
        const resp = await retrieveSignedDocument(
          authStore?.accessToken || "",
          { applicationId, isLoanTerms }
        );
        if (resp.response.entities["fileUri"]) {
          window.open(
            config.urls.api.replace("/api/", "") +
              resp.response.entities["fileUri"],
            "_blank"
          );
        }
        setOpenRetrieveModal(false);
      })();
    }
  }, [openRetrieveModal]);

  const SidebarItem = (item: any, index: number) => {
    return (
      <TouchableOpacity
        key={index}
        style={{
          ...styles.sidebarItemContainer,
          ...(authStore.user?.isUnderwriter || authStore.user?.isStaff
            ? {
                backgroundColor:
                  index === selectedItem
                    ? "color-basic-200"
                    : "color-basic-100",
              }
            : {
                backgroundColor: index === selectedItem ? "#101426" : "#192038",
              }),
        }}
        onPress={() => setSelectedItem(index)}
      >
        <Text style={styles.sidebarItemHeader}>{`Loan ID ${item.id}`}</Text>
        <Text style={styles.sidebarItemDate}>{item.date}</Text>
      </TouchableOpacity>
    );
  };

  const ContentItem = (item: any, index: number) => {
    return (
      <View
        key={index}
        style={
          width > 768
            ? styles.contentItemContainer
            : styles.contentItemContainerMobile
        }
      >
        <View
          style={{
            ...styles.flexRow,
            justifyContent: "flex-start",
            marginBottom: 20,
          }}
        >
          <Button
            style={styles.backButton}
            accessoryLeft={BackIcon}
            onPress={() => setSelectedItem(-1)}
          />
        </View>
        <ScrollView style={styles.scrollView}>
          <Text style={styles.contentItemHeader}>{`Loan ID ${item.id}`}</Text>
          <Text style={styles.contentItemDate}>{}</Text>
          <View style={styles.dividerWide} />
          <View
            style={{
              ...(width > 768 ? { marginHorizontal: 34 } : {}),
            }}
          >
            <Text style={styles.messageHeader}>{`Hello ${item.name},`}</Text>
            <Text style={styles.messageText}>
              Read the documents and accept by signing in Docusign. If you have
              any questions then please submit your request through the request
              center.
            </Text>
          </View>
          <View style={styles.buttonContainer}>
            {item.loanTermsSigned ? (
              <Button
                style={{
                  ...styles.button,
                  ...{
                    background:
                      "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
                    border: "none",
                  },
                }}
                onPress={() => {
                  setIsLoanTerms(true);
                  setOpenRetrieveModal(true);
                }}
              >
                <Text
                  style={{
                    ...{
                      color: "white",
                    },
                    textAlign: "left",
                  }}
                >
                  VIEW SIGNED LOAN TERMS
                </Text>
              </Button>
            ) : (
              <Button
                style={{
                  ...styles.button,
                  ...(item.loanTermsAvailable &&
                  authStore.user?.isPrimaryApplicant
                    ? {
                        background:
                          "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
                        border: "none",
                      }
                    : {
                        backgroundColor: "rgba(0,0,0,0.2)",
                        border: 1,
                        borderColor: "#26DDF1",
                      }),
                }}
                onPress={() => {
                  if (
                    item.loanTermsAvailable &&
                    authStore.user?.isPrimaryApplicant
                  )
                    setOpenLoanTermsModal(true);
                }}
              >
                <Text
                  style={{
                    ...(item.loanTermsAvailable &&
                    authStore.user?.isPrimaryApplicant
                      ? {
                          color: "white",
                        }
                      : {
                          color: "#26DDF1",
                        }),
                    textAlign: "left",
                  }}
                >
                  {`VIEW LOAN TERMS${
                    !authStore.user?.isPrimaryApplicant
                      ? " (FOR PRIMARY APPLICANT ONLY)"
                      : !item.loanTermsAvailable
                      ? " (NOT AVAILABLE)"
                      : ""
                  }`}
                </Text>
              </Button>
            )}
          </View>
          <View style={styles.buttonContainer}>
            {item.lendingDocSigned ? (
              <Button
                style={{
                  ...styles.button,
                  ...{
                    background:
                      "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
                    border: "none",
                  },
                }}
                onPress={() => {
                  setIsLoanTerms(false);
                  item.lendingDocComplete
                    ? setOpenRetrieveModal(true)
                    : setOpenAfterSignModal(true);
                }}
              >
                <Text
                  style={{
                    ...{
                      color: "white",
                    },
                    textAlign: "left",
                  }}
                >
                  VIEW SIGNED DOCUMENT
                </Text>
              </Button>
            ) : (
              <Button
                style={{
                  ...styles.button,
                  ...(item.lendingDocAvailable &&
                  authStore.user?.isPrimaryApplicant
                    ? {
                        background:
                          "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
                        border: "none",
                      }
                    : {
                        backgroundColor: "rgba(0,0,0,0.2)",
                        border: 1,
                        borderColor: "#26DDF1",
                      }),
                }}
                onPress={() => {
                  if (item.lendingDocExpired) setOpenExpiredModal(true);
                  else if (
                    item.lendingDocAvailable &&
                    authStore.user?.isPrimaryApplicant
                  )
                    setOpenLendingDocModal(true);
                }}
              >
                <Text
                  style={{
                    ...(item.lendingDocAvailable &&
                    authStore.user?.isPrimaryApplicant
                      ? {
                          color: "white",
                        }
                      : {
                          color: "#26DDF1",
                        }),
                    textAlign: "left",
                  }}
                >
                  {`VIEW FULL LENDING DOCUMENT${
                    !authStore.user?.isPrimaryApplicant
                      ? " (FOR PRIMARY APPLICANT ONLY)"
                      : !item.lendingDocAvailable
                      ? " (NOT AVAILABLE)"
                      : item.lendingDocExpired
                      ? " (EXPIRED)"
                      : ""
                  }`}
                </Text>
              </Button>
            )}
          </View>
        </ScrollView>
      </View>
    );
  };

  return (
    <ThemeProvider
      theme={
        authStore.user?.isStaff || authStore.user?.isUnderwriter
          ? eva.light
          : eva.dark
      }
    >
      <Layout style={styles.container}>
        <Header
          theme={
            authStore.user?.isStaff || authStore.user?.isUnderwriter
              ? "light"
              : "dark"
          }
        />
        {/* <Modal
          visible={openLendingDocModal}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => {
            setOpenLendingDocModal(false);
          }}
          style={styles.modal}
        >
          <WebViewWeb
            scrollEnabled
            source={{
              uri: `${docusignApiAuthUrl}/oauth/auth?response_type=code&scope=signature&client_id=${integrationKey}&state=${applicationId}&redirect_uri=${frontendUrl}/lending-document-modal`,
            }}
          />
        </Modal> */}
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Text style={width > 768 ? styles.header : styles.headerMobile}>
            Closing Documents
          </Text>
          <LinearGradient
            style={styles.gradientBar}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={["#D012B2", "#8A1CDB", "#5327FF", "#1D84FF", "#26DDF1"]}
          />
          <View style={styles.adminDashboardContainer}>
            <View
              style={
                width > 768
                  ? styles.adminDashBoard
                  : styles.adminDashBoardMobile
              }
            >
              <View
                style={
                  width > 768
                    ? styles.requestedDocumentsContainer
                    : styles.requestedDocumentsContainerMobile
                }
              >
                {(width > 768 || (width <= 768 && selectedItem === -1)) && (
                  <View
                    style={
                      width > 768
                        ? styles.requestedDocumentsSidebar
                        : styles.requestedDocumentsSidebarMobile
                    }
                  >
                    {closingDisplayList.length > 0 ? (
                      closingDisplayList.map((item: any, index: number) =>
                        SidebarItem(item, index)
                      )
                    ) : loading ? (
                      <LoadingIndicator />
                    ) : (
                      <TouchableOpacity
                        style={{
                          ...styles.sidebarItemContainer,
                        }}
                      >
                        <Text style={styles.sidebarItemHeader}>
                          No loan applications created yet.
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                {!noRequests &&
                  (width > 768 || (width <= 768 && selectedItem > -1)) && (
                    <View
                      style={
                        width > 768
                          ? styles.requestedDocumentContent
                          : styles.requestedDocumentContentMobile
                      }
                    >
                      {selectedItem > -1 &&
                        ContentItem(
                          closingDisplayList[selectedItem],
                          selectedItem
                        )}
                    </View>
                  )}
              </View>
            </View>
          </View>
        </ScrollView>
        <Modal
          visible={openExpiredModal}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => {
            setOpenExpiredModal(false);
          }}
          style={styles.modal}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>
              The document has expired. Please contact OppZo support to proceed.
            </Text>
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => setOpenExpiredModal(false)}
              >
                <Text style={styles.modalButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
        <Modal
          visible={openAfterSignModal}
          backdropStyle={styles.backdrop}
          onBackdropPress={() => {
            setOpenAfterSignModal(false);
          }}
          style={styles.modal}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>
              You have already signed the document. The document will be
              available for viewing once the CLO has counter-signed the
              document.
            </Text>
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => setOpenAfterSignModal(false)}
              >
                <Text style={styles.modalButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </Layout>
    </ThemeProvider>
  );
};

export default ClosingDocuments;
