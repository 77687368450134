import { useLinkTo } from "@react-navigation/native";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
} from "@ui-kitten/components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { ContactUsPopup } from "../../components/ContactUs";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

type Props = object;

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const LogIn: React.FC<Props> = (props) => {
  const linkTo = useLinkTo();
  const isReferralPartnerLogin =
    window.location.pathname.split("/")[1] === "referral";
  const defaultRoute = isReferralPartnerLogin ? "referral" : "auth";

  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const LoginImage = require("../../../assets/LoginImage.png");

  const [loading, setLoading] = useState(false);
  const defaultFields = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState({ ...defaultFields });
  const [errorMessage, setErrorMessage] = useState("");
  const [securedText, setSecuredText] = useState(true);
  const passwordRef = useRef<Input>(null);

  const { authStore } = useStore();

  const { register, setValue, handleSubmit, control } = useForm();
  const { errors } = useFormState({ control });

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LockIcon = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText(!securedText)}>
      <Icon {...props} name={securedText ? "lock-outline" : "unlock-outline"} />
    </TouchableOpacity>
  );
  const MailIcon = (props: any) => <Icon {...props} name="email-outline" />;

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setErrorMessage("");
      const result = await authStore.logIn(data);
      if (!result.ok) {
        setLoading(false);
        setErrorMessage(
          result?.errors?.nonFieldErrors?.length
            ? result.errors.nonFieldErrors[0] ===
              "Unable to log in with provided credentials."
              ? "Unable to log in with provided credentials. Please check username or password."
              : result.errors.nonFieldErrors[0]
            : "Sorry, there was an unexpected error. Please try again later."
        );
      }
    },
    [loading]
  );

  const isNextActionButtonDisabled = useCallback(() => {
    return !formData.email || !formData.password;
  }, [formData]);

  useEffect(() => {
    register("email", {
      required: "E-mail is required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid E-mail address",
      },
    });
    register("password", { required: "Password is required" });
  }, [register]);

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onSubmitEditingEmail = useCallback(() => {
    passwordRef.current?.focus();
  }, []);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View
          style={
            width > 992 ? styles.logInContainer : styles.logInContainerMobile
          }
        >
          <Image
            style={
              width > 992
                ? { width: 517, height: 809 }
                : { width: "100%", height: width * 0.8077 }
            }
            source={LoginImage}
          />
          <View style={width > 992 ? styles.logIn : styles.logInMobile}>
            <Text style={styles.header}>Sign In</Text>
            <Text style={styles.subHeader}>
              Don't have an account yet?{" "}
              <Text
                status="info"
                style={styles.link}
                onPress={() => linkTo(`/${defaultRoute}/register`)}
              >
                Sign Up
              </Text>
              .
            </Text>
            {!!errorMessage && (
              <Text category="h6" style={styles.error}>
                {errorMessage}
              </Text>
            )}
            <Input
              style={styles.input}
              textStyle={styles.inputText}
              placeholder="Work email address"
              autoCorrect={false}
              autoCapitalize="none"
              autoCompleteType="email"
              textContentType="emailAddress"
              keyboardType="email-address"
              accessoryRight={MailIcon}
              onChangeText={(text) => {
                setValue("email", text);
                setFormData({ ...formData, email: text });
              }}
              onSubmitEditing={onSubmitEditingEmail}
              onKeyPress={(event: any) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  onSubmitEditingEmail();
                }
              }}
              returnKeyType="next"
              status={errors.email ? "danger" : "basic"}
              caption={errors.email && errors.email.message}
              testID="emailField"
            />
            <Input
              ref={passwordRef}
              style={styles.input}
              placeholder="Password"
              autoCapitalize="none"
              autoCompleteType="password"
              textContentType="password"
              autoCorrect={false}
              secureTextEntry={securedText}
              accessoryRight={LockIcon}
              onChangeText={(text) => {
                setValue("password", text);
                setFormData({ ...formData, password: text });
              }}
              onSubmitEditing={onSubmitWrapped}
              returnKeyType="go"
              status={errors.password ? "danger" : "basic"}
              caption={errors.password && errors.password.message}
              testID="passwordField"
            />

            <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
              <Text
                status="info"
                style={styles.forgotPasswordText}
                onPress={() => linkTo(`/${defaultRoute}/password-reset`)}
              >
                Forgot password?{"  "}
              </Text>
            </View>

            <Button
              disabled={isNextActionButtonDisabled()}
              style={
                isNextActionButtonDisabled()
                  ? styles.disabledActionButton
                  : styles.logInButton
              }
              status="basic"
              onPress={onSubmitWrapped}
              accessoryRight={loading ? LoadingIndicator : ArrowIcon}
              children={() => (
                <Text
                  style={
                    isNextActionButtonDisabled()
                      ? styles.disabledActionButtonText
                      : styles.logInButtonText
                  }
                >
                  sign in{"  "}
                </Text>
              )}
            />
          </View>
        </View>
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default LogIn;
