import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Spinner,
  Modal,
  Input,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import React, { useRef, useState, useEffect } from "react";
import { Dimensions, View } from "react-native";

import { ConfirmPrompt } from "../../components/ConfirmPrompt";
import { useStore } from "../../stores";
import { managementExperienceOptions } from "../../utils/constants";
import { uploadDocument } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const BusinessPrincipalStep: React.FC<any> = ({
  updateLoanData,
  goToNextStep,
  loan,
  hasEmptyRequiredFields = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-forward-outline" />
  );
  const LinkedInIcon = (props: any) => (
    <Icon {...props} name="linkedin" fill="white" />
  );
  const PlusIcon = (props: any) => (
    <Icon {...props} name="plus-circle-outline" />
  );

  const { authStore } = useStore();

  const [formData, setFormData] = useState<any>({
    linkedIn: loan.linkedIn || "",
    resumes: loan.resumes || "",
    driversLicense: loan.driversLicense || "",
    financialStatements: loan.financialStatements || "",
    legalActions: loan.legalActions || "",
    managementExperience: authStore?.user?.managementExperience || "",
  });

  const [
    selectedManagementExperienceIndex,
    setSelectedManagementExperienceIndex,
  ] = useState<any>(null);

  useEffect(() => {
    const newFormData = { ...formData };
    Object.keys(formData).forEach(
      (key) => (newFormData[key] = loan[key] || formData[key])
    );
    setFormData(newFormData);
  }, [loan]);

  useEffect(() => {
    if (selectedManagementExperienceIndex)
      setFormData({
        ...formData,
        managementExperience:
          managementExperienceOptions[selectedManagementExperienceIndex?.row] ||
          "",
      });
  }, [selectedManagementExperienceIndex]);

  const [openModal, setOpenModal] = useState<any>();

  const legalActionRef = useRef<Input>(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>("");

  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="tiny" />
  );

  const [isConfirmPromptOpen, setIsConfirmPromptOpen] = useState(false);

  const allRequiredFieldFilled = () =>
    (formData.linkedIn || formData.resumes) && formData.driversLicense;

  const clickSave = () => {
    if (!allRequiredFieldFilled()) setIsConfirmPromptOpen(true);
    else saveData();
  };

  const saveData = async () => {
    setLoading(true);
    const res = await updateLoanData(formData);
    setLoading(false);
    console.log(res);
    if (res?.ok) goToNextStep(allRequiredFieldFilled());
    else setErrors(res?.errors);
  };

  return (
    <View>
      <ConfirmPrompt
        isOpen={isConfirmPromptOpen}
        close={() => setIsConfirmPromptOpen(false)}
        text="Are you sure? You still have some mandatory fields remaining which you will need to fill"
        cb={() => saveData()}
      />
      {/* <View style={styles.discountInfoContainer}>
        <View style={styles.discountInfoIconContainer}>
          <Icon style={styles.discountInfoIcon} name="info" />
        </View>
        <Text style={styles.discountInfoText}>
          Any additional information provided, may lead to a discount on the
          loan.
        </Text>
      </View> */}

      <Text category="h6" style={styles.error}>
        {errors ? JSON.stringify(errors) : ""}
      </Text>

      <Modal
        visible={openModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setOpenModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.inputLabel}>Link LinkedIn</Text>
          <Input
            style={styles.input}
            textStyle={styles.inputText}
            placeholder="LinkedIn Profile URL"
            status="basic"
            value={formData.linkedIn}
            onChangeText={(text) =>
              setFormData({ ...formData, linkedIn: text })
            }
            accessoryRight={LinkedInIcon}
          />

          <Text style={styles.inputLabel}>or Upload Resumes</Text>
          <Button
            style={
              formData.resumes
                ? styles.uploadButtonSuccess
                : styles.uploadButton
            }
            status={formData.resumes ? "success" : "basic"}
            appearance="outline"
            accessoryRight={UploadIcon}
            onPress={() =>
              uploadDocument((doc: any) => {
                if (doc.size < 10485760) {
                  setFormData({
                    ...formData,
                    resumes: doc.file,
                  });
                  setErrors("");
                } else {
                  setErrors("File too large. Upload limit: 10MB.");
                }
              })
            }
            children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
          />
        </View>
      </Modal>

      <Text style={styles.inputLabel}>
        Resumes of principals, or summary of management's experience
      </Text>
      <Button
        style={
          formData.linkedIn || formData.resumes
            ? styles.uploadButtonSuccess
            : hasEmptyRequiredFields
            ? styles.uploadButtonEmpty
            : styles.uploadButton
        }
        status={
          formData.linkedIn || formData.resumes
            ? "success"
            : hasEmptyRequiredFields
            ? "danger"
            : "basic"
        }
        appearance="outline"
        accessoryRight={PlusIcon}
        onPress={() => setOpenModal(true)}
        children={() => (
          <Text style={styles.uploadButtonText}>
            Upload Resume or Link LinkedIn Account
          </Text>
        )}
      />

      <Text style={styles.inputLabel}>Years of management experience</Text>
      <Select
        status="primary"
        size={width > 576 ? "medium" : "small"}
        style={styles.input}
        placeholder="Years of management experience"
        value={formData.managementExperience}
        selectedIndex={selectedManagementExperienceIndex}
        onSelect={(index) => setSelectedManagementExperienceIndex(index)}
      >
        {managementExperienceOptions.map((option, idx) => (
          <SelectItem title={option} key={`${idx}-business-role-option`} />
        ))}
      </Select>

      <Text style={styles.inputLabel}>Upload Valid Driver's License</Text>
      <Button
        style={
          formData.driversLicense
            ? styles.uploadButtonSuccess
            : hasEmptyRequiredFields
            ? styles.uploadButtonEmpty
            : styles.uploadButton
        }
        status={
          formData.driversLicense
            ? "success"
            : hasEmptyRequiredFields
            ? "danger"
            : "basic"
        }
        appearance="outline"
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                driversLicense: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />

      <Text style={styles.inputLabel}>
        Personal Financial Statements <Text appearance="hint">(Optional)</Text>
      </Text>
      <Button
        style={
          formData.financialStatements
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.financialStatements ? "success" : "basic"}
        appearance="outline"
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                financialStatements: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />

      <View>
        <Text style={styles.inputLabel}>
          Please provide a list of adverse legal action (past or pending) faced
          by you or your business. <Text appearance="hint">(Optional)</Text>
        </Text>
        <Input
          ref={legalActionRef}
          multiline
          textStyle={{ minHeight: 256, color: "white" }}
          style={styles.input}
          placeholder="Input Text"
          status="basic"
          value={formData.legalActions}
          onChangeText={(text) =>
            setFormData({ ...formData, legalActions: text })
          }
        />
      </View>

      <View style={width > 768 && styles.flexRow}>
        <Button
          style={
            loading ? styles.disabledActionButton : styles.nextActionButton
          }
          onPress={() => clickSave()}
          disabled={loading}
          accessoryRight={loading ? LoadingIndicator : ArrowIcon}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Save and Continue{loading ? "  " : ""}
            </Text>
          )}
        />
        <Button
          style={loading ? styles.disabledActionButton : styles.skipButton}
          status="basic"
          disabled={loading}
          onPress={() => {
            goToNextStep();
          }}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Skip for now
            </Text>
          )}
        />
      </View>
    </View>
  );
};
