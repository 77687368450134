import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },
  gradientButton: {
    height: 10,
    margin: 3,
    padding: 3,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    marginVertical: "auto",
    zIndex: 100,
  },
  cancelButton: {
    height: 10,
    margin: 3,
    paddingVertical: 3,
    paddingHorizontal: 50,
    marginVertical: "auto",
    zIndex: 100,
    backgroundColor: "#F7F9FC",
    borderColor: "none",
  },
  cancelButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "color-basic-800",
  },
  input: {
    flex: 1,
    fontSize: 22,
    marginBottom: 22,
  },
  LogInButton: {
    marginBottom: 25,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  cardsContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  card: {
    background: "color-basic-100",
    padding: 10,
    borderRadius: 10,
    width: "90%",
    margin: 30,
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  cell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  headerCell: {
    flex: 1,
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "left",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardSection: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    margin: 15,
  },
  hoverHelpText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#222B45",
    fontSize: 12,
    position: "absolute",
    left: 220,
    lineHeight: 20,
    maxWidth: 200,
    padding: 10,
    borderRadius: 4,
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  dataRow: {
    flexDirection: "row",
    margin: 10,
  },
  chevronButton: {
    width: 32,
    height: 32,
    marginVertical: "auto",
    marginRight: 32,
  },
  infoIcon: {
    width: 20,
    height: 20,
    position: "relative",
    top: 5,
    fill: "#8F9BB3",
  },
});
