import {
  Button,
  Text,
  useStyleSheet,
  Divider,
  Icon,
} from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useState, useEffect } from "react";
import { Dimensions, View, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import StepButton from "../../components/StepButton/StepButton";
import config from "../../config";
import { uploadDocument } from "../../utils/index";
import { BankLinkStep } from "./BankLinkStep";
import { ConsentPageStep } from "./ConsentPageStep";
import { PaymentApplicationStep } from "./PaymentApplicationStep";
import { TaxReturnAuthorizationStep } from "./TaxReturnAuthorizationStep";
import { themedStyles } from "./themedStyles";
import { getQuickbooksStatus } from "../../services/api/loan";
import { useStore } from "../../stores";

const AccountingLinkStep: React.FC<any> = ({
  id,
  styles,
  setFormData,
  formData,
  qbLinked = false,
  setQbLinked,
}) => {
  const bankImage = require("../../../assets/wallet.svg");
  const options = ["Quickbooks", "Deltek", "Other"];
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [accountingSoftware, setAccountingSoftware] =
    useState<string>("Quickbooks");
  const [qbConnect, setQbConnect] = useState("unknown");
  const { authStore } = useStore();
  const IncomeStatementExampleFileUrl = require("../../../assets/Income Statement Example.docx");
  const BalanceSheetExampleFileUrl = require("../../../assets/Balance Sheet Example.docx");

  const WalletIcon = (props: any) => (
    <Image
      style={{
        width: 24,
        height: 24,
        paddingHorizontal: 10,
        marginHorizontal: 10,
      }}
      source={bankImage}
    />
  );
  const UploadIcon = (props: any) => (
    <Icon {...props} fill="white" name="upload-outline" />
  );

  const launchAccountingAPI = async (name: string) => {
    if (name === "Quickbooks") {
      const params = {
        loanId: id,
      };
      const query = new URLSearchParams(params);
      try {
        const result = await WebBrowser.openAuthSessionAsync(
          `${config.urls.api}${config.urls.quickbooks.oauth}?${query}`,
          "/qb-redirect"
        );
        const status = await getQuickbooksStatus(
          authStore.accessToken || "",
          id
        );

        if (status.response.entities) {
          setQbConnect(
            status.response.entities["isConnected"] ? "success" : "failure"
          );
        } else {
          console.log(status);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (qbConnect === "success") setQbLinked(true);
  }, [qbConnect, qbLinked]);

  /* Comment out for now to only use Quickbooks */
  // useEffect(() => {
  //   setAccountingSoftware(options[selectedIndex?.row] || "");
  // }, [selectedIndex]);

  const successQbLink = qbConnect === "success" || qbLinked;

  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      {/* Comment out for now to only use Quickbooks */}
      {/* <Text style={styles.inputLabel}>
        Which accounting software do you use?
      </Text>
      <Select
        style={styles.input}
        placeholder="Select"
        value={accountingSoftware}
        selectedIndex={selectedIndex}
        onSelect={(index) => setSelectedIndex(index)}
      >
        {options.map((option, idx) => (
          <SelectItem
            title={option}
            key={`${idx}-accounting-software-option`}
          />
        ))}
      </Select> */}

      {accountingSoftware === "Quickbooks" && (
        <View style={styles.modalDetailsText}>
          <Text style={styles.subTitle}>
            <Text style={styles.bold}>
              We require an up-to-date Income (or Profit &amp; Loss) Statement
              to calculate the EBITDA. We also require a Balance Sheet with
              visible debt stack to calculate DSCR.
            </Text>
            {"\n\n"}
            Please ensure your Income Statement includes the net income,
            interest expense, tax expense, depreciation expense, and
            amortization expense clearly listed and visible.
            {"\n\n"}
            We require these data points to evaluate your loan pursuant to the
            affirmative covenants in the Loan and Security Agreement we entered
            with you.
            {"\n\n"}
            <Button
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              style={
                isHovering && !successQbLink
                  ? styles.accountLinkingButtonHover
                  : {
                      ...styles.accountLinkingButton,
                      ...(successQbLink ? styles.accountLinkingSuccess : {}),
                    }
              }
              status={
                successQbLink
                  ? "success"
                  : qbConnect === "failure"
                  ? "danger"
                  : "basic"
              }
              onPress={() => {
                launchAccountingAPI(accountingSoftware);
                setFormData({ ...formData, isAccountingLinked: true });
              }}
              accessoryLeft={WalletIcon}
              children={() => (
                <Text style={styles.accountLinkingButtonContainer}>
                  <Text style={styles.accountLinkingButtonHeader}>
                    Link {accountingSoftware}
                  </Text>
                  {"\n"}
                  <Text style={styles.accountLinkingButtonText}>
                    {successQbLink
                      ? "Quickbooks Linked\n\n"
                      : "Accessing your accounting software allows us to better underwrite your business"}
                  </Text>
                </Text>
              )}
            />
            To learn how Income or Profit &amp; Loss statements may be prepared,
            please refer to{" "}
            <Text
              status="info"
              style={styles.link}
              onPress={async () => {
                const fullFileUrl = await fetch(IncomeStatementExampleFileUrl);
                const link = document.createElement("a");
                link.href = fullFileUrl.url;
                link.download = "IncomeStatementExampleFile.docx";
                document.body.appendChild(link);
                link.click();
              }}
            >
              this sample spreadsheet
            </Text>
            . To learn how a Balance Sheet may be prepared, please refer to{" "}
            <Text
              status="info"
              style={styles.link}
              onPress={async () => {
                const fullFileUrl = await fetch(BalanceSheetExampleFileUrl);
                const link = document.createElement("a");
                link.href = fullFileUrl.url;
                link.download = "BalanceSheetExampleFile.docx";
                document.body.appendChild(link);
                link.click();
              }}
            >
              this sample
            </Text>
            . For further guidance please consult an accounting professional.
            {"\n\n"}
          </Text>
        </View>
      )}
      {accountingSoftware && accountingSoftware !== "Quickbooks" && (
        <View>
          <Text style={styles.inputLabel}>Upload Income Statement</Text>
          <Button
            style={styles.uploadButton}
            status={formData.incomeStatement ? "success" : "basic"}
            appearance="outline"
            accessoryRight={UploadIcon}
            onPress={() =>
              uploadDocument((doc: any) =>
                setFormData({
                  ...formData,
                  incomeStatement: doc,
                })
              )
            }
            children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
          />
          <Text style={styles.inputLabel}>Upload Balance Sheet</Text>
          <Button
            style={styles.uploadButton}
            status={formData.balanceSheet ? "success" : "basic"}
            appearance="outline"
            accessoryRight={UploadIcon}
            onPress={() =>
              uploadDocument((doc: any) =>
                setFormData({
                  ...formData,
                  balanceSheet: doc,
                })
              )
            }
            children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
          />
        </View>
      )}
    </>
  );
};

export const AccountLinking: React.FC<any> = ({
  onContinue,
  verifyPrequal2,
  currentStep,
  setCurrentStep,
  loan,
  loanId,
  updateLoanData,
  business,
  ssnNumber,
}) => {
  const styles = useStyleSheet(themedStyles);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const ArrowBackIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-back-outline" />
  );
  const CheckmarkIcon = (props: any) => (
    <Icon {...props} fill="white" name="checkmark-outline" />
  );

  const [formData, setFormData] = useState({
    ssnNumber: ssnNumber,
    einNumber: business.einNumber,
    organizationId: "",
    bankAccounts: [{ isLinked: null }],
    signed8821Form: null,
    incomeStatement: "",
    balanceSheet: "",
    prequal2Step: 1,
  });
  const [stepsDone, setStepsDone] = useState<any>(Array(7).fill(false));
  const [paymentStatus, setPaymentStatus] = useState("unknown");
  const [showEINSSNFields, setShowEINSSNFields] = useState(false);
  const [qbLinked, setQbLinked] = useState(!!loan?.hasConnectedQuickbooks);
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [showSubmitButton, setSubmitButtonVisibility] = useState(false);
  const { width } = Dimensions.get("window");

  const [farthestStep, setFarthestStep] = useState(0);

  useEffect(() => {
    if (farthestStep < currentStep) {
      setFarthestStep(currentStep);
    }
    setShowEINSSNFields(false);
  }, [currentStep]);

  useEffect(() => {
    updateLoanData({ prequal2Started: true });
  }, []);

  const goToNextStep = (isStepDone = false) => {
    const newStepsDone = [...stepsDone];
    newStepsDone[currentStep] = isStepDone || newStepsDone[currentStep];
    const data = { ...formData };
    data.prequal2Step = currentStep + 1;
    if (isStepDone && currentStep !== 5) updateLoanData(data);

    setStepsDone(newStepsDone);
    setCurrentStep(currentStep + 1);
  };

  const steps = [
    "Link Bank Account",
    "Application Fee",
    "Link Accounting Software",
    "Tax Return Authorization",
  ];

  useEffect(() => {
    switch (currentStep) {
      case 1:
        if (!formData.bankAccounts[0].isLinked) setDisableContinueButton(true);
        else setDisableContinueButton(false);
        break;
      case 2:
        if (showEINSSNFields) {
          if (
            !formData.ssnNumber ||
            !formData.einNumber ||
            !formData.organizationId
          )
            setDisableContinueButton(true);
          else setDisableContinueButton(false);
        } else if (paymentStatus !== "success") {
          setDisableContinueButton(true);
        } else setDisableContinueButton(false);
        break;
      case 3:
        if (qbLinked) setDisableContinueButton(false);
        else setDisableContinueButton(true);
        break;
      case 4:
        if (
          !formData.bankAccounts[0].isLinked ||
          (!formData.ssnNumber && !formData.einNumber) ||
          !qbLinked ||
          !formData.signed8821Form
        )
          setDisableContinueButton(true);
        else setDisableContinueButton(false);
        break;
      default:
        setDisableContinueButton(false);
        break;
    }
  }, [currentStep, formData, paymentStatus, showEINSSNFields, qbLinked]);

  useEffect(() => {
    const shouldHideSubmitButton =
      !formData.bankAccounts[0].isLinked ||
      (!formData.ssnNumber && !formData.einNumber) ||
      !qbLinked ||
      !formData.signed8821Form;
    setSubmitButtonVisibility(shouldHideSubmitButton);
  }, [currentStep, formData, paymentStatus, showEINSSNFields, qbLinked]);

  useEffect(() => {
    let storedData = {};
    if (Object.keys(business).length > 0) {
      storedData = {
        ...storedData,
        ssnNumber: ssnNumber,
        einNumber: business.einNumber,
      };
    }
    if (Object.keys(loan).indexOf("status") >= 0) {
      storedData = {
        ...storedData,
        organizationId: loan.organizationId,
        signed8821Form: loan.signed8821Form,
      };
    }
    if (Object.keys(storedData).length > 0) {
      setFormData({
        ...formData,
        ...storedData,
      });
      setQbLinked(loan.hasConnectedQuickbooks);
      setCurrentStep(1);
    }
  }, [loan, business]);

  return (
    <>
      {currentStep < 5 && (
        <>
          <Text style={width > 768 ? styles.header : styles.headerMobile}>
            Applicant Due Diligence
          </Text>
          <Text style={width > 768 ? styles.subHeader : styles.subHeaderMobile}>
            We just need a few more pieces of information to determine if you
            are qualified to proceed to the full loan application.
          </Text>
        </>
      )}
      {currentStep === 6 && (
        <Text style={{ ...styles.subHeader, ...{ textAlign: "center" } }}>
          Please wait for a confirmation email or check your dashboard to know
          if you have been qualified to proceed to the next steps.
        </Text>
      )}
      <View
        style={
          width > 768
            ? styles.fullApplicationStatusContainer
            : styles.fullApplicationStatusContainerMobile
        }
      >
        {currentStep !== 5 &&
          steps.map((step, idx) => {
            return (
              <View key={step}>
                <StepButton
                  number={idx + 1}
                  label={step}
                  stepsDone={stepsDone}
                  currentStep={currentStep === 5 ? 4 : currentStep}
                  clickable={
                    stepsDone[idx + 1] || farthestStep === idx + 1 || __DEV__
                  }
                  onPress={() => setCurrentStep(idx + 1)}
                />
                {idx < steps.length - 1 && <Divider style={styles.divider} />}
              </View>
            );
          })}
      </View>

      {width < 768 && (
        <View style={styles.preApprovalLabelContainer}>
          <Text style={styles.preApprovalLabel}>{steps[currentStep - 1]}</Text>
          {currentStep < 4 && (
            <Text
              style={styles.preApprovalSubLabel}
            >{`Next: ${steps[currentStep]}`}</Text>
          )}
        </View>
      )}

      {currentStep === 1 && (
        <BankLinkStep
          formData={formData}
          setFormData={setFormData}
          onContinue={(isSuccess: any) => goToNextStep(isSuccess)}
          loanId={loan?.id || loanId}
          applicantPrimaryAccountId={loan?.applicantPrimaryAccountId}
        />
      )}

      {currentStep === 2 && (
        <PaymentApplicationStep
          id={loan?.id}
          formData={formData}
          setFormData={setFormData}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          showEINSSNFields={showEINSSNFields}
          setShowEINSSNFields={setShowEINSSNFields}
        />
      )}

      {currentStep === 3 && (
        <AccountingLinkStep
          styles={styles}
          id={loan?.id}
          formData={formData}
          setFormData={setFormData}
          qbLinked={qbLinked}
          setQbLinked={setQbLinked}
        />
      )}

      {currentStep === 4 && (
        <TaxReturnAuthorizationStep
          loanId={loan?.id}
          unsigned8821Form={loan?.unsigned8821Form}
          formData={formData}
          setFormData={setFormData}
          goToNextStep={goToNextStep}
          disabled={disableContinueButton}
        />
      )}

      {currentStep === 5 && (
        <ConsentPageStep
          goToNextStep={goToNextStep}
          verifyPrequal2={verifyPrequal2}
        />
      )}

      {currentStep < 4 && currentStep !== 1 && (
        <Button
          disabled={disableContinueButton}
          style={
            disableContinueButton
              ? styles.disabledActionButton
              : styles.nextActionButton
          }
          status="basic"
          appearance="ghost"
          onPress={() => {
            if (currentStep === 2) {
              if (!showEINSSNFields) {
                setShowEINSSNFields(true);
              } else {
                goToNextStep(true);
              }
            } else if (currentStep + 1 < stepsDone.length) {
              setShowEINSSNFields(false);
              goToNextStep(true);
            }
          }}
          accessoryRight={CheckmarkIcon}
          children={() => (
            <Text
              style={
                disableContinueButton
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              {"Mark Complete"}
            </Text>
          )}
        />
      )}
      {/* {currentStep === 6 && (
        <View style={styles.goToLoanTracker}>
          <TouchableOpacity
            style={styles.goToLoanTrackerButton}
            onPress={() => onContinue()}
          >
            <Text style={styles.goToLoanTrackerText}>
              Continue to LOAN TRACKER
            </Text>
          </TouchableOpacity>
        </View>
      )} */}
      <View style={width > 576 && styles.flexRow}>
        {currentStep > 1 && (
          <Button
            style={styles.nextActionButton}
            onPress={() => setCurrentStep(currentStep - 1)}
            accessoryLeft={ArrowBackIcon}
            children={() => (
              <Text style={styles.nextActionButtonText}>Back{"  "}</Text>
            )}
          />
        )}

        {currentStep < 4 && (
          <Button
            style={styles.nextActionButton}
            onPress={() => setCurrentStep(currentStep + 1)}
            accessoryRight={ArrowIcon}
            children={() => (
              <Text style={styles.nextActionButtonText}>Next{"  "}</Text>
            )}
          />
        )}
      </View>
    </>
  );
};
