import {
  Text,
  useStyleSheet,
  Icon,
  CheckBox,
  Button,
  Divider,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

export const ProfileSettings: React.FC<any> = ({ user, setSuccess, theme }) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore } = useStore();

  const defaultFields = {
    isSubscribedToEmailNotifications:
      authStore.user?.isSubscribedToEmailNotifications,
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [formData, setFormData] = useState<any>({
    isSubscribedToEmailNotifications:
      authStore.user?.isSubscribedToEmailNotifications,
  });

  useEffect(() => {
    formDataReset(authStore.user);
    setSuccess(false);
  }, []);

  const formDataReset = (user: any) => {
    if (user) {
      setFormData({
        isSubscribedToEmailNotifications: user.isSubscribedToEmailNotifications,
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorMessages({ ...defaultErrors });
    let res: any = {};
    if (authStore.accessToken && authStore.user) {
      console.log(formData);
      res = await authStore.updateUser({
        ...formData,
        isSubscribedToEmailNotifications:
          !!formData.isSubscribedToEmailNotifications,
      });
      setLoading(false);
      if (!res?.ok) {
        setErrorMessages(res.errors);
      } else {
        setErrorMessages({ ...defaultErrors });
        setIsEditing(false);
      }
    }
    setLoading(false);
    formDataReset(authStore.user);
  }, [loading, formData]);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const EditIcon = (props: any) => (
    <Icon {...props} name="edit-outline" fill="#26DDF1" />
  );

  return (
    <View>
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Settings
        </Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsEditing(!isEditing);
          }}
        />
      </View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Divider style={styles.divider} />

      <Collapsible collapsed={isCollapsed}>
        <CheckBox
          disabled={!isEditing}
          style={{
            padding: 5,
            margin: 5,
            marginBottom: 20,
            marginLeft: 0,
          }}
          status="primary"
          checked={formData.isSubscribedToEmailNotifications}
          onChange={() =>
            setFormData((prev: any) => ({
              ...prev,
              isSubscribedToEmailNotifications:
                !prev.isSubscribedToEmailNotifications,
            }))
          }
        >
          Receive email notifications
        </CheckBox>
        {isEditing && (
          <View style={styles.flexRow}>
            <TouchableOpacity
              style={styles.saveButton}
              onPress={() => {
                onSubmitForm();
              }}
            >
              <LinearGradient
                style={styles.saveButtonGradient}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={["#D012B2", "#8A1CDB", "#5327FF"]}
              >
                <Text style={styles.saveButtonText}>Save</Text>
                <Icon
                  style={styles.saveButtonIcon}
                  name="checkmark-circle-2"
                  fill="white"
                />
              </LinearGradient>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                ...styles.cancelButton,
                ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
              }}
              onPress={() => {
                setIsEditing(false);
                formDataReset(authStore.user);
              }}
            >
              <Text
                style={{
                  ...styles.cancelButtonText,
                  ...(theme === "light" ? { color: "#222B45" } : {}),
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
