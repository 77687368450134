import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },

  input: {
    flex: 1,
    fontSize: 22,
    marginBottom: 22,
  },
  LogInButton: {
    marginBottom: 25,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  cardsContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  card: {
    background: "color-basic-100",
    padding: 10,
    borderRadius: 10,
    flex: 1,
    width: "90%",
    margin: 30,
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  cell: {
    flex: 1,
    flexGrow: 1,
    width: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
    paddingRight: 10,
  },
  textCell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  notBoldCell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  headerCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "left",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardSection: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    margin: 20,
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  dataRow: {
    flexDirection: "row",
    margin: 10,
  },
  subMenu: {
    flexDirection: "row",
    padding: 10,
    margin: 10,
  },
  pullDataBtn: {
    margin: 0,
    padding: 0,
    height: 10,
    background: "#FFF",
  },
  pullDataBtnText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    backgroundClip: "text",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    color: "transparent",
  },
  gradientButton: {
    height: 10,
    margin: 3,
    padding: 3,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
  },
  cellInput: {
    marginVertical: 10,
    width: "90%",
  },
  cellInputText: {
    width: "100%",
  },
  betweenCellsDivider: {
    width: "85%",
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
  },
  infoIcon: {
    width: 20,
    height: 20,
    position: "relative",
    top: 0,
    fill: "#8F9BB3",
  },
  hoverHelpText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#222B45",
    fontSize: 12,
    position: "absolute",
    lineHeight: 20,
    width: 150,
    padding: 10,
    borderRadius: 4,
  },
  selectItem: {
    backgroundColor: "white",
  },
  selectItemText: {
    color: "#222B45",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
  },
  flagDescription: {
    width: "85%",
    fontFamily: "Montserrat_500Normal",
    lineHeight: 16,
    fontSize: 14,
  },
  flagDescriptionText: {
    color: "#222B45",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 18,
    fontSize: 15,
  },
});
