import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import { callApi } from "./base";

export const postPlaidToken = async (plaidToken: string, token: string) =>
  callApi(
    config.urls.post_plaid_token,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        plaidToken,
      })
    )
  );

export const getLinkedAccounts = async (token: string) =>
  callApi(config.urls.get_linked_accounts, token, "GET");

export const postFundingAccount = async (token: string, data: object) =>
  callApi(
    config.urls.post_funding_account,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const postPayrollAccounts = async (token: string, data: object) =>
  callApi(
    config.urls.post_payroll_accounts,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );
