import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  scrollView: {
    height: 500,
  },
  gradientBar: {
    height: 16,
    width: "100%",
    marginLeft: "6%",
    marginBottom: 42,
  },
  adminDashBoard: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginLeft: 50,
    marginRight: 25,
    maxWidth: 1450,
    overflow: "hidden",
  },
  adminDashBoardMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 16,
    width: "100%",
    overflow: "hidden",
  },
  adminDashboardContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    flex: 1,
    fontSize: 22,
    margin: 5,
  },
  inputLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    lineHeight: 24,
    marginVertical: 14,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  LogInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  success: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-success-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    fontWeight: 800,
    lineHeight: 48,
    fontSize: 30,
    marginTop: 69,
    marginBottom: 80,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerEnd: {
    fontFamily: "Montserrat_700Bold",
    color: "black",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    fontWeight: 800,
    lineHeight: 48,
    fontSize: 30,
    marginTop: 69,
    marginBottom: 24,
    textAlign: "center",
    textTransform: "uppercase",
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    padding: 2,
    margin: 2,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  flexRowRight: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexRowLeft: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 250,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  tableHeadersCell: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    padding: 1,
    margin: 1,
    alignSelf: "center",
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  tableCell: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 20,
    fontSize: 14,
    padding: 1,
    margin: 1,
    alignSelf: "center",
    borderRadius: 5,
  },
  tableContainer: {},
  ACTIVE: {
    backgroundColor: "color-success-default",
    color: "white",
    fontFamily: "Montserrat_700Bold",
  },
  INACTIVE: {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
  },
  DENIED: {
    backgroundColor: "color-danger-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  PENDING: {
    backgroundColor: "color-warning-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  requestedDocumentsContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "stretch",
    height: "auto",
    width: "100%",
    marginBottom: 100,
  },
  requestedDocumentsContainerMobile: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "stretch",
    height: "auto",
    width: "100%",
    marginBottom: 100,
  },
  requestedDocumentsSidebar: {
    flex: 2,
    border: 1,
    borderColor: "#2E3A59",
    borderStyle: "solid",
    height: "auto",
  },
  requestedDocumentContent: {
    flex: 3,
    border: 1,
    borderColor: "#2E3A59",
    borderStyle: "solid",
    height: "auto",
  },
  requestedDocumentsSidebarMobile: {
    border: 1,
    borderColor: "#2E3A59",
    borderStyle: "solid",
    height: "auto",
  },
  requestedDocumentContentMobile: {
    border: 1,
    borderColor: "#2E3A59",
    borderStyle: "solid",
    height: "auto",
  },
  sidebarItemContainer: {
    padding: 28,
    borderColor: "#2E3A59",
    borderWidth: 1,
  },
  sidebarItemHeader: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  sidebarItemDate: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
  },
  contentItemContainer: {
    paddingVertical: 26,
    paddingHorizontal: 63,
  },
  contentItemContainerMobile: {
    paddingVertical: 26,
    paddingHorizontal: 16,
  },
  contentItemHeader: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 26,
    lineHeight: 32,
  },
  contentItemDate: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 23,
  },
  contentItemMessage: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginVertical: 35,
  },
  dividerWide: {
    height: 0,
    width: "auto",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: "#2E3A59",
  },
  divider: {
    height: 0,
    width: "auto",
    marginHorizontal: 34,
    borderWidth: 1,
    borderColor: "#2E3A59",
  },
  dividerMobile: {
    height: 0,
    width: "auto",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: "#2E3A59",
  },
  fileButton: {
    borderWidth: 1,
    borderColor: "#26DDF1",
    backgroundColor: "#222B45",
    padding: 16,
    marginBottom: 19,
  },
  fileButtonText: {
    fontFamily: "Montserrat_500Medium",
    fontSize: 13,
    lineHeight: 24,
    marginRight: 16,
    color: "#26DDF1",
  },
  uploadButton: {
    padding: 12,
    backgroundColor: "#222B45",
    borderWidth: 0,
    marginHorizontal: 5,
    marginVertical: 13,
  },
  uploadButtonText: {
    color: "white",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    marginRight: 12,
  },
  sendButtonText: {
    color: "white",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
    textTransform: "uppercase",
    marginLeft: 12,
  },
  backButton: {
    backgroundColor: "#192038",
    borderColor: "#192038",
    border: "none",
  },
  messageHeader: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    lineHeight: 20,
    marginVertical: 20,
  },
  messageText: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginBottom: 40,
  },
  buttonContainer: {
    marginHorizontal: 10,
    marginVertical: 10,
    width: "100%",
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    width: "60%",
  },
  modal: {
    flex: 1,
    width: "70%",
    height: "90%",
  },
  modalContainer: {
    width: "100%",
    backgroundColor: "#101426",
    padding: 50,
    marginVertical: "auto",
  },
  modalText: {
    color: "white",
    fontFamily: "Montserrat_700Bold",
    textAlign: "center",
    fontSize: 18,
    lineHeight: 24,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalYesButton: {
    backgroundColor: "#222B45",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingTop: 10,
    paddingBottom: 20,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  oneText: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
    marginHorizontal: 20,
  },
  oneTextMsg: {
    color: "black",
    fontSize: 20,
    lineHeight: 20,
  },
});
