import { useLinkTo } from "@react-navigation/native";
import {
  Text,
  useStyleSheet,
  Input,
  Button,
  Icon,
} from "@ui-kitten/components";
import React, { useRef } from "react";
import { Dimensions, View } from "react-native";

import HoverBubble from "../../components/HoverBubble";
import { themedStyles } from "./themedStyles";

export const DonePage: React.FC<any> = ({ loan }) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const linkTo = useLinkTo();

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );

  return (
    <>
      <Text style={width > 768 ? styles.header : styles.headerMobile}>
        You're on a roll!
      </Text>
      <Text style={width > 768 ? styles.subHeader : styles.subHeaderMobile}>
        Thanks for your information. Your business will receive an email or
        dashboard notice when your application is ready to proceed.
      </Text>
      <View style={styles.flexRowCenter}>
        <Button
          style={styles.goToLoanTrackerButton}
          status="primary"
          appearance="outline"
          onPress={() => {
            linkTo("/dashboard");
          }}
          children={() => (
            <Text style={styles.nextActionButtonText}>Go to Dashboard</Text>
          )}
        />
        <Button
          style={styles.nextActionButton}
          status="primary"
          appearance="outline"
          onPress={() => {
            linkTo(`/loan-view?id=${loan.id}`);
          }}
          accessoryRight={ArrowIcon}
          children={() => (
            <Text style={styles.nextActionButtonText}>Continue</Text>
          )}
        />
      </View>
    </>
  );
};
