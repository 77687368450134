import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";

const UCCAuthorization: React.FC<any> = (props) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.authorizationContainer}>
          <View style={styles.authorization}>
            <Text style={styles.paragraph}>
              For purposes of this UCC Authorization, “Lender” means OppZo
              Opportunity Zone Fund I Sub I, LLC, a Delaware limited liability
              company, and “Borrower” means the entity identified during
              registration. In anticipation of the possible extension of credit
              by Lender to Borrower, Borrower hereby authorizes Lender at any
              time, whether before or after any agreements or other documents
              are signed between Borrower and Lender and whether before or after
              any credit is extended to Borrower, to file in such public offices
              as Lender may consider appropriate, one or more UCC-1 financing
              statements identifying Borrower as the “debtor” and Lender as
              “secured party”, and describing the collateral as follows: “All
              assets in which the Debtor now or hereafter has any right, title
              or interest (or the power to transfer rights), whether now owned
              or existing or hereafter acquired or arising, including, without
              limitation, all proceeds of any of the foregoing, whether in the
              form of money or any other type of property”, and from time to
              time all amendments to and continuations of such financing
              statements as Lender may consider appropriate, all at Borrower’s
              expense. If Lender does extend credit to Borrower, Borrower agrees
              that any UCC-1 financing statement filed by Lender pursuant to
              this authorization prior to the date of such extension of credit
              remains Borrower’s authorized filing in connection with any or all
              security interests then or thereafter granted to secure such
              extension of credit. In the event that Lender finally determines
              not to extend credit to Borrower, Lender will file appropriate
              UCC-3 terminations, at Borrower’s expense, to any UCC-1 financing
              statements theretofore filed by Lender pursuant to this
              authorization and then remaining of record. In furtherance of the
              foregoing, Borrower represents and warrants that its exact legal
              name in its certificate of incorporation or other formation
              document is the name provided during registration, that the
              address of its chief executive office is the address provided
              during registration, and that it is organized solely under the
              laws of the state provided during registration.
            </Text>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default UCCAuthorization;
