import * as DocumentPicker from "expo-document-picker";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Toast } from "react-native-toast-notifications";

export const mebiBytesToBytes = (mebiBytes: number) =>
  mebiBytes * Math.pow(2, 20);

export const uploadDocument = async (
  setDocument: any,
  config?: {
    maxSize?: number;
    onSizeError?: (result: DocumentPicker.DocumentResult) => void;
    onError?: () => void;
  }
) => {
  try {
    const result = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
    });

    if (result.type === "success") {
      if (
        config?.maxSize &&
        result.size &&
        result.size > mebiBytesToBytes(config?.maxSize)
      ) {
        Toast.show(
          `${result.name} is too large. Upload limit: ${config?.maxSize}MB.`,
          {
            type: "danger",
          }
        );

        if (config.onSizeError) {
          config.onSizeError(result);
        }
      } else {
        // @ts-ignore
        Toast.show(`${result.name} is successfully queued for upload`, {
          type: "success",
        });
        setDocument(result);
      }
    }
  } catch (err) {
    Toast.show(
      "Some of the selected files were not successfully prepped for upload",
      {
        type: "danger",
      }
    );

    if (config?.onError) {
      config.onError();
    }
    throw err;
  }
};

export const downloadFileFromURI = async (
  uri: string,
  downloadName = "attachment"
) => {
  const link = document.createElement("a");
  const b64toBlob = (dataUri: string) =>
    fetch(dataUri).then((res) => res.blob());

  if (uri.includes("base64") || uri.includes("pdf")) {
    link.href = window.URL.createObjectURL(await b64toBlob(uri));
  } else {
    link.href = uri;
  }
  link.download = downloadName;
  document.body.appendChild(link);
  link.click();
};

export const openLinkInNewTab = (url: string) => {
  if (url.indexOf("https://") >= 0 || url.indexOf("http://") >= 0)
    window.open(url, "_blank");
  //  Opening a screen in stack in new tab
  else window.open(`${window.location.origin}${url}`, "_blank");
};

export const camelCaseToSnakeCase = (str: string) =>
  str &&
  //@ts-ignore
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((s) => s.toLowerCase())
    .join("_");

// Created the function to be able to type the period in the input field
export const formatNumberWithCommas = (number: string) => {
  if (!number) return "";
  if (number === null || isNaN(Number(number))) return;

  const fullNumber = number.split(".");
  fullNumber[0] = Number(fullNumber[0]).toLocaleString();

  if (fullNumber.length > 1 && fullNumber[1].length > 2) {
    fullNumber[1] = Number(`0.${fullNumber[1]}`).toFixed(2).toString().slice(2);
  }

  return fullNumber.join(".");
};

export const printComponent = async (componentToPrint: any, scale = 2) => {
  const widthInPx = componentToPrint.current.clientWidth * scale;
  const heightInPx = componentToPrint.current.clientHeight * scale;

  const pdf = new jsPDF({
    format: [heightInPx, widthInPx],
    orientation: heightInPx > widthInPx ? "portrait" : "landscape",
    unit: "px",
    hotfixes: ["px_scaling"],
  });
  const element = componentToPrint.current;
  const canvasForImg = await html2canvas(element);

  const dataUrl = canvasForImg.toDataURL("image/jpg");

  const canvas = document.createElement("canvas");
  canvas.width = widthInPx;
  canvas.height = heightInPx;
  const context = canvas.getContext("2d");
  context.imageSmoothingEnabled = false;
  const img = new Image();
  img.src = dataUrl;

  img.onload = () => {
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
    const png = canvas.toDataURL();
    pdf.setPage(0);
    pdf.addImage(png, 0, 0);

    // jsPDF .save() does not work on phones/tablets/ipads, so opening pdf on other tab instead
    if (
      /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      const blob = pdf.output("blob");
      window.open(URL.createObjectURL(blob));
    } else {
      pdf.save("investor_dashboard.pdf");
    }
  };
};

export const unmaskPhoneNumber = (phoneNumber: any) => {
  let ret = "";
  for (const i in phoneNumber) {
    if (
      Boolean(
        [true, true, true, true, true, true, true, true, true, true][
          phoneNumber[i]
        ]
      ) ||
      phoneNumber[i] === "+"
    ) {
      ret = `${ret}${phoneNumber[i]}`;
    }
  }
  return ret;
};

export const getFileName = (filePath: any) => {
  return filePath.split("\\").pop().split("/").pop().split(".")[0];
};
