import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Text,
  Divider,
  useStyleSheet,
  Spinner,
  Icon,
  Button,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, TouchableWithoutFeedback, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { TableDisplay } from "../../components/TableDisplay";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { EquifaxFinalPullRetry } from "./EquifaxFinalPullRetry";
import { PullReportButton } from "./PullReportButton";
import { themedStyles } from "./themedStyles";
import { uploadDocument, getFileName } from "../../utils";

export const BusinessEntityReview: React.FC<any> = ({
  canEdit,
  applicationId,
  editTimestampValue = null,
  setEditTimestampValue,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const [editData, setEditData]: any = useState({});
  const [equifaxEntityErrorList, setEquifaxEntityErrorList] = useState([]);
  const [isLoanHardRejected, setIsLoanHardRejected] = useState(true);
  const [isEquifaxFinalPullRetryOpen, setIsEquifaxFinalPullRetryOpen] =
    useState(false);
  const [fieldsToBeUpdated, setFieldsToBeUpdated] = useState([]);

  const defaultErrors = { error: "" };

  const [errors, setErrors] = useState(defaultErrors);
  const [validationErrors, setValidationErrors] = useState("");

  const [businessOverviewDataDisplay, setBusinessOverviewDataDisplay]: any =
    useState([
      {
        header: "KYB",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "kyb",
      },
      {
        header: "Business Website",
        displayValue: "?",
        fieldNameInResponse: "businessWebsite",
        editable: true,
        isEditableLink: true,
      },
      {
        header: "UCC Search",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "uccSearch",
      },
      {
        header: "Bylaws",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "bylaws",
      },
      {
        header: "Equifax Entity Report",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "equifaxEntityReport",
      },
      {
        header: "Corporate Resolution/ Authorizing Resolution",
        displayValue: (
          <PullReportButton uri="" isLink text="VIEW CERTIFICATE" />
        ),
        isOptional: true,
        isLink: true,
        fieldNameInResponse: "resolution",
      },
      {
        header: "Company Organization Chart",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        isOptional: true,
        fieldNameInResponse: "companyOrganizationChart",
      },
      {
        header: "Operating Agreement",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        isOptional: true,
        fieldNameInResponse: "operatingAgreement",
      },
    ]);

  const [
    goodStandingCertificatesDisplay,
    setGoodStandingCertificatesDisplay,
  ]: any = useState([]);

  const [taxDetailsDataDisplay, setTaxDetailsDataDisplay]: any = useState([
    {
      header: "2 Years of Tax Return Transcripts",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
      fieldNameInResponse: "twoYearsOfTaxReturnTranscripts",
    },
  ]);

  const [existingLendersDataDisplay, setExistingLendersDataDisplay]: any =
    useState([]);

  const [otherDocuments, setOtherDocuments]: any = useState([]);

  const { authStore } = useStore();

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getLoanBusinessEntity(authStore?.accessToken, applicationId)
          .then((resp) => {
            console.log(resp);
            const response = resp.response.entities;
            console.log(response);
            setIsLoanHardRejected(response.isHardRejected);
            setBusinessOverviewDataDisplay(
              businessOverviewDataDisplay.map((obj: any) => ({
                ...obj,
                displayValue: !obj.isLink ? (
                  response.businessOverview[obj.fieldNameInResponse]
                ) : (
                  <PullReportButton
                    uri={response.businessOverview[obj.fieldNameInResponse]}
                    isLink
                    text={
                      response[obj.fieldNameInResponse] === "error"
                        ? "REPORT FAIL"
                        : ["resolution"].includes(obj.fieldNameInResponse)
                        ? "VIEW CERTIFICATE"
                        : obj.fieldNameInResponse === "equifaxEntityReport"
                        ? response.isFinalPullEquifaxCommercial &&
                          response.equifaxEntityErrors.length > 0
                          ? "IN PROCESS"
                          : !response.isFinalPullEquifaxCommercial &&
                            response.equifaxEntityErrors.length > 0
                          ? "RETRY - PULL REPORT"
                          : "PULL REPORT"
                        : "PULL REPORT"
                    }
                    accessoryIconName={
                      obj.fieldNameInResponse === "equifaxEntityReport" &&
                      response.isFinalPullEquifaxCommercial
                        ? "more-horizontal"
                        : "arrow-forward"
                    }
                    openModal={() => setIsEquifaxFinalPullRetryOpen(true)}
                  />
                ),
              }))
            );
            const goodStandingCertificatesData: any = [];

            response.goodStandingCertificates.forEach((obj: any) => {
              const fileName = getFileName(obj.certificateFile);
              const fileLink = obj.certificateFile;
              goodStandingCertificatesData.push({
                header: fileName,
                displayValue: (
                  <PullReportButton
                    text={"VIEW CERTIFICATE"}
                    uri={fileLink}
                    isLink
                  />
                ),
              });
            });

            setGoodStandingCertificatesDisplay(goodStandingCertificatesData);

            setTaxDetailsDataDisplay(
              taxDetailsDataDisplay.map((obj: any) => ({
                ...obj,
                displayValue: !obj.isLink ? (
                  response[obj.fieldNameInResponse]
                ) : (
                  <PullReportButton
                    uri={response.taxDetails[obj.fieldNameInResponse]}
                    isLink
                    text={
                      response[obj.fieldNameInResponse] === "error"
                        ? "REPORT FAIL"
                        : "PULL REPORT"
                    }
                  />
                ),
              }))
            );

            setEquifaxEntityErrorList(response.equifaxEntityErrors);

            const existingLendersData: any = [];
            response.existingLendersAndLiens.forEach((obj: any) => {
              existingLendersData.push({
                header: "Lender Name",
                displayValue: obj.lenderName,
              });
              existingLendersData.push({
                header: "Loan Amount",
                displayValue: obj.lendAmount,
                prefix: "$",
              });
              existingLendersData.push({
                header: "Collateral",
                displayValue: obj.collateral,
              });
              existingLendersData.push({
                header: "Debt Schedule",
                displayValue: (
                  <PullReportButton uri={obj.debtSchedule} isLink />
                ),
              });
              existingLendersData.push({
                header: "Amortization",
                displayValue: obj.amortization,
                prefix: "$",
              });
              existingLendersData.push({
                header: "Variable?",
                displayValue: obj.isVariable ? "yes" : "no",
              });
              existingLendersData.push({
                header: "Secured?",
                displayValue: obj.isSecured ? "yes" : "no",
              });
              existingLendersData.push({
                header: "Term",
                displayValue: obj.term,
                suffix: " Months",
              });
            });

            setExistingLendersDataDisplay(existingLendersData);

            const newEditData: any = {};
            businessOverviewDataDisplay
              .filter((obj: any) => obj.editable)
              .forEach((obj: any) => {
                newEditData[obj.fieldNameInResponse] =
                  response.businessOverview[obj.fieldNameInResponse];
              });
            setEditData(newEditData);

            setOtherDocuments(
              response.otherDocuments.map((doc: any) => {
                return {
                  header: Object.keys(doc)[0],
                  displayValue: (
                    <PullReportButton uri={Object.values(doc)[0]} isLink />
                  ),
                  isLink: true,
                };
              })
            );
            setLoading(false);
          });
      }
    }, [useIsFocused()])
  );

  useEffect(() => {
    if (equifaxEntityErrorList.length > 0) {
      const listOfFieldsForBureauRetry = updateListOfFieldsForBureauRetry(
        equifaxEntityErrorList
      );
      setFieldsToBeUpdated(listOfFieldsForBureauRetry);
    }
  }, [equifaxEntityErrorList]);

  useEffect(() => {
    if (!isEquifaxFinalPullRetryOpen) {
      const index = businessOverviewDataDisplay.findIndex(
        (obj: any) => obj.fieldNameInResponse === "equifaxEntityReport"
      );

      // Update display
      setBusinessOverviewDataDisplay(
        businessOverviewDataDisplay.map((field: any, idx: number) => {
          if (idx === index)
            return {
              ...field,
              displayValue: (
                <PullReportButton
                  isLink
                  text="IN PROCESS"
                  accessoryIconName="more-horizontal"
                />
              ),
            };
          return field;
        })
      );
    }
  }, [isEquifaxFinalPullRetryOpen]);

  const updateListOfFieldsForBureauRetry = (rawError: any) => {
    const listOfFieldsForBureauRetry: any = [];
    rawError.forEach((item: any) => {
      const content = item.errorDescription.toLowerCase();
      if (content.includes("city")) listOfFieldsForBureauRetry.push("City");
      if (content.includes("state")) listOfFieldsForBureauRetry.push("State");
      if (content.includes("zip")) listOfFieldsForBureauRetry.push("ZIP Code");
      if (content.includes("company ssn"))
        listOfFieldsForBureauRetry.push("FEIN");
    });

    return listOfFieldsForBureauRetry;
  };

  const confirmFinalBureauRetry = () => {
    if (authStore?.accessToken && applicationId) {
      setLoading(true);
      api
        .sendUpdateDetailsForEntityReportEmail(
          authStore?.accessToken,
          applicationId
        )
        .then((resp) => {
          const response = resp.response.entities;
          console.log(response);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const uploadGoodStandingCertificate = (document: File) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("certificate_file", document);

    api
      .uploadGoodStandingCertificate(
        authStore?.accessToken || "",
        applicationId,
        formData
      )
      .then((resp) => {
        const response = resp.response.entities;

        const fileName = getFileName(response.certificateFile);
        const fileLink = response.certificateFile;

        // Update display
        setGoodStandingCertificatesDisplay([
          ...goodStandingCertificatesDisplay,
          {
            header: fileName,
            displayValue: (
              <PullReportButton
                text={"VIEW CERTIFICATE"}
                uri={fileLink}
                isLink
              />
            ),
          },
        ]);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const saveEdits = () => {
    setLoading(true);
    setValidationErrors("");
    api
      .updateLoanBusinessEntity(authStore?.accessToken || "", applicationId, {
        ...editData,
        editTimestamp: editTimestampValue,
      })
      .then((resp) => {
        console.log(resp);
        const response = resp.response.entities;

        // Check if other UWs edited the loan
        const errorMessageIndex = Object.keys(response).findIndex(
          (item) => item === "error"
        );
        if (errorMessageIndex > -1) {
          setErrors(response);
          setLoading(false);
          setIsEditing(false);
          return;
        }

        setEditTimestampValue(response.modified);

        // Update display
        setBusinessOverviewDataDisplay(
          businessOverviewDataDisplay.map((field: any) => {
            if (!field.editable) return field;
            return {
              ...field,
              displayValue: editData[field.fieldNameInResponse].trim(),
            };
          })
        );

        setLoading(false);
        setIsEditing(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        setValidationErrors(`${err}`);
      });
  };

  const [isBusinessOverviewOpen, setIsBusinessOverviewOpen] = useState(true);
  const [isTaxDetailsOpen, setIsTaxDetailsOpen] = useState(true);
  const [isExistingLendersOpen, setIsExistingLendersOpen] = useState(true);
  const [isGoodStandingCertificatesOpen, setIsGoodStandingCertificatesOpen] =
    useState(true);

  const [isOtherOpen, setIsOtherOpen] = useState(true);

  if (loading)
    return (
      <View style={{ ...styles.container, alignItems: "center" }}>
        <LoadingIndicator />
      </View>
    );

  return (
    <View style={styles.cardsContainer}>
      <View style={styles.card}>
        <EquifaxFinalPullRetry
          isOpen={isEquifaxFinalPullRetryOpen}
          closeModal={() => setIsEquifaxFinalPullRetryOpen(false)}
          fieldsToBeUpdated={fieldsToBeUpdated}
          confirmFinalBureauRetry={confirmFinalBureauRetry}
        />
        <View style={styles.flexRowBetween}>
          <Text style={styles.cardHeader}>
            {applicationId
              ? "Business Entity Review"
              : "Business Entity Review (no application selected)"}
          </Text>
        </View>
        <Divider style={styles.cardDivider} />
        <TouchableWithoutFeedback
          onPress={() => setIsBusinessOverviewOpen(!isBusinessOverviewOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardSection} appearance="hint">
              BUSINESS OVERVIEW
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    { rotate: isBusinessOverviewOpen ? "180deg" : "0deg" },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isBusinessOverviewOpen}>
          <View style={styles.flexRow}>
            <View
              style={{
                ...styles.flexRow,
                justifyContent: "flex-start",
                flex: 2,
              }}
            >
              {errors.error !== "" && (
                <Text
                  style={{
                    ...styles.error,
                    paddingHorizontal: "1%",
                    paddingVertical: "3%",
                  }}
                >
                  {errors.error}
                </Text>
              )}
            </View>
            <View
              style={{
                ...styles.flexRow,
                justifyContent: "flex-end",
              }}
            >
              {canEdit && !isLoanHardRejected && (
                <Button
                  style={styles.gradientButton}
                  onPress={() => setIsEditing(true)}
                >
                  Edit Data
                </Button>
              )}
            </View>
            <View
              style={{
                ...styles.flexRow,
                justifyContent: "flex-end",
                marginRight: "6px",
              }}
            >
              <Button
                style={styles.gradientButton}
                onPress={() =>
                  uploadDocument((doc: any) => {
                    uploadGoodStandingCertificate(doc.file);
                  })
                }
              >
                Upload Certificate
              </Button>
            </View>
          </View>
          <div className="text-red-500">{validationErrors}</div>
          <TableDisplay
            data={businessOverviewDataDisplay.slice(0, 6)}
            itemsPerRow={6}
            isEditing={isEditing}
            rawData={editData}
            setRawData={setEditData}
          />
          <TableDisplay
            data={businessOverviewDataDisplay.slice(6)}
            itemsPerRow={3}
            isEditing={isEditing}
            rawData={editData}
            setRawData={setEditData}
          />
          {isEditing && (
            <View style={{ ...styles.container, alignItems: "center" }}>
              <View style={styles.flexRow}>
                <Button
                  style={styles.gradientButton}
                  onPress={() => saveEdits()}
                >
                  Save Changes
                </Button>
                <Button
                  style={styles.cancelButton}
                  onPress={() => setIsEditing(false)}
                  children={() => (
                    <Text style={styles.cancelButtonText}>Cancel</Text>
                  )}
                />
              </View>
            </View>
          )}
        </Collapsible>
        <Divider style={styles.cardDivider} />
        <TouchableWithoutFeedback
          onPress={() =>
            setIsGoodStandingCertificatesOpen(!isGoodStandingCertificatesOpen)
          }
        >
          <View style={styles.flexRowBetween}>
            <Text
              style={{ ...styles.cardSection, ...styles.flexRowCenter }}
              appearance="hint"
            >
              {"GOOD STANDING CERTIFICATES "}
              <OnMouseClickTooltip text="Good standing certificates appear here if they are found by Wolters Kluwer manual document search and/or uploaded by Underwriter." />
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    {
                      rotate: isGoodStandingCertificatesOpen
                        ? "180deg"
                        : "0deg",
                    },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isGoodStandingCertificatesOpen}>
          <TableDisplay
            data={goodStandingCertificatesDisplay}
            itemsPerRow={3}
          />
        </Collapsible>
        <Divider style={styles.cardDivider} />
        <TouchableWithoutFeedback
          onPress={() => setIsTaxDetailsOpen(!isTaxDetailsOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardSection} appearance="hint">
              TAX DETAILS
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [{ rotate: isTaxDetailsOpen ? "180deg" : "0deg" }],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isTaxDetailsOpen}>
          <TableDisplay data={taxDetailsDataDisplay} itemsPerRow={4} />
        </Collapsible>
        <Divider style={styles.cardDivider} />
        <TouchableWithoutFeedback
          onPress={() => setIsExistingLendersOpen(!isExistingLendersOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text
              style={{ ...styles.cardSection, ...styles.flexRowCenter }}
              appearance="hint"
            >
              {"EXISTING LENDERS "}
              <OnMouseClickTooltip text="This is applicant input." />
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [{ rotate: isTaxDetailsOpen ? "180deg" : "0deg" }],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isExistingLendersOpen}>
          {existingLendersDataDisplay
            .map((lender: any, idx: number) => idx)
            .filter((idx: number) => idx % 8 === 0)
            .map((idx: number) => (
              <View key={`existing-lender-${idx}`}>
                <TableDisplay
                  data={existingLendersDataDisplay.slice(idx, idx + 8)}
                  itemsPerRow={4}
                />
                <Divider style={styles.cardDivider} />
              </View>
            ))}
        </Collapsible>

        {otherDocuments.length > 0 ? (
          <View>
            <Divider style={styles.cardDivider} />
            <TouchableWithoutFeedback
              style={styles.flexRowBetween}
              onPress={() => setIsOtherOpen(!isOtherOpen)}
            >
              <View style={styles.flexRowBetween}>
                <Text style={styles.cardSection} appearance="hint">
                  OTHER DOCUMENTS
                </Text>
                <Icon
                  style={[
                    styles.chevronButton,
                    {
                      transform: [{ rotate: isOtherOpen ? "180deg" : "0deg" }],
                    },
                  ]}
                  name="chevron-down-outline"
                />
              </View>
            </TouchableWithoutFeedback>
            <Divider style={styles.cardDivider} />
            <Collapsible collapsed={!isOtherOpen}>
              <TableDisplay itemsPerRow={4} data={otherDocuments} />
            </Collapsible>
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};
