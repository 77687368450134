import {
  Button,
  CheckBox,
  Icon,
  Text,
  useStyleSheet,
} from "@ui-kitten/components";
import { forEach } from "lodash";
import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";

import { themedStyles } from "./themedStyles";

type Props = {
  question: string;
  options: Array<string>;
  answer: Array<string>;
  initial: Array<string>;
  setAnswer: Function;
  displayNextActionButton: boolean;
  disabledNextActionButton: boolean;
  onContinue: Function;
};

type tOptions = {
  [key: string]: boolean;
};

const MultiSelectCheckBoxes: React.FC<Props> = ({
  question,
  options = ["YES", "NO"],
  initial,
  answer,
  setAnswer,
  displayNextActionButton,
  disabledNextActionButton = false,
  onContinue,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const defaultOptions: tOptions = {};
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);

  useEffect(() => {
    let answer: Array<string> = [];
    for (const option in selectedOptions) {
      if (selectedOptions[option]) {
        answer.push(option);
      }
    }
    setAnswer(answer);
  }, [selectedOptions]);

  useEffect(() => {
    let tmp = selectedOptions;
    initial.map((ans) => {
      tmp = { ...tmp, [ans]: true };
    });
    setSelectedOptions(tmp);
  }, [initial]);

  return (
    <>
      <View>
        <Text style={styles.question}>{question}</Text>
        <View
          style={
            width > 992
              ? styles.optionsContainer
              : styles.optionsContainerMobile
          }
        >
          {options.map((option: string) => (
            <CheckBox
              key={option}
              style={styles.checkbox}
              checked={selectedOptions && selectedOptions[option]}
              onChange={() =>
                setSelectedOptions({
                  ...selectedOptions,
                  [option]: !selectedOptions[option],
                })
              }
              children={() => (
                <Text
                  style={
                    width > 576 ? styles.inputText : styles.inputTextMobile
                  }
                >
                  {option}
                </Text>
              )}
            />
          ))}
        </View>
      </View>
      {displayNextActionButton ? (
        <Button
          disabled={disabledNextActionButton}
          style={
            disabledNextActionButton
              ? styles.disabledActionButton
              : styles.nextActionButton
          }
          status="basic"
          onPress={() => {
            onContinue(answer);
          }}
          accessoryRight={ArrowIcon}
          children={() => (
            <Text
              style={
                disabledNextActionButton
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              continue{"  "}
            </Text>
          )}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MultiSelectCheckBoxes;
