import React from "react";
import { Helmet } from "react-helmet";
import { Platform } from "react-native";

import config from "../../config";

const Seo: React.FC<any> = () => (
  <>
    {Platform.OS === "web" && (
      <>
        {!!config.gtm_id && (
          <>
            <Helmet>
              <script>
                {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${config.gtm_id}');
                `}
              </script>
            </Helmet>
            <noscript>
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${config.gtm_id}`}
                height="0"
                width="0"
                style={{ display: "none", visibility: "hidden" }}
              />
            </noscript>
          </>
        )}
        {!!config.facebook_domain_verification && (
          <Helmet>
            <meta
              name="facebook-domain-verification"
              content={`${config.facebook_domain_verification}`}
            />
          </Helmet>
        )}
        {!!config.gtag_id && (
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${config.gtag_id}`}
            />
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${config.gtag_id}');
              `}
            </script>
          </Helmet>
        )}
        {!!config.facebook_pixel_id && (
          <>
            <Helmet>
              <script>
                {`
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${config.facebook_pixel_id}');
                `}
              </script>
            </Helmet>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                src={`https://www.facebook.com/tr?id=${config.facebook_pixel_id}&ev=Lead&noscript=1`}
              />
            </noscript>
          </>
        )}
        {!!config.linkedin_insight_id && (
          <>
            <Helmet>
              <script type="text/javascript">
                {`
                  _linkedin_partner_id = "${config.linkedin_insight_id}";
                  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                `}
              </script>
              <script type="text/javascript">
                {`
                  (function(l) {
                  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                  window.lintrk.q=[]}
                  var s = document.getElementsByTagName("script")[0];
                  var b = document.createElement("script");
                  b.type = "text/javascript";b.async = true;
                  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                  s.parentNode.insertBefore(b, s);})(window.lintrk);
                `}
              </script>
            </Helmet>
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src={`https://px.ads.linkedin.com/collect/?pid=${config.linkedin_insight_id}&fmt=gif`}
              />
            </noscript>
          </>
        )}
      </>
    )}
  </>
);

export default Seo;
