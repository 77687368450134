import * as eva from "@eva-design/eva";
import {
  Modal,
  Text,
  ThemeProvider,
  useStyleSheet,
  Icon,
  Button,
} from "@ui-kitten/components";
import React from "react";
import { View, ScrollView, Dimensions } from "react-native";

import { themedStyles } from "./themedStyles";

export const EquifaxFinalPullRetry: React.FC<any> = ({
  isOpen,
  closeModal,
  fieldsToBeUpdated = [],
  confirmFinalBureauRetry,
}) => {
  const { width, height } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const CloseIcon = (props: any) => <Icon {...props} name="close" />;

  return (
    <Modal
      visible={isOpen}
      onBackdropPress={() => closeModal()}
      style={{
        ...styles.equifaxModalContainer,
        maxWidth: width * 0.6,
        maxHeight: height * 0.8,
      }}
      backdropStyle={styles.backdrop}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.equifaxModalHeaderContainer}>
          <Button
            appearance="ghost"
            status="basic"
            accessoryRight={CloseIcon}
            onPress={() => closeModal()}
          />
        </View>
        <ScrollView style={{ flex: 1, maxHeight: height * 0.5 }}>
          <View style={styles.equifaxFieldList}>
            <Text style={styles.equifaxModalText}>
              This is the final Equifax report pull retry. The applicant will
              receive an email indicating they have 24 hours to change the
              following information:
            </Text>
            {fieldsToBeUpdated.map((item: any, idx: number) => {
              return (
                <View key={`item-${idx}`} style={styles.equifaxField}>
                  <Text style={styles.equifaxFieldText}>• {item}</Text>
                </View>
              );
            })}
            <Text style={styles.equifaxModalText}>
              After 24 hours, the applicant's profile will lock and the report
              will automatically re-pull.
            </Text>
          </View>
        </ScrollView>
        <View style={styles.center}>
          <Button
            style={
              width > 576
                ? styles.nextActionButton
                : styles.nextActionButtonMobile
            }
            status="basic"
            onPress={() => {
              confirmFinalBureauRetry();
              closeModal();
            }}
            children={() => (
              <Text style={styles.nextActionButtonText}>Confirm</Text>
            )}
          />
        </View>
      </ThemeProvider>
    </Modal>
  );
};
