import {
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Button,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";

import Header from "../../components/Header";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { ThemeContext } from "./../../../theme-context";
import { printComponent } from "./../../utils";
import InterestAndAgencyDistribution from "./InterestAndAgencyDistribution";
import LoanBreakdownAndDesignation from "./LoanBreakdownAndDesignation";
import Markets from "./Markets";
import RunningBalance from "./RunningBalance";
import { themedStyles } from "./themedStyles";
type Props = object;

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);
const InvestorDashboard: React.FC<Props> = (props) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore } = useStore();

  const themeContext = React.useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const formatDisplay = (value: string, format: any) => {
    if (value === "?") return "?";
    if (!value) return "";
    if (format === "$")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(value));
    if (format === "%") return Number(value).toFixed(2) + "%";
    if (format === "days")
      return value + " day" + (Number(value) > 1 ? "s" : "");

    return value;
  };

  const [lastUpdate, setLastUpdate] = useState("");
  const [quickStats, setQuickStats] = useState([
    {
      name: "CURRENT BALANCE",
      value: "1234567.510",
      type: "$",
      nameInResponse: "currentBalance",
    },
    {
      name: "NO. OF LOANS",
      value: "17",
      change: "",
      showChange: true,
      nameInResponse: "numberOfLoans",
    },
    {
      name: "AVERAGE INTEREST",
      value: "11.510",
      type: "%",
      nameInResponse: "averageInterest",
    },
    {
      name: "AVERAGE MATURITY",
      value: "80",
      type: "days",
      nameInResponse: "averageMaturity",
    },
  ]);

  const [runningBalanceData, setRunningBalanceData] = useState<any>([]);

  const [loanBreakdownData, setLoanBreakdownData] = useState([]);
  const [loanDistributionData, setLoanDistributionData] = useState([]);
  const minInterest = 8;
  const maxInterest = 15;
  const [interestBreakdownData, setInterestBreakdownData] = useState(
    new Array(maxInterest - minInterest + 1).fill(0)
  );

  const [marketsData, setMarketsData] = useState([]);

  const [agenciesData, setAgenciesData] = useState([]);

  useEffect(() => {
    themeContext.resetTheme();
    setLoading(true);
    api.getInvestorDashboardData(authStore?.accessToken || "").then((data) => {
      const resp = data.response.entities;

      //@ts-ignore
      setLastUpdate(new Date(Date.parse(resp.lastUpdate)).toDateString());

      setQuickStats(
        quickStats.map((stat) => ({
          ...stat,
          value: resp.quickStats[stat.nameInResponse],
          change: !stat.showChange ? "" : resp.change[stat.nameInResponse],
        }))
      );

      setRunningBalanceData(
        Object.keys(resp.runningBalance).map((key) => {
          const year = key.slice(0, 4);
          const quarter = key.slice(5);
          const outstanding: any = [];
          const repaid: any = [];

          resp.runningBalance[key].forEach((month: any) => {
            outstanding.push(month.outstanding);
            repaid.push(month.repaid);
          });
          return { year, quarter, outstanding, repaid };
        })
      );

      setMarketsData(resp.markets);
      setAgenciesData(resp.agencies);
      setLoanBreakdownData(resp.loansBreakdown);
      setLoanDistributionData(resp.distribution);
      const interestBreakdown = new Array(maxInterest - minInterest + 1).fill(
        0
      );
      resp.interestRates.forEach((val: number) => {
        if (val - minInterest >= 0 && val <= maxInterest)
          interestBreakdown[val - minInterest] +=
            100.0 / resp.interestRates.length;
      });
      setInterestBreakdownData(interestBreakdown);
      setLoading(false);
    });
  }, []);

  const dashboard = React.useRef<any>();

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header theme="light" />
        {loading ? (
          <View style={styles.flexRow}>
            <LoadingIndicator />
          </View>
        ) : (
          <View
            ref={dashboard}
            testID="investor-dashboard"
            style={{
              ...styles.investorDashboardContainer,
              ...(width > 1024 ? {} : { padding: 10 }),
            }}
          >
            <View style={styles.investorHeaderText}>
              <Text style={styles.overviewText}>
                Overview: {authStore?.user?.businessName || ""} x OppZo
              </Text>
              <Text style={styles.lastUpdatedText} appearance="hint">
                Your investments with OppZo as of{" "}
                <Text
                  style={{ ...styles.lastUpdatedText, fontWeight: "600" }}
                  status="primary"
                  appearance="hint"
                >
                  {lastUpdate
                    .toLocaleString()
                    .replace(
                      " " + new Date(lastUpdate).getFullYear(),
                      ", " + new Date(lastUpdate).getFullYear()
                    )}
                </Text>
              </Text>
            </View>
            <View
              style={{
                ...styles.quickStatsContainer,
                ...(width > 1024 ? {} : { flexDirection: "column" }),
              }}
            >
              {quickStats.map((stat, idx) => (
                <View
                  style={{
                    ...styles.quickStatBlock,
                    ...(width > 1024
                      ? { borderRight: "1px solid #e7e7e7" }
                      : {
                          borderRight: "none",
                          borderBottom: "1px solid #e7e7e7",
                        }),
                    ...(idx + 1 === quickStats.length
                      ? { borderRight: 0, borderBottom: 0 }
                      : {}),
                  }}
                  key={`quick-stat-${idx}`}
                >
                  <Text style={styles.quickStatNameText}>{stat.name}</Text>
                  <View style={styles.flexRow}>
                    <Text style={styles.quickStatValueText}>
                      {formatDisplay(`${stat.value}`, stat.type)}
                    </Text>
                    {!!stat.change && (
                      <Text
                        style={
                          //@ts-ignore
                          stat.change > 0
                            ? styles.positiveChangeText
                            : styles.negativeChangeText
                        }
                      >
                        {
                          //@ts-ignore
                          stat.change > 0 ? "+" : ""
                        }
                        {stat.change}
                      </Text>
                    )}
                  </View>
                </View>
              ))}
            </View>
            <View style={width > 1024 ? styles.flexRowStretch : {}}>
              <View
                style={{
                  ...styles.runningBalanceContainer,
                  ...(width > 1024 ? {} : { flex: undefined }),
                }}
              >
                <RunningBalance data={runningBalanceData} />
              </View>
              <View
                style={{
                  ...styles.marketsContainer,
                  ...(width > 1024 ? {} : { flex: undefined }),
                }}
              >
                <Markets
                  data={marketsData
                    .slice(0)
                    //@ts-ignore
                    .sort((a, b) => b.amount - a.amount)}
                />
              </View>
            </View>
            <View style={width > 1024 ? styles.flexRowStretch : {}}>
              <View
                style={{
                  ...styles.agencyDistributionContainer,
                  ...(width > 1024 ? {} : { flex: undefined }),
                }}
              >
                <InterestAndAgencyDistribution
                  agenciesData={agenciesData}
                  interestData={interestBreakdownData}
                  maxInterest={maxInterest}
                  minInterest={minInterest}
                />
              </View>
              <View
                style={{
                  ...styles.loanDistributionContainer,
                  ...(width > 1024 ? {} : { flex: undefined }),
                }}
              >
                <LoanBreakdownAndDesignation
                  breakdownData={loanBreakdownData}
                  distributionData={loanDistributionData}
                />
              </View>
            </View>
          </View>
        )}
        {!loading && (
          <Button
            style={styles.printButton}
            onPress={() => printComponent(dashboard)}
            appearance="outline"
            status="info"
          >
            Export to PDF
          </Button>
        )}
      </ScrollView>
    </Layout>
  );
};

export default InvestorDashboard;
