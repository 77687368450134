import { useLinkTo } from "@react-navigation/native";
import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Button,
  Spinner,
  Datepicker,
  CalendarViewModes,
  CheckBox,
  NativeDateService,
} from "@ui-kitten/components";

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  createRef,
} from "react";
import { View, TouchableOpacity, Dimensions } from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { checkMboExistence } from "../../services/api";
import { useStore } from "../../stores";
import { unmaskPhoneNumber } from "./../../utils";

import {
  commonPasswordsList,
  MINIMUM_PASSWORD_LENGTH,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;
const formatDateService = new NativeDateService("en", { format: "MM.DD.YYYY" });

const AccountInformationStep: React.FC<any> = ({
  loading,
  setLoading,
  formData,
  setFormData,
  onContinue,
  errorMessages,
  setErrorMessages,
  onSubmitForm,
  isMBOSignUp,
  isPrimaryApplicant,
}) => {
  const { width, height } = Dimensions.get("window");
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);

  const { authStore } = useStore();

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [isFocuseGroupchecked, setFocusGroupChecked] = useState(false);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const MailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar-outline" />
  );
  const PhoneIcon = (props: any) => <Icon {...props} name="phone-outline" />;
  const BriefcaseIcon = (props: any) => (
    <Icon {...props} name="briefcase-outline" />
  );
  const LockIcon = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText(!securedText)}>
      <Icon {...props} name={securedText ? "lock-outline" : "unlock-outline"} />
    </TouchableOpacity>
  );

  const isNextActionButtonDisabled = useCallback(() => {
    return (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phoneNumber ||
      formData.phoneNumber.split("").filter((x: string) => !isNaN(Number(x)))
        .length < 11 ||
      !formData.birthday ||
      !formData.password1 ||
      !formData.password2 ||
      !formData.businessName
    );
  }, [formData]);

  const isPasswordCommon = (password: string) => {
    return !!commonPasswordsList.find((item: string) => item === password);
  };
  const isPasswordNotAcceptable = (password: string) => {
    const specialRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const lettersRegex = /[A-Za-z]/;
    const numbersRegex = /\d/g;
    return !(
      specialRegex.test(password) &&
      lettersRegex.test(password) &&
      numbersRegex.test(password) &&
      password.length >= MINIMUM_PASSWORD_LENGTH
    );
  };

  const lastNameRef = useRef<Input>(null);
  const businessNameRef = useRef<Input>(null);
  const workEmailRef = useRef<Input>(null);
  const birthdayRef = useRef<Input>(null);
  const phoneNumberRef = createRef<any>();
  const passwordRef = useRef<Input>(null);
  const repeatPasswordRef = useRef<Input>(null);

  const [securedText, setSecuredText] = useState(true);

  useEffect(() => {
    setErrorMessages({
      ...errorMessages,
      password1: !formData.password1
        ? ""
        : isPasswordCommon(formData.password1)
        ? "This password is too common."
        : formData.password1.length < MINIMUM_PASSWORD_LENGTH
        ? "This password is too short"
        : isPasswordNotAcceptable(formData.password1)
        ? "This password is not acceptable."
        : "",
      password2:
        formData.password2 && formData.password2 !== formData.password1
          ? "Passwords does not match"
          : "",
      phoneNumberInput:
        formData.phoneNumber &&
        formData.phoneNumber.split("").filter((x: string) => !isNaN(Number(x)))
          .length < 11
          ? "Please input your phone number in international format +1 (XXX) XXX-XXXX"
          : errorMessages?.phoneNumber,
      phoneNumber:
        formData.phoneNumber &&
        formData.phoneNumber.split("").filter((x: string) => !isNaN(Number(x)))
          .length < 11
          ? ""
          : errorMessages?.phoneNumber,
    });
  }, [formData.password1, formData.password2, formData.phoneNumber]);

  useEffect(() => {
    const num = formData.phoneNumber;
    let prt = "";
    if (num.length > 0) {
      if (num.length <= 3) {
        prt = `(${num})`;
      } else if (num.length <= 6) {
        prt = `(${num.slice(0, 3)}) ${num.slice(3, -1)}${num.slice(-1)}`;
      } else {
        prt = `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(
          6,
          -1
        )}${num.slice(-1)}`;
      }
    }
    setFormattedPhoneNumber(prt);
  }, [formData.phoneNumber]);

  const disableButton =
    isNextActionButtonDisabled() ||
    isPasswordCommon(formData.password1) ||
    isPasswordNotAcceptable(formData.password1) ||
    formData.password1 !== formData.password2 ||
    !isFocuseGroupchecked;

  const continuePress = async () => {
    setLoading(true);
    // Secondary Business Owners Sign Up
    if (!isPrimaryApplicant) {
      const updatedFormData = {
        ...formData,
        isPrimaryApplicant: false,
      };
      console.log("FORM SUBMIT IN MBO SIGN UP", updatedFormData);
      onSubmitForm(updatedFormData, false, true);
      return;
    } else {
      // Main Applicant Sign Up
      console.log("---BEGINNING REGISTRATION---");
      const response = await checkMboExistence({
        email: formData.email,
      });

      if (response.response.entities["exists"]) {
        onSubmitForm(
          {
            ...formData,
            ...response.response.entities["formData"],
          },
          false,
          true
        );
      } else if (response.response.entities["userExists"]) {
        setErrorMessages({
          ...errorMessages,
          email: "A user is already registered with this e-mail address.",
        });
      } else {
        onSubmitForm({ ...formData, isPrimaryApplicant: true }, false, true);
        onContinue();
      }
      setLoading(false);
    }
  };

  return (
    <View style={width > 992 ? styles.register : styles.registerMobile}>
      <Text style={width > 576 ? styles.header : styles.headerMobile}>
        Create an account
      </Text>
      <Text style={width > 576 ? styles.subHeader : styles.subHeaderMobile}>
        Already registered?{" "}
        <Text
          status="info"
          style={
            width > 576
              ? {
                  ...styles.link,
                  fontSize: 18,
                  fontFamily: "Montserrat_500Medium",
                }
              : {
                  ...styles.link,
                  fontSize: 13,
                  fontFamily: "Montserrat_500Medium",
                }
          }
          onPress={() => linkTo("/auth/log-in")}
        >
          Login
        </Text>
        .
      </Text>

      <Text style={{ marginBottom: 40 }}>
        {errorMessages?.nonFieldErrors && (
          <Text
            category="h6"
            style={width > 576 ? styles.error : styles.errorMobile}
          >
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>

      <View>
        <View style={width > 992 ? styles.formContainerFlexRow : {}}>
          <View style={{ flex: 1 }}>
            <Text>
              {errorMessages?.firstName && (
                <Text
                  category="h6"
                  style={width > 576 ? styles.error : styles.errorMobile}
                >
                  {errorMessages?.firstName}
                </Text>
              )}
            </Text>
            <Input
              style={width > 576 ? styles.input : styles.inputMobile}
              textStyle={
                width > 576 ? styles.inputText : styles.inputTextMobile
              }
              placeholder="First Name"
              autoCapitalize="words"
              autoCorrect={false}
              textContentType="name"
              autoCompleteType="off"
              value={formData.firstName}
              onChangeText={(text) =>
                setFormData({ ...formData, firstName: text })
              }
              onKeyPress={(event: any) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  lastNameRef.current?.focus();
                }
              }}
              onSubmitEditing={() => lastNameRef.current?.focus()}
              returnKeyType="next"
              testID="FirstNameField"
            />
          </View>
          <View style={{ flex: 1 }}>
            <Text>
              {errorMessages?.lastName && (
                <Text
                  category="h6"
                  style={width > 576 ? styles.error : styles.errorMobile}
                >
                  {errorMessages?.lastName}
                </Text>
              )}
            </Text>
            <Input
              disabled={isMBOSignUp}
              ref={lastNameRef}
              style={width > 576 ? styles.input : styles.inputMobile}
              textStyle={
                width > 576 ? styles.inputText : styles.inputTextMobile
              }
              placeholder="Last Name"
              autoCapitalize="words"
              autoCorrect={false}
              textContentType="familyName"
              autoCompleteType="off"
              value={formData.lastName}
              onChangeText={(text) =>
                setFormData({ ...formData, lastName: text })
              }
              onKeyPress={(event: any) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  businessNameRef.current?.focus();
                }
              }}
              onSubmitEditing={() => businessNameRef.current?.focus()}
              returnKeyType="next"
              testID="LastNameField"
            />
          </View>
        </View>

        <Text>
          {errorMessages?.businessName && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessName}
            </Text>
          )}
        </Text>
        <Input
          ref={businessNameRef}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Business Name"
          autoCorrect={false}
          textContentType="organizationName"
          autoCompleteType="off"
          value={formData.businessName}
          accessoryRight={BriefcaseIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, businessName: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              workEmailRef.current?.focus();
            }
          }}
          onSubmitEditing={() => workEmailRef.current?.focus()}
          returnKeyType="next"
          testID="CompanyField"
        />
        <Text>
          {errorMessages?.email && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.email}
            </Text>
          )}
        </Text>
        <Input
          ref={workEmailRef}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Work email address"
          autoCorrect={false}
          textContentType="emailAddress"
          autoCompleteType="email"
          value={formData.email}
          accessoryRight={MailIcon}
          onChangeText={(text) => setFormData({ ...formData, email: text })}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              phoneNumberRef.current?._inputElement.focus();
            }
          }}
          onSubmitEditing={() => phoneNumberRef.current?._inputElement.focus()}
          returnKeyType="next"
          testID="WorkEmailField"
        />
        <Text>
          {errorMessages?.phoneNumberInput && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.phoneNumberInput}
            </Text>
          )}
        </Text>
        <TextInputMask
          ref={phoneNumberRef}
          style={width > 576 ? styles.inputMask : styles.inputMaskMobile}
          placeholder="Business Phone Number"
          autoCorrect={false}
          value={formattedPhoneNumber}
          type="cel-phone"
          options={{
            withDDD: true,
            dddMask: "+1 (999) 999-9999",
          }}
          onChangeText={(text) => {
            if (text.length <= 17) {
              setFormData({
                ...formData,
                phoneNumber: text.replace(/[^0-9\+]/g, ""),
              });
            }
          }}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              birthdayRef.current?.focus();
            }
          }}
          onSubmitEditing={() => birthdayRef.current?.focus()}
        />

        <Text>
          {errorMessages?.birthday && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.birthday}
            </Text>
          )}
        </Text>

        <Datepicker
          ref={birthdayRef}
          style={styles.input}
          accessoryRight={CalendarIcon}
          placeholder={() => (
            <Text style={styles.datePickerPlaceholderText}>Birthday</Text>
          )}
          startView={CalendarViewModes.YEAR}
          date={formData.birthday ? new Date(formData.birthday) : ""}
          min={new Date("1900-01-01")}
          dateService={formatDateService}
          onSelect={(nextDate) =>
            setFormData({
              ...formData,
              birthday: `${1900 + nextDate.getYear()}-${
                nextDate.getMonth() + 1
              }-${nextDate.getDate()}`,
            })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              passwordRef.current?.focus();
            }
          }}
          onSubmitEditing={() => passwordRef.current?.focus()}
        />

        <Text>
          {errorMessages?.password1 && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.password1}
            </Text>
          )}
        </Text>
        <Text style={styles.helptext}>
          Your password must be at least 8 characters long, with at least one
          letter, one number, and one symbol.
        </Text>
        <Input
          ref={passwordRef}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Password (min 8 characters)"
          autoCompleteType="password"
          textContentType="newPassword"
          autoCorrect={false}
          value={formData.password1}
          secureTextEntry={securedText}
          accessoryRight={LockIcon}
          onChangeText={(text) => setFormData({ ...formData, password1: text })}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              repeatPasswordRef.current?.focus();
            }
          }}
          onSubmitEditing={() => repeatPasswordRef.current?.focus()}
          returnKeyType="next"
          testID="PasswordField"
        />
        <Text>
          {errorMessages?.password2 && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.password2}
            </Text>
          )}
        </Text>
        <Input
          ref={repeatPasswordRef}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Repeat password"
          autoCompleteType="password"
          textContentType="newPassword"
          autoCorrect={false}
          value={formData.password2}
          secureTextEntry={securedText}
          accessoryRight={LockIcon}
          onChangeText={(text) => setFormData({ ...formData, password2: text })}
          returnKeyType="go"
          testID="RepeatPasswordField"
        />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            padding: "5px",
          }}
        >
          <CheckBox
            checked={isFocuseGroupchecked}
            onChange={(nextChecked) => {
              setFocusGroupChecked(nextChecked);
              setFormData({
                ...formData,
                hasAgreedToParticipateInFocusGroup: nextChecked,
              });
            }}
            style={{ marginRight: "8px" }}
          ></CheckBox>
          <Text
            style={
              width > 576
                ? styles.link
                : {
                    ...styles.link,
                    lineHeight: 18,
                    fontSize: 12,
                  }
            }
            onPress={() =>
              window.open(
                "https://drive.google.com/file/d/1e9Hq3CGoAbmGOoshOPMcPoDTxsAvLQEC/view?usp=sharing"
              )
            }
          >
            Agree to Focus group terms and conditions
          </Text>
        </View>
      </View>

      <Text style={width > 576 ? styles.terms : styles.termsMobile}>
        By clicking "Create Account", you confirm receipt of and agreed to be
        bound by the{" "}
        <Text
          status="info"
          style={
            width > 576
              ? styles.link
              : {
                  ...styles.link,
                  lineHeight: 18,
                  fontSize: 12,
                }
          }
          onPress={() => linkTo("/auth/terms-of-service")}
        >
          Terms of Use{" "}
        </Text>
        and{" "}
        <Text
          status="info"
          style={
            width > 576
              ? styles.link
              : {
                  ...styles.link,
                  lineHeight: 18,
                  fontSize: 12,
                }
          }
          onPress={() => linkTo("/auth/privacy-policy")}
        >
          Privacy Policy{" "}
        </Text>
        and consent to the receipt of electronic communications (including
        required notices) from us as described therein.
      </Text>

      <Button
        disabled={disableButton}
        style={
          disableButton
            ? width > 576
              ? styles.disabledActionButton
              : styles.disabledActionButtonMobile
            : width > 576
            ? styles.nextActionButton
            : styles.nextActionButtonMobile
        }
        status="basic"
        onPress={() => continuePress()}
        accessoryRight={loading ? LoadingIndicator : ArrowIcon}
        children={() => (
          <Text
            style={
              disableButton
                ? styles.disabledActionButtonText
                : styles.nextActionButtonText
            }
          >
            create account{"  "}
          </Text>
        )}
      />
    </View>
  );
};

export default AccountInformationStep;
