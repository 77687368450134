import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Layout,
  Icon,
  Text,
  useStyleSheet,
  Divider,
  Modal,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, TouchableOpacity } from "react-native";

import { getLoanSummary } from "../../services/api";
import { useStore } from "../../stores";
import RequestModal from "../RequestModal";
import LoanSettingsModal from "./LoanSettingsModal";
import VerificationChecklistModal from "./VerificationChecklistModal";
import { themedStyles } from "./themedStyles";

const UnderwriterPortalHeader: React.FC<any> = ({
  activeTab,
  applicationId,
  investorView,
  editTimestampValue = null,
}) => {
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { authStore, loanStore } = useStore();
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const tabs = [
    { display: "Summary", route: "summary" },
    { display: "Collateral and Reports", route: "collateral" },
    { display: "Risk Flags", route: "risk-flags" },
    { display: "Decision", route: "decision" },
    { display: "Credit Files", route: "credit-files" },
  ];

  const [applicantName, setApplicantName] = useState("Applicant Name");
  const [businessName, setBusinessName] = useState("Business Name");
  const [applicantId, setApplicantId] = useState(1);
  const [openRequestSuccessModal, setOpenRequestSuccessModal] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isLoanSettingsModalOpen, setIsLoanSettingsModalOpen] = useState(false);
  const [isLoanHardRejected, setIsLoanHardRejected] = useState(false);

  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      getLoanSummary(authStore?.accessToken, applicationId).then((resp) => {
        const response = resp.response.entities;
        loanStore.createOrUpdateLoanApplication(response);

        setApplicantName(`${response["firstName"]} ${response["lastName"]}`);
        setBusinessName(response["businessName"]);
        setApplicantId(response["applicantId"]);
        setIsLoanHardRejected(
          response["status"].toLowerCase().includes("reject") &&
            !response["status"].toLowerCase().includes("soft")
        );
      });
    }
  }, []);

  return (
    <Layout style={styles.container}>
      <VerificationChecklistModal
        applicationId={applicationId}
        investorView={investorView}
        isOpen={isVerificationModalOpen}
        close={() => setIsVerificationModalOpen(false)}
        editTimestampValue={editTimestampValue}
      />
      <Divider />
      <View style={styles.header}>
        <View
          style={{
            ...styles.flexRow,
            flex: 0.4,
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text style={styles.textHeader}>Underwriter Portal</Text>
            <Text style={styles.welcomeMessageText} appearance="hint">
              {`Welcome ${authStore.user?.firstName} ${authStore.user?.lastName}`}
            </Text>
          </View>
          <View style={styles.verticalDivider} />
        </View>
        <View style={styles.tabContainer}>
          {tabs.map((tab) => (
            <View
              style={tab.route === activeTab ? styles.activeTab : styles.tab}
              key={tab.route}
            >
              <TouchableOpacity
                activeOpacity={1}
                onPress={() =>
                  navigation.navigate(tab.route, { applicationId })
                }
              >
                <View style={styles.tabContent}>
                  <Text
                    style={
                      tab.route === activeTab
                        ? styles.activeTabFont
                        : styles.tabFont
                    }
                  >
                    {tab.display}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          ))}
        </View>
        <View
          style={{
            ...styles.flexRow,
            flex: 0.75,
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {!investorView && !isLoanHardRejected && (
            <>
              <TouchableOpacity
                style={{ padding: 5 }}
                onPress={() => setIsLoanSettingsModalOpen(true)}
              >
                <Icon width={24} height={24} name="settings-outline" />
              </TouchableOpacity>
              <Button
                style={styles.btn}
                appearance="outline"
                onPress={() => setIsRequestModalOpen(true)}
              >
                Request
              </Button>
            </>
          )}
          {!investorView && (
            <Button
              style={styles.btn}
              appearance="outline"
              onPress={() =>
                navigation.navigate("admin", {
                  screen: "requests",
                  params: { applicationId: applicationId },
                })
              }
            >
              View requests
            </Button>
          )}
          <Button
            style={{
              ...styles.btn,
              //@ts-ignore
              background:
                "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
            }}
            onPress={() => setIsVerificationModalOpen(true)}
          >
            Verification Checklist
          </Button>
        </View>
        {!investorView && (
          <>
            <RequestModal
              isOpen={isRequestModalOpen}
              setIsOpen={setIsRequestModalOpen}
              applicationId={applicationId}
              applicantName={applicantName}
              businessName={businessName}
              applicantId={applicantId}
              openRequestSuccess={setOpenRequestSuccessModal}
            />
          </>
        )}
        <LoanSettingsModal
          isOpen={isLoanSettingsModalOpen}
          setIsOpen={setIsLoanSettingsModalOpen}
          applicationId={applicationId}
        />
        <Modal
          visible={openRequestSuccessModal}
          backdropStyle={styles.modalBackdrop}
          onBackdropPress={() => setOpenRequestSuccessModal(false)}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>Request sent successfully.</Text>
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => setOpenRequestSuccessModal(false)}
              >
                <Text style={styles.modalButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>
    </Layout>
  );
};

export default UnderwriterPortalHeader;
