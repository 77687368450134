import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Input,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useRef, useEffect, createRef } from "react";
import { Dimensions, View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import { ConfirmPrompt } from "../../components/ConfirmPrompt";
import { uploadDocument } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const ContractHistoryStep: React.FC<any> = ({
  updateLoanData,
  goToNextStep,
  loan,
  hasEmptyRequiredFields = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const PhoneIcon = (props: any) => <Icon {...props} name="phone-outline" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="tiny" />
  );
  const [formData, setFormData] = useState<any>({
    oneYearContractBacklog: loan.oneYearContractBacklog || "",
    contractingOfficerFirstName: loan.contractingOfficerFirstName || "",
    contractingOfficerLastName: loan.contractingOfficerLastName || "",
    contractingOfficerEmail: loan.contractingOfficerEmail || "",
    contractingOfficerPhone: loan.contractingOfficerPhone || "",
    largestActiveContractsFile: loan.largestActiveContractsFile || "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>("");
  const [isConfirmPromptOpen, setIsConfirmPromptOpen] = useState(false);

  const firstNameRef = useRef<Input>(null);
  const lastNameRef = useRef<Input>(null);
  const emailRef = useRef<Input>(null);
  const phoneRef = createRef<any>();

  const allRequiredFieldFilled = () =>
    formData.oneYearContractBacklog &&
    formData.contractingOfficerFirstName &&
    formData.contractingOfficerLastName &&
    formData.contractingOfficerEmail &&
    formData.contractingOfficerPhone;

  const clickSave = () => {
    if (!allRequiredFieldFilled()) setIsConfirmPromptOpen(true);
    else saveData(formData);
  };

  const saveData = async (data: any) => {
    const finalData = {
      oneYearContractBacklog: data.oneYearContractBacklog,
      contractingOfficer: {
        firstName: data.contractingOfficerFirstName,
        lastName: data.contractingOfficerLastName,
        email: data.contractingOfficerEmail,
        phoneNumber: data.contractingOfficerPhone,
      },
      largestActiveContractsFile: data.largestActiveContractsFile,
    };
    setLoading(true);
    const res = await updateLoanData(finalData);
    setLoading(false);
    console.log(res);
    if (res?.ok) goToNextStep(allRequiredFieldFilled());
    else setErrors(res?.errors);
  };

  useEffect(() => {
    const newFormData = { ...formData };
    Object.keys(formData).forEach(
      (key) => (newFormData[key] = loan[key] || formData[key])
    );
    setFormData(newFormData);
  }, [loan]);

  return (
    <View>
      <Text category="h6" style={styles.error}>
        {errors ? JSON.stringify(errors) : ""}
      </Text>

      <Text style={styles.inputLabel}>Contracting Officer contact info</Text>
      <View style={styles.flexRow}>
        <Input
          ref={firstNameRef}
          style={
            !formData.contractingOfficerFirstName && hasEmptyRequiredFields
              ? styles.inputEmpty
              : styles.input
          }
          textStyle={styles.inputText}
          placeholder="First Name"
          status="basic"
          value={formData.contractingOfficerFirstName}
          onChangeText={(text) =>
            setFormData({ ...formData, contractingOfficerFirstName: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              lastNameRef.current?.focus();
            }
          }}
          onSubmitEditing={() => lastNameRef.current?.focus()}
        />
        <Input
          ref={lastNameRef}
          style={
            !formData.contractingOfficerLastName && hasEmptyRequiredFields
              ? styles.inputEmpty
              : styles.input
          }
          textStyle={styles.inputText}
          placeholder="Last Name"
          status="basic"
          value={formData.contractingOfficerLastName}
          onChangeText={(text) =>
            setFormData({ ...formData, contractingOfficerLastName: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              emailRef.current?.focus();
            }
          }}
          onSubmitEditing={() => emailRef.current?.focus()}
        />
      </View>
      <View style={styles.flexRow}>
        <Input
          ref={emailRef}
          style={
            !formData.contractingOfficerEmail && hasEmptyRequiredFields
              ? styles.inputEmpty
              : styles.input
          }
          textStyle={styles.inputText}
          placeholder="Work email address"
          status={
            !formData.contractingOfficerEmail && hasEmptyRequiredFields
              ? "danger"
              : "basic"
          }
          value={formData.contractingOfficerEmail}
          onChangeText={(text) =>
            setFormData({ ...formData, contractingOfficerEmail: text })
          }
          accessoryRight={EmailIcon}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              phoneRef.current?._inputElement.focus();
            }
          }}
          onSubmitEditing={() => phoneRef.current?._inputElement.focus()}
        />
      </View>
      <View style={styles.flexRow}>
        <TextInputMask
          ref={phoneRef}
          style={
            width > 576
              ? !formData.contractingOfficerPhone && hasEmptyRequiredFields
                ? styles.inputMaskEmpty
                : styles.inputMask
              : !formData.contractingOfficerPhone && hasEmptyRequiredFields
              ? styles.inputMaskMobileEmpty
              : styles.inputMaskMobile
          }
          placeholder="Phone Number"
          autoCorrect={false}
          value={formData.contractingOfficerPhone}
          type={"cel-phone"}
          options={{
            withDDD: true,
            dddMask: "+1 (999) 999-9999",
          }}
          onChangeText={(text) => {
            if (text.length <= 17) {
              setFormData({ ...formData, contractingOfficerPhone: text });
            }
          }}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              firstNameRef.current?.focus();
            }
          }}
          onSubmitEditing={() => firstNameRef.current?.focus()}
        />
      </View>

      <Text style={styles.inputLabel}>
        Copies of largest active contracts{" "}
        <Text appearance="hint">(Optional)</Text>
      </Text>
      <Button
        style={
          formData.largestActiveContractsFile
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.largestActiveContractsFile ? "success" : "basic"}
        appearance="outline"
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                largestActiveContractsFile: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />

      <Text style={styles.inputLabel}>One Year Contract Backlog</Text>
      <Button
        style={
          formData.oneYearContractBacklog
            ? styles.uploadButtonSuccess
            : hasEmptyRequiredFields
            ? styles.uploadButtonEmpty
            : styles.uploadButton
        }
        appearance="outline"
        status={
          formData.oneYearContractBacklog
            ? "success"
            : hasEmptyRequiredFields
            ? "danger"
            : "basic"
        }
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                oneYearContractBacklog: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />

      <ConfirmPrompt
        isOpen={isConfirmPromptOpen}
        close={() => setIsConfirmPromptOpen(false)}
        text="Are you sure? You still have some mandatory fields remaining which you will need to fill"
        cb={() => saveData(formData)}
      />

      <View style={width > 768 && styles.flexRow}>
        <Button
          style={
            loading ? styles.disabledActionButton : styles.nextActionButton
          }
          onPress={() => clickSave()}
          disabled={loading}
          accessoryRight={loading ? LoadingIndicator : ArrowIcon}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Save and Continue{loading ? "  " : ""}
            </Text>
          )}
        />
        <Button
          style={loading ? styles.disabledActionButton : styles.skipButton}
          status="basic"
          disabled={loading}
          onPress={() => {
            goToNextStep();
          }}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Skip for now
            </Text>
          )}
        />
      </View>
    </View>
  );
};
