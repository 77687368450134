import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Text,
  Divider,
  useStyleSheet,
  Spinner,
  Icon,
  Button,
  Input,
} from "@ui-kitten/components";
import React, { useCallback, useState, useEffect } from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { TableDisplay } from "../../components/TableDisplay";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { EquifaxFinalPullRetry } from "./EquifaxFinalPullRetry";
import { PullReportButton } from "./PullReportButton";
import { themedStyles } from "./themedStyles";

export const BusinessPrincipalReview: React.FC<any> = ({
  canEdit,
  applicationId,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [ficoReasonCodes, setFicoReasonCodes] = useState([]);
  const [editData, setEditData]: any = useState({
    ficoScore: "",
    ficoReasonCodes: "",
  });

  const defaultErrors = {
    ficoScore: "",
    ficoReasonCodes: "",
  };

  const [errors, setErrors] = useState(defaultErrors);
  const [userEmailToRequestEquifaxRepull, setUserEmailToRequestEquifaxRepull] =
    useState("");
  const [fieldsToBeUpdated, setFieldsToBeUpdated] = useState([]);
  const [isEquifaxFinalPullRetryOpen, setIsEquifaxFinalPullRetryOpen] =
    useState(false);
  const [equifaxConsumerErrorList, setEquifaxConsumerErrorList] = useState([]);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const [businessPrincipalDataDisplay, setBusinessPrincipalDataDisplay]: any =
    useState([
      {
        header: "First Name",
        displayValue: "?",
        fieldNameInResponse: "firstName",
      },
      {
        header: "Last Name",
        displayValue: "?",
        fieldNameInResponse: "lastName",
      },
      {
        header: "Equifax Consumer Report",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "equifaxConsumerReport",
      },
      {
        header: "FICO Score",
        displayValue: "?",
        fieldNameInResponse: "ficoScore",
        editable: true,
        numeric: true,
      },
      {
        header: "Personal Financial Statements",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        isOptional: true,
        fieldNameInResponse: "personalFinancialStatements",
      },
      {
        header: "KYC",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "kyc",
      },
      {
        header: "Resume",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        isOptional: true,
        fieldNameInResponse: "resume",
      },
      {
        header: "LinkedIn URL",
        displayValue: (
          <PullReportButton
            uri=""
            isLink
            text="View Applicant's LinkedIn"
            accessoryIconName="linkedin"
          />
        ),
        isLink: true,
        fieldNameInResponse: "linkedin",
      },
      {
        header: "Drivers License",
        displayValue: <PullReportButton uri="" isLink text="VIEW LICENSE" />,
        isLink: true,
        fieldNameInResponse: "driversLicense",
      },
      {
        header: "FICO Reason Codes",
        displayValue: "?",
        fieldNameInResponse: "ficoReasonCodes",
        editable: true,
        isDropdown: true,
        placeholder: "Reason Codes",
        options: ficoReasonCodes,
      },
      {
        notDisplayed: true,
        header: "",
        fieldNameInResponse: "email",
      },
    ]);

  const [
    otherBusinessPartnersDataDisplay,
    setOtherBusinessPartnersDataDisplay,
  ]: any = useState([[]]);

  const [
    otherBusinessPartnersOtherDataDisplay,
    setOtherBusinessPartnersOtherDataDisplay,
  ]: any = useState([[]]);

  const [otherData, setOtherData] = useState<any>([
    {
      header: "Adverse Legal Action",
      displayValue: "?",
      fieldNameInResponse: "adverseLegalAction",
    },
  ]);

  const [otherDocuments, setOtherDocuments]: any = useState([]);

  const [isBusinessPrincipalOpen, setIsBusinessPrincipalOpen] = useState(true);
  const [isOtherOpen, setIsOtherOpen] = useState(true);
  const [isBusinessPartnerCardOpen, setIsBusinessPartnerCardOpen]: any =
    useState(false);
  const [isBusinessPartnersResponse, setIsBusinessPartnersResponse]: any =
    useState(null);
  const [
    isBusinessPartnerOtherCardOpen,
    setIsBusinessPartnerOtherCardOpen,
  ]: any = useState(true);
  const [isOtherSectionOpen, setIsOtherSectionOpen] = useState(true);

  const { authStore } = useStore();

  const saveEdits = () => {
    let flagScore = false;
    const flagReason = false;

    if (
      !(
        editData["ficoScore"] === parseInt(editData["ficoScore"], 10).toString()
      ) &&
      editData["ficoScore"] !== ""
    ) {
      //not int, invalid
      flagScore = true;
    }

    editData["ficoReasonCodes"] = editData["ficoReasonCodes"].toString();

    console.log(editData, flagScore);
    //reason code checking here

    if (flagScore || flagReason) {
      setErrors({
        ...errors,
        ficoScore: `${flagScore ? "Invalid FICO Score." : ""}`,
        ficoReasonCodes: `${flagReason ? "Invalid FICO Reason Codes." : ""}`,
      });
      return false;
    }

    setLoading(true);

    api
      .updateLoanBusinessPrincipalEntity(
        authStore?.accessToken || "",
        applicationId,
        editData
      )
      .then((resp) => {
        console.log(resp);

        // Update display
        setBusinessPrincipalDataDisplay(
          businessPrincipalDataDisplay.map((field: any) => {
            if (!field.editable) return field;
            return {
              ...field,
              displayValue: editData[field.fieldNameInResponse],
            };
          })
        );

        setLoading(false);
        setIsEditing(false);
        setErrors(defaultErrors);
        return true;
      });
  };

  const openPullRetryModal = (email: string) => {
    setUserEmailToRequestEquifaxRepull(email);
    setIsEquifaxFinalPullRetryOpen(true);
  };

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getBusinessPrincipal(authStore?.accessToken, applicationId)
          .then((resp) => {
            console.log(resp);
            const response = resp.response.entities;
            setOtherData(
              otherData.map((obj: any) => ({
                ...obj,
                displayValue:
                  obj.fieldNameInResponse === "adverseLegalAction" ? (
                    <Input
                      style={styles.flagDescription}
                      textStyle={styles.flagDescriptionText}
                      numberOfLines={5}
                      value={
                        response.businessPrincipal[obj.fieldNameInResponse]
                      }
                      multiline
                      disabled
                    />
                  ) : (
                    response[obj.fieldNameInResponse]
                  ),
              }))
            );
            const businessPrincipalErrorList: any = [
              {
                email: response.businessPrincipal.email,
                errors: response.businessPrincipal["equifaxConsumerErrors"],
              },
            ];
            setEquifaxConsumerErrorList(businessPrincipalErrorList);
            setBusinessPrincipalDataDisplay(
              businessPrincipalDataDisplay.map((obj: any) => ({
                ...obj,
                displayValue:
                  obj.fieldNameInResponse === "ficoScore" &&
                  response.businessPrincipal[obj.fieldNameInResponse] ===
                    "None" ? (
                    ""
                  ) : obj.fieldNameInResponse === "ficoReasonCodes" ? (
                    response.businessPrincipal["equifaxConsumerErrors"].length >
                    0 ? (
                      <PullReportButton
                        isLink
                        text={
                          response[obj.fieldNameInResponse] === "error"
                            ? "REPORT FAIL"
                            : response.businessPrincipal
                                .isFinalPullEquifaxConsumer &&
                              response.businessPrincipal[
                                "equifaxConsumerErrors"
                              ].length > 0
                            ? "IN PROCESS"
                            : !response.businessPrincipal
                                .isFinalPullEquifaxConsumer &&
                              response.businessPrincipal[
                                "equifaxConsumerErrors"
                              ].length > 0
                            ? "RETRY - PULL REPORT"
                            : "PULL REPORT"
                        }
                        accessoryIconName={
                          response.businessPrincipal.isFinalPullEquifaxConsumer
                            ? "more-horizontal"
                            : "arrow-forward"
                        }
                        openModal={() =>
                          openPullRetryModal(
                            response.businessPrincipal["email"]
                          )
                        }
                      />
                    ) : (
                      response.businessPrincipal[
                        obj.fieldNameInResponse
                      ].toString()
                    )
                  ) : !obj.isLink ? (
                    response.businessPrincipal[obj.fieldNameInResponse]
                  ) : obj.fieldNameInResponse === "linkedin" ? (
                    <PullReportButton
                      uri={response.businessPrincipal[obj.fieldNameInResponse]}
                      isLink
                      text="View Applicant's LinkedIn"
                      accessoryIconName="linkedin"
                    />
                  ) : obj.fieldNameInResponse === "equifaxConsumerReport" ? (
                    <>
                      <PullReportButton
                        uri={
                          response.businessPrincipal.isFinalPullEquifaxConsumer
                            ? ""
                            : response.businessPrincipal[
                                obj.fieldNameInResponse
                              ]
                        }
                        isLink
                        text={
                          response[obj.fieldNameInResponse] === "error"
                            ? "REPORT FAIL"
                            : response.businessPrincipal
                                .isFinalPullEquifaxConsumer &&
                              response.businessPrincipal[
                                "equifaxConsumerErrors"
                              ].length > 0
                            ? "IN PROCESS"
                            : !response.businessPrincipal
                                .isFinalPullEquifaxConsumer &&
                              response.businessPrincipal[
                                "equifaxConsumerErrors"
                              ].length > 0
                            ? "RETRY - PULL REPORT"
                            : "PULL REPORT"
                        }
                        accessoryIconName={
                          response.businessPrincipal.isFinalPullEquifaxConsumer
                            ? "more-horizontal"
                            : "arrow-forward"
                        }
                        openModal={() =>
                          openPullRetryModal(
                            response.businessPrincipal["email"]
                          )
                        }
                      />
                    </>
                  ) : (
                    <PullReportButton
                      uri={response.businessPrincipal[obj.fieldNameInResponse]}
                      isLink
                      text={
                        response[obj.fieldNameInResponse] === "error"
                          ? "REPORT FAIL"
                          : obj.fieldNameInResponse === "driversLicense"
                          ? "VIEW LICENCE"
                          : "PULL REPORT"
                      }
                    />
                  ),
              }))
            );
            setIsBusinessPartnerCardOpen(
              Array(response.otherBusinessPartners.length).fill(true)
            );
            setIsBusinessPartnerOtherCardOpen(
              Array(response.otherBusinessPartners.length).fill(true)
            );
            setIsBusinessPartnersResponse(response.otherBusinessPartners);
            setOtherBusinessPartnersOtherDataDisplay(
              response.otherBusinessPartners.map(
                (partner: any, idx: number) => {
                  return otherData.map((obj: any) => ({
                    ...obj,
                    displayValue:
                      obj.fieldNameInResponse === "adverseLegalAction" ? (
                        <Input
                          style={styles.flagDescription}
                          textStyle={styles.flagDescriptionText}
                          numberOfLines={5}
                          value={partner[obj.fieldNameInResponse]}
                          multiline
                          disabled
                        />
                      ) : (
                        response[obj.fieldNameInResponse]
                      ),
                  }));
                }
              )
            );

            const newEditData: any = {};
            businessPrincipalDataDisplay
              .filter((obj: any) => obj.editable)
              .forEach((obj: any) => {
                if (
                  obj.fieldNameInResponse === "ficoScore" &&
                  response.businessPrincipal[obj.fieldNameInResponse] === "None"
                ) {
                  newEditData[obj.fieldNameInResponse] = "";
                } else {
                  newEditData[obj.fieldNameInResponse] =
                    response.businessPrincipal[obj.fieldNameInResponse];
                }
              });
            setEditData(newEditData);

            setOtherDocuments(
              response.otherDocuments.map((doc: any) => {
                return {
                  header: Object.keys(doc)[0],
                  displayValue: (
                    <PullReportButton uri={Object.values(doc)[0]} isLink />
                  ),
                  isLink: true,
                };
              })
            );
            setFicoReasonCodes(response["ficoReasonCodesOptions"]);
            setLoading(false);
          });
      }
    }, [useIsFocused()])
  );

  useEffect(() => {
    setBusinessPrincipalDataDisplay(
      businessPrincipalDataDisplay.map((obj: any) => {
        if (obj.fieldNameInResponse === "ficoReasonCodes") {
          obj.options = ficoReasonCodes;
        }
        return obj;
      })
    );
  }, [ficoReasonCodes]);

  useEffect(() => {
    if (isBusinessPartnersResponse) {
      const errorList: any = [];
      setOtherBusinessPartnersDataDisplay(
        isBusinessPartnersResponse.map((partner: any, idx: number) => {
          errorList.push({
            email: partner.email,
            errors: partner["equifaxConsumerErrors"],
          });
          return businessPrincipalDataDisplay.map((obj: any) => ({
            ...obj,
            displayValue:
              obj.fieldNameInResponse === "ficoScore" &&
              partner[obj.fieldNameInResponse] === "None" ? (
                ""
              ) : obj.fieldNameInResponse === "ficoReasonCodes" ? (
                partner["equifaxConsumerErrors"].length > 0 ? (
                  <PullReportButton
                    isLink
                    text={
                      partner.isFinalPullEquifaxConsumer &&
                      partner["equifaxConsumerErrors"].length > 0
                        ? "IN PROCESS"
                        : !partner.isFinalPullEquifaxConsumer &&
                          partner["equifaxConsumerErrors"].length > 0
                        ? "RETRY - PULL REPORT"
                        : "PULL REPORT"
                    }
                    accessoryIconName={
                      partner.isFinalPullEquifaxConsumer
                        ? "more-horizontal"
                        : "arrow-forward"
                    }
                    openModal={() => openPullRetryModal(obj.email)}
                  />
                ) : (
                  partner[obj.fieldNameInResponse].toString()
                )
              ) : !obj.isLink ? (
                partner[obj.fieldNameInResponse]
              ) : obj.fieldNameInResponse === "linkedin" ? (
                <PullReportButton
                  uri={partner[obj.fieldNameInResponse]}
                  isLink
                  text="View Applicant's LinkedIn"
                  accessoryIconName="linkedin"
                />
              ) : obj.fieldNameInResponse === "equifaxConsumerReport" ? (
                <>
                  <PullReportButton
                    uri={partner[obj.fieldNameInResponse]}
                    isLink
                    text={
                      partner.isFinalPullEquifaxConsumer &&
                      partner["equifaxConsumerErrors"].length > 0
                        ? "IN PROCESS"
                        : !partner.isFinalPullEquifaxConsumer &&
                          partner["equifaxConsumerErrors"].length > 0
                        ? "RETRY - PULL REPORT"
                        : "PULL REPORT"
                    }
                    accessoryIconName={
                      obj.fieldNameInResponse === "equifaxConsumerReport" &&
                      partner.isFinalPullEquifaxConsumer
                        ? "more-horizontal"
                        : "arrow-forward"
                    }
                    openModal={() => openPullRetryModal(partner.email)}
                  />
                </>
              ) : (
                <PullReportButton
                  uri={partner[obj.fieldNameInResponse]}
                  isLink
                  text={
                    obj.fieldNameInResponse === "driversLicense"
                      ? "VIEW LICENCE"
                      : "PULL REPORT"
                  }
                />
              ),
          }));
        })
      );
      if (errorList.length > 0) {
        // @ts-ignore
        setEquifaxConsumerErrorList([
          ...equifaxConsumerErrorList,
          ...errorList,
        ]);
      }
    }
  }, [isBusinessPartnersResponse]);

  useEffect(() => {
    if (isEquifaxFinalPullRetryOpen) {
      const index = equifaxConsumerErrorList.findIndex(
        (item: any) => item.email === userEmailToRequestEquifaxRepull
      );
      if (index >= 0 && equifaxConsumerErrorList.length > 0) {
        setFieldsToBeUpdated(equifaxConsumerErrorList[index]["errors"]);
      }
    }
  }, [isEquifaxFinalPullRetryOpen, userEmailToRequestEquifaxRepull]);

  const confirmFinalBureauRetry = () => {
    if (
      authStore?.accessToken &&
      applicationId &&
      userEmailToRequestEquifaxRepull
    ) {
      setLoading(true);
      api
        .sendUpdateDetailsForConsumerReportEmail(
          authStore?.accessToken,
          applicationId,
          { userEmail: userEmailToRequestEquifaxRepull }
        )
        .then((resp) => {
          console.log(resp);
          // const response = resp.response.entities;
          setLoading(false);

          // Update display
          let emailIndex = businessPrincipalDataDisplay.findIndex(
            (field: any) => field.fieldNameInResponse === "email"
          );
          if (emailIndex < 0) return;

          if (
            businessPrincipalDataDisplay[emailIndex].displayValue ===
            userEmailToRequestEquifaxRepull
          ) {
            // Update display for Business Owner
            setBusinessPrincipalDataDisplay(
              businessPrincipalDataDisplay.map((field: any) => {
                if (
                  field.fieldNameInResponse !== "equifaxConsumerReport" &&
                  field.fieldNameInResponse !== "ficoScore" &&
                  field.fieldNameInResponse !== "ficoReasonCodes"
                )
                  return field;
                return {
                  ...field,
                  displayValue: (
                    <PullReportButton
                      isLink
                      text="IN PROCESS"
                      accessoryIconName="more-horizontal"
                    />
                  ),
                };
              })
            );
          } else {
            // Update display for Other Business Partners
            if (otherBusinessPartnersDataDisplay.length > 0) {
              emailIndex = otherBusinessPartnersDataDisplay[0].findIndex(
                (field: any) => field.fieldNameInResponse === "email"
              );
            }
            setOtherBusinessPartnersDataDisplay(
              otherBusinessPartnersDataDisplay.map((partner: any) => {
                if (emailIndex > 0) {
                  if (
                    partner[emailIndex].displayValue ===
                    userEmailToRequestEquifaxRepull
                  ) {
                    return partner.map((field: any) => {
                      if (
                        field.fieldNameInResponse !== "equifaxConsumerReport" &&
                        field.fieldNameInResponse !== "ficoScore" &&
                        field.fieldNameInResponse !== "ficoReasonCodes"
                      )
                        return field;
                      return {
                        ...field,
                        displayValue: (
                          <PullReportButton
                            isLink
                            text="IN PROCESS"
                            accessoryIconName="more-horizontal"
                          />
                        ),
                      };
                    });
                  }
                }

                return partner;
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  if (loading)
    return (
      <View style={{ ...styles.container, alignItems: "center" }}>
        <LoadingIndicator />
      </View>
    );

  return (
    <View style={styles.cardsContainer}>
      <View style={styles.card}>
        <TouchableWithoutFeedback
          onPress={() => setIsBusinessPrincipalOpen(!isBusinessPrincipalOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardHeader}>
              {applicationId
                ? "Business Principal 1 Review (Primary Applicant)"
                : "Business Principal Review (no application selected)"}
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    { rotate: isBusinessPrincipalOpen ? "180deg" : "0deg" },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isBusinessPrincipalOpen}>
          <TableDisplay
            data={businessPrincipalDataDisplay}
            itemsPerRow={5}
            isEditing={isEditing}
            rawData={editData}
            setRawData={setEditData}
          />
          <EquifaxFinalPullRetry
            isOpen={isEquifaxFinalPullRetryOpen}
            closeModal={() => setIsEquifaxFinalPullRetryOpen(false)}
            fieldsToBeUpdated={fieldsToBeUpdated}
            confirmFinalBureauRetry={() => confirmFinalBureauRetry()}
          />
          {isEditing && (
            <View style={{ ...styles.container, alignItems: "center" }}>
              {errors.ficoScore !== "" && (
                <Text style={styles.error}>{errors.ficoScore}</Text>
              )}
              {errors.ficoReasonCodes !== "" && (
                <Text style={styles.error}>{errors.ficoReasonCodes}</Text>
              )}
              <View style={styles.flexRow}>
                <Button
                  style={styles.gradientButton}
                  onPress={() => saveEdits()}
                >
                  Save Changes
                </Button>
                <Button
                  style={styles.cancelButton}
                  onPress={() => setIsEditing(false)}
                  children={() => (
                    <Text style={styles.cancelButtonText}>Cancel</Text>
                  )}
                />
              </View>
            </View>
          )}
        </Collapsible>

        <View>
          <Divider style={styles.cardDivider} />
          <TouchableWithoutFeedback
            onPress={() => setIsOtherSectionOpen(!isOtherSectionOpen)}
          >
            <View style={styles.flexRowBetween}>
              <Text style={styles.cardSection} appearance="hint">
                OTHER
              </Text>
              <Icon
                style={[
                  styles.chevronButton,
                  {
                    transform: [
                      { rotate: isOtherSectionOpen ? "180deg" : "0deg" },
                    ],
                  },
                ]}
                name="chevron-down-outline"
              />
            </View>
          </TouchableWithoutFeedback>
          <Divider style={styles.cardDivider} />
          <Collapsible collapsed={!isOtherSectionOpen}>
            <TableDisplay itemsPerRow={3} data={otherData} />
          </Collapsible>
        </View>
      </View>
      {otherBusinessPartnersDataDisplay.map((partner: any, idx: number) => (
        <View style={styles.card} key={`partner-${idx}`}>
          <TouchableWithoutFeedback
            onPress={() =>
              setIsBusinessPartnerCardOpen(
                isBusinessPartnerCardOpen.map((flag: boolean, idx2: number) =>
                  idx2 === idx ? !flag : flag
                )
              )
            }
          >
            <View style={styles.flexRowBetween}>
              <Text style={styles.cardHeader}>
                Business Principal {idx + 2} Review
              </Text>
              <Icon
                style={[
                  styles.chevronButton,
                  {
                    transform: [
                      { rotate: isBusinessPrincipalOpen ? "180deg" : "0deg" },
                    ],
                  },
                ]}
                name="chevron-down-outline"
              />
            </View>
          </TouchableWithoutFeedback>
          <Divider style={styles.cardDivider} />
          <Collapsible collapsed={!isBusinessPartnerCardOpen[idx]}>
            <TableDisplay data={partner} itemsPerRow={5} />
          </Collapsible>
          <View>
            <Divider style={styles.cardDivider} />
            <TouchableWithoutFeedback
              onPress={() =>
                setIsBusinessPartnerOtherCardOpen(
                  isBusinessPartnerOtherCardOpen.map(
                    (flag: boolean, idx2: number) =>
                      idx2 === idx ? !flag : flag
                  )
                )
              }
            >
              <View style={styles.flexRowBetween}>
                <Text style={styles.cardSection} appearance="hint">
                  OTHER
                </Text>
                <Icon
                  style={[
                    styles.chevronButton,
                    {
                      transform: [
                        { rotate: isOtherSectionOpen ? "180deg" : "0deg" },
                      ],
                    },
                  ]}
                  name="chevron-down-outline"
                />
              </View>
            </TouchableWithoutFeedback>
            <Divider style={styles.cardDivider} />
            <Collapsible collapsed={!isBusinessPartnerOtherCardOpen[idx]}>
              <TableDisplay
                itemsPerRow={3}
                data={otherBusinessPartnersOtherDataDisplay[idx]}
              />
            </Collapsible>
          </View>
        </View>
      ))}
      {otherDocuments.length > 0 ? (
        <View style={styles.card}>
          <Divider style={styles.cardDivider} />
          <TouchableWithoutFeedback
            onPress={() => setIsOtherOpen(!isOtherOpen)}
          >
            <View style={styles.flexRowBetween}>
              <Text style={styles.cardSection} appearance="hint">
                OTHER DOCUMENTS
              </Text>
              <Icon
                style={[
                  styles.chevronButton,
                  {
                    transform: [{ rotate: isOtherOpen ? "180deg" : "0deg" }],
                  },
                ]}
                name="chevron-down-outline"
              />
            </View>
          </TouchableWithoutFeedback>
          <Divider style={styles.cardDivider} />
          <Collapsible collapsed={!isOtherOpen}>
            <TableDisplay itemsPerRow={4} data={otherDocuments} />
          </Collapsible>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};
