import { Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import { View } from "react-native";

import { themedStyles } from "./themedStyles";

const DocuSignModalEnd: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <>
      <View style={styles.oneText}>
        <Text style={styles.header}>
          The DocuSign session has finished. You may now close this window.
        </Text>
      </View>
    </>
  );
};

export default DocuSignModalEnd;
