import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  flexRow: { flexDirection: "row" },
  flexColumn: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  iconSmall: {
    width: 18,
    height: 18,
    zindex: 2,
    fill: "#101426",
  },
  pill: {
    padding: 0,
    margin: 0,
    borderRadius: 25,
    marginHorizontal: 7,
    marginVertical: 5,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  pillsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  input: {
    flex: 1,
    marginBottom: 36,
    marginHorizontal: 5,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
  },
});
