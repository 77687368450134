import * as eva from "@eva-design/eva";
import {
  Modal,
  Text,
  ThemeProvider,
  useStyleSheet,
  Divider,
  Icon,
  Spinner,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, ScrollView, Dimensions } from "react-native";

import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";
import { OnMouseClickTooltip } from "../../components/Tooltip";

const AmortizationScheduleModal: React.FC<any> = ({
  isOpen,
  close,
  applicationId,
}) => {
  const styles = useStyleSheet(themedStyles);
  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const { authStore } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>({});
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [hasSetLoan, setHasSetLoan] = useState<boolean>(true);
  const headers = [
    "Payment Number",
    "Loan Amount",
    "Payment",
    "Principal Paid",
    "Interest Paid",
    "Ending Balance",
    "Interest",
    "Principal",
  ];
  const headerSplits = { 5: 5 };

  useEffect(() => {
    if (isOpen && applicationId) {
      setLoading(true);
      api
        .getAmortizationSchedule(authStore.accessToken || "", applicationId)
        .then((resp) => {
          const response = resp.response.entities;
          if ("schedule" in response)
            setSchedule(JSON.parse(response.schedule));
          else setSchedule({});
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessages(JSON.parse(error.message));
          console.log(error);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (schedule[1]) {
      if (schedule[1]["Loan Amount"] === 0) setHasSetLoan(false);
      else setHasSetLoan(true);
    }
  }, [schedule]);

  const amortizationToolTipText =
    "Using straight-line amortization for the scheduling, starting month one, take the total amount of the loan.\
    \nThen, multiply it by the given interest rate.\nTo compute the monthly interest, divide the result by 12.\
    \nAfter getting the monthly interest, subtract it from the total monthly payment and the remaining amount will be the principal.\
    \nRepeat the same procedure for month except use the new principal value instead of the original amount of the loan\
    \nBy the end of the term of the loan, your principal should be at zero.";

  return (
    <Modal
      visible={isOpen}
      onBackdropPress={() => close()}
      style={{
        ...styles.calculatorModal,
        maxHeight: Dimensions.get("window").height * 0.9,
      }}
      backdropStyle={styles.backdrop}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.modalHeaderContainer}>
          <Text style={styles.modalHeader}>
            Amortization Schedule{" "}
            <OnMouseClickTooltip text={amortizationToolTipText} fontSize={12} />
          </Text>
          <TouchableOpacity onPress={() => close()}>
            <Icon width={24} height={24} name="close-outline" />
          </TouchableOpacity>
        </View>
        <Divider style={styles.cardDivider} />
        <ScrollView style={{ flex: 1, maxHeight: 500 }}>
          {loading ? (
            <View style={styles.center}>
              <LoadingIndicator />
            </View>
          ) : (
            <View style={styles.scheduleTableContainer}>
              <View style={styles.scheduleTableHeaderRow}>
                {Object.keys(schedule).length <= 0 ? (
                  <>
                    <Text style={styles.noAmortizationText}>
                      No Amortization Schedule{"\n\n"}
                      <Text
                        style={{ ...styles.noAmortizationText, fontSize: 14 }}
                      >
                        {errorMessages["detail"]}
                      </Text>
                    </Text>
                  </>
                ) : (
                  headers.map((header, idx) => (
                    <View
                      key={"header-" + idx}
                      style={
                        idx in headerSplits
                          ? styles.scheduleTableCellWithRightBorder
                          : styles.scheduleTableCell
                      }
                    >
                      <Text style={styles.scheduleTableHeaderText}>
                        {header}
                      </Text>
                    </View>
                  ))
                )}
              </View>
              {Object.keys(schedule).map((key: any, idx: number) => (
                <View key={"row-" + idx} style={styles.scheduleTableRow}>
                  <View style={styles.scheduleTableCell}>
                    <Text style={styles.scheduleTableCellText}>{key}</Text>
                  </View>
                  {Object.keys(schedule[key]).map(
                    (prop: any, colIdx: number) => {
                      if (prop === "Payment Date")
                        return <View key={"cell-" + idx + "-" + colIdx} />;
                      return (
                        <View
                          key={"cell-" + idx + "-" + colIdx}
                          style={
                            colIdx in headerSplits
                              ? styles.scheduleTableCellWithRightBorder
                              : styles.scheduleTableCell
                          }
                        >
                          <Text style={styles.scheduleTableCellText}>
                            {schedule[key][prop]}
                          </Text>
                        </View>
                      );
                    }
                  )}
                  <View style={styles.scheduleTableCell}>
                    <Text style={styles.scheduleTableCellText}>
                      {hasSetLoan
                        ? `${Number(
                            (100 * schedule[key]["Interest Paid"]) /
                              (schedule[key]["Principal Paid"] +
                                schedule[key]["Interest Paid"])
                          ).toFixed(1)}%`
                        : "None"}
                    </Text>
                  </View>
                  <View style={styles.scheduleTableCell}>
                    <Text style={styles.scheduleTableCellText}>
                      {hasSetLoan
                        ? `${Number(
                            (100 * schedule[key]["Principal Paid"]) /
                              (schedule[key]["Principal Paid"] +
                                schedule[key]["Interest Paid"])
                          ).toFixed(1)}%`
                        : "None"}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </ScrollView>
      </ThemeProvider>
    </Modal>
  );
};

export default AmortizationScheduleModal;
