import { StyleService } from "@ui-kitten/components";

import { isChromeBrowser } from "../../utils/constants";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Montserrat_800ExtraBold",
    fontWeight: 800,
    lineHeight: 48,
    fontSize: 48,
    marginTop: 69,
    marginBottom: 24,
    color: "white",
  },
  userName: {
    color: "cornflowerblue",
  },
  subHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 40,
    fontSize: 30,
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
  subSubHeader: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 32,
    fontSize: 22,
    flexDirection: "column",
    alignItems: "center",
    color: "white",
  },
  subheaderIcon: {
    width: 20,
    height: 20,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
  },
  loanTrackerCard: {
    flex: 1,
    maxWidth: 800,
    flexDirection: "column",
    alignItems: "flex-start",
    paddingVertical: 20,
    paddingHorizontal: 0,
    marginVertical: 20,
    borderRadius: 4,
    shadowColor: "rgb(55, 65, 81)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6,

    elevation: 4,
  },
  subLoanTrackerCard: {
    flex: 1,
    maxWidth: 800,
    flexDirection: "column",
    alignItems: "flex-start",
    paddingVertical: 0,
    paddingHorizontal: 0,
    marginVertical: 20,
    marginLeft: 5,
    borderRadius: 4,
    shadowColor: "rgb(55, 65, 81)",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6,

    elevation: 4,
  },
  expandedCardContent: {
    flex: 1,
    width: "100%",
  },
  divider: {
    backgroundColor: "#8F9BB3",
    height: 2,
    marginVertical: 20,
  },
  description: {
    fontFamily: "Inter_400Regular",
    lineHeight: 24,
    fontSize: 12,
    color: "white",
  },
  loanView: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    marginVertical: 50,
    marginLeft: "20%",
    marginRight: 10,
    padding: 100,
    backgroundColor: "#101426",
  },
  loanViewContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 69,
    width: "85%",
  },
  loanViewMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    marginVertical: 50,
    marginLeft: "5%",
    marginRight: 10,
    padding: 32,
    backgroundColor: "#101426",
  },
  loanViewContainerMobile: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 69,
    width: "95%",
  },
  loanHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  loanHeaderMobile: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  loanHeaderText: {
    textAlign: "left",
  },
  nextActionButton: {
    width: "25%",
    marginVertical: 30,
  },
  loanButton: {
    marginVertical: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  loanButtonTextContainer: {
    marginLeft: 75,
  },
  loanTrackerProgressBar: {
    flex: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "stretch",
    paddingVertical: 15,
    maxWidth: 800,
  },
  loanTrackerProgressBarContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#101426",
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  stepsContainerMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  step: {
    marginVertical: 4,
  },
  stepTextContainer: {
    position: "absolute",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 56,
    width: 209,
    left: 20,
  },
  stepTextContainerMobile: {
    position: "absolute",
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 45,
    width: 90,
    left: 20,
  },
  stepText: {
    fontFamily: "Inter_500Medium",
    lineHeight: 24,
    fontSize: 18,
    color: "#222B45",
  },
  stepTextMobile: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 12,
    fontSize: 10,
    color: "#222B45",
  },
  congratsTextMessage: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 32,
    marginHorizontal: "auto",
    color: "white",
    lineHeight: 24,
    paddingBottom: 18,
  },
  loanStatusMessage: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 20,
    marginHorizontal: "auto",
    color: "white",
    lineHeight: 30,
  },
  loanStatusMessageContainer: {
    marginVertical: 15,
    marginTop: 55,
    marginBottom: 55,
    marginHorizontal: 5,
  },
  loanTrackerActionButton: {
    maxWidth: 300,
    alignSelf: "center",
    marginVertical: 10,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    marginTop: 55,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRowMobile: {
    flexDirection: "row",
    marginVertical: 10,
  },
  inputLabel: {
    fontFamily: "Inter_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
    color: "white",
  },
  input: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "400",
    fontSize: 15,
    lineHeight: 24,
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
  },
  cashInput: {
    flex: 1,
    marginBottom: 22,
    marginHorizontal: 5,
    fontFamily: "Inter_700Bold",
    fontSize: 26,
    lineHeight: 32,
  },
  questionBtn: {
    flex: 1,
    marginHorizontal: 5,
  },
  loanTrackerCardTouchableView: {
    width: "100%",
    height: "100%",
  },
  loanTrackerHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  loanTrackerHeaderMobile: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  loanTrackerButtons: {
    marginLeft: "auto",
  },
  viewButton: {
    backgroundColor: "background-basic-color-2",
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 5,
    height: 20,
    border: 0,
  },
  viewButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "background-alternative-color-1",
    lineHeight: 16,
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  quickBooksButton: {
    backgroundColor: "#222B45",
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 5,
  },
  withdrawButton: {
    backgroundColor: "#222B45",
    paddingVertical: 12,
    paddingHorizontal: 12,
    marginHorizontal: 5,
  },
  manageProfileButton: {
    backgroundColor: "#1D84FF",
    paddingVertical: 13,
    paddingHorizontal: 23.5,
  },
  withdrawButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 10,
    lineHeight: 12,
    textAlign: "center",
    color: "white",
  },
  gap: {
    width: 5,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "100%",
    backgroundColor: "#101426",
    padding: 50,
  },
  modalText: {
    color: "white",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalYesButton: {
    backgroundColor: "#222B45",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingTop: 10,
    paddingBottom: 20,
    borderRadius: 4,
  },
  modalNoButton: {
    backgroundColor: "#1D84FF",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingTop: 10,
    paddingBottom: 20,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    padding: 5,
  },
  iconSmall: {
    width: 18,
    height: 18,
    zindex: 2,
    fill: "#101426",
  },
  accountLinkingButton: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 22,
    backgroundColor: "rgba(143, 155, 179, 0.08)",
    border: "none",
  },
  accountLinkingSuccess: {
    backgroundColor: "rgba(0, 153, 51, 0.08)",
  },
  accountLinkingButtonHeader: {
    fontFamily: "Montserrat_400Regular",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
  },
  accountLinkingButtonText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
    color: "white",
    lineHeight: 24,
    fontSize: 13,
  },
  accountLinkingButtonContainer: {
    paddingHorizontal: 10,
    flex: 1,
  },
  backdrop: {
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  bankModal: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 800,
    height: 600,
    padding: 20,
    backgroundColor: "background-basic-color-2",
  },
  payrollModal: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
    maxHeight: 600,
    backgroundColor: "background-basic-color-2",
    overflowHorizontal: "hidden",
  },
  payrollModalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#112",
  },
  summarySubHeader: {
    fontFamily: "Montserrat_400Regular",
    color: "background-alternative-color-1",
    lineHeight: 24,
    fontSize: 24,
    paddingTop: 10,
  },
  subsectionHeader: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    paddingVertical: 10,
  },
  flexRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  editButton: {
    width: 10,
  },
  datePickerPlaceholderText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginHorizontal: 8,
  },
  uploadButton: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 0,
    marginBottom: 10,
    border: 0,
  },
  uploadButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "rgba(85, 170, 255, 1)",
  },
  payrollModalTitle: {
    color: "white",
    fontFamily: "Montserrat_500Medium",
    lineHeight: 24,
    fontSize: 18,
  },
  saveButton: {
    margin: 10,
    backgroundColor: "#1D84FF",
  },
  inputTextNotEditing: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "rgb(76,85,109)",
    width: "100%",
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    width: "100%",
  },
  inputTextMobile: {
    fontSize: 12,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    width: "100%",
  },
  inputMask: {
    marginHorizontal: 5,
    marginBottom: 20,
    flex: 1,
    backgroundColor: "background-basic-color-2",
    borderColor: "background-basic-color-3",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontSize: 15,
    fontWeight: "400",
    fontFamily: "Montserrat_400Regular",
    color: "background-alternative-color-1",
    width: "98%",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  inputMaskMobile: {
    marginBottom: 10,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontFamily: "Montserrat_400Regular",
    fontSize: 12,
    color: "white",
    width: "99%",
    padding: 10,
  },
  purpleGradientBackgroundContainer: {
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    padding: 1,
    marginVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  existingLenderButton: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#101426",
    border: "none",
    borderRadius: 0,
  },
  flexEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    borderBottom: "1px solid #8F9BB3",
  },
  modalHeaderText: {
    lineHeight: 32,
    fontSize: 22,
    fontFamily: "Montserrat_400Regular",
  },
  lenderInformationContainer: {
    padding: 20,
    borderRadius: 5,
    width: 1000,
    background: "#101426",
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
  existingLenderHeader: {
    fontFamily: "Montserrat_500Normal",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
  },
  existingLenderText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 13,
  },
  addLenderButton: {
    margin: 15,
    padding: 1,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    textTransform: "uppercase",
    borderRadius: 5,
    width: 175,
  },
  addLenderButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    background: "#101426",
    borderRadius: 5,
    paddingTop: 1,
    paddingBottom: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  gradientText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    margin: 0,
    padding: 0,
    color: "transparent",
    backgroundClip: "text",
  },
  dropdown: {
    fontSize: 22,
    marginBottom: 22,
    backgroundColor: "none",
    width: "99%",
  },
  bold: {
    fontFamily: "Montserrat_700Bold",
  },
});
