import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Text,
  Divider,
  useStyleSheet,
  Spinner,
  Button,
  Icon,
} from "@ui-kitten/components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import Collapsible from "react-native-collapsible";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

import { TableDisplay } from "../../components/TableDisplay";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { PullReportButton } from "./PullReportButton";
import { themedStyles } from "./themedStyles";

export const UnbilledContractAmountConfirmIndicator: React.FC<any> = ({
  date,
  underwriterName,
}) => {
  return (
    <OnMouseClickTooltip
      text={
        date
          ? `Confirmed by ${underwriterName} on ${new Date(
              date
            ).toDateString()}`
          : "Not confirmed"
      }
      iconName={date ? "checkmark-circle-2" : "info-outline"}
      iconColor={date ? "#00E096" : "gray"}
    />
  );
};

export const ContractAndCollateral: React.FC<any> = ({
  canEdit,
  applicationId,
  editTimestampValue = null,
  setEditTimestampValue,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoanHardRejected, setIsLoanHardRejected] = useState(true);

  const defaultErrors = {
    baseContractValue: "",
    unbilledContractValue: "",
    error: "",
  };

  const [errors, setErrors] = useState(defaultErrors);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const [collateralData, setCollateralData]: any = useState({
    baseContractValue: "?",
    unbilledContractValue: "?",
    maturityDate: "?",
  });

  const [collateralDataDisplay, setCollateralDataDisplay]: any = useState([
    {
      header: "Base Contract Value",
      displayValue: "?",
      editable: true,
      fieldNameInResponse: "baseContractValue",
      prefix: "$",
      maxValue: 10 ** 12,
      accessoryRight: <UnbilledContractAmountConfirmIndicator />,
    },
    {
      header: "Unbilled Contract Value",
      displayValue: "?",
      editable: true,
      fieldNameInResponse: "unbilledContractValue",
      prefix: "$",
      maxValue: 10 ** 12,
      accessoryRight: <UnbilledContractAmountConfirmIndicator />,
    },
    {
      header: "Maturity Date",
      displayValue: "?",
      fieldNameInResponse: "maturityDate",
    },
    {
      header: "Contract Time Remaining",
      displayValue: "?",
      fieldNameInResponse: "contractTimeRemaining",
      suffix: " Months",
    },
    {
      header: "Contract Pricing Type",
      displayValue: "?",
      fieldNameInResponse: "contractPricingType",
    },
    {
      header: "Contracting Agency",
      displayValue: "?",
      fieldNameInResponse: "contractingAgency",
    },
    {
      header: "Primary NAICs Code",
      displayValue: "?",
      fieldNameInResponse: "primaryNaicsCode",
    },
    {
      header: "Primary NAICs Code Definition",
      displayValue: "?",
      fieldNameInResponse: "primaryNaicsCodeDefinition",
      infoTooltip:
        "Definition is based on 2022 NAICS classification\nrefer to: https://www.census.gov/naics/?58967?yearbck=2022",
    },
    {
      header: "Contract",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
      fieldNameInResponse: "contract",
    },
    {
      header: "Contract Backlog",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
      fieldNameInResponse: "contractBacklog",
    },
    {
      header: "Fedmine Report (PDF)",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
      fieldNameInResponse: "fedmineReport",
    },
    {
      header: "Fedmine Report (CSV)",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
      fieldNameInResponse: "fedmineReportCsv",
    },
  ]);

  const saveCollateralDataChanges = () => {
    let flagBase = false;
    let flagUnbilled = false;

    console.log(
      collateralData["baseContractValue"],
      collateralData["unbilledContractValue"],
      errors
    );

    if (
      isNaN(collateralData["baseContractValue"]) &&
      collateralData["baseContractValue"] !== ""
    ) {
      //not float, invalid
      flagBase = true;
    }

    if (
      isNaN(collateralData["unbilledContractValue"]) &&
      collateralData["unbilledContractValue"] !== ""
    ) {
      //not float, invalid
      flagUnbilled = true;
    }

    if (flagBase || flagUnbilled) {
      setErrors({
        ...errors,
        baseContractValue: `${flagBase ? "Invalid Base Contract Value." : ""}`,
        unbilledContractValue: `${
          flagUnbilled ? "Invalid Unbilled Contract Value." : ""
        }`,
      });
      return false;
    }

    setLoading(true);
    api
      .updateLoanCollateral(authStore?.accessToken || "", applicationId, {
        baseContractValue: collateralData.baseContractValue,
        unbilledContractValue: collateralData.unbilledContractValue,
        editTimestamp: editTimestampValue,
      })
      .then((resp) => {
        const response = resp.response.entities;

        // Check if other UWs edited the loan
        const errorMessageIndex = Object.keys(response).findIndex(
          (item) => item === "error"
        );
        if (errorMessageIndex > -1) {
          setErrors(resp.response.entities);
          setLoading(false);
          setIsEditing(false);
          return;
        }

        setEditTimestampValue(response.modified);

        //update display
        setCollateralDataDisplay(
          collateralDataDisplay.map((field: any) => {
            if (!field.editable) return field;
            return {
              ...field,
              displayValue: collateralData[field.fieldNameInResponse],
              accessoryRight: ![
                "unbilledContractValue",
                "baseContractValue",
              ].includes(field.fieldNameInResponse) ? (
                field.accessoryRight
              ) : (
                <UnbilledContractAmountConfirmIndicator
                  underwriterName={response.unbilledContractAmountConfirmerName}
                  date={response.unbilledContractAmountConfirmationDate}
                />
              ),
            };
          })
        );

        setLoading(false);
        setIsEditing(false);
        setErrors(defaultErrors);
      });
  };

  const [updateTabCounter, setUpdateTabCounter] = useState(0);

  const confirmContractAmount = () => {
    setLoading(true);
    api
      .confirmUnbilledContractAmount(
        authStore?.accessToken || "",
        applicationId
      )
      .then((resp: any) => {
        setLoading(false);
        setUpdateTabCounter(updateTabCounter + 1);
      });
  };

  const [requestedDocuments, setRequestedDocuments]: any = useState([]);
  const [otherDocuments, setOtherDocuments]: any = useState([]);
  const { authStore } = useStore();

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getLoanCollateral(authStore?.accessToken, applicationId)
          .then((resp) => {
            const response = resp.response.entities;
            setIsLoanHardRejected(response.isHardRejected);
            setCollateralData(response);
            setCollateralDataDisplay(
              collateralDataDisplay.map((obj: any) => ({
                ...obj,
                displayValue: !obj.isLink ? (
                  response[obj.fieldNameInResponse]
                ) : (
                  <PullReportButton
                    uri={response[obj.fieldNameInResponse]}
                    isLink
                    text={
                      response[obj.fieldNameInResponse] === "error"
                        ? "REPORT FAIL"
                        : "PULL REPORT"
                    }
                  />
                ),
                accessoryRight: ![
                  "unbilledContractValue",
                  "baseContractValue",
                ].includes(obj.fieldNameInResponse) ? (
                  obj.accessoryRight
                ) : (
                  <UnbilledContractAmountConfirmIndicator
                    underwriterName={
                      response.unbilledContractAmountConfirmerName
                    }
                    date={response.unbilledContractAmountConfirmationDate}
                  />
                ),
              }))
            );

            setRequestedDocuments(
              response.requestedDocuments.map((doc: any) => {
                return {
                  header: doc[0],
                  displayValue: <PullReportButton uri={doc[1]} isLink />,
                  isLink: true,
                };
              })
            );

            setOtherDocuments(
              response.otherDocuments.map((doc: any) => {
                return {
                  header: doc[0],
                  displayValue: <PullReportButton uri={doc[1]} isLink />,
                  isLink: true,
                };
              })
            );
            setLoading(false);
          });
      }
    }, [useIsFocused(), updateTabCounter])
  );

  const [isCollateralOpen, setIsCollateralOpen] = useState(true);
  const [isRequestedOpen, setIsRequestedOpen] = useState(true);
  const [isOtherOpen, setIsOtherOpen] = useState(true);

  if (loading)
    return (
      <View style={{ ...styles.container, alignItems: "center" }}>
        <LoadingIndicator />
      </View>
    );

  return (
    <View style={styles.cardsContainer}>
      <View style={styles.card}>
        <TouchableWithoutFeedback
          onPress={() => setIsCollateralOpen(!isCollateralOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardHeader}>
              {applicationId
                ? "Contract and Collateral Review"
                : "Contract and Collateral Review (no application selected)"}
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [{ rotate: isCollateralOpen ? "180deg" : "0deg" }],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isCollateralOpen}>
          <View
            style={{
              ...styles.flexRow,
              justifyContent: "flex-end",
            }}
          >
            {canEdit && !isLoanHardRejected && (
              <>
                <Button
                  style={styles.gradientButton}
                  onPress={() => setIsEditing(true)}
                >
                  Confirm Contract Value
                </Button>
              </>
            )}
          </View>
          <TableDisplay
            data={collateralDataDisplay}
            itemsPerRow={3}
            isEditing={isEditing}
            rawData={collateralData}
            setRawData={setCollateralData}
          />
          {isEditing && (
            <View style={{ ...styles.container, alignItems: "center" }}>
              {errors.baseContractValue !== "" && (
                <Text style={styles.error}>{errors.baseContractValue}</Text>
              )}
              {errors.unbilledContractValue !== "" && (
                <Text style={styles.error}>{errors.unbilledContractValue}</Text>
              )}
              <View style={styles.flexRow}>
                <Button
                  style={styles.gradientButton}
                  onPress={() => saveCollateralDataChanges()}
                >
                  Confirm
                </Button>
                <Button
                  style={styles.cancelButton}
                  onPress={() => setIsEditing(false)}
                  children={() => (
                    <Text style={styles.cancelButtonText}>Cancel</Text>
                  )}
                />
              </View>
            </View>
          )}
        </Collapsible>

        {requestedDocuments.length > 0 ? (
          <View>
            <Divider style={styles.cardDivider} />
            <TouchableWithoutFeedback
              onPress={() => setIsRequestedOpen(!isRequestedOpen)}
            >
              <View style={styles.flexRowBetween}>
                <Text style={styles.cardSection} appearance="hint">
                  REQUESTED DOCUMENTS
                </Text>
                <Icon
                  style={[
                    styles.chevronButton,
                    {
                      transform: [
                        { rotate: isRequestedOpen ? "180deg" : "0deg" },
                      ],
                    },
                  ]}
                  name="chevron-down-outline"
                />
              </View>
            </TouchableWithoutFeedback>
            <Divider style={styles.cardDivider} />
            <Collapsible collapsed={!isRequestedOpen}>
              <TableDisplay itemsPerRow={4} data={requestedDocuments} />
            </Collapsible>
          </View>
        ) : (
          <></>
        )}

        {otherDocuments.length > 0 ? (
          <View>
            <Divider style={styles.cardDivider} />
            <TouchableWithoutFeedback
              onPress={() => setIsOtherOpen(!isOtherOpen)}
            >
              <View style={styles.flexRowBetween}>
                <Text style={styles.cardSection} appearance="hint">
                  OTHER DOCUMENTS
                </Text>
                <Icon
                  style={[
                    styles.chevronButton,
                    {
                      transform: [{ rotate: isOtherOpen ? "180deg" : "0deg" }],
                    },
                  ]}
                  name="chevron-down-outline"
                />
              </View>
            </TouchableWithoutFeedback>
            <Divider style={styles.cardDivider} />
            <Collapsible collapsed={!isOtherOpen}>
              <TableDisplay itemsPerRow={4} data={otherDocuments} />
            </Collapsible>
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};
