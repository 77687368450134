import * as eva from "@eva-design/eva";
import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Layout,
  Text,
  Divider,
  useStyleSheet,
  Spinner,
  ThemeProvider,
  Icon,
  Button,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import { View, ScrollView } from "react-native";
import Collapsible from "react-native-collapsible";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

import Header from "../../components/Header";
import { TableDisplay } from "../../components/TableDisplay";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import UnderwriterPortalHeader from "../../components/UnderwriterPortalHeader";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { UnbilledContractAmountConfirmIndicator } from "../UnderwriterPortalCollateral/ContractAndCollateral";
import { themedStyles } from "./themedStyles";

const Summary: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const investorView =
    route?.params?.investorView ||
    new URLSearchParams(window.location.search).get("investorView");

  const { authStore, loanStore } = useStore();

  const [isEditing, setIsEditing] = useState(false);
  const canEdit = authStore.user?.isStaff;
  const [editData, setEditData]: any = useState({});

  const defaultErrors = { error: "" };
  const [errors, setErrors] = useState(defaultErrors);

  const [loanData, setLoanData] = useState([
    {
      header: "Unbilled Contract Amount",
      displayValue: "?",
      fieldNameInResponse: "unbilledContractAmount",
      prefix: "$",
      accessoryRight: <UnbilledContractAmountConfirmIndicator />,
    },
    {
      header: "System Computed Loan Amount",
      displayValue: "?",
      fieldNameInResponse: "amount",
      prefix: "$",
      infoTooltip: "20% of the Unbilled Contract Value, capped at $1M",
    },
    {
      header: "Term",
      displayValue: "?",
      fieldNameInResponse: "term",
      suffix: " Months",
    },
    {
      header: "Interest Rate",
      displayValue: "?",
      fieldNameInResponse: "interest",
      suffix: "%",
    },
    {
      header: "Monthly Payment",
      displayValue: "?",
      fieldNameInResponse: "monthlyPayment",
      prefix: "$",
      infoTooltip: `loan_amount = System Computed Loan Amount
interest = interest rate (i.e. 11)
adjusted_interest = interest / 100 / 12 / 12
term = term in months
x = (1 + adjusted_interest) ^ (term)
amortized_monthly_payment = round( loan_amount * adjusted_interest * x/ (x - 1), 2)
monthly_payment = amortized_monthly_payment * 1.2`,
    },
    {
      header: "Account Created On",
      displayValue: "?",
      fieldNameInResponse: "dateJoined",
    },
    {
      header: "Application Created On",
      displayValue: "?",
      fieldNameInResponse: "created",
    },
    {
      header: "Application Fee Status",
      displayValue: "?",
      fieldNameInResponse: "applicationProcessingFeeStatus",
    },
    {
      header: "Application Fee Paid At",
      displayValue: "?",
      fieldNameInResponse: "applicationFeePaidAt",
    },
    {
      header: "Pre-Qual 1 Started At",
      displayValue: "?",
      fieldNameInResponse: "prequal1StartDate",
    },
    {
      header: "Pre-Qual 1 Ended At",
      displayValue: "?",
      fieldNameInResponse: "prequal1EndDate",
    },
    {
      header: "Pre-Qual 2 Started At",
      displayValue: "?",
      fieldNameInResponse: "prequal2StartDate",
    },
    {
      header: "Pre-Qual 2 Ended At",
      displayValue: "?",
      fieldNameInResponse: "prequal2EndDate",
    },
    {
      header: "Full Application Started At",
      displayValue: "?",
      fieldNameInResponse: "fullApplicationStartDate",
    },
    {
      header: "Full Application Ended At",
      displayValue: "?",
      fieldNameInResponse: "fullApplicationEndDate",
    },
    {
      header: "Peach Borrower ID",
      displayValue: "?",
      fieldNameInResponse: "peachBorrowerId",
    },
    {
      header: "Peach Loan ID",
      displayValue: "?",
      fieldNameInResponse: "peachLoanId",
    },
  ]);

  const [businessData, setBusinessData] = useState([
    {
      header: "Business Name",
      displayValue: "?",
      fieldNameInResponse: "businessName",
    },
    {
      header: "Street",
      displayValue: "?",
      fieldNameInResponse: "businessAddStreetLine1",
    },
    {
      header: "City",
      displayValue: "?",
      fieldNameInResponse: "businessAddCity",
    },
    {
      header: "State",
      displayValue: "?",
      fieldNameInResponse: "businessAddState",
    },
    {
      header: "ZIP Code",
      displayValue: "?",
      fieldNameInResponse: "businessAddZipCode",
    },
    {
      header: "Phone",
      displayValue: "?",
      fieldNameInResponse: "phoneNumber",
    },
    {
      header: "EIN",
      displayValue: "?",
      fieldNameInResponse: "einNumber",
    },
    {
      header: "CAGE",
      displayValue: "?",
      fieldNameInResponse: "cageCode",
    },
    {
      header: "DUNS",
      displayValue: "?",
      fieldNameInResponse: "dunsNumber",
    },
    {
      header: "UEI",
      displayValue: "?",
      fieldNameInResponse: "ueiNumber",
      editable: true,
      infoTooltip:
        "Changing UEI number will redo Fedmine API data pull and Fedmine website scraping",
    },
    {
      header: "Focus Group Agreement",
      displayValue: "?",
      fieldNameInResponse: "hasAgreedToParticipateInFocusGroup",
    },
    {
      header: "Contract Number",
      displayValue: "?",
      fieldNameInResponse: "contractNumber",
      editable: true,
      infoTooltip: `Changing Contract Number will update PSC/NAICS/maturity date and effective date
 values displayed in UW portal if this contract number is present for specified UEI company`,
    },
    {
      header: "Years in Business",
      displayValue: "?",
      fieldNameInResponse: "yearsInBusiness",
      suffix: " Years",
    },
    {
      header: "In Opportunity Zone?",
      displayValue: "?",
      fieldNameInResponse: "inOpportunityZone",
    },
    {
      header: "Ave. Years of Management Experience",
      displayValue: "?",
      fieldNameInResponse: "averageManagementExperience",
    },
    {
      header: "Organization Type",
      displayValue: "?",
      fieldNameInResponse: "businessType",
    },
  ]);

  const [businessPrincipalData, setBusinessPrincipalData] = useState([
    {
      header: "First Name",
      displayValue: "?",
      fieldNameInResponse: "firstName",
    },
    {
      header: "Last Name",
      displayValue: "?",
      fieldNameInResponse: "lastName",
    },
    {
      header: "Cell Phone",
      displayValue: "?",
      fieldNameInResponse: "phoneNumber",
    },
    {
      header: "Email",
      displayValue: "?",
      fieldNameInResponse: "email",
    },
    {
      header: "Birthday",
      displayValue: "?",
      fieldNameInResponse: "birthday",
    },
    {
      header: "SSN",
      displayValue: "?",
      fieldNameInResponse: "ssnNumber",
    },
    {
      header: "Business Role",
      displayValue: "?",
      fieldNameInResponse: "businessRole",
    },
    {
      header: "Years of Management Experience",
      displayValue: "?",
      fieldNameInResponse: "managementExperience",
    },
  ]);

  const [businessPartnersData, setBusinessPartnersData]: any = useState([[]]);

  const [loading, setLoading] = useState(false);

  const [isLoanSummaryOpen, setIsLoanSummaryOpen] = useState(true);
  const [isBusinessOpen, setIsBusinessOpen] = useState(true);
  const [isBusinessPrincipalOpen, setIsBusinessPrincipalOpen] = useState(true);
  const [isBusinessPartnerOpen, setIsBusinessPartnerOpen]: any = useState([]);
  const [editTimestampValue, setEditTimestampValue] = useState<Date | null>(
    null
  );

  const format = (value: any, pattern: any) => {
    let i = 0,
      v = value.toString();
    return pattern.replace(/#/g, (_: any) => v[i++]);
  };

  const formatFocusGroup = (value: boolean) => {
    console.log(value);
    return value === true ? "True" : "False";
  };

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getLoanSummary(authStore?.accessToken, applicationId)
          .then((resp) => {
            const response = resp.response.entities;
            loanStore.createOrUpdateLoanApplication(response);

            setLoanData(
              loanData.map((obj: any) => ({
                ...obj,
                displayValue:
                  obj.fieldNameInResponse === "monthlyPayment"
                    ? response["finalDecisionStatus"].toLowerCase() ===
                      "approved"
                      ? response[obj.fieldNameInResponse]
                      : "?"
                    : obj.fieldNameInResponse === "interest"
                    ? response["riskRating"]
                      ? response[obj.fieldNameInResponse]
                      : "?"
                    : response[obj.fieldNameInResponse] ||
                      (obj.fieldNameInResponse ? "?" : ""),

                accessoryRight:
                  obj.fieldNameInResponse !== "unbilledContractAmount" ? (
                    obj.accessoryRight
                  ) : (
                    <UnbilledContractAmountConfirmIndicator
                      underwriterName={
                        response.unbilledContractAmountConfirmerName
                      }
                      date={response.unbilledContractAmountConfirmationDate}
                    />
                  ),
              }))
            );

            setBusinessData(
              businessData.map((obj) => ({
                ...obj,
                displayValue:
                  obj.fieldNameInResponse === "phoneNumber" &&
                  response["phoneNumber"] &&
                  response["phoneNumber"].substring(0, 2) === "+1" &&
                  response["phoneNumber"].length <= 12
                    ? format(
                        response["phoneNumber"].substring(2),
                        "+1 (###) ###-####"
                      )
                    : obj.fieldNameInResponse ===
                      "hasAgreedToParticipateInFocusGroup"
                    ? formatFocusGroup(
                        response["hasAgreedToParticipateInFocusGroup"]
                      )
                    : response[obj.fieldNameInResponse] ||
                      (obj.fieldNameInResponse ? "?" : ""),
              }))
            );
            setBusinessPrincipalData(
              businessPrincipalData.map((obj) => ({
                ...obj,
                displayValue:
                  obj.fieldNameInResponse === "ssnNumber" &&
                  response["ssnNumber"]
                    ? response["ssnNumber"].replace(/\d(?=(?:\D*\d){4})/g, "•")
                    : obj.fieldNameInResponse === "phoneNumber" &&
                      response["phoneNumber"] &&
                      response["phoneNumber"].substring(0, 2) === "+1" &&
                      response["phoneNumber"].length <= 12
                    ? format(
                        response["phoneNumber"].substring(2),
                        "+1 (###) ###-####"
                      )
                    : response[obj.fieldNameInResponse] ||
                      (obj.fieldNameInResponse ? "?" : ""),
                accessoryRight: obj.fieldNameInResponse === "ssnNumber" &&
                  response["ssnNumber"] && (
                    <OnMouseClickTooltip
                      text={response["ssnNumber"]}
                      iconName="eye-outline"
                    />
                  ),
              }))
            );
            setIsBusinessPartnerOpen(
              Array(response.businessPartners.length).fill(true)
            );
            setBusinessPartnersData(
              response.businessPartners.map((partner: any, idx: number) => {
                return businessPrincipalData.map((obj) => ({
                  ...obj,
                  displayValue:
                    obj.fieldNameInResponse === "ssnNumber" &&
                    partner["ssnNumber"]
                      ? partner["ssnNumber"].replace(/\d(?=(?:\D*\d){4})/g, "•")
                      : obj.fieldNameInResponse === "phoneNumber" &&
                        partner["phoneNumber"] &&
                        partner["phoneNumber"].substring(0, 2) === "+1" &&
                        partner["phoneNumber"].length <= 12
                      ? format(
                          partner["phoneNumber"].substring(2),
                          "+1 (###) ###-####"
                        )
                      : partner[obj.fieldNameInResponse] ||
                        (obj.fieldNameInResponse ? "?" : ""),
                  accessoryRight: obj.fieldNameInResponse === "ssnNumber" &&
                    response["ssnNumber"] && (
                      <OnMouseClickTooltip
                        text={response["ssnNumber"]}
                        iconName="eye-outline"
                      />
                    ),
                }));
              })
            );
            authStore.setLastReviewedLoan(applicationId);

            const newEditData: any = {};
            businessData
              .filter((obj: any) => obj.editable)
              .forEach((obj: any) => {
                newEditData[obj.fieldNameInResponse] =
                  response[obj.fieldNameInResponse];
              });
            setEditData(newEditData);

            setLoading(false);
          });
      }
    }, [useIsFocused()])
  );

  const saveEdits = () => {
    setLoading(true);
    api
      .updateLoanSummary(authStore?.accessToken || "", applicationId, {
        ...editData,
        editTimestamp: editTimestampValue,
      })
      .then((resp) => {
        console.log(resp);
        const response = resp.response.entities;

        // Check if other UWs edited the loan
        const errorMessageIndex = Object.keys(response).findIndex(
          (item) => item === "error"
        );
        if (errorMessageIndex > -1) {
          setErrors(response);
          setLoading(false);
          setIsEditing(false);
          return;
        }

        setEditTimestampValue(response.modified);

        // Update display
        setBusinessData(
          businessData.map((field: any) => {
            if (!field.editable) return field;
            return {
              ...field,
              displayValue: editData[field.fieldNameInResponse],
            };
          })
        );

        setLoading(false);
        setIsEditing(false);
      });
  };

  // Get current timestamp
  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      api
        .getLoanApplicationDetails(authStore?.accessToken, applicationId)
        .then((resp) => {
          setEditTimestampValue(resp.response.entities.modified);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <ThemeProvider theme={eva.light}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme="light" />
          <UnderwriterPortalHeader
            activeTab="summary"
            applicationId={applicationId}
            investorView={investorView}
            editTimestampValue={editTimestampValue}
          />
          {loading ? (
            <View style={{ ...styles.container, alignItems: "center" }}>
              <LoadingIndicator />
            </View>
          ) : (
            <View style={styles.cardsContainer}>
              <View style={styles.card}>
                <TouchableWithoutFeedback
                  onPress={() => setIsLoanSummaryOpen(!isLoanSummaryOpen)}
                  style={styles.flexRow}
                >
                  <Text style={styles.cardHeader}>
                    {applicationId
                      ? "Loan Summary"
                      : "Loan Summary (no application selected)"}
                  </Text>
                  <Icon
                    style={[
                      styles.chevronButton,
                      {
                        transform: [
                          { rotate: isLoanSummaryOpen ? "180deg" : "0deg" },
                        ],
                      },
                    ]}
                    name="chevron-down-outline"
                  />
                </TouchableWithoutFeedback>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isLoanSummaryOpen}>
                  <TableDisplay data={loanData} itemsPerRow={5} />
                </Collapsible>
              </View>
              <View style={styles.card}>
                <Text style={styles.cardHeader}>Applicant Summary</Text>
                <Divider style={styles.cardDivider} />
                <TouchableWithoutFeedback
                  onPress={() => setIsBusinessOpen(!isBusinessOpen)}
                  style={styles.flexRow}
                >
                  <Text style={styles.cardSection} appearance="hint">
                    BUSINESS
                  </Text>
                  <Icon
                    style={[
                      styles.chevronButton,
                      {
                        transform: [
                          { rotate: isBusinessOpen ? "180deg" : "0deg" },
                        ],
                      },
                    ]}
                    name="chevron-down-outline"
                  />
                </TouchableWithoutFeedback>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isBusinessOpen}>
                  <View style={styles.flexRow}>
                    <View
                      style={{
                        ...styles.flexRow,
                        justifyContent: "flex-start",
                        flex: 2,
                      }}
                    >
                      {errors.error !== "" && (
                        <Text
                          style={{
                            ...styles.error,
                            paddingHorizontal: "1%",
                            paddingVertical: "3%",
                          }}
                        >
                          {errors.error}
                        </Text>
                      )}
                    </View>
                    <View
                      style={{
                        ...styles.flexRow,
                        justifyContent: "flex-end",
                      }}
                    >
                      {canEdit && (
                        <Button
                          style={styles.gradientButton}
                          onPress={() => setIsEditing(true)}
                        >
                          Edit Data
                        </Button>
                      )}
                    </View>
                  </View>
                  <TableDisplay
                    data={businessData}
                    itemsPerRow={5}
                    isEditing={isEditing}
                    rawData={editData}
                    setRawData={setEditData}
                  />
                  {isEditing && (
                    <View style={{ ...styles.container, alignItems: "center" }}>
                      <View style={styles.flexRow}>
                        <Button
                          style={styles.gradientButton}
                          onPress={() => saveEdits()}
                        >
                          Save Changes
                        </Button>
                        <Button
                          style={styles.cancelButton}
                          onPress={() => setIsEditing(false)}
                          children={() => (
                            <Text style={styles.cancelButtonText}>Cancel</Text>
                          )}
                        />
                      </View>
                    </View>
                  )}
                </Collapsible>
                <Divider style={styles.cardDivider} />
                <TouchableWithoutFeedback
                  onPress={() =>
                    setIsBusinessPrincipalOpen(!isBusinessPrincipalOpen)
                  }
                  style={styles.flexRow}
                >
                  <Text style={styles.cardSection} appearance="hint">
                    BUSINESS PRINCIPAL 1 (PRIMARY APPLICANT)
                  </Text>
                  <Icon
                    style={[
                      styles.chevronButton,
                      {
                        transform: [
                          {
                            rotate: isBusinessPrincipalOpen ? "180deg" : "0deg",
                          },
                        ],
                      },
                    ]}
                    name="chevron-down-outline"
                  />
                </TouchableWithoutFeedback>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isBusinessPrincipalOpen}>
                  <TableDisplay data={businessPrincipalData} itemsPerRow={5} />
                </Collapsible>

                {businessPartnersData.map((partner: any, idx: number) => (
                  <View key={idx}>
                    <TouchableWithoutFeedback
                      onPress={() =>
                        setIsBusinessPartnerOpen(
                          isBusinessPartnerOpen.map(
                            (flag: boolean, idx2: number) =>
                              idx === idx2 ? !flag : flag
                          )
                        )
                      }
                      style={styles.flexRow}
                    >
                      <Text style={styles.cardSection} appearance="hint">
                        BUSINESS PRINCIPAL {idx + 2}
                      </Text>
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: isBusinessPartnerOpen[idx]
                                  ? "180deg"
                                  : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </TouchableWithoutFeedback>
                    <Divider style={styles.cardDivider} />
                    <Collapsible collapsed={!isBusinessPartnerOpen[idx]}>
                      <TableDisplay data={partner} itemsPerRow={5} />
                    </Collapsible>
                  </View>
                ))}
              </View>
            </View>
          )}
        </ScrollView>
      </Layout>
    </ThemeProvider>
  );
};

export default Summary;
