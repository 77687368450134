import {
  model,
  Model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";

@model("oppzo/DocumentRequestMessage")
export default class DocumentRequestMessage extends Model({
  id: prop<number>(),
  loanApplicationId: prop<number>(),
  authorId: prop<number | null>(),
  authorName: prop<string | null>(),
  isUnread: prop<boolean>(),
  created: prop<Date>(),
  status: prop<string>(),
  documentRequestType: prop<string | null>(),
  documentCategory: prop<string | null>(),
  documentName: prop<string | null>(),
  description: prop<string | null>(),
  rootMessageId: prop<number | null>(),
  parentMessageId: prop<number | null>(),
  attachments: prop<any[] | null>(),
  attachmentsMeta: prop<any[] | null>(),
  body: prop<string | null>(),
  replies: prop<DocumentRequestMessage[] | null>(),
  isReply: prop<boolean | null>(),
  applicantId: prop<number | null>(),
  applicantName: prop<string | null>(),
  applicantStateOfIncorporation: prop<string | null>(),
  applicantBusinessType: prop<string | null>(),
  applicantBusinessName: prop<string | null>(),
  applicationContractNumber: prop<string | null>(),
}) {
  @modelAction
  update(data: ModelCreationData<DocumentRequestMessage>) {
    Object.assign(this, data);
  }
}
