import { useLinkTo } from "@react-navigation/native";
import {
  Button,
  Input,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
} from "@ui-kitten/components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import { View, TouchableOpacity } from "react-native";

import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const SuccessResetPassword: React.FC<{}> = (props) => {
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);

  const [loading, setLoading] = useState(false);
  const defaultFields = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState({ ...defaultFields });
  const [errorMessage, setErrorMessage] = useState("");
  const [securedText, setSecuredText] = useState(true);
  const passwordRef = useRef<Input>(null);

  const { authStore } = useStore();

  const { register, setValue, handleSubmit, control } = useForm();
  const { errors } = useFormState({ control });

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LockIcon = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText(!securedText)}>
      <Icon {...props} name={securedText ? "lock-outline" : "unlock-outline"} />
    </TouchableOpacity>
  );
  const MailIcon = (props: any) => <Icon {...props} name="email-outline" />;

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setErrorMessage("");
      const result = await authStore.logIn(data);
      if (!result.ok) {
        setLoading(false);
        setErrorMessage(
          result?.errors?.nonFieldErrors?.length
            ? result.errors.nonFieldErrors[0]
            : "Backend server could not be reached."
        );
      }
    },
    [loading]
  );

  const isNextActionButtonDisabled = useCallback(() => {
    return !formData.email || !formData.password;
  }, [formData]);

  useEffect(() => {
    register("email", {
      required: "E-mail is required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid E-mail address",
      },
    });
    register("password", { required: "Password is required" });
  }, [register]);

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onSubmitEditingEmail = useCallback(() => {
    passwordRef.current?.focus();
  }, []);

  return (
    <View style={styles.resetPassword}>
      <Text style={styles.header}>Password Reset Success!</Text>
      <Text style={styles.subHeader}>
        Don’t have an account yet?{" "}
        <Text
          status="info"
          style={styles.link}
          onPress={() => linkTo("/auth/register")}
        >
          Sign Up
        </Text>
        .
      </Text>
      <Text style={styles.subHeader}>
        {"\n\n"}
        You have successfully created a new password. Try logging in now.
      </Text>
      {!!errorMessage && (
        <Text category="h6" style={styles.error}>
          {errorMessage}
        </Text>
      )}
      <Input
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Work email address"
        autoCorrect={false}
        autoCapitalize="none"
        autoCompleteType="email"
        accessoryRight={MailIcon}
        onChangeText={(text) => {
          setValue("email", text);
          setFormData({ ...formData, email: text });
        }}
        onSubmitEditing={onSubmitEditingEmail}
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            onSubmitEditingEmail();
          }
        }}
        returnKeyType="next"
        status={errors.email ? "danger" : "basic"}
        caption={errors.email && errors.email.message}
        testID="emailField"
      />
      <Input
        ref={passwordRef}
        style={styles.input}
        placeholder="Password"
        autoCapitalize="none"
        autoCompleteType="password"
        autoCorrect={false}
        secureTextEntry={securedText}
        accessoryRight={LockIcon}
        onChangeText={(text) => {
          setValue("password", text);
          setFormData({ ...formData, password: text });
        }}
        onSubmitEditing={onSubmitWrapped}
        returnKeyType="go"
        status={errors.password ? "danger" : "basic"}
        caption={errors.password && errors.password.message}
        testID="passwordField"
      />

      <Text
        status="info"
        style={styles.forgotPasswordText}
        onPress={() => linkTo("/auth/password-reset")}
      >
        Forgot password?{"  "}
      </Text>

      <Button
        disabled={isNextActionButtonDisabled()}
        style={
          isNextActionButtonDisabled()
            ? styles.disabledActionButton
            : styles.resetPasswordButton
        }
        status="basic"
        onPress={onSubmitWrapped}
        accessoryRight={loading ? LoadingIndicator : ArrowIcon}
        children={() => (
          <Text
            style={
              isNextActionButtonDisabled()
                ? styles.disabledActionButtonText
                : styles.resetPasswordButtonText
            }
          >
            sign in{"  "}
          </Text>
        )}
      />
    </View>
  );
};

export default SuccessResetPassword;
