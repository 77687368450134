import * as eva from "@eva-design/eva";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import {
  Button,
  Layout,
  useStyleSheet,
  Icon,
  Divider,
  Drawer,
  DrawerGroup,
  DrawerItem,
  Modal,
  Text,
  ThemeProvider,
  Tooltip,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native";
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons";

import config from "../../config";
import { useInterval } from "../../hooks";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const Header: React.FC<any> = ({ theme = "dark" }) => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { authStore, notificationsStore, loanStore, documentRequestsStore } =
    useStore();
  const logoImageLight = require("../../../assets/OppzoLogo.svg");
  const logoImageBlack = require("../../../assets/OppzoLogoBlack.svg");

  const MenuIcon = (props: any) => <Icon {...props} name="menu-outline" />;
  const RequestsIcon = (props: any) => (
    <Icon {...props} name="folder-add-outline" />
  );
  const LoansIcon = (props: any) => (
    <Icon {...props} name="file-text-outline" />
  );

  const ManageProfileIcon = (props: any) => (
    <Icon {...props} name="person-outline" />
  );

  const InvestorDashboardIcon = (props: any) => (
    <MaterialCommunityIcon
      {...props}
      name="poll-box-outline"
      size={20}
      color="gray"
    />
  );

  const InvestorLoanTapeIcon = (props: any) => (
    <MaterialCommunityIcon {...props} name="receipt" size={20} color="gray" />
  );

  const InvestorUnderwriterPortalIcon = (props: any) => (
    <MaterialCommunityIcon
      {...props}
      name="clipboard-check"
      size={20}
      color="gray"
    />
  );

  const [width, setWidth] = useState(Dimensions.get("window").width);
  const [height, setHeight] = useState(Dimensions.get("window").height);
  useEffect(() => {
    const listener: any = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        console.log(window.width, window.height);
        setWidth(window.width);
        setHeight(window.height);
      }
    );
    return () => listener?.remove();
  });

  const DashboardIcon = (props: any) => <Icon {...props} name="grid-outline" />;

  const ExternalLinkIcon = (props: any) => (
    <Icon {...props} name="external-link-outline" />
  );

  const UnderwriterPortalIcon = (props: any) => (
    <Icon {...props} name="briefcase-outline" />
  );

  const LoadingIndicator = (_props: any) => (
    <View style={{ alignItems: "center" }}>
      <Spinner status="primary" size="giant" />
    </View>
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [loans, setLoans] = useState<any[]>([]);

  const format = (value: any, pattern: any) => {
    let i = 0,
      v = value.toString();
    return pattern.replace(/#/g, (_: any) => v[i++]);
  };

  const requestsShortcutLookup = {
    "Request for Personal Financial Statements":
      "Personal Financial Statements",
    "Request for Tax returns (Personal); last two years":
      "Personal Tax Returns",
    "Request for Tax returns (Corporate); last two years":
      "Corporate Tax Returns",
    "Request for Last two years FYE Financial Statements (Corporate)":
      "FYE Statements",
    "Request for Current YTD and Monthly Interim Financial Statements with prior comparable period":
      "Current YTD",
    "Request for Debt Schedule with Copies of Applicable Note(s)":
      "Debt Schedule",
    "Request for Executed contract with Government, State & Local (Sample Invoices with Corresponding Backup, Copies of contracts, & Modifications)":
      "Executed Contract",
    "Request for Accounts Receivable aging (Current Period & Month End A/R Detailed Aging, Aged by Invoice Date)":
      "Accounts Receivable Aging",
    "Request for FYE Financial Projections by Month to Include BS, IS & CF Statement":
      "FYE Projections",
    "Request for ByLaws": "ByLaws",
    "Request for Contract Backlog Report": "Contract Backlog Report",
    "Request for Bank Statements for all Operating and Payroll Accounts; last 2 years":
      "Bank Statements",
    "Request for Accounts Payable aging (Current Period & Month End A/P Detailed Aging, Aged by Invoice Date)":
      "Accounts Payable Aging",
    "Request for Good Standing Certificate": "Good Standing Certificate",
    "Request for Articles of Incorporation and any applicable amendments":
      "Articles of Incorporation",
    "Request for Company Organizational Structure Chart":
      "Organizational Structure Chart",
    "Request for Operating Agreement": "Operating Agreement",
    "Request for Corporate Resolution/Authorizing Resolution":
      "Corporate/Authorizing Resolution",
    "Request for Incumbency of Officers/Incumbency Certificate": "Incumbency",
    "Request for Equifax Entity Report": "Equifax Entity Report",
    "Request for Equifax Consumer Report": "Equifax Consumer Report",
    "Request for Fedmine Report": "Fedmine Report",
    "Request for Closing Document": "Closing Document",
    "Request for Covenant Compliance Document": "Covenant Compliance Document",
    "Request for Other": "Other",
    "Request for ": "Other",
  };

  useEffect(() => {
    if (
      !authStore.user?.isUnderwriter &&
      !authStore.user?.isStaff &&
      !authStore.user?.isInvestor
    ) {
      (async () => {
        setLoading(true);
        await fetchDocumentRequestMessagesForUser();
        await loanStore.fetchLoanApplications();
        const newLoans: any = [];
        loanStore.loanApplications.forEach((loan) => {
          if (
            authStore.user?.businesses?.includes(loan.business) &&
            !loan.isTemporary
          )
            newLoans.push(loan.$);
        });
        setLoans(newLoans);
        setLoading(false);
      })();
    }
  }, []);

  const [selectedIndexUW, setSelectedIndexUW]: any = useState(null);
  const [selectedIndexInvestor, setSelectedIndexInvestor]: any = useState(null);
  const [selectedReferralPartnerIndex, setSelectedReferralPartnerIndex]: any =
    useState(null);
  const [selectedInboundReferralIndex, setSelectedInboundReferralIndex]: any =
    useState(null);
  const [selectedIndexServicingAgent, setSelectedIndexServicingAgent]: any =
    useState(null);
  const [selectedIndexManagement, setSelectedIndexManagement]: any =
    useState(null);
  const [selectedIndexUser, setSelectedIndexUser]: any = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationsTooltipShowing, setIsNotificationsTooltipShowing] =
    useState(false);

  const [loggedIn, setLoggedIn] = useState<boolean>(!!authStore.accessToken);
  const getMenuOfLoggedInUser = () =>
    authStore.user?.isStaff || authStore.user?.isUnderwriter
      ? "underwriter"
      : authStore.user?.isInvestor
      ? "investor"
      : authStore.user?.isReferralPartner
      ? "referralPartner"
      : authStore.user?.isInboundReferralManager
      ? "inboundReferralManager"
      : authStore.user?.isLoanAgent
      ? "servicing"
      : "user";

  const [menu, setMenu] = useState(getMenuOfLoggedInUser());
  const [notifications, setNotifications] = useState<any>([]);
  const [requestedDocuments, setRequestedDocuments] = useState<any>([]);

  useEffect(() => {
    setLoggedIn(!!(authStore.accessToken && authStore.user));
    setMenu(getMenuOfLoggedInUser());
  }, [authStore.user?.id, authStore.accessToken]);

  const getMessageDisplayObj = (docReqMessage: any) => ({
    ...docReqMessage,
    applicationId: docReqMessage.loanApplicationId,
    title: `Request for ${
      docReqMessage.documentRequestType === "Other"
        ? docReqMessage.documentName && docReqMessage.documentName !== null
          ? docReqMessage.documentName
          : `${docReqMessage.documentCategory} File`
        : docReqMessage.documentRequestType
    }`,
    for: docReqMessage.applicantName,
    date: new Date(docReqMessage.created).toLocaleDateString(),
    attachments: docReqMessage.attachments || [],
  });

  const fetchDocumentRequestMessagesForUser = async () => {
    await documentRequestsStore.fetchDocumentRequestMessagesForUser();
    const reqDocuments: any = [];
    const replies: any = {};
    documentRequestsStore.messages.forEach((msg) => {
      if (Number(msg.applicantId) === Number(authStore?.user?.id)) {
        if (msg.isReply) replies[msg.id] = msg;
        else reqDocuments.push(getMessageDisplayObj(msg.$));
      }
    });
    reqDocuments.sort((a: any, b: any) =>
      new Date(a.created) > new Date(b.created) ? -1 : 1
    );
    reqDocuments.forEach((doc: any) => {
      doc.replies = doc.replies.map((replyId: any) => replies[replyId]);
    });

    setRequestedDocuments(reqDocuments);
  };

  const isTestEnv = () =>
    config.urls.api.includes("test") ||
    config.urls.api.includes("dev") ||
    config.urls.api.includes("local") ||
    config.urls.api.includes("127.0.0.1") ||
    config.urls.api.includes("demo");

  useEffect(() => {
    if ((menu === "underwriter" || menu === "investor") && selectedIndexUW) {
      //underwriter
      if (!selectedIndexUW.section)
        switch (selectedIndexUW.row) {
          case 0:
            setIsMenuOpen(false);
            navigation.navigate("underwriter", {
              screen: "dashboard",
            });
            break;
          case 2:
            setIsMenuOpen(false);
            navigation.navigate("admin", {
              screen: "requested-documents",
            });
            break;
          case 3:
            setIsMenuOpen(false);
            navigation.navigate("admin", {
              screen: "closing",
            });
            break;
        }
      else {
        // nested sub-menu
        switch (selectedIndexUW.row) {
          case 0:
            setIsMenuOpen(false);
            navigation.navigate("underwriter", {
              screen: "summary",
              params: { applicationId: authStore.lastReviewedLoanId },
            });
            break;
          case 1:
            setIsMenuOpen(false);
            navigation.navigate("underwriter", {
              screen: "collateral",
              params: { applicationId: authStore.lastReviewedLoanId },
            });
            break;
          case 2:
            setIsMenuOpen(false);
            navigation.navigate("underwriter", {
              screen: "risk-flags",
              params: { applicationId: authStore.lastReviewedLoanId },
            });
            break;
          case 3:
            setIsMenuOpen(false);
            navigation.navigate("underwriter", {
              screen: "decision",
              params: { applicationId: authStore.lastReviewedLoanId },
            });
            break;
          case 4:
            setIsMenuOpen(false);
            navigation.navigate("underwriter", {
              screen: "credit-files",
              params: { applicationId: authStore.lastReviewedLoanId },
            });
            break;
        }
      }
    }
  }, [selectedIndexUW]);

  useEffect(() => {
    if (selectedIndexServicingAgent) {
      // servicing agent
      switch (selectedIndexServicingAgent.row) {
        case 0:
          setIsMenuOpen(false);
          navigation.navigate("loan-servicing", {
            screen: "dashboard",
          });
          break;
        case 1:
          setIsMenuOpen(false);
          window.open(
            isTestEnv()
              ? "https://servicing.test.getoppzo.com"
              : "https://servicing.getoppzo.com",
            "_blank"
          );
          break;
      }
    }
  }, [selectedIndexServicingAgent]);

  useEffect(() => {
    if (selectedIndexInvestor) {
      //investor
      switch (selectedIndexInvestor.row) {
        case 0:
          setIsMenuOpen(false);
          navigation.navigate("investor", {
            screen: "dashboard",
          });
          break;
        case 1:
          setIsMenuOpen(false);
          navigation.navigate("investor", {
            screen: "loan-tape",
          });
          break;
        case 2:
          setIsMenuOpen(false);
          navigation.navigate("investor", {
            screen: "uw-portal",
          });
          break;
      }
    }
  }, [selectedIndexInvestor]);

  useEffect(() => {
    if (selectedInboundReferralIndex) {
      switch (selectedInboundReferralIndex.row) {
        case 0:
          setIsMenuOpen(false);
          navigation.navigate("inbound-referrals", {
            screen: "dashboard",
          });
          break;
        case 1:
          setIsMenuOpen(false);
          navigation.navigate("inbound-referrals", {
            screen: "approval",
          });
          break;
        case 2:
          setIsMenuOpen(false);
          navigation.navigate("inbound-referrals", {
            screen: "manage-profile",
          });
          break;
      }
    }
  }, [selectedInboundReferralIndex]);

  useEffect(() => {
    if (selectedReferralPartnerIndex) {
      switch (selectedReferralPartnerIndex.row) {
        case 0:
          setIsMenuOpen(false);
          navigation.navigate("referral", {
            screen: "dashboard",
          });
          break;
        case 1:
          setIsMenuOpen(false);
          navigation.navigate("referral", {
            screen: "guidelines",
          });
          break;
        case 2:
          setIsMenuOpen(false);
          navigation.navigate("referral", {
            screen: "manage-profile",
          });
          break;
      }
    }
  }, [selectedReferralPartnerIndex]);

  useEffect(() => {
    if (selectedIndexManagement) {
      //management
      switch (selectedIndexManagement.row) {
        case 0:
          setIsMenuOpen(false);
          if (menu === "underwriter") {
            navigation.navigate("underwriter", {
              screen: "manage-profile",
            });
          } else if (menu === "investor") {
            navigation.navigate("investor", {
              screen: "manage-profile",
            });
          } else {
            navigation.navigate("loan-servicing", {
              screen: "manage-profile",
            });
          }
          break;
      }
    }
  }, [selectedIndexManagement]);

  useEffect(() => {
    if (menu === "user" && selectedIndexUser) {
      //user
      console.log(selectedIndexUser);
      if (!selectedIndexUser.section) {
        switch (selectedIndexUser.row) {
          case 0:
            setIsMenuOpen(false);
            navigation.navigate("dashboard");
            break;
          case 3:
            setIsMenuOpen(false);
            navigation.navigate("closing-documents");
            break;
          case 4:
            setIsMenuOpen(false);
            window.open(
              isTestEnv()
                ? "https://loans.test.getoppzo.com"
                : "https://loans.getoppzo.com",
              "_blank"
            );
            break;
          case 5:
            setIsMenuOpen(false);
            navigation.navigate("manage-profile");
            break;
        }
      } else {
        if (selectedIndexUser.section === 1) {
          if (loans.length > 0) {
            setIsMenuOpen(false);
            navigation.navigate("loan-view", {
              id: loans[selectedIndexUser.row].id,
            });
          }
        } else {
          if (requestedDocuments.length > 0) {
            setIsMenuOpen(false);
            navigation.navigate("request-center", {
              selectedRequest: selectedIndexUser.row,
            });
          } else {
            setIsMenuOpen(false);
            navigation.navigate("request-center");
          }
        }
      }
    }
  }, [selectedIndexUser]);

  useInterval(() => {
    if (!notificationsStore.loading)
      notificationsStore.fetchNotifications().then(() => {
        const newNotifications: any = [];
        notificationsStore.notifications.forEach((notification) => {
          newNotifications.push(notification.$);
        });
        if (
          newNotifications.map((n: any) => n.id).join("") !==
          notifications.map((n: any) => n.id).join("")
        )
          setNotifications(newNotifications);
      });
  }, 500);

  const UserMenu = (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Drawer
          appearance="noDivider"
          style={styles.menu}
          selectedIndex={selectedIndexUser}
          onSelect={(index) => setSelectedIndexUser(index)}
        >
          <DrawerItem
            title="Dashboard"
            accessoryLeft={DashboardIcon}
            style={styles.menuItem}
          />
          <DrawerGroup
            title="Loans"
            accessoryLeft={LoansIcon}
            style={styles.menuItem}
          >
            {loans.length > 0 ? (
              loans.map((loan) => (
                <DrawerItem key={loan.id} title={`Loan ID ${loan.id}`} />
              ))
            ) : (
              <DrawerItem key={0} title="No Loan Applications yet." />
            )}
          </DrawerGroup>
          <DrawerGroup
            title="Requests"
            accessoryLeft={RequestsIcon}
            style={{ ...styles.menuItem }}
          >
            {requestedDocuments.length > 0 ? (
              requestedDocuments.map((item: any, index: number) => (
                <DrawerItem
                  title={() => {
                    let s: keyof typeof requestsShortcutLookup = item.title;
                    if (requestsShortcutLookup[s] == undefined) {
                      s = "Request for Other";
                    }
                    return (
                      <View style={{ ...styles.flexRow, alignItems: "center" }}>
                        {item.isUnread && (
                          <View style={styles.activeNotificationCircle} />
                        )}
                        <Text
                          style={{ fontSize: 13, flex: 1 }}
                        >{`${requestsShortcutLookup[s]}`}</Text>
                      </View>
                    );
                  }}
                  key={index}
                />
              ))
            ) : (
              <DrawerItem key={0} title="No Requests right now." />
            )}
          </DrawerGroup>
          <DrawerItem
            title="Closing Documents"
            accessoryLeft={LoansIcon}
            style={styles.menuItem}
          />
          <DrawerItem
            title="Servicing"
            accessoryLeft={ExternalLinkIcon}
            style={styles.menuItem}
            disabled={
              !loans.filter(
                (loan) => loan.status === "funded" || loan.status === "done"
              ).length
            }
          />
          <DrawerItem
            title="Manage Profile"
            accessoryLeft={ManageProfileIcon}
            style={styles.menuItem}
          />
        </Drawer>
      )}
    </>
  );

  const ManagementMenu = (
    <>
      <Text style={styles.menuTextSubheader}>Management</Text>
      <Drawer
        appearance="noDivider"
        style={styles.menu}
        selectedIndex={selectedIndexManagement}
        onSelect={(index) => {
          setSelectedIndexManagement(index);
        }}
      >
        <DrawerItem
          title="Manage Profile"
          accessoryLeft={ManageProfileIcon}
          style={styles.menuItem}
        />
      </Drawer>
    </>
  );

  const InvestorMenu = (
    <>
      <Text style={styles.menuTextSubheader}>Investor</Text>
      <Drawer
        appearance="noDivider"
        style={styles.menu}
        selectedIndex={selectedIndexInvestor}
        onSelect={(index) => {
          setSelectedIndexInvestor(index);
        }}
      >
        <DrawerItem
          title="Dashboard"
          accessoryLeft={InvestorDashboardIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Loan Tape"
          accessoryLeft={InvestorLoanTapeIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Underwriter Portal"
          accessoryLeft={InvestorUnderwriterPortalIcon}
          style={styles.menuItem}
        />
      </Drawer>
      <Divider />
      {!authStore.user?.isStaff && ManagementMenu}
    </>
  );

  const ServicingAgentMenu = (
    <>
      <Text style={styles.menuTextSubheader}>Servicing</Text>
      <Drawer
        appearance="noDivider"
        style={styles.menu}
        selectedIndex={selectedIndexServicingAgent}
        onSelect={(index) => {
          setSelectedIndexServicingAgent(index);
        }}
      >
        <DrawerItem
          title="Dashboard"
          accessoryLeft={DashboardIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Servicing"
          accessoryLeft={ExternalLinkIcon}
          style={styles.menuItem}
        />
      </Drawer>
      <Divider />
      {!authStore.user?.isStaff && ManagementMenu}
    </>
  );

  const InboundReferralManagerMenu = (
    <>
      <Text style={styles.menuTextSubheader}>Inbound Referrals</Text>
      <Drawer
        appearance="noDivider"
        style={styles.menu}
        selectedIndex={selectedInboundReferralIndex}
        onSelect={(index) => {
          setSelectedInboundReferralIndex(index);
        }}
      >
        <DrawerItem
          title="Dashboard"
          accessoryLeft={InvestorDashboardIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Approvals"
          accessoryLeft={RequestsIcon}
          style={styles.menuItem}
        />
        {!authStore.user?.isStaff && (
          <DrawerItem
            title="Manage Profile"
            accessoryLeft={ManageProfileIcon}
            style={styles.menuItem}
          />
        )}
      </Drawer>
    </>
  );

  const ReferralPartnerMenu = (
    <>
      <Text style={styles.menuTextSubheader}>Referral Partner</Text>
      <Drawer
        appearance="noDivider"
        style={styles.menu}
        selectedIndex={selectedReferralPartnerIndex}
        onSelect={(index) => {
          setSelectedReferralPartnerIndex(index);
        }}
      >
        <DrawerItem
          title="Dashboard"
          accessoryLeft={InvestorDashboardIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Guidelines"
          accessoryLeft={RequestsIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Manage Profile"
          accessoryLeft={ManageProfileIcon}
          style={styles.menuItem}
        />
      </Drawer>
    </>
  );

  const UnderWriterMenu = (
    <>
      <Text style={styles.menuTextSubheader}>Underwriter</Text>
      <Drawer
        appearance="noDivider"
        style={styles.menu}
        selectedIndex={selectedIndexUW}
        onSelect={(index) => {
          setSelectedIndexUW(index);
        }}
      >
        <DrawerItem
          title="Dashboard"
          accessoryLeft={DashboardIcon}
          style={styles.menuItem}
        />
        <DrawerGroup
          title="Underwriter portal"
          accessoryLeft={UnderwriterPortalIcon}
        >
          <DrawerItem title="Summary" />
          <DrawerItem title="Collateral and reports" />
          <DrawerItem title="Risk flags" />
          <DrawerItem title="Decision" />
          <DrawerItem title="Credit files" />
        </DrawerGroup>
        <DrawerItem
          title="Requests"
          accessoryLeft={RequestsIcon}
          style={styles.menuItem}
        />
        <DrawerItem
          title="Closing Queue"
          accessoryLeft={DashboardIcon}
          style={styles.menuItem}
        />
      </Drawer>
      <Divider />
      {authStore.user?.isLoanAgent && ServicingAgentMenu}
      {authStore.user?.isInvestor && InvestorMenu}
      {authStore.user?.isInboundReferralManager && InboundReferralManagerMenu}
      {ManagementMenu}
    </>
  );

  return (
    <ThemeProvider theme={theme === "light" ? eva.light : eva.dark}>
      <Layout style={styles.container}>
        <Modal style={{ ...styles.menuContainer, width }} visible={isMenuOpen}>
          <ThemeProvider
            theme={
              authStore.user?.isStaff || theme === "light"
                ? eva.light
                : eva.dark
            }
          >
            <ScrollView style={{ ...styles.menu, height }}>
              <View
                style={{ ...styles.flexRow, justifyContent: "space-between" }}
              >
                {theme === "light" && (
                  <Image
                    style={{ width: 136, height: 68 }}
                    source={logoImageBlack}
                  />
                )}
                {theme === "dark" && (
                  <Image
                    style={{ width: 136, height: 68 }}
                    source={logoImageLight}
                  />
                )}
                {loggedIn && (
                  <Button
                    appearance="ghost"
                    accessoryRight={MenuIcon}
                    status="basic"
                    onPress={() => setIsMenuOpen(false)}
                  />
                )}
              </View>
              <View style={styles.menuUserDescContainer}>
                <Text
                  style={styles.menuTextHeader}
                >{`${authStore.user?.firstName} ${authStore.user?.lastName}`}</Text>
                <Text style={styles.menuTextDescription} appearance="hint">
                  {authStore.user?.isStaff
                    ? "Administrator"
                    : authStore.user?.isUnderwriter
                    ? "Underwriter"
                    : authStore.user?.isInvestor
                    ? "Investor"
                    : authStore.user?.isReferralPartner
                    ? "Referral Partner"
                    : authStore.user?.phoneNumber.substring(0, 2) === "+1" &&
                      authStore.user?.phoneNumber.length <= 12
                    ? format(
                        authStore.user?.phoneNumber.substring(2),
                        "+1 (###) ###-####"
                      )
                    : authStore.user?.phoneNumber}
                </Text>
              </View>
              {menu === "user"
                ? UserMenu
                : menu == "investor"
                ? InvestorMenu
                : menu == "inboundReferralManager"
                ? InboundReferralManagerMenu
                : menu == "referralPartner"
                ? ReferralPartnerMenu
                : menu == "underwriter"
                ? UnderWriterMenu
                : ServicingAgentMenu}
              {(width > 576 && loggedIn) || !loggedIn ? (
                <></>
              ) : (
                <Button
                  onPress={() => {
                    if (loggedIn) {
                      const link =
                        authStore.user?.isStaff || authStore.user?.isUnderwriter
                          ? "/admin/log-in"
                          : authStore.user?.isInvestor
                          ? "/investor/log-in"
                          : "/auth/log-in";
                      setLoggedIn(false);
                      authStore.logOut();
                      linkTo(link);
                    } else {
                      navigation.navigate("log-in");
                    }
                  }}
                  style={
                    theme === "dark" ? styles.button : styles.UWPortalButton
                  }
                  status="basic"
                  children={() => (
                    <Text style={styles.buttonText}>
                      {loggedIn ? "LOG OUT" : "LOG IN"}
                    </Text>
                  )}
                />
              )}
            </ScrollView>
            <TouchableOpacity
              style={{
                ...(width > 992
                  ? styles.backdrop
                  : width > 768
                  ? styles.backdropMedium
                  : width > 576
                  ? styles.backdropSmall
                  : styles.backdropMobile),
                height,
              }}
              onPress={() => setIsMenuOpen(false)}
            />
          </ThemeProvider>
        </Modal>
        <View style={styles.header}>
          <View style={styles.flexRow}>
            {/* TODO: remove later */}
            <TouchableOpacity
              onPress={() => {
                const link = document.createElement("a");
                link.href = "https://www.getoppzo.com/";
                document.body.appendChild(link);
                link.click();
              }}
            >
              {theme === "light" && (
                <Image
                  style={{ width: 136, height: 68 }}
                  source={logoImageBlack}
                />
              )}
              {theme === "dark" && (
                <Image
                  style={{ width: 136, height: 68 }}
                  source={logoImageLight}
                />
              )}
            </TouchableOpacity>
            {loggedIn ? (
              <Button
                appearance="ghost"
                accessoryRight={MenuIcon}
                status="basic"
                onPress={() => setIsMenuOpen(true)}
              />
            ) : (
              <></>
            )}
          </View>
          <View style={styles.flexRow}>
            {loggedIn ? (
              <Tooltip
                anchor={() => (
                  <TouchableOpacity
                    style={styles.notificationIcon}
                    onPress={() => setIsNotificationsTooltipShowing(true)}
                  >
                    <Icon
                      width={24}
                      height={24}
                      fill={theme === "light" ? "black" : "white"}
                      name="bell-outline"
                    />
                    {notifications.filter(
                      (notification: any) => !notification.isRead
                    ).length ? (
                      <View style={styles.notificationCircle} />
                    ) : (
                      <></>
                    )}
                  </TouchableOpacity>
                )}
                visible={isNotificationsTooltipShowing}
                onBackdropPress={() => setIsNotificationsTooltipShowing(false)}
                children={() =>
                  notifications.length === 0 ? (
                    <Text
                      style={
                        theme === "light"
                          ? styles.tooltipTextLight
                          : styles.tooltipTextDark
                      }
                    >
                      No Notifications
                    </Text>
                  ) : (
                    <ScrollView style={styles.notificationsContainer}>
                      {notifications.map((notification: any, idx: number) => (
                        <View key={notification.id}>
                          <View style={styles.flexRowItemsCenter}>
                            <Text
                              style={
                                theme === "light"
                                  ? styles.tooltipTextLight
                                  : styles.tooltipTextDark
                              }
                              onPress={() => {
                                if (notification.data.url) {
                                  window.location.href = notification.data.url;
                                }
                              }}
                            >
                              {notification.text}
                            </Text>
                            <TouchableOpacity
                              onPress={() => {
                                notificationsStore.markNotificationAsRead(
                                  notification.id
                                );
                                setNotifications(
                                  notifications.filter(
                                    (obj: any) => obj.id !== notification.id
                                  )
                                );
                              }}
                            >
                              <Icon
                                width={24}
                                height={24}
                                fill={theme === "light" ? "white" : "black"}
                                name="close-outline"
                                style={{ padding: 5 }}
                              />
                            </TouchableOpacity>
                          </View>
                          {idx + 1 !== notifications.length ? (
                            <View style={styles.notificationDivider} />
                          ) : (
                            <></>
                          )}
                        </View>
                      ))}
                    </ScrollView>
                  )
                }
              />
            ) : (
              <></>
            )}
            {(width > 576 && loggedIn) || !loggedIn ? (
              <Button
                onPress={() => {
                  if (loggedIn) {
                    const link =
                      authStore.user?.isStaff || authStore.user?.isUnderwriter
                        ? "/admin/log-in"
                        : authStore.user?.isInvestor
                        ? "/investor/log-in"
                        : authStore.user?.isReferralPartner
                        ? "/referral/log-in"
                        : "/auth/log-in";
                    setLoggedIn(false);
                    authStore.logOut();
                    linkTo(link);
                  } else {
                    navigation.navigate("log-in");
                  }
                }}
                style={theme === "dark" ? styles.button : styles.UWPortalButton}
                status="basic"
                children={() => (
                  <Text style={styles.buttonText}>
                    {loggedIn ? "LOG OUT" : "LOG IN"}
                  </Text>
                )}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
      </Layout>
    </ThemeProvider>
  );
};

export default Header;
