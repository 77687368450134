import { observable } from "mobx";
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  modelAction,
  prop,
  objectMap,
  ModelCreationData,
} from "mobx-keystone";

import DocumentRequestMessage from "../models/DocumentRequestMessage";
import * as api from "../services/api";
import Store from "./Store";

type Extra = { [key: string]: any } | null;
type Response = { errors: any; ok: boolean; extra: Extra };

const getError = (error: Error): Response => {
  try {
    return { errors: JSON.parse(error.message), ok: false, extra: null };
  } catch {
    return { errors: { detail: error.message }, ok: false, extra: null };
  }
};

const _success = { errors: { detail: null }, ok: true, extra: null };
const getSuccess = (extra?: Extra): Response =>
  extra ? { ..._success, extra } : _success;

@model("oppzo/DocumentRequestsStore")
export default class DocumentRequestsStore extends Model({
  messages: prop(() => objectMap<DocumentRequestMessage>()),
}) {
  @observable
  loading = false;

  @modelAction
  createOrUpdateDocumentRequestMessage(
    data: ModelCreationData<DocumentRequestMessage>
  ) {
    const id = `${data.id}`;
    if (this.messages.has(id)) {
      this.messages.get(id)!.update(data);
    } else {
      const condition = new DocumentRequestMessage(data);
      this.messages.set(id, condition);
      condition.update(data);
    }
  }

  @modelAction
  removeDocumentRequestMessage(id: number) {
    this.messages.delete(`${id}`);
  }

  @modelFlow
  addDocumentRequestMessage = _async(function* (
    this: DocumentRequestsStore,
    data: DocumentRequestMessage
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<DocumentRequestMessage>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.addDocumentRequestMessage(token, data)));
    } catch (error: any) {
      console.warn("[DEBUG] error adding condition", error);
      return getError(error);
    }

    this.createOrUpdateDocumentRequestMessage(entities);

    this.loading = false;
    return getSuccess({ newDocumentRequest: entities });
  });

  @modelFlow
  updateDocumentRequestMessage = _async(function* (
    this: DocumentRequestsStore,
    id: number,
    data: DocumentRequestMessage
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<DocumentRequestMessage>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.updateDocumentRequestMessage(token, id, data)));
    } catch (error: any) {
      console.warn("[DEBUG] error adding condition", error);
      return getError(error);
    }

    this.createOrUpdateDocumentRequestMessage(entities);

    this.loading = false;
    return getSuccess({ newDocumentRequest: entities });
  });

  @modelFlow
  fetchDocumentRequestMessages = _async(function* (
    this: DocumentRequestsStore
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let results: ModelCreationData<DocumentRequestMessage>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchDocumentRequestMessages(token)));
    } catch (error: any) {
      console.warn("[DEBUG] error fetching DocumentRequestMessage", error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateDocumentRequestMessage(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDocumentRequestMessagesForApplication = _async(function* (
    this: DocumentRequestsStore,
    applicationId: string
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let results: ModelCreationData<DocumentRequestMessage>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(
        api.fetchDocumentRequestMessagesForLoanApplication(token, applicationId)
      ));
    } catch (error: any) {
      console.warn("[DEBUG] error fetching DocumentRequestMessage", error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateDocumentRequestMessage(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchDocumentRequestMessagesForUser = _async(function* (
    this: DocumentRequestsStore
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let results: ModelCreationData<DocumentRequestMessage>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchDocumentRequestMessagesForUser(token)));
    } catch (error: any) {
      console.warn("[DEBUG] error fetching DocumentRequestMessage", error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateDocumentRequestMessage(data));

    this.loading = false;
    return getSuccess();
  });
}
