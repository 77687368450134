import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Input,
  Divider,
  Select,
  SelectItem,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect, useRef } from "react";
import { Dimensions, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { useStore } from "../../stores";
import {
  acceptedBusinessStateOptions,
  businessTypeOptions,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const BusinessDetails: React.FC<any> = ({
  canEdit,
  business,
  theme = "dark",
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const LoadingIndicator = (props: any) => <Spinner />;

  const EditIcon = (props: any) => (
    <Icon {...props} name="edit-outline" fill="#26DDF1" />
  );

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const businessNameRef = useRef<Input>(null);
  const businessAddStreetLine1Ref = useRef<Input>(null);
  const businessAddStreetLine2Ref = useRef<Input>(null);
  const businessAddCityRef = useRef<Input>(null);
  const businessAddZipCodeRef = useRef<Input>(null);
  const workEmailRef = useRef<Input>(null);

  const { businessStore } = useStore();

  const [formData, setFormData] = useState({
    businessName: business.businessName,
    businessType: business.businessType,
    businessAddStreetLine1: business.businessAddStreetLine1,
    businessAddStreetLine2: business.businessAddStreetLine2,
    businessAddCity: business.businessAddCity,
    businessAddState: business.businessAddState,
    businessAddZipCode: business.businessAddZipCode,
  });

  useEffect(() => {
    setFormData({
      businessName: business.businessName,
      businessType: business.businessType,
      businessAddStreetLine1: business.businessAddStreetLine1,
      businessAddStreetLine2: business.businessAddStreetLine2,
      businessAddCity: business.businessAddCity,
      businessAddState: business.businessAddState,
      businessAddZipCode: business.businessAddZipCode,
    });
  }, [business]);

  const [businessAddStateIndex, setBusinessAddStateIndex] =
    React.useState<any>(null);
  const [businessTypeIndex, setBusinessTypeIndex] = React.useState<any>(null);

  const [errors, setErrors] = useState<any>({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  const ErrorDescription = (fieldName: string) => {
    if (!errors[fieldName]) return <></>;
    return <View style={styles.error}>{errors[fieldName]}</View>;
  };

  useEffect(() => {
    if (businessTypeIndex) {
      setFormData({
        ...formData,
        businessType: businessTypeOptions[businessTypeIndex.row],
      });
    }
  }, [businessTypeIndex]);

  useEffect(() => {
    if (businessAddStateIndex) {
      setFormData({
        ...formData,
        businessAddState:
          acceptedBusinessStateOptions[businessAddStateIndex.row],
      });
    }
  }, [businessAddStateIndex]);

  return (
    <View>
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Business Information
        </Text>
        {!!canEdit && (
          <Button
            style={styles.editButton}
            appearance="ghost"
            status="basic"
            accessoryLeft={EditIcon}
            onPress={() => {
              setIsEditing(!isEditing);
            }}
          />
        )}
      </View>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        {ErrorDescription("businessName")}
        <Input
          ref={businessNameRef}
          style={styles.input}
          disabled={!isEditing}
          value={formData.businessName}
          onChangeText={(text) =>
            setFormData({ ...formData, businessName: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddStreetLine1Ref.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddStreetLine1Ref.current?.focus()}
          placeholder="Business Name"
        />
        {ErrorDescription("businessType")}
        <Select
          status={theme === "dark" ? "primary" : "success"}
          size={width > 576 ? "medium" : "small"}
          style={styles.input}
          disabled={!isEditing}
          selectedIndex={businessTypeIndex}
          value={formData.businessType}
          onSelect={(index) => {
            if (Array.isArray(index)) {
              index = index[0];
            }
            setBusinessTypeIndex(index);
          }}
        >
          {businessTypeOptions.map((title) => (
            <SelectItem key={title} title={title} />
          ))}
        </Select>

        {ErrorDescription("businessAddStreetLine1")}
        <Input
          ref={businessAddStreetLine1Ref}
          style={styles.input}
          disabled={!isEditing}
          value={formData.businessAddStreetLine1}
          onChangeText={(text) =>
            setFormData({ ...formData, businessAddStreetLine1: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddStreetLine2Ref.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddStreetLine2Ref.current?.focus()}
          placeholder="Street Line 1"
        />

        {ErrorDescription("businessAddStreetLine2")}
        <Input
          ref={businessAddStreetLine2Ref}
          style={styles.input}
          disabled={!isEditing}
          value={formData.businessAddStreetLine2}
          onChangeText={(text) =>
            setFormData({ ...formData, businessAddStreetLine2: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddCityRef.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddCityRef.current?.focus()}
          placeholder="Street Line 2"
        />

        {ErrorDescription("businessAddCity")}
        {ErrorDescription("businessAddState")}
        {ErrorDescription("businessAddZipCode")}
        <View style={width > 800 ? styles.flexRow : {}}>
          <Input
            ref={businessAddCityRef}
            style={styles.input}
            disabled={!isEditing}
            value={formData.businessAddCity}
            onChangeText={(text) =>
              setFormData({ ...formData, businessAddCity: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                businessAddZipCodeRef.current?.focus();
              }
            }}
            onSubmitEditing={() => businessAddZipCodeRef.current?.focus()}
            placeholder="City"
          />
          <Select
            status={theme === "dark" ? "primary" : "success"}
            size={width > 576 ? "medium" : "small"}
            style={styles.input}
            disabled={!isEditing}
            selectedIndex={businessAddStateIndex}
            value={formData.businessAddState}
            onSelect={(index) => {
              if (Array.isArray(index)) {
                index = index[0];
              }
              setBusinessAddStateIndex(index);
            }}
          >
            {acceptedBusinessStateOptions.map((title) => (
              <SelectItem key={title} title={title} />
            ))}
          </Select>
          <Input
            ref={businessAddZipCodeRef}
            style={styles.input}
            disabled={!isEditing}
            value={formData.businessAddZipCode}
            onChangeText={(text) =>
              setFormData({
                ...formData,
                businessAddZipCode: /^\d{0,5}$/.test(text)
                  ? text
                  : formData.businessAddZipCode,
              })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                businessNameRef.current?.focus();
              }
            }}
            onSubmitEditing={() => businessNameRef.current?.focus()}
            placeholder="Zip code"
          />
        </View>

        {isEditing && (
          <Button
            style={{ ...styles.saveButton, width: 200, alignSelf: "center" }}
            onPress={async () => {
              setLoading(true);
              const res = await businessStore.updateBusiness(
                business.id,
                formData
              );
              setLoading(false);
              if (!res?.ok) {
                setErrors(res?.errors);
              } else {
                setErrors({});
                setIsEditing(false);
              }
            }}
            accessoryRight={loading ? LoadingIndicator : <></>}
          >
            Save
          </Button>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
