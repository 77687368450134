import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  flexRow: { flexDirection: "row" },
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 16,
    fontSize: 13,
    marginBottom: 0,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 16,
    fontSize: 13,
  },
  gradientContainer: {
    right: 0,
    top: 0,
    width: "100%",
    padding: 30,
    overflow: "hidden",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  gradientContainerMobile: {
    left: 0,
    top: 0,
    width: "100%",
    padding: 20,
    overflow: "hidden",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  contactUsContainer: {
    backgroundColor: "#222B45",
    padding: 30,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
  },
  errorMobile: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 10,
  },
  input: {
    marginBottom: 16,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderRadius: 4,
  },
  inputMobile: {
    marginBottom: 8,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderRadius: 4,
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "black",
    width: "100%",
  },
  inputTextMobile: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    fontSize: 12,
    color: "black",
    width: "100%",
  },
  gradientDivider: {
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
    height: 1,
    width: "100%",
    marginBottom: 16,
  },
  sendButtonContainer: {
    height: 40,
    marginHorizontal: 10,
    padding: 8,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  sendText: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
  },
  modalDocTypeList: {
    marginHorizontal: 12,
    backgroundColor: "white",
  },
  modalDocTypeListItem: {
    padding: 8,
    backgroundColor: "white",
  },
  modalCategoryTypeModal: {
    zIndex: 100,
    position: "absolute",
    width: "87%",
    left: 21.5,
    height: 125,
    top: 255,
    borderRadius: 4,
  },
  modalHeaderText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
    textTransform: "uppercase",
    letterSpacing: 1,
  },
  modalSubheaderText: {
    color: "#888",
    fontFamily: "Montserrat_500Medium",
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 1,
  },
  modalText: {
    color: "#8F9BB3",
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    fontWeight: "bold",
  },
  iconLarge: {
    width: 22,
    height: 22,
    marginHorizontal: 8,
  },
  modalIconRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    borderRadius: 4,
    marginBottom: 16,
    marginHorizontal: 5,
    flex: 1,
    padding: 10,
  },
});
