import {
  Button,
  Text,
  useStyleSheet,
  Icon,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View, Dimensions } from "react-native";

import { VerticalBarChart } from "./VerticalBarChart";
import { themedStyles } from "./themedStyles";

const InterestAndAgencyDistribution: React.FC<any> = ({
  agenciesData,
  interestData,
  maxInterest,
  minInterest,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const LeftArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-back-outline" />
  );
  const RightArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-forward-outline" />
  );

  const labels = [];
  for (let i = minInterest; i <= maxInterest; i++) labels.push(`${i} %`);

  const dropdownOptions = [
    "Interest Rate Distribution",
    "Government Agencies Distribution",
  ];

  const [interestRateChartData, setInterestRateChartData] = useState([[]]);

  const [selectedIndex, setSelectedIndex] = useState<any>(new IndexPath(0));
  const [currentView, setCurrentView] = useState(dropdownOptions[0]);

  const colors = ["#6A1DB8"];

  useEffect(() => {
    setInterestRateChartData(interestData.map((item: any) => [item]));
  }, [interestData]);

  useEffect(() => {
    if (selectedIndex) {
      setCurrentView(dropdownOptions[selectedIndex.row]);
    }
  }, [selectedIndex]);

  return (
    <View>
      <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
        <Select
          style={{ flex: 1, margin: 5 }}
          placeholder="Month(s)/Week(s)/Day(s)"
          value={currentView}
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          {dropdownOptions.map((title, idx) => (
            <SelectItem
              key={`chart-option-${idx}`}
              style={styles.selectItem}
              title={() => <Text style={styles.selectItem}>{title}</Text>}
            />
          ))}
        </Select>
      </View>
      {currentView === dropdownOptions[0] && (
        <VerticalBarChart
          chartHeight={300}
          xTitle="Interest Rate"
          yTitle="Percentage"
          colors={colors}
          data={interestRateChartData}
          labels={labels}
          maxYValue={100}
        />
      )}
      {currentView === dropdownOptions[1] && (
        <View
          style={{ flexDirection: "column", justifyContent: "space-between" }}
        >
          <View style={styles.agenciesTableContainer}>
            <View style={styles.agenciesTable}>
              <View
                style={{ ...styles.flexRow, justifyContent: "space-between" }}
              >
                <Text style={{ ...styles.agenciesTableHeaderText, flex: 3 }}>
                  Contracting Agencies
                </Text>
                <Text
                  style={{
                    ...styles.agenciesTableHeaderText,
                    textAlign: "right",
                    flex: 3,
                  }}
                >
                  Running Bal.
                </Text>
                <Text
                  style={{
                    ...styles.agenciesTableHeaderText,
                    textAlign: "right",
                    flex: 2,
                  }}
                >
                  No. of Loans
                </Text>
              </View>
              {agenciesData
                .slice(
                  currentPage * rowsPerPage,
                  (currentPage + 1) * rowsPerPage
                )
                .map((agency: any, idx: number) => (
                  <View
                    key={`agency-${idx}`}
                    style={{
                      ...styles.flexRow,
                      justifyContent: "space-between",
                      // @ts-ignore
                      borderTop: idx ? "none" : "1px solid #E0E0E0",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <Text style={{ ...styles.agenciesTableText, flex: 3 }}>
                      {agency.agency}
                    </Text>
                    <Text
                      style={{
                        ...styles.agenciesTableText,
                        textAlign: "right",
                        flex: 3,
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(Number(agency.amount))}
                    </Text>
                    <Text
                      style={{
                        ...styles.agenciesTableText,
                        textAlign: "right",
                        flex: 2,
                      }}
                    >
                      {agency.loans}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
          <View style={{ ...styles.flexRow, justifyContent: "flex-end" }}>
            <Text
              style={{
                ...styles.agenciesTableText,
                textAlign: "right",
                paddingHorizontal: 10,
              }}
            >
              {agenciesData.length ? currentPage * rowsPerPage + 1 : 0}-
              {Math.min((currentPage + 1) * rowsPerPage, agenciesData.length)}{" "}
              of {agenciesData.length}
            </Text>
            <Button
              appearance="ghost"
              status="basic"
              accessoryRight={LeftArrowIcon}
              onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
            />
            <Button
              appearance="ghost"
              status="basic"
              accessoryRight={RightArrowIcon}
              onPress={() =>
                setCurrentPage(
                  Math.min(
                    Math.ceil(agenciesData.length / rowsPerPage) - 1,
                    currentPage + 1
                  )
                )
              }
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default InterestAndAgencyDistribution;
