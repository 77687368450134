import { useNavigation } from "@react-navigation/core";
import {
  Button,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView } from "react-native";

import config from "../../config";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const UploadedDocumentsTab: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [idOfCurrentApplicationSelected, setIdOfCurrentApplicationSelected] =
    useState(applicationId);

  const EyeIcon = (props: any) => <Icon {...props} name="eye-outline" />;

  const fieldsDesc: { [key: string]: any } = {
    name: {
      name: "name",
      width: 2 / 4,
      display: "Document Name",
    },
    type: {
      name: "type",
      width: 1 / 4,
      display: "Document Type",
    },
  };

  const [
    uploadedDocumentsForCurrentApplication,
    setUploadedDocumentsForCurrentApplication,
  ] = useState([]);

  const { authStore } = useStore();

  useEffect(() => {
    if (idOfCurrentApplicationSelected) {
      setLoading(true);
      api
        .getUploadedDocumentsForALoanCreditFile(
          authStore.accessToken || "",
          idOfCurrentApplicationSelected
        )
        .then((resp) => {
          const response = resp.response.entities;
          setUploadedDocumentsForCurrentApplication(response);
          setLoading(false);
        });
    }
  }, [idOfCurrentApplicationSelected]);

  return (
    <>
      <View style={styles.adminDashboardContainer}>
        <View style={styles.requestedDocumentsContainer}>
          <ScrollView style={styles.requestedDocumentContent}>
            <Divider />
            {loading ? (
              <View style={styles.center}>
                <LoadingIndicator />
              </View>
            ) : (
              <>
                <View style={styles.tableHeaders}>
                  {Object.values(fieldsDesc).map((field) => (
                    <View
                      key={"header-" + field.display}
                      style={{
                        ...styles.tableHeadersCell,
                        alignSelf: "center",
                      }}
                    >
                      <Text style={styles.tableHeadersCell}>
                        {field.display}
                      </Text>
                    </View>
                  ))}
                  <View
                    style={{
                      ...{ flex: 1 / 4 },
                      ...styles.tableHeadersCell,
                    }}
                  >
                    <Text
                      style={{
                        ...styles.tableHeadersCell,
                        alignSelf: "center",
                      }}
                    >
                      Actions
                    </Text>
                  </View>
                </View>
                {uploadedDocumentsForCurrentApplication.map((doc: any) => (
                  <View key={doc.id + "-row"}>
                    <View style={styles.tableRow}>
                      {Object.keys(fieldsDesc).map((header: any, idx: any) => (
                        <View
                          key={doc.id + "-column-" + idx}
                          style={{
                            ...styles.tableCell,
                            flex: 1 / 4,
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              ...styles.tableCellText,
                              maxWidth: 450,
                            }}
                          >
                            {doc[header]}
                          </Text>
                        </View>
                      ))}
                      <View
                        style={{
                          ...{ flex: 1 / 4, alignSelf: "center" },
                          ...styles.tableHeadersCell,
                        }}
                      >
                        <Button
                          status="basic"
                          appearance="ghost"
                          accessoryRight={EyeIcon}
                          onPress={() => {
                            if (doc.url) {
                              if (
                                /^(?:[a-z]+:)?\/\//.test(doc.url.toLowerCase())
                              )
                                window.open(doc.url, "_blank");
                              else
                                window.open(
                                  config.urls.api.replace("/api/", "") +
                                    doc.url,
                                  "_blank"
                                );
                            } else alert("document not found");
                          }}
                        >
                          View
                        </Button>
                      </View>
                    </View>
                    <Divider />
                  </View>
                ))}
              </>
            )}
          </ScrollView>
        </View>
      </View>
    </>
  );
};

export default UploadedDocumentsTab;
