import { callApi } from "./base";

export const getLatestPrivacy = async (token: string) =>
  callApi(`policies/privacy/latest/`, token, "GET");

export const getLatestTermsOfService = async (token: string) =>
  callApi(`policies/tos/latest/`, token, "GET");

export const checkIfPrivacyIsRead = async (token: string) =>
  callApi(`policies/privacy/check-if-read/`, token, "GET");

export const checkIfTosIsRead = async (token: string) =>
  callApi(`policies/tos/check-if-read/`, token, "GET");

export const setVersionControlPrivacy = async (token: string) =>
  callApi(`policies/privacy/set-version-control/`, token, "POST");

export const setVersionControlTos = async (token: string) =>
  callApi(`policies/tos/set-version-control/`, token, "POST");
