import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Datepicker,
  CalendarViewModes,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import { useStore } from "../../stores";
import MBOStore from "../../stores/MBOStore";
import { themedStyles } from "./themedStyles";

export const SummarySSN: React.FC<any> = ({
  user,
  mbo,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  setSuccess,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore, mboStore } = useStore();

  const defaultFields = {
    ssnNumber: "",
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [localFormData, setLocalFormData] = useState<any>({ ...defaultFields });

  useEffect(() => {
    formDataReset();
    setSuccess(false);
  }, []);

  const formDataReset = () => {
    setLocalFormData({
      ssnNumber: formData.ssnNumber,
    });
  };

  const SSNRef = useRef<Input>(null);

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorMessages({ ...defaultErrors });
    setFormData({
      ...formData,
      ...localFormData,
    });
    const result = await authStore.updateBasicAndBusinessInformation({
      ...formData,
      ...localFormData,
    });
    if (!result?.ok) {
      console.log(result?.errors);
      setErrorMessages(result?.errors);
    } else {
      setIsEdit(false);
      setSuccess(true);
    }
    setLoading(false);
  }, [loading, localFormData]);

  return (
    <View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Text>
        {errorMessages?.ssnNumber && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.ssnNumber}
          </Text>
        )}
      </Text>
      <Input
        ref={SSNRef}
        style={{
          ...(isEdit ? styles.input : styles.inputNotEditing),
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        textStyle={{
          ...(isEdit
            ? width > 576
              ? styles.inputText
              : styles.inputTextMobile
            : styles.inputTextNotEditing),
          color: isEdit ? (theme === "light" ? "black" : "white") : "#8F9BB3",
        }}
        disabled={!isEdit}
        value={localFormData.ssnNumber}
        textContentType="none"
        autoCapitalize="none"
        autoCorrect={false}
        placeholder="SSN"
        onChangeText={(text) =>
          setLocalFormData({ ...localFormData, ssnNumber: text })
        }
      />
      {isEdit && (
        <View style={styles.flexRow}>
          <TouchableOpacity
            style={styles.saveButton}
            onPress={() => {
              onSubmitForm();
            }}
          >
            <LinearGradient
              style={styles.saveButtonGradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={["#D012B2", "#8A1CDB", "#5327FF"]}
            >
              <Text style={styles.saveButtonText}>Save</Text>
              <Icon
                style={styles.saveButtonIcon}
                name="checkmark-circle-2"
                fill="white"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.cancelButton,
              ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
            }}
            onPress={() => {
              setIsEdit(false);
              formDataReset();
            }}
          >
            <Text
              style={{
                ...styles.cancelButtonText,
                ...(theme === "light" ? { color: "#222B45" } : {}),
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
