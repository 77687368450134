import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },
  input: {
    flex: 1,
    fontSize: 22,
    marginBottom: 22,
  },
  LogInButton: {
    marginBottom: 25,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  cardsContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 50,
  },
  card: {
    background: "color-basic-100",
    padding: 10,
    borderRadius: 10,
    width: "90%",
    margin: 30,
  },
  flexRow: {
    flexDirection: "row",
    flex: 1,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
  },
  flexRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  headerCell: {
    flex: 1,
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "left",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardSection: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    margin: 15,
  },
  cardDivider: {
    height: 2,
    margin: 5,
    zIndex: -1,
  },
  dataRow: {
    flexDirection: "row",
    margin: 10,
  },
  finalDecisionModalButton: {
    alignSelf: "flex-start",
    marginHorizontal: 75,
    color: "#6A1DB8",
    background: "rgba(137, 41, 215, 0.08)",
    padding: 8,
    outline: 0,
    border: "1.5px solid #6A1DB8",
    borderRadius: 3,
    zIndex: -1,
  },
  finalDecisionModalButtonText: {
    color: "#6A1DB8",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 12,
  },
  backdrop: {
    backgroundColor: "#0005",
  },
  calculatorModal: {
    padding: 20,
    borderRadius: 5,
    width: 1000,
    backgroundColor: "white",
  },
  modalHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 26,
    margin: 20,
  },
  modalHeaderContainer: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  scheduleTableContainer: {
    border: "2px solid lightgray",
    borderRadius: 10,
    flexDirection: "column",
    justifyContent: "space-around",
    margin: 5,
  },
  scheduleTableHeaderRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottom: "2px solid lightgray",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 14,
  },
  scheduleTableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 14,
  },
  scheduleTableCell: {
    flex: 1,
    textAlign: "center",
    textAlignVertical: "center",
    padding: 16,
  },
  scheduleTableCellWithRightBorder: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    borderRight: "2px solid lightgray",
    padding: 16,
  },
  chevronButton: {
    width: 32,
    height: 32,
    marginVertical: "auto",
    marginRight: 32,
  },
  scheduleTableHeaderText: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 14,
  },
  scheduleTableCellText: {
    fontFamily: "SFProTextRegular",
    wordBreak: "break-all",
  },
  infoIcon: {
    fill: "#8F9BB3",
    width: 20,
    height: 20,
    position: "relative",
    top: 5,
  },
  cardHeaderInfoIcon: {
    fill: "color-basic-800",
    width: 20,
    height: 20,
    position: "relative",
    top: 4,
  },
  hoverHelpText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#222B45",
    fontSize: 12,
    position: "absolute",
    left: 220,
    lineHeight: 20,
    maxWidth: 200,
    padding: 10,
    borderRadius: 4,
    zIndex: 999,
  },
  covenantsSelectText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
  },
  covenantOptionContainer: {
    padding: 5,
    flexDirection: "row",
    maxWidth: 600,
  },
  covenantCheckbox: {},
  covenantCheckboxLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    lineHeight: 24,
    textAlign: "left",
    padding: 5,
    marginHorizontal: 15,
  },
  covenantInput: {
    flex: 1,
  },
  flexRowItemsCenter: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  covenantSubmit: {
    maxWidth: 150,
    marginHorizontal: 10,
    marginVertical: 30,
  },
  setBack: {
    zIndex: -1,
  },
  noAmortizationText: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 20,
    textAlign: "center",
    paddingVertical: 20,
  },
});
