import { useLinkTo } from "@react-navigation/native";
import {
  Button,
  Input,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
} from "@ui-kitten/components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";

import { resetPassword } from "../../services/api";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const PasswordReset: React.FC<{
  onContinue: Function;
  isAdminReset: boolean;
  isInvestorReset: boolean;
  isReferralPartnerReset: boolean;
}> = ({
  onContinue,
  isAdminReset = false,
  isInvestorReset = false,
  isReferralPartnerReset = false,
}) => {
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);

  const defaultFields = { email: "" };
  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>(defaultFields);
  const [errorMessages, setErrorMessages] = useState(defaultErrors);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const MailIcon = (props: any) => <Icon {...props} name="email-outline" />;

  const passwordReset = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    let response: any = {};
    try {
      response = await resetPassword({ email: formData.email });
      if ("entities" in response.response) {
        console.log(response.response.entities);
        onContinue(true);
      } else {
        setErrorMessages(JSON.parse(response.response));
      }
    } catch (err) {
      console.log(err.message);
      setErrorMessages(JSON.parse(err.message));
    }
    setLoading(false);
  }, [loading, formData]);

  return (
    <View style={styles.resetPassword}>
      <Text
        style={{
          ...styles.header,
          ...(isAdminReset || isInvestorReset ? { color: "#222B45" } : {}),
        }}
      >
        Reset Password
      </Text>
      <Text
        style={{
          ...styles.subHeader,
          ...(isAdminReset || isInvestorReset ? { color: "#222B45" } : {}),
        }}
      >
        Go back to{" "}
        <Text
          status="info"
          style={styles.link}
          onPress={() => {
            if (isAdminReset) linkTo("/admin/log-in");
            else if (isInvestorReset) linkTo("/investor/log-in");
            else if (isReferralPartnerReset) linkTo("/referral/log-in");
            else linkTo("/auth/log-in");
          }}
        >
          Sign In
        </Text>
        .
      </Text>
      <Text
        style={{
          ...styles.subHeader,
          ...(isAdminReset || isInvestorReset ? { color: "#222B45" } : {}),
        }}
      >
        {"\n\n"}
        Enter the email associated with your account and we will send an email
        with instructions to reset your password.
      </Text>
      {!!errorMessages && !!errorMessages.email && (
        <Text category="h6" style={styles.error}>
          {errorMessages.email}
        </Text>
      )}
      <Input
        style={
          isAdminReset || isInvestorReset ? styles.inAdminInput : styles.input
        }
        placeholder="E-mail"
        keyboardType="email-address"
        autoCapitalize="none"
        autoCompleteType="email"
        autoCorrect={false}
        accessoryRight={MailIcon}
        onChangeText={(text) => setFormData({ ...formData, email: text })}
        onSubmitEditing={() => {
          if (formData.email) passwordReset();
        }}
        testID="emailField"
      />

      <Button
        disabled={!formData.email}
        style={
          !formData.email
            ? styles.disabledActionButton
            : styles.resetPasswordButton
        }
        status="basic"
        onPress={() => passwordReset()}
        accessoryRight={loading ? LoadingIndicator : ArrowIcon}
        children={() => (
          <Text
            style={
              !formData.email
                ? styles.disabledActionButtonText
                : styles.resetPasswordButtonText
            }
          >
            send instructions{"  "}
          </Text>
        )}
      />
    </View>
  );
};

export default PasswordReset;
