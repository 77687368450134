import { Text, useStyleSheet, Layout } from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useEffect } from "react";
import { View, ScrollView } from "react-native";

import { themedStyles } from "./themedStyles";

export const StripeRedirect: React.FC<any> = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const success = urlParams.get("success");
  const styles = useStyleSheet(themedStyles);

  useEffect(() => {
    setTimeout(
      () => WebBrowser.maybeCompleteAuthSession({ skipRedirectCheck: true }),
      2000
    );
  }, []);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <View style={styles.preApprovalContainer}>
          <View style={styles.preApproval}>
            <Text style={styles.title}>
              {" "}
              Your payment with Stripe was{" "}
              {success ? "successful" : "unsuccessful"}. You can now close this
              window.
            </Text>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};
