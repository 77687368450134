import {
  Text,
  useStyleSheet,
  Icon,
  Modal,
  Button,
} from "@ui-kitten/components";
import React from "react";
import { TouchableOpacity, View } from "react-native";

import { themedStyles } from "./themedStyles";

export const ConfirmPrompt: React.FC<any> = ({
  isOpen,
  close,
  text,
  cb = () => {},
  confirmText = "CONFIRM",
  cancelText = "Cancel",
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.modalBackdrop}
      onBackdropPress={() => close()}
    >
      <View style={styles.confirmPromptContainer}>
        <View style={styles.flexEnd}>
          <TouchableOpacity onPress={() => close()}>
            <Icon name="close" width="20" height="20" fill="#8F9BB3" />
          </TouchableOpacity>
        </View>
        <View style={styles.modalContentContainer}>
          <View style={styles.modalHeaderContainer}>
            <Text style={styles.modalHeaderText}>{text}</Text>
          </View>
          <View style={styles.flexRow}>
            <Button
              style={styles.nextActionButton}
              status="primary"
              onPress={() => {
                cb();
                close();
              }}
              children={() => (
                <Text style={styles.nextActionButtonText}>{confirmText}</Text>
              )}
            />
            <Button
              style={styles.skipButton}
              status="basic"
              onPress={() => close()}
              children={() => (
                <Text style={styles.nextActionButtonText}>{cancelText}</Text>
              )}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};
