import { Text, useStyleSheet, Tooltip } from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, Dimensions } from "react-native";

import { themedStyles } from "./themedStyles";

export const VerticalBarChart: React.FC<any> = ({
  chartHeight,
  colors,
  xTitle,
  yTitle,
  labels,
  data,
  ticksCount = 10,
  dataFormat = "",
  maxYValue = "",
  stacked = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const [tooltipsVisible, setTooltipsVisible] = useState(
    data.map((arr: any) => arr.map((x: any) => false))
  );
  const [maxHeight, setMaxHeight] = useState(maxYValue ? Number(maxYValue) : 0);

  useEffect(() => {
    let newMaxHeight = 0;
    data.forEach((item: any) => {
      newMaxHeight = Math.max(
        newMaxHeight,
        item.reduce(
          (acc: number, x: number) => (stacked ? acc + x : Math.max(acc, x)),
          0
        )
      );
    });
    setTooltipsVisible(data.map((arr: any) => arr.map((x: any) => false)));
    setMaxHeight(maxYValue ? Number(maxYValue) : newMaxHeight);
  }, [data]);

  const formatDataLong = (val: number) => {
    if (dataFormat === "$")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(val));
    if (dataFormat === "%") return val.toFixed(1) + "%";
    return val;
  };

  const formatData = (val: number) => {
    if (dataFormat === "$") {
      if (!val) return "$0";
      if (val >= 10 ** 9) return "$" + (val / 10 ** 9).toFixed(1) + "B";
      if (val >= 10 ** 6) return "$" + (val / 10 ** 6).toFixed(1) + "M";
      return "$" + Math.floor(val / 10 ** 3) + "K";
    }
    if (dataFormat === "%") return val.toFixed(0) + "%";
    return val;
  };

  return (
    <View>
      <View style={styles.flexRow}>
        <View style={{ ...styles.yTitleContainer }}>
          <Text style={styles.yTitle}>{yTitle}</Text>
        </View>
        <View style={{ ...styles.chartContainer, height: chartHeight }}>
          <View
            style={{
              ...styles.chartTicks,
              height: chartHeight,
              ...(width > 1024 ? {} : { flex: 2 }),
            }}
          >
            {[...Array(ticksCount + 1)].map((tick: any, idx: number) => (
              <View style={styles.tick} key={`tick-${idx}`}>
                <Text style={styles.tickText}>
                  {formatData(Math.round((idx / ticksCount) * maxHeight))}
                </Text>
              </View>
            ))}
          </View>
          {data.map((item: any, idx: number) => (
            <View
              style={{
                ...styles.chartBarContainer,
                height: chartHeight,
                ...(stacked
                  ? {}
                  : {
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }),
              }}
              key={`chart-bar-container-${idx}`}
            >
              {item.map((num: number, idx2: number) => (
                <Tooltip
                  key={`chart-bar-${idx}-${idx2}`}
                  visible={tooltipsVisible[idx] && tooltipsVisible[idx][idx2]}
                  onBackdropPress={() =>
                    setTooltipsVisible(
                      tooltipsVisible.map((arr: any, i: number) =>
                        arr.map((x: any, i2: number) => {
                          if (i === idx && i2 === idx2) return false;
                          return x;
                        })
                      )
                    )
                  }
                  placement="top"
                  anchor={() => (
                    <TouchableOpacity
                      style={{
                        ...styles.chartBar,
                        height:
                          (chartHeight * num) /
                          (maxHeight + maxHeight / ticksCount),
                        backgroundColor: colors[item.length - 1 - idx2],
                        width: stacked
                          ? width > 1024
                            ? "75%"
                            : "50%"
                          : width > 1024
                          ? 75 / data[0].length + "%"
                          : 50 / data[0].length + "%",
                      }}
                      onPress={() =>
                        setTooltipsVisible(
                          tooltipsVisible.map((arr: any, i: number) =>
                            arr.map((x: any, i2: number) => {
                              if (i === idx && i2 === idx2) return true;
                              return x;
                            })
                          )
                        )
                      }
                    />
                  )}
                  children={() => (
                    <Text style={styles.tooltipTextLight}>
                      {formatDataLong(Math.round(num))}
                    </Text>
                  )}
                />
              ))}
              <View
                style={{
                  height: chartHeight,
                  position: "absolute",
                  width: "100%",
                }}
              >
                {[...Array(ticksCount + 1)].map((tick: any, idx: number) => (
                  <View style={styles.gridBox} key={`grid-${idx}`} />
                ))}
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.labelsRow}>
        <View style={styles.yTitleContainer} />
        <View
          style={{
            ...styles.chartTicks,
            ...(width > 1024 ? {} : { flex: 2 }),
          }}
        />
        {data.map((item: any, idx: number) => (
          <View
            style={{ ...styles.chartBarContainer }}
            key={`chart-label-${idx}`}
          >
            <Text
              style={{
                ...styles.chartLabelText,
                ...(width > 1024 ? {} : { fontSize: 10 }),
              }}
            >
              {labels[idx]}
            </Text>
          </View>
        ))}
      </View>
      <Text style={styles.xTitle}>{xTitle}</Text>
    </View>
  );
};
