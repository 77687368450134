import {
  Button,
  Text,
  useStyleSheet,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View } from "react-native";

import { YesNoQuestion } from "../PreApproval/YesNoQuestion";
import { themedStyles } from "./themedStyles";

export const LendersStep: React.FC<any> = ({
  formData,
  setFormData,
  errorMessages,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [selectedIndex, setSelectedIndex] = useState<any>([]);
  const existingLendersOptions = ["PPP", "EIDL", "Other"];

  useEffect(() => {
    const selectedIndexes = selectedIndex.map((obj: any) => obj.row);
    const selectedOptions = selectedIndexes.map(
      (idx: number) => existingLendersOptions[idx]
    );

    setFormData({
      ...formData,
      existingLendersSelectedOptions: selectedOptions,
    });
  }, [selectedIndex]);

  return (
    <View>
      <YesNoQuestion
        answer={formData.hasExistingLenders}
        setAnswer={(ans: string) =>
          setFormData({ ...formData, hasExistingLenders: ans })
        }
        option1="yes"
        option2="no"
        question="Do you have any existing lenders (including PPP and EIDL)?"
      />
      {formData.hasExistingLenders === "yes" && (
        <Select
          style={styles.select}
          multiSelect
          selectedIndex={selectedIndex}
          onSelect={(index: any) => setSelectedIndex(index)}
          value={formData.existingLendersSelectedOptions.join(",")}
        >
          {existingLendersOptions.map((option: any) => (
            <SelectItem
              key={option}
              style={styles.selectItem}
              title={() => <Text style={styles.selectItem}>{option}</Text>}
            />
          ))}
        </Select>
      )}
    </View>
  );
};
