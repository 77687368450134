import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Select,
  SelectItem,
  IndexPath,
  Layout,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";

import { useStore } from "../../stores";
import {
  businessRoleOptions,
  businessTypeOptions,
  listOfStatesOptions,
  countyOptionsByState,
  stateFullNamesToShortNames,
  acceptedBusinessStateOptions,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const SummaryBusinessInfo: React.FC<any> = ({
  user,
  business,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const BriefCaseIcon = (props: any) => (
    <Icon {...props} name="briefcase-outline" />
  );
  const LocationIcon = (props: any) => <Icon {...props} name="pin-outline" />;
  const PersonIcon = (props: any) => <Icon {...props} name="person-outline" />;

  const [businessRoleIndex, setBusinessRoleIndex] = React.useState<
    IndexPath | undefined
  >(undefined);
  const [businessTypeIndex, setBusinessTypeIndex] = React.useState<
    IndexPath | undefined
  >(undefined);
  const [businessAddStateIndex, setBusinessAddStateIndex] = React.useState<
    IndexPath | undefined
  >(undefined);
  const [stateOfIncorporationIndex, setStateOfIncorporationIndex] =
    React.useState<IndexPath | undefined>(undefined);
  const [jurisdictionCountyIndex, setJurisdictionCountyIndex] = React.useState<
    IndexPath | undefined
  >(undefined);

  const [countyOptions, setCountyOptions] = useState<any>(
    user.businessAddState
      ? Object.keys(
          //@ts-ignore
          countyOptionsByState[
            //@ts-ignore
            stateFullNamesToShortNames[user.businessAddState]
          ]
        )
      : []
  );

  const { authStore, businessStore } = useStore();
  const [loading, setLoading] = useState(false);

  return (
    <View>
      {/* MBOs don't have a way to input business role yet on signup. */}
      {/* <Layout style={styles.input}>
        <Select
          status={theme === "light" ? "success" : "primary"}
          size={width > 576 ? "medium" : "small"}
          disabled
          selectedIndex={businessRoleIndex}
          value={authStore.user?.businessRole}
        >
          {businessRoleOptions.map((title) => (
            <SelectItem key={title} title={title} />
          ))}
        </Select>
      </Layout> */}
      <Layout style={styles.input}>
        <Select
          status={theme === "light" ? "success" : "primary"}
          size={width > 576 ? "medium" : "small"}
          disabled
          selectedIndex={businessTypeIndex}
          value={business.businessType}
        >
          {businessTypeOptions.map((title) => (
            <SelectItem key={title} title={title} />
          ))}
        </Select>
      </Layout>
      <Input
        disabled
        style={{
          ...styles.inputNotEditing,
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        textStyle={{
          ...styles.inputTextNotEditing,
          color: "#8F9BB3",
        }}
        value={business.businessName}
        accessoryRight={BriefCaseIcon}
        placeholder="Business Name"
        textContentType="organizationName"
        autoCorrect={false}
        autoCapitalize="sentences"
        autoCompleteType="off"
      />
      <Input
        style={{
          ...styles.inputNotEditing,
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        textStyle={{
          ...styles.inputTextNotEditing,
          color: "#8F9BB3",
        }}
        disabled
        value={business.businessAddStreetLine1}
        accessoryRight={LocationIcon}
        textContentType="none"
        autoCompleteType="off"
        autoCorrect={false}
        autoCapitalize="none"
        placeholder="Street Address Line 1"
      />
      <Input
        style={{
          ...styles.inputNotEditing,
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        textStyle={{
          ...styles.inputTextNotEditing,
          color: "#8F9BB3",
        }}
        disabled
        value={business.businessAddStreetLine2}
        accessoryRight={LocationIcon}
        textContentType="none"
        autoCompleteType="off"
        autoCorrect={false}
        autoCapitalize="none"
        placeholder="Street Address Line 2"
      />
      <View style={styles.flexRow}>
        <Input
          style={{
            ...styles.inputNotEditing,
            backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
            borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
          }}
          textStyle={{
            ...styles.inputTextNotEditing,
            color: "#8F9BB3",
          }}
          disabled
          value={business.businessAddCity}
          textContentType="none"
          autoCompleteType="off"
          autoCorrect={false}
          autoCapitalize="sentences"
          placeholder="City"
        />
        <Layout
          style={{
            ...styles.inputNotEditing,
            backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
            borderColor: "#EDF1F7",
          }}
        >
          <Select
            status={theme === "light" ? "success" : "primary"}
            size={width > 576 ? "medium" : "small"}
            disabled
            selectedIndex={businessAddStateIndex}
            value={business.businessAddState}
          >
            {acceptedBusinessStateOptions.map((title) => (
              <SelectItem key={title} title={title} />
            ))}
          </Select>
        </Layout>
        <Input
          style={{
            ...styles.inputNotEditing,
            backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
            borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
          }}
          textStyle={{
            ...styles.inputTextNotEditing,
            color: "#8F9BB3",
          }}
          disabled
          value={business.businessAddZipCode}
          textContentType="none"
          autoCompleteType="off"
          autoCorrect={false}
          autoCapitalize="none"
          keyboardType="decimal-pad"
          placeholder="Zip Code"
        />
      </View>
      <Text
        style={{
          ...styles.inputLabel,
          ...(theme === "light" ? { color: "#FFFFFF" } : {}),
        }}
      >
        State of Incorporation (Jurisdiction State)
      </Text>
      <Layout style={styles.input}>
        <Select
          status={theme === "light" ? "success" : "primary"}
          size={width > 576 ? "medium" : "small"}
          disabled
          selectedIndex={stateOfIncorporationIndex}
          value={business.stateOfIncorporation}
          placeholder="State of Incorporation (jurisdiction state)"
        >
          {listOfStatesOptions.map((title) => (
            <SelectItem key={title} title={title} />
          ))}
        </Select>
      </Layout>
      <Text
        style={{
          ...styles.inputLabel,
          ...(theme === "light" ? { color: "#FFFFFF" } : {}),
        }}
      >
        Jurisdiction county
      </Text>
      <Layout style={styles.input}>
        <Select
          status={theme === "light" ? "success" : "primary"}
          size={width > 576 ? "medium" : "small"}
          disabled
          selectedIndex={jurisdictionCountyIndex}
          value={business.jurisdictionCounty}
          placeholder="Jurisdiction county"
          onSelect={(index: IndexPath | IndexPath[]) => {
            if (Array.isArray(index)) {
              index = index[0];
            }
            setJurisdictionCountyIndex(index);
          }}
        >
          {countyOptions.map((title: any) => (
            <SelectItem key={title} title={title} />
          ))}
        </Select>
      </Layout>
    </View>
  );
};
