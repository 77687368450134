import { useStyleSheet, Icon, Text, Tooltip } from "@ui-kitten/components";
import React, { useState } from "react";
import { TouchableOpacity } from "react-native";

import { themedStyles } from "./themedStyles";

const HoverBubble: React.FC<any> = ({ text, width, theme = "light" }) => {
  const styles = useStyleSheet(themedStyles);

  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <Tooltip
        fullWidth
        placement="top start"
        anchor={() => (
          <TouchableOpacity
            onPress={() => setShowInfo(true)}
            style={{ width: width ? width : "50%", paddingLeft: 4 }}
          >
            <Icon
              style={styles.infoIcon}
              name="info-outline"
              fill={theme === "light" ? "black" : "white"}
            />
          </TouchableOpacity>
        )}
        visible={showInfo}
        onBackdropPress={() => setShowInfo(false)}
      >
        <Text
          style={{
            ...(theme === "light"
              ? styles.tooltipTextLight
              : styles.tooltipTextDark),
          }}
        >
          {text}
        </Text>
      </Tooltip>
    </>
  );
};

export default HoverBubble;
