import { StyleService } from "@ui-kitten/components";
import { Dimensions } from "react-native";

export const themedStyles = StyleService.create({
  btn: {
    marginHorizontal: 3,
    marginVertical: 3,
  },
  container: { paddingHorizontal: 10 },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    textAlign: "center",
    marginHorizontal: 5,
  },
  verticalDivider: {
    width: 3,
    height: 30,
    marginVertical: 10,
    backgroundColor: "#0001",
  },
  textSubHeader: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
    textAlign: "center",
  },
  welcomeMessageText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 24,
    fontSize: 13,
    textAlign: "center",
  },
  button: {
    fontFamily: "Inter_700Bold",
    marginHorizontal: 10,
    backgroundColor: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
  },
  tabContent: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    paddingVertical: 20,
  },
  tabContainer: {
    flex: 1.25,
    flexDirection: "row",
    marginHorizontal: 30,
  },
  tab: {
    height: "100%",
    flex: 1,
    paddingVertical: 0,
    marginHorizontal: 20,
    textAlign: "center",
  },
  activeTab: {
    height: "100%",
    flex: 1,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    paddingVertical: 0,
    paddingBottom: 3,
    marginHorizontal: 20,
    textAlign: "center",
  },
  tabFont: {
    color: "#8F9BB3",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  activeTabFont: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
    backgroundClip: "text",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    color: "transparent",
  },
  flexRow: {
    flexDirection: "row",
  },
  sidePanelContainer: {
    flex: 1,
    flexDirection: "row-reverse",
    width: Dimensions.get("window").width,
  },
  sidePanel: {
    padding: 10,
    paddingLeft: 50,
    background: "background-basic-color-1",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    flex: 1,
    height: Dimensions.get("window").height,
  },
  backdrop: {
    height: Dimensions.get("window").height,
    flex: 1,
    backgroundColor: "#0007",
  },
  flexRowReverse: {
    flexDirection: "row-reverse",
  },
  verificationChecklistHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    textAlign: "left",
    marginHorizontal: 5,
    marginVertical: 20,
  },
  verificationChecklistSubHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "left",
    marginHorizontal: 5,
    marginVertical: 5,
  },
  verificationChecklistNoteText: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "justify",
    marginHorizontal: 5,
    marginVertical: 5,
  },
  gradientButton: {
    //@ts-ignore
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    flex: 1,
    margin: 10,
  },
  disabledButton: {
    flex: 1,
    margin: 10,
  },
  outlineButton: {
    flex: 1,
    margin: 10,
  },
  select: {
    flex: 1,
    fontSize: 22,
    marginBottom: 40,
    background: "white",
    width: 250,
    height: 20,
  },
  selectAlt: {
    fontSize: 22,
    marginBottom: 40,
    background: "white",
    width: 250,
    height: 20,
  },
  selectItem: {
    backgroundColor: "white",
    color: "#222B45",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
  },
  verificationNotesInput: {
    paddingRight: 25,
  },
  reAssignButton: {
    flex: 1,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    marginBottom: 25,
    marginLeft: 10,
    marginRight: 10,
  },
  downloadButton: {
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    padding: 20,
  },
  downloadNotAvailableButton: {
    backgroundColor: "F8F8F8",
    padding: 20,
  },
  uploadButton: {
    display: "flex",
    justifyContent: "space-between",
    flex: 0.5,
    border: 0,
  },
  uploadButtonText: {
    fontFamily: "Montserrat_400Regular",
    fontStyle: "normal",
    fontSize: 15,
    lineHeight: 20,
    color: "#8F9BB3",
  },
  pill: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    margin: 0,
    borderRadius: 25,
    marginHorizontal: 7,
    marginVertical: 5,
    backgroundColor: "color-basic-default",
  },
  pillText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  APPROVED: {
    backgroundColor: "color-success-default",
  },
  FUNDED: {
    backgroundColor: "color-success-default",
  },
  REJECTED: {
    backgroundColor: "color-danger-default",
  },
  "SOFT REJECTED": {
    backgroundColor: "color-danger-default",
  },
  "PREQUAL 1": {
    backgroundColor: "color-warning-default",
  },
  "PREQUAL 2": {
    backgroundColor: "color-warning-default",
  },
  "FULL APPLICATION": {
    backgroundColor: "color-warning-default",
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  declineModal: {
    padding: 20,
    borderRadius: 5,
    background: "white",
  },
  settingsModal: {
    padding: 20,
    borderRadius: 5,
    background: "white",
  },
  modalHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 26,
    margin: 20,
  },
  modalHeaderContainer: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "100%",
    backgroundColor: "white",
    padding: 50,
  },
  modalText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalYesButton: {
    backgroundColor: "#1D84FF",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalNoButton: {
    backgroundColor: "#222B45",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  userHistory: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Montserrat_700Bold",
    fontSize: 10,
    lineHeight: 12,
    marginHorizontal: 10,
    width: 250,
  },
  userHistoryTitles: {
    marginHorizontal: 10,
  },
  userHistoryValues: {},
  userHistoryTitle: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 10,
    lineHeight: 12,
  },
  userHistoryValue: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 10,
    lineHeight: 12,
  },
  icon: {
    width: 16,
    height: 16,
    paddingHorizontal: 10,
    marginHorizontal: 10,
  },
  chevronButton: {
    width: 32,
    height: 32,
    marginVertical: "auto",
    marginRight: 32,
  },
  flexRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardSubHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 12,
  },
  historyUserName: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    width: 250,
    paddingHorizontal: 20,
    height: 20,
  },
  assignButton: {
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    border: "1px solid #D012B2",
    borderRadius: 4,
    marginVertical: 10,
    marginLeft: 10,
  },
  assignButtonFill: {
    backgroundColor: "white",
    paddingHorizontal: 15,
    paddingVertical: 6,
    borderRadius: 4,
    border: "1px solid white",
  },
  assignButtonText: {
    backgroundClip: "text",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    color: "transparent",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 12,
  },
  space: { flex: 0.1 },
});
