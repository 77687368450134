import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Divider,
  Spinner,
} from "@ui-kitten/components";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Dimensions, View } from "react-native";

import { ConfirmPrompt } from "../../components/ConfirmPrompt";
import HoverBubble from "../../components/HoverBubble";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { uploadDocument } from "../../utils/index";
import { ExistingLenders } from "./ExistingLenders";
import { themedStyles } from "./themedStyles";

export const FinancialInformationStep: React.FC<any> = ({
  updateLoanData,
  goToNextStep,
  loan,
  business,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-forward-outline" />
  );
  const [formData, setFormData] = useState<any>({
    fiscalYearEndProjections: loan.fiscalYearEndProjections || "",
  });
  const [lendersData, setLendersData] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>("");

  const { authStore } = useStore();

  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="tiny" />
  );

  const [isConfirmPromptOpen, setIsConfirmPromptOpen] = useState(false);

  const clickSave = () => {
    if (business.hasExistingLenders && (!lendersData || !lendersData.length))
      setIsConfirmPromptOpen(true);
    else saveDataToBackend();
  };

  useEffect(() => {
    setLoading(true);
    api
      .getExistingLendersData(authStore?.accessToken || "", loan.id)
      .then((resp: any) => {
        setLendersData(
          resp.response.entities.map((obj: any) => ({
            id: obj.id,
            existingLenders: obj.lenderName,
            existingLendersLendAmount: `${obj.lendAmount}`,
            existingLendersCollateral: obj.collateral,
            existingLendersDebtSchedule: obj.debtSchedule,
            existingLendersIsVariable: obj.isVariable,
            existingLendersAmortization: `${obj.amortization}`,
            existingLendersTerm: obj.term,
            existingLendersIsSecured: obj.isSecured,
          }))
        );
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const newFormData = { ...formData };
    Object.keys(formData).forEach(
      (key) => (newFormData[key] = loan[key] || formData[key])
    );
    setFormData(newFormData);
  }, [loan]);

  const saveDataToBackend = async () => {
    setLoading(true);
    const data = new FormData();
    lendersData.forEach((lender: any, idx: number) => {
      Object.keys(lender).forEach((key) => {
        if (
          key !== "existingLendersDebtSchedule" ||
          lender[key] instanceof File
        ) {
          data.append(`${key}-${idx}`, lender[key]);
        }
      });
    });
    const resLenders = await api.updateExistingLendersData(
      authStore?.accessToken || "",
      loan.id,
      data
    );
    const res = await updateLoanData(formData);
    setLoading(false);
    console.log(resLenders, res);
    if (res?.ok) goToNextStep(!loan.hasExistingLenders || lendersData.length);
    else setErrors(res?.errors);
  };

  return (
    <View>
      <ConfirmPrompt
        isOpen={isConfirmPromptOpen}
        close={() => setIsConfirmPromptOpen(false)}
        text="Are you sure? You still have some mandatory fields remaining which you will need to fill"
        cb={() => saveDataToBackend()}
      />

      <ExistingLenders
        lendersData={lendersData}
        setLendersData={setLendersData}
        loading={loading}
      />
      <Text category="h6" style={styles.error}>
        {errors ? JSON.stringify(errors) : ""}
      </Text>
      <Text style={styles.subHeader}>Financial Projections</Text>
      <Divider style={styles.divider} />
      <Text style={styles.inputLabel}>
        Fiscal Year End Financial Statement Projections
        <Text appearance="hint">(Optional)</Text>
        <HoverBubble
          width="40%"
          text={
            <Text style={styles.tooltipTextDark}>
              This will be your balance sheet and income statement for the
              fiscal year end on a monthly basis. This can be a pdf, excel, or
              csv file.
            </Text>
          }
        />
      </Text>
      <Button
        style={
          formData.fiscalYearEndProjections
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.fiscalYearEndProjections ? "success" : "basic"}
        appearance="outline"
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                fiscalYearEndProjections: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />
      <View style={width > 768 && styles.flexRow}>
        <Button
          style={
            loading ? styles.disabledActionButton : styles.nextActionButton
          }
          onPress={() => clickSave()}
          disabled={loading}
          accessoryRight={loading ? LoadingIndicator : ArrowIcon}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Save and Continue{loading ? "  " : ""}
            </Text>
          )}
        />
        <Button
          style={loading ? styles.disabledActionButton : styles.skipButton}
          status="basic"
          disabled={loading}
          onPress={() => {
            goToNextStep();
          }}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Skip for now
            </Text>
          )}
        />
      </View>
    </View>
  );
};
