import * as eva from "@eva-design/eva";
import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Text,
  Button,
  useStyleSheet,
  Spinner,
  Modal,
  Icon,
  ThemeProvider,
  Select,
  SelectItem,
  Input,
  Divider,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Collapsible from "react-native-collapsible";

import config from "../../config";
import * as api from "../../services/api";
import { postInvestorVerificationSidebarData } from "../../services/api";
import { useStore } from "../../stores";
import { uploadDocument } from "../../utils";
import DeclineApplicationModal from "./DeclineApplicationModal";
import { themedStyles } from "./themedStyles";

const VerificationChecklistModal: React.FC<any> = ({
  applicationId,
  investorView,
  isOpen,
  close,
  editTimestampValue,
}) => {
  const styles = useStyleSheet(themedStyles);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const CloseIcon = (props: any) => <Icon {...props} name="close" />;
  const InfoIcon = (props: any) => <Icon {...props} name="info-outline" />;
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;

  const { authStore, loanStore } = useStore();

  const [loading, setLoading] = useState(false);

  const [selectedManualDecision, setSelectedManualDecision] =
    useState<any>(null);
  const [selectedUserAssignment, setSelectedUserAssignment] =
    useState<any>(null);
  const [selectedRiskRating, setSelectedRiskRating] = useState<any>(null);
  const [loanStatus, setLoanStatus] = useState<any>("");
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [declineOptions, setDeclineOptions] = useState([""]);
  const [applicationAssignmentHistory, setApplicationAssignmentHistory] =
    useState<any>([]);
  const [isAssignmentHistoryCollapsed, setIsAssignmentHistoryCollapsed] =
    useState(true);
  const [isNotesHistoryCollapsed, setIsNotesHistoryCollapsed] = useState(true);
  const [isInvestorNotesHistoryCollapsed, setIsInvestorNotesHistoryCollapsed] =
    useState(true);
  const [cantApproveReason, setCantApproveReason] = useState("");
  const [awaitingCLODecision, setAwaitingCLODecision] = useState(false);

  const [verificationData, setVerificationData] = useState<any>({
    riskRating: "",
    manualDecision: "",
    assignedUser: "",
    applicationNotes: [],
    newApplicationNote: "",
    finalDecision: false,
    declineReasons: [],
  });

  const [investorVerificationData, setInvestorVerificationData] = useState<any>(
    {
      riskRating: "",
      applicationNotes: [],
      investorNotes: [],
      newInvestorNote: "",
      investorDecision: "",
    }
  );

  const [underwritingSpreadsheet, setUnderwritingSpreadsheet] =
    useState<any>("");

  const [dueDiligenceChecklist, setDueDiligenceChecklist] = useState<any>("");
  const dueDiligenceChecklistFileUrl = require("../../../assets/Due Diligence Checklist.xlsx");
  const MasterUnderwritingSpreadsheetFileUrl = require("../../../assets/Master Underwriting Template.xlsx");

  const riskRatingOptions = [
    "Risk Rating 1 (Low Risk “L/R”)",
    "Risk Rating 2 (Satisfactory Risk “S/R”)",
    "Risk Rating 3a (Average Risk “A/R”)",
    "Risk Rating 3b (Average Risk “A/R”)",
    "Risk Rating 4a (Special Mention “S/M”)",
    "Risk Rating 4b (Special Mention “S/M”)",
    "Risk Rating 4c (Special Mention “S/M”)",
    "Risk Rating 5 (Substandard/Doubtful “S/D”)",
  ];

  const investorDecisionOptions = ["Approve", "Decline"];

  const investorDecisionValue = () => {
    if (selectedManualDecision === null) {
      return investorVerificationData.investorDecision;
    } else {
      return investorDecisionOptions[selectedManualDecision.row];
    }
  };

  const getManualDecisionOptions = useCallback(
    (statusOverride = "") => {
      const status = statusOverride || loanStatus;
      if (status.includes("approved") || status.includes("funded"))
        return ["Final Review", "Funded"];

      if (status.includes("prequal1")) {
        return [
          "Review - Prequal 1",
          "Approve - Prequal 1",
          "Decline - Prequal 1",
        ];
      }
      if (status.includes("prequal2")) {
        return [
          "Review - Prequal 2",
          "Approve - Prequal 2",
          "Decline - Prequal 2",
        ];
      }
      if (status.includes("full")) {
        return [
          "Review - Full Application",
          "Approve - Full Application",
          "Decline - Full Application",
        ];
      }

      return [];
    },
    [loanStatus]
  );

  const [userAssignmentOptions, setUserAssignmentOptions]: any = useState([{}]);

  const getLoanStatusDisplay = useCallback(() => {
    let displayValue = "";

    if (loanStatus.includes("softreject")) {
      displayValue = "SOFT REJECTED";
    } else if (loanStatus.includes("reject")) {
      displayValue = "REJECTED";
    } else if (loanStatus.includes("prequal1")) {
      displayValue = "PREQUAL 1";
    } else if (loanStatus.includes("prequal2")) {
      displayValue = "PREQUAL 2";
    } else if (loanStatus.includes("full")) {
      displayValue = "FULL APPLICATION";
    } else if (loanStatus.includes("funded")) {
      displayValue = "FUNDED";
    } else if (loanStatus.includes("approved")) {
      displayValue = "APPROVED";
    } else {
      displayValue = loanStatus.toUpperCase();
    }

    return (
      //@ts-ignore
      <View style={{ ...styles.pill, ...styles[displayValue] }}>
        <Text style={styles.pillText}>{displayValue}</Text>
      </View>
    );
  }, [loanStatus]);

  const saveUnderwriterNotes = async (
    finalDecision = false,
    decline = false
  ) => {
    setCantApproveReason("");
    let manualDecisionOverwrite = "";
    if (finalDecision) {
      if (decline) {
        manualDecisionOverwrite =
          getManualDecisionOptions().filter((opt) =>
            opt.toLowerCase().includes("decline")
          )[0] || "";
      } else {
        manualDecisionOverwrite =
          getManualDecisionOptions().filter((opt) =>
            opt.toLowerCase().includes("approve")
          )[0] || "";
      }
    }
    if (
      decline &&
      manualDecisionOverwrite === verificationData.manualDecision &&
      !declineOptions.length
    ) {
      setIsDeclineModalOpen(true);
      return;
    }
    setLoading(true);
    if (
      underwritingSpreadsheet instanceof File ||
      dueDiligenceChecklist instanceof File
    ) {
      const formData = new FormData();
      if (underwritingSpreadsheet instanceof File)
        formData.append("underwriting_spreadsheet", underwritingSpreadsheet);
      if (dueDiligenceChecklist instanceof File)
        formData.append("due_diligence_checklist", dueDiligenceChecklist);
      await api.postUnderwriterVerificationSidebarDataMultipartFormData(
        authStore.accessToken || "",
        applicationId,
        formData
      );
    }
    const response = await api.postUnderwriterVerificationSidebarData(
      authStore?.accessToken || "",
      applicationId,
      {
        ...verificationData,
        finalDecision,
        manualDecision: manualDecisionOverwrite
          ? manualDecisionOverwrite
          : verificationData.manualDecision,
        editTimestamp: editTimestampValue,
      }
    );
    setIsDeclineModalOpen(false);
    setLoading(false);
    if (response.response.entities.error) {
      setCantApproveReason(response.response.entities.error);
    } else if (response.response.entities.reasonWhyCantApprove)
      setCantApproveReason(
        " " + response.response.entities.reasonWhyCantApprove
      );
    else {
      loanStore.createOrUpdateLoanApplication(response.response.entities);
      window.location.reload();
    }
  };

  const saveInvestorNotes = async () => {
    setLoading(true);
    await postInvestorVerificationSidebarData(
      authStore?.accessToken || "",
      applicationId,
      {
        ...investorVerificationData,
        investorDecision: investorDecisionValue(),
      }
    );
    setLoading(false);
    window.location.reload();
  };

  const reAssignUser = (userId = null) => {
    setLoading(true);
    api
      .postUnderwriterVerificationSidebarData(
        authStore?.accessToken || "",
        applicationId,
        { assignedUser: userId || verificationData.assignedUser }
      )
      .then((resp) => {
        if (userId) {
          window.location.reload();
        }
        setLoading(false);
      });
  };

  useFocusEffect(
    useCallback(() => {
      if (applicationId) {
        setLoading(true);
        if (investorView) {
          api
            .getInvestorVerificationSidebarData(
              authStore?.accessToken || "",
              applicationId
            )
            .then((resp) => {
              const {
                investorDecision,
                applicationNotes,
                investorNotes,
                riskRating,
                underwritingSpreadsheet,
                status,
                declineReasonCodes,
                declineReasons,
              } = resp.response.entities;

              applicationNotes.sort(
                (a: any, b: any) =>
                  Date.parse(b.created) - Date.parse(a.created)
              );
              investorNotes.sort(
                (a: any, b: any) =>
                  Date.parse(b.created) - Date.parse(a.created)
              );

              setDeclineOptions(declineReasonCodes);
              setLoanStatus(status.toLowerCase());
              setInvestorVerificationData({
                ...investorVerificationData,
                riskRating,
                applicationNotes,
                investorNotes,
                investorDecision,
                declineReasons,
              });
              setUnderwritingSpreadsheet(underwritingSpreadsheet);
              setLoading(false);
            });
        } else {
          api
            .getUnderwriterVerificationSidebarData(
              authStore?.accessToken || "",
              applicationId
            )
            .then((resp) => {
              const {
                riskRating,
                manualDecision,
                applicationNotes,
                users,
                assignedUser,
                underwritingSpreadsheet,
                dueDiligenceChecklist,
                status,
                declineReasonCodes,
                declineReasons,
                assignedToUnderwriterHistoryPerUnderwriter,
                waitingCloDecision,
              } = resp.response.entities;

              applicationNotes.sort(
                (a: any, b: any) =>
                  Date.parse(b.created) - Date.parse(a.created)
              );

              setDeclineOptions(declineReasonCodes);
              setLoanStatus(status.toLowerCase());
              setUserAssignmentOptions(users);
              setAwaitingCLODecision(waitingCloDecision);
              setVerificationData({
                ...verificationData,
                riskRating,
                manualDecision: getManualDecisionOptions(
                  status.toLowerCase()
                ).includes(manualDecision)
                  ? manualDecision
                  : "",
                applicationNotes,
                assignedUser,
                declineReasons,
              });
              setUnderwritingSpreadsheet(underwritingSpreadsheet);
              setDueDiligenceChecklist(dueDiligenceChecklist);
              setApplicationAssignmentHistory(
                assignedToUnderwriterHistoryPerUnderwriter
              );
              setLoading(false);
            });
        }
      }
    }, [useIsFocused()])
  );

  useEffect(() => {
    if (selectedRiskRating) {
      setVerificationData({
        ...verificationData,
        riskRating: riskRatingOptions[selectedRiskRating.row],
      });
    }
  }, [selectedRiskRating]);

  useEffect(() => {
    if (selectedUserAssignment) {
      setVerificationData({
        ...verificationData,
        assignedUser: userAssignmentOptions[selectedUserAssignment.row].id,
      });
    }
  }, [selectedUserAssignment]);

  useEffect(() => {
    if (selectedManualDecision) {
      setVerificationData({
        ...verificationData,
        manualDecision: getManualDecisionOptions()[selectedManualDecision.row],
      });
    }
  }, [selectedManualDecision]);

  const splitDate = (date: string) => {
    return new Date(date)
      .toString()
      .slice(
        0,
        new Date(date).toString().indexOf("(") ||
          new Date(date).toString().length
      )
      .split("GMT")
      .join("\nGMT");
  };

  const UserHistory = (obj: any) => {
    return (
      <View style={styles.userHistory}>
        <View style={styles.userHistoryTitles}>
          <Text style={styles.userHistoryTitle} appearance="hint">
            START:{"\n\n"}
          </Text>
          <Text style={styles.userHistoryTitle} appearance="hint">
            END:{"\n\n"}
          </Text>
          <Text style={styles.userHistoryTitle} appearance="hint">
            TOTAL:
          </Text>
        </View>
        <View style={styles.userHistoryValues}>
          <Text style={styles.userHistoryValue}>
            {splitDate(obj.startDate)}
          </Text>
          <Text style={styles.userHistoryValue}>
            {obj.endDate !==
            applicationAssignmentHistory[
              applicationAssignmentHistory.length - 1
            ].endDate
              ? splitDate(obj.endDate)
              : "-\n\n"}
          </Text>
          <Text style={styles.userHistoryValue}>
            {Math.floor(obj.total / 24)} days and {obj.total % 24} hours
          </Text>
        </View>
      </View>
    );
  };

  const disableSaveBtn =
    ["withdrawn", "approved", "funded", "done"].includes(loanStatus) ||
    (loanStatus.toLowerCase().includes("reject") &&
      !loanStatus.toLowerCase().includes("soft"));

  const disableContinueDeclineBtn =
    (!underwritingSpreadsheet &&
      !loanStatus.toLowerCase().includes("prequal1")) ||
    disableSaveBtn;

  return (
    <Modal style={styles.sidePanelContainer} visible={isOpen}>
      <ThemeProvider theme={eva.light}>
        <View style={styles.sidePanel}>
          {!loading && !investorView && (
            <DeclineApplicationModal
              isOpen={isDeclineModalOpen}
              close={() => setIsDeclineModalOpen(false)}
              declineOptions={declineOptions}
              setVerificationData={setVerificationData}
              verificationData={verificationData}
              underwritingSpreadsheet={underwritingSpreadsheet}
              onDecline={() => saveUnderwriterNotes(true, true)}
            />
          )}
          {loading ? (
            <View
              style={{
                ...styles.container,
                alignItems: "center",
                margin: "auto",
              }}
            >
              <LoadingIndicator />
            </View>
          ) : (
            <>
              {investorView ? (
                //If Investor

                <ScrollView>
                  <View style={styles.flexRowReverse}>
                    <Button
                      appearance="ghost"
                      status="basic"
                      accessoryRight={CloseIcon}
                      onPress={() => close()}
                    />
                  </View>
                  <View>
                    <Text status="danger">{cantApproveReason}</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.verificationChecklistSubHeader,
                      marginVertical: 0,
                    }}
                  >
                    {`Application ID ${applicationId}`}
                  </Text>
                  <View
                    style={{
                      ...styles.flexRow,
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.verificationChecklistHeader}>
                      Verification Checklist
                    </Text>
                    {getLoanStatusDisplay()}
                  </View>
                  <Text style={styles.verificationChecklistHeader}>
                    Risk Rating
                  </Text>
                  <Input
                    disabled
                    style={{ ...styles.select, width: 525, marginBottom: 0 }}
                    textStyle={{
                      ...styles.selectItem,
                      fontWeight: "500",
                      backgroundColor: "#F8F8F8",
                    }}
                    placeholder="Risk Rating"
                    value={investorVerificationData.riskRating}
                  />
                  <Text>{"\n"}</Text>
                  <Text style={styles.verificationChecklistHeader}>
                    Underwriting Spreadsheet
                  </Text>
                  <View style={{ ...styles.flexRow, alignItems: "center" }}>
                    {underwritingSpreadsheet ? (
                      <Button
                        style={{ ...styles.downloadButton, marginBottom: 0 }}
                        onPress={() => {
                          if (
                            /^(?:[a-z]+:)?\/\//.test(
                              underwritingSpreadsheet.toLowerCase()
                            )
                          )
                            window.open(underwritingSpreadsheet, "_blank");
                          else
                            window.open(
                              config.urls.api.replace("/api/", "") +
                                underwritingSpreadsheet,
                              "_blank"
                            );
                        }}
                      >
                        DOWNLOAD
                      </Button>
                    ) : (
                      <Button
                        style={{
                          ...styles.downloadNotAvailableButton,
                          marginBottom: 0,
                        }}
                        disabled
                      >
                        DOWNLOAD (NOT AVAILABLE)
                      </Button>
                    )}
                  </View>
                  <Text style={styles.verificationChecklistHeader}>Notes</Text>
                  <Text
                    appearance="hint"
                    style={styles.verificationChecklistSubHeader}
                  >
                    New Investor Note
                  </Text>
                  <Input
                    style={styles.verificationNotesInput}
                    multiline
                    value={investorVerificationData.newInvestorNote}
                    numberOfLines={5}
                    placeholder="Add Note(s) Here"
                    onChangeText={(text) =>
                      setInvestorVerificationData({
                        ...investorVerificationData,
                        newInvestorNote: text,
                      })
                    }
                  />
                  <TouchableWithoutFeedback
                    onPress={() =>
                      setIsInvestorNotesHistoryCollapsed(
                        !isInvestorNotesHistoryCollapsed
                      )
                    }
                  >
                    <View style={styles.flexRowBetween}>
                      <View style={{ ...styles.flexRow, alignItems: "center" }}>
                        <Text style={styles.cardSubHeader} appearance="hint">
                          NOTES HISTORY
                        </Text>
                      </View>
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: !isInvestorNotesHistoryCollapsed
                                  ? "180deg"
                                  : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                  <Collapsible collapsed={isInvestorNotesHistoryCollapsed}>
                    {investorVerificationData.investorNotes.map(
                      (note: any, idx: number) => {
                        return (
                          <View key={`note-${idx}`}>
                            <Text
                              appearance="hint"
                              style={styles.verificationChecklistSubHeader}
                            >
                              Application Note by {note.author} -{" "}
                              {`${new Date(note.created).toLocaleString(
                                "en-US",
                                {
                                  timeZone: "America/New_York",
                                }
                              )} (ET)`}
                            </Text>
                            <Text style={styles.verificationChecklistNoteText}>
                              {note.text}
                            </Text>
                          </View>
                        );
                      }
                    )}
                  </Collapsible>
                  <TouchableWithoutFeedback
                    onPress={() =>
                      setIsNotesHistoryCollapsed(!isNotesHistoryCollapsed)
                    }
                  >
                    <View style={styles.flexRowBetween}>
                      <View style={{ ...styles.flexRow, alignItems: "center" }}>
                        <Text style={styles.cardSubHeader} appearance="hint">
                          UNDERWRITER NOTES HISTORY
                        </Text>
                      </View>
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: !isNotesHistoryCollapsed
                                  ? "180deg"
                                  : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                  <Collapsible collapsed={isNotesHistoryCollapsed}>
                    {investorVerificationData.applicationNotes.map(
                      (note: any, idx: number) => {
                        return (
                          <View key={`note-${idx}`}>
                            <Text
                              appearance="hint"
                              style={styles.verificationChecklistSubHeader}
                            >
                              Application Note by {note.author} -{" "}
                              {`${new Date(note.created).toLocaleString(
                                "en-US",
                                {
                                  timeZone: "America/New_York",
                                }
                              )} (ET)`}
                            </Text>
                            <Text style={styles.verificationChecklistNoteText}>
                              {note.text}
                            </Text>
                          </View>
                        );
                      }
                    )}
                  </Collapsible>
                  <Text style={styles.verificationChecklistHeader}>
                    Decision
                  </Text>
                  <Select
                    style={styles.select}
                    placeholder={investorVerificationData.investorDecision}
                    value={investorDecisionValue()}
                    selectedIndex={selectedManualDecision}
                    onSelect={(index) => setSelectedManualDecision(index)}
                  >
                    {investorDecisionOptions.map((option, idx) => (
                      <SelectItem
                        style={styles.selectItem}
                        accessoryRight={InfoIcon}
                        title={() => (
                          <Text style={styles.selectItem}>{option}</Text>
                        )}
                        key={`${idx}-investor-decision-option`}
                      />
                    ))}
                  </Select>
                  <Text style={styles.verificationChecklistHeader}>
                    Return to OppZo
                  </Text>
                  <View style={{ ...styles.flexRow, marginBottom: 80 }}>
                    <Input
                      disabled
                      style={{
                        ...styles.selectAlt,
                        marginBottom: 0,
                        marginRight: 20,
                      }}
                      textStyle={{
                        ...styles.selectItem,
                        fontWeight: "500",
                        backgroundColor: "#F8F8F8",
                      }}
                      value="Kysha Pierre-Louis"
                    />
                    <Button
                      style={{ ...styles.downloadButton, marginBottom: 0 }}
                      onPress={() => saveInvestorNotes()}
                    >
                      RETURN
                    </Button>
                  </View>
                </ScrollView>
              ) : (
                //If Underwriter

                <ScrollView>
                  <View style={styles.flexRowReverse}>
                    <Button
                      appearance="ghost"
                      status="basic"
                      accessoryRight={CloseIcon}
                      onPress={() => close()}
                    />
                  </View>
                  <View>
                    <Text status="danger">{cantApproveReason}</Text>
                  </View>
                  <Text
                    style={{
                      ...styles.verificationChecklistSubHeader,
                      marginVertical: 0,
                    }}
                  >
                    {`Application ID ${applicationId}`}
                  </Text>
                  <View
                    style={{
                      ...styles.flexRow,
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.verificationChecklistHeader}>
                      Verification Checklist
                    </Text>
                    {getLoanStatusDisplay()}
                  </View>

                  <Text style={styles.verificationChecklistHeader}>
                    Risk Rating
                  </Text>
                  <Select
                    style={{ ...styles.select, width: 525 }}
                    placeholder="Risk Rating"
                    value={verificationData.riskRating}
                    selectedIndex={selectedRiskRating}
                    onSelect={(index) => setSelectedRiskRating(index)}
                  >
                    {riskRatingOptions.map((option, idx) => (
                      <SelectItem
                        style={styles.selectItem}
                        accessoryRight={InfoIcon}
                        title={() => (
                          <Text style={styles.selectItem}>{option}</Text>
                        )}
                        key={`${idx}-risk-rating-option`}
                      />
                    ))}
                  </Select>
                  <Text>{"\n"}</Text>

                  <Text style={styles.verificationChecklistHeader}>
                    Due Diligence Checklist
                  </Text>
                  <View style={{ ...styles.flexRow, alignItems: "center" }}>
                    <Button
                      style={styles.uploadButton}
                      status={dueDiligenceChecklist ? "success" : "basic"}
                      accessoryRight={UploadIcon}
                      appearance="outline"
                      onPress={() =>
                        uploadDocument((doc: any) => {
                          setDueDiligenceChecklist(doc.file);
                        })
                      }
                      children={() => (
                        <Text style={styles.uploadButtonText}>
                          {!dueDiligenceChecklist
                            ? "Upload"
                            : "Upload (Replace)"}
                        </Text>
                      )}
                    />
                    {dueDiligenceChecklist ? (
                      <Button
                        style={{ ...styles.downloadButton, marginBottom: 0 }}
                        onPress={() => {
                          if (
                            /^(?:[a-z]+:)?\/\//.test(
                              dueDiligenceChecklist.toLowerCase()
                            )
                          )
                            window.open(dueDiligenceChecklist, "_blank");
                          else
                            window.open(
                              config.urls.api.replace("/api/", "") +
                                dueDiligenceChecklist,
                              "_blank"
                            );
                        }}
                      >
                        DOWNLOAD
                      </Button>
                    ) : (
                      <Button
                        style={{ ...styles.downloadButton, marginBottom: 0 }}
                        onPress={async () => {
                          const fullFileUrl = await fetch(
                            dueDiligenceChecklistFileUrl
                          );
                          const link = document.createElement("a");
                          link.href = fullFileUrl.url;
                          link.download = "DueDilligenceChecklist.xlsx";
                          document.body.appendChild(link);
                          link.click();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                    )}
                  </View>
                  <Text>{"\n"}</Text>

                  <Text style={styles.verificationChecklistHeader}>
                    Underwriting Spreadsheet
                  </Text>
                  <View style={{ ...styles.flexRow, alignItems: "center" }}>
                    <Button
                      style={styles.uploadButton}
                      status={underwritingSpreadsheet ? "success" : "basic"}
                      accessoryRight={UploadIcon}
                      appearance="outline"
                      onPress={() =>
                        uploadDocument((doc: any) =>
                          setUnderwritingSpreadsheet(doc.file)
                        )
                      }
                      children={() => (
                        <Text style={styles.uploadButtonText}>
                          {!underwritingSpreadsheet
                            ? "Upload"
                            : "Upload (Replace)"}
                        </Text>
                      )}
                    />
                    {underwritingSpreadsheet ? (
                      <Button
                        style={{ ...styles.downloadButton, marginBottom: 0 }}
                        onPress={() => {
                          if (
                            /^(?:[a-z]+:)?\/\//.test(
                              underwritingSpreadsheet.toLowerCase()
                            )
                          )
                            window.open(underwritingSpreadsheet, "_blank");
                          else
                            window.open(
                              config.urls.api.replace("/api/", "") +
                                underwritingSpreadsheet,
                              "_blank"
                            );
                        }}
                      >
                        DOWNLOAD
                      </Button>
                    ) : (
                      <Button
                        style={{ ...styles.downloadButton, marginBottom: 0 }}
                        onPress={async () => {
                          const fullFileUrl = await fetch(
                            MasterUnderwritingSpreadsheetFileUrl
                          );
                          const link = document.createElement("a");
                          link.href = fullFileUrl.url;
                          link.download = "MasterUnderwritingSpreadsheet.xlsx";
                          document.body.appendChild(link);
                          link.click();
                        }}
                      >
                        DOWNLOAD
                      </Button>
                    )}
                  </View>

                  <Text style={styles.verificationChecklistHeader}>Notes</Text>
                  <Text
                    appearance="hint"
                    style={styles.verificationChecklistSubHeader}
                  >
                    New Application Note
                  </Text>
                  <Input
                    style={styles.verificationNotesInput}
                    multiline
                    value={verificationData.newApplicationNote}
                    numberOfLines={5}
                    placeholder="Add Note(s) Here"
                    onChangeText={(text) =>
                      setVerificationData({
                        ...verificationData,
                        newApplicationNote: text,
                      })
                    }
                  />
                  <TouchableWithoutFeedback
                    onPress={() =>
                      setIsNotesHistoryCollapsed(!isNotesHistoryCollapsed)
                    }
                  >
                    <View style={styles.flexRowBetween}>
                      <View style={{ ...styles.flexRow, alignItems: "center" }}>
                        <Text style={styles.cardSubHeader} appearance="hint">
                          NOTES HISTORY
                        </Text>
                      </View>
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: !isNotesHistoryCollapsed
                                  ? "180deg"
                                  : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                  <Collapsible collapsed={isNotesHistoryCollapsed}>
                    {verificationData.applicationNotes.map(
                      (note: any, idx: number) => {
                        return (
                          <View key={`note-${idx}`}>
                            <Text
                              appearance="hint"
                              style={styles.verificationChecklistSubHeader}
                            >
                              Application Note by {note.author} -{" "}
                              {`${new Date(note.created).toLocaleString(
                                "en-US",
                                {
                                  timeZone: "America/New_York",
                                }
                              )} (ET)`}
                            </Text>
                            <Text style={styles.verificationChecklistNoteText}>
                              {note.text}
                            </Text>
                          </View>
                        );
                      }
                    )}
                  </Collapsible>

                  <Text style={styles.verificationChecklistHeader}>
                    Manual Decision
                  </Text>
                  <Text
                    appearance="hint"
                    style={styles.verificationChecklistSubHeader}
                  >
                    Manual Decision
                  </Text>
                  <Select
                    style={styles.select}
                    placeholder="Manual decision"
                    value={verificationData.manualDecision}
                    selectedIndex={selectedManualDecision}
                    onSelect={(index) => setSelectedManualDecision(index)}
                  >
                    {getManualDecisionOptions().map((option, idx) => (
                      <SelectItem
                        style={styles.selectItem}
                        accessoryRight={InfoIcon}
                        title={() => (
                          <Text style={styles.selectItem}>{option}</Text>
                        )}
                        key={`${idx}-manual-decision-option`}
                      />
                    ))}
                  </Select>

                  <Text style={styles.verificationChecklistHeader}>
                    User Assignment
                  </Text>
                  <View style={{ ...styles.flexRow, alignItems: "center" }}>
                    <Select
                      style={styles.select}
                      placeholder="User assignment"
                      value={
                        userAssignmentOptions.filter(
                          (user: any) =>
                            user.id === verificationData.assignedUser
                        ).length &&
                        userAssignmentOptions.filter(
                          (user: any) =>
                            user.id === verificationData.assignedUser
                        )[0].name
                      }
                      selectedIndex={selectedUserAssignment}
                      onSelect={(index) => setSelectedUserAssignment(index)}
                    >
                      {userAssignmentOptions.map((user: any, idx: number) => (
                        <SelectItem
                          style={styles.selectItem}
                          accessoryRight={InfoIcon}
                          title={() => (
                            <Text style={styles.selectItem}>{user.name}</Text>
                          )}
                          key={`${idx}-user-assignment-option`}
                        />
                      ))}
                    </Select>
                    {!!applicationAssignmentHistory.length &&
                      UserHistory(
                        applicationAssignmentHistory[
                          applicationAssignmentHistory.length - 1
                        ]
                      )}
                    <Button
                      style={styles.reAssignButton}
                      onPress={() => reAssignUser()}
                    >
                      RE-ASSIGN
                    </Button>
                    <View style={styles.space} />
                  </View>
                  <Divider />
                  <TouchableWithoutFeedback
                    onPress={() =>
                      setIsAssignmentHistoryCollapsed(
                        !isAssignmentHistoryCollapsed
                      )
                    }
                  >
                    <View style={styles.flexRowBetween}>
                      <View style={{ ...styles.flexRow, alignItems: "center" }}>
                        <Text style={styles.cardSubHeader} appearance="hint">
                          ASSIGNMENT HISTORY
                        </Text>
                      </View>
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: !isAssignmentHistoryCollapsed
                                  ? "180deg"
                                  : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                  <Collapsible collapsed={isAssignmentHistoryCollapsed}>
                    {applicationAssignmentHistory.map(
                      (obj: any, idx: number) => (
                        <View
                          style={{ ...styles.flexRow, alignItems: "center" }}
                          key={`assignment-history-${idx}`}
                        >
                          <Text style={styles.historyUserName}>{obj.user}</Text>
                          {UserHistory(obj)}
                          <TouchableOpacity
                            style={styles.assignButton}
                            onPress={() => reAssignUser(obj.userId)}
                          >
                            <View style={styles.assignButtonFill}>
                              <Text style={styles.assignButtonText}>
                                ASSIGN
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      )
                    )}
                  </Collapsible>

                  <View style={{ ...styles.flexRow, marginTop: 25 }}>
                    <Button
                      style={
                        disableContinueDeclineBtn
                          ? styles.disabledButton
                          : styles.outlineButton
                      }
                      appearance="outline"
                      status="danger"
                      disabled={disableContinueDeclineBtn}
                      onPress={() => setIsDeclineModalOpen(true)}
                    >
                      DECLINE
                    </Button>
                    <Button
                      style={
                        disableSaveBtn
                          ? styles.disabledButton
                          : styles.outlineButton
                      }
                      appearance="outline"
                      onPress={() => saveUnderwriterNotes()}
                      disabled={disableSaveBtn}
                    >
                      SAVE
                    </Button>
                    <Button
                      style={
                        disableContinueDeclineBtn
                          ? styles.disabledButton
                          : styles.gradientButton
                      }
                      onPress={() => saveUnderwriterNotes(true)}
                      disabled={disableContinueDeclineBtn}
                    >
                      {loanStatus.includes("full") &&
                      !verificationData.manualDecision
                        .toLowerCase()
                        .includes("decline")
                        ? "APPROVE"
                        : "CONTINUE"}
                    </Button>
                  </View>
                </ScrollView>
              )}
            </>
          )}
        </View>
        <TouchableOpacity style={styles.backdrop} onPress={() => close()} />
      </ThemeProvider>
    </Modal>
  );
};

export default VerificationChecklistModal;
