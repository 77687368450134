import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Datepicker,
  CalendarViewModes,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import { useStore } from "../../stores";
import MBOStore from "../../stores/MBOStore";
import { themedStyles } from "./themedStyles";

export const SummaryPersonalInfo: React.FC<any> = ({
  user,
  mbo,
  business,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  setSuccess,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const PhoneIcon = (props: any) => <Icon {...props} name="phone-outline" />;
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar-outline" />
  );

  const { authStore, mboStore } = useStore();

  const defaultFields = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    birthday: "",
    businessName: "",
    businessAddress: "",
    businessAddStreetLine1: "",
    businessAddStreetLine2: "",
    businessAddCity: "",
    businessAddState: "",
    businessAddZipCode: "",
    businessType: "",
    bypassEditLimit: true,
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [localFormData, setLocalFormData] = useState<any>({ ...defaultFields });

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");

  useEffect(() => {
    formDataReset();
    setSuccess(false);
  }, []);

  const formDataReset = () => {
    setLocalFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      birthday: formData.birthday,
      businessName: business.businessName,
      businessAddress: business.businessAddress,
      businessAddStreetLine1: business.businessAddStreetLine1,
      businessAddStreetLine2: business.businessAddStreetLine2,
      businessAddCity: business.businessAddCity,
      businessAddState: business.businessAddState,
      businessAddZipCode: business.businessAddZipCode,
      businessType: business.businessType,
      bypassEditLimit: true,
    });
  };

  const firstNameRef = useRef<Input>(null);
  const lastNameRef = useRef<Input>(null);
  const phoneNumberRef = createRef<any>();
  const workEmailRef = useRef<Input>(null);

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  const unmaskPhoneNumber = (phoneNumber: any) => {
    let ret = "";
    for (const i in phoneNumber) {
      if (
        Boolean(
          [true, true, true, true, true, true, true, true, true, true][
            phoneNumber[i]
          ]
        ) ||
        phoneNumber[i] === "+"
      ) {
        ret = `${ret}${phoneNumber[i]}`;
      }
    }
    return ret;
  };

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorMessages({ ...defaultErrors });
    const result = await authStore.updateBasicAndBusinessInformation({
      ...localFormData,
      phoneNumber: unmaskPhoneNumber(formData.phoneNumber),
    });
    if (!result.ok) {
      console.log(result.errors);
      setErrorMessages(result.errors);
    } else {
      setFormData({
        ...formData,
        ...localFormData,
      });
      setIsEdit(false);
      setSuccess(true);
    }
    setLoading(false);
  }, [loading, localFormData]);

  useEffect(() => {
    const num = localFormData.phoneNumber;
    let prt = "";
    if (num.length > 0) {
      if (num.length <= 3) {
        prt = `(${num})`;
      } else if (num.length <= 6) {
        prt = `(${num.slice(0, 3)}) ${num.slice(3, -1)}${num.slice(-1)}`;
      } else {
        prt = `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(
          6,
          -1
        )}${num.slice(-1)}`;
      }
    }
    setFormattedPhoneNumber(prt);
  }, [localFormData.phoneNumber]);

  return (
    <View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <View style={styles.flexRow}>
        <View style={{ flex: 1 }}>
          <Text>
            {errorMessages?.firstName && (
              <Text category="h6" style={styles.error}>
                {errorMessages?.firstName}
              </Text>
            )}
          </Text>
          <Input
            ref={firstNameRef}
            style={{
              ...(isEdit ? styles.input : styles.inputNotEditing),
              backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
              borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
            }}
            textStyle={{
              ...(isEdit
                ? width > 576
                  ? styles.inputText
                  : styles.inputTextMobile
                : styles.inputTextNotEditing),
              color: isEdit
                ? theme === "light"
                  ? "black"
                  : "white"
                : "#8F9BB3",
            }}
            textContentType="name"
            autoCompleteType="name"
            autoCapitalize="sentences"
            disabled={!isEdit}
            placeholder="First Name"
            value={localFormData.firstName}
            onChangeText={(text) =>
              setLocalFormData({ ...localFormData, firstName: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                lastNameRef.current?.focus();
              }
            }}
            onSubmitEditing={() => lastNameRef.current?.focus()}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Text>
            {errorMessages?.lastName && (
              <Text category="h6" style={styles.error}>
                {errorMessages?.lastName}
              </Text>
            )}
          </Text>
          <Input
            ref={lastNameRef}
            style={{
              ...(isEdit ? styles.input : styles.inputNotEditing),
              backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
              borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
            }}
            textContentType="familyName"
            autoCompleteType="off"
            autoCapitalize="sentences"
            textStyle={{
              ...(isEdit
                ? width > 576
                  ? styles.inputText
                  : styles.inputTextMobile
                : styles.inputTextNotEditing),
              color: isEdit
                ? theme === "light"
                  ? "black"
                  : "white"
                : "#8F9BB3",
            }}
            disabled={!isEdit}
            placeholder="Last Name"
            value={localFormData.lastName}
            onChangeText={(text) =>
              setLocalFormData({ ...localFormData, lastName: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                phoneNumberRef.current?._inputElement.focus();
              }
            }}
            onSubmitEditing={() =>
              phoneNumberRef.current?._inputElement.focus()
            }
          />
        </View>
      </View>
      <Text>
        {errorMessages?.phoneNumber && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.phoneNumber}
          </Text>
        )}
      </Text>
      <TextInputMask
        ref={phoneNumberRef}
        style={{
          ...(isEdit ? styles.input : styles.inputNotEditing),
          ...(isEdit
            ? width > 576
              ? styles.inputText
              : styles.inputTextMobile
            : styles.inputTextNotEditing),
          ...(width > 576 ? styles.inputMask : styles.inputMaskMobile),
          color: isEdit ? (theme === "light" ? "black" : "white") : "#8F9BB3",
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        placeholder="Business Phone Number"
        autoCorrect={false}
        value={formattedPhoneNumber}
        type="cel-phone"
        options={{
          withDDD: true,
          dddMask: "+1 (999) 999-9999",
        }}
        onChangeText={(text) => {
          if (isEdit) {
            if (text.length <= 17) {
              setLocalFormData({
                ...localFormData,
                phoneNumber: text.replace(/[^0-9\+]/g, ""),
              });
            }
          }
        }}
      />
      <Text>
        {errorMessages?.email && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.email}
          </Text>
        )}
      </Text>
      <Input
        ref={workEmailRef}
        style={{
          ...(isEdit ? styles.input : styles.inputNotEditing),
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        textStyle={{
          ...(isEdit
            ? width > 576
              ? styles.inputText
              : styles.inputTextMobile
            : styles.inputTextNotEditing),
          color: isEdit ? (theme === "light" ? "black" : "white") : "#8F9BB3",
        }}
        disabled={!isEdit}
        value={localFormData.email}
        textContentType="emailAddress"
        autoCompleteType="email"
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="email-address"
        placeholder="Work Email"
        accessoryRight={EmailIcon}
        onChangeText={(text) =>
          setLocalFormData({ ...localFormData, email: text })
        }
      />
      <Datepicker
        style={{
          ...(isEdit ? styles.input : styles.inputNotEditing),
          backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
          borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
        }}
        accessoryRight={CalendarIcon}
        placeholder={() => (
          <Text style={styles.datePickerPlaceholderText}>Birthday</Text>
        )}
        disabled={!isEdit}
        startView={CalendarViewModes.YEAR}
        date={localFormData.birthday ? new Date(localFormData.birthday) : ""}
        min={new Date("1900-01-01")}
        onSelect={(nextDate) =>
          setLocalFormData({
            ...localFormData,
            birthday: `${1900 + nextDate.getYear()}-${
              nextDate.getMonth() + 1
            }-${nextDate.getDate()}`,
          })
        }
      />
      {isEdit && (
        <View style={styles.flexRow}>
          <TouchableOpacity
            style={styles.saveButton}
            onPress={() => {
              onSubmitForm();
            }}
          >
            <LinearGradient
              style={styles.saveButtonGradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={["#D012B2", "#8A1CDB", "#5327FF"]}
            >
              <Text style={styles.saveButtonText}>Save</Text>
              <Icon
                style={styles.saveButtonIcon}
                name="checkmark-circle-2"
                fill="white"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.cancelButton,
              ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
            }}
            onPress={() => {
              setIsEdit(false);
              formDataReset();
            }}
          >
            <Text
              style={{
                ...styles.cancelButtonText,
                ...(theme === "light" ? { color: "#222B45" } : {}),
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
