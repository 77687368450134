import { useLinkTo, useNavigation } from "@react-navigation/native";
import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React from "react";
import { View, ScrollView, Dimensions, Image } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const EmailVerification: React.FC<any> = ({ route }) => {
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const { authStore } = useStore();
  const { width } = Dimensions.get("window");
  const linkTo = useLinkTo();
  const EmailVerifyImage = require("../../../assets/EmailVerifyImage.png");
  const email =
    route?.params?.email ||
    new URLSearchParams(window.location.search).get("email") ||
    authStore.user?.email;

  const businessNameVerification =
    route?.params?.businessNameVerification ||
    new URLSearchParams(window.location.search).get("businessNameVerification");

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View
          style={
            width > 992 ? styles.logInContainer : styles.logInContainerMobile
          }
        >
          <Image
            style={
              width > 992
                ? { width: 517, height: 809 }
                : { width: "100%", height: width * 0.8077 }
            }
            source={EmailVerifyImage}
          />
          <View style={width > 992 ? styles.logIn : styles.logInMobile}>
            <Text style={styles.header}>
              {businessNameVerification &&
                `You have been listed as a business partner for ${businessNameVerification}'s loan. \n\n`}
              Please verify your email to continue the registration process.
            </Text>
            <Text style={styles.subHeader}>
              Already verified?{" "}
              <Text
                status="info"
                style={styles.link}
                onPress={() => linkTo("/auth/log-in")}
              >
                Log In
              </Text>
              .
            </Text>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default EmailVerification;
