import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Input,
  Divider,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Dimensions, View } from "react-native";
import Collapsible from "react-native-collapsible";
import { TextInputMask } from "react-native-masked-text";

import { useStore } from "../../stores";
import { UploadButtonWithView } from "./UploadButtonWithView";
import { themedStyles } from "./themedStyles";

export const FullApplicationDetails: React.FC<any> = ({
  canEdit,
  business,
  loan,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const LoadingIndicator = (props: any) => <Spinner />;
  const EditIcon = (props: any) => (
    <Icon {...props} name="edit-outline" fill="#26DDF1" />
  );
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const { loanStore } = useStore();

  const [formData, setFormData] = useState({
    companyOrganizationalChart: loan.companyOrganizationalChart,
    operatingAgreement: loan.operatingAgreement,
    corporateOrAuthorizingResolution: loan.corporateOrAuthorizingResolution,
    fiscalYearEndProjections: loan.fiscalYearEndProjections,
    financialStatements: loan.financialStatements,
    contractingOfficer: {
      firstName: loan.contractingOfficerFirstName,
      lastName: loan.contractingOfficerLastName,
      email: loan.contractingOfficerEmail,
      phoneNumber: loan.contractingOfficerPhone,
    },
  });

  const ErrorDescription = (fieldName: string) => {
    if (!errors[fieldName]) return <></>;
    return <View style={styles.error}>{errors[fieldName]}</View>;
  };

  useEffect(() => {
    setFormData({
      companyOrganizationalChart: loan.companyOrganizationalChart,
      operatingAgreement: loan.operatingAgreement,
      corporateOrAuthorizingResolution: loan.corporateOrAuthorizingResolution,
      fiscalYearEndProjections: loan.fiscalYearEndProjections,
      financialStatements: loan.financialStatements,
      contractingOfficer: {
        firstName: loan.contractingOfficerFirstName,
        lastName: loan.contractingOfficerLastName,
        email: loan.contractingOfficerEmail,
        phoneNumber: loan.contractingOfficerPhone,
      },
    });
  }, [business, loan]);

  const fileFields = [
    {
      display: "Company Organizational Chart",
      field: "companyOrganizationalChart",
    },
    {
      display: "Operating Agreement",
      field: "operatingAgreement",
    },
    {
      display: "Corporate Or Authorizing Resolution",
      field: "corporateOrAuthorizingResolution",
    },
    {
      display: "Fiscal Year End Projections",
      field: "fiscalYearEndProjections",
    },
    {
      display: "Financial Statements",
      field: "financialStatements",
    },
  ];

  const [errors, setErrors] = useState<any>({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <View>
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Full Application
        </Text>
        {!!canEdit && (
          <Button
            style={styles.editButton}
            appearance="ghost"
            status="basic"
            accessoryLeft={EditIcon}
            onPress={() => {
              setIsEditing(!isEditing);
            }}
          />
        )}
      </View>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        {fileFields.map((field: any, idx: number) => (
          <View key={idx}>
            {ErrorDescription(field.field)}
            <Text style={styles.inputLabel}>{field.display}</Text>
            <UploadButtonWithView
              fieldName={field.field}
              formData={formData}
              setFormData={setFormData}
              setErrors={setErrors}
              isEditing={isEditing}
            />
          </View>
        ))}

        <Text style={styles.inputLabel}>Contracting Officer contact info</Text>
        {ErrorDescription("contractingOfficer")}
        <View style={styles.flexRow}>
          <Input
            style={styles.input}
            textStyle={styles.inputText}
            placeholder="First Name"
            status="basic"
            disabled={!isEditing}
            value={formData.contractingOfficer.firstName}
            onChangeText={(text) =>
              setFormData({
                ...formData,
                contractingOfficer: {
                  ...formData.contractingOfficer,
                  firstName: text,
                },
              })
            }
          />
          <Input
            style={styles.input}
            textStyle={styles.inputText}
            placeholder="Last Name"
            status="basic"
            disabled={!isEditing}
            value={formData.contractingOfficer.lastName}
            onChangeText={(text) =>
              setFormData({
                ...formData,
                contractingOfficer: {
                  ...formData.contractingOfficer,
                  lastName: text,
                },
              })
            }
          />
        </View>
        <Input
          style={styles.input}
          textStyle={styles.inputText}
          placeholder="Work email address"
          status="basic"
          disabled={!isEditing}
          value={formData.contractingOfficer.email}
          onChangeText={(text) =>
            setFormData({
              ...formData,
              contractingOfficer: {
                ...formData.contractingOfficer,
                email: text,
              },
            })
          }
          accessoryRight={EmailIcon}
        />
        <TextInputMask
          style={width > 576 ? styles.inputMask : styles.inputMaskMobile}
          placeholder="Phone Number"
          autoCorrect={false}
          value={formData.contractingOfficer.phoneNumber}
          type="cel-phone"
          disabled={!isEditing}
          options={{
            withDDD: true,
            dddMask: "+1 (999) 999-9999",
          }}
          onChangeText={(text) => {
            if (text.length <= 17) {
              setFormData({
                ...formData,
                contractingOfficer: {
                  ...formData.contractingOfficer,
                  phoneNumber: text,
                },
              });
            }
          }}
        />

        {isEditing && (
          <Button
            style={{ ...styles.saveButton, width: 200, alignSelf: "center" }}
            onPress={async () => {
              setLoading(true);
              const res = await loanStore.updateLoanApplication(
                loan.id,
                formData
              );
              setLoading(false);
              if (!res?.ok) {
                setErrors(res?.errors);
              } else {
                setErrors({});
                setIsEditing(false);
              }
            }}
            accessoryRight={loading ? LoadingIndicator : <></>}
          >
            Save
          </Button>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
