import * as eva from "@eva-design/eva";
import { Layout, ThemeProvider, useStyleSheet } from "@ui-kitten/components";
import React, { useState } from "react";
import { View, Image, ScrollView, Dimensions } from "react-native";

import Header from "../../components/Header";
import PasswordReset from "./PasswordReset";
import VerifyResetPasswordEmail from "./VerifyResetPasswordEmail";
import { themedStyles } from "./themedStyles";

const ResetPassword: React.FC<any> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const ResetPasswordImage = require("../../../assets/ResetPasswordImage.png");
  const AdminResetPasswordImage = require("../../../assets/AdminResetPasswordImage.png");

  const [emailSent, setEmailSent] = useState(false);

  const isAdminReset = window.location.pathname.split("/")[1] === "admin";
  const isInvestorReset = window.location.pathname.split("/")[1] === "investor";
  const isReferralPartnerReset =
    window.location.pathname.split("/")[1] === "referral";

  return (
    <ThemeProvider
      theme={isAdminReset || isInvestorReset ? eva.light : eva.dark}
    >
      <Layout
        style={{
          ...styles.container,
          ...(isAdminReset || isInvestorReset
            ? { backgroundColor: "white" }
            : {}),
        }}
      >
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme={isAdminReset || isInvestorReset ? "light" : "dark"} />
          <View
            style={
              width > 992
                ? styles.resetPasswordContainer
                : styles.resetPasswordContainerMobile
            }
          >
            <Image
              style={
                width > 992
                  ? { width: 517, height: 809 }
                  : { width: "100%", height: width * 0.8077 }
              }
              source={
                isAdminReset || isInvestorReset
                  ? AdminResetPasswordImage
                  : ResetPasswordImage
              }
            />
            {!emailSent && (
              <PasswordReset
                onContinue={setEmailSent}
                isAdminReset={isAdminReset}
                isInvestorReset={isInvestorReset}
                isReferralPartnerReset={isReferralPartnerReset}
              />
            )}
            {emailSent && (
              <VerifyResetPasswordEmail
                onContinue={setEmailSent}
                isAdminReset={isAdminReset}
                isInvestorReset={isInvestorReset}
              />
            )}
          </View>
        </ScrollView>
      </Layout>
    </ThemeProvider>
  );
};

export default ResetPassword;
