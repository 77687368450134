import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Divider,
  Modal,
  IndexPath,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import { TouchableWithoutFeedback } from "@ui-kitten/components/devsupport";
import * as WebBrowser from "expo-web-browser";
import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";

import HoverBubble from "../../components/HoverBubble";
import { TableDisplay } from "../../components/TableDisplay";
import config from "../../config";
import * as api from "../../services/api";
import { checkMatchingLenderData } from "../../services/api";
import {
  generateClosingDocument,
  generateLoanTerms,
  getCreditFileData,
  getDocuSignAppKeys,
  retrieveSignedDocument,
  sendDocuSignDocument,
} from "../../services/api/docusign";
import { useStore } from "../../stores";
import { PullReportButton } from "../UnderwriterPortalCollateral/PullReportButton";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const ClosingDocumentsTab: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const [loading, setLoading] = useState(true);
  const [docuSignModal, setDocuSignModal] = useState(false);
  const [integrationKey, setIntegrationKey] = useState("");

  const [templateIdList, setTemplateIdList] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [openSureModal, setOpenSureModal] = useState(false);
  const [openFailModal, setFailModal] = useState(false);
  const [failText, setFailText] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openGenerateSuccessModal, setOpenGenerateSuccessModal] =
    useState(false);
  const [currentSentTemplateId, setCurrentSentTemplateId] = useState("");
  const [generateDocModal, setGenerateDocModal] = useState(false);
  const [generateDocLoad, setGenerateDocLoad] = useState(false);
  const [loanTermsModal, setLoanTermsModal] = useState(false);
  const [generateLoanTermsModal, setGenerateLoanTermsModal] = useState(false);
  const [sendLoanTermsModal, setSendLoanTermsModal] = useState(false);
  const [openRetrieveModal, setOpenRetrieveModal] = useState(false);
  const [isLoanTerms, setIsLoanTerms] = useState(false);
  const [signByCloModal, setSignByCloModal] = useState(false);
  const [successModalText, setSuccessModalText] = useState("");
  const [lendingDocStatus, setLendingDocStatus] = useState("");
  const [loanTermsStatus, setLoanTermsStatus] = useState("");
  const [docusignApiAuthUrl, setDocusignApiAuthUrl] = useState(
    "https://account.docusign.com"
  );
  const [frontendUrl, setFrontendUrl] = useState("");
  const [isCbo, setIsCbo] = useState(false);

  const [generateDocErrors, setGenerateDocErrors] = useState("");

  const [lenderDropdownIndex, setLenderDropdownIndex] = React.useState<
    IndexPath | undefined
  >(undefined);

  const { authStore } = useStore();

  const [lendersList, setLendersList] = useState<any>([]);

  const [generateFormData, setGenerateFormData] = useState({
    loanAgreementDate: "_____",
    maturityDate: "_____",
    formOfNoteDate: "_____",
    primeRate: "_____",
    lenderId: "",
    lender: "Select Lender:",
    lenderCustom: "",
    serviceProvider: "OppZo Loan Servicing",
    guarantor: "",
  });

  const viewLoanTermsButton = (
    <Button status="basic" onPress={() => setLoanTermsModal(true)}>
      VIEW
    </Button>
  );

  const generateLoanTermsButton = (
    <Button status="basic" onPress={() => setGenerateLoanTermsModal(true)}>
      GENERATE
    </Button>
  );

  const sendLoanTermsButton = (disabled = false) => (
    <>
      <Button
        disabled={disabled}
        status="basic"
        onPress={() => setSendLoanTermsModal(true)}
      >
        SEND
      </Button>
      {disabled && (
        <HoverBubble
          width="50%"
          theme="dark"
          text={
            <Text style={styles.tooltipTextLight}>
              Only the CBO is allowed to Send Loan Terms to applicants for their
              signature.
            </Text>
          }
        />
      )}
    </>
  );

  const viewLendingDocumentButton = (
    <Button
      status="basic"
      onPress={() => {
        setDocuSignModal(true);
      }}
    >
      VIEW
    </Button>
  );

  const generateDocumentButton = (
    <Button status="basic" onPress={() => setGenerateDocModal(true)}>
      GENERATE
    </Button>
  );

  const sendForSignatureButton = (disabled = false) => (
    <>
      <Button
        disabled={disabled}
        status="basic"
        onPress={() => setOpenSureModal(true)}
      >
        SEND
      </Button>
      {disabled && (
        <HoverBubble
          width="50%"
          text={
            <Text style={styles.tooltipTextLight}>
              Only the CBO is allowed to Send Closing Documents to applicants
              for their signature.
            </Text>
          }
        />
      )}
    </>
  );

  const signByCloButton = (disabled = false) => (
    <>
      <Button
        disabled={disabled}
        status="basic"
        onPress={() => setSignByCloModal(true)}
      >
        SIGN
      </Button>
      {disabled && (
        <HoverBubble
          width="50%"
          text={
            <Text style={styles.tooltipTextLight}>
              Only the CBO is allowed to countersign Closing Documents.
            </Text>
          }
        />
      )}
    </>
  );

  const viewSignedButton = (
    <Button
      status="basic"
      onPress={() => {
        setIsLoanTerms(false);
        setOpenRetrieveModal(true);
      }}
    >
      VIEW
    </Button>
  );

  const viewLoanTermsSignedButton = (
    <Button
      status="basic"
      onPress={() => {
        setIsLoanTerms(true);
        setOpenRetrieveModal(true);
      }}
    >
      VIEW
    </Button>
  );

  const lenderDropdown = (
    <Layout style={styles.input}>
      <Select
        status="success"
        size="large"
        selectedIndex={lenderDropdownIndex}
        value={generateFormData.lender}
        onSelect={(index: IndexPath | IndexPath[]) => {
          if (Array.isArray(index)) {
            index = index[0];
          }
          setLenderDropdownIndex(index);
        }}
      >
        {lendersList.map((lender: any) => (
          <SelectItem key={lender.id} title={lender.name} />
        ))}
      </Select>
    </Layout>
  );

  useEffect(() => {
    if (lenderDropdownIndex?.row)
      setGenerateFormData({
        ...generateFormData,
        lenderId: lendersList[lenderDropdownIndex?.row].id,
        lender: lendersList[lenderDropdownIndex?.row].name,
      });
  }, [lenderDropdownIndex]);

  useEffect(() => {
    if (
      lendersList.filter(
        (lender: any) => lender.name === generateFormData.lender
      ).length
    )
      setGenerateFormData({
        ...generateFormData,
        lenderId:
          lendersList.filter(
            (lender: any) => lender.name === generateFormData.lender
          )[0].id || 0,
      });
  }, [generateFormData.lender]);

  const serviceProviderButton = (
    <Button status="basic">{generateFormData.serviceProvider}</Button>
  );

  const guarantorInput = (
    <Input
      style={styles.input}
      textStyle={styles.inputText}
      value={generateFormData.guarantor}
      placeholder="Guarantor"
      onChangeText={(text) => {
        setGenerateFormData({ ...generateFormData, guarantor: text });
      }}
    />
  );

  const [loanTermsData, setLoanTermsData] = useState([
    {
      header: "Document Name",
      displayValue: "Loan Terms Document",
      fieldNameInResponse: "loanTermsName",
    },
    {
      header: "Status",
      displayValue: "Not yet signed",
      fieldNameInResponse: "loanTermsStatus",
    },
    {
      header: "Generate Loan Terms",
      isControlButton: true,
      displayValue: generateLoanTermsButton,
      fieldNameInResponse: "",
    },
    {
      header: "View Loan Terms",
      isControlButton: true,
      displayValue: viewLoanTermsButton,
      fieldNameInResponse: "",
    },
  ]);

  const [sendLoanTermsData, setSendLoanTermsData] = useState([
    {
      header: "Send to applicant for signature",
      isControlButton: true,
      displayValue: sendLoanTermsButton(!isCbo),
    },
  ]);

  const [fullLendingDocumentData, setFullLendingDocumentData] = useState([
    {
      header: "Document Name",
      displayValue: "-",
      fieldNameInResponse: "lendingDocName",
    },
    {
      header: "Status",
      displayValue: "Document not yet generated",
      fieldNameInResponse: "lendingDocStatus",
    },
    {
      header: "Generate Document",
      isControlButton: true,
      displayValue: generateDocumentButton,
      fieldNameInResponse: "",
    },
    {
      header: "View Document",
      isControlButton: true,
      displayValue: viewLendingDocumentButton,
      fieldNameInResponse: "",
    },
  ]);

  const [sendForSignatureData, setSendForSignatureData] = useState([
    {
      header: "Send to applicant for signature",
      isControlButton: true,
      displayValue: sendForSignatureButton(!isCbo),
      fieldNameInResponse: "",
    },
  ]);

  const [uccData, setUccData] = useState([
    {
      header: "UCC1 filling status",
      fieldNameInResponse: "uccFillingStatus",
      displayValue: "-",
    },
    {
      header: "UCC1 filling status description",
      fieldNameInResponse: "uccFillingStatusDescription",
      displayValue: "-",
      isTextBlock: true,
    },
    {
      header: "UCC1 filling document",
      fieldNameInResponse: "uccFillingAttachment",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
    },
    {
      header: "UCC3 termination status",
      fieldNameInResponse: "uccTerminationStatus",
      displayValue: "-",
    },
    {
      header: "UCC3 termination status description",
      fieldNameInResponse: "uccTerminationStatusDescription",
      displayValue: "-",
      isTextBlock: true,
    },
    {
      header: "UCC3 termination document",
      fieldNameInResponse: "uccTerminationAttachment",
      displayValue: <PullReportButton uri="" isLink />,
      isLink: true,
    },
  ]);

  const setCreditFileData = async () => {
    const cfd = await getCreditFileData(authStore?.accessToken || "", {
      applicationId,
    });
    if (cfd.response.entities) {
      setIsCbo(cfd.response.entities["isCbo"]);

      setLoanTermsData(
        loanTermsData.map((obj) => ({
          ...obj,
          displayValue:
            obj.fieldNameInResponse !== ""
              ? cfd.response.entities[obj.fieldNameInResponse] ||
                (obj.fieldNameInResponse ? "?" : "")
              : obj.displayValue,
        }))
      );

      setFullLendingDocumentData(
        fullLendingDocumentData.map((obj) => ({
          ...obj,
          displayValue:
            obj.fieldNameInResponse !== ""
              ? cfd.response.entities[obj.fieldNameInResponse] ||
                (obj.fieldNameInResponse ? "?" : "")
              : obj.displayValue,
        }))
      );

      setLendingDocStatus(cfd.response.entities["lendingDocStatus"]);
      setLoanTermsStatus(cfd.response.entities["loanTermsStatus"]);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api.getUccData(
        authStore?.accessToken || "",
        applicationId
      );
      const resp = response.response.entities;
      console.log(resp);
      setUccData(
        uccData.map((obj: any) => ({
          ...obj,
          displayValue: !obj.isLink ? (
            resp[obj.fieldNameInResponse]
          ) : (
            <PullReportButton uri={resp[obj.fieldNameInResponse]} isLink />
          ),
        }))
      );

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api.getLenderData(
        authStore?.accessToken || "",
        applicationId
      );
      const resp = response.response.entities;
      console.log(resp);
      setLendersList(resp.lendersList);
      setGenerateFormData({
        ...generateFormData,
        lender: resp.lenderName || generateFormData.lender,
        lenderId: resp.lenderId || generateFormData.lenderId,
        guarantor: resp.guarantor || generateFormData.guarantor,
        serviceProvider:
          resp.serviceProvider || generateFormData.serviceProvider,
      });
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resp = await getDocuSignAppKeys(authStore?.accessToken || "");
      if (resp.response.entities) {
        setIntegrationKey(resp.response.entities["integrationKey"]);
        setDocusignApiAuthUrl(resp.response.entities["docusignApiAuthUrl"]);
        setFrontendUrl(resp.response.entities["frontendUrl"]);
      }
      await setCreditFileData();
      setLoading(false);
    })();
  }, [applicationId]);

  useEffect(() => {
    if (docuSignModal) {
      if (lendingDocStatus === "Document not yet generated") {
        setFailModal(true);
        setFailText(
          "Document is not yet generated. Please click the 'Generate' button first."
        );
        setDocuSignModal(false);
      } else {
        const url = `${frontendUrl}/underwriter/docusign-modal/?state=${applicationId}`;
        WebBrowser.openBrowserAsync(url);
        setDocuSignModal(false);
      }
    }
  }, [docuSignModal]);

  useEffect(() => {
    if (loanTermsModal) {
      if (loanTermsStatus === "Document not yet generated") {
        setFailModal(true);
        setFailText(
          "Document is not yet generated. Please click the 'Generate' button first."
        );
        setLoanTermsModal(false);
      } else {
        const url = `${frontendUrl}/underwriter/loan-terms-modal/?state=${applicationId}`;
        WebBrowser.openBrowserAsync(url);
        setLoanTermsModal(false);
      }
    }
  }, [loanTermsModal]);

  useEffect(() => {
    if (openRetrieveModal) {
      (async () => {
        const resp = await retrieveSignedDocument(
          authStore?.accessToken || "",
          { applicationId, isLoanTerms }
        );
        if (resp.response.entities["fileUri"]) {
          window.open(
            config.urls.api.replace("/api/", "") +
              resp.response.entities["fileUri"],
            "_blank"
          );
        }
        setOpenRetrieveModal(false);
      })();
    }
  }, [openRetrieveModal]);

  useEffect(() => {
    if (signByCloModal) {
      const url = `${frontendUrl}/underwriter/lending-doc-clo-sign-modal/?state=${applicationId}`;
      WebBrowser.openBrowserAsync(url);
      setSignByCloModal(false);
    }
  }, [signByCloModal]);

  const sendDocument = (isLoanTerms: boolean) => {
    (async () => {
      const resp = await sendDocuSignDocument(authStore?.accessToken || "", {
        applicationId,
        isLoanTerms,
      });
      if (resp.response.entities) {
        setSuccessModalText(resp.response.entities["response"]);
        await setCreditFileData();
        setSendLoanTermsModal(false);
        setOpenSuccessModal(true);
      }
    })();
  };

  const onGenerateDocPress = () => {
    (async () => {
      setGenerateDocErrors("");
      setGenerateDocLoad(true);
      console.log(generateFormData);
      if (
        generateFormData.lender === "Select Lender:" ||
        generateFormData.guarantor === ""
      ) {
        setGenerateDocLoad(false);
        setGenerateDocModal(false);
        setFailText(
          "Please fill up the Lender, Service Provider, and Guarantor fields before generating files."
        );
        setFailModal(true);
        return;
      }
      const data = {
        applicationId,
        ...generateFormData,
      };
      const respCheck = await checkMatchingLenderData(
        authStore?.accessToken || "",
        { ...data, isLoanTerms: false }
      );
      if (respCheck.response.entities) {
        if (respCheck.response.entities["showWarning"]) {
          setGenerateDocLoad(false);
          setGenerateDocModal(false);
          setFailText(
            `The Lender Data in the generated Loan Terms document is different from the Lender Data about to be used to generate the Full Lending document.\n\nLender Data in Loan Terms:
      Lender: ${respCheck.response.entities["lenderData"]["lender"]}
      Service Provider: ${respCheck.response.entities["lenderData"]["serviceProvider"]}
      Guarantor: ${respCheck.response.entities["lenderData"]["guarantor"]}`
          );
          setFailModal(true);
        } else {
          const resp = await generateClosingDocument(
            authStore?.accessToken || "",
            data
          );
          if (resp.response.entities) {
            if (resp.response.entities["primeRate"]) {
              setGenerateDocErrors(resp.response.entities["primeRate"]);
              setGenerateDocLoad(false);
            } else {
              await setCreditFileData();
              setGenerateDocModal(false);
              setGenerateDocLoad(false);
              setOpenGenerateSuccessModal(true);
            }
          }
        }
      }
    })();
  };

  const onGenerateLoanTermsPress = () => {
    (async () => {
      if (
        generateFormData.lender === "Select Lender:" ||
        generateFormData.guarantor === ""
      ) {
        setGenerateDocLoad(false);
        setGenerateLoanTermsModal(false);
        setFailText(
          "Please fill up the Lender, Service Provider, and Guarantor fields before generating files."
        );
        setFailModal(true);
        return;
      }
      const data = {
        applicationId,
        ...generateFormData,
      };
      const respCheck = await checkMatchingLenderData(
        authStore?.accessToken || "",
        { ...data, isLoanTerms: true }
      );
      if (respCheck.response.entities) {
        if (respCheck.response.entities["showWarning"]) {
          setGenerateDocLoad(false);
          setGenerateLoanTermsModal(false);
          setFailText(
            `The Lender Data in the generated Full Lending document is different from the Lender Data about to be used to generate the Loan Terms document.\n\nLender Data in Full Lending document:
      Lender: ${respCheck.response.entities["lenderData"]["lender"]}
      Service Provider: ${respCheck.response.entities["lenderData"]["serviceProvider"]}
      Guarantor: ${respCheck.response.entities["lenderData"]["guarantor"]}`
          );
          setFailModal(true);
        } else {
          const resp = await generateLoanTerms(
            authStore?.accessToken || "",
            data
          );
          if (resp.response.entities) {
            await setCreditFileData();
            setGenerateLoanTermsModal(false);
            setOpenGenerateSuccessModal(true);
          }
        }
      }
    })();
  };

  useEffect(() => {
    if (loanTermsStatus === "Complete") {
      (async () => {
        const tmp = loanTermsData.filter((obj) => "header" in obj);
        tmp.push({
          header: "View Signed Loan Terms",
          isControlButton: true,
          displayValue: viewLoanTermsSignedButton,
          fieldNameInResponse: "",
        });

        setLoanTermsData(tmp);
      })();
    }
  }, [loanTermsStatus]);

  useEffect(() => {
    if (lendingDocStatus === "Complete") {
      (async () => {
        const tmp = fullLendingDocumentData.filter((obj) => "header" in obj);
        tmp.push({
          header: "View Signed Document",
          isControlButton: true,
          displayValue: viewSignedButton,
          fieldNameInResponse: "",
        });

        setFullLendingDocumentData(tmp);
      })();
    } else if (lendingDocStatus === "Signed by Applicant") {
      (async () => {
        const tmp = fullLendingDocumentData.filter((obj) => "header" in obj);
        tmp.push({
          header: "Countersign Document",
          isControlButton: true,
          displayValue: signByCloButton(!isCbo),
          fieldNameInResponse: "",
        });

        setFullLendingDocumentData(tmp);
      })();
    }
  }, [lendingDocStatus]);

  useEffect(() => {}, [templateIdList, currentSentTemplateId]);

  useEffect(() => {
    if (selectedTemplateId !== "") {
      setDocuSignModal(true);
    }
  }, [selectedTemplateId]);

  return (
    <>
      <Modal
        visible={openFailModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setFailModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>{failText}</Text>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => setFailModal(false)}
            >
              <Text style={styles.modalButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Modal
        visible={generateLoanTermsModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setGenerateLoanTermsModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>
            {`Generate Loan Terms Document for ${applicationId}?`}
          </Text>
          {loanTermsStatus === "Sent to Applicant" && (
            <Text style={{ color: "red" }}>
              Warning: You have already sent the document. Generating a new file
              will interrupt the signing process and unsend the previous file.
            </Text>
          )}
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => onGenerateLoanTermsPress()}
            >
              <Text style={styles.modalButtonText}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.modalNoButton}
              onPress={() => setGenerateLoanTermsModal(false)}
            >
              <Text style={styles.modalButtonText}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Modal
        visible={sendLoanTermsModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setSendLoanTermsModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>
            {loanTermsStatus === "Document not yet generated"
              ? "Document is not yet generated. Please click the 'Generate' button first."
              : loanTermsStatus !== "Available from Docusign"
              ? `Send document to Application ${applicationId}?`
              : "Document is not ready to send. Please click the 'View' button first to preview the document. This also prepares the document for sending."}
          </Text>
          {loanTermsStatus === "Document not yet generated" ||
          loanTermsStatus === "Available from Docusign" ? (
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => setSendLoanTermsModal(false)}
              >
                <Text style={styles.modalButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => sendDocument(true)}
              >
                <Text style={styles.modalButtonText}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.modalNoButton}
                onPress={() => setSendLoanTermsModal(false)}
              >
                <Text style={styles.modalButtonText}>No</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </Modal>
      <Modal
        visible={openSureModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setOpenSureModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>
            {lendingDocStatus === "Document not yet generated"
              ? "Document is not yet generated. Please click the 'Generate' button first."
              : lendingDocStatus !== "Available from Docusign"
              ? `Send document to Application ${applicationId}?`
              : "Document is not ready to send. Please click the 'View' button first to preview the document. This also prepares the document for sending."}
          </Text>
          {lendingDocStatus === "Document not yet generated" ||
          lendingDocStatus === "Available from Docusign" ? (
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => setOpenSureModal(false)}
              >
                <Text style={styles.modalButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalYesButton}
                onPress={() => sendDocument(false)}
              >
                <Text style={styles.modalButtonText}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.modalNoButton}
                onPress={() => setOpenSureModal(false)}
              >
                <Text style={styles.modalButtonText}>No</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </Modal>
      <Modal
        visible={openSuccessModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setOpenSuccessModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>{successModalText}</Text>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => setOpenSuccessModal(false)}
            >
              <Text style={styles.modalButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <Modal
        visible={openGenerateSuccessModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setOpenGenerateSuccessModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>
            Document is done generating. You may now either View the document or
            Send it to the applicant.
          </Text>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => setOpenGenerateSuccessModal(false)}
            >
              <Text style={styles.modalButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <View style={styles.adminDashboardContainer}>
        <View style={styles.requestedDocumentsContainer}>
          <ScrollView style={styles.requestedDocumentContent}>
            <Divider />
            {loading ? (
              <View style={styles.center}>
                <LoadingIndicator />
              </View>
            ) : (
              <>
                <View style={styles.card}>
                  <TableDisplay
                    data={[
                      {
                        header: "Lender",
                        displayValue: lenderDropdown,
                        fieldNameInResponse: "lender",
                        editable:
                          (lendingDocStatus !== "Complete" &&
                            lendingDocStatus !== "Signed by Applicant") ||
                          loanTermsStatus !== "Complete",
                        isDropdown: true,
                        isMultiSelect: false,
                        options: lendersList.map((lender: any) => lender.name),
                        placeholder: generateFormData.lender,
                      },
                      {
                        header: "Service Provider",
                        displayValue: serviceProviderButton,
                        fieldNameInResponse: "serviceProvider",
                      },
                      {
                        header: "Guarantor",
                        displayValue: guarantorInput,
                        fieldNameInResponse: "guarantor",
                        editable:
                          (lendingDocStatus !== "Complete" &&
                            lendingDocStatus !== "Signed by Applicant") ||
                          loanTermsStatus !== "Complete",
                      },
                    ]}
                    itemsPerRow={4}
                    isEditing
                    rawData={generateFormData}
                    setRawData={setGenerateFormData}
                  />
                </View>
                <View style={styles.card}>
                  <TouchableWithoutFeedback style={styles.flexRow}>
                    <Text style={styles.cardHeader}>Loan Terms</Text>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <TableDisplay data={loanTermsData} itemsPerRow={4} />
                  {loanTermsStatus !== "Signed by Applicant" &&
                  loanTermsStatus !== "Complete" ? (
                    <TableDisplay data={sendLoanTermsData} itemsPerRow={4} />
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.card}>
                  <TouchableWithoutFeedback style={styles.flexRow}>
                    <Text style={styles.cardHeader}>Full Lending Document</Text>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <TableDisplay
                    data={fullLendingDocumentData}
                    itemsPerRow={4}
                  />
                  {lendingDocStatus !== "Signed by Applicant" &&
                  lendingDocStatus !== "Complete" ? (
                    <TableDisplay data={sendForSignatureData} itemsPerRow={4} />
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.card}>
                  <TouchableWithoutFeedback style={styles.flexRow}>
                    <Text style={styles.cardHeader}>UCC</Text>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <TableDisplay data={uccData} itemsPerRow={3} />
                </View>
              </>
            )}
          </ScrollView>
        </View>
      </View>
      <Modal
        visible={generateDocModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setGenerateDocModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>
            {`Generate Lending Document for ${applicationId}?`}
          </Text>
          {lendingDocStatus === "Sent to Applicant" && (
            <Text style={{ color: "red" }}>
              Warning: You have already sent the document. Generating a new file
              will interrupt the signing process and unsend the previous file.
            </Text>
          )}
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => onGenerateDocPress()}
            >
              <Text style={styles.modalButtonText}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.modalNoButton}
              onPress={() => setGenerateDocModal(false)}
            >
              <Text style={styles.modalButtonText}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default ClosingDocumentsTab;
