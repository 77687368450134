import { Text, useStyleSheet, Spinner, Divider } from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Dimensions, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { listAllBusinessOwners } from "../../services/api";
import { useStore } from "../../stores";
import { PersonalDetails } from "./PersonalDetails";
import { themedStyles } from "./themedStyles";

const BusinessOwnerSubSection: React.FC<any> = ({ ownerData, title }) => {
  const styles = useStyleSheet(themedStyles);
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <View>
      <Text
        onPress={() => setIsCollapsed(!isCollapsed)}
        style={styles.subsectionHeader}
      >
        {isCollapsed ? "▽" : "△"} {title}
      </Text>
      <Collapsible collapsed={isCollapsed}>
        <PersonalDetails user={ownerData} noHeader />
      </Collapsible>
    </View>
  );
};

export const OtherBusinessOwnersDetails: React.FC<any> = ({
  business,
  loan,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const { authStore } = useStore();
  const LoadingIndicator = (props: any) => <Spinner />;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [businessOwnersData, setBusinessOwnersData]: any = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!business.id) return;
      setLoading(true);
      const response = await listAllBusinessOwners(
        authStore?.accessToken || "",
        { businessId: business?.id }
      );
      console.log(response);
      if (response.response.entities) {
        setBusinessOwnersData(response.response.entities);
      }
      setLoading(false);
    })();
  }, [business]);

  if (
    !businessOwnersData ||
    !businessOwnersData?.primaryOwner ||
    !authStore?.user?.id ||
    (businessOwnersData?.primaryOwner?.id === authStore?.user?.id &&
      !businessOwnersData?.secondaryOwner?.length)
  )
    return <></>;
  return (
    <View>
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Other Business Owners
        </Text>
      </View>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        {!!loading && <LoadingIndicator />}
        {authStore?.user?.id !== businessOwnersData.primaryOwner?.id &&
          businessOwnersData?.primaryOwner && (
            <BusinessOwnerSubSection
              title="Principal Business Owner"
              ownerData={businessOwnersData.primaryOwner}
            />
          )}
        {businessOwnersData?.secondaryOwner?.length &&
          businessOwnersData?.secondaryOwner.map(
            (owner: any, index: number) => {
              if (owner.id !== authStore.user?.id) {
                return (
                  <BusinessOwnerSubSection
                    key={index}
                    title="Business Partner"
                    ownerData={owner}
                  />
                );
              }
            }
          )}
        )
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
