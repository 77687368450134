import { observable } from "mobx";
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  modelAction,
  prop,
  objectMap,
  ModelCreationData,
} from "mobx-keystone";

import LoanApplication from "../models/LoanApplication";
import * as api from "../services/api";
import Store from "./Store";

type Extra = { [key: string]: any } | null;
type Response = { errors: any; ok: boolean; extra: Extra };

const getError = (error: Error): Response => {
  try {
    return { errors: JSON.parse(error.message), ok: false, extra: null };
  } catch {
    return { errors: { detail: error.message }, ok: false, extra: null };
  }
};

const _success = { errors: { detail: null }, ok: true, extra: null };
const getSuccess = (extra?: Extra): Response =>
  extra ? { ..._success, extra } : _success;

@model("oppzo/LoanStore")
export default class LoanStore extends Model({
  loanApplications: prop(() => objectMap<LoanApplication>()),
}) {
  @observable
  loading = false;

  @modelAction
  createOrUpdateLoanApplication(data: ModelCreationData<LoanApplication>) {
    const id = `${data.id}`;
    if (this.loanApplications.has(id)) {
      this.loanApplications.get(id)!.update(data);
    } else {
      const condition = new LoanApplication(data);
      this.loanApplications.set(id, condition);
      condition.update(data);
    }
  }

  @modelAction
  removeLoanApplication(id: number) {
    this.loanApplications.delete(`${id}`);
  }

  @modelFlow
  addLoanApplication = _async(function* (
    this: LoanStore,
    data: LoanApplication
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<LoanApplication>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.addLoanApplication(token, data)));
    } catch (error: any) {
      console.warn("[DEBUG] error adding condition", error);
      return getError(error);
    }

    this.createOrUpdateLoanApplication(entities);

    this.loading = false;
    return getSuccess({ id: entities.id });
  });

  @modelFlow
  fetchLoanApplications = _async(function* (this: LoanStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let results: ModelCreationData<LoanApplication>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchLoanApplications(token)));
    } catch (error: any) {
      console.warn("[DEBUG] error fetching LoanApplications", error);
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateLoanApplication(data));

    this.loanApplications.forEach((loan) => {
      let flag = false;
      results.forEach((rloan) => {
        if (loan.id === rloan.id) {
          flag = true;
        }
      });
      if (!flag) {
        this.removeLoanApplication(loan.id);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateLoanApplication = _async(function* (
    this: LoanStore,
    id: number,
    data: Partial<LoanApplication>
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    const filesData = {};
    const nonFilesData = {};
    const fileFieldsNames = [
      "balanceSheet",
      "incomeStatement",
      "fiscalYearEndProjections",
      "resumes",
      "financialStatements",
      "businessPlan",
      "companyOrganizationalChart",
      "corporateOrAuthorizingResolution",
      "operatingAgreement",
      "byLaws",
      "signed406tForm",
      "largestActiveContractsFile",
      "driversLicense",
      "cashFlowProjectionsFile",
      "oneYearBudgetProjectionsFile",
      "oneYearContractBacklog",
      "baseContract",
      "signed8821Form",
    ];
    Object.keys(data).forEach((key: string) => {
      //@ts-ignore
      if (data[key] instanceof File) {
        //@ts-ignore
        filesData[key] = data[key];
      } else if (!fileFieldsNames.includes(key)) {
        //@ts-ignore
        nonFilesData[key] = data[key];
      }
    });

    let entities: ModelCreationData<LoanApplication>;
    if (Object.keys(nonFilesData).length > 0) {
      try {
        ({
          response: { entities },
        } = yield* _await(api.updateLoanApplication(token, id, nonFilesData)));
        this.createOrUpdateLoanApplication(entities);
      } catch (error: any) {
        console.warn("[DEBUG] error updating LoanApplication", error);
        return getError(error);
      }
    }
    if (Object.keys(filesData).length > 0) {
      try {
        ({
          response: { entities },
        } = yield* _await(
          api.updateLoanApplicationFiles(token, id, filesData)
        ));
        this.createOrUpdateLoanApplication(entities);
      } catch (error: any) {
        console.warn("[DEBUG] error updating LoanApplication", error);
        return getError(error);
      }
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteLoanApplication = _async(function* (
    this: LoanStore,
    id: number,
    data: object
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let response;
    try {
      response = yield* _await(api.deleteLoanApplication(token, id, data));
    } catch (error: any) {
      console.warn("[DEBUG] error deleting LoanApplication", error);
      return getError(error);
    }

    if (!("errors" in response)) {
      this.removeLoanApplication(id);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  verifyPrequal1 = _async(function* (this: LoanStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<LoanApplication>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.verifyPrequal1(token, id)));
    } catch (error: any) {
      console.warn("[DEBUG] error verifying prequal 1", error);
      return getError(error);
    }

    this.createOrUpdateLoanApplication(entities);

    this.loading = false;
    return getSuccess({ status: entities.status, id: entities.id });
  });

  @modelFlow
  verifyPrequal2 = _async(function* (this: LoanStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<LoanApplication>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.verifyPrequal2(token, id)));
    } catch (error: any) {
      console.warn("[DEBUG] error verifying prequal 2", error);
      return getError(error);
    }

    this.createOrUpdateLoanApplication(entities);

    this.loading = false;
    return getSuccess({ status: entities.status, id: entities.id });
  });

  @modelFlow
  verifyFullApplication = _async(function* (this: LoanStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<LoanApplication>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.verifyFullApplication(token, id)));
    } catch (error: any) {
      console.warn("[DEBUG] error verifying prequal 2", error);
      return getError(error);
    }

    this.createOrUpdateLoanApplication(entities);

    this.loading = false;
    return getSuccess({ status: entities.status, id: entities.id });
  });

  @modelFlow
  verifyApplicationFeePayment = _async(function* (this: LoanStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<LoanApplication>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.verifyApplicationProcessingFeePayment(token, id)));
    } catch (error: any) {
      console.warn(
        "[DEBUG] error verifying application processing fee payment",
        error
      );
      return getError(error);
    }

    this.createOrUpdateLoanApplication(entities);

    this.loading = false;
    return getSuccess({ isPaid: entities.hasPaidApplicationProcessingFee });
  });

  @modelFlow
  getUnsigned8821Form = _async(function* (this: LoanStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<LoanApplication>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.getUnsigned8821Form(token, id)));
    } catch (error: any) {
      console.warn("[DEBUG] error getting 8821 form", error);
      return getError(error);
    }

    this.createOrUpdateLoanApplication(entities);

    this.loading = false;
    return getSuccess({
      unsigned8821Form: entities.unsigned8821Form,
    });
  });
}
