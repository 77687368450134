import {
  Text,
  useStyleSheet,
  Input,
  IndexPath,
  Select,
  Layout,
  SelectItem,
} from "@ui-kitten/components";
import React, { useEffect, useRef } from "react";
import { View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import HoverBubble from "../../components/HoverBubble";
import { businessRoleOptions } from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const SSNStep: React.FC<any> = ({
  formData,
  setFormData,
  onContinue,
  mboId,
  width,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [businessRoleIndex, setBusinessRoleIndex] = React.useState<
    IndexPath | undefined
  >(undefined);

  const [formattedSSNNumber, setFormattedSSNNumber] = React.useState(
    formData.ssnNumber
  );

  useEffect(() => {
    if (businessRoleIndex) {
      setFormData({
        ...formData,
        businessRole: businessRoleOptions[businessRoleIndex.row],
      });
    }
  }, [businessRoleIndex]);

  return (
    <View>
      <View style={styles.applicationFeeContainer}>
        <Text style={styles.inputLabel}>
          SSN
          <HoverBubble
            text={
              <Text style={styles.tooltipTextDark}>
                Please enter your social security number.
              </Text>
            }
            theme="light"
          />
        </Text>
        <TextInputMask
          style={styles.inputMask}
          placeholder="input your SSN"
          autoCorrect={false}
          value={formattedSSNNumber}
          type="custom"
          options={{ mask: "999-99-9999" }}
          onChangeText={(text: string) => {
            if (text.length <= 11) {
              setFormattedSSNNumber(text);
              setFormData({
                ...formData,
                ssnNumber: text.replace(/[^0-9\+]/g, ""),
              });
            }
          }}
          testID="SSNField"
        />
        <Text style={styles.inputLabel}>Business Role</Text>
        <Layout style={styles.input}>
          <Select
            status="primary"
            size={width > 576 ? "large" : "medium"}
            selectedIndex={businessRoleIndex}
            value={formData.businessRole}
            onSelect={(index: IndexPath | IndexPath[]) => {
              if (Array.isArray(index)) {
                index = index[0];
              }
              setBusinessRoleIndex(index);
            }}
          >
            {businessRoleOptions.map((title) => (
              <SelectItem key={title} title={title} />
            ))}
          </Select>
        </Layout>
      </View>
    </View>
  );
};
