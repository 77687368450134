import { Layout, Text, useStyleSheet, Spinner } from "@ui-kitten/components";
import React from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { BasicInfoEditForm } from "./BasicInfoEditForm";
import { PasswordEditForm } from "./PasswordEditForm";
import { themedStyles } from "./themedStyles";

export const LoadingIndicator = (_props: any) => <Spinner status="basic" />;
type Props = object;

const ProfileEdit: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.registerContainer}>
          <View style={styles.register}>
            <Text style={styles.header}>Edit Profile</Text>
            <Text style={styles.subHeader}>
              Here you can change your basic account information and password.
              {"\n"}
              <Text appearance="hint">
                Please keep in mind that a maximum of 2 changes per 18 months
                are allowed (except for password)
              </Text>
            </Text>
            <View>
              <BasicInfoEditForm />
              <PasswordEditForm />
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default ProfileEdit;
