import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  gradientContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  gradientContainerPopup: {
    position: "absolute",
    right: 0,
    top: 0,
    height: 589,
    width: "90%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  gradientContainerMobile: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 589,
    width: "95%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
  },
  divider: {
    minHeight: 1,
    color: "#C5CEE0",
  },
  indent: {
    marginLeft: 20,
  },
  header: {
    fontFamily: "Inter_700Bold",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
    color: "white",
  },
  subHeader: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 32,
    fontSize: 22,
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    marginBottom: 12,
  },
  subSubHeader: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 12,
    fontSize: 12,
    marginVertical: 12,
    flexDirection: "column",
    alignItems: "center",
    color: "#aaaaaa",
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
    marginBottom: 34,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 18,
    fontSize: 12,
    marginBottom: 34,
  },
  question: {
    fontFamily: "Inter_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 40,
  },
  questionButton: {
    flex: 1,
    maxWidth: "49%",
  },
  questionButtonSelected: {
    flex: 1,
    maxWidth: "49%",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
  },
  questionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  buttonTextSelected: {
    fontFamily: "Montserrat_700Bold",
    color: "#101426",
    textTransform: "uppercase",
  },
  buttonText: {
    fontFamily: "Montserrat_700Bold",
    color: "#55AAFF",
    textTransform: "uppercase",
  },
  formQuestionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  fullApplication: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    padding: 100,
    marginVertical: 50,
    marginLeft: "10%",
    marginRight: "20%",
    backgroundColor: "#101426",
  },
  fullApplicationMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    padding: 32,
    marginHorizontal: 36,
    marginVertical: 22,
    width: "80%",
    backgroundColor: "#101426",
  },
  fullApplicationContainer: {
    flex: 0.95,
    marginTop: 114,
  },
  fullApplicationContainerMobile: {
    flex: 1,
    width: "95%",
  },
  nextActionButton: {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: "center",
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
  },
  skipButton: {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: "center",
    border: "none",
    background: "none",
    backgroundColor: "#222B45",
  },
  disabledActionButton: {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: "center",
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "color-basic-600",
    fontSize: 14,
  },
  inputLabel: {
    fontFamily: "Inter_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
    color: "white",
  },
  inputText: {
    color: "white",
    width: "100%",
  },
  input: {
    fontSize: 22,
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#222B45",
  },
  inputEmpty: {
    fontSize: 22,
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "rgba(255, 61, 113, 0.3)",
    borderColor: "rgba(255, 61, 113, 0.3)",
  },
  inputMask: {
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontSize: 14,
    fontFamily: "Montserrat_400Regular",
    color: "white",
    width: "99%",
    padding: 10,
  },
  inputMaskEmpty: {
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "rgba(255, 61, 113, 0.3)",
    borderColor: "rgba(255, 61, 113, 0.3)",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontSize: 14,
    fontFamily: "Montserrat_400Regular",
    color: "white",
    width: "99%",
    padding: 10,
  },
  inputMaskMobile: {
    marginBottom: 10,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontFamily: "Montserrat_400Regular",
    fontSize: 12,
    color: "white",
    padding: 10,
  },
  inputMaskMobileEmpty: {
    marginBottom: 10,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "rgba(255, 61, 113, 0.3)",
    borderColor: "rgba(255, 61, 113, 0.3)",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontFamily: "Montserrat_400Regular",
    fontSize: 12,
    color: "white",
    padding: 10,
  },
  linkedInButton: {
    backgroundColor: "#55AAFF",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
  },
  linkedInButtonText: {
    fontFamily: "Inter_400Regular",
    fontStyle: "normal",
    fontSize: 15,
    lineHeight: 20,
    color: "white",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
    backgroundColor: "#222B45",
  },
  uploadButtonSuccess: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
    backgroundColor: "rgba(0, 224, 150, 0.08)",
  },
  uploadButtonEmpty: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
    backgroundColor: "rgba(255, 61, 113, 0.3)",
  },
  uploadButtonText: {
    fontFamily: "Inter_400Regular",
    fontStyle: "normal",
    fontSize: 15,
    lineHeight: 20,
    color: "#8F9BB3",
  },
  flexRow: { flexDirection: "row" },
  flexColumn: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  fullApplicationStatusContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 50,
  },
  fullApplicationStatusContainerMobile: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    width: "100%",
  },
  fullApplicationLabelContainer: {
    flex: 1,
    textAlign: "center",
    marginBottom: 34,
  },
  fullApplicationLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "#1D84FF",
  },
  fullApplicationSubLabel: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 10,
    lineHeight: 16,
    color: "white",
  },
  discountInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(28, 107, 255, 0.16)",
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginVertical: 20,
    borderRadius: 5,
    marginBottom: 40,
  },
  discountInfoIconContainer: {
    backgroundColor: "white",
    padding: 7,
    borderRadius: 5,
  },
  discountInfoIcon: {
    width: 32,
    height: 32,
    fill: "#1C6BFF",
  },
  discountInfoText: {
    fontFamily: "Inter_500Medium",
    lineHeight: 24,
    fontSize: 18,
    fontColor: "rgb(14, 61, 183)",
    marginLeft: 10,
  },
  gap: {
    height: 1,
    width: 10,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    width: "200%",
    marginLeft: "-50%",
    backgroundColor: "#101426",
    padding: 20,
  },
  tooltipTextDark: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "black",
    wordBreak: "break",
  },
  fullApplicationDoneMessage: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 20,
    marginHorizontal: "auto",
    color: "white",
    lineHeight: 24,
    marginBottom: 15,
    alignText: "center",
    textAlign: "center",
  },
  existingLenderHeader: {
    fontFamily: "Montserrat_500Normal",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
  },
  existingLenderText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 13,
  },
  addLenderButton: {
    margin: 15,
    padding: 1,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    textTransform: "uppercase",
    borderRadius: 5,
    width: 185,
  },
  addLenderButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    background: "#101426",
    borderRadius: 5,
    paddingTop: 1,
    paddingBottom: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  gradientText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    margin: 0,
    padding: 0,
    color: "transparent",
    backgroundClip: "text",
  },
  purpleGradientBackgroundContainer: {
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    padding: 1,
    marginVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  existingLenderButton: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#101426",
    border: "none",
    borderRadius: 0,
  },
  flexEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    borderBottom: "1px solid #8F9BB3",
  },
  modalHeaderText: {
    lineHeight: 32,
    fontSize: 22,
    fontFamily: "Montserrat_400Regular",
  },
  lenderInformationContainer: {
    padding: 20,
    borderRadius: 5,
    width: 1000,
    background: "#101426",
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
});
