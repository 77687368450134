import { Text, useStyleSheet, Icon, Button } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";

import { themedStyles } from "./themedStyles";
import { Dimensions } from "react-native";

const StepButton: React.FC<any> = ({
  label,
  number,
  stepsDone,
  currentStep,
  onPress,
  clickable = true,
}) => {
  let { width, height } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const isDone = stepsDone[number];
  const status = (() => {
    if (currentStep === number) return "info";
    if (isDone) return "info";
    if (currentStep <= number) return "basic";
    return "danger";
  })();

  const DoneIcon = (props: any) => <Icon {...props} name="checkmark-outline" />;

  return (
    <View
      style={{
        ...styles.flexColumn,
        ...styles.padSm,
      }}
    >
      <Button
        size="small"
        appearance={isDone ? "filled" : "outline"}
        status={status}
        style={
          width > 768
            ? {
                ...styles.circleButton,
                ...(currentStep === number ? { borderColor: "#26DDF1" } : {}),
                ...(isDone
                  ? {
                      background:
                        "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
                    }
                  : {}),
              }
            : {
                ...styles.circleButtonMobile,
                ...(currentStep === number ? { borderColor: "#26DDF1" } : {}),
                ...(isDone
                  ? {
                      background:
                        "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
                    }
                  : {}),
              }
        }
        children={() =>
          !isDone ? (
            <Text
              status={status}
              style={
                width > 768
                  ? {
                      ...styles.circleButtonNumber,
                      ...(currentStep === number
                        ? { color: "#26DDF1" }
                        : status !== "danger" && !isDone
                        ? { color: "#8F9BB3" }
                        : {}),
                    }
                  : {
                      ...styles.circleButtonNumberMobile,
                      ...(currentStep === number
                        ? { color: "#26DDF1" }
                        : status !== "danger" && !isDone
                        ? { color: "#8F9BB3" }
                        : {}),
                    }
              }
            >
              {number}
            </Text>
          ) : (
            DoneIcon(styles.iconSmall)
          )
        }
        onPress={clickable ? onPress : () => {}}
      />
      {width > 768 && (
        <Text
          style={{
            ...styles.circleButtonLabel,
            ...(currentStep === number
              ? { color: "#26DDF1" }
              : status !== "danger"
              ? { color: "#8F9BB3" }
              : {}),
          }}
          status={status}
          onPress={clickable ? onPress : () => {}}
        >
          {label}
        </Text>
      )}
    </View>
  );
};

export default StepButton;
