import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Button,
  Divider,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { changePassword } from "../../services/api";
import { useStore } from "../../stores";
import {
  commonPasswordsList,
  MINIMUM_PASSWORD_LENGTH,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const ProfileSecurity: React.FC<any> = ({ user, setSuccess, theme }) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const LockIcon = (props: any) => <Icon {...props} name="lock-outline" />;

  const { authStore } = useStore();

  const defaultFields = {
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [formData, setFormData] = useState<any>({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  });

  const oldPasswordRef = useRef<Input>(null);
  const newPassword1Ref = useRef<Input>(null);
  const newPassword2Ref = useRef<Input>(null);

  const isPasswordCommon = (password: string) => {
    return !!commonPasswordsList.find((item: string) => item === password);
  };
  const isPasswordNotAcceptable = (password: string) => {
    const specialRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const lettersRegex = /[A-Za-z]/;
    const numbersRegex = /\d/g;
    return !(
      specialRegex.test(password) &&
      lettersRegex.test(password) &&
      numbersRegex.test(password) &&
      password.length >= MINIMUM_PASSWORD_LENGTH
    );
  };

  useEffect(() => {
    formDataReset(authStore.user);
    setSuccess(false);
  }, []);

  const formDataReset = (user: any) => {
    if (user) {
      setFormData({
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  useEffect(() => {
    setErrorMessages({
      ...errorMessages,
      newPassword1: !formData.newPassword1
        ? ""
        : isPasswordCommon(formData.newPassword1)
        ? "This password is too common."
        : formData.newPassword1.length < MINIMUM_PASSWORD_LENGTH
        ? "This password is too short"
        : isPasswordNotAcceptable(formData.newPassword1)
        ? "This password is not acceptable."
        : "",
      newPassword2:
        formData.newPassword2 && formData.newPassword2 !== formData.newPassword1
          ? "Passwords does not match"
          : "",
    });
  }, [formData.newPassword1, formData.newPassword2]);

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorMessages({ ...defaultErrors });
    let response: any = {};
    if (authStore.accessToken && authStore.user) {
      try {
        response = await changePassword(authStore.accessToken, {
          newPassword1: formData.newPassword1,
          newPassword2: formData.newPassword2,
          oldPassword: formData.oldPassword,
        });
        if ("entities" in response.response) {
          setIsEditing(false);
          setSuccess(true);
        } else {
          setErrorMessages(JSON.parse(response.response));
        }
      } catch (err) {
        console.log(err.message);
        setErrorMessages(JSON.parse(err.message));
      }
    }
    setLoading(false);
    formDataReset(authStore.user);
  }, [loading, formData]);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const EditIcon = (props: any) => (
    <Icon {...props} name="edit-outline" fill="#26DDF1" />
  );

  return (
    <View>
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Security
        </Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsEditing(!isEditing);
          }}
        />
      </View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Text>
        {errorMessages?.oldPassword && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.oldPassword}
          </Text>
        )}
      </Text>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        <Input
          ref={oldPasswordRef}
          style={{
            ...(isEditing ? styles.input : styles.inputNotEditing),
            backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
            borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
          }}
          textStyle={{
            ...(isEditing
              ? width > 576
                ? styles.inputText
                : styles.inputTextMobile
              : styles.inputTextNotEditing),
            color: isEditing
              ? theme === "light"
                ? "black"
                : "white"
              : "#8F9BB3",
          }}
          disabled={!isEditing}
          autoCapitalize="none"
          autoCorrect={false}
          value={formData.oldPassword}
          textContentType="password"
          autoCompleteType="password"
          placeholder="Old Password"
          accessoryRight={LockIcon}
          secureTextEntry
          onChangeText={(text) =>
            setFormData({ ...formData, oldPassword: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              newPassword1Ref.current?.focus();
            }
          }}
          onSubmitEditing={() => newPassword1Ref.current?.focus()}
        />
        <Text>
          {errorMessages?.newPassword1 && (
            <Text category="h6" style={styles.error}>
              {errorMessages?.newPassword1}
            </Text>
          )}
        </Text>
        <Input
          ref={newPassword1Ref}
          style={{
            ...(isEditing ? styles.input : styles.inputNotEditing),
            backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
            borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
          }}
          textStyle={{
            ...(isEditing
              ? width > 576
                ? styles.inputText
                : styles.inputTextMobile
              : styles.inputTextNotEditing),
            color: isEditing
              ? theme === "light"
                ? "black"
                : "white"
              : "#8F9BB3",
          }}
          disabled={!isEditing}
          value={formData.newPassword1}
          autoCorrect={false}
          autoCapitalize="none"
          textContentType="newPassword"
          autoCompleteType="password"
          placeholder="New Password"
          accessoryRight={LockIcon}
          secureTextEntry
          onChangeText={(text) =>
            setFormData({ ...formData, newPassword1: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              newPassword2Ref.current?.focus();
            }
          }}
          onSubmitEditing={() => newPassword2Ref.current?.focus()}
        />
        <Text>
          {errorMessages?.newPassword2 && (
            <Text category="h6" style={styles.error}>
              {errorMessages?.newPassword2}
            </Text>
          )}
        </Text>
        <Input
          ref={newPassword2Ref}
          style={{
            ...(isEditing ? styles.input : styles.inputNotEditing),
            backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
            borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
          }}
          textStyle={{
            ...(isEditing
              ? width > 576
                ? styles.inputText
                : styles.inputTextMobile
              : styles.inputTextNotEditing),
            color: isEditing
              ? theme === "light"
                ? "black"
                : "white"
              : "#8F9BB3",
          }}
          disabled={!isEditing}
          value={formData.newPassword2}
          placeholder="New Password (again)"
          autoCorrect={false}
          autoCapitalize="none"
          textContentType="newPassword"
          autoCompleteType="password"
          accessoryRight={LockIcon}
          secureTextEntry
          onChangeText={(text) =>
            setFormData({ ...formData, newPassword2: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              oldPasswordRef.current?.focus();
            }
          }}
          onSubmitEditing={() => oldPasswordRef.current?.focus()}
        />
        {isEditing && (
          <View style={styles.flexRow}>
            <TouchableOpacity
              style={styles.saveButton}
              onPress={() => {
                onSubmitForm();
              }}
            >
              <LinearGradient
                style={styles.saveButtonGradient}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={["#D012B2", "#8A1CDB", "#5327FF"]}
              >
                <Text style={styles.saveButtonText}>Save</Text>
                <Icon
                  style={styles.saveButtonIcon}
                  name="checkmark-circle-2"
                  fill="white"
                />
              </LinearGradient>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                ...styles.cancelButton,
                ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
              }}
              onPress={() => {
                setIsEditing(false);
                formDataReset(authStore.user);
              }}
            >
              <Text
                style={{
                  ...styles.cancelButtonText,
                  ...(theme === "light" ? { color: "#222B45" } : {}),
                }}
              >
                Cancel
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
