import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import { callApi } from "./base";

export const getDocuSignTemplateIds = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.get_template_ids,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const addDocuSignTemplateId = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.add_template_id,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const sendDocuSignDocument = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.send_document,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

// export const getSentDocuSignTemplateId = async (token: string, data: any) =>
//   callApi(
//     config.urls.docusign.get_sent_template_id,
//     token,
//     "POST",
//     { "Content-Type": "application/json" },
//     JSON.stringify(
//       snakeCaseKeys({
//         data,
//       })
//     )
//   )

export const getDocuSignAppKeys = async (token: string) =>
  callApi(config.urls.docusign.get_keys, token, "GET");

export const getDocuSignAuthToken = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.get_auth_token,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const createSigningCeremony = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.create_signing_ceremony,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const createLendingDocSigning = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.create_lending_document_signing,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const createLendingDocCloSigning = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.create_lending_document_clo_signing,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const checkIfDocuSignTemplateOwned = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.check_if_template_is_owned,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const generateClosingDocument = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.generate_closing_document,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const generateLoanTerms = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.generate_loan_terms,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const getCreditFileData = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.get_credit_file_data,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const getClosingDocumentsData = async (token: string) =>
  callApi(config.urls.docusign.get_closing_documents_data, token, "GET");

export const setDocumentAsSigned = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.set_document_as_signed,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const saveSignedDocument = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.save_signed_document,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const retrieveSignedDocument = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.retrieve_signed_document,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );

export const setDocumentAsCloSigned = async (token: string, data: any) =>
  callApi(
    config.urls.docusign.set_document_as_clo_signed,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        data,
      })
    )
  );
