import { Text, useStyleSheet, Button, Icon } from "@ui-kitten/components";
import React, { useState } from "react";
import { View } from "react-native";

import { stateSvgGeometry } from "./../../utils/constants";
import { themedStyles } from "./themedStyles";

const Markets: React.FC<any> = ({ data }) => {
  const styles = useStyleSheet(themedStyles);
  const rowsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);

  const LeftArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-back-outline" />
  );
  const RightArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-forward-outline" />
  );

  const formattedStateLoanAmount = (amount: number) => {
    if (amount > 10 ** 9) return "$" + Math.floor(amount / 10 ** 9) + "B";
    if (amount > 10 ** 6) return "$" + Math.floor(amount / 10 ** 6) + "M";
    return "$" + Math.floor(amount / 10 ** 3) + "K";
  };

  const getStateColor = (code: any) => {
    const state = data.filter((state: any) => state.code === code)[0] || {};
    if (!state) return "#E4E9F2";

    const idx = data
      .map((st: any, idx: number) => idx)
      .filter((idx: number) => data[idx].code === code)[0];
    const ratio = (data.length - idx) / data.length;

    if (ratio > 0.03) return `rgba(80, 20, 154, ${ratio})`;
    return "#E4E9F2";
  };

  return (
    <View>
      <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
        <Text style={styles.cardTitleText}>Markets</Text>
      </View>
      <View style={styles.mapContainer}>
        <svg
          width="270"
          height="178"
          viewBox="0 0 270 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {Object.keys(stateSvgGeometry).map((k, idx: number) => (
            <path
              // @ts-ignore
              d={stateSvgGeometry[k]}
              fill={getStateColor(k)}
              key={`svg-path-${idx}`}
            />
          ))}
        </svg>
      </View>
      <View style={styles.marketsTableContainer}>
        <View style={styles.marketsTable}>
          <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
            <Text style={styles.marketsTableHeaderText}> {"\t"}State</Text>
            <Text
              style={{
                ...styles.marketsTableHeaderText,
                textAlign: "left",
                color: "rgba(66, 82, 110, 0.86)",
              }}
            >
              Distribution
            </Text>
          </View>
          {data
            .slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
            .map((state: any, idx: number) => (
              <View
                key={`table-row-${idx}`}
                style={{
                  ...styles.flexRow,
                  justifyContent: "space-between",
                  // @ts-ignore
                  borderTop: idx ? "1px solid #E0E0E0" : "none",
                }}
              >
                <View style={{ ...styles.flexRow, flex: 1 }}>
                  <View
                    style={{
                      ...styles.colorCircle,
                      backgroundColor: getStateColor(state.code),
                    }}
                  />
                  <Text style={styles.marketsTableText}>{state.state}</Text>
                </View>
                <Text
                  style={{
                    ...styles.marketsTableText,
                    color: "rgba(66, 82, 110, 0.86)",
                    textAlign: "left",
                  }}
                >
                  {formattedStateLoanAmount(state.amount)} - {state.loans} loan
                  {state.loans === 1 ? "" : "s"}
                </Text>
              </View>
            ))}
        </View>
      </View>
      <View style={{ ...styles.flexRow, justifyContent: "flex-end" }}>
        <Text
          style={{
            ...styles.agenciesTableText,
            textAlign: "right",
            paddingHorizontal: 10,
          }}
        >
          {currentPage * rowsPerPage + 1}-
          {Math.min((currentPage + 1) * rowsPerPage, data.length)} of{" "}
          {data.length}
        </Text>
        <Button
          appearance="ghost"
          status="basic"
          accessoryRight={LeftArrowIcon}
          onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
        />
        <Button
          appearance="ghost"
          status="basic"
          accessoryRight={RightArrowIcon}
          onPress={() =>
            setCurrentPage(
              Math.min(
                Math.ceil(data.length / rowsPerPage) - 1,
                currentPage + 1
              )
            )
          }
        />
      </View>
    </View>
  );
};

export default Markets;
