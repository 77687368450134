import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  CheckBox,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";

import { openLinkInNewTab } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const ConsentPageStep: React.FC<{
  goToNextStep: Function;
}> = ({ goToNextStep }) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );

  const defaultConsent = {
    consumerCredit: false,
    commercialCredit: false,
  };
  const [hasConsent, setHasConsent] = useState(defaultConsent);

  const disabled = !hasConsent.consumerCredit;

  return (
    <View>
      <Text style={styles.consentTitle}>
        By checking the boxes below, you agree that:
      </Text>

      <View>
        <CheckBox
          style={styles.checkbox}
          checked={hasConsent.consumerCredit}
          onChange={() =>
            setHasConsent({
              ...hasConsent,
              consumerCredit: !hasConsent.consumerCredit,
            })
          }
          children={() => (
            <Text
              style={
                width > 576
                  ? { ...styles.inputText, paddingLeft: 10 }
                  : { ...styles.inputTextMobile, paddingLeft: 10 }
              }
            >
              You received and read the{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() => openLinkInNewTab("/compliance/consumer-credit")}
              >
                <Text style={styles.link}>
                  Consumer Credit Report Authorization
                </Text>
              </TouchableOpacity>{" "}
              and you agree to all the terms of the Consumer Credit Report
              Authorization;
            </Text>
          )}
        />

        <Button
          disabled={disabled}
          style={{
            ...(disabled
              ? styles.disabledActionButton
              : styles.nextActionButton),
            marginBottom: 70,
          }}
          status="basic"
          accessoryRight={ArrowIcon}
          appearance="outline"
          onPress={() => {
            goToNextStep(true);
          }}
          children={() => (
            <Text
              style={
                disabled
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Continue
            </Text>
          )}
        />
      </View>
    </View>
  );
};
