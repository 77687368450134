import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "50%",
    height: "85%",
    backgroundColor: "white",
    paddingVertical: 16,
    borderRadius: 10,
    flexDirection: "column",
    alignItems: "stretch",
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
  },
  modalContainerExpanded: {
    width: "98%",
    height: "98%",
    backgroundColor: "white",
    paddingVertical: 16,
    borderRadius: 10,
    flexDirection: "column",
    alignItems: "stretch",
  },
  modalHeaderText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalSubheaderText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 16,
    lineHeight: 24,
  },
  modalText: {
    color: "black",
    fontFamily: "Montserrat_400Regular",
    fontSize: 18,
    lineHeight: 24,
  },
  modalTextSB: {
    color: "#42526E",
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalHeaderRow: {
    marginTop: 3,
    marginBottom: 32,
    marginHorizontal: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  modalIconRow: {
    flexDirection: "row",
    justifyContent: "center",
  },
  icon: {
    width: 18,
    height: 18,
    marginHorizontal: 8,
  },
  iconInside: {
    width: 18,
    height: 18,
    marginVertical: 2,
    marginHorizontal: 2,
  },
  iconLarge: {
    width: 22,
    height: 22,
    marginHorizontal: 8,
  },
  iconLarger: {
    width: 28,
    height: 28,
    marginHorizontal: 8,
    marginVertical: "auto",
  },
  checkedBox: {
    width: 22,
    height: 22,
    marginHorizontal: 8,
    borderRadius: 4,
  },
  uncheckedBox: {
    width: 22,
    height: 22,
    marginHorizontal: 8,
    marginVertical: "auto",
    backgroundColor: "#8F9BB329",
    borderColor: "#C5CEE0",
    border: 1,
    borderRadius: 4,
  },
  modalRow: {
    marginVertical: 3,
    marginHorizontal: 28,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  modalFooterRow: {
    marginVertical: 3,
    marginHorizontal: 16,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  divider: {
    backgroundColor: "#E0E0E0",
    width: "100%",
    height: 2,
    marginVertical: 20,
  },
  listDivider: {
    backgroundColor: "#E0E0E0",
    width: "100%",
    height: 2,
  },
  input: {
    marginHorizontal: 12,
    width: "auto",
    backgroundColor: "white",
    border: "none",
  },
  searchInput: {
    marginHorizontal: 12,
    width: "auto",
    backgroundColor: "#F7F9FC",
    border: "none",
  },
  modalButton: {
    border: "none",
    textTransform: "uppercase",
    marginHorizontal: 10,
    padding: 4,
  },
  modalDocTypeModal: {
    zIndex: 100,
    position: "absolute",
    width: "100%",
    height: 256,
    top: 210,
  },
  modalDocTypeList: {
    marginHorizontal: 12,
    backgroundColor: "white",
  },
  modalDocTypeListItem: {
    padding: 8,
    backgroundColor: "white",
  },
  modalCategoryTypeModal: {
    zIndex: 100,
    position: "absolute",
    width: "100%",
    height: 200,
    top: 210,
  },
  uploadButton: {
    padding: 12,
    backgroundColor: "#white",
    borderWidth: 0,
    marginHorizontal: 5,
    marginVertical: 0,
  },
  uploadButtonText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    marginRight: 12,
  },
  documentNameInput: {
    marginHorizontal: 2,
    width: "auto",
    border: "none",
    fontFamily: "Montserrat_500Normal",
  },
});
