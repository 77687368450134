import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import ESign from "../../containers/ESign";
import EmailVerification from "../../containers/EmailVerification";
import LogIn from "../../containers/LogIn";
import PrivacyPolicy from "../../containers/PrivacyPolicy";
import Register from "../../containers/Register";
import ResetPassword from "../../containers/ResetPassword";
import ConfirmResetPassword from "../../containers/ResetPassword/ConfirmResetPassword";
import TermsOfService from "../../containers/TermsOfService";
import MBOReminder from "../../containers/MBOReminder";

export type AuthStackParamList = {
  "log-in": undefined;
  register: undefined;
  "privacy-policy": undefined;
  "terms-of-service": undefined;
  "e-sign": undefined;
  "email-verification": undefined;
  "password-reset": undefined;
  "password-reset-confirm": undefined;
  "mbo-reminder": undefined;
};

export type AuthStackNavigation = StackNavigationProp<AuthStackParamList>;

type Props = object;

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack: React.FC<Props> = (props) => {
  return (
    <Stack.Navigator initialRouteName="register" headerMode="none">
      <Stack.Screen
        name="log-in"
        options={{ title: "OppZo | Log In" }}
        component={LogIn}
      />
      <Stack.Screen
        name="register"
        options={{ title: "OppZo | Register" }}
        component={Register}
      />
      <Stack.Screen
        name="email-verification"
        options={{ title: "OppZo | Verify Email" }}
        component={EmailVerification}
      />
      <Stack.Screen
        name="password-reset"
        options={{ title: "OppZo | Password Reset" }}
        component={ResetPassword}
      />
      <Stack.Screen
        name="password-reset-confirm"
        options={{ title: "OppZo | Password Reset Confirm" }}
        component={ConfirmResetPassword}
      />
      <Stack.Screen
        name="privacy-policy"
        options={{ title: "OppZo | Privacy Policy" }}
        component={PrivacyPolicy}
      />
      <Stack.Screen
        name="terms-of-service"
        options={{ title: "OppZo | Terms of Use" }}
        component={TermsOfService}
      />
      <Stack.Screen
        name="e-sign"
        options={{ title: "OppZo | E-Sign" }}
        component={ESign}
      />
      <Stack.Screen
        name="mbo-reminder"
        options={{ title: "OppZo | Send Reminder" }}
        component={MBOReminder}
      />
    </Stack.Navigator>
  );
};

export default AuthStack;
