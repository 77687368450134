import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Datepicker,
  CalendarViewModes,
  Layout,
  Select,
  IndexPath,
  SelectItem,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import { useStore } from "../../stores";
import MBOStore from "../../stores/MBOStore";
import { businessRoleOptions } from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const SummaryBusinessRole: React.FC<any> = ({
  user,
  mbo,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  setSuccess,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore, mboStore } = useStore();

  const defaultFields = {
    ssnNumber: "",
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [localFormData, setLocalFormData] = useState<any>({ ...defaultFields });

  useEffect(() => {
    formDataReset();
    setSuccess(false);
  }, []);

  const formDataReset = () => {
    setLocalFormData({
      businessRole: formData.businessRole,
    });
  };

  const SSNRef = useRef<Input>(null);
  const [businessRoleIndex, setBusinessRoleIndex] = React.useState<
    IndexPath | undefined
  >(undefined);

  useEffect(() => {
    if (businessRoleIndex) {
      setLocalFormData({
        ...formData,
        businessRole: businessRoleOptions[businessRoleIndex.row],
      });
    }
  }, [businessRoleIndex]);

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorMessages({ ...defaultErrors });
    setFormData({
      ...formData,
      ...localFormData,
    });
    const result = await authStore.updateBasicAndBusinessInformation({
      ...formData,
      ...localFormData,
    });
    if (!result?.ok) {
      console.log(result?.errors);
      setErrorMessages(result?.errors);
    } else {
      setIsEdit(false);
      setSuccess(true);
    }
    setLoading(false);
  }, [loading, localFormData]);

  return (
    <View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Text>
        {errorMessages?.ssnNumber && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.ssnNumber}
          </Text>
        )}
      </Text>
      <Layout style={styles.input}>
        <Select
          disabled={!isEdit}
          status={"primary"}
          size={width > 576 ? "medium" : "small"}
          selectedIndex={businessRoleIndex}
          value={localFormData.businessRole}
          onSelect={(index: IndexPath | IndexPath[]) => {
            if (Array.isArray(index)) {
              index = index[0];
            }
            setBusinessRoleIndex(index);
          }}
        >
          {businessRoleOptions.map((title) => (
            <SelectItem key={title} title={title} />
          ))}
        </Select>
      </Layout>
      {isEdit && (
        <View style={styles.flexRow}>
          <TouchableOpacity
            style={styles.saveButton}
            onPress={() => {
              onSubmitForm();
            }}
          >
            <LinearGradient
              style={styles.saveButtonGradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={["#D012B2", "#8A1CDB", "#5327FF"]}
            >
              <Text style={styles.saveButtonText}>Save</Text>
              <Icon
                style={styles.saveButtonIcon}
                name="checkmark-circle-2"
                fill="white"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.cancelButton,
              ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
            }}
            onPress={() => {
              setIsEdit(false);
              formDataReset();
            }}
          >
            <Text
              style={{
                ...styles.cancelButtonText,
                ...(theme === "light" ? { color: "#222B45" } : {}),
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
