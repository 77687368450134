import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import DocumentRequestMessage from "../../models/DocumentRequestMessage";
import { callApi } from "./base";

export const addDocumentRequestMessage = async (
  token: string,
  data: Partial<DocumentRequestMessage>
) =>
  callApi(
    config.urls.documentRequests,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data.$ || {}))
  );

export const fetchDocumentRequestMessages = async (token: string) =>
  callApi(config.urls.documentRequests, token, "GET");

export const fetchDocumentRequestMessagesForLoanApplication = async (
  token: string,
  id: string
) =>
  callApi(
    `${config.urls.loanDocumentRequests}?application_id=${id}`,
    token,
    "GET"
  );

export const fetchDocumentRequestMessagesForUser = async (token: string) =>
  callApi(`${config.urls.userDocumentRequests}`, token, "GET");

export const fetchDocumentRequestMessageAttachment = async (
  token: string,
  id: string
) =>
  callApi(
    `${config.urls.documentRequestsAttachments}?attachment_id=${id}`,
    token,
    "GET"
  );

export const updateDocumentRequestMessage = async (
  token: string,
  id: number,
  data: Partial<DocumentRequestMessage>
) =>
  callApi(
    `${config.urls.documentRequests}${id}/`,
    token,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );
