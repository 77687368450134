import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
  Select,
  SelectItem,
  IndexPath,
  Tooltip,
} from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";

type Props = object;

const sideColorBar = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 43 882"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7215_49100)">
      <rect
        opacity="0.8"
        x="501.045"
        y="-254.607"
        width="1165.75"
        height="838.048"
        transform="rotate(75 501.045 -254.607)"
        fill="url(#paint0_linear_7215_49100)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_7215_49100"
        x1="501.045"
        y1="294.868"
        x2="1666.82"
        y2="297.955"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D012B2" />
        <stop offset="0.232001" stop-color="#8A1CDB" />
        <stop offset="0.487214" stop-color="#5327FF" />
        <stop offset="0.758052" stop-color="#1D84FF" />
        <stop offset="0.990348" stop-color="#26DDF1" />
      </linearGradient>
      <clipPath id="clip0_7215_49100">
        <rect width="43" height="882" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const horizontalBar = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1369 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7215_48988)">
      <g filter="url(#filter0_f_7215_48988)">
        <path
          d="M-53 -528H1600V556H-53L-53 -528Z"
          fill="url(#paint0_linear_7215_48988)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_7215_48988"
        x="-153"
        y="-628"
        width="1853"
        height="1284"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="50"
          result="effect1_foregroundBlur_7215_48988"
        />
      </filter>
      <linearGradient
        id="paint0_linear_7215_48988"
        x1="-53"
        y1="182.736"
        x2="1600.03"
        y2="187.535"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D012B2" />
        <stop offset="0.232001" stop-color="#8A1CDB" />
        <stop offset="0.487214" stop-color="#5327FF" />
        <stop offset="0.758052" stop-color="#1D84FF" />
        <stop offset="0.990348" stop-color="#26DDF1" />
      </linearGradient>
      <clipPath id="clip0_7215_48988">
        <rect width="1369" height="604" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const guidelines = [
  {
    guideline: "Provide accurate and complete information: ",
    details:
      "When submitting loan applications or referring clients to OppZo, brokers should ensure that all information provided is accurate, complete, and up-to-date. This includes client information, financial data, and any other relevant details.",
  },
  {
    guideline: "Comply with all laws and regulations: ",
    details:
      "Brokers should comply with all applicable laws and regulations related to the financial industry, including those related to lending, privacy, and data security.",
  },
  {
    guideline: "Act in the client's best interest: ",
    details:
      "Brokers should act in the best interest of their clients, providing guidance and advice that is tailored to their specific needs and circumstances.",
  },
  {
    guideline: "Maintain a professional image: ",
    details:
      "Brokers should maintain a professional image when working with OppZo, including professional attire and behavior, timely communication, and a high level of customer service.",
  },
  {
    guideline: "Avoid conflicts of interest: ",
    details:
      "Brokers should avoid any conflicts of interest that could impact their ability to provide unbiased advice or recommendations to clients.",
  },
  {
    guideline: "Follow OppZo's policies and procedures: ",
    details:
      "Brokers should follow OppZo's policies and procedures related to loan applications, referrals, and any other relevant processes.",
  },
];

const ReferralPartnerGuidelines: React.FC<Props> = (props) => {
  return (
    <div className="h-full overflow-y-auto bg-basic-1100">
      <div className="absolute md:left-[43px] left-[13px] -translate-x-full top-0">
        {sideColorBar}
      </div>
      <Header />
      <div className="text-white h-full ml-[13px] md:ml-[43px] pt-10 md:pl-10 pl-5">
        <h1 className="mb-5 md:mb-20 md:mt-10 text-[1.5rem] md:text-[3rem] font-extrabold tracking-widest text-center leading-[3rem]">
          OppZo Guidelines
        </h1>
        <div className="">{horizontalBar}</div>
        <div className="bg-basic-900 md:py-16 py-8 md:px-[8.9375rem] px-7 mt-10 mr-5 md:mr-20 text-h6">
          <ul className="list-disc">
            {guidelines.map(({ guideline, details }) => (
              <>
                <li>
                  <strong>{guideline}</strong>
                  {details}
                </li>
                <br />
              </>
            ))}
          </ul>
          <br />
          <p className="text-h6 mt-2">
            By following these guidelines, brokers can help ensure a positive
            and productive working relationship with Oppzo, while also providing
            the best possible service to their clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReferralPartnerGuidelines;
