import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Inter_700Bold",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Inter_500Medium",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 70,
  },
  eSign: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
  },
  eSignContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
  },
});
