import {
  Text,
  useStyleSheet,
  Input,
  Button,
  Icon,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import * as DocumentPicker from "expo-document-picker";

import { uploadDocument, formatNumberWithCommas } from "../../utils/index";
import {
  MAX_INTEGER_VALUE,
  contractPricingTypeOptions,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";

const MAX_FILE_SIZE = 10;

export const ContractorInformationStep: React.FC<any> = ({
  formData,
  setFormData,
  errorMessages,
}) => {
  const styles = useStyleSheet(themedStyles);

  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;

  const dunsNumberRef = useRef<Input>(null);
  const ueiNumberRef = useRef<Input>(null);
  const contractNumberRef = useRef<Input>(null);
  const contractAmountRef = useRef<Input>(null);
  const unbilledContractAmountRef = useRef<Input>(null);

  const [fileSizeError, setFileSizeError] = useState("");

  const [
    selectedContractPricingTypeIndex,
    setSelectedContractPricingTypeIndex,
  ] = useState<any>(null);

  useEffect(() => {
    setFormData({
      ...formData,
      contractPricingType:
        contractPricingTypeOptions[selectedContractPricingTypeIndex?.row] || "",
    });
  }, [selectedContractPricingTypeIndex]);

  useEffect(() => {
    if (formData.contractPricingType) {
      const index = contractPricingTypeOptions.findIndex(
        (type: string) => type === formData.contractPricingType
      );
      setSelectedContractPricingTypeIndex(new IndexPath(index));
    }
  }, []);

  function getUploadButtonStyle() {
    if (fileSizeError) {
      return styles.uploadButtonError;
    }

    return formData.baseContract
      ? styles.uploadButtonSuccess
      : styles.uploadButton;
  }

  function getUploadButtonStatus() {
    if (fileSizeError) {
      return "danger";
    }

    return formData.baseContract ? "success" : "basic";
  }

  function resetBaseContractFormData() {
    setFormData({
      ...formData,
      baseContract: null,
    });
  }

  return (
    <View>
      <Text style={styles.inputLabel}>What is your CAGE code?</Text>
      <Text>
        {errorMessages?.cageCode && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.cageCode}
          </Text>
        )}
      </Text>
      <Input
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Input CAGE Number"
        autoCapitalize="none"
        autoCorrect={false}
        maxLength={5}
        value={formData.cageCode}
        onChangeText={(text) => setFormData({ ...formData, cageCode: text })}
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            ueiNumberRef.current?.focus();
          }
        }}
        onSubmitEditing={() => ueiNumberRef.current?.focus()}
        returnKeyType="next"
        testID="cageCodeField"
      />

      <Text style={styles.inputLabel}>What is your UEI number?</Text>
      <Text>
        {errorMessages?.ueiNumber && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.ueiNumber}
          </Text>
        )}
      </Text>
      <Input
        ref={ueiNumberRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Input UEI Number"
        autoCapitalize="none"
        autoCorrect={false}
        maxLength={12}
        value={formData.ueiNumber}
        onChangeText={(text) => setFormData({ ...formData, ueiNumber: text })}
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            dunsNumberRef.current?.focus();
          }
        }}
        onSubmitEditing={() => dunsNumberRef.current?.focus()}
        returnKeyType="next"
        testID="ueiNumberField"
      />

      <Text style={styles.inputLabel}>What is your DUNS number?</Text>
      <Text>
        {errorMessages?.dunsNumber && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.dunsNumber}
          </Text>
        )}
      </Text>
      <Input
        ref={dunsNumberRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Input DUNS Number"
        autoCapitalize="none"
        autoCorrect={false}
        maxLength={9}
        value={formData.dunsNumber}
        onChangeText={(text) =>
          setFormData({
            ...formData,
            dunsNumber: Number.isNaN(Number(text)) ? formData.dunsNumber : text,
          })
        }
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            contractNumberRef.current?.focus();
          }
        }}
        onSubmitEditing={() => contractNumberRef.current?.focus()}
        returnKeyType="next"
        testID="dunsNumberField"
        keyboardType="numeric"
      />

      <Text style={styles.inputLabel}>What is your contract number?</Text>
      <Text>
        {errorMessages?.contractNumber && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.contractNumber}
          </Text>
        )}
      </Text>
      <Input
        ref={contractNumberRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Input Contract Number"
        autoCapitalize="none"
        autoCorrect={false}
        value={formData.contractNumber.join(", ")}
        onChangeText={(text) =>
          setFormData({
            ...formData,
            contractNumber: text.split(",").map((number) => number.trim()),
          })
        }
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            contractAmountRef.current?.focus();
          }
        }}
        onSubmitEditing={() => contractAmountRef.current?.focus()}
        returnKeyType="next"
        testID="contractNumberField"
      />
      <Text style={styles.inputLabel}>What is your contract pricing type?</Text>
      <Text>
        {errorMessages?.contractPricingType && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.contractPricingType}
          </Text>
        )}
      </Text>
      <Select
        status="primary"
        style={styles.dropdown}
        placeholder="Contract Pricing Type"
        value={formData.contractPricingType}
        selectedIndex={selectedContractPricingTypeIndex}
        onSelect={(index) => setSelectedContractPricingTypeIndex(index)}
      >
        {contractPricingTypeOptions.map((option, idx) => (
          <SelectItem
            title={option}
            key={`${idx}-contract-pricing-type-option`}
          />
        ))}
      </Select>

      <Text style={styles.inputLabel}>
        Base contract with applicable modifications.
      </Text>
      <Text>
        {errorMessages?.baseContract && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.baseContract}
          </Text>
        )}
        {fileSizeError && (
          <Text category="h6" style={styles.error}>
            {fileSizeError}
          </Text>
        )}
      </Text>
      <Button
        style={getUploadButtonStyle()}
        appearance="outline"
        status={getUploadButtonStatus()}
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument(
            (doc: any) => {
              setFormData({
                ...formData,
                baseContract: doc.file,
              });
              setFileSizeError("");
            },
            {
              maxSize: MAX_FILE_SIZE,
              onError: resetBaseContractFormData,
              onSizeError: (doc: DocumentPicker.DocumentResult) => {
                resetBaseContractFormData();
                if (doc.type === "success") {
                  setFileSizeError(
                    `${doc.name} is too large, upload limit: ${MAX_FILE_SIZE}MB.`
                  );
                } else {
                  setFileSizeError(
                    `File too large. Upload limit: ${MAX_FILE_SIZE}MB.`
                  );
                }
              },
            }
          )
        }
        children={() => (
          <Text style={styles.uploadButtonText}>
            {formData.baseContract ? "Change" : "Upload"}
          </Text>
        )}
      />

      {formData.baseContract && (
        <Text category="h6" style={styles.selectedFile}>
          {formData.baseContract.name} is successfully queued for upload
        </Text>
      )}
      <Text style={styles.inputLabel}>What is the contract amount?</Text>
      <Text>
        {errorMessages?.contractAmount && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.contractAmount}
          </Text>
        )}
      </Text>
      <Input
        ref={contractAmountRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Amount"
        keyboardType="decimal-pad"
        autoCapitalize="none"
        autoCorrect={false}
        value={formatNumberWithCommas(formData.contractAmount)}
        onChangeText={(text) => {
          const number = text.replace(/,/g, "");
          setFormData({
            ...formData,
            contractAmount:
              Number.isNaN(Number(number)) ||
              Number(number) >= MAX_INTEGER_VALUE
                ? formData.contractAmount
                : number,
          });
        }}
        returnKeyType="next"
        testID="contractAmountField"
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            unbilledContractAmountRef.current?.focus();
          }
        }}
        onSubmitEditing={() => unbilledContractAmountRef.current?.focus()}
      />
      <Text style={styles.inputLabel}>
        What is the unbilled contract amount?
      </Text>
      <Text>
        {errorMessages?.unbilledContractAmount && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.unbilledContractAmount}
          </Text>
        )}
      </Text>
      <Input
        ref={unbilledContractAmountRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Amount"
        keyboardType="decimal-pad"
        autoCapitalize="none"
        autoCorrect={false}
        value={formatNumberWithCommas(formData.unbilledContractAmount)}
        onChangeText={(text) => {
          const number = text.replace(/,/g, "");
          setFormData({
            ...formData,
            unbilledContractAmount:
              Number.isNaN(Number(number)) ||
              Number(number) >= MAX_INTEGER_VALUE
                ? formData.unbilledContractAmount
                : number,
          });
        }}
        returnKeyType="next"
        testID="unbilledContractAmountField"
      />
    </View>
  );
};
