import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
  Select,
  SelectItem,
  IndexPath,
  Tooltip,
} from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";

type Props = object;

const ReferralPartnerDashboard: React.FC<Props> = (props) => {
  return (
    <div className="h-full">
      <Header theme="dark" />
      <div className="bg-basic-1000 text-white p-10 h-full">
        TODO: Inbound Referral Dashboard #993
      </div>
    </div>
  );
};

export default ReferralPartnerDashboard;
