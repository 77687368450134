import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import LoanApplication from "../../models/LoanApplication";
import { camelCaseToSnakeCase } from "../../utils";
import { callApi, getFullUrl } from "./base";

export const addLoanApplication = async (
  token: string,
  data: Partial<LoanApplication>
) =>
  callApi(
    config.urls.loans.application,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data.$ || {}))
  );

export const fetchLoanApplications = async (token: string) =>
  callApi(config.urls.loans.application, token, "GET");

export const getLoanApplicationDetails = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_details`,
    token,
    "GET"
  );

export const updateLoanApplicationFiles = async (
  token: string,
  id: number,
  data: object
) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) =>
    //@ts-ignore
    formData.append(camelCaseToSnakeCase(key), data[key])
  );

  return callApi(
    `${config.urls.loans.application}${id}/`,
    token,
    "PATCH",
    {},
    formData
  );
};

export const updateLoanApplication = async (
  token: string,
  id: number,
  data: Partial<LoanApplication>
) =>
  callApi(
    `${config.urls.loans.application}${id}/`,
    token,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const deleteLoanApplication = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/`,
    token,
    "DELETE",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const activateLoanApplication = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys({ withdrawalStartDate: null }))
  );

export const verifyPrequal1 = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/verify_prequal_1/`,
    token,
    "POST",
    { "Content-Type": "application/json" }
  );

export const verifyApplicationProcessingFeePayment = async (
  token: string,
  id: number
) =>
  callApi(
    `${config.urls.loans.application}${id}/verify_application_fee_payment/`,
    token,
    "POST",
    { "Content-Type": "application/json" }
  );

export const verifyPrequal2 = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/verify_prequal_2/`,
    token,
    "POST",
    { "Content-Type": "application/json" }
  );

export const verifyFullApplication = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/verify_full_application/`,
    token,
    "POST",
    { "Content-Type": "application/json" }
  );

export const getUnsigned8821Form = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_unsigned8821_form/`,
    token,
    "POST",
    { "Content-Type": "application/json" }
  );

export const stripePayment = async (token: string, id: number, data: object) =>
  callApi(
    `${config.urls.loans.application}${id}/stripe_intent_view/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const getLoanSummary = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_summary/`,
    token,
    "GET"
  );

export const getUnderwriterVerificationSidebarData = async (
  token: string,
  id: number
) =>
  callApi(
    `${config.urls.loans.application}${id}/get_underwriter_verification_sidebar_data/`,
    token,
    "GET"
  );

export const getInvestorVerificationSidebarData = async (
  token: string,
  id: number
) =>
  callApi(
    `${config.urls.loans.application}${id}/get_investor_verification_sidebar_data/`,
    token,
    "GET"
  );

export const getLoanSettingsData = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_settings_data/`,
    token,
    "GET"
  );

export const updateLoanSettingsData = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_loan_settings_data/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const getLoanCollateral = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_collateral/`,
    token,
    "GET"
  );

export const updateLoanCollateral = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_loan_application_collateral/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const confirmUnbilledContractAmount = async (
  token: string,
  id: number
) =>
  callApi(
    `${config.urls.loans.application}${id}/confirm_unbilled_contract_amount/`,
    token,
    "POST"
  );

export const updateLoanBusinessEntity = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_loan_application_business_entity/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const updateLoanSummary = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_loan_application_summary/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const updateAssignedUserForLoan = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_assigned_user_for_loan/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const updateLoanBusinessPrincipalEntity = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_loan_application_business_principal_entity/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const postUnderwriterVerificationSidebarDataMultipartFormData = async (
  token: string,
  id: number,
  formData: FormData
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_underwriter_verification_sidebar_data/`,
    token,
    "POST",
    {},
    formData
  );

export const postUnderwriterVerificationSidebarData = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_underwriter_verification_sidebar_data/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const postInvestorVerificationSidebarData = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_investor_verification_sidebar_data/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const getLoanBusinessEntity = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_business_entity/`,
    token,
    "GET"
  );

export const getLoanRisks = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_risk_flags/`,
    token,
    "GET"
  );

export const getBusinessPrincipal = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_business_principal/`,
    token,
    "GET"
  );

export const getARPaging = async (token: string, id: number) =>
  callApi(`${config.urls.loans.application}${id}/get_ar_paging/`, token, "GET");

export const getFinancials = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_financials/`,
    token,
    "GET"
  );

export const getFundsTracking = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_funds_tracking/`,
    token,
    "GET"
  );

export const getBalanceSheet = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_balancesheet_data/`,
    token,
    "GET"
  );

export const getFinalDecisionData = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_application_final_decision_data/`,
    token,
    "GET"
  );

export const updateFinalDecisionData = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_underwriter_final_decision_data/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const updateCovenantsData = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/update_covenants_data/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const getAmortizationSchedule = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_amortization_schedule_for_loan/`,
    token,
    "GET"
  );

export const uploadDocumentFromUnderwriterPortal = async (
  token: string,
  id: number,
  data: FormData
) =>
  callApi(
    `${config.urls.loans.application}${id}/upload_document_from_underwriter_portal/`,
    token,
    "POST",
    {},
    data
  );

export const getMinimizedLoansList = async (token: string) =>
  callApi(
    `${config.urls.loans.application}get_minimized_loans_list/`,
    token,
    "GET"
  );

export const getLoansListForFinalDecision = async (
  token: string,
  page: number,
  pageSize: number,
  filters?: any
) => {
  // TODO: refactor filter handling so that its more reusable
  let filtersQueries = `&search=${filters.search || ""}&sort=${filters.sort}`;
  if (filters.byStatus)
    filtersQueries += `&short_status=${filters.status.join(",")}`;
  if (filters.byDecisionStatus)
    filtersQueries += `&decision_status=${filters.decisionStatus.join(",")}`;
  if (filters.byLocation)
    filtersQueries += `&business__business_add_state__in=${filters.location.join(
      ","
    )}`;
  if (filters.byType)
    filtersQueries += `&business__business_type__in=${filters.type.join(",")}`;
  return callApi(
    `${config.urls.loans.application}get_loans_list_for_final_decision/?page_size=${pageSize}&page=${page}${filtersQueries}${filters?.additionalQueryString}`,
    token,
    "GET"
  );
};

export const getLoansListForServicingMonitoringDashboard = async (
  token: string,
  page: number,
  pageSize: number,
  filters?: any
) => {
  let filtersQueries = `&search=${filters.search || ""}&sort=${filters.sort}`;

  return callApi(
    `${config.urls.loans.application}get_loans_list_for_servicing_monitoring_dashboard/?page_size=${pageSize}&page=${page}${filtersQueries}`,
    token,
    "GET"
  );
};

export const getUploadedDocumentsForALoan = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_uploaded_documents_for_a_loan/`,
    token,
    "GET"
  );

export const getUploadedDocumentsForALoanCreditFile = async (
  token: string,
  id: number
) =>
  callApi(
    `${config.urls.loans.application}${id}/get_uploaded_documents_for_a_loan_credit_file/`,
    token,
    "GET"
  );

export const getLoanPreviewData = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_loan_preview_data/`,
    token,
    "GET"
  );

export const uploadFileToAPI = async (
  token: string | null,
  id: number,
  file: File,
  name: string
) => {
  if (token) {
    try {
      const data = new FormData();
      data.append(name, file);

      await fetch(getFullUrl(`${config.urls.loans.application}${id}/`), {
        method: "PATCH",
        credentials: "include",
        headers: { Authorization: `Bearer ${token}` },
        body: data,
      });
    } catch (error) {
      console.log(error.message);
    }
  }
};

export const getIncomeStatement = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_incomestatement_data/`,
    token,
    "GET"
  );

export const getExistingLendersData = async (token: string, id: number) =>
  callApi(
    `${config.urls.loans.application}${id}/get_existing_lenders_data/`,
    token,
    "GET"
  );

export const updateExistingLendersData = async (
  token: string,
  loanId: number,
  data: FormData
) =>
  callApi(
    `${config.urls.loans.application}${loanId}/update_existing_lenders_data/`,
    token,
    "POST",
    {},
    data
  );

export const getLoanTapeData = async (token: string) =>
  callApi(config.urls.investor.get_loan_tape_data, token, "GET");

export const getInvestorLoansList = async (token: string) =>
  callApi(config.urls.investor.get_investor_loans_list, token, "GET");

export const getInvestorDashboardData = async (token: string) =>
  callApi(
    `${config.urls.loans.application}get_investor_dashboard_data/`,
    token,
    "GET"
  );

export const getLenderData = async (token: string, loanId: number) =>
  callApi(
    `${config.urls.loans.application}${loanId}/get_lender_data/`,
    token,
    "GET"
  );

export const getUccData = async (token: string, loanId: number) =>
  callApi(
    `${config.urls.loans.application}${loanId}/get_ucc_data/`,
    token,
    "GET"
  );

export const getQuickbooksStatus = async (token: string, loanId: number) =>
  callApi(
    `${config.urls.loans.application}${loanId}/get_quickbooks_status/`,
    token,
    "GET"
  );

export const supportSendEmails = async (token: string, data: object) =>
  callApi(
    config.urls.support_send_emails,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const checkMatchingLenderData = async (token: string, data: object) =>
  callApi(
    config.urls.docusign.check_matching_lender_data,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const sendUpdateDetailsForEntityReportEmail = async (
  token: string,
  id: number
) =>
  callApi(
    `${config.urls.loans.application}${id}/send_update_details_for_entity_report_email/`,
    token,
    "POST",
    { "Content-Type": "application/json" }
  );

export const sendUpdateDetailsForConsumerReportEmail = async (
  token: string,
  id: number,
  data: object
) =>
  callApi(
    `${config.urls.loans.application}${id}/send_update_details_for_consumer_report_email/`,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const uploadGoodStandingCertificate = async (
  token: string,
  id: number,
  formData: FormData
) =>
  callApi(
    `${config.urls.loans.application}${id}/add_good_standing_certificate/`,
    token,
    "POST",
    {},
    formData
  );
