import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },

  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
  },
  tooltipTextDark: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "black",
  },
  infoIcon: {
    width: 20,
    height: 20,
    position: "relative",
    fill: "#8F9BB3",
    paddingHorizontal: 10,
  },
  addLenderButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    background: "#101426",
    borderRadius: 5,
    paddingTop: 1,
    paddingBottom: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  gradientText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    margin: 0,
    padding: 0,
    color: "transparent",
    backgroundClip: "text",
  },
  purpleGradientBackgroundContainer: {
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    padding: 1,
    marginVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  existingLenderButton: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#101426",
    border: "none",
    borderRadius: 0,
  },
  flexEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    borderBottom: "1px solid #8F9BB3",
  },
  modalHeaderText: {
    lineHeight: 32,
    fontSize: 22,
    fontFamily: "Montserrat_400Regular",
  },
  confirmPromptContainer: {
    padding: 20,
    borderRadius: 5,
    width: 750,
    background: "#101426",
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    width: "200%",
    marginLeft: "-50%",
    backgroundColor: "#101426",
    padding: 20,
  },
  inputLabel: {
    fontFamily: "Inter_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
    color: "white",
  },
  nextActionButton: {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: "center",
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
  },
  skipButton: {
    flex: 1,
    marginVertical: 10,
    marginHorizontal: 10,
    textAlign: "center",
    border: "none",
    background: "none",
    backgroundColor: "#222B45",
  },
  flexRow: { flexDirection: "row" },
});
