import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
    paddingLeft: 30,
    paddingTop: 20,
  },
  input: {
    flex: 1,
    fontSize: 22,
    marginVertical: 11,
  },
  inputText: {
    color: "white",
    width: "100%",
  },
  LogInButton: {
    marginBottom: 25,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingLeft: 20,
  },
  cardsContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  card: {
    background: "color-basic-100",
    padding: 10,
    borderRadius: 10,
    width: "90%",
    margin: 30,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  headerCell: {
    flex: 1,
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "left",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardSubHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    margin: 20,
    marginRight: 5,
  },
  cardSection: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    margin: 10,
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  dataRow: {
    flexDirection: "row",
    margin: 10,
  },
  subMenu: {
    flexDirection: "row",
    padding: 10,
    margin: 10,
  },
  pullDataBtn: {
    margin: 0,
    padding: 0,
    height: 10,
    background: "#FFF",
  },
  pullDataLink: {
    margin: 0,
    padding: 0,
  },
  disabledPullDataLink: {
    backgroundColor: "transparent",
  },
  pullDataBtnText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    backgroundClip: "text",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    color: "transparent",
  },
  pullDataLinkText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "color-primary-default",
  },
  disabledPullDataLinkText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "color-basic-500",
  },
  gradientButton: {
    height: 10,
    margin: 3,
    padding: 3,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    marginVertical: "auto",
    zIndex: 100,
  },
  cellInput: {
    marginVertical: 10,
    width: "90%",
  },
  icon: {
    width: 16,
    height: 16,
    paddingHorizontal: 10,
    marginHorizontal: 10,
  },
  chevronButton: {
    width: 32,
    height: 32,
    marginVertical: "auto",
    marginRight: 32,
  },
  adminDashBoard: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginLeft: 50,
    marginRight: 25,
    maxWidth: 1450,
    overflow: "hidden",
  },
  adminDashboardContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    flex: 0.5,
    fontSize: 22,
    margin: 5,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_500Medium",
    lineHeight: 36,
    fontSize: 24,
    color: "black",
    marginBottom: 20,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    margin: 2,
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 250,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#EDF1F7",
  },
  tableHeadersCell: {
    fontFamily: "Montserrat_500Medium",
    fontWeight: "bold",
    lineHeight: 24,
    fontSize: 15,
    padding: 1,
    margin: 1,
    alignSelf: "flex-start",
    justifySelf: "center",
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    background: "white",
    alignItems: "center",
    alignContent: "center",
  },
  tableCell: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  tableCellLast: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
  },
  tableCellText: {
    fontFamily: "Montserrat_500Medium",
    fontWeight: "500",
    lineHeight: 20,
    fontSize: 13,
    padding: 1,
    paddingHorizontal: 10,
    margin: 1,
    alignSelf: "flex-start",
    justifySelf: "center",
  },
  tableContainer: {},
  inputLabel: {
    fontFamily: "Montserrat_700Bold",
    color: "black",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
  },
  ACTIVE: {
    backgroundColor: "color-success-default",
    color: "white",
    fontFamily: "Montserrat_700Bold",
  },
  INACTIVE: {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
  },
  DENIED: {
    backgroundColor: "color-danger-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  PENDING: {
    backgroundColor: "color-warning-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  requestedDocumentsContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "stretch",
  },
  requestedDocumentContent: {
    flex: 5,
    paddingHorizontal: 75,
    paddingVertical: 20,
  },
  activeNotificationCircle: {
    width: 15,
    height: 15,
    margin: 15,
    background: "color-success-default",
    borderRadius: 50,
  },
  pendingNotificationCircle: {
    width: 15,
    height: 15,
    margin: 15,
    background: "color-warning-default",
    borderRadius: 50,
  },
  deniedNotificationCircle: {
    width: 15,
    height: 15,
    margin: 15,
    background: "color-danger-default",
    borderRadius: 50,
  },
  requestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-basic-300",
  },
  selectedRequestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-basic-500",
    backgroundColor: "color-basic-300",
  },
  newRequestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-primary-300",
    backgroundColor: "color-primary-200",
  },
  selectedNewRequestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-primary-500",
    backgroundColor: "color-primary-300",
  },
  uploadedDocumentsText: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 26,
    flex: 1,
    margin: 5,
    marginVertical: 30,
    marginBottom: 50,
  },
  requestDate: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 12,
    flex: 1,
    margin: 5,
  },
  requestBody: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 20,
    fontSize: 15,
    flex: 1,
    margin: 5,
    padding: 20,
  },
  attachment: {
    margin: 10,
    padding: 5,
    paddingVertical: 15,
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  modal: {
    flex: 1,
    width: "70%",
    height: "90%",
  },
  generateDocContainer: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 50,
  },
  requestSectionTitle: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    margin: 10,
  },
  textArea: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 15,
    minHeight: 150,
    margin: 10,
  },
  borderLessButton: {
    border: "none",
    margin: 10,
  },
  borderLessButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "black",
    lineHeight: 16,
    fontSize: 12,
    paddingHorizontal: 15,
  },
  tableCellHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 20,
    fontSize: 15,
    padding: 15,
  },
  tableCellHeaderText: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 20,
    fontSize: 15,
  },
  attachDocumentButton: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 70,
    paddingHorizontal: 40,
    margin: 20,
    borderRadius: 10,
    background: "color-basic-200",
    color: "color-basic-600",
    borderColor: "#E4E9F2",
    borderStyle: "solid",
    borderWidth: 1,
  },
  attachDocumentButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "uppercase",
    color: "color-basic-600",
  },
  modalButton: {
    border: "none",
    textTransform: "uppercase",
    marginHorizontal: 10,
    padding: 4,
  },
  oneText: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
    marginHorizontal: 20,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "100%",
    backgroundColor: "white",
    padding: 50,
  },
  modalText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalYesButton: {
    backgroundColor: "#1D84FF",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalNoButton: {
    backgroundColor: "#222B45",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  circleGradientFill: {
    width: 32,
    height: 32,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    borderRadius: 25,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  circleGrayOutline: {
    width: 32,
    height: 32,
    background: "transparent",
    border: "1px solid #777",
    borderRadius: 25,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fundsTrackingMainText: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "center",
    paddingHorizontal: 5,
  },
  fundsTrackingAlternateText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_500Normal",
    lineHeight: 16,
    fontSize: 12,
    textAlign: "left",
    paddingHorizontal: 5,
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
    wordBreak: "break",
  },
});
