import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Button,
  Divider,
} from "@ui-kitten/components";
import React, { useRef, useState, useCallback } from "react";
import { View, TouchableOpacity, Dimensions } from "react-native";

import { useStore } from "../../stores";
import { LoadingIndicator } from "./index";
import { themedStyles } from "./themedStyles";

export const PasswordEditForm: React.FC<any> = () => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore } = useStore();

  const oldPasswordRef = useRef<Input>(null);
  const passwordRef = useRef<Input>(null);
  const repeatPasswordRef = useRef<Input>(null);

  const defaultFields = {
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
  };

  const [formData, setFormData] = useState({ ...defaultFields });
  const [loading, setLoading] = useState(false);
  const [securedText, setSecuredText] = useState(true);
  const [securedText2, setSecuredText2] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmitForm = useCallback(
    async (data) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setErrorMessage("");
      const result = await authStore.updatePassword(data);
      console.log(result.errors);
      setLoading(false);
      if (!result.ok) {
        setErrorMessage(result.errors["message"]);
      }
    },
    [loading]
  );

  const SaveIcon = (props: any) => <Icon {...props} name="save-outline" />;
  const LockIcon = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText(!securedText)}>
      <Icon {...props} name={securedText ? "lock-outline" : "unlock-outline"} />
    </TouchableOpacity>
  );
  const LockIcon2 = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText2(!securedText2)}>
      <Icon
        {...props}
        name={securedText2 ? "lock-outline" : "unlock-outline"}
      />
    </TouchableOpacity>
  );
  return (
    <View style={styles.sectionForm}>
      <Text style={styles.inputLabel}>Password</Text>
      <Divider style={styles.divider} />
      <View>
        <Text>
          {errorMessage && (
            <Text category="h6" style={styles.error}>
              {errorMessage}
            </Text>
          )}
        </Text>
        <Input style={{ display: "none" }} />
        <Input
          ref={oldPasswordRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Old Password"
          autoCompleteType="password"
          autoCorrect={false}
          value={formData.oldPassword}
          secureTextEntry={securedText}
          accessoryRight={LockIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, oldPassword: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              passwordRef.current?.focus();
            }
          }}
          onSubmitEditing={() => passwordRef.current?.focus()}
          returnKeyType="next"
          testID="PasswordField"
        />
        <Input
          ref={passwordRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="New password (min 8 characters)"
          autoCompleteType="password"
          autoCorrect={false}
          value={formData.newPassword1}
          secureTextEntry={securedText2}
          accessoryRight={LockIcon2}
          onChangeText={(text) =>
            setFormData({ ...formData, newPassword1: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              repeatPasswordRef.current?.focus();
            }
          }}
          onSubmitEditing={() => repeatPasswordRef.current?.focus()}
          returnKeyType="next"
          testID="PasswordField"
        />
        <Input
          ref={repeatPasswordRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Repeat new password"
          autoCompleteType="password"
          autoCorrect={false}
          value={formData.newPassword2}
          secureTextEntry={securedText2}
          accessoryRight={LockIcon2}
          onChangeText={(text) =>
            setFormData({ ...formData, newPassword2: text })
          }
          returnKeyType="go"
          testID="RepeatPasswordField"
        />
      </View>

      <Button
        style={styles.nextActionButton}
        status="primary"
        appearance="outline"
        onPress={() => onSubmitForm(formData)}
        accessoryRight={loading ? LoadingIndicator : SaveIcon}
      >
        Change password
      </Button>
    </View>
  );
};
