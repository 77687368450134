import * as eva from "@eva-design/eva";
import { useLinkTo } from "@react-navigation/native";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  ThemeProvider,
} from "@ui-kitten/components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useForm, useFormState } from "react-hook-form";
import {
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Dimensions,
} from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

type Props = object;

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const LogIn: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const linkTo = useLinkTo();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const passwordRef = useRef<Input>(null);

  const isInvestorLogin = window.location.pathname.split("/")[1] === "investor";
  const isAdminLogin = window.location.pathname.split("/")[1] === "admin";
  const isLoanServicingLogin =
    window.location.pathname.split("/")[1] === "loan-servicing";

  const { authStore } = useStore();

  const { register, setValue, handleSubmit, control } = useForm();
  const { errors } = useFormState({ control });

  const [securedText, setSecuredText] = useState(true);

  const colorBar = require("../../../assets/colorBar.svg");
  const photo = isInvestorLogin
    ? require("../../../assets/InvestorLogInImage.png")
    : isLoanServicingLogin
    ? require("../../../assets/LoanServicerLoginPhoto.png")
    : require("../../../assets/AdminLoginPhoto.png");

  const MailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LockIcon = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText(!securedText)}>
      <Icon {...props} name={securedText ? "lock-outline" : "unlock-outline"} />
    </TouchableOpacity>
  );

  const onSubmit = useCallback(
    async (data) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setErrorMessage("");
      const result = await authStore.logIn(data);
      if (!result.ok) {
        setLoading(false);
        setErrorMessage(result.errors.nonFieldErrors[0]);
      }
    },
    [loading]
  );

  useEffect(() => {
    register("email", {
      required: "E-mail is required",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid E-mail address",
      },
    });
    register("password", { required: "Password is required" });
  }, [register]);

  const onSubmitWrapped = handleSubmit(onSubmit);

  const onSubmitEditingEmail = useCallback(() => {
    passwordRef.current?.focus();
  }, []);

  const { width } = Dimensions.get("window");

  return (
    <ThemeProvider theme={eva.light}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme="light" />
          <View style={styles.LogInContainer}>
            {" "}
            <View
              style={{
                flex: width < 800 ? 1 : 2,
                position: "relative",
              }}
            >
              <svg
                width="43"
                height="832"
                viewBox="0 0 43 832"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_5613_38616)">
                  <rect
                    opacity="0.8"
                    x="462.357"
                    y="-244.24"
                    width="968.443"
                    height="757.941"
                    transform="rotate(75 462.357 -244.24)"
                    fill="url(#paint0_linear_5613_38616)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_5613_38616"
                    x1="462.357"
                    y1="252.712"
                    x2="1430.82"
                    y2="255.067"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#D012B2" />
                    <stop offset="0.232001" stopColor="#8A1CDB" />
                    <stop offset="0.487214" stopColor="#5327FF" />
                    <stop offset="0.758052" stopColor="#1D84FF" />
                    <stop offset="0.990348" stopColor="#26DDF1" />
                  </linearGradient>
                  <clipPath id="clip0_5613_38616">
                    <rect width="43" height="832" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <img
                src={photo}
                alt=""
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: "4rem",
                  ...(Dimensions.get("window").width < 1400
                    ? { right: 0 }
                    : { left: 0 }),
                  maxWidth: 600,
                }}
              />
            </View>
            <View
              style={{
                ...styles.LogIn,
                flex: 3,
                ...(width < 800 ? { paddingHorizontal: 15 } : {}),
              }}
            >
              <Text style={styles.header}>
                {isInvestorLogin
                  ? "Funding Partner Portal"
                  : isLoanServicingLogin
                  ? "Loan Servicer Portal"
                  : "Admin Portal"}
              </Text>

              {!!errorMessage && (
                <Text category="h6" style={styles.error}>
                  {errorMessage}
                </Text>
              )}
              <Input
                style={styles.input}
                placeholder="E-mail"
                keyboardType="email-address"
                autoCapitalize="none"
                autoCompleteType="email"
                textContentType="emailAddress"
                accessoryRight={MailIcon}
                autoCorrect={false}
                onChangeText={(text) => setValue("email", text)}
                onBlur={onSubmitEditingEmail}
                onSubmitEditing={onSubmitEditingEmail}
                returnKeyType="next"
                status={errors.email ? "danger" : "basic"}
                caption={errors.email && errors.email.message}
                testID="emailField"
              />
              <Input
                ref={passwordRef}
                style={styles.input}
                placeholder="Password"
                autoCapitalize="none"
                autoCompleteType="password"
                textContentType="password"
                accessoryRight={LockIcon}
                autoCorrect={false}
                secureTextEntry={securedText}
                onChangeText={(text) => setValue("password", text)}
                onSubmitEditing={onSubmitWrapped}
                returnKeyType="go"
                status={errors.password ? "danger" : "basic"}
                caption={errors.password && errors.password.message}
                testID="passwordField"
              />

              <View
                style={{ flexDirection: "row", justifyContent: "flex-end" }}
              >
                <Text
                  status="info"
                  style={styles.forgotPasswordText}
                  onPress={() => {
                    if (isAdminLogin) linkTo("/admin/password-reset");
                    else if (isInvestorLogin)
                      linkTo("/investor/password-reset");
                    else if (isLoanServicingLogin)
                      linkTo("/loan-servicing/password-reset");
                  }}
                >
                  Forgot password?{"  "}
                </Text>
              </View>
              <Button
                disabled={loading}
                style={styles.logInButton}
                status="basic"
                onPress={onSubmitWrapped}
                accessoryRight={loading ? LoadingIndicator : ArrowIcon}
                children={() => (
                  <Text style={styles.logInButtonText}>sign in{"  "}</Text>
                )}
              />
            </View>
          </View>
        </ScrollView>
      </Layout>
    </ThemeProvider>
  );
};

export default LogIn;
