import {
  Button,
  Text,
  useStyleSheet,
  Divider,
  Icon,
  CheckBox,
} from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useState, useEffect } from "react";
import { Dimensions, View, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import StepButton from "../../components/StepButton/StepButton";
import config from "../../config";
import { updateUserBasicAndBusinessInformation } from "../../services/api";
import { businessRoleOptions } from "../../utils/constants";
import { uploadDocument } from "../../utils/index";
import { ConsentPageStep } from "./ConsentPageStep";
import { DonePage } from "./DonePage";
import { FullAppStep } from "./FullAppStep";
import { SSNStep } from "./SSNStep";
import SummaryStep from "./SummaryStep";
import { TaxReturnAuthorizationStep } from "./TaxReturnAuthorizationStep";
import { themedStyles } from "./themedStyles";

export const MboForm: React.FC<any> = ({
  onContinue,
  loan,
  loanId,
  business,
  businessId,
  currentStep,
  setCurrentStep,
  mbo,
  mboId,
  user,
  updateMboData,
  updateUserData,
}) => {
  const styles = useStyleSheet(themedStyles);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );

  const [userFormData, setUserFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    birthday: user.birthday,
    ssnNumber: user.ssnNumber,
    businessRole: user.businessRole,
    businessName: business.businessName,
    businessAddress: business.businessAddress,
    businessAddStreetLine1: business.businessAddStreetLine1,
    businessAddStreetLine2: business.businessAddStreetLine2,
    businessAddCity: business.businessAddCity,
    businessAddState: business.businessAddState,
    businessAddZipCode: business.businessAddZipCode,
    businessType: business.businessType,
    bypassEditLimit: true,
  });

  const [formData, setFormData] = useState({
    unsigned8821Form: null,
    signed8821Form: null,
    infoStep: 1,
    resumes: mbo.resumes,
    linkedIn: mbo.linkedIn,
    driversLicense: mbo.driversLicense,
    personalFinancialStatements: mbo.personalFinancialStatements,
    legalActions: mbo.legalActions,
  });
  const [stepsDone, setStepsDone] = useState<any>(Array(7).fill(false));
  const [paymentStatus, setPaymentStatus] = useState("unknown");
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const { width } = Dimensions.get("window");

  const [certifyCheck, setCertifyCheck] = useState(false);
  const [farthestStep, setFarthestStep] = useState(0);

  const goToNextStep = (isStepDone = false) => {
    const newStepsDone = [...stepsDone];
    newStepsDone[currentStep] = isStepDone || newStepsDone[currentStep];
    const data = { ...formData };
    data.infoStep = currentStep + 1;
    console.log(data.infoStep);
    if (currentStep === 2) {
      // @ts-ignore
      delete data.signed8821Form;
    }
    if (isStepDone) updateMboData(data);
    if (isStepDone) updateUserData(userFormData);

    setStepsDone(newStepsDone);
    setCurrentStep(currentStep + 1);
  };

  // const steps = ["SSN", "Tax Guard", "Other Information"];
  const steps = ["SSN", "Other Information"];

  useEffect(() => {
    switch (currentStep) {
      case 1:
        if (userFormData.ssnNumber === "" || userFormData.businessRole === "")
          setDisableContinueButton(true);
        else setDisableContinueButton(false);
        break;
      case 2:
        if (!formData.signed8821Form) setDisableContinueButton(true);
        else setDisableContinueButton(false);
        break;
      case 4:
        if (!formData.resumes && !formData.linkedIn)
          setDisableContinueButton(true);
        else setDisableContinueButton(false);
        break;
      case 5:
        if (!certifyCheck) setDisableContinueButton(true);
        else setDisableContinueButton(false);
        break;
      default:
        setDisableContinueButton(false);
        break;
    }
  }, [currentStep, formData, userFormData, paymentStatus, certifyCheck]);

  useEffect(() => {
    if (Object.keys(mbo).indexOf("status") >= 0) {
      setFormData({
        ...formData,
        // unsigned8821Form: mbo.unsigned8821Form,
        // signed8821Form: mbo.signed8821Form,
        resumes: mbo.resumes,
        linkedIn: mbo.linkedIn,
        driversLicense: mbo.driversLicense,
        personalFinancialStatements: mbo.personalFinancialStatements,
        legalActions: mbo.legalActions,
      });
      setUserFormData({
        ...userFormData,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        birthday: user.birthday,
        ssnNumber: user.ssnNumber,
        businessRole: user.businessRole,
        businessName: business.businessName,
        businessAddress: business.businessAddress,
        businessAddStreetLine1: business.businessAddStreetLine1,
        businessAddStreetLine2: business.businessAddStreetLine2,
        businessAddCity: business.businessAddCity,
        businessAddState: business.businessAddState,
        businessAddZipCode: business.businessAddZipCode,
        businessType: business.businessType,
      });
      setCurrentStep(mbo.infoStep);
    }
  }, [mbo]);

  return (
    <>
      {currentStep < 3 && (
        <>
          <Text style={width > 768 ? styles.header : styles.headerMobile}>
            Applicant Due Diligence
          </Text>
          <Text style={width > 768 ? styles.subHeader : styles.subHeaderMobile}>
            We just need a few more pieces of information to determine if you
            are qualified to proceed to the full loan application.
          </Text>
        </>
      )}
      {currentStep === 4 && (
        <>
          <Text style={width > 768 ? styles.header : styles.headerMobile}>
            Full Application
          </Text>
          <Text style={width > 768 ? styles.subHeader : styles.subHeaderMobile}>
            {
              "You're almost there! Please provide a clear copy of each of the document requested below."
            }
          </Text>
        </>
      )}
      {currentStep === 5 && (
        <>
          <Text style={width > 768 ? styles.header : styles.headerMobile}>
            Application Summary
          </Text>
        </>
      )}
      {currentStep < 5 && (
        <View
          style={
            width > 768
              ? styles.fullApplicationStatusContainer
              : styles.fullApplicationStatusContainerMobile
          }
        >
          {(currentStep !== 3 || currentStep !== 5) &&
            steps.map((step, idx) => {
              return (
                <View key={step}>
                  <StepButton
                    number={idx + 1}
                    label={step}
                    stepsDone={stepsDone}
                    currentStep={currentStep === 5 ? 4 : currentStep}
                    clickable={
                      stepsDone[idx + 1] || farthestStep === idx + 1 || __DEV__
                    }
                    onPress={() => setCurrentStep(idx + 1)}
                  />
                </View>
              );
            })}
        </View>
      )}

      {width < 768 && (
        <View style={styles.preApprovalLabelContainer}>
          <Text style={styles.preApprovalLabel}>{steps[currentStep - 1]}</Text>
          {currentStep < 3 && (
            <Text
              style={styles.preApprovalSubLabel}
            >{`Next: ${steps[currentStep]}`}</Text>
          )}
        </View>
      )}

      {currentStep === 1 && (
        <SSNStep formData={userFormData} setFormData={setUserFormData} />
      )}

      {/* Comment for now since it is already requested in the primary applicant */}
      {/* {currentStep === 2 && (
        <TaxReturnAuthorizationStep
          mboId={mbo?.id}
          loanId={loanId}
          unsigned8821Form={mbo?.unsigned8821Form}
          formData={formData}
          setFormData={setFormData}
          goToNextStep={goToNextStep}
          disabled={disableContinueButton}
        />
      )} */}

      {currentStep === 2 && <ConsentPageStep goToNextStep={goToNextStep} />}

      {currentStep === 3 && (
        <FullAppStep
          formData={formData}
          setFormData={setFormData}
          goToNextStep={goToNextStep}
        />
      )}

      {currentStep === 4 && (
        <>
          <SummaryStep
            mbo={mbo}
            business={business}
            userFormData={userFormData}
            setUserFormData={setUserFormData}
            formData={formData}
            setFormData={setFormData}
            goToNextStep={goToNextStep}
          />
          <CheckBox
            style={styles.checkbox}
            checked={certifyCheck}
            onChange={() => setCertifyCheck(!certifyCheck)}
            children={() => (
              <Text
                style={
                  width > 576
                    ? { ...styles.inputText, paddingLeft: 10 }
                    : { ...styles.inputTextMobile, paddingLeft: 10 }
                }
              >
                I certify the above information is correct, to my knowledge.
              </Text>
            )}
          />
        </>
      )}

      {currentStep === 5 && <DonePage loan={loan} />}

      {currentStep !== 2 && currentStep !== 2 && currentStep !== 5 && (
        <Button
          disabled={disableContinueButton}
          style={
            disableContinueButton
              ? styles.disabledActionButton
              : styles.nextActionButton
          }
          status="basic"
          appearance="ghost"
          onPress={() => {
            goToNextStep(true);
          }}
          accessoryRight={ArrowIcon}
          children={() => (
            <Text
              style={
                disableContinueButton
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              {"Continue "}
            </Text>
          )}
        />
      )}
    </>
  );
};
