import { useNavigation } from "@react-navigation/core";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Card,
  Divider,
  Modal,
} from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  ScrollView,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";

// @ts-ignore
import { WebView as WebViewWeb } from "react-native-web-webview";
import * as api from "../../services/api";
import {
  createLendingDocSigning,
  createSigningCeremony,
  getDocuSignAppKeys,
  getDocuSignAuthToken,
} from "../../services/api/docusign";
import { useStore } from "../../stores";
import { uploadDocument } from "../../utils";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const UserLendingDocModal: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.state.split(":")[0] ||
    new URLSearchParams(window.location.search).get("state");

  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [frontendUrl, setFrontendUrl] = useState("");

  const [accessToken, setAccessToken] = useState("");
  const [uploadedName, setUploadedName] = useState("");
  const [uploadedUri, setUploadedUri] = useState("");

  const IconPlaceholder = (_props: any) => <View />;

  const [formData, setFormData] = useState<any>({
    uploadedDocument: "",
    docType: "Closing Document",
    categoryType: "",
    documentName: "",
  });

  const submit = (item: any) => {
    setUploadLoading(true);

    const data = new FormData();
    Object.keys(formData).forEach((key) => data.append(key, formData[key]));

    api
      .uploadDocumentFromUnderwriterPortal(
        authStore.accessToken || "",
        applicationId,
        data
      )
      .then((resp) => {
        const response = resp.response.entities;
        console.log(response);
        setUploadedUri(response["fileUri"]);
        setUploadLoading(false);
        setUploadDone(true);
      });
  };

  const [docuSignModal, setDocuSignModal] = useState(false);

  useEffect(() => {
    (async () => {
      // let basicToken = "";

      const resp = await getDocuSignAppKeys(authStore?.accessToken || "");
      if (resp.response.entities) {
        setFrontendUrl(resp.response.entities["frontendUrl"]);
      }

      let data = {
        // basicToken: basicToken,
        // grantType: "authorization_code",
        // code: route?.params?.code || "",
        applicationId: applicationId,
      };

      const respAuth = await getDocuSignAuthToken(
        authStore?.accessToken || "",
        data
      );
      if (respAuth.response.entities) {
        if (respAuth.response.entities["timeout"] === "timeout") {
          setErrorMessages(
            "Connection timed out from Docusign. Please try again."
          );
          setLoading(false);
        } else if (respAuth.response.entities["error"] === "error") {
          setErrorMessages("An error occured. Please contact OppZo support.");
          setLoading(false);
        } else {
          setAccessToken(respAuth.response.entities["accessToken"]);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (accessToken !== "") {
      setLoading(true);
      let data = {
        accessToken: accessToken,
        applicationId: applicationId,
        returnUrl: `${frontendUrl}/lending-document-modal-end?applicationId=${applicationId}&isLoanTerms=false`,
        isLoanTerms: false,
      };
      (async () => {
        const resp = await createLendingDocSigning(
          authStore?.accessToken || "",
          data
        );
        if (typeof resp.response.entities === "object") {
          if (resp.response.entities["timeout"] === "timeout") {
            setErrorMessages(
              "Connection timed out from Docusign. Please try again."
            );
            setLoading(false);
          } else if (resp.response.entities["signed"] === "signed") {
            setErrorMessages(
              "You have already signed the document. Please check the email that you used to login on DocuSign for a copy of the document."
            );
            setLoading(false);
          } else {
            setEmbedUrl(resp.response.entities["redirectUrl"]);
          }
        } else {
          setErrorMessages(
            resp.response.entities + " You may now close this window."
          );
          setLoading(false);
        }
      })();
    }
  }, [accessToken]);

  useEffect(() => {
    if (embedUrl !== "") {
      setLoading(false);
    }
  }, [embedUrl]);

  const { authStore } = useStore();

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : errorMessages === "" ? (
        <WebViewWeb source={{ uri: embedUrl }} />
      ) : (
        <View style={styles.oneText}>
          <Text style={styles.oneTextMsg}>{errorMessages}</Text>
        </View>
      )}
    </>
  );
};

export default UserLendingDocModal;
