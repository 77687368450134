import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/core";
import {
  Text,
  useStyleSheet,
  Modal,
  Divider,
  Icon,
  ThemeProvider,
  Spinner,
  Button,
  Input,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { TouchableOpacity, View, ScrollView, Dimensions } from "react-native";

import { TableDisplay } from "../../components/TableDisplay";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

export const LoanPreviewModal: React.FC<any> = ({
  isOpen,
  close,
  applicationId,
}) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const [selectedIndex, setSelectedIndex] = React.useState([]);
  const [baseThreshold, setBaseThreshold] = useState("");

  const [loanData, setLoanData] = useState([
    {
      header: "Unbilled Contract Value",
      displayValue: "?",
      fieldNameInResponse: "unbilledContractAmount",
      prefix: "$",
    },
    {
      header: "System Computed Loan Amount",
      displayValue: "?",
      fieldNameInResponse: "amount",
      prefix: "$",
      infoTooltip: "20% of the Unbilled Contract Value, capped at $1M",
    },
    {
      header: "Term",
      displayValue: "?",
      fieldNameInResponse: "term",
      suffix: " Months",
    },
    {
      header: "Interest Rate",
      displayValue: "?",
      fieldNameInResponse: "interest",
      suffix: "%",
    },
    {
      header: "Monthly Payment",
      displayValue: "?",
      fieldNameInResponse: "monthlyPayment",
      infoTooltip: `loan_amount = System Computed Loan Amount
interest = interest rate (i.e. 11)
adjusted_interest = interest / 100 / 12 / 12
term = term in months
x = (1 + adjusted_interest) ^ (term)
amortized_monthly_payment = round( loan_amount * adjusted_interest * x/ (x - 1), 2)
monthly_payment = amortized_monthly_payment * 1.2`,
      prefix: "$",
    },
  ]);

  const [applicationData, setApplicationData] = useState([
    {
      header: "Business Name",
      displayValue: "?",
      fieldNameInResponse: "businessName",
    },
    {
      header: "State",
      displayValue: "?",
      fieldNameInResponse: "state",
    },
    {
      header: "State Of Incorporation",
      displayValue: "?",
      fieldNameInResponse: "stateOfIncorporation",
    },
  ]);

  const [contractAndCollateralData, setContractAndCollateralData] = useState([
    {
      header: "Base Contract Value",
      displayValue: "?",
      fieldNameInResponse: "baseContractValue",
      prefix: "$",
    },
    {
      header: "Unbilled Contract Value",
      displayValue: "?",
      fieldNameInResponse: "unbilledContractAmount",
      prefix: "$",
    },
    {
      header: "Maturity Date",
      displayValue: "?",
      fieldNameInResponse: "maturityDate",
    },
  ]);

  const [financialsData, setFinancialsData] = useState([
    {
      header: "Average Cash Balance Over 12 Months",
      displayValue: "?",
      fieldNameInResponse: "averageCashBalanceOver12Months",
      prefix: "$",
    },
    {
      header: "Debt Service Coverage By Cash",
      displayValue: "?",
      fieldNameInResponse: "debtServiceCoverageByCash",
    },
    {
      header: "Debt Service Coverage By Operating Income",
      displayValue: "?",
      fieldNameInResponse: "debtServiceCoverageByOperatingIncome",
    },
  ]);

  const [covenantsData, setCovenantsData] = useState<any>([
    {
      label: "Leverage (Debt/Equity)",
      threshold: "",
      info: "N/A",
      checked: false,
    },
    {
      label: "DSCR (EBITDA/Debt Service)",
      threshold: "",
      info: "Minimum 1.2",
      checked: true,
      alwaysChecked: true,
    },
    {
      label: "Tangible Net Worth ...",
      threshold: "",
      info: "Positive at $1",
      checked: false,
    },
    {
      label: "Current Ratio...",
      threshold: "",
      info: "Minimum 1.0",
      checked: false,
    },
  ]);

  const { authStore } = useStore();

  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      setLoading(true);
      api
        .getLoanPreviewData(authStore?.accessToken, applicationId)
        .then((resp) => {
          const response = resp.response.entities;
          setLoanData(
            loanData.map((obj: any) => {
              if (obj.fieldNameInResponse in response.loanSummary) {
                return {
                  ...obj,
                  displayValue:
                    obj.fieldNameInResponse === "monthlyPayment"
                      ? response.loanSummary[
                          "finalDecisionStatus"
                        ].toLowerCase() !== "approved"
                        ? "?"
                        : response.loanSummary[obj.fieldNameInResponse]
                      : obj.fieldNameInResponse === "interest"
                      ? response.loanSummary["riskRating"]
                        ? response.loanSummary[obj.fieldNameInResponse]
                        : "?"
                      : response.loanSummary[obj.fieldNameInResponse],
                };
              } else return obj;
            })
          );
          setStatus(response.loanSummary.status);
          setApplicationData(
            applicationData.map((obj: any) => {
              if (obj.fieldNameInResponse in response.businessSummary) {
                return {
                  ...obj,
                  displayValue:
                    response.businessSummary[obj.fieldNameInResponse],
                };
              } else return obj;
            })
          );
          setContractAndCollateralData(
            contractAndCollateralData.map((obj: any) => {
              if (obj.fieldNameInResponse in response.contractAndCollateral) {
                return {
                  ...obj,
                  displayValue:
                    response.contractAndCollateral[obj.fieldNameInResponse],
                };
              } else return obj;
            })
          );
          setFinancialsData(
            financialsData.map((obj: any) => {
              if (obj.fieldNameInResponse in response.financials) {
                return {
                  ...obj,
                  displayValue: response.financials[obj.fieldNameInResponse],
                };
              } else return obj;
            })
          );
          if (response.covenants.length) {
            const responseCovenants = covenantsData.map((obj: any) => {
              return {
                ...obj,
                ...(response.covenants.filter(
                  (cov: any) => cov.label === obj.label
                )[0] || {}),
              };
            });
            setCovenantsData(responseCovenants);
            setSelectedIndex(
              responseCovenants
                .map((cov: any, idx: number) => ({ ...cov, idx }))
                .filter((cov: any) => cov.checked || cov.alwaysChecked)
                .map((cov: any) => new IndexPath(cov.idx))
            );
            const allThresholds = [
              ...new Set(
                response.covenants
                  .filter((cov: any) => cov.checked)
                  .map((cov: any) => cov.threshold)
              ),
            ];

            setBaseThreshold(
              //@ts-ignore
              allThresholds.length === 1 ? allThresholds[0] : ""
            );
          } else {
            setSelectedIndex(
              covenantsData
                .map((cov: any, idx: number) => ({ ...cov, idx }))
                .filter((cov: any) => cov.checked || cov.alwaysChecked)
                .map((cov: any) => new IndexPath(cov.idx))
            );
            setBaseThreshold("");
          }
          setLoading(false);
        });
    }
  }, [applicationId]);

  useEffect(() => {
    const selectedIndexes = selectedIndex.map((obj: any) => obj.row);

    setCovenantsData(
      covenantsData.map((option: any, idx: number) => {
        if (selectedIndexes.includes(idx)) {
          return { ...option, checked: true, threshold: baseThreshold };
        }
        return { ...option, checked: false, threshold: "" };
      })
    );
  }, [selectedIndex]);

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => close()}
      style={styles.previewModal}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.modalHeaderContainer}>
          <Text style={styles.header}>Preview</Text>
          <TouchableOpacity onPress={() => close()}>
            <Icon width={24} height={24} name="close-outline" />
          </TouchableOpacity>
        </View>

        {loading ? (
          <View style={styles.center}>
            <LoadingIndicator />
          </View>
        ) : (
          <ScrollView
            style={{
              ...styles.previewModalScroll,
              maxHeight: Dimensions.get("window").height * 0.75,
            }}
          >
            <Text style={styles.cardHeader}>Loan Summary</Text>
            <TableDisplay data={loanData} itemsPerRow={3} />
            <Divider style={styles.cardDivider} />

            <Text style={styles.cardHeader}>Application Summary</Text>
            <TableDisplay data={applicationData} itemsPerRow={3} />
            <Divider style={styles.cardDivider} />

            <Text style={styles.cardHeader}>Contract and Collateral</Text>
            <TableDisplay data={contractAndCollateralData} itemsPerRow={3} />
            <Divider style={styles.cardDivider} />

            <Text style={styles.cardHeader}>Financials</Text>
            <TableDisplay data={financialsData} itemsPerRow={2} />

            <TouchableOpacity
              style={styles.viewFullApplicationButton}
              onPress={() => {
                navigation.navigate("summary", { applicationId });
                close();
              }}
            >
              <Text style={styles.viewFullApplicationButtonText}>
                View Full Application
              </Text>
            </TouchableOpacity>
          </ScrollView>
        )}
      </ThemeProvider>
    </Modal>
  );
};
