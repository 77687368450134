import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  manageProfileContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 69,
    width: "85%",
    backgroundColor: "red",
  },
  manageProfileContainerMobile: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 69,
    width: "95%",
    backgroundColor: "red",
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    fontWeight: 800,
    lineHeight: 48,
    fontSize: 30,
    marginTop: 69,
    marginBottom: 24,
    textAlign: "center",
  },
  subHeader: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 32,
    fontSize: 22,
    flexDirection: "column",
    alignItems: "center",
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 24,
    marginBottom: 0,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    lineHeight: 16,
    fontSize: 13,
    marginBottom: 70,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexRowBetweenSpacer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 62,
  },
  divider: {
    backgroundColor: "#8F9BB3",
    height: 2,
    marginVertical: 20,
  },
  profileView: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    marginVertical: 50,
    marginTop: 18,
    marginLeft: "20%",
    marginRight: 18,
    padding: 100,
    paddingHorizontal: 150,
  },
  profileViewMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    marginVertical: 50,
    marginTop: 18,
    marginLeft: "5%",
    marginRight: 18,
    padding: 30,
  },
  editButton: {
    width: 10,
  },
  inputLabel: {
    fontFamily: "Montserrat_500Medium",
    fontSize: 13,
    lineHeight: 24,
    marginHorizontal: 5,
  },
  input: {
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputNotEditing: {
    marginBottom: 22,
    marginHorizontal: 5,
    flex: 1,
    // backgroundColor: "#1A2138",
    borderRadius: 4,
  },
  inputTextNotEditing: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    width: "100%",
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    width: "100%",
  },
  inputTextMobile: {
    fontSize: 12,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    width: "100%",
  },
  inputMask: {
    marginBottom: 22,
    marginHorizontal: 5,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    padding: 15,
    width: "98%",
  },
  inputMaskMobile: {
    marginBottom: 20,
    marginHorizontal: 5,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    padding: 15,
    width: "97%",
  },
  cashInput: {
    flex: 1,
    marginBottom: 22,
    marginHorizontal: 5,
    fontFamily: "Inter_700Bold",
    fontSize: 26,
    lineHeight: 32,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
  },
  saveButton: {
    borderRadius: 5,
  },
  saveButtonGradient: {
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-evenly",
    borderRadius: 5,
  },
  saveButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
    padding: 5,
    color: "white",
  },
  saveButtonIcon: {
    width: 26,
    height: 26,
    paddingHorizontal: 5,
  },
  cancelButton: {
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-evenly",
    borderRadius: 5,
    backgroundColor: "#222B45",
    marginLeft: 20,
  },
  cancelButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
    padding: 5,
    paddingHorizontal: 15,
    color: "white",
  },
  toast: {
    backgroundColor: "rgba(29, 132, 255, 0.16)",
    paddingVertical: 22,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 20,
    marginBottom: 40,
  },
  toastSuccess: {
    backgroundColor: "#1D84FF",
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 20,
    marginBottom: 40,
  },
  toastInfo: {
    width: 44,
    height: 44,
    backgroundColor: "white",
    borderRadius: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    justifySelf: "center",
    alignSelf: "center",
  },
  toastInfoIcon: {
    width: 20,
    height: 20,
  },
  toastHeader: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  toastDescription: {
    fontFamily: "Montserrat_500Medium",
    fontSize: 13,
    lineHeight: 24,
    flex: 1,
    flexWrap: "wrap",
  },
  toastTextContainer: {
    display: "flex",
    flexShrink: 1,
  },
  datePickerPlaceholderText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginHorizontal: 8,
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
  summarySubHeader: {
    fontFamily: "Montserrat_400Regular",
    color: "background-alternative-color-1",
    lineHeight: 24,
    fontSize: 24,
    paddingTop: 10,
  },
});
