import {
  Text,
  useStyleSheet,
  Button,
  Icon,
  Spinner,
} from "@ui-kitten/components";
import React, { useState } from "react";

import config from "../../config";
import { themedStyles } from "./themedStyles";

export const PullReportButton: React.FC<any> = ({
  uri = "",
  isLink = false,
  text = "PULL REPORT",
  accessoryIconName = "arrow-forward",
  getUri,
  setUri = false,
  openModal,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(false);

  const accessoryIcon = (props: any) => (
    <Icon {...props} name={accessoryIconName} />
  );
  const accessoryPlaceholder = (props: any) => <></>;
  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="tiny" />
  );

  const disabled =
    loading ||
    (!setUri &&
      !uri &&
      text != "NO HIT / FAIL" &&
      text != "RETRY - PULL REPORT") ||
    uri == "https://" ||
    uri == "http://" ||
    uri == "";

  return (
    <Button
      disabled={disabled || text == "REPORT FAIL"}
      size={isLink ? "tiny" : "medium"}
      style={{
        ...(isLink && !disabled
          ? styles.pullDataLink
          : isLink && disabled
          ? styles.disabledPullDataLinkText
          : styles.pullDataBtn),
        ...(disabled ? styles.disabledPullDataLink : {}),
        ...(text == "REPORT FAIL" ? styles.errorPullDataLink : {}),
      }}
      appearance={isLink ? "ghost" : "outline"}
      accessoryRight={
        loading
          ? LoadingIndicator
          : isLink
          ? accessoryIcon
          : accessoryPlaceholder
      }
      onPress={() => {
        if (text == "NO HIT / FAIL" || text == "RETRY - PULL REPORT") {
          openModal();
        } else if (setUri) {
          getUri(setLoading);
        } else {
          if (uri) {
            if (/^(?:[a-z]+:)?\/\//.test(uri.toLowerCase()))
              window.open(uri, "_blank");
            else
              window.open(config.urls.api.replace("/api/", "") + uri, "_blank");
          } else alert("document not found");
        }
      }}
      children={() => (
        <Text
          style={{
            ...(isLink ? styles.pullDataLinkText : styles.pullDataBtnText),
            ...(disabled ? styles.disabledPullDataLinkText : {}),
            ...(text == "REPORT FAIL" ? styles.errorPullDataLinkText : {}),
          }}
        >
          {text}
          {loading && "  "}
        </Text>
      )}
    />
  );
};
