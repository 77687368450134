import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Select,
  SelectItem,
  Button,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";

import { useStore } from "../../stores";
import { uploadDocument } from "../../utils";
import { managementExperienceOptions } from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const SummaryOtherInfo: React.FC<any> = ({
  user,
  mbo,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  setSuccess,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore, mboStore } = useStore();

  const defaultFields = {
    resumes: "",
    linkedIn: "",
    driversLicense: "",
    personalFinancialStatements: "",
    legalActions: "",
    managementExperience: authStore?.user?.managementExperience || "",
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [
    selectedManagementExperienceIndex,
    setSelectedManagementExperienceIndex,
  ] = useState<any>(null);

  const [localFormData, setLocalFormData] = useState<any>({ ...defaultFields });

  useEffect(() => {
    formDataReset();
    setSuccess(false);
  }, []);

  useEffect(() => {
    if (selectedManagementExperienceIndex)
      setLocalFormData({
        ...localFormData,
        managementExperience:
          managementExperienceOptions[selectedManagementExperienceIndex?.row] ||
          "",
      });
  }, [selectedManagementExperienceIndex]);

  const formDataReset = () => {
    setLocalFormData({
      resumes: formData.resumes,
      linkedIn: formData.linkedIn,
      driversLicense: formData.driversLicense,
      personalFinancialStatements: formData.personalFinancialStatements,
      legalActions: formData.legalActions,
    });
  };

  const legalActionsRef = useRef<Input>(null);

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setFormData({
      ...formData,
      ...localFormData,
    });
    setErrorMessages({ ...defaultErrors });
    const result = await mboStore.updateMBOInfo(mbo.id, {
      ...localFormData,
    });
    if (!result?.ok) {
      console.log(result?.errors);
      setErrorMessages(result?.errors);
    } else {
      setIsEdit(false);
      setSuccess(true);
    }
    setLoading(false);
  }, [loading, localFormData]);

  return (
    <View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Text>
        {errorMessages?.resumes && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.resumes}
          </Text>
        )}
      </Text>
      <Text
        style={{
          ...styles.inputLabel,
          ...(theme === "light" ? { color: "#FFFFFF" } : {}),
        }}
      >
        Resumes
      </Text>
      <Button
        disabled={!isEdit}
        style={
          localFormData.resumes
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={localFormData.resumes ? "success" : "basic"}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setLocalFormData({
                ...localFormData,
                resumes: doc.file,
              });
              setErrorMessages({ ...errorMessages, resumes: "" });
            } else {
              setErrorMessages({
                ...errorMessages,
                resumes: "File too large. Upload limit: 10MB.",
              });
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Resumes</Text>}
      />

      <Text style={styles.inputLabel}>Years of management experience</Text>
      <Select
        status="primary"
        size={width > 576 ? "medium" : "small"}
        style={styles.input}
        placeholder="Years of management experience"
        value={formData.managementExperience}
        selectedIndex={selectedManagementExperienceIndex}
        onSelect={(index) => setSelectedManagementExperienceIndex(index)}
      >
        {managementExperienceOptions.map((option, idx) => (
          <SelectItem title={option} key={`${idx}-business-role-option`} />
        ))}
      </Select>

      <Text
        style={{
          ...styles.inputLabel,
          ...(theme === "light" ? { color: "#FFFFFF" } : {}),
        }}
      >
        Driver's License
      </Text>
      <Button
        disabled={!isEdit}
        style={
          localFormData.driversLicense
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={localFormData.driversLicense ? "success" : "basic"}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setLocalFormData({
                ...localFormData,
                driversLicense: doc.file,
              });
              setErrorMessages({ ...errorMessages, driversLicense: "" });
            } else {
              setErrorMessages({
                ...errorMessages,
                resumes: "File too large. Upload limit: 10MB.",
              });
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>Driver's License</Text>
        )}
      />
      <Text
        style={{
          ...styles.inputLabel,
          ...(theme === "light" ? { color: "#FFFFFF" } : {}),
        }}
      >
        Personal Financial Statements
      </Text>
      <Button
        disabled={!isEdit}
        style={
          localFormData.personalFinancialStatements
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={localFormData.personalFinancialStatements ? "success" : "basic"}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setLocalFormData({
                ...localFormData,
                personalFinancialStatements: doc.file,
              });
              setErrorMessages({
                ...errorMessages,
                personalFinancialStatements: "",
              });
            } else {
              setErrorMessages({
                ...errorMessages,
                resumes: "File too large. Upload limit: 10MB.",
              });
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>Financial Statements</Text>
        )}
      />
      <Text
        style={{
          ...styles.inputLabel,
          ...(theme === "light" ? { color: "#FFFFFF" } : {}),
        }}
      >
        Legal Actions
      </Text>
      <Input
        disabled={!isEdit}
        multiline
        textStyle={{ minHeight: 256, color: "white" }}
        style={styles.input}
        placeholder="Input Text"
        status="basic"
        value={localFormData.legalActions}
        onChangeText={(text) =>
          setLocalFormData({ ...localFormData, legalActions: text })
        }
      />
      {isEdit && (
        <View style={styles.flexRow}>
          <TouchableOpacity
            style={styles.saveButton}
            onPress={() => {
              onSubmitForm();
            }}
          >
            <LinearGradient
              style={styles.saveButtonGradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={["#D012B2", "#8A1CDB", "#5327FF"]}
            >
              <Text style={styles.saveButtonText}>Save</Text>
              <Icon
                style={styles.saveButtonIcon}
                name="checkmark-circle-2"
                fill="white"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.cancelButton,
              ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
            }}
            onPress={() => {
              setIsEdit(false);
              formDataReset();
            }}
          >
            <Text
              style={{
                ...styles.cancelButtonText,
                ...(theme === "light" ? { color: "#222B45" } : {}),
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
