import { useLinkTo, useNavigation } from "@react-navigation/native";
import {
  Button,
  Icon,
  Layout,
  Modal,
  useStyleSheet,
  Divider,
  Text,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  Image,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Platform,
} from "react-native";

import { ThemeContext } from "../../../theme-context";
import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import config from "../../config";
import { checkMboValidity, storeMboEmails } from "../../services/api";
import { useStore } from "../../stores";
import { LoadingIndicator } from "../ProfileEdit";
import AccountInformationStep from "./AccountInformationStep";
import BusinessInformationStep from "./BusinessInformationStep";
import PartnerInformationStep from "./PartnerInformationStep";
import StepButton from "../../components/StepButton/StepButton";
import { themedStyles } from "./themedStyles";

type Props = object;

const RejectionModal: React.FC<any> = ({ isOpen, setIsOpen }) => {
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  return (
    <Modal visible={isOpen} style={styles.rejectionModal}>
      <Text style={styles.rejectionModalText}>
        Only a decision-making executive or majority owner can apply for a
        business loan.
      </Text>
      <View style={styles.flexRow}>
        <Button
          style={styles.nextActionModalButton}
          accessoryRight={ArrowIcon}
          onPress={() => {
            window.open("mailto:support@getoppzo.com");
          }}
        >
          Contact OppZo
        </Button>
        <Button
          style={styles.goBackModalButton}
          accessoryRight={ArrowIcon}
          onPress={() => {
            setIsOpen(false);
          }}
        >
          Go back
        </Button>
      </View>
    </Modal>
  );
};

const Register: React.FC<any> = ({ route }) => {
  const linkTo = useLinkTo();

  const { width, height } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const SignUpImage = require("../../../assets/SignUpImage.png");
  const SignUpImageMobile = require("../../../assets/SignUpImageMobile.png");

  const key =
    route?.params?.key ||
    new URLSearchParams(window.location.search).get("key");

  const isProdEnv = !(
    config.urls.api.includes("test") ||
    config.urls.api.includes("dev") ||
    config.urls.api.includes("local") ||
    config.urls.api.includes("127.0.0.1") ||
    config.urls.api.includes("demo")
  );

  const { authStore } = useStore();

  const defaultFields = {
    firstName: "",
    lastName: "",
    email: authStore?.user?.email || "",
    phoneNumber: "",
    birthday: "",
    password1: "",
    password2: "",
    businessRole: "",
    businessName: "",
    businessAddress: "",
    businessAddStreetLine1: "",
    businessAddStreetLine2: "",
    businessAddCity: "",
    businessAddState: "",
    businessAddZipCode: "",
    stateOfIncorporation: "",
    jurisdictionCounty: "",
    businessType: "",
    tradeNames: [],
    businessIsAuthorize: false,
    isPrimaryApplicant: true,
    otherBusinessOwners: null,
    ownTwentyPercent: null,
    businessPartners: [],
    contractorLevel: [] as string[],
  };
  const defaultErrors = {
    ...defaultFields,
    phoneNumberInput: "",
    nonFieldErrors: "",
  };
  const [tempFirstName, setTempFirstName] = useState("");
  const [tempLastName, setTempLastName] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [formData, setFormData] = useState({ ...defaultFields });
  const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });
  const [currentQuestionID, setCurrentQuestionID] = useState(
    authStore?.user?.id ? 1 : 0
  );
  const [stepsDone, setStepsDone] = useState<any>(Array(3).fill(false));
  const [loading, setLoading] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [sameAddressForLoan, setSameAddressForLoan] = useState(false);
  const [tradeNamesNotApplicable, setTradeNamesNotApplicable] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [isMBOSignUp, setIsMBOSignUp] = useState(false);
  const [isPrimaryApplicant, setIsPrimaryApplicant] = useState(true);

  const themeContext = React.useContext(ThemeContext);

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  useEffect(() => {
    themeContext.resetTheme();
    const params = new URLSearchParams(window.location.search);
    setFormData({
      ...formData,
      firstName: params.get("firstName") || "",
      lastName: params.get("lastName") || "",
      email: params.get("email") || authStore?.user?.email || "",
      businessName:
        params.get("businessName") || authStore?.user?.businessName || "",
      contractorLevel:
        params.get("governmentContract")?.split(",") || ([] as string[]),
    });
  }, []);

  const unmaskPhoneNumber = (phoneNumber: any) => {
    let ret = "";
    for (const i in phoneNumber) {
      if (
        Boolean(
          [true, true, true, true, true, true, true, true, true, true][
            phoneNumber[i]
          ]
        ) ||
        phoneNumber[i] === "+"
      ) {
        ret = `${ret}${phoneNumber[i]}`;
      }
    }
    return ret;
  };

  const triggerConversion = useCallback(() => {
    if (Platform.OS === "web") {
      console.log(`trigger create account`);
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "signup form complete",
      });
      if ((window as any)?.gtag && config.gtag_conversion_id) {
        (window as any).gtag("event", "conversion", {
          send_to: `${config.gtag_id}/${config.gtag_conversion_id}`,
        });
      }
      if ((window as any)?.fbq) {
        (window as any).fbq("track", "PageView");
        (window as any).fbq("track", "Lead");
      }
      if ((window as any)?.lintrk && config.linkedin_conversion_id) {
        (window as any).lintrk("track", {
          conversion_id: `${config.linkedin_conversion_id}`,
        });
      }
    }
  }, []);

  const triggerConversionPrequal1 = useCallback(() => {
    if (Platform.OS === "web") {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: "pre-qual 1 complete",
      });
    }
  }, []);

  const onSubmitForm = useCallback(
    async (
      data,
      isCheckExistenceCatch = false,
      isInCreateAccountPage = false
    ) => {
      if (loading) {
        return;
      }
      console.log("data", data);
      console.log("IS MBO SIGNUP", isMBOSignUp);
      console.log("IS Check existence catch", isCheckExistenceCatch);
      setLoading(true);

      // Allow only Main Applicant to Create Account to apply for loan
      // REMOVING AS OF 11/8 - THIS STOPS SBOs FROM REGISTERING AND THEY SHOULD BE ALLOWED TO
      // if (
      //   data.isPrimaryApplicant === false &&
      //   !isMBOSignUp &&
      //   !isCheckExistenceCatch
      // ) {
      //   console.log('IS SBO');
      //   setIsRejectionModalOpen(true);
      //   setLoading(false);
      //   setFormSubmit(false);
      //   return;
      // }

      setErrorMessages({ ...defaultErrors });

      // Create Account
      if (isInCreateAccountPage) {
        console.log("SIGNING USER UP", data);
        const signUpResult = await authStore.signUp({
          ...data,
          phoneNumber: unmaskPhoneNumber(data.phoneNumber),
        });
        console.log("SIGN UP RESULT", signUpResult);

        // If user is not a primary applicant, send them to the email verification view
        if (signUpResult.ok && !data.isPrimaryApplicant) {
          linkTo("/auth/email-verification");
        }

        if (!signUpResult.ok) {
          setLoading(false);
          setErrorMessages({ ...errorMessages, ...signUpResult.errors });

          // Go back to first page if there's any errors there:
          if (
            signUpResult?.errors?.firstName ||
            signUpResult?.errors?.lastName ||
            signUpResult?.errors?.businessName ||
            signUpResult?.errors?.email ||
            signUpResult?.errors?.phoneNumber ||
            signUpResult?.errors?.password1 ||
            signUpResult?.errors?.password2
          ) {
            setCurrentQuestionID(0);
          }
        } else if (isProdEnv) {
          // Trigger only on prod site
          triggerConversion();
        }
        setLoading(false);
        setFormSubmit(false);
        return;
      }

      // Already done filling up all information including the Business Details
      const result = await authStore.checkBusiness(data);
      if (!result.ok && !isMBOSignUp && !isCheckExistenceCatch) {
        setErrorMessages({ ...errorMessages, ...result.errors });
        // Reset to Business Details Page
        setCurrentQuestionID(2);
        setLoading(false);
        setFormSubmit(false);
        setFormSubmit(false);
        return;
      }

      const response = await storeMboEmails({
        email: data.email,
        businessPartners: data.businessPartners,
      });
      console.log("Store MBO Response", response);
      if ((response.response.entities["success"] = true)) {
        console.log(response.response.entities);
        // Trigger only on prod site
        if (isProdEnv) {
          console.log(`trigger prequal1`);
          triggerConversionPrequal1();
        }

        if (authStore?.user?.id)
          await authStore.refetchUser(authStore?.user?.id || 0);

        setLoading(false);
        if (isCheckExistenceCatch) {
          linkTo(
            `/auth/email-verification/?businessNameVerification=${data.businessName}`
          );
        }
        linkTo("/auth/email-verification");
      } else {
        setErrorMessages({
          ...errorMessages,
          nonFieldErrors: "An error has occured.",
        });
        setFormSubmit(true);
      }
      setLoading(false);
      setFormData(defaultFields);
    },
    [loading, isMBOSignUp]
  );

  useEffect(() => {
    (async () => {
      if (key) {
        setPageLoading(true);
        const response = await checkMboValidity({
          key,
        });
        if (response.response.entities["valid"] === true) {
          setIsMBOSignUp(false);
          setIsPrimaryApplicant(false);
          setFormData({
            ...formData,
            ...response.response.entities["formData"],
          });
        }
      }
      setPageLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (
      formData.businessAddStreetLine1 &&
      formData.businessAddCity &&
      formData.businessAddState &&
      formData.businessAddZipCode
    ) {
      let address = `${formData.businessAddStreetLine1}, ${formData.businessAddCity}, ${formData.businessAddState}, ${formData.businessAddZipCode}`;
      if (formData.businessAddStreetLine2) {
        address = `${formData.businessAddStreetLine1}, ${formData.businessAddStreetLine2}, ${formData.businessAddCity}, ${formData.businessAddState}, ${formData.businessAddZipCode}`;
      }
      setFormData({
        ...formData,
        businessAddress: address,
      });
    }
  }, [
    formData.businessAddStreetLine1,
    formData.businessAddStreetLine2,
    formData.businessAddCity,
    formData.businessAddState,
    formData.businessAddZipCode,
  ]);

  useEffect(() => {
    if (formSubmit) {
      const nullText = "This field may not be blank.";
      setErrorMessages({
        ...errorMessages,
        businessRole: formData.businessRole ? "" : nullText,
        businessType: formData.businessType ? "" : nullText,
        stateOfIncorporation: formData.stateOfIncorporation ? "" : nullText,
      });
    }
  }, [formSubmit]);

  const isPlusButtonDisabled = useCallback(() => {
    return tempFirstName === "" || tempLastName === "" || tempEmail === "";
  }, [tempFirstName, tempLastName, tempEmail]);

  const validateFilledForm = useCallback(() => {
    let filledBusinessInfoPage = true;
    let filledPartnerInfoPage = true;
    if (
      !formData.businessRole ||
      !formData.businessType ||
      !formData.businessName ||
      !formData.businessAddStreetLine1 ||
      !formData.businessAddCity ||
      !formData.businessAddState ||
      !formData.businessAddZipCode ||
      !formData.stateOfIncorporation ||
      (!formData.jurisdictionCounty &&
        !["Washington DC", "District Of Columbia"].includes(
          formData.businessAddState
        )) ||
      !sameAddressForLoan ||
      (!formData.tradeNames.length && !tradeNamesNotApplicable)
    ) {
      filledBusinessInfoPage = false;
    }

    if (
      formData.isPrimaryApplicant === null ||
      (formData.isPrimaryApplicant && formData.otherBusinessOwners == null) ||
      (formData.isPrimaryApplicant &&
        formData.otherBusinessOwners &&
        formData.ownTwentyPercent == null) ||
      (formData.ownTwentyPercent &&
        formData.isPrimaryApplicant &&
        formData.otherBusinessOwners &&
        formData.businessPartners.length === 0 &&
        isPlusButtonDisabled())
    ) {
      filledPartnerInfoPage = false;
    }

    return { filledBusinessInfoPage, filledPartnerInfoPage };
  }, [
    formData,
    tradeNamesNotApplicable,
    tempFirstName,
    tempLastName,
    tempEmail,
    sameAddressForLoan,
    currentQuestionID,
  ]);

  const { filledBusinessInfoPage, filledPartnerInfoPage } =
    validateFilledForm();
  const submitActionButtonIsDisabled =
    !filledBusinessInfoPage || !filledPartnerInfoPage;
  useEffect(() => {
    const newStepsDone = [...stepsDone];
    const { filledBusinessInfoPage, filledPartnerInfoPage } =
      validateFilledForm();
    newStepsDone[1] = filledBusinessInfoPage;
    newStepsDone[2] = filledPartnerInfoPage;
    setStepsDone(newStepsDone);
  }, [filledBusinessInfoPage, filledPartnerInfoPage]);

  return (
    <Layout style={styles.container}>
      <RejectionModal
        isOpen={isRejectionModalOpen}
        setIsOpen={setIsRejectionModalOpen}
      />
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        {pageLoading ? (
          <View style={{ ...styles.container, alignItems: "center" }}>
            <LoadingIndicator />
          </View>
        ) : (
          <>
            <View
              style={{
                ...styles.flexRow,
                justifyContent: "center",
                padding: 24,
              }}
            >
              {currentQuestionID > 0 &&
                ["Business Information", "Partner Information"].map(
                  (step, idx) => {
                    return (
                      <>
                        <StepButton
                          number={idx + 1}
                          label={step}
                          stepsDone={stepsDone}
                          currentStep={currentQuestionID}
                          onPress={() => setCurrentQuestionID(idx + 1)}
                          clickable={() => {
                            stepsDone[idx + 1];
                          }}
                        />
                        {<Divider style={styles.divider} />}
                      </>
                    );
                  }
                )}
            </View>
            <View
              style={
                width > 992
                  ? styles.registerContainer
                  : styles.registerContainerMobile
              }
            >
              {width > 992 ? (
                <Image
                  style={{ width: 517, height: 809 }}
                  source={SignUpImage}
                />
              ) : (
                <Image
                  style={{ width: height * 0.1424 * 0.9, height: height * 0.9 }}
                  source={SignUpImageMobile}
                />
              )}
              {currentQuestionID === 0 && (
                <AccountInformationStep
                  loading={loading}
                  setLoading={setLoading}
                  formData={formData}
                  setFormData={setFormData}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                  onContinue={() => {
                    setLoading(false);
                    setCurrentQuestionID(currentQuestionID + 1);
                    // triggerConversion();
                  }}
                  onSubmitForm={onSubmitForm}
                  isMBOSignUp={isMBOSignUp}
                  isPrimaryApplicant={isPrimaryApplicant}
                  user={authStore.user?.$}
                />
              )}
              {currentQuestionID === 1 && (
                <BusinessInformationStep
                  loading={loading}
                  formData={formData}
                  setFormData={setFormData}
                  onSubmitForm={onSubmitForm}
                  onContinue={() => {
                    setCurrentQuestionID(currentQuestionID + 1);
                  }}
                  currentQuestionID={currentQuestionID}
                  setCurrentQuestionID={setCurrentQuestionID}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                  sameAddressForLoan={sameAddressForLoan}
                  setSameAddressForLoan={setSameAddressForLoan}
                  tradeNamesNotApplicable={tradeNamesNotApplicable}
                  setTradeNamesNotApplicable={setTradeNamesNotApplicable}
                  user={authStore.user?.$}
                  submitActionButtonIsDisabled={submitActionButtonIsDisabled}
                />
              )}
              {currentQuestionID === 2 && (
                <PartnerInformationStep
                  loading={loading}
                  formData={formData}
                  setFormData={setFormData}
                  onSubmitForm={onSubmitForm}
                  currentQuestionID={currentQuestionID}
                  setCurrentQuestionID={setCurrentQuestionID}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                  sameAddressForLoan={sameAddressForLoan}
                  setSameAddressForLoan={setSameAddressForLoan}
                  tradeNamesNotApplicable={tradeNamesNotApplicable}
                  setTradeNamesNotApplicable={setTradeNamesNotApplicable}
                  user={authStore.user?.$}
                  tempFirstName={tempFirstName}
                  tempLastName={tempLastName}
                  tempEmail={tempEmail}
                  setTempFirstName={setTempFirstName}
                  setTempLastName={setTempLastName}
                  setTempEmail={setTempEmail}
                  submitActionButtonIsDisabled={submitActionButtonIsDisabled}
                />
              )}
            </View>
          </>
        )}
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default Register;
