import * as eva from "@eva-design/eva";
import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Layout,
  Text,
  Divider,
  useStyleSheet,
  Spinner,
  ThemeProvider,
  Button,
  Icon,
  Input,
  CheckBox,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import Collapsible from "react-native-collapsible";

import Header from "../../components/Header";
import { TableDisplay } from "../../components/TableDisplay";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import UnderwriterPortalHeader from "../../components/UnderwriterPortalHeader";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { UnbilledContractAmountConfirmIndicator } from "../UnderwriterPortalCollateral/ContractAndCollateral";
import AmortizationScheduleModal from "./AmortizationScheduleModal";
import { themedStyles } from "./themedStyles";

const Decision: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  const [showMaxLoanInfo, setShowMaxLoanInfo] = useState(false);
  const [showCovenantsInfo, setShowCovenantsInfo] = useState(false);
  const [disableCovenants, setDisableCovenants] = useState(false);

  const defaultErrors = {
    systemFinalDecisionLoanAmount: "",
    error: "",
  };

  const [errors, setErrors] = useState(defaultErrors);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const investorView =
    route?.params?.investorView ||
    new URLSearchParams(window.location.search).get("investorView");

  const { authStore } = useStore();
  const [isLoanHardRejected, setIsLoanHardRejected] = useState(true);

  const [businessInformationData, setBusinessInformationData] = useState([
    {
      header: "Business Name",
      displayValue: "?",
      fieldNameInResponse: "businessName",
    },
    {
      header: "Type",
      displayValue: "?",
      fieldNameInResponse: "businessType",
    },
    {
      header: "NAICS",
      displayValue: "?",
      fieldNameInResponse: "naics",
    },
    {
      header: "PSC",
      displayValue: "?",
      fieldNameInResponse: "psc",
    },
    {
      header: "Street",
      displayValue: "?",
      fieldNameInResponse: "businessAddStreetLine1",
    },
    {
      header: "City",
      displayValue: "?",
      fieldNameInResponse: "businessAddCity",
    },
    {
      header: "State",
      displayValue: "?",
      fieldNameInResponse: "businessAddState",
    },
    {
      header: "ZIP Code",
      displayValue: "?",
      fieldNameInResponse: "businessAddZipCode",
    },
    {
      header: "Phone",
      displayValue: "?",
      fieldNameInResponse: "phoneNumber",
    },
    {
      header: "EIN",
      displayValue: "?",
      fieldNameInResponse: "einNumber",
    },
    {
      header: "Years of Business",
      displayValue: "?",
      fieldNameInResponse: "yearsInBusiness",
      suffix: " Years",
    },
    {
      header: "Annual Revenue",
      displayValue: "?",
      fieldNameInResponse: "annualRevenue",
      prefix: "$",
    },
    {
      header: "Average Over 12 Months",
      displayValue: "?",
      fieldNameInResponse: "averageCashBalanceOver12Months",
      prefix: "$",
    },
    {
      header: "Unbilled Contract Value",
      displayValue: "?",
      fieldNameInResponse: "unbilledContractAmount",
      prefix: "$",
      accessoryRight: <UnbilledContractAmountConfirmIndicator />,
    },
    {
      header: "Debt Service Coverage by Operating Income",
      displayValue: "?",
      fieldNameInResponse: "debtServiceCoverageByOperatingIncome",
    },
  ]);

  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState<any>({
    systemFinalDecisionLoanAmount: "",
    term: "",
  });

  const [covenantsData, setCovenantsData] = useState<any>([
    {
      label: "Leverage (Debt/Equity)",
      threshold: "",
      info: "No minimum",
      checked: false,
    },
    {
      label: "DSCR (EBITDA/Debt Service)",
      threshold: "",
      info: "Minimum 1.2",
      checked: true,
      alwaysChecked: true,
    },
    {
      label: "Tangible Net Worth ...",
      threshold: "",
      info: "Positive at $1",
      checked: false,
    },
    {
      label: "Current Ratio...",
      threshold: "",
      info: "Minimum 1.0",
      checked: false,
    },
  ]);

  const editButton = (
    <Button
      status="basic"
      onPress={() => setIsEditing(true)}
      style={{ flex: 1 }}
    >
      EDIT
    </Button>
  );

  const saveButton = (
    <Button
      style={{
        //@ts-ignore
        background:
          "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
        width: 200,
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
      onPress={() => setIsEditing(false)}
    >
      SAVE
    </Button>
  );

  // First Row: Unbilled Contract Loan Amount
  const [loanData, setLoanData] = useState([
    {
      header: "Unbilled Contract Value",
      displayValue: "?",
      fieldNameInResponse: "unbilledContractAmount",
      prefix: "$",
      accessoryRight: <UnbilledContractAmountConfirmIndicator />,
    },
    {
      header: "System Computed Loan Amount",
      displayValue: "?",
      fieldNameInResponse: "amount",
      prefix: "$",
      infoTooltip: "20% of the Unbilled Contract Value, capped at $1M",
    },
    {
      header: "Term",
      displayValue: "?",
      editable: true,
      fieldNameInResponse: "term",
      suffix: " Months",
    },
    {
      header: "Interest Rate",
      displayValue: "?",
      fieldNameInResponse: "interest",
      suffix: "%",
    },
    {
      header: "Monthly Payment",
      displayValue: "?",
      fieldNameInResponse: "monthlyPayment",
      prefix: "$",
      infoTooltip: `loan_amount = System Computed Loan Amount
interest = interest rate (i.e. 11)
adjusted_interest = interest / 100 / 12 / 12
term = term in months
x = (1 + adjusted_interest) ^ (term)
amortized_monthly_payment = round( loan_amount * adjusted_interest * x/ (x - 1), 2)
monthly_payment = amortized_monthly_payment * 1.2`,
    },
  ]);

  // Second Row: Maximum Loan Amount
  const [maximumLoanData, setMaximumLoanData] = useState([
    {
      header: "Average Bank Balance Over 12 Months",
      displayValue: "?",
      fieldNameInResponse: "averageCashBalanceOver12Months",
      prefix: "$",
      infoTooltip: `Uses data from bank accounts applicant links with Plaid
computes monthly balances for last 12 months
(balance for month x is calculated by summing current balances across all accounts and undoing any transactions that happened after)

so balance[0] is balance of all accounts at the time of last Plaid data pull
balance[1] is balance 1 month before that
...
balance[i] is balance i months before that
...
balance[11] is balance 1 year before that

it's possible that applicant's bank account is new and there's not enough data from Plaid
to calculate all balances in the past. In that case only available month balances are computed.

then average cash balance over 12 months is sum of monthly balances divided by 12
(or by number less than 12 if less months of balance history are available)
`,
    },
    {
      header: "System Computed Loan Amount",
      displayValue: "?",
      fieldNameInResponse: "amount",
      prefix: "$",
      infoTooltip: `P = loan amount
r = interest / 100 / 12
n = term
monthly_payment = (P * r * (1 + r) ** n) / ((1 + r) ** n - 1) * 1.2
given formulas above, System Computed Loan Amount is a maximum value of P (loan amount) such that monthly_payment
is at most average_cash_balance_over_12_months / 1.2`,
    },
    {
      header: "Term",
      displayValue: "?",
      editable: true,
      fieldNameInResponse: "term",
      suffix: " Months",
    },
    {
      header: "Interest Rate",
      displayValue: "?",
      fieldNameInResponse: "maxInterest",
      suffix: "%",
    },
    {
      header: "Monthly Payment",
      displayValue: "?",
      fieldNameInResponse: "maxMonthlyPayment",
      prefix: "$",
      infoTooltip: `monthly_payment = Average Bank Balance Over 12 Months / 1.2`,
    },
  ]);

  // Third Row: Underwriter's Final Decision
  const [finalDecisionData, setFinalDecisionData] = useState([
    {
      header: "Final Loan Amount",
      displayValue: "?",
      fieldNameInResponse: "systemFinalDecisionLoanAmount",
      editable: true,
      prefix: "$",
      infoTooltip: `This value cannot exceed the unbilled contract loan amount value, and capped at $1M.
This value can be entered/changed by UW (defaults to 20% of unbilled contract value, capped at $1M)`,
    },
    {
      header: "Term",
      displayValue: "?",
      editable: true,
      fieldNameInResponse: "term",
      suffix: " Months",
    },
    {
      header: "Interest Rate",
      displayValue: "?",
      fieldNameInResponse: "interest",
      suffix: "%",
    },
    {
      header: "Monthly Payment",
      displayValue: "?",
      fieldNameInResponse: "monthlyPayment",
      prefix: "$",
      infoTooltip: `P = Final Loan Amount
r = interest / 100 / 12
n = term
Monthly Payment = (P * r * (1 + r) ** n) / ((1 + r) ** n - 1)`,
    },
  ]);

  const [loading, setLoading] = useState(false);

  const [isAmortizationScheduleModalOpen, setIsAmortizationScheduleModalOpen] =
    useState(false);

  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);

  const [editTimestampValue, setEditTimestampValue] = useState<Date | null>(
    null
  );

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getFinalDecisionData(authStore?.accessToken, applicationId)
          .then((resp) => {
            const response = resp.response.entities;
            setBusinessInformationData(
              businessInformationData.map((obj: any) => {
                if (obj.fieldNameInResponse in response.businessInformation) {
                  return {
                    ...obj,
                    displayValue:
                      response.businessInformation[obj.fieldNameInResponse],
                    accessoryRight:
                      obj.fieldNameInResponse !== "unbilledContractAmount" ? (
                        obj.accessoryRight
                      ) : (
                        <UnbilledContractAmountConfirmIndicator
                          underwriterName={
                            response.unbilledContractAmountConfirmerName
                          }
                          date={response.unbilledContractAmountConfirmationDate}
                        />
                      ),
                  };
                } else return obj;
              })
            );
            setFinalDecisionData(
              finalDecisionData.map((obj: any) => {
                if (obj.fieldNameInResponse in response.finalDecision) {
                  return {
                    ...obj,
                    ...(obj.fieldNameInResponse ===
                    "systemFinalDecisionLoanAmount"
                      ? {
                          maxValue: Math.min(
                            response.loanData.unbilledContractAmount,
                            10 ** 6
                          ),
                        }
                      : {}),
                    displayValue:
                      obj.fieldNameInResponse === "monthlyPayment"
                        ? response.finalDecision["riskRating"]
                          ? response.finalDecision[obj.fieldNameInResponse]
                          : "?"
                        : obj.fieldNameInResponse === "interest"
                        ? response.finalDecision["riskRating"]
                          ? response.finalDecision[obj.fieldNameInResponse]
                          : "?"
                        : response.finalDecision[obj.fieldNameInResponse],
                  };
                } else
                  return !obj.isControlButton
                    ? obj
                    : {
                        ...obj,
                        displayValue: response.isHardRejected
                          ? null
                          : obj.displayValue,
                      };
              })
            );
            setLoanData(
              loanData.map((obj: any) => {
                if (obj.fieldNameInResponse in response.loanData) {
                  return {
                    ...obj,
                    displayValue:
                      obj.fieldNameInResponse === "monthlyPayment"
                        ? response.loanData["riskRating"]
                          ? response.loanData[obj.fieldNameInResponse]
                          : "?"
                        : obj.fieldNameInResponse === "interest"
                        ? response.loanData["riskRating"]
                          ? response.loanData[obj.fieldNameInResponse]
                          : "?"
                        : response.loanData[obj.fieldNameInResponse],
                    accessoryRight:
                      obj.fieldNameInResponse !== "unbilledContractAmount" ? (
                        obj.accessoryRight
                      ) : (
                        <UnbilledContractAmountConfirmIndicator
                          underwriterName={
                            response.unbilledContractAmountConfirmerName
                          }
                          date={response.unbilledContractAmountConfirmationDate}
                        />
                      ),
                  };
                } else return obj;
              })
            );
            setMaximumLoanData(
              maximumLoanData.map((obj: any) => {
                if (obj.fieldNameInResponse in response.maximumLoan) {
                  return {
                    ...obj,
                    displayValue:
                      obj.fieldNameInResponse === "maxMonthlyPayment"
                        ? response.maximumLoan["riskRating"]
                          ? response.maximumLoan[obj.fieldNameInResponse]
                          : "?"
                        : obj.fieldNameInResponse === "maxInterest"
                        ? response.maximumLoan["riskRating"]
                          ? response.maximumLoan[obj.fieldNameInResponse]
                          : "?"
                        : response.maximumLoan[obj.fieldNameInResponse],
                  };
                } else return obj;
              })
            );

            setDisableCovenants(
              response.disableCovenants || response.isHardRejected
            );
            setIsCovenantsOpen(!response.disableCovenants);
            if (response.covenants.length)
              setCovenantsData(
                covenantsData.map((obj: any) => {
                  return {
                    ...obj,
                    ...(response.covenants.filter(
                      (cov: any) => cov.label === obj.label
                    )[0] || {}),
                  };
                })
              );
            setLoading(false);
          });
      }
    }, [useIsFocused(), forceUpdateCounter])
  );

  useEffect(() => {
    if (isEditing) {
      setEditData({
        ...editData,
        systemFinalDecisionLoanAmount: finalDecisionData.filter(
          (obj) => obj.fieldNameInResponse === "systemFinalDecisionLoanAmount"
        )[0].displayValue,
        term: finalDecisionData.filter(
          (obj) => obj.fieldNameInResponse === "term"
        )[0].displayValue,
      });
    } else {
      const updatedData: any = {};
      let isSave = false;
      for (const key in editData) {
        if (editData[key]) {
          updatedData[key] = editData[key];
          isSave = true;
        }
      }
      if (isSave) {
        let flagLoanAmount = false;

        if (
          isNaN(updatedData["systemFinalDecisionLoanAmount"]) === true &&
          updatedData["systemFinalDecisionLoanAmount"] !== ""
        ) {
          //not int, invalid
          flagLoanAmount = true;
        }

        //reason code checking here

        if (flagLoanAmount) {
          setErrors({
            ...errors,
            systemFinalDecisionLoanAmount: `${
              flagLoanAmount ? "Invalid Loan Amount." : ""
            }`,
          });
          setIsEditing(true);
        } else {
          setLoading(true);
          api
            .updateFinalDecisionData(
              authStore?.accessToken || "",
              applicationId,
              { ...updatedData, editTimestamp: editTimestampValue }
            )
            .then((resp) => {
              const response = resp.response.entities;

              // Check if other UWs edited the loan
              const errorMessageIndex = Object.keys(response).findIndex(
                (item) => item === "error"
              );
              if (errorMessageIndex > -1) {
                setErrors(response);
                setLoading(false);
                return;
              }

              setEditTimestampValue(response.modified);

              setErrors(defaultErrors);
              setForceUpdateCounter(forceUpdateCounter + 1);
            });
        }
      }
    }
  }, [isEditing]);

  // Get current timestamp
  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      api
        .getLoanApplicationDetails(authStore?.accessToken, applicationId)
        .then((resp) => {
          setEditTimestampValue(resp.response.entities.modified);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const [isBusinessOpen, setIsBusinessOpen] = useState(true);
  const [isFinalDecisionOpen, setIsFinalDecisionOpen] = useState(true);
  const [isCovenantsOpen, setIsCovenantsOpen] = useState(true);

  const onSubmitCovenants = () => {
    setLoading(true);
    api
      .updateCovenantsData(authStore?.accessToken || "", applicationId, {
        covenants: covenantsData,
        editTimestamp: editTimestampValue,
      })
      .then((resp) => {
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={eva.light}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme="light" />
          <UnderwriterPortalHeader
            activeTab="decision"
            applicationId={applicationId}
            investorView={investorView}
            editTimestampValue={editTimestampValue}
          />
          {loading ? (
            <View style={{ ...styles.container, alignItems: "center" }}>
              <LoadingIndicator />
            </View>
          ) : (
            <View style={styles.cardsContainer}>
              <View style={styles.card}>
                <TouchableWithoutFeedback
                  onPress={() => setIsBusinessOpen(!isBusinessOpen)}
                >
                  <View style={styles.flexRowBetween}>
                    <Text style={styles.cardHeader}>
                      {applicationId
                        ? "Business"
                        : "Business (no loan application selected)"}
                    </Text>
                    <Icon
                      style={[
                        styles.chevronButton,
                        {
                          transform: [
                            { rotate: isBusinessOpen ? "180deg" : "0deg" },
                          ],
                        },
                      ]}
                      name="chevron-down-outline"
                    />
                  </View>
                </TouchableWithoutFeedback>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isBusinessOpen}>
                  <Text style={styles.cardSection} appearance="hint">
                    BUSINESS INFORMATION
                  </Text>
                  <Divider style={styles.cardDivider} />
                  <TableDisplay
                    data={businessInformationData}
                    itemsPerRow={4}
                  />
                </Collapsible>
              </View>

              <View style={styles.card}>
                <TouchableWithoutFeedback
                  onPress={() => setIsFinalDecisionOpen(!isFinalDecisionOpen)}
                >
                  <View style={styles.flexRowBetween}>
                    <Text style={styles.cardHeader}>
                      {applicationId
                        ? "Final Decision"
                        : "Final Decision (no application selected)"}
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      {errors.error !== "" && (
                        <Text
                          style={{
                            ...styles.error,
                            paddingHorizontal: "1%",
                            paddingVertical: "3%",
                          }}
                        >
                          {errors.error}
                        </Text>
                      )}
                      {authStore.user?.isStaff && !investorView && editButton}
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: isFinalDecisionOpen ? "180deg" : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </View>
                  </View>
                </TouchableWithoutFeedback>
                <Divider style={styles.cardDivider} />
                <Text style={styles.cardSection} appearance="hint">
                  Unbilled Contract Amount
                </Text>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isFinalDecisionOpen}>
                  <TableDisplay
                    data={loanData}
                    itemsPerRow={5}
                    isEditing={isEditing}
                    rawData={editData}
                    setRawData={setEditData}
                  />
                </Collapsible>
                <Divider style={styles.cardDivider} />
                <View
                  style={{
                    ...styles.flexRow,
                    justifyContent: "flex-start",
                    zIndex: 1,
                  }}
                >
                  <Text style={styles.cardSection} appearance="hint">
                    Maximum Loan Amount{" "}
                    <TouchableOpacity
                      //@ts-ignore
                      onMouseEnter={() => setShowMaxLoanInfo(true)}
                      onMouseLeave={() => setShowMaxLoanInfo(false)}
                      onPress={() => setShowMaxLoanInfo(!showMaxLoanInfo)}
                    >
                      <Icon style={styles.infoIcon} name="info-outline" />
                    </TouchableOpacity>
                  </Text>
                  {showMaxLoanInfo && (
                    <Text style={styles.hoverHelpText}>
                      Loan amount computed based on applicant's average bank
                      balance
                    </Text>
                  )}
                </View>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isFinalDecisionOpen}>
                  <TableDisplay
                    data={maximumLoanData}
                    itemsPerRow={5}
                    isEditing={isEditing}
                    rawData={editData}
                    setRawData={setEditData}
                  />
                </Collapsible>
                <Divider style={styles.cardDivider} />
                <Text style={styles.cardSection} appearance="hint">
                  Underwriter's Final Decision
                </Text>
                <Divider style={styles.cardDivider} />
                <Collapsible collapsed={!isFinalDecisionOpen}>
                  {errors.systemFinalDecisionLoanAmount !== "" && (
                    <Text style={styles.error}>
                      {errors.systemFinalDecisionLoanAmount}
                    </Text>
                  )}
                  <TableDisplay
                    data={finalDecisionData}
                    itemsPerRow={5}
                    isEditing={isEditing}
                    rawData={editData}
                    setRawData={setEditData}
                  />
                  {isEditing && saveButton}
                </Collapsible>
              </View>

              <View style={styles.card}>
                <TouchableWithoutFeedback
                  onPress={() => setIsCovenantsOpen(!isCovenantsOpen)}
                >
                  <View style={styles.flexRowBetween}>
                    <Text style={styles.cardHeader}>
                      {applicationId
                        ? "Covenants"
                        : "Covenants (no loan application selected)"}
                      {"  "}
                      <TouchableOpacity
                        //@ts-ignore
                        onMouseEnter={() => setShowCovenantsInfo(true)}
                        onMouseLeave={() => setShowCovenantsInfo(false)}
                        onPress={() => setShowCovenantsInfo(!showCovenantsInfo)}
                      >
                        <Icon
                          style={styles.cardHeaderInfoIcon}
                          name="question-mark-circle-outline"
                        />
                      </TouchableOpacity>
                    </Text>
                    {showCovenantsInfo && (
                      <Text style={{ ...styles.hoverHelpText, left: 160 }}>
                        Covenants are financial benchmarks to assess the health
                        of a business. These will be available to add only
                        during the full under-writer review. <br />
                        Thresholds values can be adjusted for each covenant that
                        is selected.
                      </Text>
                    )}
                    <Icon
                      style={[
                        styles.chevronButton,
                        {
                          transform: [
                            { rotate: isCovenantsOpen ? "180deg" : "0deg" },
                          ],
                        },
                      ]}
                      name="chevron-down-outline"
                    />
                  </View>
                </TouchableWithoutFeedback>
                <Divider style={styles.cardDivider} />
                <View style={styles.setBack}>
                  <Collapsible collapsed={!isCovenantsOpen}>
                    <Text style={styles.covenantsSelectText}>SELECT</Text>
                    {covenantsData.map((covenantOption: any, idx: number) => (
                      <View
                        style={styles.covenantOptionContainer}
                        key={"covenant-option-" + idx}
                      >
                        <View style={styles.flexRowItemsCenter}>
                          <CheckBox
                            disabled={
                              disableCovenants ||
                              covenantsData[idx].alwaysChecked
                            }
                            style={styles.covenantCheckbox}
                            checked={
                              covenantsData[idx].checked ||
                              covenantsData[idx].alwaysChecked
                            }
                            onChange={() =>
                              setCovenantsData(
                                covenantsData.map((cov: any) =>
                                  cov.label === covenantOption.label
                                    ? {
                                        ...cov,
                                        checked:
                                          !cov.checked ||
                                          covenantsData[idx].alwaysChecked,
                                        threshold: "",
                                      }
                                    : cov
                                )
                              )
                            }
                          />
                          <Text
                            style={styles.covenantCheckboxLabel}
                            onPress={() =>
                              setCovenantsData(
                                covenantsData.map((cov: any) =>
                                  cov.label === covenantOption.label &&
                                  !disableCovenants
                                    ? {
                                        ...cov,
                                        checked:
                                          !cov.checked ||
                                          covenantsData[idx].alwaysChecked,
                                        threshold:
                                          !cov.checked ||
                                          covenantsData[idx].alwaysChecked
                                            ? covenantOption.threshold
                                            : "",
                                      }
                                    : cov
                                )
                              )
                            }
                          >
                            {covenantOption.label}
                          </Text>
                        </View>
                        <View style={styles.flexRowItemsCenter}>
                          <Input
                            style={styles.covenantInput}
                            placeholder="Threshold"
                            value={covenantsData[idx].threshold}
                            disabled={
                              !covenantsData[idx].checked &&
                              !covenantsData[idx].alwaysChecked
                            }
                            onChangeText={(text) =>
                              setCovenantsData(
                                covenantsData.map((cov: any) =>
                                  cov.label === covenantOption.label
                                    ? { ...cov, threshold: text }
                                    : cov
                                )
                              )
                            }
                          />
                          <OnMouseClickTooltip text={covenantOption.info} />
                        </View>
                      </View>
                    ))}
                    {authStore.user?.isStaff && !investorView && (
                      <Button
                        disabled={disableCovenants}
                        style={styles.covenantSubmit}
                        appearance="outline"
                        onPress={onSubmitCovenants}
                      >
                        SUBMIT
                      </Button>
                    )}
                  </Collapsible>
                </View>
              </View>

              <TouchableOpacity
                style={styles.finalDecisionModalButton}
                onPress={() => setIsAmortizationScheduleModalOpen(true)}
              >
                <Text style={styles.finalDecisionModalButtonText}>
                  GENERATE AMORTIZATION SCHEDULE
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </ScrollView>
        <AmortizationScheduleModal
          close={() => setIsAmortizationScheduleModalOpen(false)}
          isOpen={isAmortizationScheduleModalOpen}
          applicationId={applicationId}
        />
      </Layout>
    </ThemeProvider>
  );
};

export default Decision;
