import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },

  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
  },
  tooltipTextDark: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "black",
  },
  infoIcon: {
    width: 20,
    height: 20,
    position: "relative",
    paddingHorizontal: 10,
  },
});
