import { CheckBox, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";

import { themedStyles } from "./themedStyles";

export const ContractorTypeStep: React.FC<any> = ({
  formData,
  setFormData,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const [isPrime, setIsPrime] = useState(false);
  const [isSubcontractor, setIsSubcontractor] = useState(false);

  useEffect(() => {
    if (isPrime) {
      setFormData({ ...formData, contractorType: "prime" });
    } else {
      setFormData({ ...formData, contractorType: "subcontractor" });
    }
  }, [isPrime]);

  useEffect(() => {
    if (isSubcontractor) {
      setFormData({ ...formData, wantsToBePrime: "yes" });
    } else {
      setFormData({ ...formData, wantsToBePrime: "no" });
    }
  }, [isSubcontractor]);

  useEffect(() => {
    if (formData.contractorType === "prime") {
      setIsPrime(true);
    }
    if (formData.wantsToBePrime === "yes") {
      setIsSubcontractor(true);
    }
  }, [formData]);

  return (
    <View>
      <Text style={styles.inputLabel}>
        What type of government contractor are you? Select all that apply.
      </Text>
      <View
        style={
          width > 992
            ? styles.formQuestionButtonsContainer
            : styles.formQuestionButtonsContainerMobile
        }
      >
        <CheckBox
          style={styles.checkbox}
          checked={isPrime}
          onChange={() => setIsPrime(!isPrime)}
          children={() => (
            <Text style={{ ...styles.inputText, paddingLeft: 10 }}>PRIME</Text>
          )}
        />
        <CheckBox
          style={styles.checkbox}
          checked={isSubcontractor}
          onChange={() => setIsSubcontractor(!isSubcontractor)}
          children={() => (
            <Text style={{ ...styles.inputText, paddingLeft: 10 }}>
              SUBCONTRACTOR
            </Text>
          )}
        />
      </View>

      {/* Commenting out "Do you want to become a prime contractor" part */}
      {/* {formData.contractorType === "subcontractor" && (
        <>
          <Text style={styles.inputLabel}>
            Do you want to be a Prime contractor?
          </Text>
          <View style={styles.formQuestionButtonsContainer}>
            <Button
              style={
                formData.wantsToBePrime === "yes"
                  ? styles.questionButtonSelected
                  : styles.questionButton
              }
              appearance={
                formData.wantsToBePrime === "yes" ? "filled" : "outline"
              }
              onPress={() =>
                setFormData({ ...formData, wantsToBePrime: "yes" })
              }
              children={() => (
                <Text
                  style={
                    formData.wantsToBePrime === "yes"
                      ? styles.buttonTextSelected
                      : styles.buttonText
                  }
                >
                  yes
                </Text>
              )}
            />
            <Button
              style={
                formData.wantsToBePrime === "no"
                  ? styles.questionButtonSelected
                  : styles.questionButton
              }
              appearance={
                formData.wantsToBePrime === "no" ? "filled" : "outline"
              }
              onPress={() => setFormData({ ...formData, wantsToBePrime: "no" })}
              children={() => (
                <Text
                  style={
                    formData.wantsToBePrime === "no"
                      ? styles.buttonTextSelected
                      : styles.buttonText
                  }
                >
                  no
                </Text>
              )}
            />
          </View>
        </>
      )} */}
    </View>
  );
};
