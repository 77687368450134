import { useLinkTo } from "@react-navigation/native";
import { Button, Text, useStyleSheet, Icon } from "@ui-kitten/components";
import * as Linking from "expo-linking";
import React from "react";
import { View } from "react-native";

import { themedStyles } from "./themedStyles";

const VerifyResetPasswordEmail: React.FC<{
  onContinue: Function;
  isAdminReset: boolean;
  isInvestorReset: boolean;
}> = ({ onContinue, isAdminReset = false, isInvestorReset = false }) => {
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );

  return (
    <View style={styles.resetPassword}>
      <Text
        style={{
          ...styles.header,
          ...(isAdminReset || isInvestorReset ? { color: "#222B45" } : {}),
        }}
      >
        Check your email.
      </Text>
      <Text
        style={{
          ...styles.subHeader,
          ...(isAdminReset || isInvestorReset ? { color: "#222B45" } : {}),
        }}
      >
        Go back to{" "}
        <Text
          status="info"
          style={styles.link}
          onPress={() => {
            if (isAdminReset) linkTo("/admin/log-in");
            else if (isInvestorReset) linkTo("/investor/log-in");
            else linkTo("/auth/log-in");
          }}
        >
          Sign In
        </Text>
        .
      </Text>
      <Text
        style={{
          ...styles.subHeader,
          ...(isAdminReset || isInvestorReset ? { color: "#222B45" } : {}),
        }}
      >
        {"\n\n"}
        We have sent a password recover instructions to your email.
      </Text>

      <View style={styles.flexRow}>
        <Button
          style={{ ...styles.resetPasswordButton, marginTop: 0 }}
          status="basic"
          onPress={() => Linking.openURL("https://mail.google.com/")}
          accessoryRight={ArrowIcon}
          children={() => (
            <Text style={styles.resetPasswordButtonText}>Open email{"  "}</Text>
          )}
        />
        <Button
          style={{
            ...styles.skipButton,
            backgroundColor:
              isAdminReset || isInvestorReset ? "#8F9BB3" : "#222B45",
          }}
          status="basic"
          onPress={() => {
            if (isAdminReset) linkTo("/admin/log-in");
            else if (isInvestorReset) linkTo("/investor/log-in");
            else linkTo("/auth/log-in");
          }}
          children={() => (
            <Text style={styles.resetPasswordButtonText}>
              Skip, I'll confirm later
            </Text>
          )}
        />
      </View>
      <Text style={styles.text}>
        Did not receive the email? Check your spam filter or{" "}
        <Text
          status="info"
          style={{ ...styles.link, fontSize: 15 }}
          onPress={() => onContinue(false)}
        >
          try another email address
        </Text>
        .
      </Text>
    </View>
  );
};

export default VerifyResetPasswordEmail;
