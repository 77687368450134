import * as eva from "@eva-design/eva";
import {
  Text,
  useStyleSheet,
  Modal,
  Card,
  CheckBox,
  ThemeProvider,
} from "@ui-kitten/components";
import React from "react";
import { View, ScrollView } from "react-native";

import {
  possibleTypes,
  possibleLocations,
  possibleStatus,
  possibleDecisionStatus,
  SORT,
} from "./index";
import { themedStyles } from "./themedStyles";

export const FilterOptionsModal: React.FC<any> = ({
  isOptionsModalOpen,
  setIsOptionsModalOpen,
  filterOptions,
  setFilterOptions,
  sortingMode,
  setSortingMode,
}) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <Modal
      visible={isOptionsModalOpen}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => setIsOptionsModalOpen(false)}
    >
      <ThemeProvider theme={eva.light}>
        <Card disabled>
          <ScrollView style={{ flex: 1, maxHeight: 600 }}>
            <View style={styles.optionsModal}>
              <View style={styles.optionsModalSection}>
                <Text style={styles.optionsHeader}>Filter</Text>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={filterOptions.byType}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      byType: !filterOptions.byType,
                    })
                  }
                >
                  Business Type
                </CheckBox>
                {filterOptions.byType && (
                  <View>
                    {possibleTypes.map((typeOption) => (
                      <CheckBox
                        key={typeOption}
                        style={styles.filterSubCheckbox}
                        checked={filterOptions.type.includes(typeOption)}
                        onChange={() => {
                          setFilterOptions({
                            ...filterOptions,
                            type: filterOptions.type.includes(typeOption)
                              ? filterOptions.type.filter(
                                  (type: any) => type !== typeOption
                                )
                              : [...filterOptions.type, typeOption],
                          });
                        }}
                      >
                        {typeOption}
                      </CheckBox>
                    ))}
                  </View>
                )}

                <CheckBox
                  style={styles.filterCheckbox}
                  checked={filterOptions.byLocation}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      byLocation: !filterOptions.byLocation,
                    })
                  }
                >
                  Business Location
                </CheckBox>
                {filterOptions.byLocation && (
                  <View>
                    {possibleLocations.map((loc) => (
                      <CheckBox
                        key={loc}
                        style={styles.filterSubCheckbox}
                        checked={filterOptions.location.includes(loc)}
                        onChange={() => {
                          setFilterOptions({
                            ...filterOptions,
                            location: filterOptions.location.includes(loc)
                              ? filterOptions.location.filter(
                                  (location: any) => location !== loc
                                )
                              : [...filterOptions.location, loc],
                          });
                        }}
                      >
                        {loc}
                      </CheckBox>
                    ))}
                  </View>
                )}
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={filterOptions.byStatus}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      byStatus: !filterOptions.byStatus,
                    })
                  }
                >
                  Status
                </CheckBox>
                {filterOptions.byStatus && (
                  <View>
                    {possibleStatus.map((statusOption) => (
                      <CheckBox
                        key={statusOption}
                        style={styles.filterSubCheckbox}
                        checked={filterOptions.status.includes(statusOption)}
                        onChange={() => {
                          setFilterOptions({
                            ...filterOptions,
                            status: filterOptions.status.includes(statusOption)
                              ? filterOptions.status.filter(
                                  (status: any) => status !== statusOption
                                )
                              : [...filterOptions.status, statusOption],
                          });
                          console.log(filterOptions);
                        }}
                      >
                        {statusOption}
                      </CheckBox>
                    ))}
                  </View>
                )}
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={filterOptions.byDecisionStatus}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      byDecisionStatus: !filterOptions.byDecisionStatus,
                    })
                  }
                >
                  Decision
                </CheckBox>
                {filterOptions.byDecisionStatus && (
                  <View>
                    {possibleDecisionStatus.map((statusOption) => (
                      <CheckBox
                        key={statusOption}
                        style={styles.filterSubCheckbox}
                        checked={filterOptions.decisionStatus.includes(
                          statusOption
                        )}
                        onChange={() => {
                          setFilterOptions({
                            ...filterOptions,
                            decisionStatus:
                              filterOptions.decisionStatus.includes(
                                statusOption
                              )
                                ? filterOptions.decisionStatus.filter(
                                    (status: any) => status !== statusOption
                                  )
                                : [
                                    ...filterOptions.decisionStatus,
                                    statusOption,
                                  ],
                          });
                          console.log(filterOptions);
                        }}
                      >
                        {statusOption}
                      </CheckBox>
                    ))}
                  </View>
                )}
              </View>
              <View style={styles.verticalDivider} />
              <View style={styles.optionsModalSection}>
                <Text style={styles.optionsHeader}>Sort</Text>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={sortingMode === SORT.OLD}
                  onChange={(nextChecked) => setSortingMode(SORT.OLD)}
                >
                  Oldest First
                </CheckBox>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={sortingMode === SORT.NEW}
                  onChange={(nextChecked) => setSortingMode(SORT.NEW)}
                >
                  Newest First
                </CheckBox>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={sortingMode === SORT.UPDATED_INC}
                  onChange={(nextChecked) => setSortingMode(SORT.UPDATED_INC)}
                >
                  Oldest Updated First
                </CheckBox>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={sortingMode === SORT.UPDATED_DEC}
                  onChange={(nextChecked) => setSortingMode(SORT.UPDATED_DEC)}
                >
                  Latest Updated First
                </CheckBox>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={sortingMode === SORT.ALPHABET_INC}
                  onChange={(nextChecked) => setSortingMode(SORT.ALPHABET_INC)}
                >
                  Alphabetically Ascending
                </CheckBox>
                <CheckBox
                  style={styles.filterCheckbox}
                  checked={sortingMode === SORT.ALPHABET_DEC}
                  onChange={(nextChecked) => setSortingMode(SORT.ALPHABET_DEC)}
                >
                  Alphabetically Descending
                </CheckBox>
              </View>
            </View>
          </ScrollView>
        </Card>
      </ThemeProvider>
    </Modal>
  );
};
