import {
  Text,
  useStyleSheet,
  Input,
  Datepicker,
  CalendarViewModes,
  Icon,
  NativeDateService,
} from "@ui-kitten/components";
import React, { useRef } from "react";
import { View } from "react-native";

import { themedStyles } from "./themedStyles";
import { formatNumberWithCommas } from "../../utils/index";
import { MAX_INTEGER_VALUE } from "../../utils/constants";

export const BusinessInformationPrequalStep: React.FC<any> = ({
  formData,
  setFormData,
  errorMessages,
}) => {
  const styles = useStyleSheet(themedStyles);

  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar-outline" />
  );

  const formatDateService = new NativeDateService("en", {
    format: "MM.DD.YYYY",
  });
  const annualRevenueRef = useRef<Input>(null);
  const businessStartDateRef = useRef<Datepicker>(null);

  return (
    <View>
      <Text style={styles.inputLabel}>Annual Revenue</Text>
      <Text>
        {errorMessages?.annualRevenue && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.annualRevenue}
          </Text>
        )}
      </Text>
      <Input
        ref={annualRevenueRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Input Annual Revenue"
        keyboardType="decimal-pad"
        autoCapitalize="none"
        autoCorrect={false}
        value={formatNumberWithCommas(formData.annualRevenue)}
        onChangeText={(text) => {
          const number = text.replace(/,/g, "");
          setFormData({
            ...formData,
            annualRevenue:
              Number.isNaN(Number(number)) ||
              Number(number) >= MAX_INTEGER_VALUE
                ? formData.annualRevenue
                : number,
          });
        }}
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            businessStartDateRef.current?.focus();
          }
        }}
        onSubmitEditing={() => businessStartDateRef.current?.focus()}
        returnKeyType="next"
        testID="annualRevenueField"
      />

      <Text style={styles.inputLabel}>When did you start your business?</Text>
      <Text>
        {errorMessages?.yearsInBusiness && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.yearsInBusiness}
          </Text>
        )}
      </Text>
      <Datepicker
        ref={businessStartDateRef}
        style={styles.input}
        accessoryRight={CalendarIcon}
        dateService={formatDateService}
        placeholder={() => (
          <Text style={styles.datePickerPlaceholderText}>
            Business Start Date
          </Text>
        )}
        startView={CalendarViewModes.YEAR}
        date={formData.businessStartDate}
        min={new Date("1900-01-01")}
        onSelect={(nextDate) =>
          setFormData({ ...formData, businessStartDate: nextDate })
        }
      />
    </View>
  );
};
