import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";

const ConsumerCreditReportAuthorization: React.FC<any> = (props) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.authorizationContainer}>
          <View style={styles.authorization}>
            <Text style={{ ...styles.paragraph, ...{ textIndent: 0 } }}>
              Please read this Authorization carefully and print or save a copy
              for future reference.{"\n\n"}
            </Text>
            <Text style={styles.paragraph}>
              In this Credit Report Authorization, the words “you” and “your”
              mean any person authorizing us to obtain and use such person’s
              credit report. The words “we,” “us,” “our,” and “OppZo” mean the
              creditor, OppZo, and our service providers.
            </Text>
            <Text style={styles.paragraph}>
              Pursuant to this Authorization, you authorize OppZo to obtain your
              credit report for any lawful purpose, including but not limited
              to: authenticating your identity; making credit decisions;
              assisting with internal modeling and analysis; and servicing. You
              authorize and agree that OppZo may transfer or otherwise provide
              your credit report to any successor in interest to OppZo.
            </Text>
            <Text style={styles.paragraph}>
              By selecting the checkbox, displayed in connection with
              acknowledging and/or agreeing to this Authorization, you agree
              that you have received, read, and agree to all the terms of this
              Authorization; and you authorize us to obtain and use credit
              reports from one or more consumer reporting agencies, now and
              periodically for as long as any loan for which you are directly or
              indirectly obligated remains outstanding.
            </Text>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default ConsumerCreditReportAuthorization;
