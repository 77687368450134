import * as eva from "@eva-design/eva";
import {
  Inter_100Thin,
  Inter_200ExtraLight,
  Inter_300Light,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/inter";
import {
  useFonts,
  Montserrat_100Thin,
  Montserrat_200ExtraLight,
  Montserrat_300Light,
  Montserrat_400Regular,
  Montserrat_500Medium,
  Montserrat_600SemiBold,
  Montserrat_700Bold,
  Montserrat_800ExtraBold,
  Montserrat_900Black,
} from "@expo-google-fonts/montserrat";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
} from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ToastProvider } from "react-native-toast-notifications";

import { default as mapping } from "./mapping.json";
import Seo from "./src/components/Seo";
import { useInit } from "./src/hooks";
import AppContainer from "./src/navigators";
import { StoreProvider } from "./src/stores";
import { ThemeContext } from "./theme-context";

export default function App() {
  const { store, ready } = useInit();

  const [fontsLoaded] = useFonts({
    SFProTextRegular: require("./assets/fonts/SFProText-Regular.ttf"),
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
    Montserrat_100Thin,
    Montserrat_200ExtraLight,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Montserrat_800ExtraBold,
    Montserrat_900Black,
  });

  const [theme, setTheme] = useState("light");

  const resetTheme = () => {
    const route = window.location.pathname.split("/");
    const onLightThemeRoute =
      route.length > 1 &&
      (route[1] === "investor" ||
        route[1] === "admin" ||
        route[1] === "underwriter" ||
        route[1] === "loan-servicing");
    const nextTheme = onLightThemeRoute ? "light" : "dark";
    console.log(nextTheme);
    setTheme(nextTheme);
  };

  useEffect(() => {
    resetTheme();
  });

  if (!ready || !store || !(fontsLoaded || Platform.OS === "web")) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <ToastProvider>
        <Seo />
        <IconRegistry icons={EvaIconsPack} />
        <StatusBar style="auto" />
        <ThemeContext.Provider value={{ theme, resetTheme }}>
          <ApplicationProvider
            {...eva}
            /* @ts-ignore */
            customMapping={mapping}
            /* @ts-ignore */
            theme={eva[theme]}
          >
            <SafeAreaProvider>
              <StoreProvider value={store}>
                <KeyboardAvoidingView
                  style={styles.container}
                  behavior={Platform.OS === "ios" ? "padding" : undefined}
                >
                  <ActionSheetProvider>
                    <AppContainer />
                  </ActionSheetProvider>
                </KeyboardAvoidingView>
              </StoreProvider>
            </SafeAreaProvider>
          </ApplicationProvider>
        </ThemeContext.Provider>
      </ToastProvider>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
