import { observable } from "mobx";
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  modelAction,
  prop,
  objectMap,
  ModelCreationData,
} from "mobx-keystone";

import Notification from "../models/Notification";
import * as api from "../services/api";
import Store from "./Store";

type Extra = { [key: string]: any } | null;
type Response = { errors: any; ok: boolean; extra: Extra };

const getError = (error: Error): Response => {
  try {
    return { errors: JSON.parse(error.message), ok: false, extra: null };
  } catch {
    return { errors: { detail: error.message }, ok: false, extra: null };
  }
};

const _success = { errors: { detail: null }, ok: true, extra: null };
const getSuccess = (extra?: Extra): Response =>
  extra ? { ..._success, extra } : _success;

@model("oppzo/Notification")
export default class NotificationsStore extends Model({
  notifications: prop(() => objectMap<Notification>()),
}) {
  @observable
  loading = false;
  lastNotificationsFetchTime = 0;
  NOTIFICATIONS_FETCH_FREQUENCY = 5 * 60 * 1000;

  @modelAction
  createNotification(data: ModelCreationData<Notification>) {
    const id = `${data.id}`;

    if (!this.notifications.has(id)) {
      const notification = new Notification(data);
      this.notifications.set(id, notification);
      notification.update(data);
    }
  }

  @modelAction
  removeNotification(id: number) {
    this.notifications.delete(`${id}`);
  }

  @modelFlow
  markNotificationAsRead = _async(function* (
    this: NotificationsStore,
    id: number
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    try {
      this.removeNotification(id);
      yield* _await(api.markNotificationAsRead(token, id));
    } catch (error) {
      console.warn("[DEBUG] error updating Notifications", error);
      this.loading = false;
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchNotifications = _async(function* (this: NotificationsStore) {
    const rootStore = getRoot<Store>(this);

    if (
      Number(new Date()) - this.lastNotificationsFetchTime <
        this.NOTIFICATIONS_FETCH_FREQUENCY ||
      !rootStore?.authStore?.accessToken
    )
      return;

    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;
    this.lastNotificationsFetchTime = Number(new Date());

    let results: ModelCreationData<Notification>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchNotifications(token)));
    } catch (error) {
      console.warn("[DEBUG] error fetching Notifications", error);
      return getError(error);
    }
    results.forEach((data) => this.createNotification(data));

    this.loading = false;
    return getSuccess();
  });
}
