import { useLinkTo } from "@react-navigation/native";
import {
  Text,
  useStyleSheet,
  Icon,
  IndexPath,
  Input,
  Button,
  Spinner,
  Select,
  SelectItem,
  CheckBox,
  Modal,
} from "@ui-kitten/components";
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  createRef,
} from "react";
import { View, Dimensions, TouchableOpacity } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import ListInput from "../../components/ListInput";
import { checkMboListExistence } from "../../services/api";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const PartnerInformationStep: React.FC<any> = ({
  currentQuestionID,
  setCurrentQuestionID,
  loading,
  formData,
  setFormData,
  onSubmitForm,
  errorMessages,
  tempFirstName,
  tempLastName,
  tempEmail,
  setTempFirstName,
  setTempLastName,
  setTempEmail,
  sameAddressForLoan,
  setSameAddressForLoan,
  tradeNamesNotApplicable,
  setTradeNamesNotApplicable,
  submitActionButtonIsDisabled,
}) => {
  const { width } = Dimensions.get("window");
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);

  const RightArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LeftArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-back-outline" />
  );
  const BriefcaseIcon = (props: any) => (
    <Icon {...props} name="briefcase-outline" />
  );
  const CloudUploadIcon = (props: any) => (
    <Icon {...props} fill="white" name="cloud-upload-outline" />
  );
  const PlusIcon = (props: any) => <Icon {...props} name="plus-outline" />;
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const CloseIcon = (props: any) => <Icon {...props} name="close-outline" />;
  const LocationIcon = (props: any) => <Icon {...props} name="pin-outline" />;
  const QuestionIcon = (props: any) => (
    <TouchableOpacity
      //@ts-ignore
      onMouseEnter={() => setShowTradeInfo(true)}
      onMouseLeave={() => setShowTradeInfo(false)}
    >
      <Icon {...props} name="question-mark-circle-outline" />
    </TouchableOpacity>
  );

  const tempFirstNameRef = useRef<Input>(null);
  const tempLastNameRef = useRef<Input>(null);
  const tempEmailRef = useRef<Input>(null);

  const [showTradeInfo, setShowTradeInfo] = useState(false);
  const [showDomainEmailError, setShowDomainEmailError] = useState(false);
  const [showEmailExistsError, setShowEmailExistsError] = useState(false);
  const [showIncompleteError, setShowIncompleteError] = useState(false);
  const [onSubmitFormButton, setOnSubmitFormButton] = useState(false);
  const [firstNameRefs, setFirstNameRefs] = useState([]);
  const [lastNameRefs, setLastNameRefs] = useState([]);
  const [emailRefs, setEmailRefs] = useState([]);

  const isNextActionButtonDisabled = useCallback(() => {
    return (
      formData.isPrimaryApplicant === null ||
      (formData.isPrimaryApplicant && formData.otherBusinessOwners == null) ||
      (formData.isPrimaryApplicant &&
        formData.otherBusinessOwners &&
        formData.ownTwentyPercent == null) ||
      (formData.ownTwentyPercent &&
        formData.isPrimaryApplicant &&
        formData.otherBusinessOwners &&
        formData.businessPartners.length === 0 &&
        isPlusButtonDisabled())
    );
  }, [formData, tempFirstName, tempLastName, tempEmail]);

  const isPlusButtonDisabled = useCallback(() => {
    return tempFirstName === "" || tempLastName === "" || tempEmail === "";
  }, [tempFirstName, tempLastName, tempEmail]);

  const deleteMbo = (e: any, index: number) => {
    let newFormData = { ...formData };
    let temp = [...formData.businessPartners];
    temp.splice(index, 1);
    setFormData({ ...newFormData, businessPartners: temp });
  };

  const addMbo = async () => {
    await setFormData(() => {
      let newFormData = { ...formData };
      let temp = [...formData.businessPartners];
      temp.push({
        firstName: tempFirstName,
        lastName: tempLastName,
        email: tempEmail,
      });
      return { ...newFormData, businessPartners: temp };
    });
    setTempEmail("");
    setTempFirstName("");
    setTempLastName("");
  };

  useEffect(() => {
    const length = formData.businessPartners.length;
    setFirstNameRefs((refs) =>
      Array(length)
        // @ts-ignore
        .fill()
        .map((_, i) => refs[i] || createRef())
    );
    setLastNameRefs((refs) =>
      Array(length)
        // @ts-ignore
        .fill()
        .map((_, i) => refs[i] || createRef())
    );
    setEmailRefs((refs) =>
      Array(length)
        // @ts-ignore
        .fill()
        .map((_, i) => refs[i] || createRef())
    );
  }, [formData.businessPartners.length]);

  useEffect(() => {
    setShowDomainEmailError(false);
    setShowEmailExistsError(false);
    setShowIncompleteError(false);
  }, [formData.businessPartners, tempEmail, tempFirstName, tempLastName]);

  return (
    <View style={width > 992 ? styles.register : styles.registerMobile}>
      <Text style={width > 576 ? styles.header : styles.headerMobile}>
        Add your business details
      </Text>
      <Text style={width > 576 ? styles.subHeader : styles.subHeaderMobile}>
        Already registered?{" "}
        <Text
          status="info"
          style={
            width > 576
              ? {
                  ...styles.link,
                  fontSize: 18,
                  fontFamily: "Montserrat_500Medium",
                }
              : {
                  ...styles.link,
                  fontSize: 13,
                  fontFamily: "Montserrat_500Medium",
                }
          }
          onPress={() => linkTo("/auth/log-in")}
        >
          Login
        </Text>
        .
      </Text>

      <Text style={{ marginBottom: 40 }}>
        {errorMessages?.nonFieldErrors && (
          <Text
            category="h6"
            style={width > 576 ? styles.error : styles.errorMobile}
          >
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>

      <Text style={styles.question}>Are you the primary applicant?</Text>
      <View style={styles.questionButtonsContainer}>
        <Button
          style={
            formData.isPrimaryApplicant === true
              ? styles.questionButtonSelected
              : styles.questionButton
          }
          appearance={
            formData.isPrimaryApplicant === true ? "filled" : "outline"
          }
          onPress={() => setFormData({ ...formData, isPrimaryApplicant: true })}
          children={() => (
            <Text
              style={
                formData.isPrimaryApplicant === true
                  ? styles.buttonTextSelected
                  : styles.buttonText
              }
            >
              yes
            </Text>
          )}
        />
        <Button
          style={
            formData.isPrimaryApplicant === false
              ? styles.questionButtonSelected
              : styles.questionButton
          }
          appearance={
            formData.isPrimaryApplicant === false ? "filled" : "outline"
          }
          onPress={() =>
            setFormData({ ...formData, isPrimaryApplicant: false })
          }
          children={() => (
            <Text
              style={
                formData.isPrimaryApplicant === false
                  ? styles.buttonTextSelected
                  : styles.buttonText
              }
            >
              no
            </Text>
          )}
        />
      </View>
      {formData.isPrimaryApplicant && (
        <>
          <Text style={styles.question}>Are there other business owners?</Text>
          <View style={styles.questionButtonsContainer}>
            <Button
              style={
                formData.otherBusinessOwners === true
                  ? styles.questionButtonSelected
                  : styles.questionButton
              }
              appearance={
                formData.otherBusinessOwners === true ? "filled" : "outline"
              }
              onPress={() =>
                setFormData({ ...formData, otherBusinessOwners: true })
              }
              children={() => (
                <Text
                  style={
                    formData.otherBusinessOwners === true
                      ? styles.buttonTextSelected
                      : styles.buttonText
                  }
                >
                  yes
                </Text>
              )}
            />
            <Button
              style={
                formData.otherBusinessOwners === false
                  ? styles.questionButtonSelected
                  : styles.questionButton
              }
              appearance={
                formData.otherBusinessOwners === false ? "filled" : "outline"
              }
              onPress={() =>
                setFormData({ ...formData, otherBusinessOwners: false })
              }
              children={() => (
                <Text
                  style={
                    formData.otherBusinessOwners === false
                      ? styles.buttonTextSelected
                      : styles.buttonText
                  }
                >
                  no
                </Text>
              )}
            />
          </View>
        </>
      )}
      {formData.otherBusinessOwners && formData.isPrimaryApplicant && (
        <>
          <Text style={styles.question}>
            Do they own 20% or more of the company?
          </Text>
          <View style={styles.questionButtonsContainer}>
            <Button
              style={
                formData.ownTwentyPercent === true
                  ? styles.questionButtonSelected
                  : styles.questionButton
              }
              appearance={
                formData.ownTwentyPercent === true ? "filled" : "outline"
              }
              onPress={() =>
                setFormData({ ...formData, ownTwentyPercent: true })
              }
              children={() => (
                <Text
                  style={
                    formData.ownTwentyPercent === true
                      ? styles.buttonTextSelected
                      : styles.buttonText
                  }
                >
                  yes
                </Text>
              )}
            />
            <Button
              style={
                formData.ownTwentyPercent === false
                  ? styles.questionButtonSelected
                  : styles.questionButton
              }
              appearance={
                formData.ownTwentyPercent === false ? "filled" : "outline"
              }
              onPress={() =>
                setFormData({ ...formData, ownTwentyPercent: false })
              }
              children={() => (
                <Text
                  style={
                    formData.ownTwentyPercent === false
                      ? styles.buttonTextSelected
                      : styles.buttonText
                  }
                >
                  no
                </Text>
              )}
            />
          </View>
        </>
      )}

      {formData.otherBusinessOwners &&
        formData.isPrimaryApplicant &&
        formData.ownTwentyPercent && (
          <>
            <Text style={styles.question}>
              Please enter their email addresses
            </Text>
            {showEmailExistsError && (
              <Text
                category="h6"
                style={width > 576 ? styles.error : styles.errorMobile}
              >
                {
                  "One or more of the email addresses entered either has an account or has been listed as a business partner already."
                }
              </Text>
            )}
            {showDomainEmailError && (
              <Text
                category="h6"
                style={width > 576 ? styles.error : styles.errorMobile}
              >
                {
                  "One or more of the email addresses entered have domains which do not match yours. We will only allow email addresses which match domains with you. "
                }
              </Text>
            )}
            {showIncompleteError && (
              <Text
                category="h6"
                style={width > 576 ? styles.error : styles.errorMobile}
              >
                {"Please fill up the last row completely, or leave it blank."}
              </Text>
            )}
            <View>
              {formData.businessPartners.map((partner: any, index: number) => (
                <React.Fragment key={`mbo-${index}`}>
                  <View
                    style={width > 576 && styles.flexRow}
                    key={`mbo-row-${index}`}
                  >
                    <View style={{ flex: 2 }} key={`mbo-fn-${index}`}>
                      <Input
                        key={`mbo-fn-input-${index}`}
                        ref={firstNameRefs[index]}
                        style={width > 576 ? styles.input : styles.inputMobile}
                        textStyle={
                          width > 576
                            ? styles.inputText
                            : styles.inputTextMobile
                        }
                        placeholder="First Name"
                        autoCapitalize="words"
                        autoCorrect={false}
                        textContentType="name"
                        autoCompleteType="off"
                        value={formData.businessPartners[index].firstName}
                        onChangeText={(text) =>
                          setFormData(() => {
                            let newFormData = { ...formData };
                            let temp = [...formData.businessPartners];
                            temp[index].firstName = text;
                            return { ...newFormData, businessPartners: temp };
                          })
                        }
                        onKeyPress={(event: any) => {
                          if (event.keyCode === 9) {
                            event.preventDefault();
                            // @ts-ignore
                            lastNameRefs[index].current?.focus();
                          }
                        }}
                        onSubmitEditing={() =>
                          // @ts-ignore
                          lastNameRefs[index].current?.focus()
                        }
                        returnKeyType="next"
                        testID="FirstNameField"
                      />
                    </View>
                    <View style={{ flex: 2 }} key={`mbo-ln-${index}`}>
                      <Input
                        key={`mbo-ln-input-${index}`}
                        ref={lastNameRefs[index]}
                        style={width > 576 ? styles.input : styles.inputMobile}
                        textStyle={
                          width > 576
                            ? styles.inputText
                            : styles.inputTextMobile
                        }
                        placeholder="Last Name"
                        autoCapitalize="words"
                        autoCorrect={false}
                        textContentType="name"
                        autoCompleteType="off"
                        value={formData.businessPartners[index].lastName}
                        onChangeText={(text) =>
                          setFormData(() => {
                            let newFormData = { ...formData };
                            let temp = [...formData.businessPartners];
                            temp[index].lastName = text;
                            return { ...newFormData, businessPartners: temp };
                          })
                        }
                        onKeyPress={(event: any) => {
                          if (event.keyCode === 9) {
                            event.preventDefault();
                            // @ts-ignore
                            emailRefs[index].current?.focus();
                          }
                        }}
                        onSubmitEditing={() =>
                          // @ts-ignore
                          emailRefs[index].current?.focus()
                        }
                        returnKeyType="next"
                        testID="LastNameField"
                      />
                    </View>
                    <View style={{ flex: 4 }} key={`mbo-email-${index}`}>
                      <Input
                        key={`mbo-email-input-${index}`}
                        ref={emailRefs[index]}
                        style={width > 576 ? styles.input : styles.inputMobile}
                        textStyle={
                          width > 576
                            ? styles.inputText
                            : styles.inputTextMobile
                        }
                        placeholder="Email"
                        autoCapitalize="words"
                        autoCorrect={false}
                        textContentType="name"
                        autoCompleteType="off"
                        accessoryRight={EmailIcon}
                        value={formData.businessPartners[index].email}
                        onChangeText={(text) =>
                          setFormData(() => {
                            let newFormData = { ...formData };
                            let temp = [...formData.businessPartners];
                            temp[index].email = text;
                            return { ...newFormData, businessPartners: temp };
                          })
                        }
                        onKeyPress={(event: any) => {
                          if (event.keyCode === 9) {
                            event.preventDefault();
                            if (index < formData.businessPartners.length - 1) {
                              // @ts-ignore
                              firstNameRefs[index + 1].current?.focus();
                            } else {
                              // @ts-ignore
                              firstNameRefs[0].current?.focus();
                            }
                          }
                        }}
                        onSubmitEditing={() => {
                          if (index < formData.businessPartners.length - 1) {
                            // @ts-ignore
                            firstNameRefs[index + 1].current?.focus();
                          } else {
                            // @ts-ignore
                            firstNameRefs[0].current?.focus();
                          }
                        }}
                        returnKeyType="next"
                        testID="EmailField"
                      />
                    </View>
                    <View style={{ flex: 1 }} key={`mbo-btn-${index}`}>
                      <Button
                        key={`mbo-btn-btn-${index}`}
                        style={styles.plusButton}
                        status="basic"
                        onPress={(e) => deleteMbo(e, index)}
                        accessoryRight={CloseIcon}
                      />
                    </View>
                  </View>
                </React.Fragment>
              ))}
            </View>
            <View style={width > 576 && styles.flexRow}>
              <View style={{ flex: 2 }}>
                <Input
                  ref={tempFirstNameRef}
                  style={width > 576 ? styles.input : styles.inputMobile}
                  textStyle={
                    width > 576 ? styles.inputText : styles.inputTextMobile
                  }
                  placeholder="First Name"
                  autoCapitalize="words"
                  autoCorrect={false}
                  textContentType="name"
                  autoCompleteType="off"
                  value={tempFirstName}
                  onChangeText={(text) => setTempFirstName(text)}
                  onKeyPress={(event: any) => {
                    if (event.keyCode === 9) {
                      event.preventDefault();
                      tempLastNameRef.current?.focus();
                    }
                  }}
                  onSubmitEditing={() => tempLastNameRef.current?.focus()}
                  returnKeyType="next"
                  testID="FirstNameField"
                />
              </View>
              <View style={{ flex: 2 }}>
                <Input
                  ref={tempLastNameRef}
                  style={width > 576 ? styles.input : styles.inputMobile}
                  textStyle={
                    width > 576 ? styles.inputText : styles.inputTextMobile
                  }
                  placeholder="Last Name"
                  autoCapitalize="words"
                  autoCorrect={false}
                  textContentType="name"
                  autoCompleteType="off"
                  value={tempLastName}
                  onChangeText={(text) => setTempLastName(text)}
                  onKeyPress={(event: any) => {
                    if (event.keyCode === 9) {
                      event.preventDefault();
                      tempEmailRef.current?.focus();
                    }
                  }}
                  onSubmitEditing={() => tempEmailRef.current?.focus()}
                  returnKeyType="next"
                  testID="LastNameField"
                />
              </View>
              <View style={{ flex: 4 }}>
                <Input
                  ref={tempEmailRef}
                  style={width > 576 ? styles.input : styles.inputMobile}
                  textStyle={
                    width > 576 ? styles.inputText : styles.inputTextMobile
                  }
                  placeholder="Email"
                  textContentType="emailAddress"
                  autoCompleteType="email"
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="email-address"
                  accessoryRight={EmailIcon}
                  value={tempEmail}
                  onChangeText={(text) => setTempEmail(text)}
                  onKeyPress={(event: any) => {
                    if (event.keyCode === 9) {
                      event.preventDefault();
                      tempFirstNameRef.current?.focus();
                    }
                  }}
                  onSubmitEditing={() => tempFirstNameRef.current?.focus()}
                  returnKeyType="next"
                  testID="EmailField"
                />
              </View>
              <View style={{ flex: 1 }}>
                <Button
                  disabled={isPlusButtonDisabled()}
                  style={styles.plusButton}
                  status="basic"
                  onPress={() => addMbo()}
                  accessoryRight={PlusIcon}
                />
              </View>
            </View>
          </>
        )}

      <Text style={width > 576 ? styles.termsMBO : styles.termsMBOMobile}>
        We will require some information from your business partners. We will
        send them an email asking them to create an account, which will be
        linked to{" "}
        <Text
          style={
            width > 576
              ? styles.link
              : {
                  ...styles.link,
                  lineHeight: 18,
                  fontSize: 12,
                }
          }
        >
          {formData.businessName}'s
        </Text>{" "}
        loan application.
      </Text>

      <View style={width > 576 && styles.flexRow}>
        <Button
          style={
            width > 576
              ? styles.nextActionButton
              : styles.nextActionButtonMobile
          }
          status="basic"
          onPress={() => setCurrentQuestionID(currentQuestionID - 1)}
          accessoryLeft={LeftArrowIcon}
          children={() => <Text style={styles.nextActionButtonText}>Back</Text>}
        />
        {!submitActionButtonIsDisabled && (
          <Button
            disabled={submitActionButtonIsDisabled}
            style={
              submitActionButtonIsDisabled
                ? width > 576
                  ? styles.disabledActionButton
                  : styles.disabledActionButtonMobile
                : width > 576
                ? styles.nextActionButton
                : styles.nextActionButtonMobile
            }
            status="basic"
            onPress={async () => {
              let domainFlag = false;
              let existenceFlag = false;

              if (
                !(tempFirstName && tempLastName && tempEmail) &&
                !(!tempFirstName && !tempLastName && !tempEmail)
              ) {
                setShowIncompleteError(true);
                return false;
              }

              if (!isPlusButtonDisabled()) {
                if (
                  formData.email
                    .split("@")
                    [formData.email.split("@").length - 1].toLowerCase() !==
                  tempEmail
                    .split("@")
                    [tempEmail.split("@").length - 1].toLowerCase()
                ) {
                  //domain check
                  domainFlag = true;
                }
              }
              formData.businessPartners.map((partner: any, index: number) => {
                if (
                  formData.email
                    .split("@")
                    [formData.email.split("@").length - 1].toLowerCase() !==
                  partner.email
                    .split("@")
                    [partner.email.split("@").length - 1].toLowerCase()
                ) {
                  //domain check
                  domainFlag = true;
                }
              });

              let email_list = formData.businessPartners.map((partner: any) => {
                return partner.email.toLowerCase();
              });
              if (tempEmail != "") email_list.push(tempEmail.toLowerCase());
              const response = await checkMboListExistence({
                email: [...email_list],
              });

              console.log(
                "passage check",
                domainFlag,
                response.response.entities["exists"]
              );
              if (domainFlag || response.response.entities["exists"]) {
                if (
                  response.response.entities["exists"] ||
                  email_list.includes(formData.email)
                ) {
                  setShowEmailExistsError(true);
                }
                if (domainFlag) {
                  setShowDomainEmailError(true);
                }
              } else {
                await onSubmitForm({
                  ...formData,
                  businessPartners: [
                    ...formData.businessPartners,
                    {
                      firstName: tempFirstName,
                      lastName: tempLastName,
                      email: tempEmail,
                    },
                  ],
                });
              }
            }}
            accessoryRight={loading ? LoadingIndicator : CloudUploadIcon}
            children={() => (
              <Text
                style={
                  submitActionButtonIsDisabled
                    ? styles.disabledActionButtonText
                    : styles.nextActionButtonText
                }
              >
                Submit
              </Text>
            )}
          />
        )}
      </View>
    </View>
  );
};

export default PartnerInformationStep;
