import {
  Button,
  useStyleSheet,
  Select,
  SelectItem,
  Icon,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View } from "react-native";

import RichTextEditor from "../../components/RichTextEditor";
import { useStore } from "../../stores";
import { uploadDocument } from "./index";
import { themedStyles } from "./themedStyles";

export const NewRequestForm: React.FC<any> = ({
  addNewRequest,
  applicationId,
  rootMessageId,
  parentMessageId,
  theme = "light",
}) => {
  const [selectedIndex, setSelectedIndex]: any = React.useState(null);
  const [selectedRequestDocument, setSelectedRequestDocument] =
    useState("Type of document");
  const [attachments, setAttachments]: any = useState([]);

  const [message, setMessage] = useState("");
  const FileIcon = (props: any) => <Icon {...props} name="file-text-outline" />;

  useEffect(() => {
    if (selectedIndex) {
      setSelectedRequestDocument(options[selectedIndex.row]);
    }
  }, [selectedIndex]);

  const options = [
    "Personal Financial Statements",
    "Tax returns (Personal); last two years",
    "Tax returns (Corporate); last two years",
    "last two years FYE Financial Statements (Corporate)",
    "Current YTD and Monthly Interim Financial Statements with prior comparable period",
    "Debt Schedule with Copies of Applicable Note(s)",
    "Executed contract with Government, State & Local (Sample Invoices with Corresponding Backup, Copies of contracts, & Modifications)",
    "Accounts Receivable aging (Current Period & Month End A/R Detailed Aging, Aged by Invoice Date)",
    "FYE Financial Projections by Month to Include BS, IS & CF Statement ",
    "Contract Backlog Report",
    "Bank Statements for all Operating and Payroll Accounts; last 2 years",
    "Accounts Payable aging (Current Period & Month End A/P Detailed Aging, Aged by Invoice Date)",
    "Good Standing Certificate",
    "Articles of Incorporation and any applicable amendments",
    "Company Organizational Structure Chart",
    "ByLaws",
    "Operating Agreement",
    "Corporate Resolution/Authorizing Resolution",
    "Incumbency of Officers/Incumbency Certificate",
    "Other",
  ];

  const styles = useStyleSheet(themedStyles);

  return (
    <>
      {!rootMessageId && (
        <Select
          style={styles.input}
          selectedIndex={selectedIndex}
          //@ts-ignore
          onSelect={(index) => setSelectedIndex(index)}
          value={selectedRequestDocument}
        >
          {options.map((option) => (
            <SelectItem key={"request-document-" + option} title={option} />
          ))}
        </Select>
      )}
      <View style={{ height: 225, overflow: "hidden" }}>
        <RichTextEditor
          setText={(text: any) => setMessage(text)}
          placeholder="message"
          theme={theme}
          fileUploadButtonClickCallback={() =>
            uploadDocument((doc: any) =>
              setAttachments([...attachments, { name: doc.name, uri: doc.uri }])
            )
          }
        />
      </View>
      <View style={styles.flexRow}>
        {attachments.map((file: any) => (
          <Button
            key={`file-${file.name}`}
            style={styles.attachment}
            appearance="outline"
            accessoryLeft={FileIcon}
            onPress={() => window.open(file.uri, file.name)}
          >
            {file.name}
          </Button>
        ))}
      </View>

      <View style={styles.flexRow}>
        <Button
          style={styles.attachment}
          appearance="outline"
          onPress={() =>
            uploadDocument((doc: any) =>
              setAttachments([...attachments, { name: doc.name, uri: doc.uri }])
            )
          }
        >
          Add attachment
        </Button>

        <Button
          onPress={() => {
            addNewRequest({
              id: -1,
              applicationId: applicationId || 1,
              date: new Date().toLocaleDateString(),
              ...(rootMessageId
                ? {}
                : { requestedDocument: selectedRequestDocument }),
              body: message,
              attachments,
              status: "active",
              parentMessageId,
              rootMessageId,
            });
            setMessage("");
            setAttachments([]);
          }}
          style={styles.attachment}
          appearance="outline"
        >
          Send
        </Button>
      </View>
    </>
  );
};
