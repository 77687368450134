import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";

import Header from "../../components/Header";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { acceptedBusinessStateOptions } from "../../utils/constants";
import { FilterOptionsModal } from "./FilterOptionsModal";
import { LoanPreviewModal } from "./LoanPreviewModal";
import { themedStyles } from "./themedStyles";
import UnderwriterPortalFinalDecision from "../UnderwriterPortalFinalDecision";
type Props = object;

const UnderwriterPortalClosingQueue: React.FC<Props> = (props) => {
  return <UnderwriterPortalFinalDecision isClosingQueue />;
};

export default UnderwriterPortalClosingQueue;
