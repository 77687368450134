import { useLinkTo } from "@react-navigation/native";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
} from "@ui-kitten/components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from "react-native";

import Header from "../../components/Header";
import { confirmResetPassword } from "../../services/api";
import {
  commonPasswordsList,
  MINIMUM_PASSWORD_LENGTH,
} from "../../utils/constants";
import SuccessResetPassword from "./SuccessResetPassword";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const ConfirmResetPassword: React.FC<any> = ({ route }) => {
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const ResetPasswordImage = require("../../../assets/ResetPasswordImage.png");
  const AdminResetPasswordImage = require("../../../assets/AdminResetPasswordImage.png");

  const resetUid =
    route?.params?.uid ||
    new URLSearchParams(window.location.search).get("uid");
  const resetToken =
    route?.params["token"] ||
    new URLSearchParams(window.location.search).get("token") ||
    route?.params["amp;token"] ||
    new URLSearchParams(window.location.search).get("amp;token");

  const defaultFields = {
    uid: "",
    token: "",
    newPassword1: "",
    newPassword2: "",
  };
  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<any>(defaultFields);
  const [errorMessages, setErrorMessages] = useState(defaultErrors);
  const [securedText, setSecuredText] = useState(true);
  const [resetDone, setResetDone] = useState(false);

  const newPassword2Ref = useRef<Input>(null);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const EyeIcon = (props: any) => (
    <TouchableOpacity onPress={() => setSecuredText(!securedText)}>
      <Icon {...props} name={securedText ? "eye-off-outline" : "eye-outline"} />
    </TouchableOpacity>
  );

  const confirmPasswordReset = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setErrorMessages(defaultErrors);
    let response: any = {};
    try {
      response = await confirmResetPassword(formData);
      if ("entities" in response.response) {
        setResetDone(true);
      } else {
        setErrorMessages(JSON.parse(response.response));
      }
    } catch (err) {
      console.log(err.message);
      setErrorMessages(JSON.parse(err.message));
    }
    setLoading(false);
  }, [loading, formData]);

  const isNextActionButtonDisabled = useCallback(() => {
    return (
      !formData.newPassword1 ||
      !formData.newPassword2 ||
      formData.newPassword1 != formData.newPassword2
    );
  }, [formData]);

  const isPasswordCommon = (password: string) => {
    return !!commonPasswordsList.find((item: string) => item === password);
  };
  const isPasswordNotAcceptable = (password: string) => {
    const specialRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const lettersRegex = /[A-Za-z]/;
    const numbersRegex = /\d/g;
    return !(
      specialRegex.test(password) &&
      lettersRegex.test(password) &&
      numbersRegex.test(password) &&
      password.length >= MINIMUM_PASSWORD_LENGTH
    );
  };

  useEffect(() => {
    setErrorMessages({
      ...errorMessages,
      newPassword1: !formData.newPassword1
        ? ""
        : isPasswordCommon(formData.newPassword1)
        ? "This password is too common."
        : formData.newPassword1.length < MINIMUM_PASSWORD_LENGTH
        ? "This password is too short"
        : isPasswordNotAcceptable(formData.newPassword1)
        ? "This password is not acceptable."
        : "",
      newPassword2:
        formData.newPassword2 && formData.newPassword2 !== formData.newPassword1
          ? "Passwords does not match"
          : "",
    });
  }, [formData.newPassword1, formData.newPassword2]);

  const disableButton =
    isNextActionButtonDisabled() ||
    isPasswordCommon(formData.newPassword1) ||
    isPasswordNotAcceptable(formData.newPassword1) ||
    formData.newPassword1 !== formData.newPassword2;

  useEffect(() => {
    setFormData({ ...formData, uid: resetUid || "", token: resetToken || "" });
  }, []);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View
          style={
            width > 992
              ? styles.resetPasswordContainer
              : styles.resetPasswordContainerMobile
          }
        >
          <Image
            style={
              width > 992
                ? { width: 517, height: 809 }
                : { width: "100%", height: width * 0.8077 }
            }
            source={ResetPasswordImage}
          />
          {!resetDone && (
            <View style={styles.resetPassword}>
              <Text style={styles.header}>Create new password</Text>
              <Text style={styles.subHeader}>
                Don't have an account yet?{" "}
                <Text
                  status="info"
                  style={styles.link}
                  onPress={() => linkTo("/auth/register")}
                >
                  Sign Up
                </Text>
                .
              </Text>

              {!!errorMessages && !!errorMessages.token && (
                <Text category="h6" style={styles.error}>
                  token: {errorMessages.token}
                </Text>
              )}
              {!!errorMessages && !!errorMessages.uid && (
                <Text category="h6" style={styles.error}>
                  uid: {errorMessages.token}
                </Text>
              )}
              {!!errorMessages && !!errorMessages.nonFieldErrors && (
                <Text category="h6" style={styles.error}>
                  nonFieldErrors: {errorMessages.nonFieldErrors}
                </Text>
              )}

              <Text>
                {errorMessages?.newPassword1 && (
                  <Text category="h6" style={styles.error}>
                    {errorMessages?.newPassword1}
                  </Text>
                )}
              </Text>

              <Text style={styles.inputLabel}>Password</Text>
              <Input
                style={styles.input}
                placeholder="Password (min 8 characters)"
                autoCapitalize="none"
                autoCompleteType="password"
                autoCorrect={false}
                secureTextEntry={securedText}
                accessoryRight={EyeIcon}
                onKeyPress={(event: any) => {
                  if (event.keyCode === 9) {
                    event.preventDefault();
                    newPassword2Ref.current?.focus();
                  }
                }}
                onChangeText={(text) =>
                  setFormData({ ...formData, newPassword1: text })
                }
                onSubmitEditing={() => newPassword2Ref.current?.focus()}
                returnKeyType="next"
                testID="newPassword1Field"
              />
              <Text style={styles.caption}>Must be at least 8 characters.</Text>

              <Text style={styles.inputLabel}>Confirm Password</Text>

              <Text>
                {errorMessages?.newPassword2 && (
                  <Text category="h6" style={styles.error}>
                    {errorMessages?.newPassword2}
                  </Text>
                )}
              </Text>
              <Input
                ref={newPassword2Ref}
                style={styles.input}
                placeholder="Password (min 8 characters)"
                autoCapitalize="none"
                autoCompleteType="password"
                autoCorrect={false}
                secureTextEntry={securedText}
                onChangeText={(text) =>
                  setFormData({ ...formData, newPassword2: text })
                }
                onSubmitEditing={() => confirmPasswordReset()}
                returnKeyType="go"
                testID="newPassword2Field"
              />

              <Text style={styles.passwordMatchText}>
                {!!formData.newPassword1 &&
                formData.newPassword1 === formData.newPassword2
                  ? "Both passwords match."
                  : " "}
              </Text>

              <Button
                disabled={disableButton}
                style={
                  disableButton
                    ? styles.disabledActionButton
                    : styles.resetPasswordButton
                }
                status="basic"
                onPress={() => confirmPasswordReset()}
                accessoryRight={loading ? LoadingIndicator : ArrowIcon}
                children={() => (
                  <Text
                    style={
                      disableButton
                        ? styles.disabledActionButtonText
                        : styles.resetPasswordButtonText
                    }
                  >
                    reset password{"  "}
                  </Text>
                )}
              />
            </View>
          )}
          {resetDone && <SuccessResetPassword />}
        </View>
      </ScrollView>
    </Layout>
  );
};

export default ConfirmResetPassword;
