import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  flexRow: { flexDirection: "row" },
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 70,
  },
  link: {
    fontFamily: "Montserrat_700Bold",
    color: "#26D4F3",
    lineHeight: 24,
    fontSize: 18,
  },
  text: {
    fontFamily: "Montserrat_400Regular",
    color: "#8F9BB3",
    fontWeight: 500,
    lineHeight: 24,
    fontSize: 15,
    marginTop: 70,
  },
  caption: {
    fontFamily: "Montserrat_400Regular",
    color: "#8F9BB3",
    fontWeight: 500,
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 36,
  },
  resetPassword: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
  },
  resetPasswordContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  resetPasswordContainerMobile: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    marginBottom: 20,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inAdminInput: {
    marginBottom: 20,
    marginHorizontal: 5,
    flex: 1,
    borderRadius: 4,
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
  },
  inputLabel: {
    fontFamily: "Montserrat_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  skipButton: {
    flex: 1,
    maxWidth: 200,
    marginRight: 10,
    border: "none",
    backgroundColor: "#222B45",
    marginTop: 0,
    marginLeft: 10,
  },
  resetPasswordButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 70,
    marginRight: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  resetPasswordButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "white",
    fontSize: 14,
  },
  disabledActionButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 70,
    marginRight: 10,
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "color-basic-600",
    fontSize: 14,
  },
  passwordMatchText: {
    fontFamily: "Montserrat_400Regular",
    color: "#26DDF1",
    fontSize: 15,
    lineHeight: 24,
  },
  forgotPasswordText: {
    fontFamily: "Montserrat_400Regular",
    color: "#26DDF1",
    textAlign: "right",
    fontSize: 15,
    lineHeight: 24,
  },
});
