import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import ClosingQueue from "../../containers/ClosingQueue";
import ManageProfile from "../../containers/ManageProfile";
import Collateral from "../../containers/UnderwriterPortalCollateral";
import CreditFiles from "../../containers/UnderwriterPortalCreditFiles";
import DocuSignModal from "../../containers/UnderwriterPortalCreditFiles/DocuSignModal";
import DocuSignModalEnd from "../../containers/UnderwriterPortalCreditFiles/DocuSignModalEnd";
import LendingDocCloSignModal from "../../containers/UnderwriterPortalCreditFiles/LendingDocCloSignModal";
import LendingDocCloSignModalEnd from "../../containers/UnderwriterPortalCreditFiles/LendingDocCloSignModalEnd";
import LoanTermsModal from "../../containers/UnderwriterPortalCreditFiles/LoanTermsModal";
import RetrieveSignedDoc from "../../containers/UnderwriterPortalCreditFiles/RetrieveSignedDoc";
import RetrieveSignedLoanTerms from "../../containers/UnderwriterPortalCreditFiles/RetrieveSignedLoanTerms";
import Decision from "../../containers/UnderwriterPortalDecision";
import UnderwriterPortalFinalDecision from "../../containers/UnderwriterPortalFinalDecision";
import RiskFlags from "../../containers/UnderwriterPortalRiskFlags";
import Summary from "../../containers/UnderwriterPortalSummary";

export type UnderwriterStackParamList = {
  summary: undefined;
  collateral: undefined;
  decision: undefined;
  "risk-flags": undefined;
  dashboard: undefined;
  closing: undefined;
  "credit-files": undefined;
  "docusign-modal": undefined;
  "docusign-modal-end": undefined;
  "retrieve-signed-doc-modal": undefined;
  "loan-terms-modal": undefined;
  "loan-terms-modal-end": undefined;
  "retrieve-signed-loan-terms-modal": undefined;
  "lending-doc-clo-sign-modal": undefined;
  "lending-doc-clo-sign-modal-end": undefined;
  "manage-profile": undefined;
};

export type UnderwriterStackNavigation =
  StackNavigationProp<UnderwriterStackParamList>;

type Props = object;

const Stack = createStackNavigator<UnderwriterStackParamList>();

const UnderwriterStack: React.FC<Props> = (props) => {
  return (
    <Stack.Navigator initialRouteName="dashboard" headerMode="none">
      <Stack.Screen
        name="summary"
        options={{ title: "OppZo | Underwriter Portal | Loan summary" }}
        component={Summary}
      />
      <Stack.Screen
        name="collateral"
        options={{
          title: "OppZo | Underwriter Portal | Collateral & Reports",
        }}
        component={Collateral}
      />
      <Stack.Screen
        name="risk-flags"
        options={{
          title: "OppZo | Underwriter Portal | Risk Flags",
        }}
        component={RiskFlags}
      />
      <Stack.Screen
        name="decision"
        options={{
          title: "OppZo | Underwriter Portal | Decision",
        }}
        component={Decision}
      />
      <Stack.Screen
        name="credit-files"
        options={{
          title: "OppZo | Underwriter Portal | Decision",
        }}
        component={CreditFiles}
      />
      <Stack.Screen
        name="dashboard"
        options={{
          title: "OppZo | Underwriter Portal | Dashboard",
        }}
        component={UnderwriterPortalFinalDecision}
      />
      <Stack.Screen
        name="closing"
        options={{
          title: "OppZo | Underwriter Portal | Closing Queue",
        }}
        component={ClosingQueue}
      />
      <Stack.Screen
        name="docusign-modal"
        options={{
          title: "OppZo | Underwriter Portal | DocuSign",
        }}
        component={DocuSignModal}
      />
      <Stack.Screen
        name="docusign-modal-end"
        options={{
          title: "OppZo | Underwriter Portal | DocuSign",
        }}
        component={DocuSignModalEnd}
      />
      <Stack.Screen
        name="retrieve-signed-doc-modal"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={RetrieveSignedDoc}
      />
      <Stack.Screen
        name="loan-terms-modal"
        options={{
          title: "OppZo | Underwriter Portal | DocuSign",
        }}
        component={LoanTermsModal}
      />
      <Stack.Screen
        name="retrieve-signed-loan-terms-modal"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={RetrieveSignedLoanTerms}
      />
      <Stack.Screen
        name="lending-doc-clo-sign-modal"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={LendingDocCloSignModal}
      />
      <Stack.Screen
        name="lending-doc-clo-sign-modal-end"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={LendingDocCloSignModalEnd}
      />
      <Stack.Screen
        name="manage-profile"
        options={{ title: "OppZo | Underwriter Portal | Manage Profile" }}
        component={ManageProfile}
      />
    </Stack.Navigator>
  );
};

export default UnderwriterStack;
