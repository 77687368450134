import * as WebBrowser from "expo-web-browser";
import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
import { useStore } from "../../stores";
import { getLatestTermsOfService } from "../../services/api/policies";
import Markdown from "react-native-markdown-display";
type Props = object;

const TermsOfServiceModal: React.FC<any> = ({ style }) => {
  const styles = useStyleSheet(themedStyles);
  const { authStore } = useStore();
  const [mdtext, setMdtext] = useState("");

  useEffect(() => {
    (async () => {
      const res = await getLatestTermsOfService(authStore?.accessToken || "");
      if (res.response.entities) {
        setMdtext(res.response.entities.body);
      }
    })();
  }, []);

  return (
    <View style={style}>
      <Markdown style={themedStyles}>{mdtext}</Markdown>
    </View>
  );
};

const TermsOfService: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { authStore } = useStore();
  const [mdtext, setMdtext] = useState("");

  useEffect(() => {
    (async () => {
      const res = await getLatestTermsOfService(authStore?.accessToken || "");
      if (res.response.entities) {
        setMdtext(res.response.entities.body);
      }
    })();
  }, []);

  const handleLinkPress = () => {
    WebBrowser.openBrowserAsync("mailto: info@getoppzo.com");
  };

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.termsOfServiceContainer}>
          <View style={styles.termsOfService}>
            <Markdown style={themedStyles}>{mdtext}</Markdown>
            {/* <Text style={styles.header}>Welcome to OppZo!</Text>

            <Text style={styles.paragraph}>
              The following terms of use ("Agreement") apply to the entity
              ("Business") registered on OppZo Inc.'s website{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("https://www.getoppzo.com")
                }
              >
                <Text style={styles.link}>www.getoppzo.com</Text>
              </TouchableOpacity>{" "}
              ("Website") and the majority owner of the Business duly authorized
              to register the Business ("Owner") (Business and Owner are
              hereinafter collectively referred to as "you," "your" or
              "yourself"), including using the "Apply Now" or "Get Started"
              feature in connection with applying for a business loan
              (collectively the "Service"). This Agreement applies in addition
              to all other agreements that may be provided to you.{" "}
              <Text style={styles.bold}>
                Please read this Agreement carefully because it contains
                important information, including our limitation of liability to
                you and your agreement to dispute resolution and waiver of the
                right to participate in a class action.
              </Text>{" "}
              Your use of the Website and/or the Service constitutes your
              consent to the terms of the Agreement. If you do not agree to the
              terms of the Agreement, you are not permitted to use this Website
              and/or the Service.
            </Text>
            <Text style={styles.subHeader}>Use of Website and the Service</Text>
            <Text style={styles.paragraph}>
              This Website is owned and operated by OppZo, Inc. ("OppZo", "we",
              or "us"). By visiting the Website and/or using the Service, you
              consent to the Agreement and the terms and conditions in our
              Privacy Policy.
            </Text>
            <Text style={styles.paragraph}>
              We may amend, change, or alter this Agreement (including the
              Privacy Policy that is incorporated into this Agreement) at any
              time in our sole discretion. If we amend the terms to this
              Agreement, such amendment will be effective upon posting. Your
              failure to cease use of the Website and/or the Service will
              constitute your acceptance of the amended, changed, or altered
              terms. Therefore, it is important that you regularly review the
              Agreement during your use of the Website and/or Service to be
              aware of any amendments, changes, or alterations. If you do not
              agree to the amendments, changes, or alterations or to any of the
              terms in this Agreement, your only remedy is to cease use of the
              Website and/or the Service.
            </Text>

            <Text style={styles.subHeader}>
              Limitations of Use and Compliance with Laws
            </Text>
            <Text style={styles.paragraph}>
              You agree to use the Website and the Service only as permitted by
              the Agreement and applicable laws or regulations. The Website and
              the Service are intended solely for natural persons at least the
              age of eighteen years old who serve as agents or authorized
              representatives of legally formed entities within the
              jurisdictions authorized to receive the Services as set forth on
              the Website. Any use or access of the Website or Service by
              natural persons under such age is unauthorized. By using the
              Website and/or the Service, you represent and warrant that you are
              at least the age of eighteen years old.
            </Text>

            <Text style={styles.subHeader}>
              Changes to the Website or the Service
            </Text>
            <Text style={styles.paragraph}>
              We are always trying to improve your experience on the Website and
              with the Service. We may need to add or change features, services,
              products, or functionality to you and may do so without notice to
              you.
            </Text>

            <Text style={styles.subHeader}>
              Offers and Promotional Materials
            </Text>
            <Text style={styles.paragraph}>
              We may offer discounts or promotional offers to you. Unless
              otherwise required by law or specifically authorized in writing by
              us, any such discounts or promotional offers are non-transferable.
              You may not reproduce such discounts or promotional offers unless
              specifically authorized in writing by us.
            </Text>

            <Text style={styles.subHeader}>Marketing Information</Text>
            <Text style={styles.paragraph}>
              The Website and/or the Service may contain information for
              marketing purposes, including, but not limited to, the amount of
              funding a business may receive and the speed at which a business
              may receive funding; however, individual business outcomes vary.
              In addition, such information may be subject to change.
            </Text>

            <Text style={styles.subHeader}>
              Accessing Information on the Website and Registration
            </Text>
            <Text style={styles.paragraph}>
              You may be required to register for an account on the Website to
              access certain information. Upon registration or when using the
              Service, you may be required to provide information about Owner
              and Business including, but not limited to, your identity or
              finances. When you set up an account with us or use the Service,
              you must provide us accurate, correct, and up to date information.
              We will treat such information according to our Privacy Policy.
              You are solely responsible for maintaining the confidentiality of
              your password and you must notify us immediately of any breach of
              security or unauthorized use of your account.
            </Text>

            <Text style={styles.subHeader}>Privacy</Text>
            <Text style={styles.paragraph}>
              Our privacy practices are set forth in our Privacy Policy, which
              is part of this Agreement. By accessing the Website and/or using
              our Service, you may be sharing personal information with us. By
              using the Website and/or the Service, you consent to the terms of
              the Privacy Policy and the Agreement.
            </Text>

            <Text style={styles.subHeader}>Representations and Warranties</Text>
            <Text style={{ ...styles.paragraph, ...{ textIndent: 0 } }}>
              You hereby warrant and represent as follows:{"\n\n"}
            </Text>
            <Text style={styles.listItem}>
              a.{"   "}Business is duly organized, validly existing and in good
              standing as a corporation or other entity under the laws of the
              jurisdiction of your incorporation;
            </Text>
            <Text style={styles.listItem}>
              b.{"   "}You have the full right, power, and authority to enter
              into and perform your obligations and grant the rights, licenses,
              and authorizations you grant and are required to grant under this
              Agreement;
            </Text>
            <Text style={styles.listItem}>
              c.{"   "}Owner assenting to this Agreement has been duly
              authorized by all necessary corporate or organizational action of
              Business; and
            </Text>
            <Text style={styles.listItem}>
              d.{"   "}This Agreement will constitute a legal, valid, and
              binding obligation enforceable against you (jointly and severally)
              in accordance with these terms.
            </Text>

            <Text style={styles.subHeader}>
              DISCLAIMERS AND LIMITATION OF WARRANTY AND LIABILITY
            </Text>
            <Text style={{ ...styles.paragraph, ...styles.bold }}>
              PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY
              OF OPPZO, INC. ENTITIES TO YOU. "OPPZO, INC. ENTITIES" MEANS
              OPPZO, INC., ITS PARENT, ANY LENDERS THAT OPPZO PROVIDES SERVICES
              TO, THIRD-PARTY SERVICE PROVIDERS TO OPPZO, SUPPLIERS AND
              LICENSORS TO OPPZO, AND ANY AFFILIATES, PARTNERS, AND RELATED
              COMPANIES, OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AGENTS, AND
              REPRESENTATIVES OF EACH OF THE FOREGOING. EACH PROVISION BELOW
              APPLIES TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW:
              {"\n\n"}
            </Text>
            <Text style={styles.listItem}>
              a.{"   "}WE ARE PROVIDING YOU THE SERVICE, ALONG WITH OUR WEBSITE
              AND THE INFORMATION ON THE WEBSITE, ON AN "AS IS" AND "AS
              AVAILABLE" BASIS, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
              IMPLIED. WITHOUT LIMITING THE FOREGOING, OPPZO, INC. ENTITIES
              EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY
              KIND WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              MERCHANTABILITY, TITLE, ACCURACY AND COMPLETENESS (INCLUDING BUT
              NOT LIMITED TO TECHNICAL ERRORS OR TYPOGRAPHICAL MISTAKES),
              UNINTERRUPTED OR ERROR-FREE SERVICE, FITNESS FOR A PARTICULAR
              PURPOSE, QUIET ENJOYMENT, AND NONINFRINGEMENT, AND ANY WARRANTIES
              ARISING OUT OF COURSE OF DEALING OR TRADE USAGE OR ANY WARRANTY
              THAT THE WEBSITE OR THE INFORMATION ON THE WEBSITE ARE ADEQUATE OR
              FREE OF VIRUSES OR OTHER SUCH HARMFUL COMPONENTS. OPPZO, INC.
              ENTITIES ARE NOT RESPONSIBLE FOR UPDATING OR AMENDING THE WEBSITE
              OR THE INFORMATION ON THE WEBSITE OR TO PROVIDE SUPPORT FOR YOUR
              USE OF THE WEBSITE OR THE INFORMATION ON THE WEBSITE. NO
              INFORMATION OBTAINED BY YOU FROM OPPZO, INC. ENTITIES SHALL CREATE
              ANY WARRANTY, GUARANTEE, OR CONDITIONS OF ANY KIND UNLESS
              EXPRESSLY PROVIDED FOR IN THIS AGREEMENT.
            </Text>
            <Text style={styles.listItem}>
              b.{"   "}OPPZO, INC. ENTITIES MAKE NO PROMISES WITH RESPECT TO,
              AND EXPRESSLY DISCLAIM ALL LIABILITY FOR: (i) CONTENT POSTED BY
              ANY THIRD PARTY, (ii) ANY THIRD-PARTY WEBSITE, THIRD-PARTY
              PRODUCT, OR THIRD-PARTY SERVICE LISTED ON OR ACCESSIBLE TO YOU
              THROUGH THE SERVICE OR THE WEBSITE, INCLUDING AN INTEGRATED
              SERVICE PROVIDER (iii) THE QUALITY OR CONDUCT OF ANY THIRD PARTY
              YOU ENCOUNTER IN CONNECTION WITH YOUR USE OF THE SERVICE OR THE
              WEBSITE. OPPZO, INC. ENTITIES MAKE NO WARRANTY THAT (a) THE
              SERVICE OR THE WEBSITE WILL MEET YOUR REQUIREMENTS, (b) THE
              SERVICE OR THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
              ERROR-FREE, (c) THE RESULTS OR INFORMATION THAT YOU MAY OBTAIN
              FROM THE USE OF THE SERVICE OR THE WEBSITE WILL BE ACCURATE OR
              RELIABLE, OR (d) CONCERN THE QUALITY OF ANY PRODUCTS, SERVICES,
              INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
              THROUGH THE SERVICE OR THE WEBSITE.
            </Text>
            <Text style={styles.listItem}>
              c.{"   "}YOU AGREE THAT UNDER THE MAXIMUM EXTENT PERMITTED BY LAW,
              OPPZO INC. ENTITIES WILL NOT BE LIABLE TO YOU UNDER ANY THEORY OF
              LIABILITY. WITHOUT LIMITING THE FOREGOING, YOU AGREE THAT OPPZO
              INC. ENTITIES SPECIFICALLY WILL NOT BE LIABLE FOR ANY INDIRECT,
              INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR EXEMPLARY
              DAMAGES, LOSS OF PROFITS, BUSINESS INTERRUPTION, REPUTATIONAL
              HARM, OR LOSS OF DATA (EVEN IF OPPZO, INC. ENTITIES HAVE BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES ARE
              FORESEEABLE) ARISING OUT OF IN ANY WAY CONNECTED WITH YOUR USE OF,
              OR INABILITY TO USE, THE WEBSITE OR THE SERVICE.
            </Text>
            <Text style={styles.listItem}>
              d.{"   "}YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE WEBSITE
              AND/OR THE SERVICE IS TO STOP USING THE WEBSITE AND/OR THE
              SERVICE.
            </Text>
            <Text style={styles.listItem}>
              e.{"   "}WITHOUT LIMITING THE FOREGOING, OPPZO INC. ENTITIES'
              MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU
              SUFFER IN CONNECTION WITH THE SERVICE, THE WEBSITE, OR THIS
              AGREEMENT IS LIMITED TO THE AMOUNT PAID, IF ANY, TO OPPZO INC. IN
              CONNECTION WITH THE SERVICE OR USE OF THE WEBSITE IN THE TWELVE
              (12) MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY.
            </Text>

            <Text style={styles.subHeader}>Indemnification</Text>
            <Text style={styles.paragraph}>
              You agree to release, indemnify, hold harmless, and defend OppZo
              Inc. Entities from and against all losses (including but not
              limited to third-party claims), expenses, damages, and costs,
              including reasonable attorneys' fees, arising out of or related to
              your use of the Service, Website, or information on the Website,
              your failure (or any other person's failure using your account) to
              meet any obligation concerning your account or your breach of any
              part of this Agreement. We will promptly notify you of any such
              claim and will provide you (at your expense) with reasonable
              assistance in defending the claim. You will allow us to
              participate in the defense and shall not settle any such claim
              without our prior written consent. We reserve the right, at our
              own expense, to assume the exclusive defense of any matter
              otherwise subject to indemnification by you. In that event, you
              will have no further obligation to defend us in that matter but
              shall provide us with reasonable cooperation.
            </Text>

            <Text style={styles.subHeader}>Copyrights/Trademarks</Text>
            <Text style={styles.paragraph}>
              All content and materials made available by OppZo, Inc.,
              including, but not limited to, the Website, logos, widgets, text,
              images, design, software, documentation, source code, algorithms,
              graphics, website name, photographs, video and audio files, other
              files, data, and the selection, arrangement, structure,
              coordination, and "look and feel" thereof (excluding third party
              web services or third-party content linked to or posted within the
              Service) are the property of OppZo Inc. and/or its licensors. The
              OppZo, Inc. name, mark, and logo are trademarks and service marks
              of OppZo, Inc. We retain all right, title, and interest in and to
              such property, name, mark, and logo. Except as expressly provided
              in these terms, you agree not to use, modify, reproduce,
              distribute, sell, license, reverse engineer, decompile, in
              appropriately use or otherwise exploit same without our express
              written permission.
            </Text>

            <Text style={styles.subHeader}>Entire Agreement/Severability</Text>
            <Text style={styles.paragraph}>
              This Agreement supersedes all prior terms, agreements,
              discussions, and writings regarding the Service and the Website
              and constitutes the entire agreement between you and us regarding
              the Service and the Website, except as provided for in this
              Agreement. If any part, term, or provision of this Agreement is
              found to be unenforceable, illegal, or otherwise invalid, then
              that provision will not affect the enforceability of the remaining
              provisions of the agreement, which will remain in full force and
              effect.
            </Text>

            <Text style={styles.subHeader}>Termination of Use</Text>
            <Text style={styles.paragraph}>
              We may limit, suspend, or terminate your use or access to the
              Service or the Website at any time for any reason, without any
              notice. With such limitation, suspension, or termination, we may
              remove or delete any information that you have provided to us,
              including your account or login credentials.
            </Text>

            <Text style={styles.subHeader}>Survival</Text>
            <Text style={styles.paragraph}>
              If any provision herein contained which by its nature or effect is
              required to be observed, kept, or performed upon termination or
              expiration of this Agreement, such provision will survive and
              remain binding upon you until fully observed, kept or performed.
            </Text>

            <Text style={styles.subHeader}>Electronic Communications</Text>
            <Text style={styles.paragraph}>
              To inquire about or request prequalification for credit, apply for
              and obtain a loan or other extension of credit, and engage in
              loans and related transactions with OppZo, Inc. Entities, and to
              view and retain a copy of all the related notices, disclosures,
              agreements, authorizations, acknowledgements, terms and
              conditions, and other documents during the course of our
              relationship with you (collectively, "Disclosures"), you
              understand that you must consent to receive Disclosures and engage
              in transactions and enter into contracts and agreements
              electronically.
            </Text>
            <Text style={styles.paragraph}>
              Described below are the terms and conditions for providing
              Disclosures to, and transacting business with Owner and Business
              electronically. Please read the information below carefully and
              thoroughly.
            </Text>
            <Text style={styles.paragraph}>
              Owner acknowledges and agrees on their own behalf and on behalf of
              the Business that: 1) electronic signatures, whether digital or
              encrypted, of the parties included in this Agreement and of any
              other agreements for or related to the Services are intended to
              authenticate writings and to have the same force and effect as
              manual signatures; 2) the transactions under this Agreement and
              any agreement related to the Services may be conducted
              electronically through whatever means OppZo may provide from time
              to time; and, 3) we may provide Owner and/or Business with the
              Disclosures in electronic format, and that we are not required to
              send a paper document to Owner or Business, unless and until the
              Owner and/or Business has withdrawn their consent as described
              below. All Disclosures that we provide to Owner or Business in
              electronic format will be provided as e-mail attachments or as
              links embedded in e-mails that access any website or mobile
              application that we own or control or by access to our online
              platform.{"\n\n"}
            </Text>
            <Text style={styles.listItem}>1.{"   "}GETTING PAPER COPIES.</Text>
            <Text style={styles.paragraph}>
              To obtain paper copies of any documents, Owner or Business must
              print the electronic version of the document with their printer.
              Owner and Business will not be able to obtain paper copies of
              documents from OppZo, Inc. Entities at a later date.{"\n\n"}
            </Text>
            <Text style={styles.listItem}>
              2.{"   "}WITHDRAWING YOUR CONSENT.
            </Text>
            <Text style={styles.paragraph}>
              Owner or Business may withdraw consent to receive Disclosures
              electronically, without charge, by following the instructions
              below. If Business has a pending credit request or application in
              process with OppZo, Inc. Entities, or an extension of credit that
              has been approved and is in the process of funding, either Owner's
              or Business' withdrawal of consent will terminate the credit
              request or application and will result in the cancellation of any
              funds in process of disbursement (except where prohibited by law).
            </Text>
            <Text style={styles.paragraph}>
              After Business' extension of credit has been originated and
              funded, Owner or Business may withdraw their consent to receive
              any future Disclosures in electronic format. Withdrawing consent
              does not apply to origination disclosures that were previously
              provided to Owner and Business (electronically) prior to Business'
              loan funding (or at the time of funding). Withdrawing consent may
              slow the speed at which we can deliver services.
            </Text>
            <Text style={styles.paragraph}>
              Owner or Business may withdraw their consent to receive future
              Disclosures electronically by sending an email to{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("mailto: support@getoppzo.com")
                }
              >
                <Text style={styles.link}>support@getoppzo.com</Text>
              </TouchableOpacity>{" "}
              with the subject line of "Withdraw electronic consent" and in the
              body of the email, state the withdrawing party's full name, US
              Postal Address, email address, and telephone number.
            </Text>
            <Text style={styles.paragraph}>
              Owner's or Business' withdrawal of consent will be effective only
              after we have had a reasonable period of time to process the
              request.{"\n\n"}
            </Text>
            <Text style={styles.listItem}>
              3.{"   "}CHANGE IN YOUR EMAIL ADDRESS OR OTHER CONTACT
              INFORMATION.
            </Text>
            <Text style={styles.paragraph}>
              To ensure Owner and/or Business receives all Disclosures in a
              timely manner, Owner agrees to promptly notify us of any change in
              Owner's or Business' email address, Business' mailing address, or
              Owner's home mailing address. To let us know of a change in your
              e-mail address, navigate to your OppZo dashboard and select Manage
              Profile to update your account details.
            </Text>
            <Text style={styles.paragraph}>
              At our discretion, we may treat the failure to provide us with a
              valid e-mail address or the malfunction of a previously valid
              e-mail address as a withdrawal of consent to receive electronic
              documents.{"\n\n"}
            </Text>
            <Text style={styles.listItem}>
              4.{"   "}REQUIRED HARDWARE AND SOFTWARE
            </Text>
            <Text style={styles.paragraph}>
              In order to retain Disclosures electronically, Owner and Business
              must satisfy the following computer hardware and software
              requirements: access to the Internet; PDF, JPEG or PNG capability
              and hardware capable of running this software. To ensure access to
              loan documents in PDF format, Owner and/or Business must have
              Adobe Reader.
            </Text>
            <Text style={styles.paragraph}>
              In addition to the above requirements, Owner must make sure that
              they have software on their mobile device that allows Owner and/or
              Business to print or save the Disclosures presented to Owner and
              Business during the application process. These applications can be
              found for most mobile devices in the device's respective "app
              store".
              {"\n\n"}
            </Text>
            <Text style={styles.listItem}>5.{"   "}FEDERAL LAW</Text>
            <Text style={styles.paragraph}>
              All Disclosures we provide in either electronic or paper format
              will be considered "in writing." Owner and/or Business should
              print or download for their records a copy of this ESIGN Act
              Consent and any other Disclosures. Owner and Business
              (individually and collectively) agree, and we all intend the
              federal Electronic Signatures in Global and National Commerce Act
              to apply to (i) this ESIGN Act Consent and (ii) our ability to
              conduct business with Owner and Business (individually and
              collectively) by electronic means.{"\n\n"}
            </Text>
            <Text style={styles.listItem}>6.{"   "}TERMINATION/CHANGES</Text>
            <Text style={styles.paragraph}>
              We reserve the right, in our sole discretion, to provide Owner
              and/or Business with any Disclosures in paper format and to
              discontinue electronic provision of Disclosures at any time. We
              will provide Owner and/or Business with notice of any such
              termination or change as required by law.
              {"\n\n"}
            </Text>
            <Text style={styles.listItem}>
              7.{"   "}ACKNOWLEDGING YOUR ACCESS AND CONSENT TO RECEIVE
              MATERIALS ELECTRONICALLY AND TO CONDUCT BUSINESS ELECTRONICALLY
            </Text>
            <Text style={styles.paragraph}>
              By clicking "Get Started" Owner and Business (individually and
              collectively) agree that: (i) they have read this ESIGN Act
              Consent; (ii) they will receive Disclosures in electronic format
              and conduct transactions electronically; (iii) the Disclosures and
              electronic signatures will have the same legal effect as written
              and signed paper documents; (iv) they have the necessary hardware
              and software to view, save and print this ESIGN Act Consent and
              the Disclosures or any related documents and communications; and
              (v) unless or until Owner and/or Business notifies us as described
              above to revoke this consent, Owner's and Business' individual and
              collective consent applies to every Disclosure or other record
              that we may send each the Owner and Business during the course of
              Owner's and Business' relationship with us.
            </Text>

            <Text style={styles.subHeader}>Adverse Action</Text>
            <Text style={styles.paragraph}>
              We may not grant Business' request for credit. If Business would
              like a statement of specific reasons why Business' application was
              denied, please contact{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("mailto: support@getoppzo.com")
                }
              >
                <Text style={styles.link}>support@getoppzo.com</Text>
              </TouchableOpacity>{" "}
              within 60 days of the date we notify Business. We will provide
              Business with the statement of reasons within 30 days after
              receiving Business' request.
            </Text>
            <Text style={styles.paragraph}>
              Notice: The Federal Equal Credit Opportunity Act prohibits
              creditors from discriminating against credit applicants on the
              basis of race, color, religion, national origin, sex, marital
              status, age (provided the applicant has the capacity to enter into
              a binding contract); because all or part of the applicant's income
              derives from any public assistance program; or because the
              applicant has in good faith exercised any right under the Consumer
              Credit Protection Act. The Federal agency that administers
              compliance with this law concerning this creditor is the Federal
              Trade Commission, Equal Credit Opportunity, Washington, DC 20580.
            </Text>

            <Text style={styles.subHeader}>Interpretation</Text>
            <Text style={styles.paragraph}>
              In construing or interpreting the terms of this Agreement no
              presumption is to operate in either party's favor because of its
              counsel's role in drafting this Agreement.
            </Text>

            <Text style={styles.subHeader}>Notices</Text>
            <Text style={styles.paragraph}>
              All notices permitted or required under this Agreement, unless
              specified otherwise in this Agreement, must be sent in writing as
              follows in order to be valid: (i) if to you, by us via email to
              the address associated with your account, and (ii) if to us by you
              via{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() => handleLinkPress()}
              >
                <Text style={styles.link}>info@getoppzo.com</Text>
              </TouchableOpacity>
              . Notices will be deemed given (a) if to you, when emailed, and
              (b) if to us, on receipt by us.
            </Text>

            <Text style={styles.subHeader}>Relationship</Text>
            <Text style={styles.paragraph}>
              This Agreement does not confer any third-party beneficiary rights
              and does not create a joint venture, agency, partnership, or other
              form of joint enterprise between you and us. Except as expressly
              provided herein, neither party has the right, power, or authority
              to create any obligation or duty, express or implied, on behalf of
              the other.
            </Text>

            <Text style={styles.subHeader}>Waiver</Text>
            <Text style={styles.paragraph}>
              No wavier of any terms will deemed a further or continuing waiver
              or such term or any other term. Our failure to assert a right or
              provision under this Agreement will not constitute a waiver of
              such right or provision.
            </Text>

            <Text style={styles.subHeader}>
              Headings and Sections References
            </Text>
            <Text style={styles.paragraph}>
              Headings and sections in this Agreement are for reference purposes
              only and shall not have any substantive effect, including, but not
              limited to, effecting the meaning or interpretation of the
              Agreement.
            </Text>

            <Text style={styles.subHeader}>
              Governing Law and Dispute Resolution
            </Text>
            <Text style={styles.paragraph}>
              This Agreement shall be construed and governed in accordance with
              the laws of the State of New York, without respect to its conflict
              of laws principles. Any action, claim, grievance, dispute, or
              controversy arising out of or in relation to this Agreement or the
              Website shall first be submitted for mediation and if not settled
              within 60 days of serving a written demand for mediation, it shall
              be resolved by binding arbitration administered by the American
              Arbitration Association according to its Commercial Arbitration
              Rules. The number of arbitrators shall be one. The place of
              arbitration shall be Miami, Florida. Notwithstanding the above, an
              action may be brought in a court of competent jurisdiction in
              Miami-Dade County to enforce an arbitration award and you hereby
              agree to personal jurisdiction by such court and waive any
              jurisdictional, venue, or inconvenient forum objections to such
              court.
            </Text>

            <Text style={styles.paragraph}>
              {"\n\n"}
              <Text style={{ ...styles.paragraph, ...styles.bold }}>
                Any dispute resolution proceedings will be conducted only on an
                individual basis and not in a class, consolidated, or
                representative action. By agreeing to these terms, you are
                waiving the right to participate in a class action.
              </Text>{" "}
              Further, unless the parties mutually agree otherwise, the
              arbitrator may not consolidate more than one person's claims and
              may not otherwise preside over any form of a representative or
              class proceeding.
            </Text>

            <Text style={styles.paragraph}>
              {"\n\n"}
              If this section is found to be illegal or unenforceable by a court
              of competent jurisdiction, then the parties agree that any dispute
              will be resolved exclusively in a state or federal court located
              in New York County, New York, and that the parties both submit to
              the personal jurisdiction of such courts. If a claim proceeds in
              court rather than through arbitration, the parties waive any right
              to a jury trial.
            </Text>

            <Text style={styles.subHeader}>Contact Information</Text>
            <Text style={styles.paragraph}>
              If you have questions regarding this Agreement or the Website,
              please contact us at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() => handleLinkPress()}
              >
                <Text style={styles.link}>info@getoppzo.com</Text>
              </TouchableOpacity>
              .
            </Text> */}
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default TermsOfService;
export { TermsOfServiceModal };
