import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/core";
import {
  Button,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  ThemeProvider,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import RichTextEditor from "../../components/RichTextEditor";
import DocumentRequestMessage from "../../models/DocumentRequestMessage";
import * as api from "../../services/api";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import { NewRequestForm } from "../AdminRequestCenter/NewRequestForm";
import { themedStyles } from "./themedStyles";

const downloadFile = async (
  attachmentId: string,
  name: string,
  token: string
) => {
  const link = document.createElement("a");
  const dataUri = await api
    .fetchDocumentRequestMessageAttachment(token, attachmentId)
    .then((response) => response.response.entities);
  const b64toBlob = (dataUri: string) =>
    fetch(dataUri).then((res) => res.blob());
  link.href = window.URL.createObjectURL(await b64toBlob(dataUri));
  //link.href = window.URL.createObjectURL(new Blob([path], { type }));
  link.download = name;
  document.body.appendChild(link);
  link.click();
};

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const RequestCenter: React.FC<any> = ({ route }) => {
  const applicationId =
    route?.params?.id ||
    new URLSearchParams(window.location.search).get("applicationId");
  const selectedRequest =
    route?.params?.selectedRequest ||
    new URLSearchParams(window.location.search).get("selectedRequest");
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const FileIcon = (props: any) => <Icon {...props} name="file-text" />;
  const BackIcon = (props: any) => (
    <Icon {...props} name="arrow-back-outline" />
  );

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [noRequests, setNoRequests] = useState(false);
  const [searchString, setSearchString] = useState("");

  const { authStore, documentRequestsStore, loanStore } = useStore();

  const [requestedDocuments, setRequestedDocuments]: any = useState([]);
  const [requestedDocumentsDisplayList, setRequestedDocumentsDisplayList]: any =
    useState([]);

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  useEffect(() => {
    setLoading(true);
    const filtered: any[] = requestedDocuments.filter(
      (doc: any) =>
        Object.values(doc).filter((field) => `${field}`.includes(searchString))
          .length
    );
    const sorted = filtered.sort((a, b) =>
      new Date(a.created) > new Date(b.created) ? -1 : 1
    );

    setRequestedDocumentsDisplayList(sorted);
    setLoading(false);
  }, [requestedDocuments, searchString]);

  useEffect(() => {
    if (
      selectedItem !== -1 &&
      selectedItem < requestedDocumentsDisplayList.length
    ) {
      (async () => {
        const item = requestedDocumentsDisplayList[selectedItem];
        item.isUnread = false;
        documentRequestsStore.updateDocumentRequestMessage(item.id, item);
      })();
    }
  }, [selectedItem]);

  const getMessageDisplayObj = (docReqMessage: any) => ({
    ...docReqMessage,
    applicationId: docReqMessage.loanApplicationId,
    title: `Request for ${
      docReqMessage.documentRequestType === "Other"
        ? docReqMessage.documentName && docReqMessage.documentName !== null
          ? docReqMessage.documentName
          : `${docReqMessage.documentCategory} File`
        : docReqMessage.documentRequestType
    }`,
    for: docReqMessage.applicantName,
    date: new Date(docReqMessage.created).toLocaleDateString(),
    attachments: docReqMessage.attachments || [],
  });

  useEffect(() => {
    (async () => {
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (!res1.response.entities) {
        navigation.navigate("dashboard");
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (!res2.response.entities) {
        navigation.navigate("dashboard");
      }
    })();
  }, []);

  const fetchDocumentRequestMessagesForUser = () => {
    setLoading(true);
    documentRequestsStore.fetchDocumentRequestMessagesForUser().then(() => {
      const reqDocuments: any = [];
      const replies: any = {};
      documentRequestsStore.messages.forEach((msg) => {
        if (Number(msg.applicantId) === Number(authStore?.user?.id)) {
          if (msg.isReply) replies[msg.id] = msg;
          else reqDocuments.push(getMessageDisplayObj(msg.$));
        }
      });
      reqDocuments.sort((a: any, b: any) =>
        new Date(a.created) > new Date(b.created) ? -1 : 1
      );
      reqDocuments.forEach((doc: any) => {
        doc.replies = doc.replies.map((replyId: any) => replies[replyId]);
      });
      console.log("reqDocuments", reqDocuments);
      setRequestedDocuments(reqDocuments);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (requestedDocumentsDisplayList.length > 0) {
      if (selectedRequest != null) {
        setSelectedItem(selectedRequest);
      }
    }
  }, [requestedDocumentsDisplayList, selectedRequest]);

  useEffect(() => {
    fetchDocumentRequestMessagesForUser();
    console.log("selectedRequest", selectedRequest);
  }, [route]);

  const addNewReply = async (req: any) => {
    const res = await documentRequestsStore.addDocumentRequestMessage(
      // @ts-ignore
      new DocumentRequestMessage({
        id: -1,
        loanApplicationId:
          requestedDocumentsDisplayList[selectedItem].applicationId,
        authorId: authStore.user?.id || null,
        body: req.body,
        attachments: req.attachments,
        rootMessageId: req.rootMessageId,
        parentMessageId: req.parentMessageId,
      })
    );
    if (res?.ok)
      setRequestedDocuments(
        requestedDocuments.map((doc: any, idx: number) => {
          if (res?.extra?.newDocumentRequest.rootMessageId !== doc.id)
            return doc;
          return {
            ...doc,
            replies: [
              ...doc.replies,
              getMessageDisplayObj(res?.extra?.newDocumentRequest),
            ],
          };
        })
      );
  };

  const SidebarItem = (item: any, index: number) => {
    return (
      <TouchableOpacity
        key={index}
        style={{
          ...styles.sidebarItemContainer,
          ...(authStore.user?.isUnderwriter || authStore.user?.isStaff
            ? {
                backgroundColor:
                  index === selectedItem
                    ? "color-basic-200"
                    : "color-basic-100",
              }
            : {
                backgroundColor: index === selectedItem ? "#101426" : "#192038",
              }),
        }}
        onPress={() => setSelectedItem(index)}
      >
        <View style={styles.flexRowBetween}>
          {item.isUnread && <View style={styles.unreadCircle}> </View>}
          <Text style={styles.sidebarItemHeader}>{item.title}</Text>
        </View>
        <Text style={styles.sidebarItemDate}>{item.date}</Text>
      </TouchableOpacity>
    );
  };

  const ContentItem = (item: any, index: number) => {
    return (
      <View
        key={index}
        style={
          width > 768
            ? styles.contentItemContainer
            : styles.contentItemContainerMobile
        }
      >
        <View style={{ ...styles.flexRow, justifyContent: "flex-start" }}>
          <Button
            style={styles.backButton}
            accessoryLeft={BackIcon}
            onPress={() => setSelectedItem(-1)}
          />
        </View>
        <ScrollView style={styles.scrollView}>
          <Text style={styles.contentItemHeader}>{item.title}</Text>
          <Text style={styles.contentItemDate}>{item.date}</Text>
          <View style={styles.dividerWide} />
          <View
            style={{
              ...(width > 768 ? { marginHorizontal: 34 } : {}),
              height: 300,
            }}
          >
            <RichTextEditor
              initialText={item.body}
              readOnly
              theme={
                authStore.user?.isStaff || authStore.user?.isUnderwriter
                  ? "light"
                  : "dark"
              }
            />
          </View>
          {item.attachmentsMeta.length ? (
            <View>
              <View style={styles.flexRowLeft}>
                {item.attachmentsMeta.map((file: any) => (
                  <Button
                    style={styles.fileButton}
                    status="basic"
                    appearance="filled"
                    accessoryLeft={FileIcon}
                    onPress={() =>
                      downloadFile(
                        file.id,
                        file.name,
                        authStore?.accessToken || ""
                      )
                    }
                    children={() => (
                      <Text style={styles.fileButtonText}>{file.name}</Text>
                    )}
                  />
                ))}
              </View>
            </View>
          ) : (
            <></>
          )}
          {item.replies.length ? (
            <>
              {item.replies.map((reply: any) => (
                <View key={"reply-" + reply.id}>
                  <View style={styles.dividerWide} />
                  {/* <Text style={styles.contentItemMessage}>{reply.body}</Text> */}
                  <RichTextEditor
                    readOnly
                    initialText={reply.body}
                    theme="dark"
                  />
                  <View
                    style={{
                      ...styles.flexRow,
                      justifyContent: "flex-start",
                    }}
                  >
                    {reply.attachmentsMeta &&
                      reply.attachmentsMeta.map((file: any) => (
                        <Button
                          style={styles.fileButton}
                          status="basic"
                          appearance="filled"
                          accessoryLeft={FileIcon}
                          onPress={() =>
                            downloadFile(
                              file.id,
                              file.name,
                              authStore?.accessToken || ""
                            )
                          }
                          children={() => (
                            <Text style={styles.fileButtonText}>
                              {file.name}
                            </Text>
                          )}
                        />
                      ))}
                  </View>
                </View>
              ))}
            </>
          ) : (
            <></>
          )}
        </ScrollView>
        <View>
          <View style={styles.dividerWide} />
          <NewRequestForm
            addNewRequest={addNewReply}
            rootMessageId={requestedDocumentsDisplayList[selectedItem].id}
            parentMessageId={
              requestedDocumentsDisplayList[selectedItem].replies.length
                ? requestedDocumentsDisplayList[selectedItem].replies[
                    requestedDocumentsDisplayList[selectedItem].replies.length -
                      1
                  ].id
                : requestedDocumentsDisplayList[selectedItem].id
            }
            theme={
              authStore.user?.isStaff || authStore.user?.isUnderwriter
                ? "light"
                : "dark"
            }
          />
        </View>
      </View>
    );
  };

  return (
    <ThemeProvider
      theme={
        authStore.user?.isStaff || authStore.user?.isUnderwriter
          ? eva.light
          : eva.dark
      }
    >
      <Layout style={styles.container}>
        <Header
          theme={
            authStore.user?.isStaff || authStore.user?.isUnderwriter
              ? "light"
              : "dark"
          }
        />
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Text style={width > 768 ? styles.header : styles.headerMobile}>
            Request Center
          </Text>
          <LinearGradient
            style={styles.gradientBar}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={["#D012B2", "#8A1CDB", "#5327FF", "#1D84FF", "#26DDF1"]}
          />
          <View style={styles.adminDashboardContainer}>
            <View
              style={
                width > 768
                  ? styles.adminDashBoard
                  : styles.adminDashBoardMobile
              }
            >
              <View
                style={
                  width > 768
                    ? styles.requestedDocumentsContainer
                    : styles.requestedDocumentsContainerMobile
                }
              >
                {(width > 768 || (width <= 768 && selectedItem === -1)) && (
                  <View
                    style={
                      width > 768
                        ? styles.requestedDocumentsSidebar
                        : styles.requestedDocumentsSidebarMobile
                    }
                  >
                    {requestedDocumentsDisplayList.length > 0 ? (
                      requestedDocumentsDisplayList.map(
                        (item: any, index: number) => SidebarItem(item, index)
                      )
                    ) : loading ? (
                      <LoadingIndicator />
                    ) : (
                      SidebarItem(
                        { title: "No requests right now.", date: "" },
                        -1
                      )
                    )}
                  </View>
                )}
                {!noRequests &&
                  (width > 768 || (width <= 768 && selectedItem > -1)) && (
                    <View
                      style={
                        width > 768
                          ? styles.requestedDocumentContent
                          : styles.requestedDocumentContentMobile
                      }
                    >
                      {selectedItem > -1 &&
                        ContentItem(
                          requestedDocumentsDisplayList[selectedItem],
                          selectedItem
                        )}
                    </View>
                  )}
              </View>
            </View>
          </View>
        </ScrollView>
        {isContactUsOpen && (
          <View style={styles.contactUsPopupContainer}>
            <ContactUsPopup />
          </View>
        )}
        <ContactUsAnchor />
      </Layout>
    </ThemeProvider>
  );
};

export default RequestCenter;
