import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
import * as api from "../../services/api";
import { useStore } from "../../stores";

const QuickbooksARTable: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  const tableWidth = Dimensions.get("window").width - 300;

  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const { authStore } = useStore();
  const defaultItems: any = [];
  const [items, setItems] = useState(defaultItems);

  const fieldsDesc: { [key: string]: any } = {
    account: {
      width: (tableWidth * 1.25) / 12,
      display: "A/R Account",
    },
    first30Days: {
      width: (tableWidth * 1.25) / 12,
      display: "0 - 30 Days",
    },
    days30To60: {
      width: (tableWidth * 1.25) / 12,
      display: "31 - 60 Days",
    },
    days60To90: {
      width: (tableWidth * 1.25) / 12,
      display: "61 - 90 Days",
    },
    over90Days: {
      width: (tableWidth * 1.25) / 12,
      display: "Over 90 Days",
    },
    total: {
      width: (tableWidth * 1.25) / 12,
      display: "Total",
    },
  };

  const getARData = async () => {
    try {
      const results = await api.getARPaging(
        authStore?.accessToken || "",
        Number(id)
      );
      if ("entities" in results.response) {
        const { entities } = results.response;
        setItems(entities);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getARData();
  }, []);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>AR Paging</Text>
            <View style={styles.tableContainer}>
              <View style={styles.tableHeaders}>
                {Object.values(fieldsDesc).map((field) => (
                  <View
                    key={"header-" + field.display}
                    style={{
                      ...{ width: field.width },
                      ...styles.tableHeadersCell,
                    }}
                  >
                    <Text style={styles.tableHeadersCell}>{field.display}</Text>
                  </View>
                ))}
              </View>
              <View>
                {!!items &&
                  items.map((client: any) => (
                    <View key={client.account + "-row"} style={styles.tableRow}>
                      {Object.keys(fieldsDesc).map((fieldName: string) => (
                        <View
                          key={
                            client.account +
                            "-row-data-" +
                            fieldsDesc[fieldName].display
                          }
                          style={{
                            ...{ width: fieldsDesc[fieldName].width },
                            ...(fieldName === "status"
                              ? // @ts-ignore
                                styles[client[fieldName]]
                              : {}),
                            ...styles.tableCell,
                          }}
                        >
                          <Text
                            style={{
                              ...styles.tableCell,
                              ...(fieldName === "status"
                                ? // @ts-ignore
                                  styles[client[fieldName]]
                                : {}),
                            }}
                          >
                            {client[fieldName]}
                          </Text>
                        </View>
                      ))}
                    </View>
                  ))}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default QuickbooksARTable;
