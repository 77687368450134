import * as eva from "@eva-design/eva";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Spinner, ThemeProvider } from "@ui-kitten/components";
import * as Linking from "expo-linking";
import { observer } from "mobx-react-lite";
import React from "react";

import AdminRequestCenter from "../containers/AdminRequestCenter";
import ClosingDocuments from "../containers/ClosingDocuments";
import UserLendingDocModal from "../containers/ClosingDocuments/UserLendingDocModal";
import UserLendingDocModalEnd from "../containers/ClosingDocuments/UserLendingDocModalEnd";
import UserLoanTermsModal from "../containers/ClosingDocuments/UserLoanTermsModal";
import UserRetrieveSignedDoc from "../containers/ClosingDocuments/UserRetrieveSignedDoc";
import UserRetrieveSignedLoanTerms from "../containers/ClosingDocuments/UserRetrieveSignedLoanTerms";
import Dashboard from "../containers/Dashboard";
import FullApplication from "../containers/FullApplication";
import LoanView from "../containers/LoanView";
import MBOPrequal from "../containers/MBOPrequal";
import ManageProfile from "../containers/ManageProfile";
import NotQualified from "../containers/NotQualified";
import PreApproval from "../containers/PreApproval";
import { QuickbooksRedirect } from "../containers/PreApproval/QuickbooksRedirect";
import { StripeRedirect } from "../containers/PreApproval/StripeRedirect";
import PreQualification from "../containers/PreQualification";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import ProfileEdit from "../containers/ProfileEdit";
import QuickbooksARTable from "../containers/Quickbooks/QuickbooksARTable";
import QuickbooksBalanceSheet from "../containers/Quickbooks/QuickbooksBalanceSheet";
import QuickbooksIncomeStatement from "../containers/Quickbooks/QuickbooksIncomeStatement";
import Register from "../containers/Register";
import RequestCenter from "../containers/RequestCenter";
import TermsOfService from "../containers/TermsOfService";
import { useStore } from "../stores";
import AdminStack from "./stacks/AdminStack";
import AuthStack from "./stacks/AuthStack";
import ComplianceStack from "./stacks/ComplianceStack";
import InvestorStack from "./stacks/InvestorStack";
import LoanServicingStack from "./stacks/LoanServicingStack";
import UnderwriterStack from "./stacks/UnderwriterStack";
import ReferralPartnerStack from "./stacks/ReferralPartnerStack";
import InboundReferralStack from "./stacks/InboundReferralManagerStack";
type Props = object;
import "./main.css";

const Stack = createStackNavigator();
const prefix = Linking.createURL("/");

import { NativeWindStyleSheet } from "nativewind";

NativeWindStyleSheet.setOutput({
  default: "native",
});

const AppContainer: React.FC<Props> = (_props) => {
  const { authStore } = useStore();
  const linking = {
    prefixes: [prefix],
  };

  return (
    <NavigationContainer linking={linking} fallback={<Spinner />}>
      {authStore && authStore.accessToken && authStore.user?.isStaff ? (
        // User is Admin
        <ThemeProvider theme={eva.light}>
          <Stack.Navigator initialRouteName="underwriter" headerMode="none">
            <Stack.Screen name="underwriter" component={UnderwriterStack} />
            {authStore.user?.isInvestor && (
              <Stack.Screen name="investor" component={InvestorStack} />
            )}
            <Stack.Screen name="admin" component={AdminStack} />
            {authStore.user?.isLoanAgent && (
              <Stack.Screen
                name="loan-servicing"
                component={LoanServicingStack}
              />
            )}
            {authStore.user?.isInboundReferralManager && (
              <Stack.Screen
                name="inbound-referrals"
                component={InboundReferralStack}
              />
            )}
            <Stack.Screen
              name="admin-request-center"
              options={{ title: "OppZo | Admin Request Center" }}
              component={AdminRequestCenter}
            />
            <Stack.Screen
              name="manage-profile"
              options={{ title: "OppZo | Manage Profile" }}
              component={ManageProfile}
            />
          </Stack.Navigator>
        </ThemeProvider>
      ) : authStore && authStore.accessToken && authStore.user?.isInvestor ? (
        // User is Investor
        <ThemeProvider theme={eva.light}>
          <Stack.Navigator initialRouteName="investor" headerMode="none">
            <Stack.Screen name="investor" component={InvestorStack} />
            <Stack.Screen
              name="manage-profile"
              options={{ title: "OppZo | Manage Profile" }}
              component={ManageProfile}
            />
          </Stack.Navigator>
        </ThemeProvider>
      ) : authStore &&
        authStore.accessToken &&
        authStore.user?.isUnderwriter ? (
        // User is Underwriter
        <ThemeProvider theme={eva.light}>
          <Stack.Navigator initialRouteName="underwriter" headerMode="none">
            <Stack.Screen name="underwriter" component={UnderwriterStack} />
            <Stack.Screen
              name="request-center"
              options={{ title: "OppZo | Request Center" }}
              component={RequestCenter}
            />
            <Stack.Screen
              name="manage-profile"
              options={{ title: "OppZo | Manage Profile" }}
              component={ManageProfile}
            />
          </Stack.Navigator>
        </ThemeProvider>
      ) : authStore && authStore.accessToken && authStore.user?.isLoanAgent ? (
        // User is Underwriter
        <ThemeProvider theme={eva.light}>
          <Stack.Navigator initialRouteName="loan-servicing" headerMode="none">
            <Stack.Screen
              name="loan-servicing"
              component={LoanServicingStack}
            />
            <Stack.Screen
              name="manage-profile"
              options={{ title: "OppZo | Manage Profile" }}
              component={ManageProfile}
            />
          </Stack.Navigator>
        </ThemeProvider>
      ) : authStore?.accessToken && authStore.user?.isInboundReferralManager ? (
        <ThemeProvider theme={eva.light}>
          <Stack.Navigator
            initialRouteName="inbound-referrals"
            headerMode="none"
          >
            <Stack.Screen
              name="inbound-referrals"
              component={InboundReferralStack}
            />
          </Stack.Navigator>
        </ThemeProvider>
      ) : authStore?.accessToken && authStore?.user?.isReferralPartner ? (
        <ThemeProvider theme={eva.dark}>
          <Stack.Navigator initialRouteName="referral" headerMode="none">
            <Stack.Screen
              name="referral"
              options={{ title: "OppZo | Referral Partner" }}
              component={ReferralPartnerStack}
            />
            <Stack.Screen
              name="manage-profile"
              options={{ title: "OppZo | Manage Profile" }}
              component={ManageProfile}
            />
          </Stack.Navigator>
        </ThemeProvider>
      ) : authStore &&
        authStore.accessToken &&
        authStore.user?.id &&
        !(authStore.user?.businesses || []).length &&
        authStore.user?.isPrimaryApplicant ? (
        // User is authenticated, but registration is not done (no businesses)
        <Stack.Navigator initialRouteName="registration" headerMode="none">
          <Stack.Screen
            name="registration"
            options={{ title: "OppZo | Registration" }}
            component={Register}
          />
        </Stack.Navigator>
      ) : authStore && authStore.accessToken && authStore.user?.id ? (
        // User is authenticated
        <Stack.Navigator initialRouteName="dashboard" headerMode="none">
          <Stack.Screen
            name="dashboard"
            options={{ title: "OppZo | Loan Tracker" }}
            component={Dashboard}
          />
          <Stack.Screen
            name="pre-qualification"
            options={{ title: "OppZo | Pre-Qualification" }}
            component={PreQualification}
          />
          <Stack.Screen
            name="not-qualified"
            options={{ title: "OppZo | Rejected" }}
            component={NotQualified}
          />
          <Stack.Screen
            name="pre-approval"
            options={{ title: "OppZo | Pre-Approval" }}
            component={PreApproval}
          />
          <Stack.Screen
            name="mbo-prequal"
            options={{ title: "OppZo | Pre-Approval" }}
            component={MBOPrequal}
          />
          <Stack.Screen
            name="manage-profile"
            options={{ title: "OppZo | Manage Profile" }}
            component={ManageProfile}
          />
          <Stack.Screen
            name="full-application"
            options={{ title: "OppZo | Full-Application" }}
            component={FullApplication}
          />
          <Stack.Screen
            name="request-center"
            options={{ title: "OppZo | Request Center" }}
            component={RequestCenter}
          />
          <Stack.Screen
            name="loan-view"
            options={{ title: "OppZo | Loan View" }}
            component={LoanView}
          />
          <Stack.Screen
            name="closing-documents"
            options={{ title: "OppZo | Closing Documents" }}
            component={ClosingDocuments}
          />
          <Stack.Screen
            name="stripe-redirect"
            options={{ title: "OppZo | Stripe redirect" }}
            component={StripeRedirect}
          />
          <Stack.Screen
            name="qb-redirect"
            options={{ title: "OppZo | Quickbooks redirect" }}
            component={QuickbooksRedirect}
          />
          <Stack.Screen
            name="edit-profile"
            options={{ title: "OppZo | Edit Profile" }}
            component={ProfileEdit}
          />
          <Stack.Screen
            name="qb-ar-paging"
            options={{ title: "OppZo | AR Paging" }}
            component={QuickbooksARTable}
          />
          <Stack.Screen
            name="qb-balance-sheet"
            options={{ title: "OppZo | Balance Sheet" }}
            component={QuickbooksBalanceSheet}
          />
          <Stack.Screen
            name="qb-income-statement"
            options={{ title: "OppZo | Income Statement" }}
            component={QuickbooksIncomeStatement}
          />
          <Stack.Screen
            name="privacy-policy"
            options={{ title: "OppZo | Privacy Policy" }}
            component={PrivacyPolicy}
          />
          <Stack.Screen
            name="terms-of-service"
            options={{ title: "OppZo | Terms of Service" }}
            component={TermsOfService}
          />
          <Stack.Screen
            name="lending-document-modal"
            options={{ title: "OppZo | Full Lending Document" }}
            component={UserLendingDocModal}
          />
          <Stack.Screen
            name="lending-document-modal-end"
            options={{ title: "OppZo | Full Lending Document" }}
            component={UserLendingDocModalEnd}
          />
          <Stack.Screen
            name="user-retrieve-signed-modal"
            options={{ title: "OppZo | Full Lending Document" }}
            component={UserRetrieveSignedDoc}
          />
          <Stack.Screen
            name="user-loan-terms-modal"
            options={{ title: "OppZo | Loan Terms" }}
            component={UserLoanTermsModal}
          />
          <Stack.Screen
            name="user-retrieve-signed-loan-terms-modal"
            options={{ title: "OppZo | Loan Terms" }}
            component={UserRetrieveSignedLoanTerms}
          />
          <Stack.Screen name="compliance" component={ComplianceStack} />
        </Stack.Navigator>
      ) : (
        // User is not authenticated
        <Stack.Navigator initialRouteName="auth" headerMode="none">
          <Stack.Screen name="auth" component={AuthStack} />
          <Stack.Screen name="admin" component={AdminStack} />
          <Stack.Screen name="investor" component={InvestorStack} />
          <Stack.Screen name="compliance" component={ComplianceStack} />
          <Stack.Screen name="loan-servicing" component={LoanServicingStack} />
          <Stack.Screen name="referral" component={ReferralPartnerStack} />
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
};

export default observer(AppContainer);
