import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  modalScroll: {
    borderWidth: 1,
    borderColor: "white",
    padding: 50,
  },
  modalScrollMobile: {
    borderWidth: 1,
    borderColor: "white",
    padding: 25,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 60,
    fontSize: 48,
    marginBottom: 70,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
    marginBottom: 34,
  },
  userName: {
    color: "white",
    textTransform: "capitalize",
  },
  subHeader: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
    marginBottom: 20,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 18,
    fontSize: 12,
    marginBottom: 34,
  },
  loanHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 20,
    fontSize: 16,
    marginBottom: 34,
  },
  description: {
    fontFamily: "Montserrat_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
  },
  descriptionMobile: {
    fontFamily: "Montserrat_600SemiBold",
    lineHeight: 20,
    fontSize: 10,
  },
  loanTracker: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    marginVertical: 100,
    marginHorizontal: 50,
    maxWidth: 800,
  },
  loanTrackerMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    marginVertical: 100,
    marginHorizontal: 22,
  },
  loanTrackerContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  nextActionButton: {
    width: "25%",
    marginVertical: 30,
  },
  loanButton: {
    marginVertical: 10,
    display: "flex",
    justifyContent: "space-between",
    background:
      "linear-gradient(97.13deg, rgba(29, 132, 255, 0.8) 8.56%, rgba(38, 221, 241, 0.8) 98.84%)",
    border: 0,
    paddingRight: 25,
  },
  loanButtonDisabled: {
    marginVertical: 10,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#2e2837",
    border: 0,
    paddingRight: 25,
  },
  rejectedLoanButton: {
    marginVertical: 10,
    display: "flex",
    justifyContent: "space-between",
    background:
      "linear-gradient(90.11deg, rgb(108,32,47) 0.12%, rgb(108,32,47) 100%)",
    border: 0,
    paddingRight: 25,
  },
  fundedLoanButton: {
    marginVertical: 10,
    display: "flex",
    justifyContent: "space-between",
    background:
      "linear-gradient(90.11deg, rgba(208, 18, 178, 0.8) 0.12%, rgba(138, 28, 219, 0.8) 23.28%, rgba(83, 39, 255, 0.8) 48.75%, rgba(29, 132, 255, 0.8) 75.79%, rgba(38, 221, 241, 0.8) 98.97%)",
    border: 0,
    paddingRight: 25,
  },
  loanButtonTextContainer: {
    marginLeft: 75,
  },
  loanButtonTextContainerMobile: {
    marginLeft: 15,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "auto",
    backgroundColor: "#101426",
    padding: 50,
    marginHorizontal: 100,
    height: 600,
  },
  modalContainerMobile: {
    width: "auto",
    backgroundColor: "#101426",
    padding: 25,
    marginHorizontal: 25,
    height: 600,
  },
  modalText: {
    color: "white",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 50,
  },
  modalTextMobile: {
    color: "white",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 25,
  },
  modalSubText: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    fontSize: 18,
    lineHeight: 24,
    marginBottom: 50,
  },
  modalSubTextMobile: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 25,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalButton: {
    backgroundColor: "#1D84FF",
    width: 200,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  link: {
    textDecorationLine: "underline",
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
});
