import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import AdminLogin from "../../containers/AdminLogIn";
import InvestorDashboard from "../../containers/InvestorDashboard";
import InvestorLoanTape from "../../containers/InvestorLoanTape";
import InvestorPortalFinalDecision from "../../containers/InvestorUWPortal";
import ManageProfile from "../../containers/ManageProfile";
import ResetPassword from "../../containers/ResetPassword";
import ConfirmResetPassword from "../../containers/ResetPassword/ConfirmResetPassword";
import Collateral from "../../containers/UnderwriterPortalCollateral";
import CreditFiles from "../../containers/UnderwriterPortalCreditFiles";
import Decision from "../../containers/UnderwriterPortalDecision";
import RiskFlags from "../../containers/UnderwriterPortalRiskFlags";
import Summary from "../../containers/UnderwriterPortalSummary";
import { useStore } from "../../stores";

export type InvestorStackParamList = {
  dashboard: undefined;
  "loan-tape": undefined;
  "uw-portal": undefined;
  summary: any;
  collateral: any;
  decision: any;
  "risk-flags": any;
  "credit-files": any;
  "log-in": undefined;
  "password-reset": undefined;
  "password-reset-confirm": undefined;
  "manage-profile": undefined;
};

export type AuthStackNavigation = StackNavigationProp<InvestorStackParamList>;

type Props = object;

const Stack = createStackNavigator<InvestorStackParamList>();

const InvestorStack: React.FC<Props> = (props) => {
  const { authStore } = useStore();
  return authStore.user?.isInvestor && authStore.accessToken ? (
    <Stack.Navigator initialRouteName="dashboard" headerMode="none">
      <Stack.Screen
        name="dashboard"
        options={{ title: "OppZo | Investor Portal | Dashboard" }}
        component={InvestorDashboard}
      />
      <Stack.Screen
        name="loan-tape"
        options={{ title: "OppZo | Investor Portal | Loan Tape" }}
        component={InvestorLoanTape}
      />
      <Stack.Screen
        name="uw-portal"
        options={{ title: "OppZo | Investor Portal | Underwriter Portal" }}
        component={InvestorPortalFinalDecision}
      />
      <Stack.Screen
        name="summary"
        options={{ title: "OppZo | Investor Portal | Loan summary" }}
        component={Summary}
        initialParams={{ investorView: true }}
      />
      <Stack.Screen
        name="collateral"
        options={{
          title: "OppZo | Investor Portal | Collateral & Reports",
        }}
        component={Collateral}
        initialParams={{ investorView: true }}
      />
      <Stack.Screen
        name="manage-profile"
        options={{ title: "OppZo | Investor Portal | Manage Profile" }}
        component={ManageProfile}
      />
      <Stack.Screen
        name="risk-flags"
        options={{
          title: "OppZo | Investor Portal | Risk Flags",
        }}
        component={RiskFlags}
        initialParams={{ investorView: true }}
      />
      <Stack.Screen
        name="decision"
        options={{
          title: "OppZo | Investor Portal | Decision",
        }}
        component={Decision}
        initialParams={{ investorView: true }}
      />
      <Stack.Screen
        name="credit-files"
        options={{
          title: "OppZo | Investor Portal | Decision",
        }}
        component={CreditFiles}
        initialParams={{ investorView: true }}
      />
    </Stack.Navigator>
  ) : (
    <Stack.Navigator initialRouteName="log-in" headerMode="none">
      <Stack.Screen
        name="log-in"
        options={{ title: "OppZo | Admin Portal Log In" }}
        component={AdminLogin}
      />
      <Stack.Screen
        name="password-reset"
        options={{ title: "OppZo | Password Reset" }}
        component={ResetPassword}
      />
      <Stack.Screen
        name="password-reset-confirm"
        options={{ title: "OppZo | Password Reset Confirm" }}
        component={ConfirmResetPassword}
      />
    </Stack.Navigator>
  );
};

export default InvestorStack;
