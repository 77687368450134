import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import AdminLogin from "../../containers/AdminLogIn";
import ManageProfile from "../../containers/ManageProfile";
import ResetPassword from "../../containers/ResetPassword";
import ConfirmResetPassword from "../../containers/ResetPassword/ConfirmResetPassword";
import ServicingMonitoringDashboard from "../../containers/ServicingMonitoringDashboard";
import { useStore } from "../../stores";

export type LoanServicingStackParamList = {
  dashboard: undefined;
  "log-in": undefined;
  "password-reset": undefined;
  "password-reset-confirm": undefined;
  "manage-profile": undefined;
};

export type AuthStackNavigation =
  StackNavigationProp<LoanServicingStackParamList>;

type Props = object;

const Stack = createStackNavigator<LoanServicingStackParamList>();

const LoanServicingStack: React.FC<Props> = (props) => {
  const { authStore } = useStore();
  return authStore.accessToken &&
    authStore.user &&
    (authStore.user.isUnderwriter || authStore.user.isLoanAgent) ? (
    <Stack.Navigator initialRouteName="dashboard" headerMode="none">
      <Stack.Screen
        name="dashboard"
        options={{ title: "OppZo | Loan Servicing Monitoring Dashboard" }}
        component={ServicingMonitoringDashboard}
      />
      <Stack.Screen
        name="manage-profile"
        options={{ title: "OppZo | Manage profile" }}
        component={ManageProfile}
      />
    </Stack.Navigator>
  ) : (
    <Stack.Navigator initialRouteName="log-in" headerMode="none">
      <Stack.Screen
        name="log-in"
        options={{ title: "OppZo | Loan Servicer Portal Log In" }}
        component={AdminLogin}
      />
      <Stack.Screen
        name="password-reset"
        options={{ title: "OppZo | Password Reset" }}
        component={ResetPassword}
      />
      <Stack.Screen
        name="password-reset-confirm"
        options={{ title: "OppZo | Password Reset Confirm" }}
        component={ConfirmResetPassword}
      />
    </Stack.Navigator>
  );
};

export default LoanServicingStack;
