import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import Login from "../../containers/LogIn";
import ManageProfile from "../../containers/ManageProfile";
import ReferralPartnerDashboard from "../../containers/ReferralPartnerDashboard";
import ReferralPartnerGuidelines from "../../containers/ReferralPartnerGuidelines";
import ResetPassword from "../../containers/ResetPassword";
import ReferralPartnerRegistration from "../../containers/ReferralPartnerRegistration";
import ConfirmResetPassword from "../../containers/ResetPassword/ConfirmResetPassword";

import { useStore } from "../../stores";

export type ReferralPartnerStackParams = {
  "log-in": undefined;
  "manage-profile": undefined;
  "password-reset": undefined;
  "password-reset-confirm": undefined;
  dashboard: undefined;
  guidelines: undefined;
  register: undefined;
};

export type AuthStackNavigation =
  StackNavigationProp<ReferralPartnerStackParams>;

const Stack = createStackNavigator<ReferralPartnerStackParams>();

const ReferralPartnerStack: any = (props) => {
  const { authStore } = useStore();
  return authStore?.accessToken && authStore?.user?.isReferralPartner ? (
    <Stack.Navigator initialRouteName="dashboard" headerMode="none">
      <Stack.Screen
        name="dashboard"
        options={{ title: "OppZo | Referral Partner Portal | Dashboard" }}
        component={ReferralPartnerDashboard}
      />
      <Stack.Screen
        name="guidelines"
        options={{ title: "OppZo | Referral Partner Portal | Guidelines" }}
        component={ReferralPartnerGuidelines}
      />
      <Stack.Screen
        name="manage-profile"
        options={{
          title: "OppZo | Inbound Referral Partners | Manage Profile",
        }}
        component={ManageProfile}
      />
    </Stack.Navigator>
  ) : (
    <Stack.Navigator initialRouteName="log-in" headerMode="none">
      <Stack.Screen
        name="log-in"
        options={{ title: "OppZo | Referral Partner Portal Log In" }}
        component={Login}
      />
      <Stack.Screen
        name="register"
        options={{ title: "OppZo | Referral Partner Portal Sign Up" }}
        component={ReferralPartnerRegistration}
      />
      <Stack.Screen
        name="password-reset"
        options={{ title: "OppZo | Password Reset" }}
        component={ResetPassword}
      />
      <Stack.Screen
        name="password-reset-confirm"
        options={{ title: "OppZo | Password Reset Confirm" }}
        component={ConfirmResetPassword}
      />
    </Stack.Navigator>
  );
};

export default ReferralPartnerStack;
