import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  adminDashBoard: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginLeft: 50,
    marginRight: 25,
    maxWidth: 1450,
    overflow: "hidden",
  },
  adminDashboardContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    flex: 1,
    fontSize: 22,
    margin: 5,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  LogInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    margin: 2,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 250,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#EDF1F7",
  },
  tableHeadersCell: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    padding: 1,
    margin: 1,
    alignSelf: "center",
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  tableCell: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 20,
    fontSize: 14,
    padding: 1,
    margin: 1,
    alignSelf: "center",
    borderRadius: 5,
  },
  tableContainer: {},
  ACTIVE: {
    backgroundColor: "color-success-default",
    color: "white",
    fontFamily: "Montserrat_700Bold",
  },
  INACTIVE: {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
  },
  "TIMED OUT": {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
    color: "text-color-basic-default",
  },
  DENIED: {
    backgroundColor: "color-danger-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  PENDING: {
    backgroundColor: "color-warning-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  requestedDocumentsContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "stretch",
    height: 500,
    width: 1350,
  },
  requestedDocumentsSidebar: {
    flex: 2,
    border: 1,
    borderColor: "#C5CEE0",
    borderStyle: "solid",
    height: 500,
  },
  requestedDocumentContent: {
    flex: 5,
    border: 1,
    borderColor: "#C5CEE0",
    borderStyle: "solid",
    height: 500,
    paddingHorizontal: 75,
    paddingVertical: 20,
  },
  activeNotificationCircle: {
    width: 15,
    height: 15,
    margin: 15,
    background: "color-success-default",
    borderRadius: 50,
  },
  pendingNotificationCircle: {
    width: 15,
    height: 15,
    margin: 15,
    background: "color-warning-default",
    borderRadius: 50,
  },
  deniedNotificationCircle: {
    width: 15,
    height: 15,
    margin: 15,
    background: "color-danger-default",
    borderRadius: 50,
  },
  requestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-basic-300",
  },
  selectedRequestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-basic-500",
    backgroundColor: "color-basic-300",
  },
  newRequestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-primary-300",
    backgroundColor: "color-primary-200",
  },
  selectedNewRequestDocument: {
    borderBottomWidth: 2,
    borderBottomColor: "color-primary-500",
    backgroundColor: "color-primary-300",
  },
  requestTitle: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 26,
    flex: 1,
    margin: 5,
  },
  requestDate: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 12,
    flex: 1,
    margin: 5,
  },
  requestBody: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 20,
    fontSize: 15,
    flex: 1,
    margin: 5,
    padding: 20,
  },
  requestSubHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 16,
    flex: 1,
    margin: 5,
    padding: 5,
  },
  attachment: {
    margin: 10,
    padding: 5,
    paddingVertical: 15,
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  requestSectionTitle: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    margin: 10,
  },
  textArea: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 15,
    minHeight: 150,
    margin: 10,
  },
  saveButton: {
    width: 30,
    height: 30,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "100%",
    backgroundColor: "white",
    padding: 50,
  },
  modalText: {
    color: "black",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalYesButton: {
    backgroundColor: "#1D84FF",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalNoButton: {
    backgroundColor: "#222B45",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  welcomeMessageText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 24,
    fontSize: 13,
    textAlign: "left",
  },
  select: {
    flex: 1,
    fontSize: 22,
    marginBottom: 40,
    background: "white",
    minWidth: 150,
    height: 20,
  },
  selectAlt: {
    fontSize: 22,
    marginBottom: 40,
    background: "white",
    width: 250,
    height: 20,
  },
  selectItem: {
    backgroundColor: "white",
    color: "#222B45",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
  },
});
