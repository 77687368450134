import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  LogIn: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 900,
  },
  LogInContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
  },
  input: {
    flex: 1,
    fontSize: 22,
    marginBottom: 30,
  },
  logInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  logInButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "white",
    fontSize: 14,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 120,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  forgotPasswordText: {
    fontFamily: "Montserrat_400Regular",
    color: "#1D84FF",
    textAlign: "right",
    fontSize: 15,
    lineHeight: 24,
  },
});
