import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
  Select,
  SelectItem,
  IndexPath,
  Tooltip,
} from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { FilterOptionsModal } from "./FilterOptionsModal";
import { themedStyles } from "./themedStyles";

type Props = object;

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

export const SORT = {
  NEW: "NEW",
  OLD: "OLD",
  ALPHABET_INC: "ALPHA_INC",
  ALPHABET_DEC: "ALPHA_DEC",
};

export const possibleLocations = ["Florida", "Virginia", "Other"];
export const possibleStatus = ["NEW", "PENDING", "COMPLETE", "TIMED OUT"];
export const possibleTypes = [
  "S Corporation",
  "C Corporation",
  "Nonprofit Corporation",
  "Limited Liability Corporation (LLC)",
  "Limited Liability Partnership (LLP)",
  "Partnership",
];

const displayPerPageOptions = [10, 25, 50, 100];

const AdminDashboard: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const colorBar = require("../../../assets/colorBar.svg");
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;
  const LeftArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-back-outline" />
  );
  const RightArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-forward-outline" />
  );
  const KebabMenuIcon = (props: any) => (
    <Icon {...props} name="more-vertical-outline" />
  );
  const OptionsIcon = (props: any) => (
    <Icon {...props} name="options-2-outline" />
  );
  const MessageIcon = (props: any) => (
    <Icon {...props} name="message-circle-outline" />
  );

  const navigation = useNavigation();

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [displayPerPage, setDisplayPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortingMode, setSortingMode] = useState(SORT.NEW);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const maxWidth = styles.adminDashBoard.maxWidth
    ? Number(styles.adminDashBoard.maxWidth)
    : 1080;
  const tableWidth = width <= maxWidth ? width - 300 : maxWidth - 300;
  const totalColumns = 12;
  const fieldsDesc: { [key: string]: any } = {
    loanApplicationId: {
      width: (tableWidth * 1.25) / totalColumns,
      display: "Application ID",
    },
    authorName: {
      width: (tableWidth * 2.25) / totalColumns,
      display: "Underwriter Name",
    },
    businessName: {
      width: (tableWidth * 1.75) / totalColumns,
      display: "Business Name",
    },
    status: { width: tableWidth / totalColumns, display: "Status" },
    contractNumber: {
      width: (tableWidth * 1.75) / totalColumns,
      display: "Contract Number",
    },
    documentRequestType: {
      width: (tableWidth * 1.75) / totalColumns,
      display: "Request Document",
    },
    created: {
      width: (tableWidth * 1.15) / totalColumns,
      display: "Request Date",
    },
  };
  const testData: any = [];
  for (let i = 0; i < 103; i++)
    testData.push({
      id: 123456 + i,
      loanApplicationId: (i % 2) + 1,
      authorName: [
        "John Snow",
        "Harry Potter",
        "Klein Moretti",
        "Ren Dover",
        "Azazel Donovan",
      ][i % 5],
      businessName:
        ["A", "B", "C", "D", "E", "F"][Math.floor(Math.random() * 5)] + " Corp",
      status: ["NEW", "PENDING", "COMPLETE", "TIMED OUT"][
        Math.floor(Math.random() * 4)
      ],
      contractNumber: (Math.random() * 123456789 + 1000000000).toFixed(0),
      documentRequestType: "document" + Math.floor(Math.random() * 12345),
      description: "desc" + Math.floor(Math.random() * 1234),
      created: `2021-${(Math.random() * 12 + 1).toFixed(0)}-${(
        Math.random() * 28 +
        1
      ).toFixed(0)}`,
    });
  const [requestedDocuments, setRequestedDocuments]: any = useState([]);
  const [requestedDocumentsDisplayList, setRequestedDocumentsDisplayList]: any =
    useState([]);

  const [filterOptions, setFilterOptions]: any = useState({
    byStatus: false,
    byLocation: false,
    byType: false,
    status: [],
    location: [],
    type: [],
  });

  const { documentRequestsStore } = useStore();
  const [isStatusChangeTooltipVisible, setIsStatusChangeTooltipVisible] =
    useState<boolean[]>([]);

  useEffect(() => {
    documentRequestsStore.fetchDocumentRequestMessages().then(() => {
      const docReq: any = [];
      documentRequestsStore.messages.forEach((msg) => {
        if (!msg.isReply)
          docReq.push({
            ...msg.$,
            created: new Date(msg.created).toLocaleDateString(),
            status: msg.status.toUpperCase(),
            businessName: msg.applicantBusinessName,
            contractNumber: msg.applicationContractNumber,
            description: msg.description || "-",
            location: msg.applicantStateOfIncorporation || "",
            type: msg.applicantBusinessType || "",
          });
      });
      setRequestedDocuments(docReq);
    });
  }, []);

  useEffect(() => {
    setDisplayPerPage(displayPerPageOptions[selectedIndex.row]);
  }, [selectedIndex]);

  useEffect(() => {
    setLoading(true);
    const filtered = requestedDocuments
      .filter(
        (doc: any) =>
          Object.values(doc).filter((field) =>
            `${field}`.toLowerCase().includes(searchString.toLowerCase().trim())
          ).length
      )
      .filter((doc: any) => {
        let ok = true;
        if (
          filterOptions.byStatus &&
          !filterOptions.status.includes(doc["status"].toLowerCase())
        )
          ok = false;

        if (
          filterOptions.byLocation &&
          !filterOptions.location.includes(doc["location"].toLowerCase()) &&
          !filterOptions.location.includes("Other")
        )
          ok = false;

        if (
          filterOptions.byType &&
          !filterOptions.type.includes(doc["type"].toLowerCase())
        )
          ok = false;

        return ok;
      });
    const sorted = filtered.sort((a: any, b: any) => {
      if (
        (sortingMode === SORT.ALPHABET_INC ||
          sortingMode === SORT.ALPHABET_DEC) &&
        a.businessName.toLowerCase() === b.businessName.toLowerCase()
      )
        return 0;
      if (sortingMode === SORT.ALPHABET_INC)
        return a.businessName.toLowerCase() < b.businessName.toLowerCase()
          ? -1
          : 1;
      if (sortingMode === SORT.ALPHABET_DEC)
        return a.businessName.toLowerCase() > b.businessName.toLowerCase()
          ? -1
          : 1;

      if (sortingMode === SORT.NEW) return Number(a.id) > Number(b.id) ? -1 : 1;
      if (sortingMode === SORT.OLD) return Number(a.id) < Number(b.id) ? -1 : 1;

      return 0;
    });

    const results = [];
    for (let i = 1; (i - 1) * displayPerPage < sorted.length; i++)
      results.push(sorted.slice((i - 1) * displayPerPage, i * displayPerPage));

    if (currentPage >= results.length) setCurrentPage(0);

    setIsStatusChangeTooltipVisible(Array(displayPerPage).fill(false));
    setRequestedDocumentsDisplayList(results);
    setLoading(false);
  }, [
    requestedDocuments,
    searchString,
    sortingMode,
    displayPerPage,
    filterOptions,
  ]);

  const { authStore } = useStore();

  const updateRequestedDocumentStatus = async (
    docId: number,
    newStatus: string
  ) => {
    setLoading(true);
    await documentRequestsStore.updateDocumentRequestMessage(docId, {
      ...requestedDocuments.filter((doc: any) => doc.id === docId)[0],
      status: newStatus.toLowerCase(),
    });
    setLoading(false);
    setRequestedDocuments(
      requestedDocuments.map((doc: any) =>
        doc.id !== docId ? doc : { ...doc, status: newStatus }
      )
    );
    setIsStatusChangeTooltipVisible(Array(displayPerPage).fill(false));
  };

  const getPagesButtonsNumbers = useCallback(() => {
    const buttons = [];
    for (
      let i = Math.max(0, currentPage - 2);
      i < Math.min(requestedDocumentsDisplayList.length, currentPage + 3);
      i++
    )
      buttons.push(i);
    return buttons;
  }, [requestedDocumentsDisplayList, currentPage]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <View style={styles.colorBar}>
          <Image
            style={{
              width: 43,
              height: Dimensions.get("window").height - 68,
            }}
            source={colorBar}
          />
        </View>
        <Header theme="light" />
        <FilterOptionsModal
          isOptionsModalOpen={isOptionsModalOpen}
          setIsOptionsModalOpen={setIsOptionsModalOpen}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          sortingMode={sortingMode}
          setSortingMode={setSortingMode}
        />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>Requested Documents</Text>
            <Text style={styles.welcomeMessageText} appearance="hint">
              {`Welcome, ${authStore.user?.firstName} ${authStore.user?.lastName}`}
            </Text>
            <Divider />
            <View>
              <View style={styles.requestedDocumentsHeader}>
                {/* <Text style={styles.header}>Requested Documents</Text> */}
                {__DEV__ ? (
                  <Button onPress={() => setRequestedDocuments(testData)}>
                    load UI test data [DEV]
                  </Button>
                ) : (
                  <></>
                )}
                <View style={{ ...styles.flexRow, flex: 1 }}>
                  <Input
                    style={styles.input}
                    placeholder="Search"
                    value={searchString}
                    onChangeText={(text) => setSearchString(text)}
                    accessoryRight={SearchIcon}
                  />
                  <Button
                    style={styles.optionsButton}
                    status="basic"
                    accessoryRight={OptionsIcon}
                    onPress={() => setIsOptionsModalOpen(true)}
                  />
                  <Text style={styles.flexRow} appearance="hint">
                    Show&nbsp;&nbsp;
                    <Select
                      selectedIndex={selectedIndex}
                      //@ts-ignore
                      onSelect={(index) => setSelectedIndex(index)}
                      value={displayPerPage}
                    >
                      {displayPerPageOptions.map((option) => (
                        <SelectItem
                          style={styles.selectItem}
                          key={"display-per-page-" + option}
                          title={() => (
                            <Text style={styles.selectItem}>{option}</Text>
                          )}
                        />
                      ))}
                    </Select>
                    &nbsp;&nbsp;entries
                  </Text>
                </View>
              </View>
              {requestedDocuments ? (
                <ScrollView
                  style={{
                    ...styles.scroll,
                    maxHeight: Dimensions.get("window").height - 250,
                  }}
                  keyboardShouldPersistTaps="handled"
                >
                  <View style={styles.tableContainer}>
                    <View style={styles.tableHeaders}>
                      <Button status="basic" appearance="ghost" />
                      {Object.values(fieldsDesc).map((field) => (
                        <View
                          key={"header-" + field.display}
                          style={{
                            ...{ width: field.width },
                            ...styles.tableHeadersCell,
                          }}
                        >
                          <Text style={styles.tableHeadersCell}>
                            {field.display}
                          </Text>
                        </View>
                      ))}
                      <Button
                        status="basic"
                        appearance="ghost"
                        accessoryRight={MessageIcon}
                        onPress={() => navigation.navigate("requests")}
                      />
                    </View>
                    {loading && <LoadingIndicator />}
                    {!loading ? (
                      (requestedDocumentsDisplayList[currentPage] || []).map(
                        (doc: any, idx: number) => (
                          <View key={doc.id + "-row"} style={styles.tableRow}>
                            <Tooltip
                              placement="right"
                              anchor={() => (
                                <Button
                                  status="basic"
                                  appearance="ghost"
                                  accessoryRight={KebabMenuIcon}
                                  onPress={() =>
                                    setIsStatusChangeTooltipVisible(
                                      isStatusChangeTooltipVisible.map(
                                        (val: boolean, idx2: number) =>
                                          idx2 === idx ? true : val
                                      )
                                    )
                                  }
                                />
                              )}
                              visible={isStatusChangeTooltipVisible[idx]}
                              onBackdropPress={() =>
                                setIsStatusChangeTooltipVisible(
                                  isStatusChangeTooltipVisible.map(
                                    (val: boolean, idx2: number) =>
                                      idx2 === idx ? false : val
                                  )
                                )
                              }
                              children={() => (
                                <ScrollView>
                                  {possibleStatus.map(
                                    (status: any, idx: number) => (
                                      <View key={status.id}>
                                        <View style={styles.flexRowItemsCenter}>
                                          <Text
                                            style={styles.tooltipTextLight}
                                            onPress={() =>
                                              updateRequestedDocumentStatus(
                                                doc.id,
                                                status
                                              )
                                            }
                                          >
                                            {status}
                                          </Text>
                                        </View>
                                        {idx + 1 !== possibleStatus.length ? (
                                          <View
                                            style={styles.notificationDivider}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </View>
                                    )
                                  )}
                                </ScrollView>
                              )}
                            />
                            {Object.keys(fieldsDesc).map(
                              (fieldName: string) => (
                                <View
                                  key={
                                    doc.id +
                                    "-row-data-" +
                                    fieldsDesc[fieldName].display
                                  }
                                  style={{
                                    ...{ width: fieldsDesc[fieldName].width },
                                    ...(fieldName === "status"
                                      ? // @ts-ignore
                                        styles[doc[fieldName]]
                                      : {}),
                                    ...styles.tableCell,
                                  }}
                                >
                                  <Text
                                    numberOfLines={1}
                                    style={{
                                      ...styles.tableCell,
                                      ...(fieldName === "status"
                                        ? // @ts-ignore
                                          styles[doc[fieldName]]
                                        : {}),
                                    }}
                                  >
                                    {doc[fieldName]}
                                  </Text>
                                </View>
                              )
                            )}
                            <Button
                              status="basic"
                              appearance="ghost"
                              accessoryRight={MessageIcon}
                              onPress={() =>
                                navigation.navigate("requests", {
                                  applicationId: doc["loanApplicationId"],
                                })
                              }
                            />
                          </View>
                        )
                      )
                    ) : (
                      <LoadingIndicator />
                    )}
                  </View>
                </ScrollView>
              ) : (
                LoadingIndicator
              )}
              <View
                style={{
                  ...styles.flexRow,
                  justifyContent: "space-between",
                  paddingHorizontal: 30,
                  margin: 0,
                }}
              >
                <Text style={styles.subheader} appearance="hint">
                  {`Showing ${currentPage * displayPerPage + 1} to ${
                    (currentPage + 1) * displayPerPage
                  } of ${
                    requestedDocumentsDisplayList.length
                      ? displayPerPage *
                          (requestedDocumentsDisplayList.length - 1) +
                        requestedDocumentsDisplayList[
                          requestedDocumentsDisplayList.length - 1
                        ].length
                      : "?"
                  } entries`}
                </Text>
                <View style={styles.flexRow}>
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={LeftArrowIcon}
                    onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
                  />
                  {getPagesButtonsNumbers().map((idx) => (
                    <Button
                      status="basic"
                      appearance={idx === currentPage ? "filled" : "ghost"}
                      onPress={() => setCurrentPage(idx)}
                      key={"page-btn-" + idx}
                    >
                      {idx + 1}
                    </Button>
                  ))}
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={RightArrowIcon}
                    onPress={() =>
                      setCurrentPage(
                        Math.min(
                          requestedDocumentsDisplayList.length - 1,
                          currentPage + 1
                        )
                      )
                    }
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default AdminDashboard;
