import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Input,
  Divider,
  Datepicker,
  CalendarViewModes,
  CheckBox,
  Spinner,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Dimensions, View } from "react-native";
import Collapsible from "react-native-collapsible";

import { useStore } from "../../stores";
import { formatNumberWithCommas } from "../../utils";
import { contractPricingTypeOptions } from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const Prequal1Details: React.FC<any> = ({ canEdit, business, loan }) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const LoadingIndicator = (props: any) => <Spinner />;
  const EditIcon = (props: any) => (
    <Icon {...props} name="edit-outline" fill="#26DDF1" />
  );
  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar-outline" />
  );

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    selectedContractPricingTypeIndex,
    setSelectedContractPricingTypeIndex,
  ] = useState<any>(null);

  useEffect(() => {
    setFormData({
      ...formData,
      contractPricingType:
        contractPricingTypeOptions[selectedContractPricingTypeIndex?.row] || "",
    });
  }, [selectedContractPricingTypeIndex]);
  const { businessStore } = useStore();

  const [formData, setFormData] = useState({
    einNumber: business.einNumber,
    cageCode: business.cageCode,
    dunsNumber: business.dunsNumber,
    ueiNumber: business.ueiNumber,
    contractNumber: business.contractNumber,
    contractPricingType: business.contractPricingType,
    contractAmount: business.contractAmount,
    annualRevenue: business.annualRevenue,
    businessStartDate: business.businessStartDate,
  });

  useEffect(() => {
    setFormData({
      einNumber: business.einNumber,
      cageCode: business.cageCode,
      dunsNumber: business.dunsNumber,
      ueiNumber: business.ueiNumber,
      contractNumber: business.contractNumber,
      contractPricingType: business.contractPricingType,
      contractAmount: business.contractAmount,
      annualRevenue: business.annualRevenue,
      businessStartDate: business.businessStartDate,
    });
    if (business.contractPricingType) {
      const index = contractPricingTypeOptions.findIndex(
        (type: string) => type === business.contractPricingType
      );
      setSelectedContractPricingTypeIndex(new IndexPath(index));
    }
  }, [business, loan]);

  const ErrorDescription = (fieldName: string) => {
    if (!errors[fieldName]) return <></>;
    return <View style={styles.error}>{errors[fieldName]}</View>;
  };

  const [errors, setErrors] = useState<any>({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <View>
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Prequal 1
        </Text>
        {!!canEdit && (
          <Button
            style={styles.editButton}
            appearance="ghost"
            status="basic"
            accessoryLeft={EditIcon}
            onPress={() => {
              setIsEditing(!isEditing);
            }}
          />
        )}
      </View>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        <Text style={styles.inputLabel}>
          What type of government contractor are you?
        </Text>
        <View style={width > 992 ? styles.flexRow : { marginBottom: 15 }}>
          <CheckBox
            style={styles.checkbox}
            disabled
            checked={loan?.isPrimeContractor}
            children={() => (
              <Text
                style={{
                  ...styles.inputLabel,
                  paddingLeft: 10,
                  marginBottom: 0,
                }}
              >
                PRIME
              </Text>
            )}
          />
          <CheckBox
            style={styles.checkbox}
            disabled
            checked={loan?.wantsToBePrime}
            children={() => (
              <Text
                style={{
                  ...styles.inputLabel,
                  paddingLeft: 10,
                  marginBottom: 0,
                }}
              >
                SUBCONTRACTOR
              </Text>
            )}
          />
        </View>
        {ErrorDescription("einNumber")}
        <Text style={styles.inputLabel}>EIN Number</Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formData.einNumber}
          maxLength={9}
          onChangeText={(text) => setFormData({ ...formData, einNumber: text })}
          placeholder="EIN"
        />

        {ErrorDescription("cageCode")}
        <Text style={styles.inputLabel}>CAGE Code</Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formData.cageCode}
          maxLength={5}
          onChangeText={(text) => setFormData({ ...formData, cageCode: text })}
          placeholder="CAGE code"
        />

        {ErrorDescription("ueiNumber")}
        <Text style={styles.inputLabel}>UEI Number</Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formData.ueiNumber}
          maxLength={12}
          onChangeText={(text) => setFormData({ ...formData, ueiNumber: text })}
          placeholder="UEI Number"
        />

        {ErrorDescription("dunsNumber")}
        <Text style={styles.inputLabel}>
          DUNS Number <Text appearance="hint">(Optional)</Text>
        </Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formData.dunsNumber}
          maxLength={9}
          onChangeText={(text) =>
            setFormData({ ...formData, dunsNumber: text })
          }
          placeholder="DUNS number"
        />

        {ErrorDescription("contractNumber")}
        <Text style={styles.inputLabel}>Contract Number</Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formData.contractNumber}
          onChangeText={(text) =>
            setFormData({ ...formData, contractNumber: text })
          }
          placeholder="Contract Number"
        />

        {ErrorDescription("contractPricingType")}
        <Text style={styles.inputLabel}>Contract Pricing Type</Text>
        <Select
          status="primary"
          disabled={!isEditing}
          style={styles.dropdown}
          placeholder="Contract Pricing Type"
          value={formData.contractPricingType}
          selectedIndex={selectedContractPricingTypeIndex}
          onSelect={(index) => setSelectedContractPricingTypeIndex(index)}
        >
          {contractPricingTypeOptions.map((option, idx) => (
            <SelectItem
              title={option}
              key={`${idx}-contract-pricing-type-option`}
            />
          ))}
        </Select>

        {ErrorDescription("contractAmount")}
        <Text style={styles.inputLabel}>Contract Amount</Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formatNumberWithCommas(formData.contractAmount)}
          onChangeText={(text) => {
            const number = text.replace(/,/g, "");
            setFormData({
              ...formData,
              contractAmount:
                Number.isNaN(Number(number)) || Number(number) >= 10 ** 10
                  ? formData.contractAmount
                  : number,
            });
          }}
          placeholder="Contract Amount"
        />

        {ErrorDescription("annualRevenue")}
        <Text style={styles.inputLabel}>Annual Revenue</Text>
        <Input
          style={styles.input}
          disabled={!isEditing}
          value={formatNumberWithCommas(formData.annualRevenue)}
          onChangeText={(text) => {
            const number = text.replace(/,/g, "");
            setFormData({
              ...formData,
              annualRevenue:
                Number.isNaN(Number(number)) || Number(number) >= 10 ** 10
                  ? formData.annualRevenue
                  : number,
            });
          }}
          placeholder="Annual Revenue"
        />

        {ErrorDescription("businessStartDate")}
        <Text style={styles.inputLabel}>Business Start Date</Text>
        <Datepicker
          style={styles.input}
          accessoryRight={CalendarIcon}
          placeholder={() => (
            <Text style={styles.datePickerPlaceholderText}>
              Business Start Date
            </Text>
          )}
          disabled={!isEditing}
          startView={CalendarViewModes.YEAR}
          date={
            formData.businessStartDate
              ? new Date(formData.businessStartDate)
              : ""
          }
          min={new Date("1900-01-01")}
          onSelect={(nextDate) =>
            setFormData({
              ...formData,
              //@ts-ignore
              businessStartDate: `${1900 + nextDate.getYear()}-${
                //@ts-ignore
                nextDate.getMonth() + 1
                //@ts-ignore
              }-${nextDate.getDate()}`,
            })
          }
        />

        {isEditing && (
          <Button
            style={{ ...styles.saveButton, width: 200, alignSelf: "center" }}
            onPress={async () => {
              setLoading(true);
              const res = await businessStore.updateBusiness(
                business.id,
                formData
              );
              setLoading(false);
              if (!res?.ok) {
                setErrors(res?.errors);
              } else {
                setErrors({});
                setIsEditing(false);
              }
            }}
            accessoryRight={loading ? LoadingIndicator : <></>}
          >
            Save
          </Button>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
