import { useNavigation } from "@react-navigation/native";
import { Layout, Spinner, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity, Image } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { AccountLinking } from "./AccountLinking";
import { themedStyles } from "./themedStyles";
import { Dimensions } from "react-native";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { ContactUsPopup } from "../../components/ContactUs";

const PreApproval: React.FC<any> = ({ route }) => {
  let { width } = Dimensions.get("window");
  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const navigation = useNavigation();
  const { authStore, loanStore, businessStore } = useStore();
  const styles = useStyleSheet(themedStyles);
  if (!authStore || !authStore.accessToken) return <Text>Register</Text>;
  const [currentStep, setCurrentStep] = useState<number>(0);

  const [loan, setLoan] = useState<any>({});
  const [business, setBusiness] = useState<any>({});

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  const updateLoanData = async (data: any) => {
    const res = await loanStore.updateLoanApplication(id, data);
    return res;
  };

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  useEffect(() => {
    if (!loanStore.loanApplications.size) {
      loanStore.fetchLoanApplications();
    } else {
      let flag = false;
      loanStore.loanApplications.forEach((loan) => {
        if (loan.id === Number(id)) {
          flag = true;
          if (loan.applicantId === authStore.user?.id) {
            setLoan(loan.$);
          } else {
            navigation.navigate("dashboard");
          }
        }
      });
      if (!flag) {
        navigation.navigate("dashboard");
      }
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (!res1.response.entities) {
        navigation.navigate("dashboard");
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (!res2.response.entities) {
        navigation.navigate("dashboard");
      }
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(loan).indexOf("status") >= 0) {
      if (loan.status !== "prequal2" && loan.status !== "prequal2InProgress") {
        navigation.navigate("loan-view", { id });
      }

      if (!businessStore.business.size) {
        businessStore.fetchBusinesses();
      } else {
        if (Object.keys(loan).length > 0) {
          if (loan.business) {
            let flag = false;
            businessStore.business.forEach((business) => {
              if (Number(business.id) === Number(loan.business)) {
                setBusiness(business.$);
                flag = true;
              }
            });
            if (!flag) {
              navigation.navigate("dashboard");
            }
          }
        }
      }
    }
  }, [loan]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.preApprovalContainer}>
          <View
            style={
              width > 768
                ? {
                    ...styles.gradientContainer,
                    ...(currentStep === 5 ? { width: "100%" } : {}),
                  }
                : {
                    ...styles.gradientContainerMobile,
                    ...(currentStep === 5 ? { width: "100%" } : {}),
                  }
            }
          />
          <View
            style={width > 768 ? styles.preApproval : styles.preApprovalMobile}
          >
            {Object.keys(loan).length > 0 ? (
              <AccountLinking
                loan={loan}
                loanId={loan.id}
                business={business}
                ssnNumber={authStore.user?.ssnNumber}
                verifyPrequal2={async () => {
                  const res = await loanStore.verifyPrequal2(loan.id);
                  console.log(res);
                  if (!res?.ok) {
                    console.log(res?.errors);
                  }
                }}
                onContinue={() => {
                  navigation.navigate("loan-view", { id });
                }}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                updateLoanData={updateLoanData}
              />
            ) : (
              <LoadingIndicator />
            )}
          </View>
        </View>
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default PreApproval;
