import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Input,
  Select,
  SelectItem,
} from "@ui-kitten/components";
import React, { useRef, useState, useEffect } from "react";
import { Dimensions, View } from "react-native";

import { managementExperienceOptions } from "../../utils/constants";
import { uploadDocument } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const FullAppStep: React.FC<any> = ({
  formData,
  setFormData,
  goToNextStep,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LinkedInIcon = (props: any) => <Icon {...props} name="linkedin" />;

  const [
    selectedManagementExperienceIndex,
    setSelectedManagementExperienceIndex,
  ] = useState<any>(null);

  useEffect(() => {
    if (selectedManagementExperienceIndex)
      setFormData({
        ...formData,
        managementExperience:
          managementExperienceOptions[selectedManagementExperienceIndex?.row] ||
          "",
      });
  }, [selectedManagementExperienceIndex]);

  const legalActionRef = useRef<Input>(null);
  const [errors, setErrors] = useState<any>("");

  return (
    <View>
      <Text category="h6" style={styles.error}>
        {errors ? JSON.stringify(errors) : ""}
      </Text>
      <Text style={styles.inputLabel}>
        Resumes of principals, or summary of management's experience
      </Text>
      <View style={styles.flexRow}>
        <Input
          style={styles.input}
          textStyle={styles.inputText}
          placeholder="LinkedIn Profile URL"
          status="basic"
          value={formData.linkedIn}
          onChangeText={(text) => setFormData({ ...formData, linkedIn: text })}
          accessoryRight={LinkedInIcon}
        />
        <View style={styles.gap} />
        <Button
          style={
            formData.resumes ? styles.uploadButtonSuccess : styles.uploadButton
          }
          status={formData.resumes ? "success" : "basic"}
          appearance="outline"
          accessoryRight={UploadIcon}
          onPress={() =>
            uploadDocument((doc: any) =>
              setFormData({
                ...formData,
                resumes: doc,
              })
            )
          }
          children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
        />
      </View>

      <Text style={styles.inputLabel}>Years of management experience</Text>
      <Select
        status="primary"
        size={width > 576 ? "medium" : "small"}
        style={styles.input}
        placeholder="Years of management experience"
        value={formData.managementExperience}
        selectedIndex={selectedManagementExperienceIndex}
        onSelect={(index) => setSelectedManagementExperienceIndex(index)}
      >
        {managementExperienceOptions.map((option, idx) => (
          <SelectItem title={option} key={`${idx}-business-role-option`} />
        ))}
      </Select>

      <Text style={styles.inputLabel}>
        Upload Valid Driver's License <Text appearance="hint">(Optional)</Text>
      </Text>
      <Button
        style={
          formData.driversLicence
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.driversLicence ? "success" : "basic"}
        appearance="outline"
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                driversLicence: doc,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />

      <Text style={styles.inputLabel}>
        Personal Financial Statements <Text appearance="hint">(Optional)</Text>
      </Text>
      <Button
        style={
          formData.personalFinancialStatements
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.personalFinancialStatements ? "success" : "basic"}
        appearance="outline"
        accessoryRight={UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                personalFinancialStatements: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />

      <View>
        <Text style={styles.inputLabel}>
          Please provide a list of adverse legal action (past or pending) faced
          by you or your business. <Text appearance="hint">(Optional)</Text>
        </Text>
        <Input
          ref={legalActionRef}
          multiline
          textStyle={{ minHeight: 256, color: "white" }}
          style={styles.input}
          placeholder="Input Text"
          status="basic"
          value={formData.legalActions}
          onChangeText={(text) =>
            setFormData({ ...formData, legalActions: text })
          }
        />
      </View>
    </View>
  );
};
