import { Text, useStyleSheet, Icon, Button } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";

import { uploadDocument } from "../../utils";
import { themedStyles } from "./themedStyles";

export const UploadButtonWithView: React.FC<any> = ({
  formData,
  fieldName,
  setFormData,
  isEditing,
  setErrors = () => {},
}) => {
  const styles = useStyleSheet(themedStyles);

  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;
  const CheckIcon = (props: any) => (
    <Icon {...props} name="checkmark-circle-2-outline" fill="#26DDF1" />
  );

  return (
    <View style={{ ...styles.flexRow, gap: 10 }}>
      <Button
        style={styles.uploadButton}
        status={formData[fieldName] ? "info" : "basic"}
        disabled={!isEditing}
        appearance="outline"
        accessoryRight={formData[fieldName] ? CheckIcon : UploadIcon}
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              const newFormData = { ...formData };
              newFormData[fieldName] = doc.file;
              setFormData(newFormData);
              setErrors([]);
            } else {
              setErrors(["File too large. Upload limit: 10MB."]);
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>
            {formData[fieldName]
              ? fieldName.charAt(0).toUpperCase() +
                fieldName.replace(/([A-Z])/g, " $1").slice(1)
              : isEditing
              ? `Upload ${
                  fieldName.charAt(0).toUpperCase() +
                  fieldName.replace(/([A-Z])/g, " $1").slice(1)
                }`
              : "N/A"}
          </Text>
        )}
      />
      <Button
        style={styles.viewButton}
        onPress={() => {
          window.open(formData[fieldName], "_blank");
        }}
        disabled={!formData[fieldName]}
        children={() => <Text style={styles.viewButtonText}>View</Text>}
      />
    </View>
  );
};
