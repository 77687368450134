import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useCallback, useEffect } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";

import Header from "../../components/Header";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { ThemeContext } from "./../../../theme-context";
import { FilterOptionsModal } from "./FilterOptionsModal";
import { LoanPreviewModal } from "./LoanPreviewModal";
import { themedStyles } from "./themedStyles";
type Props = object;

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const testDataApplicantName = [
  "This is all placeholder data",
  "This is all placeholder data",
  "This is all placeholder data",
  "This is all placeholder data",
  "This is all placeholder data",
  "This is all placeholder data",
  "This is all placeholder data",
  "Robert Fox",
  "Devon Lane",
  "Wade Warren",
];
const testDataAssignedUser = ["Jamie Wood", "Allison Smith", "Dwayne Keene"];
const testDataBusinessName = ["Company A", "Company B", "Company C"];
const testDataContractNumber = ["ABCD1234", "QWERTY123", "ZYXW0987"];
const testDataDateUpdated = ["2022-01-17", "2021-09-16", "2022-02-02"];
const testDataDecision = ["Review", "Decline", "Approve"];
const testDataInterest = [9, 10, 11, 12];
const testDataLoanAmount = ["10000000", "1500000", "3000000"];
const testDataTerm = [12, 18, 24];

export const SORT = {
  DEFAULT: "DEFAULT",
  NEW: "NEW",
  OLD: "OLD",
  UPDATED_INC: "UPDATED_INC",
  UPDATED_DEC: "UPDATED_DEC",
  ALPHABET_INC: "ALPHA_INC",
  ALPHABET_DEC: "ALPHA_DEC",
};

export const possibleLocations = ["Florida", "Virginia", "Maryland"];
export const possibleStatus = [
  "PRE-QUAL 1",
  "PRE-QUAL 2",
  "FULL APPLICATION",
  "WITHDRAWN",
];
export const possibleDecisionStatus = ["Approve", "Review", "Decline"];
export const possibleTypes = [
  "S Corporation",
  "C Corporation",
  "Nonprofit Corporation",
  "Limited Liability Corporation (LLC)",
  "Limited Liability Partnership (LLP)",
  "Partnership",
];

const displayPerPageOptions = [10, 25, 50, 100];

const InvestorPortalFinalDecision: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { width, height } = Dimensions.get("window");
  const maxWidth = styles.adminDashBoard.maxWidth
    ? Number(styles.adminDashBoard.maxWidth)
    : 1080;

  const colorBar = require("../../../assets/colorBar.svg");
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;
  const LeftArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-back-outline" />
  );
  const RightArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-forward-outline" />
  );
  const OptionsIcon = (props: any) => (
    <Icon {...props} name="options-2-outline" />
  );
  const EyeIcon = (props: any) => <Icon {...props} name="eye-outline" />;

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [displayPerPage, setDisplayPerPage] = useState(10);
  const [searchString, setSearchString] = useState(
    new URLSearchParams(window.location.search).get("userName") || ""
  );
  const [loading, setLoading] = useState(false);
  const [sortingMode, setSortingMode] = useState(SORT.DEFAULT);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [isLoanPreviewModalOpen, setIsLoanPreviewModalOpen] = useState(false);
  const [loanPreviewApplicationId, setLoanPreviewApplicationId] =
    useState(null);
  const [generatePlaceholderData, setGeneratePlaceholderData] = useState(false);
  const [isPlaceholderData, setIsPlaceholderData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const tableWidth = width <= maxWidth ? width - 300 : maxWidth - 300;
  const totalColumns = 8;
  const fieldsDesc: { [key: string]: any } = {
    loanApplicationId: {
      width: tableWidth * (0.5 / totalColumns),
      display: "ID",
    },
    loanAmount: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Loan Amount",
      format: "$",
      align: "center",
    },
    interest: {
      width: tableWidth * (0.8 / totalColumns),
      display: "Interest",
      format: "%",
      align: "center",
    },
    term: {
      width: tableWidth * (0.8 / totalColumns),
      display: "Term (months)",
      align: "center",
    },
    dateUpdated: {
      width: tableWidth * (0.95 / totalColumns),
      display: "Date Updated",
    },
    finalDecisionStatus: {
      width: tableWidth * (1.2 / totalColumns),
      display: "Decision",
    },
    assignedUser: {
      width: tableWidth * (1 / totalColumns),
      display: "Assignee",
    },
    actions: {
      width: tableWidth * (1.5 / totalColumns),
      display: "Actions",
    },
  };

  const [loanApplications, setLoanApplications]: any = useState([]);
  const [loanApplicationsDisplayList, setLoanApplicationsDisplayList]: any =
    useState([]);

  const [filterOptions, setFilterOptions]: any = useState({
    byStatus: false,
    byDecisionStatus: false,
    byLocation: false,
    byType: false,
    decisionStatus: [],
    status: [],
    location: [],
    type: [],
  });

  const themeContext = React.useContext(ThemeContext);

  useEffect(() => {
    themeContext.resetTheme();
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .getInvestorLoansList(authStore.accessToken || "")
      .then((resp) => {
        const response = resp.response.entities;
        setLoanApplications(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useFocusEffect(
    useCallback(() => {
      setDisplayPerPage(displayPerPageOptions[selectedIndex.row]);
    }, [useIsFocused(), selectedIndex])
  );

  useEffect(() => {
    setCurrentPage(0);
    const filtered = [...loanApplications]
      .filter(
        (doc: any) =>
          Object.values(doc).filter((field) =>
            `${field}`.toLowerCase().includes(searchString.toLowerCase().trim())
          ).length
      )
      .filter((doc: any) => {
        let ok = true;
        if (
          filterOptions?.byStatus &&
          !filterOptions?.status?.includes(doc["status"].toLowerCase())
        )
          ok = false;

        if (
          filterOptions?.byDecisionStatus &&
          !filterOptions?.decisionStatus?.includes(
            doc["finalDecisionStatus"].toLowerCase()
          )
        )
          ok = false;

        if (
          filterOptions?.byLocation &&
          !filterOptions?.location?.includes((doc.location || "").toLowerCase())
        )
          ok = false;

        if (
          filterOptions?.byType &&
          !filterOptions?.type?.includes((doc.type || "").toLowerCase())
        )
          ok = false;

        return ok;
      });

    const sorted = filtered.sort((a: any, b: any) => {
      if (sortingMode === SORT.DEFAULT) return 0;

      if (
        (sortingMode === SORT.ALPHABET_INC ||
          sortingMode === SORT.ALPHABET_DEC) &&
        a.businessName === b.businessName
      )
        return 0;

      if (sortingMode === SORT.ALPHABET_INC)
        return a.businessName < b.businessName ? -1 : 1;
      if (sortingMode === SORT.ALPHABET_DEC)
        return a.businessName > b.businessName ? -1 : 1;

      if (sortingMode === SORT.UPDATED_INC)
        return new Date(a.dateUpdated) > new Date(b.dateUpdated) ? -1 : 1;
      if (sortingMode === SORT.UPDATED_DEC)
        return new Date(a.dateUpdated) < new Date(b.dateUpdated) ? -1 : 1;

      if (sortingMode === SORT.OLD)
        return Number(a.loanApplicationId) - Number(b.loanApplicationId);
      if (sortingMode === SORT.NEW)
        return Number(b.loanApplicationId) - Number(a.loanApplicationId);

      return 0;
    });

    const results = [];
    for (let i = 1; (i - 1) * displayPerPage < sorted.length; i++)
      results.push(sorted.slice((i - 1) * displayPerPage, i * displayPerPage));

    setLoanApplicationsDisplayList(results);
  }, [
    loanApplications,
    searchString,
    sortingMode,
    displayPerPage,
    filterOptions,
  ]);

  useEffect(() => {
    if (generatePlaceholderData) {
      setIsPlaceholderData(true);
      let data = [];
      for (let i = 0; i < 30 + Math.floor(Math.random() * 30); i++) {
        let tmp = {
          loanApplicationId: Math.floor(Math.random() * 99999),
          applicantName:
            testDataApplicantName[
              Math.floor(Math.random() * testDataApplicantName.length)
            ],
          assignedUser:
            testDataAssignedUser[
              Math.floor(Math.random() * testDataAssignedUser.length)
            ],
          businessName:
            testDataBusinessName[
              Math.floor(Math.random() * testDataBusinessName.length)
            ],
          contractNumber:
            testDataContractNumber[
              Math.floor(Math.random() * testDataContractNumber.length)
            ],
          dateUpdated:
            testDataDateUpdated[
              Math.floor(Math.random() * testDataDateUpdated.length)
            ],
          finalDecisionStatus:
            testDataDecision[
              Math.floor(Math.random() * testDataDecision.length)
            ],
          interest:
            testDataInterest[
              Math.floor(Math.random() * testDataInterest.length)
            ],
          loanAmount:
            testDataLoanAmount[
              Math.floor(Math.random() * testDataLoanAmount.length)
            ],
          term: testDataTerm[Math.floor(Math.random() * testDataTerm.length)],
        };
        data.push(tmp);
      }
      setLoanApplications(data);
      setCurrentPage(0);
    }
    setGeneratePlaceholderData(false);
  }, [generatePlaceholderData]);

  const { authStore } = useStore();

  const formatDisplay = (value: string, format: any) => {
    if (!value) return "";
    if (format === "$")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(value));
    if (format === "%") return value + "%";

    return value;
  };

  const getPagesButtonsNumbers = useCallback(() => {
    const buttons = [];
    for (
      let i = Math.max(0, currentPage - 2);
      i < Math.min(loanApplicationsDisplayList.length, currentPage + 3);
      i++
    )
      buttons.push(i);
    return buttons;
  }, [loanApplicationsDisplayList, currentPage]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <View style={styles.colorBar}>
          <Image
            style={{
              width: 43,
              height: height - 68,
            }}
            source={colorBar}
          />
        </View>
        <Header theme="light" />
        <FilterOptionsModal
          isOptionsModalOpen={isOptionsModalOpen}
          setIsOptionsModalOpen={setIsOptionsModalOpen}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          sortingMode={sortingMode}
          setSortingMode={setSortingMode}
        />
        <LoanPreviewModal
          isOpen={isLoanPreviewModalOpen}
          close={() => setIsLoanPreviewModalOpen(false)}
          applicationId={loanPreviewApplicationId}
        />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>Investor Portal</Text>
            <Text style={styles.welcomeMessageText} appearance="hint">
              {`Welcome, ${authStore.user?.firstName} ${authStore.user?.lastName}`}
            </Text>
            <Divider />
            <View>
              <View style={styles.requestedDocumentsHeader}>
                <Text style={styles.header}>Final Approval Portal</Text>
                <View style={{ ...styles.flexRow, flex: 1 }}>
                  <Input
                    style={styles.input}
                    placeholder="Search"
                    value={searchString}
                    onChangeText={(text) => setSearchString(text)}
                    accessoryRight={SearchIcon}
                  />
                  <Button
                    style={styles.optionsButton}
                    status="basic"
                    accessoryRight={OptionsIcon}
                    onPress={() => setIsOptionsModalOpen(true)}
                  />
                  <Text style={styles.flexRow} appearance="hint">
                    Show&nbsp;&nbsp;
                    <Select
                      selectedIndex={selectedIndex}
                      //@ts-ignore
                      onSelect={(index) => setSelectedIndex(index)}
                      value={displayPerPage}
                    >
                      {displayPerPageOptions.map((option) => (
                        <SelectItem
                          style={styles.selectItem}
                          key={"display-per-page-" + option}
                          title={() => (
                            <Text style={styles.selectItem}>{option}</Text>
                          )}
                        />
                      ))}
                    </Select>
                    &nbsp;&nbsp;entries
                  </Text>
                </View>
              </View>
              <View
                style={{ height: height - 250, ...(loading && styles.center) }}
              >
                <ScrollView
                  style={{
                    ...styles.scroll,
                    maxHeight: height - 250,
                  }}
                  keyboardShouldPersistTaps="handled"
                >
                  {loading || !loanApplications ? (
                    <LoadingIndicator />
                  ) : (
                    <View style={styles.tableContainer}>
                      <View style={styles.tableHeaders}>
                        {Object.values(fieldsDesc).map((field) => (
                          <View
                            key={"header-" + field.display}
                            style={{
                              ...{ width: field.width },
                              ...styles.tableCell,
                            }}
                          >
                            <Text
                              style={{
                                ...styles.tableHeadersCellText,
                                ...{ width: field.width },
                              }}
                            >
                              {field.display}
                            </Text>
                          </View>
                        ))}
                      </View>
                      {(loanApplicationsDisplayList[currentPage] || []).map(
                        (doc: any) => (
                          <View
                            key={doc.loanApplicationId + "-row"}
                            style={styles.tableRow}
                          >
                            {Object.keys(fieldsDesc).map(
                              (fieldName: string) => (
                                <View
                                  key={
                                    doc.loanApplicationId +
                                    "-row-data-" +
                                    fieldsDesc[fieldName].display
                                  }
                                  style={{
                                    ...{
                                      width: fieldsDesc[fieldName].width,
                                    },
                                    ...styles.tableCell,
                                  }}
                                >
                                  {fieldName !== "actions" ? (
                                    <Text
                                      numberOfLines={1}
                                      style={{
                                        ...(fieldsDesc[fieldName].align
                                          ? {
                                              textAlign:
                                                fieldsDesc[fieldName].align,
                                            }
                                          : {}),
                                        ...styles.tableCellText,
                                        ...(fieldName ===
                                          "finalDecisionStatus" ||
                                        fieldName === "status"
                                          ? {
                                              // @ts-ignore
                                              ...styles[doc[fieldName]],
                                              paddingHorizontal: 5,
                                              textAlign: "center",
                                            }
                                          : {}),
                                        ...{
                                          width: fieldsDesc[fieldName].width,
                                        },
                                      }}
                                    >
                                      {formatDisplay(
                                        doc[fieldName],
                                        fieldsDesc[fieldName].format || ""
                                      )}
                                    </Text>
                                  ) : (
                                    <Button
                                      status="basic"
                                      appearance="ghost"
                                      disabled={isPlaceholderData}
                                      accessoryRight={EyeIcon}
                                      style={{
                                        width: fieldsDesc[fieldName].width - 20,
                                      }}
                                      onPress={() => {
                                        setLoanPreviewApplicationId(
                                          doc.loanApplicationId
                                        );
                                        setIsLoanPreviewModalOpen(true);
                                      }}
                                      children={() => (
                                        <Text style={styles.previewLoanButton}>
                                          Preview
                                        </Text>
                                      )}
                                    />
                                  )}
                                </View>
                              )
                            )}
                          </View>
                        )
                      )}
                    </View>
                  )}
                </ScrollView>
              </View>
              <View
                style={{
                  ...styles.flexRow,
                  justifyContent: "space-between",
                  paddingHorizontal: 30,
                  margin: 0,
                }}
              >
                <Text style={styles.subheader} appearance="hint">
                  {`Showing ${currentPage * displayPerPage + 1} to ${
                    loanApplicationsDisplayList.length
                      ? Math.min(
                          (currentPage + 1) * displayPerPage,
                          displayPerPage *
                            (loanApplicationsDisplayList.length - 1) +
                            loanApplicationsDisplayList[
                              loanApplicationsDisplayList.length - 1
                            ].length
                        )
                      : (currentPage + 1) * displayPerPage
                  } of ${
                    loanApplicationsDisplayList.length
                      ? displayPerPage *
                          (loanApplicationsDisplayList.length - 1) +
                        loanApplicationsDisplayList[
                          loanApplicationsDisplayList.length - 1
                        ].length
                      : "?"
                  } entries`}
                </Text>
                <View style={styles.flexRow}>
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={LeftArrowIcon}
                    onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
                  />
                  {getPagesButtonsNumbers().map((idx) => (
                    <Button
                      status="basic"
                      appearance={idx === currentPage ? "filled" : "ghost"}
                      onPress={() => setCurrentPage(idx)}
                      key={"page-btn-" + idx}
                    >
                      {idx + 1}
                    </Button>
                  ))}
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={RightArrowIcon}
                    onPress={() =>
                      setCurrentPage(
                        Math.min(
                          loanApplicationsDisplayList.length - 1,
                          currentPage + 1
                        )
                      )
                    }
                  />
                </View>
              </View>
              <View
                style={{
                  ...styles.flexRow,
                  paddingVertical: 50,
                  margin: 0,
                }}
              >
                {__DEV__ || authStore?.user?.isStaff ? (
                  <Button
                    onPress={() => {
                      setGeneratePlaceholderData(true);
                    }}
                  >
                    Generate Placeholder Data
                  </Button>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default InvestorPortalFinalDecision;
