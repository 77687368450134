import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import { camelCaseToSnakeCase } from "../../utils";
import { callApi } from "./base";

export const signUp = async (data: {
  email: string;
  password1: string;
  password2: string;
  birthday: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  phoneNumber: string;
  businessName: string;
  tradeNames: string[];
  isPrimaryApplicant: boolean;
  otherBusinessOwners: boolean;
  ownTwentyPercent: boolean;
  contractorLevel: string[];
}) =>
  callApi(
    config.urls.auth.signUp,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys({ ...data }))
  );

export const logIn = async (data: { email: string; password: string }) =>
  callApi(
    config.urls.auth.logIn,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        email: data.email,
        password: data.password,
      })
    )
  );

export const logOut = async (token: string) =>
  callApi(config.urls.auth.logOut, token, "POST");

export const tokenRefresh = async (data: { refreshToken: string | null }) =>
  callApi(
    config.urls.auth.tokenRefresh,
    "",
    "POST",
    { "Content-Type": "application/json" },
    data.refreshToken // on web, refreshToken is intentionally missing because it is sent as a cookie
      ? JSON.stringify(
          snakeCaseKeys({
            refresh: data.refreshToken,
          })
        )
      : ""
  );

export const changePassword = async (token: string, data: object) =>
  callApi(
    config.urls.auth.changePassword,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data || {}))
  );

export const resetPassword = async (data: { email: string }) =>
  callApi(
    config.urls.auth.resetPassword,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys({ ...data }))
  );

export const confirmResetPassword = async (data: {
  uid: string;
  token: string;
  newPassword1: string;
  newPassword2: string;
}) =>
  callApi(
    `${config.urls.auth.confirmResetPassword}${data.uid}/${data.token}/`,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys({ ...data }))
  );

export const updateUserBasicAndBusinessInformation = async (
  token: string,
  data: {
    email: string;
    firstName: string;
    lastName: string;
    businessName: string;
    businessAddress: string;
    businessAddStreetLine1: string;
    businessAddStreetLine2: string;
    businessAddCity: string;
    businessAddState: string;
    businessAddZipCode: string;
    businessRole: string;
    businessType: string;
    phoneNumber: string;
  }
) =>
  callApi(
    config.urls.updateBasicAndBusinessInformation,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys({ ...data }))
  );

export const updateUser = async (token: string, id: number, data: object) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    //@ts-ignore
    if (key === "resumes" && typeof data[key] === "string") return;
    //@ts-ignore
    if (!data[key] && !["isSubscribedToEmailNotifications"].includes(key))
      return;
    //@ts-ignore
    formData.append(camelCaseToSnakeCase(key), data[key]);
  });

  return callApi(`${config.urls.users}${id}/`, token, "PATCH", {}, formData);
};

export const fetchUser = async (token: string, id: number) => {
  return callApi(`${config.urls.users}${id}/`, token, "GET");
};

export const updatePassword = async (
  token: string,
  data: {
    oldPassword: string;
    newPassword1: string;
    newPassword2: string;
  }
) =>
  callApi(
    config.urls.updatePassword,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(
      snakeCaseKeys({
        oldPassword: data.oldPassword,
        newPassword1: data.newPassword1,
        newPassword2: data.newPassword2,
      })
    )
  );

export const checkBusiness = async (data: {
  email: string;
  businessRole: string;
  businessName: string;
  businessAddress: string;
  businessAddStreetLine1: string;
  businessAddStreetLine2: string;
  businessAddCity: string;
  businessAddState: string;
  businessAddZipCode: string;
  stateOfIncorporation: string;
  jurisdictionCounty: string;
  businessType: string;
  tradeNames: [];
  businessIsAuthorize: boolean;
  isPrimaryApplicant: boolean;
  otherBusinessOwners: boolean;
  ownTwentyPercent: boolean;
  businessPartners: string[];
  contractorLevel: string[];
}) =>
  callApi(
    config.urls.checkBusiness,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const storeMboEmails = async (data: {
  email: string;
  businessPartners: object[];
}) =>
  callApi(
    config.urls.mbo.store_mbo_emails,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const sendMboEmails = async () =>
  callApi(config.urls.mbo.send_mbo_emails, "", "POST");

export const checkMboValidity = async (data: { key: string }) =>
  callApi(
    config.urls.mbo.check_mbo_validity,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const checkMboExistence = async (data: { email: string }) =>
  callApi(
    config.urls.mbo.check_mbo_existence,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const checkMboListExistence = async (data: { email: string[] }) =>
  callApi(
    config.urls.mbo.check_mbo_list_existence,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const sendMboEmailReminders = async (data: { userId: number }) =>
  callApi(
    config.urls.mbo.send_mbo_email_reminders,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );
