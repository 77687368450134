import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  flexRow: { flexDirection: "row" },
  flexColumn: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  padSm: {
    padding: 12,
  },
  circleButton: {
    height: 32,
    width: 32,
    borderRadius: 32,
    marginVertical: 10,
    padding: 12,
  },
  circleButtonMobile: {
    height: 24,
    width: 24,
    borderRadius: 24,
    marginVertical: 10,
    padding: 0,
  },
  circleButtonNumber: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
  },
  circleButtonNumberMobile: {
    fontSize: 8,
    lineHeight: 16,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
  },
  circleButtonLabel: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
    textAlign: "center",
    flex: 1,
  },
  circleButtonLabelMobile: {
    fontSize: 12,
    lineHeight: 12,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
    textAlign: "center",
    width: 72,
    flex: 1,
  },
  iconSmall: {
    width: 18,
    height: 18,
    zindex: 2,
    fill: "#101426",
  },
});
