import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Divider,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Dimensions, View, Image } from "react-native";
import Collapsible from "react-native-collapsible";

import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

export const Prequal2Details: React.FC<any> = ({
  canEdit = false,
  business,
  loan,
  buttons = [],
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const LoadingIndicator = (props: any) => <Spinner />;

  const { authStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({ bankAccounts: [] });

  useEffect(() => {
    setLoading(true);
    api.getLinkedAccounts(authStore.accessToken || "").then((resp) => {
      setLoading(false);
      console.log(resp.response.entities);
      if (Object.values(resp.response.entities).length)
        setData({
          ...data,
          bankAccounts: Object.values(resp.response.entities).map(
            (bank: any) => ({
              isLinked: true,
              needsReLink: bank.expired,
              userId: bank.user_id,
              ...bank,
            })
          ),
        });
    });
  }, [loan]);

  const [errors, setErrors] = useState<any>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <View>
      <View style={width > 576 ? styles.flexRowBetween : {}}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Prequal 2
        </Text>
        {errors.map((error: any) => (
          <View style={styles.error}>{error}</View>
        ))}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            ...(width > 576 ? {} : { marginTop: 15 }),
          }}
        >
          {buttons.map((button: any, idx: number) => (
            <View key={idx}> {button()} </View>
          ))}
        </View>
      </View>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          data.bankAccounts.map((bank: any, idx: number) => {
            if (!bank.name) return <View key={idx} />;
            return (
              <View key={bank.name + "-" + idx}>
                <Image
                  style={{ width: 75, height: 75, margin: 10 }}
                  source={{ uri: `data:image/png;base64,${bank.logo}` }}
                />
                {bank.accounts.map((acc: any, idx: number) => (
                  <View key={acc.name + "-" + idx}>
                    <Button
                      style={styles.accountLinkingButton}
                      appearance="outline"
                      status="control"
                      children={() => (
                        <Text>
                          <Text style={styles.payrollModalTitle}>
                            {acc.name}
                            {"\n"}
                          </Text>
                          <Text style={styles.payrollModalTitle}>
                            {"*".repeat(8) + acc.mask}
                          </Text>
                        </Text>
                      )}
                    />
                  </View>
                ))}
              </View>
            );
          })
        )}

        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
