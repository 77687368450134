import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
    background: "color-basic-200",
  },
  backdrop: {
    backgroundColor: "#0005",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
  },

  input: {
    flex: 1,
    fontSize: 22,
    marginBottom: 22,
  },
  LogInButton: {
    marginBottom: 25,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  cardsContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  card: {
    background: "color-basic-100",
    padding: 10,
    borderRadius: 10,
    width: "90%",
    margin: 30,
  },
  flexRow: {
    flexDirection: "row",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  flexRowBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  headerCell: {
    flex: 1,
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    textAlign: "left",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardSubHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    margin: 20,
    marginRight: 5,
  },
  cardSection: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 16,
    fontSize: 14,
    margin: 10,
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  dataRow: {
    flexDirection: "row",
    margin: 10,
  },
  subMenu: {
    flexDirection: "row",
    padding: 10,
    margin: 10,
  },
  pullDataBtn: {
    margin: 0,
    padding: 0,
    height: 10,
    background: "#FFF",
  },
  pullDataLink: {
    margin: 0,
    padding: 0,
  },
  disabledPullDataLink: {
    backgroundColor: "transparent",
  },
  errorPullDataLink: {
    backgroundColor: "transparent",
  },
  pullDataBtnText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    backgroundClip: "text",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    color: "transparent",
  },
  pullDataLinkText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "color-primary-default",
  },
  disabledPullDataLinkText: {
    margin: 0,
    padding: 0,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "color-basic-500",
  },
  errorPullDataLinkText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "red",
  },
  gradientButton: {
    height: 10,
    margin: 3,
    padding: 3,
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    marginVertical: "auto",
    zIndex: 100,
  },
  cancelButton: {
    height: 10,
    margin: 3,
    paddingVertical: 3,
    paddingHorizontal: 50,
    marginVertical: "auto",
    zIndex: 100,
    backgroundColor: "#F7F9FC",
    borderColor: "none",
  },
  cancelButtonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "color-basic-800",
  },
  cellInput: {
    marginVertical: 10,
    width: "90%",
  },
  icon: {
    width: 16,
    height: 16,
    paddingHorizontal: 10,
    marginHorizontal: 10,
  },
  chevronButton: {
    width: 32,
    height: 32,
    marginVertical: "auto",
    marginRight: 32,
  },
  flagDescription: {
    width: "85%",
    fontFamily: "Montserrat_500Normal",
    lineHeight: 16,
    fontSize: 14,
  },
  flagDescriptionText: {
    color: "#222B45",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 18,
    fontSize: 15,
  },
  equifaxModalContainer: {
    paddingVertical: 30,
    paddingHorizontal: 10,
    borderRadius: 26,
    backgroundColor: "white",
  },
  equifaxModalHeaderContainer: {
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  equifaxModalText: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 18,
    marginHorizontal: 20,
    marginVertical: 10,
    textAlign: "left",
  },
  equifaxFieldList: {
    flexDirection: "column",
    justifyContent: "space-around",
    margin: 15,
    marginHorizontal: "10%",
  },
  equifaxField: { marginLeft: 25 },
  equifaxFieldText: {
    fontFamily: "Montserrat_400Regular",
    textTransform: "capitalize",
    fontSize: 16,
    lineHeight: 20,
  },
  equifaxCloseIcon: {
    width: 25,
    height: 25,
    position: "relative",
    top: 5,
    fill: "#C34E6A",
  },
  nextActionButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 20,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonMobile: {
    flex: 1,
    maxWidth: 200,
    marginTop: 5,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "white",
    fontSize: 14,
  },
  tabContainer: {
    height: 350,
  },
  tabView: {
    width: "100%",
  },
});
