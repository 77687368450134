import {
  model,
  Model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";

@model("oppzo/User")
export default class User extends Model({
  id: prop<number>(),
  birthday: prop<string | null>(),
  businessName: prop<string>(""),
  businessRole: prop<string>(""),
  businesses: prop<number[] | null>(),
  email: prop<string>(""),
  firstName: prop<string>(""),
  isFinalPullEquifaxConsumer: prop<boolean | null>(),
  isInboundReferralManager: prop<boolean | null>(),
  isInvestor: prop<boolean | null>(),
  isLoanAgent: prop<boolean | null>(),
  isPrimaryApplicant: prop<boolean | null>(),
  isReferralPartner: prop<boolean | null>(),
  isStaff: prop<boolean | null>(),
  isSubscribedToEmailNotifications: prop<boolean | null>(),
  isUnderwriter: prop<boolean | null>(),
  lastName: prop<string>(""),
  linkedIn: prop<string | null>(),
  managementExperience: prop<string | null>(),
  middleName: prop<string>(""),
  phoneNumber: prop<string>(""),
  primaryAccountId: prop<string | null>(),
  resumes: prop<File | null>(),
  showFundsTrackingTab: prop<boolean>(),
  ssnNumber: prop<number | null>(),
  suffix: prop<string>(""),
  versionControlPrivacy: prop<number>(),
  versionControlTos: prop<number>(),
}) {
  @modelAction
  update(data: ModelCreationData<User>) {
    Object.assign(this, data);
  }
}
