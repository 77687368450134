import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 70,
  },
  link: {
    fontFamily: "Montserrat_500Medium",
    color: "#26D4F3",
    lineHeight: 24,
    fontSize: 18,
  },
  logIn: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
  },
  logInMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 20,
    marginVertical: 50,
    width: "100%",
  },
  logInContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  logInContainerMobile: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    marginBottom: 36,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
  },
  logInButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 70,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  logInButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "white",
    fontSize: 14,
  },
  disabledActionButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 70,
    marginHorizontal: 10,
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "color-basic-600",
    fontSize: 14,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  forgotPasswordText: {
    fontFamily: "Montserrat_400Regular",
    color: "#26DDF1",
    textAlign: "right",
    fontSize: 15,
    lineHeight: 24,
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
});
