import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Text,
  Divider,
  useStyleSheet,
  Spinner,
  Icon,
} from "@ui-kitten/components";
import React, { useCallback, useState } from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import Collapsible from "react-native-collapsible";
import _ from "lodash";

import { TableDisplay } from "../../components/TableDisplay";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { PullReportButton } from "./PullReportButton";
import { BankStatementList } from "./BankStatementList";
import { themedStyles } from "./themedStyles";

export const Accounting: React.FC<any> = ({ applicationId }) => {
  const styles = useStyleSheet(themedStyles);
  const [loading, setLoading] = useState(false);

  const { authStore } = useStore();

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const QuestionCircleIcon = (props: any) => (
    <Icon {...props} name="question-mark-circle-outline" />
  );

  const accountsTableHeaders = [
    "0-30 Days",
    "31-60 Days",
    "61-90 Days",
    "Over 90 Days",
    "Total",
  ];

  const [bankStatements, setBankStatements]: any = useState([]);
  const [financialsDataDisplay, setFinancialsDataDisplay]: any = useState([
    {
      header: "Bank Name",
      displayValue: "?",
      fieldNameInResponse: "bankName",
    },
    {
      header: "Account Number",
      displayValue: "?",
      fieldNameInResponse: "accountNumber",
    },
    {
      header: "Routing Number",
      displayValue: "?",
      fieldNameInResponse: "routingNumber",
    },
    {
      header: "Average Cash Balance over 12 Months",
      displayValue: "?",
      fieldNameInResponse: "averageCashBalanceOver12Months",
      prefix: "$",
      infoTooltip: `Uses data from bank accounts applicant links with Plaid
computes monthly balances for last 12 months
(balance for month x is calculated by summing current balances across all accounts and undoing any transactions that happened after)

so balance[0] is balance of all accounts at the time of last Plaid data pull
balance[1] is balance 1 month before that
...
balance[i] is balance i months before that
...
balance[11] is balance 1 year before that

it's possible that applicant's bank account is new and there's not enough data from Plaid
to calculate all balances in the past. In that case only available month balances are computed.

then average cash balance over 12 months is sum of monthly balances divided by 12
(or by number less than 12 if less months of balance history are available)`,
    },
    {
      header: "Average change in cash balance over 12 months",
      displayValue: "?",
      fieldNameInResponse: "changeInCashBalanceOver12Months",
      suffix: "%",
      infoTooltip: `Uses data from bank accounts applicant links with Plaid
computes monthly balances for last 12 months
(balance for month x is calculated by summing current balances across all accounts and undoing any transactions that happened after)

so balance[0] is balance of all accounts at the time of last Plaid data pull
balance[1] is balance 1 month before that
...
balance[i] is balance i months before that
...
balance[11] is balance 1 year before that

it's possible that applicant's bank account is new and there's not enough data from Plaid
to calculate all balances in the past. In that case only available month balances are computed.

then percent changes in balance are computes as ( balance[i] / balance[i-1] * 100 - 100) for each i in [1,11]

then Average change in cash balance over 12 months is sum of monthly balances divided by 11
(or by number less than 11 if less months of balance history are available)
`,
    },
    {
      header: "Debt Service Coverage by Cash ",
      displayValue: "?",
      fieldNameInResponse: "debtServiceCoverageByCash",
    },
    {
      header: "Debt Service Coverage by Operating Income",
      displayValue: "?",
      fieldNameInResponse: "debtServiceCoverageByOperatingIncome",
    },
    {
      header: "Accounts Payable Current Period Total",
      displayValue: "?",
      fieldNameInResponse: "accountsPayableCurrentPeriodTotal",
      prefix: "$",
    },
    {
      header: "Accounts Receivable Current Period Total",
      displayValue: "?",
      fieldNameInResponse: "accountsReceivableCurrentPeriodTotal",
      prefix: "$",
    },
    {
      header: "Current Ratio",
      displayValue: "?",
      fieldNameInResponse: "currentRatio",
    },
    {
      header: "Working Capital",
      displayValue: "?",
      fieldNameInResponse: "workingCapitalRatio",
    },
    {
      header: "Quick Ratio",
      displayValue: "?",
      fieldNameInResponse: "quickRatio",
    },
    {
      header: "Tangible Net Worth",
      displayValue: "?",
      fieldNameInResponse: "tangibleNetWorth",
    },
    {
      header: "Leverage Ratio",
      displayValue: "?",
      fieldNameInResponse: "leverageRatio",
    },
    {
      header: "Days Sales Outstanding Ratio",
      displayValue: "?",
      fieldNameInResponse: "daysSalesOutstandingRatio",
    },
    {
      header: "Inventory Turnover ",
      displayValue: "?",
      fieldNameInResponse: "inventoryTurnover",
    },
    {
      header: "Accounts Payable Turnover",
      displayValue: "?",
      fieldNameInResponse: "accountsPayableTurnover",
    },
    {
      header: "EBITDA",
      displayValue: "?",
      fieldNameInResponse: "ebitda",
      prefix: "$",
    },
    {
      header: "Interest Coverage",
      displayValue: "?",
      fieldNameInResponse: "interestCoverage",
    },
  ]);

  const accountsObjKeys = [
    "account",
    "first30Days",
    "days30To60",
    "days60To90",
    "over90Days",
    "total",
  ];

  const [otherDocuments, setOtherDocuments]: any = useState([]);

  const [accountsPayable, setAccountsPayable]: any = useState([
    {
      account: "?",
      first30Days: "?",
      days30To60: "?",
      days60To90: "?",
      over90Days: "?",
      total: "?",
    },
  ]);

  const [accountsReceivable, setAccountsReceivable]: any = useState([
    {
      account: "?",
      first30Days: "?",
      days30To60: "?",
      days60To90: "?",
      over90Days: "?",
      total: "?",
    },
  ]);

  const [accountsPayableCurrentPeriod, setAccountsPayableCurrentPeriod] =
    useState("?");
  const [accountsReceivableCurrentPeriod, setAccountsReceivableCurrentPeriod] =
    useState("?");

  const [bankPullableReportsDataDisplay, setBankPullableReportsDataDisplay] =
    useState([
      {
        header: "Bank Asset Reports",
        displayValue: <BankStatementList bankStatements={bankStatements} />,
        isLink: true,
        fieldNameInResponse: "bankAssetReport",
      },
      {
        header: "Other Bank Accounts",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "otherBankAccountsReport",
      },
      {
        header: "YTD Financial Statements",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "ytdFinancialStatements",
      },
      {
        header: "Current Period Financial Statements",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "currentPeriodFinancialStatements",
      },
      {
        header: "FYE Financial Statements",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        fieldNameInResponse: "fyeFinancialStatements",
      },
    ]);

  const [financialProjectionsDataDisplay, setFinancialProjectionsDataDisplay] =
    useState([
      {
        header: "Fiscal Year End Financial Statement Projections",
        displayValue: <PullReportButton uri="" isLink />,
        isLink: true,
        isOptional: true,
        fieldNameInResponse: "fiscalYearEndProjections",
      },
    ]);

  useFocusEffect(
    useCallback(() => {
      let bankStatementsFormatted = false;
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getFinancials(authStore?.accessToken, applicationId)
          .then((resp) => {
            const response = resp.response.entities;
            const primaryAccount = _.get(
              response,
              "bankReports.bankAccounts.primaryAccount",
              false
            );
            if (primaryAccount) {
              const mask = primaryAccount.mask;
              const bankStatementsRaw = primaryAccount.bankStatements.reverse();
              bankStatementsFormatted = bankStatementsRaw.map(
                (statement: { year: any; fileUrl: any; title: any }) => {
                  return {
                    accountMask: mask,
                    title: statement.title,
                    uri: statement.fileUrl,
                    year: statement.year,
                  };
                }
              );
              if (bankStatementsFormatted) {
                setBankStatements(bankStatementsFormatted);
              }
            }

            setFinancialsDataDisplay(
              financialsDataDisplay.map((obj: any) => {
                if (obj.fieldNameInResponse in response.financialsData) {
                  return {
                    ...obj,
                    displayValue:
                      response.financialsData[obj.fieldNameInResponse],
                  };
                } else return obj;
              })
            );

            if (
              response.accountsPayableData &&
              response.accountsPayableData.length
            ) {
              const data = [...response.accountsPayableData];
              setAccountsPayable(data.slice(0, 10));
            }

            setAccountsPayableCurrentPeriod(
              response.financialsData.accountsPayableCurrentPeriodTotal
            );

            if (
              response.accountsReceivableData &&
              response.accountsReceivableData.length
            ) {
              const data = [...response.accountsReceivableData];
              setAccountsReceivable(data.slice(0, 10));
            }

            setAccountsReceivableCurrentPeriod(
              response.financialsData.accountsReceivableCurrentPeriodTotal
            );

            setBankPullableReportsDataDisplay(
              bankPullableReportsDataDisplay.map((obj: any) => {
                if (
                  obj.fieldNameInResponse === "bankAssetReport" &&
                  bankStatementsFormatted
                ) {
                  return {
                    ...obj,
                    displayValue: (
                      <BankStatementList
                        bankStatements={bankStatementsFormatted}
                      />
                    ),
                  };
                } else if (obj.fieldNameInResponse in response.bankReports) {
                  return {
                    ...obj,
                    displayValue: (
                      <PullReportButton
                        uri={response.bankReports[obj.fieldNameInResponse]}
                        isLink
                        text={
                          response[obj.fieldNameInResponse] === "error"
                            ? "REPORT FAIL"
                            : "PULL REPORT"
                        }
                      />
                    ),
                  };
                } else return obj;
              })
            );

            setFinancialProjectionsDataDisplay(
              financialProjectionsDataDisplay.map((obj: any) => {
                if (obj.fieldNameInResponse in response.financialProjections) {
                  return {
                    ...obj,
                    displayValue: (
                      <PullReportButton
                        uri={
                          response.financialProjections[obj.fieldNameInResponse]
                        }
                        isLink
                        text={
                          response[obj.fieldNameInResponse] === "error"
                            ? "REPORT FAIL"
                            : "PULL REPORT"
                        }
                      />
                    ),
                  };
                } else return obj;
              })
            );

            setOtherDocuments(
              response.otherDocuments.map((doc: any) => {
                return {
                  header: Object.keys(doc)[0],
                  displayValue: (
                    <PullReportButton uri={Object.values(doc)[0]} isLink />
                  ),
                  isLink: true,
                };
              })
            );

            setLoading(false);
          });
      }
    }, [useIsFocused()])
  );

  const [isFinancialsOpen, setIsFinancialsOpen] = useState(true);
  const [isAccountsPayableOpen, setIsAccountsPayableOpen] = useState(true);
  const [isAccountsReceivableOpen, setIsAccountsReceivableOpen] =
    useState(true);
  const [isBankReportsOpen, setIsBankReportsOpen] = useState(true);
  const [isFinancialProjectionsOpen, setIsFinancialProjectionsOpen] =
    useState(true);
  const [isOtherOpen, setIsOtherOpen] = useState(true);

  if (loading)
    return (
      <View style={{ ...styles.container, alignItems: "center" }}>
        <LoadingIndicator />
      </View>
    );

  return (
    <View style={styles.cardsContainer}>
      <View style={styles.card}>
        <TouchableWithoutFeedback
          onPress={() => setIsFinancialsOpen(!isFinancialsOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardHeader}>
              {applicationId
                ? "Accounting"
                : "Accounting (no application selected)"}
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [{ rotate: isFinancialsOpen ? "180deg" : "0deg" }],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isFinancialsOpen}>
          <TableDisplay data={financialsDataDisplay} itemsPerRow={4} />
        </Collapsible>

        <TouchableWithoutFeedback
          onPress={() => setIsAccountsPayableOpen(!isAccountsPayableOpen)}
        >
          <View style={styles.flexRowBetween}>
            <View style={{ ...styles.flexRow, alignItems: "center" }}>
              <Text style={styles.cardSubHeader}>
                Accounts Payable Month End{" "}
              </Text>
            </View>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    { rotate: isAccountsPayableOpen ? "180deg" : "0deg" },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>

        <Collapsible collapsed={!isAccountsPayableOpen}>
          <TableDisplay
            data={["A/P Account", ...accountsTableHeaders].map((header) => ({
              header,
            }))}
            itemsPerRow={6}
            hideValues
            itemStyle={{ fontFamily: "SFProTextRegular" }}
          />
          <Divider style={styles.cardDivider} />
          {accountsPayable.map((acc: any, idx: number) => (
            <TableDisplay
              key={"payable-acc-" + idx}
              hideHeader
              valuesNotBold
              itemsPerRow={6}
              data={accountsObjKeys.map((key) => ({
                displayValue: `${acc[key]}`,
                prefix: key !== "account" ? "$" : "",
              }))}
              itemStyle={{ fontFamily: "SFProTextRegular" }}
            />
          ))}
          <Divider style={styles.cardDivider} />
          <TableDisplay
            itemsPerRow={4}
            data={[
              {
                header: "Accounts Payable Current Period",
                displayValue: accountsPayableCurrentPeriod,
                prefix: "$",
              },
            ]}
          />
        </Collapsible>

        <TouchableWithoutFeedback
          onPress={() => setIsAccountsReceivableOpen(!isAccountsReceivableOpen)}
        >
          <View style={styles.flexRowBetween}>
            <View style={{ ...styles.flexRow, alignItems: "center" }}>
              <Text style={styles.cardSubHeader}>
                Accounts Receivable Month End
              </Text>
            </View>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    { rotate: isAccountsReceivableOpen ? "180deg" : "0deg" },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>

        <Collapsible collapsed={!isAccountsReceivableOpen}>
          <TableDisplay
            data={["A/R Account", ...accountsTableHeaders].map((header) => ({
              header,
            }))}
            itemsPerRow={6}
            hideValues
            itemStyle={{ fontFamily: "SFProTextRegular" }}
          />
          <Divider style={styles.cardDivider} />
          {accountsReceivable.map((acc: any, idx: number) => (
            <TableDisplay
              key={"receivable-acc-" + idx}
              hideHeader
              valuesNotBold
              itemsPerRow={6}
              data={accountsObjKeys.map((key) => ({
                displayValue: `${acc[key]}`,
                prefix: key !== "account" ? "$" : "",
              }))}
              itemStyle={{ fontFamily: "SFProTextRegular" }}
            />
          ))}
          <Divider style={styles.cardDivider} />
          <TableDisplay
            itemsPerRow={4}
            data={[
              {
                header: "Accounts Receivable Current Period",
                displayValue: accountsReceivableCurrentPeriod,
                prefix: "$",
              },
            ]}
          />
        </Collapsible>

        <Divider style={styles.cardDivider} />
        <TouchableWithoutFeedback
          onPress={() => setIsBankReportsOpen(!isBankReportsOpen)}
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardSection} appearance="hint">
              BANK REPORTS
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    { rotate: isBankReportsOpen ? "180deg" : "0deg" },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isBankReportsOpen}>
          <TableDisplay itemsPerRow={5} data={bankPullableReportsDataDisplay} />
        </Collapsible>
        <Divider style={styles.cardDivider} />
        <TouchableWithoutFeedback
          onPress={() =>
            setIsFinancialProjectionsOpen(!isFinancialProjectionsOpen)
          }
        >
          <View style={styles.flexRowBetween}>
            <Text style={styles.cardSection} appearance="hint">
              FINANCIAL PROJECTIONS
            </Text>
            <Icon
              style={[
                styles.chevronButton,
                {
                  transform: [
                    { rotate: isFinancialProjectionsOpen ? "180deg" : "0deg" },
                  ],
                },
              ]}
              name="chevron-down-outline"
            />
          </View>
        </TouchableWithoutFeedback>
        <Divider style={styles.cardDivider} />
        <Collapsible collapsed={!isFinancialProjectionsOpen}>
          <TableDisplay
            itemsPerRow={3}
            data={financialProjectionsDataDisplay}
          />
        </Collapsible>

        {otherDocuments.length > 0 ? (
          <View>
            <Divider style={styles.cardDivider} />
            <TouchableWithoutFeedback
              onPress={() => setIsOtherOpen(!isOtherOpen)}
            >
              <View style={styles.flexRowBetween}>
                <Text style={styles.cardSection} appearance="hint">
                  OTHER DOCUMENTS
                </Text>
                <Icon
                  style={[
                    styles.chevronButton,
                    {
                      transform: [{ rotate: isOtherOpen ? "180deg" : "0deg" }],
                    },
                  ]}
                  name="chevron-down-outline"
                />
              </View>
            </TouchableWithoutFeedback>
            <Collapsible collapsed={!isOtherOpen}>
              <Divider style={styles.cardDivider} />
              <TableDisplay itemsPerRow={4} data={otherDocuments} />
            </Collapsible>
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};
