import * as eva from "@eva-design/eva";
import {
  Text,
  useStyleSheet,
  Modal,
  Card,
  CheckBox,
  ThemeProvider,
} from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";

import { SORT } from "./index";
import { themedStyles } from "./themedStyles";

export const FilterOptionsModal: React.FC<any> = ({
  isOptionsModalOpen,
  setIsOptionsModalOpen,
  filterOptions,
  setFilterOptions,
  sortingMode,
  setSortingMode,
}) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <Modal
      visible={isOptionsModalOpen}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => setIsOptionsModalOpen(false)}
    >
      <ThemeProvider theme={eva.light}>
        <Card disabled>
          <View style={styles.optionsModal}>
            <View style={styles.optionsModalSection}>
              <Text style={styles.optionsHeader}>Sort</Text>
              <CheckBox
                style={styles.filterCheckbox}
                checked={sortingMode === SORT.OLDEST_MODIFIED}
                onChange={(nextChecked) => setSortingMode(SORT.OLDEST_MODIFIED)}
              >
                Oldest Modified First
              </CheckBox>
              <CheckBox
                style={styles.filterCheckbox}
                checked={sortingMode === SORT.NEWEST_MODIFIED}
                onChange={(nextChecked) => setSortingMode(SORT.NEWEST_MODIFIED)}
              >
                Newest Modified First
              </CheckBox>
              <CheckBox
                style={styles.filterCheckbox}
                checked={sortingMode === SORT.APPROVED_INC}
                onChange={(nextChecked) => setSortingMode(SORT.APPROVED_INC)}
              >
                Oldest Approved First
              </CheckBox>
              <CheckBox
                style={styles.filterCheckbox}
                checked={sortingMode === SORT.APPROVED_DEC}
                onChange={(nextChecked) => setSortingMode(SORT.APPROVED_DEC)}
              >
                Latest Approved First
              </CheckBox>
              <CheckBox
                style={styles.filterCheckbox}
                checked={sortingMode === SORT.LOAN_ID_INC}
                onChange={(nextChecked) => setSortingMode(SORT.LOAN_ID_INC)}
              >
                Loan ID Ascending
              </CheckBox>
              <CheckBox
                style={styles.filterCheckbox}
                checked={sortingMode === SORT.LOAN_ID_DEC}
                onChange={(nextChecked) => setSortingMode(SORT.LOAN_ID_DEC)}
              >
                Loan ID Descending
              </CheckBox>
            </View>
          </View>
        </Card>
      </ThemeProvider>
    </Modal>
  );
};
