import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import MBOInfo from "../../models/MBOInfo";
import { camelCaseToSnakeCase } from "../../utils";
import { callApi, getFullUrl } from "./base";

export const addMBOInfo = async (token: string, data: Partial<MBOInfo>) =>
  callApi(
    config.urls.mbo.mbo,
    token,
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data.$ || {}))
  );

export const fetchMBOInfo = async (token: string) =>
  callApi(config.urls.mbo.mbo, token, "GET");

export const updateMBOInfoFiles = async (
  token: string,
  id: number,
  data: object
) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) =>
    //@ts-ignore
    formData.append(camelCaseToSnakeCase(key), data[key])
  );

  return callApi(`${config.urls.mbo.mbo}${id}/`, token, "PATCH", {}, formData);
};

export const updateMBOInfo = async (
  token: string,
  id: number,
  data: Partial<MBOInfo>
) =>
  callApi(
    `${config.urls.mbo.mbo}${id}/`,
    token,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );

export const deleteMBOInfo = async (token: string, id: number) =>
  callApi(`${config.urls.mbo.mbo}${id}/`, token, "DELETE");

export const getUnsigned8821FormForMBO = async (token: string, id: number) =>
  callApi(`${config.urls.mbo.mbo}${id}/get_unsigned8821_form/`, token, "POST", {
    "Content-Type": "application/json",
  });

export const uploadFileToMBOAPI = async (
  token: string | null,
  id: number,
  file: File,
  name: string
) => {
  if (token) {
    try {
      const data = new FormData();
      data.append(name, file);

      await fetch(getFullUrl(`${config.urls.mbo.mbo}${id}/`), {
        method: "PATCH",
        credentials: "include",
        headers: { Authorization: `Bearer ${token}` },
        body: data,
      });
    } catch (error: any) {
      console.log(error.message);
    }
  }
};

export const listAllBusinessOwners = async (token: string, data: any) =>
  callApi(
    config.urls.mbo.list_all_business_owners,
    token,
    "POST",
    {
      "Content-Type": "application/json",
    },
    JSON.stringify(snakeCaseKeys(data))
  );
