import * as eva from "@eva-design/eva";
import {
  Modal,
  Text,
  ThemeProvider,
  useStyleSheet,
  Divider,
  Icon,
  Button,
  Input,
  CheckBox,
} from "@ui-kitten/components";
import React, { useState } from "react";
import { View, TouchableOpacity, ScrollView, Dimensions } from "react-native";

import { themedStyles } from "./themedStyles";

const DeclineApplicationModal: React.FC<any> = ({
  isOpen,
  close,
  declineOptions,
  setVerificationData,
  verificationData,
  onDecline,
  underwritingSpreadsheet,
}) => {
  const { width, height } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;

  const [search, setSearch] = useState("");

  return (
    <Modal
      visible={isOpen}
      onBackdropPress={() => close()}
      style={styles.declineModal}
      backdropStyle={styles.backdrop}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.modalHeaderContainer}>
          <Text style={styles.modalHeader}>Decline</Text>
          <TouchableOpacity onPress={() => close()}>
            <Icon width={24} height={24} name="close-outline" />
          </TouchableOpacity>
        </View>
        <Divider style={styles.cardDivider} />
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "stretch" }}
        >
          <View
            style={{
              height: height * 0.7,
              width: width * 0.6,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Montserrat500_Normal",
                fontSize: 22,
                lineHeight: 32,
                textAlign: "center",
                padding: 10,
              }}
            >
              Please indicate the reason why you're declining the application.
            </Text>
            <Input
              placeholder="Search"
              style={{ padding: 10, margin: 10 }}
              accessoryRight={SearchIcon}
              value={search}
              onChangeText={(text) => setSearch(text)}
            />
            <ScrollView
              style={{ flex: 1, maxHeight: 325, padding: 10, margin: 10 }}
            >
              {declineOptions
                .filter(
                  (opt: any) =>
                    opt.description.includes(search) ||
                    opt.code.includes(search)
                )
                .map((opt: any) => (
                  <CheckBox
                    key={opt.code}
                    style={{ padding: 5, margin: 5 }}
                    status="danger"
                    checked={verificationData.declineReasons.includes(opt.code)}
                    onChange={() => {
                      console.log(verificationData);
                      if (verificationData.declineReasons.includes(opt.code)) {
                        setVerificationData({
                          ...verificationData,
                          declineReasons:
                            verificationData.declineReasons.filter(
                              (code: any) => code !== opt.code
                            ),
                        });
                      } else {
                        setVerificationData({
                          ...verificationData,
                          declineReasons: [
                            ...verificationData.declineReasons,
                            opt.code,
                          ],
                        });
                      }
                    }}
                  >
                    {opt.description}
                  </CheckBox>
                ))}
            </ScrollView>
            <View style={{ ...styles.flexRow, marginTop: 25 }}>
              <Button
                style={styles.outlineButton}
                appearance="outline"
                status="danger"
                disabled={!underwritingSpreadsheet}
                onPress={() => onDecline()}
              >
                DECLINE
              </Button>
            </View>
          </View>
        </View>
      </ThemeProvider>
    </Modal>
  );
};

export default DeclineApplicationModal;
