import { useStyleSheet, Icon, Button, Input } from "@ui-kitten/components";
import React, { useState, useRef } from "react";
import { View, TouchableOpacity } from "react-native";

import { themedStyles } from "./themedStyles";

const ListInput: React.FC<any> = ({
  values,
  setValues,
  disabled,
  inputStyle,
  placeholder,
  accessoryRight,
}) => {
  const styles = useStyleSheet(themedStyles);

  const [textInput, setTextInput] = useState("");

  const handleTextChange = (text: string, submit = false) => {
    if (text.indexOf(",") !== -1) {
      setValues([...values, text.substr(0, text.indexOf(","))]);
      handleTextChange(text.substr(text.indexOf(",") + 1), submit);
    } else if (!submit) setTextInput(text);
    else if (submit) {
      if (text) setValues([...values, text]);
      setTextInput("");
    }
  };

  const tradeNameRef = useRef<Input>(null);

  return (
    <TouchableOpacity onPress={() => tradeNameRef.current?.focus()}>
      <View style={styles.pillsContainer}>
        {values.map((value: any, idx: number) => (
          <Button
            key={"pill-" + idx}
            style={styles.pill}
            size="small"
            accessoryRight={(props: any) => (
              <TouchableOpacity
                onPress={() =>
                  setValues(values.filter((val: any) => val !== value))
                }
              >
                <Icon {...props} name="close-outline" />
              </TouchableOpacity>
            )}
          >
            {value}
          </Button>
        ))}
      </View>
      <View>
        <Input
          ref={tradeNameRef}
          disabled={disabled}
          style={inputStyle}
          textStyle={styles.inputText}
          value={textInput}
          placeholder={placeholder}
          accessoryRight={accessoryRight}
          onChangeText={handleTextChange}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9 && textInput) {
              event.preventDefault();
              handleTextChange(textInput, true);
              setTimeout(() => tradeNameRef.current?.focus(), 10);
            }
          }}
          onSubmitEditing={() => {
            if (textInput) {
              handleTextChange(textInput, true);
              setTimeout(() => tradeNameRef.current?.focus(), 10);
            }
          }}
          onBlur={() => {
            if (textInput) {
              handleTextChange(textInput, true);
            }
          }}
        />
      </View>
    </TouchableOpacity>
  );
};

export default ListInput;
