import { useStyleSheet } from "@ui-kitten/components";
import React from "react";
import { View } from "react-native";
//@ts-ignore
import { WebView as WebViewWeb } from "react-native-web-webview";

import config from "../../config";
import { themedStyles } from "./themedStyles";

/*
  A iframe wrapper for rich text editing
  Note:
  do not update initialText with setText - this will cause iframe to reload every keystroke
*/

const RichTextEditor: React.FC<any> = ({
  initialText = "",
  setText,
  placeholder = "",
  readOnly = false,
  theme = "light",
  fileUploadButtonClickCallback = () => {},
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={{ ...styles.webviewContainer }}>
      <WebViewWeb
        scrollEnabled
        source={
          readOnly
            ? {
                html: `
<link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
<style>
    body {
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .ql-editor .ql-blank::placeholder{
        color: ${theme === "dark" ? "white" : "black"} !important;
    }
    #editor {
        height: 75%;
        color: ${theme === "dark" ? "white" : "black"};
    }
    .ql-toolbar .ql-stroke {
    fill: none;
    stroke: ${theme === "dark" ? "white" : "black"};
}

    .ql-toolbar .ql-fill {
        fill: ${theme === "dark" ? "white" : "black"};
        stroke: none;
    }

    .ql-toolbar .ql-picker {
        color: ${theme === "dark" ? "white" : "black"};
    }
</style>

<!-- Create the editor container -->
<div id="editor">
</div>

<!-- Include the Quill library -->
<script src="https://cdn.quilljs.com/1.3.6/quill.js"></script>

<!-- Initialize Quill editor -->
<script>
  var quill = new Quill('#editor', {
      readOnly: true,
  });

  quill.setContents(JSON.parse(atob("${initialText}") || "{}"));
</script>`,
              }
            : {
                uri: `${config.urls.api}${config.urls.get_rich_text_editor_view}?text=${initialText}&placeholder=${placeholder}&readOnly=${readOnly}&theme=${theme}`,
              }
        }
        onMessage={(e: any) => {
          if (e?.nativeEvent?.isTrusted && e?.nativeEvent?.data) {
            try {
              const json = JSON.parse(e.nativeEvent.data || "");
              if (json.text) {
                const jsonString = JSON.stringify(json.text);
                const jsonBase64 = Buffer.from(jsonString).toString("base64");
                setText(jsonBase64);
                console.log(jsonString, jsonBase64);
              }
              if (json.fileButtonClick) {
                fileUploadButtonClickCallback();
              }
            } catch (e) {}
          }
        }}
      />
    </View>
  );
};

export default RichTextEditor;
