import { useNavigation } from "@react-navigation/native";
import {
  Layout,
  Text,
  useStyleSheet,
  Icon,
  Button,
} from "@ui-kitten/components";
import React from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
type Props = object;

const ESign: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const BackIcon = (props: any) => <Icon {...props} name="backspace-outline" />;

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.eSignContainer}>
          <View style={styles.eSign}>
            <Text style={styles.header}>e-sign</Text>
            <Text>// TODO: write/get e-sign</Text>

            <Button
              style={styles.nextActionButton}
              status="basic"
              onPress={() => {
                navigation.goBack();
              }}
              accessoryLeft={BackIcon}
            >
              go back
            </Button>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default ESign;
