import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import CommercialCreditReportAuthorization from "../../containers/CreditAndUCCAuthorization/CommercialCreditReportAuthorization";
import ConsumerCreditReportAuthorization from "../../containers/CreditAndUCCAuthorization/ConsumerCreditReportAuthorization";
import UCCAuthorization from "../../containers/CreditAndUCCAuthorization/UCCAuthorization";

export type ComplianceStackParamList = {
  "commercial-credit": undefined;
  "consumer-credit": undefined;
  "ucc-authorization": undefined;
};

export type ComplianceStackNavigation =
  StackNavigationProp<ComplianceStackParamList>;

type Props = object;

const Stack = createStackNavigator<ComplianceStackParamList>();

const ComplianceStack: React.FC<Props> = (props) => {
  return (
    <Stack.Navigator initialRouteName="commercial-credit" headerMode="none">
      <Stack.Screen
        name="commercial-credit"
        options={{
          title: "OppZo | Compliance | Commercial Credit Report Authorization",
        }}
        component={CommercialCreditReportAuthorization}
      />
      <Stack.Screen
        name="consumer-credit"
        options={{
          title: "OppZo | Compliance | Consumer Credit Report Authorization",
        }}
        component={ConsumerCreditReportAuthorization}
      />
      <Stack.Screen
        name="ucc-authorization"
        options={{ title: "OppZo | Compliance | UCC Authorization" }}
        component={UCCAuthorization}
      />
    </Stack.Navigator>
  );
};

export default ComplianceStack;
