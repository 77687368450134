import {
  useStyleSheet,
  Button,
  Icon,
  Divider,
  List,
  ListItem,
  Tab,
  TabView,
  Layout,
  Text,
} from "@ui-kitten/components";
import React, { useState } from "react";

import config from "../../config";
import { themedStyles } from "./themedStyles";

interface IListItem {
  accountMask: string;
  title: string;
  uri: string;
  year: string;
}

const DownloadIcon = (props: any) => (
  <Icon {...props} name="download-outline" />
);
const getBankStatementYears = (bankStatements) => {
  let uniqueValues = bankStatements
    .map((statement) => statement.year)
    .filter(
      (value, index, current_value) => current_value.indexOf(value) === index
    );
  return uniqueValues;
};

const filterBankStatementsByYear = (bankStatements, year) => {
  const statementsByYear = bankStatements.filter(
    (statement) => statement.year === year
  );
  return statementsByYear;
};

export const BankStatementList: React.FC<any> = ({
  bankStatements = [],
  //   openModal,
}) => {
  const styles = useStyleSheet(themedStyles);

  const bankStatementYears = getBankStatementYears(bankStatements);
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  const RenderItemAccessory = (item: any): React.ReactElement => {
    return (
      <Button
        size="tiny"
        status="primary"
        accessoryLeft={DownloadIcon}
        onPress={() => {
          window.open(
            config.urls.api.replace("/api/", "") + item.item.uri,
            "_blank"
          );
        }}
      />
    );
  };
  const renderItem = ({
    item,
    index,
  }: {
    item: IListItem;
    index: number;
  }): React.ReactElement => (
    <ListItem
      title={`${item.title}`}
      description={`Acct #: ${item.accountMask}`}
      accessoryRight={<RenderItemAccessory item={item} />}
    />
  );

  if (bankStatements && bankStatements.length > 0) {
    return (
      <TabView
        selectedIndex={selectedIndex}
        onSelect={(index) => setSelectedIndex(index)}
        style={styles.tabView}
      >
        {bankStatementYears.map((year, idx) => (
          <Tab title={year} key={idx}>
            <Layout style={styles.tabContainer}>
              <List
                data={filterBankStatementsByYear(bankStatements, year)}
                ItemSeparatorComponent={Divider}
                renderItem={renderItem}
              />
            </Layout>
          </Tab>
        ))}
      </TabView>
    );
  } else {
    return <Text status="warning">Bank Statements Not Available</Text>;
  }
};
