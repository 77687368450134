import { useEffect, useState } from "react";
import * as Sentry from "sentry-expo";

import config from "../config";
import { createStore, Store } from "../stores";

export const useInit = () => {
  const [store, setStore] = useState<Store>();
  const [ready, setReady] = useState({
    store: false,
  });

  useEffect(() => {
    Sentry.init({
      dsn: config.sentry_dsn,
      environment: config.sentry_environment,
    });
    (async () => {
      const store = createStore();
      await store.load();
      setStore(store);
      setReady((prevReady) => ({
        ...prevReady,
        store: true,
      }));

      store.fetchInitialData();
    })();
  }, []);

  return {
    store,
    ready: !Object.values(ready).includes(false),
  };
};
