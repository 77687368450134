import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  flexRow: { flexDirection: "row" },
  flexColumn: { flexDirection: "column" },
  flexAlignCenter: { alignItems: "center" },
  scroll: {
    flex: 1,
  },
  gradientContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: 589,
    width: "90%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  gradientContainerMobile: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 589,
    width: "95%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 70,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
    marginBottom: 34,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 18,
    fontSize: 12,
    marginBottom: 34,
  },
  bold: {
    fontFamily: "Montserrat_700Bold",
  },
  title: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 26,
    color: "white",
    lineHeight: 32,
    marginBottom: 20,
  },
  subTitle: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 24,
    color: "white",
  },
  preApproval: {
    flex: 1,
    backgroundColor: "#101426",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 100,
    marginVertical: 50,
    maxWidth: 800,
  },
  preApprovalMobile: {
    flex: 1,
    backgroundColor: "#101426",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 32,
    marginHorizontal: 36,
    marginVertical: 22,
    width: "80%",
  },
  preApprovalContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
    marginRight: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  disabledNextActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
    background: "basic-color-default",
    transition: "none",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
  },
  disabledActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "color-basic-600",
    fontSize: 14,
  },
  goToLoanTracker: {
    flex: 1,
    alignItems: "center",
  },
  goToLoanTrackerButton: {
    flex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    marginTop: 20,
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 4,
    borderColor: "#26DDF1",
  },
  goToLoanTrackerText: {
    color: "#26DDF1",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
    textTransform: "uppercase",
  },
  inputLabel: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  input: {
    flex: 1,
    marginBottom: 20,
    marginRight: 16,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputMobile: {
    flex: 1,
    marginBottom: 16,
    marginRight: 5,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
  },
  inputTextMobile: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    fontSize: 12,
    color: "white",
    width: "100%",
  },
  consentTitle: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 20,
    lineHeight: 24,
    marginBottom: 10,
  },
  accountLinkingButton: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 22,
    backgroundColor: "rgba(143, 155, 179, 0.08)",
    border: 1,
    borderColor: "transparent",
    borderStyle: "solid",
    borderRadius: 5,
    transition: "all 0.25 ease-in-out",
  },
  accountLinkingButtonHover: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 22,
    backgroundColor: "rgba(143, 155, 179, 0.08)",
    border: 1,
    borderColor: "color-primary-500",
    borderStyle: "solid",
    borderRadius: 5,
    transition: "all 0.25 ease-in-out",
  },
  accountLinkingSuccess: {
    backgroundColor: "rgba(0, 153, 51, 0.08)",
  },
  accountLinkingButtonHeader: {
    fontFamily: "Montserrat_400Regular",
    color: "background-color-basic-1",
    lineHeight: 32,
    fontSize: 22,
  },
  accountLinkingButtonText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
    color: "background-color-basic-1",
    lineHeight: 24,
    fontSize: 13,
    marginTop: 5,
    display: "flex",
  },
  backdrop: {
    backgroundColor: "transparent",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  payrollModalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#112",
  },
  payrollModal: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
    maxHeight: 600,
    backgroundColor: "#112",
    overflowHorizontal: "hidden",
  },
  payrollModalTitle: {
    color: "white",
    fontFamily: "Montserrat_500Medium",
    lineHeight: 24,
    fontSize: 18,
  },
  payrollScroll: {
    margin: 20,
    padding: 10,
  },
  payrollBankListContainer: {},
  bankModal: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  bankModalCard: {
    display: "flex",
    borderRadius: 10,
    paddingVertical: 10,
    justifyContent: "center",
    alignContent: "center",
    justifyItems: "center",
    alignItems: "stretch",
  },
  accountingModalCard: {
    display: "flex",
    borderRadius: 10,
    paddingVertical: 10,
    justifyContent: "center",
    alignItems: "stretch",
    maxWidth: 600,
  },
  accountingModalCardContainer: {
    display: "flex",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  closeModalBtn: {
    alignSelf: "flex-end",
    justifySelf: "flex-end",
    height: 50,
    width: 50,
  },
  modalHeaderText: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 22,
    textAlign: "center",
    paddingHorizontal: 50,
    paddingTop: 15,
    paddingBottom: 30,
    borderBottom: "1px solid gray",
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    marginVertical: 12,
    paddingRight: 5,
  },
  modalInfoText: {
    padding: 10,
    textAlign: "center",
  },
  modalContinueLinkButton: {
    width: "30%",
    paddingVertical: 10,
    alignSelf: "center",
  },
  accountingModalHeaderText: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 22,
    textAlign: "center",
    paddingHorizontal: 25,
    paddingTop: 15,
    paddingBottom: 5,
    borderBottom: "1px solid gray",
  },
  accountingAccountLinkButton: {
    width: 300,
    paddingHorizontal: 5,
    paddingVertical: 25,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginVertical: 7,
    backgroundColor: "white",
  },
  accountingAccountOptionName: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 32,
    fontSize: 22,
  },
  accountLinkingButtonContainer: {
    paddingHorizontal: 10,
    flex: 1,
  },
  fullApplicationStatusContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 50,
  },
  fullApplicationStatusContainerMobile: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    width: "100%",
  },
  preApprovalLabelContainer: {
    flex: 1,
    textAlign: "center",
    marginBottom: 34,
  },
  preApprovalLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "#1D84FF",
  },
  preApprovalSubLabel: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 10,
    lineHeight: 16,
    color: "white",
  },
  divider: {
    height: 2,
    color: "#C5CEE0",
    maxWidth: 40,
    minWidth: 10,
    flex: 1,
  },
  questionButton: {
    flex: 1,
    maxWidth: "49%",
    borderColor: "#55AAFF",
  },
  questionButtonSelected: {
    flex: 1,
    maxWidth: "49%",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
  },
  buttonTextSelected: {
    fontFamily: "Montserrat_700Bold",
    color: "#101426",
    textTransform: "uppercase",
  },
  buttonText: {
    fontFamily: "Montserrat_700Bold",
    color: "#55AAFF",
    textTransform: "uppercase",
  },
  questionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 80,
  },
  formQuestionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  spinnerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  applicationFeeContainer: {
    flex: 1,
    width: "100%",
    alignSelf: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  formContainerFlexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  taxGuardContainer: {
    flexDirection: "row",
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
  },
  errorMobile: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 10,
  },
  uploadButton: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
  },
  uploadButtonText: {
    fontFamily: "Montserrat_400Regular",
    fontStyle: "normal",
    fontSize: 15,
    lineHeight: 20,
    color: "#8F9BB3",
  },
  circleButton: {
    height: 20,
    width: 20,
    borderRadius: 20,
    backgroundColor: "#55AAFF",
  },
  iconSmall: {
    width: 18,
    height: 18,
    zindex: 2,
    fill: "#101426",
  },
  link: {
    color: "#26D4F3",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 15,
    textIndent: 0,
  },
  tooltipTextDark: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "black",
    wordBreak: "break",
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    wordBreak: "break",
    color: "white",
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
  datePickerPlaceholderText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginHorizontal: 8,
  },
  inputMask: {
    flex: 1,
    marginBottom: 20,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    paddingHorizontal: 15,
    paddingVertical: 13,
    width: "97.5%",
  },
  modalDetailsText: {
    paddingVertical: 20,
  },
  ebitdaSheetButtonText: {
    color: "white",
    textTransform: "uppercase",
    lineHeight: 16,
    fontSize: 13,
    fontFamily: "Montserrat_700Bold",
  },
});
