import { useNavigation, useIsFocused } from "@react-navigation/native";
import {
  Layout,
  Text,
  useStyleSheet,
  Icon,
  Button,
  Modal,
  Spinner,
} from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, useContext } from "react";
import {
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from "react-native";

import { ThemeContext } from "../../../theme-context";
import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
  setVersionControlPrivacy,
  setVersionControlTos,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import { PrivacyModal } from "../PrivacyPolicy";
import { TermsOfServiceModal } from "../TermsOfService";
import { themedStyles } from "./themedStyles";

const Dashboard: React.FC<any> = observer((props) => {
  const { width, height } = Dimensions.get("window");
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const sideImage = require("../../../assets/OppzoDashboard.png");
  const sideImageMobile = require("../../../assets/OppzoDashboardMobile.png");

  const ArrowIcon = (props: any) => (
    <Icon
      fill="white"
      name="arrow-ios-forward-outline"
      width={30}
      height={30}
    />
  );
  const LoadingIndicator = (_props: any) => (
    <View style={{ alignItems: "center" }}>
      <Spinner status="primary" size="giant" />
    </View>
  );
  const { authStore, loanStore, businessStore } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [loans, setLoans] = useState<any[]>([]);
  const [businesses, setBusinesses] = useState<any[]>([]);
  const [isPrivacyRead, setIsPrivacyRead] = useState(true);
  const [isTosRead, setIsTosRead] = useState(true);

  const [openPrivacyModal, setOpenPrivacyModal] = useState(true);
  const [openTosModal, setOpenTosModal] = useState(true);

  const themeContext = React.useContext(ThemeContext);

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  useEffect(() => {
    themeContext.resetTheme();
  }, []);

  const isFocused = useIsFocused();

  useEffect(() => {
    (async () => {
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (res1.response.entities !== undefined) {
        setIsPrivacyRead(res1.response.entities);
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (res2.response.entities !== undefined) {
        setIsTosRead(res2.response.entities);
      }
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    businessStore
      .fetchBusinesses()
      .then(() => {
        const newBusinesses: any = [];
        businessStore.business.forEach((newBusiness) => {
          if (authStore.user?.businesses?.includes(newBusiness.id)) {
            newBusinesses.push(newBusiness.$);
          }
        });
        setBusinesses(newBusinesses);
      })
      .then(() => {
        loanStore
          .fetchLoanApplications()
          .then(() => {
            const newLoans: any = [];
            loanStore.loanApplications.forEach((loan) => {
              if (
                authStore.user?.businesses?.includes(loan.business) &&
                !loan.isTemporary
              )
                newLoans.push(loan.$);
            });
            setLoans(newLoans);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          })
          .then(() => setLoading(false));
      });
  }, []);

  useEffect(() => {
    if (isFocused) {
      const newLoans: any = [];
      loanStore.loanApplications.forEach((loan) => {
        if (
          authStore.user?.businesses?.includes(loan.business) &&
          !loan.isTemporary
        )
          newLoans.push(loan.$);
      });
      setLoans(newLoans);
    }
  }, [loanStore.loanApplications, isFocused, businessStore.business]);

  return (
    <Layout style={styles.container}>
      <Modal
        visible={!isPrivacyRead && openPrivacyModal}
        backdropStyle={styles.modalBackdrop}
      >
        <View
          style={
            width > 576 ? styles.modalContainer : styles.modalContainerMobile
          }
        >
          <Text style={width > 576 ? styles.modalText : styles.modalTextMobile}>
            Privacy Policy Update
          </Text>
          <Text
            style={
              width > 576 ? styles.modalSubText : styles.modalSubTextMobile
            }
          >
            We've updated our Privacy Policy. Please take the time to read the
            updated version below. Once you have read the updated Privacy Policy
            and agree with them, please click the "I Agree" button below.
          </Text>
          <ScrollView
            style={width > 576 ? styles.modalScroll : styles.modalScrollMobile}
            keyboardShouldPersistTaps="handled"
          >
            <PrivacyModal />
          </ScrollView>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalButton}
              onPress={() => {
                setOpenPrivacyModal(false);
                setVersionControlPrivacy(authStore?.accessToken || "");
              }}
            >
              <Text style={styles.modalButtonText}>I Agree</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Modal
        visible={!isTosRead && openTosModal}
        backdropStyle={styles.modalBackdrop}
      >
        <View
          style={
            width > 576 ? styles.modalContainer : styles.modalContainerMobile
          }
        >
          <Text style={width > 576 ? styles.modalText : styles.modalTextMobile}>
            Terms of Service Update
          </Text>
          <Text
            style={
              width > 576 ? styles.modalSubText : styles.modalSubTextMobile
            }
          >
            We've updated our Terms of Service. Please take the time to read the
            updated version below. Once you have read the updated Terms of
            Service and agree with them, please click the "I Agree" button
            below.
          </Text>
          <ScrollView
            style={width > 576 ? styles.modalScroll : styles.modalScrollMobile}
            keyboardShouldPersistTaps="handled"
          >
            <TermsOfServiceModal />
          </ScrollView>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalButton}
              onPress={() => {
                setOpenTosModal(false);
                setVersionControlTos(authStore?.accessToken || "");
              }}
            >
              <Text style={styles.modalButtonText}>I Agree</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.loanTrackerContainer}>
          {width > 992 ? (
            <Image style={{ width: 517, height: 809 }} source={sideImage} />
          ) : (
            <Image
              style={{ width: height * 0.1529 * 0.9, height: height * 0.9 }}
              source={sideImageMobile}
            />
          )}
          <View
            style={width > 992 ? styles.loanTracker : styles.loanTrackerMobile}
          >
            <Text style={width > 576 ? styles.header : styles.headerMobile}>
              Hi,{" "}
              <Text
                style={
                  width > 576
                    ? { ...styles.header, ...styles.userName }
                    : { ...styles.headerMobile, ...styles.userName }
                }
              >
                {authStore.user?.firstName + " " + authStore.user?.lastName}
              </Text>
            </Text>

            <Text
              style={width > 576 ? styles.subHeader : styles.subHeaderMobile}
            >
              Please select which loan you want to track
            </Text>

            {loading ? (
              <LoadingIndicator />
            ) : (
              <ScrollView>
                {!loans.length && (
                  <Button
                    status="basic"
                    style={
                      authStore.user?.isPrimaryApplicant
                        ? styles.loanButton
                        : styles.loanButtonDisabled
                    }
                    accessoryRight={ArrowIcon}
                    disabled={!authStore.user?.isPrimaryApplicant}
                    onPress={() => {
                      navigation.navigate("pre-qualification");
                    }}
                    children={() => (
                      <Text
                        style={
                          width > 576
                            ? styles.loanButtonTextContainer
                            : styles.loanButtonTextContainerMobile
                        }
                      >
                        <Text
                          style={
                            width > 576
                              ? styles.subHeader
                              : styles.loanHeaderMobile
                          }
                        >
                          New loan
                        </Text>
                        {"\n"}
                        <Text
                          style={
                            width > 576
                              ? styles.description
                              : styles.descriptionMobile
                          }
                        >
                          {authStore.user?.isPrimaryApplicant
                            ? "apply for a new loan"
                            : "Please wait for your Primary Applicant to apply for a loan"}
                        </Text>
                      </Text>
                    )}
                  />
                )}

                {loans.map((loan) => {
                  const businessId = loan.business;
                  let loanBusiness: any;
                  businesses.forEach((business) => {
                    if (business.id === businessId) {
                      loanBusiness = business;
                    }
                  });
                  return (
                    <Button
                      status="basic"
                      style={
                        loan.status.toLowerCase().includes("funded") ||
                        loan.status.toLowerCase().includes("done")
                          ? styles.fundedLoanButton
                          : loan.status.toLowerCase().includes("reject")
                          ? styles.rejectedLoanButton
                          : loan.status.toLowerCase().includes("withdrawn")
                          ? styles.loanButtonDisabled
                          : styles.loanButton
                      }
                      key={loan.id}
                      accessoryRight={ArrowIcon}
                      onPress={() => {
                        navigation.navigate("loan-view", { id: loan.id });
                      }}
                      children={() => (
                        <Text
                          style={
                            width > 576
                              ? styles.loanButtonTextContainer
                              : styles.loanButtonTextContainerMobile
                          }
                        >
                          <Text
                            style={
                              width > 576
                                ? styles.subHeader
                                : styles.loanHeaderMobile
                            }
                          >
                            Loan ID {loan.id}
                          </Text>
                          {"\n"}
                          <Text
                            style={
                              width > 576
                                ? styles.description
                                : styles.descriptionMobile
                            }
                          >
                            Loan amount: $
                            {Number(loanBusiness.contractAmount).toFixed(2)},
                            Term:{" "}
                            {loan.term
                              ? (loan.term | 0) === 1
                                ? "1 month"
                                : `${loan.term} months`
                              : "not yet set"}
                          </Text>
                          {"\n"}
                          <Text
                            style={
                              width > 576
                                ? styles.description
                                : styles.descriptionMobile
                            }
                          >
                            {loan.status.toLowerCase().includes("funded") ||
                            loan.status.toLowerCase().includes("done")
                              ? "This loan is funded"
                              : loan.status.toLowerCase().includes("reject")
                              ? "This loan has been denied"
                              : loan.status.toLowerCase().includes("withdrawn")
                              ? "This loan is withdrawn"
                              : "This loan is still in the process of approval"}
                          </Text>
                        </Text>
                      )}
                    />
                  );
                })}
              </ScrollView>
            )}
          </View>
        </View>
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
});

export default Dashboard;
