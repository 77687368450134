import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Divider,
  Input,
  Spinner,
  Modal,
} from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import HoverBubble from "../../components/HoverBubble";
import { uploadDocument, formatNumberWithCommas } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const ExistingLenders: React.FC<any> = ({
  lendersData,
  setLendersData,
  loading,
}) => {
  const { width, height } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;

  const emptyLenderFormData = {
    existingLenders: "",
    existingLendersLendAmount: "",
    existingLendersCollateral: "",
    existingLendersIsVariable: null,
    existingLendersAmortization: "",
    existingLendersTerm: "",
    existingLendersIsSecured: null,
  };
  const lenderRef = useRef<Input>(null);
  const borrowRef = useRef<Input>(null);
  const collateralRef = useRef<Input>(null);
  const modalCloseRef = useRef<TouchableOpacity>(null);

  const lenderEditRef = useRef<Input>(null);
  const borrowEditRef = useRef<Input>(null);
  const collateralEditRef = useRef<Input>(null);
  const amortizationRef = useRef<Input>(null);
  const termRef = useRef<Input>(null);

  const [lenderData, setLenderData] = useState<any>({ ...emptyLenderFormData });
  const [editLenderData, setEditLenderData] = useState<any>({});
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState<any>("");

  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="tiny" />
  );

  const closeLenderInformationEditModal = () => {
    setIsEditing(false);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    if (isEditModalOpen) {
      setLendersData(
        lendersData.map((lender: any, idx: number) =>
          idx === editLenderData.idx
            ? { ...editLenderData, idx: undefined }
            : lender
        )
      );
    }
  }, [isEditModalOpen, editLenderData]);

  const deleteLoan = () => {
    setLendersData(
      lendersData.filter(
        (lender: any, idx: number) => idx !== editLenderData.idx
      )
    );
    setIsEditing(false);
    setIsEditModalOpen(false);
  };

  const addLoan = () => {
    if (!lenderData.existingLenders || !lenderData.existingLendersLendAmount)
      return;
    setLendersData([...lendersData, lenderData]);
    setLenderData({ ...emptyLenderFormData });
  };

  return (
    <View>
      <Text category="h6" style={styles.error}>
        {errors ? JSON.stringify(errors) : ""}
      </Text>

      <Text style={styles.subHeader}>Existing Lender Information</Text>

      <Divider style={styles.divider} />

      <Text style={styles.subSubHeader}>
        Note: The primary applicant is responsible for adding loans to the
        business.
      </Text>

      <Text style={styles.inputLabel}>
        Who are your existing Lenders? (please add one at a time)
      </Text>

      <Input
        ref={lenderRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Existing Lenders"
        status="basic"
        value={lenderData.existingLenders}
        onChangeText={(text) =>
          setLenderData({ ...lenderData, existingLenders: text })
        }
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            borrowRef.current?.focus();
          }
        }}
        onSubmitEditing={() => borrowRef.current?.focus()}
      />

      <Text style={styles.inputLabel}>
        How much did you borrow from that lender?
      </Text>

      <Input
        ref={borrowRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Amount"
        status="basic"
        value={formatNumberWithCommas(lenderData.existingLendersLendAmount)}
        keyboardType="decimal-pad"
        onChangeText={(text) => {
          const number = text.replace(/,/g, "");
          setLenderData({
            ...lenderData,
            existingLendersLendAmount:
              Number.isNaN(Number(number)) || Number(number) > 10 ** 12
                ? lenderData.existingLendersLendAmount
                : number,
          });
        }}
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            collateralRef.current?.focus();
          }
        }}
        onSubmitEditing={() => collateralRef.current?.focus()}
      />

      <Text style={styles.inputLabel}>
        What is the collateral on that loan?
      </Text>

      <Input
        ref={collateralRef}
        style={styles.input}
        textStyle={styles.inputText}
        placeholder="Collateral/s"
        status="basic"
        value={lenderData.existingLendersCollateral}
        onChangeText={(text) =>
          setLenderData({ ...lenderData, existingLendersCollateral: text })
        }
        onKeyPress={(event: any) => {
          if (event.keyCode === 9) {
            event.preventDefault();
            amortizationRef.current?.focus();
          }
        }}
        onSubmitEditing={() => amortizationRef.current?.focus()}
      />

      <Text style={styles.inputLabel}>
        Please fill in the payment terms on that loan.
      </Text>

      <View style={styles.indent}>
        <Text style={styles.question}>
          Is the interest rate on this loan fixed or variable?
        </Text>
        <View style={styles.questionButtonsContainer}>
          <Button
            style={
              lenderData.existingLendersIsVariable === false
                ? styles.questionButtonSelected
                : styles.questionButton
            }
            appearance={
              lenderData.existingLendersIsVariable === false
                ? "filled"
                : "outline"
            }
            onPress={() =>
              setLenderData({ ...lenderData, existingLendersIsVariable: false })
            }
            children={() => (
              <Text
                style={
                  lenderData.existingLendersIsVariable === false
                    ? styles.buttonTextSelected
                    : styles.buttonText
                }
              >
                Fixed
              </Text>
            )}
          />
          <Button
            style={
              lenderData.existingLendersIsVariable === true
                ? styles.questionButtonSelected
                : styles.questionButton
            }
            appearance={
              lenderData.existingLendersIsVariable === true
                ? "filled"
                : "outline"
            }
            onPress={() =>
              setLenderData({ ...lenderData, existingLendersIsVariable: true })
            }
            children={() => (
              <Text
                style={
                  lenderData.existingLendersIsVariable === true
                    ? styles.buttonTextSelected
                    : styles.buttonText
                }
              >
                Variable
              </Text>
            )}
          />
        </View>
        <Text style={styles.inputLabel}>
          What is the amortization on the loan?
        </Text>

        <Input
          ref={amortizationRef}
          style={styles.input}
          textStyle={styles.inputText}
          placeholder="Amount"
          status="basic"
          value={formatNumberWithCommas(lenderData.existingLendersAmortization)}
          keyboardType="decimal-pad"
          onChangeText={(text) => {
            const number = text.replace(/,/g, "");
            setLenderData({
              ...lenderData,
              existingLendersAmortization:
                Number.isNaN(Number(number)) || Number(number) > 10 ** 12
                  ? lenderData.existingLendersAmortization
                  : number,
            });
          }}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              termRef.current?.focus();
            }
          }}
          onSubmitEditing={() => termRef.current?.focus()}
        />
        <Text style={styles.inputLabel}>
          What is the term on the loan (in months)?
        </Text>

        <Input
          ref={termRef}
          style={styles.input}
          textStyle={styles.inputText}
          placeholder="Amount"
          status="basic"
          value={`${lenderData.existingLendersTerm}`}
          keyboardType="decimal-pad"
          onChangeText={(text) => {
            let number = text;
            setLenderData({
              ...lenderData,
              existingLendersTerm: Number.isNaN(Number(number))
                ? lenderData.existingLendersTerm
                : number,
            });
          }}
        />
        <Text style={styles.question}>Is this debt secured?</Text>
        <View style={styles.questionButtonsContainer}>
          <Button
            style={
              lenderData.existingLendersIsSecured === true
                ? styles.questionButtonSelected
                : styles.questionButton
            }
            appearance={
              lenderData.existingLendersIsSecured === true
                ? "filled"
                : "outline"
            }
            onPress={() =>
              setLenderData({ ...lenderData, existingLendersIsSecured: true })
            }
            children={() => (
              <Text
                style={
                  lenderData.existingLendersIsSecured === true
                    ? styles.buttonTextSelected
                    : styles.buttonText
                }
              >
                Yes
              </Text>
            )}
          />
          <Button
            style={
              lenderData.existingLendersIsSecured === false
                ? styles.questionButtonSelected
                : styles.questionButton
            }
            appearance={
              lenderData.existingLendersIsSecured === false
                ? "filled"
                : "outline"
            }
            onPress={() =>
              setLenderData({ ...lenderData, existingLendersIsSecured: false })
            }
            children={() => (
              <Text
                style={
                  lenderData.existingLendersIsSecured === false
                    ? styles.buttonTextSelected
                    : styles.buttonText
                }
              >
                No
              </Text>
            )}
          />
        </View>
      </View>

      <TouchableOpacity
        style={styles.addLenderButton}
        onPress={() => addLoan()}
        disabled={loading}
      >
        <Text style={styles.addLenderButtonText}>
          <Icon
            width="20px"
            height="20px"
            name="plus-circle-outline"
            fill="#1D84FF"
          />
          <Text style={styles.gradientText}>{"  "}Add Existing Lender</Text>
        </Text>
      </TouchableOpacity>

      {loading ? (
        <LoadingIndicator />
      ) : (
        lendersData.map((lender: any, idx: number) => (
          <View style={styles.purpleGradientBackgroundContainer} key={idx}>
            <Button
              style={styles.existingLenderButton}
              appearance="outline"
              status="success"
              onPress={() => {
                setEditLenderData({ ...lender, idx });
                setIsEditModalOpen(true);
              }}
              children={() => (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <View>
                    <Text style={styles.existingLenderHeader}>
                      {lender.existingLenders}
                    </Text>
                    {"\n"}
                    <Text style={styles.existingLenderText}>
                      {lender.existingLendersLendAmount &&
                        `$${formatNumberWithCommas(
                          lender.existingLendersLendAmount
                        )}`}
                    </Text>
                  </View>
                  <Icon
                    width="25px"
                    height="25px"
                    fill="#1DC462"
                    name="checkmark-circle-2-outline"
                  />
                </View>
              )}
            />
          </View>
        ))
      )}

      <Modal
        visible={isEditModalOpen}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => closeLenderInformationEditModal()}
      >
        <ScrollView
          style={{ ...styles.lenderInformationContainer, height: height * 0.8 }}
        >
          <View style={styles.flexEnd}>
            <TouchableOpacity
              onPress={() => closeLenderInformationEditModal()}
              ref={modalCloseRef}
            >
              <Icon name="close" width="20" height="20" fill="#8F9BB3" />
            </TouchableOpacity>
          </View>
          <View style={styles.modalContentContainer}>
            <View style={styles.modalHeaderContainer}>
              <Text style={styles.modalHeaderText}>Lender Information</Text>
              <View style={styles.flexRow}>
                <TouchableOpacity
                  style={{ padding: 5 }}
                  onPress={() => deleteLoan()}
                >
                  <Icon name="trash-2" width="20" height="20" fill="#8F9BB3" />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ padding: 5 }}
                  onPress={() => setIsEditing(true)}
                >
                  <Icon name="edit" width="20" height="20" fill="#D012B2" />
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.modalContent}>
              <Text style={styles.inputLabel}>Lender Name</Text>
              <Input
                disabled={!isEditing}
                ref={lenderEditRef}
                style={styles.input}
                textStyle={styles.inputText}
                placeholder="Lender Name"
                status="basic"
                value={editLenderData.existingLenders}
                onChangeText={(text) => {
                  setEditLenderData({
                    ...editLenderData,
                    existingLenders: text,
                  });
                }}
                onKeyPress={(event: any) => {
                  if (event.keyCode === 9) {
                    event.preventDefault();
                    borrowEditRef.current?.focus();
                  }
                }}
                onSubmitEditing={() => borrowEditRef.current?.focus()}
              />
              <Text style={styles.inputLabel}>
                How much did you borrow from this existing lender?
              </Text>
              <Input
                disabled={!isEditing}
                ref={borrowEditRef}
                style={styles.input}
                textStyle={styles.inputText}
                placeholder="Amount"
                status="basic"
                keyboardType="decimal-pad"
                value={formatNumberWithCommas(
                  editLenderData.existingLendersLendAmount
                )}
                onChangeText={(text) => {
                  const number = text.replace(/,/g, "");
                  setEditLenderData({
                    ...editLenderData,
                    existingLendersLendAmount:
                      Number.isNaN(Number(number)) || Number(number) > 10 ** 12
                        ? editLenderData.existingLendersLendAmount
                        : number,
                  });
                }}
                onKeyPress={(event: any) => {
                  if (event.keyCode === 9) {
                    event.preventDefault();
                    collateralEditRef.current?.focus();
                  }
                }}
                onSubmitEditing={() => collateralEditRef.current?.focus()}
              />
              <Text style={styles.inputLabel}>
                What is the collateral on this loan?
              </Text>
              <Input
                disabled={!isEditing}
                ref={collateralEditRef}
                style={styles.input}
                textStyle={styles.inputText}
                placeholder="Collateral/s"
                status="basic"
                value={editLenderData.existingLendersCollateral}
                onChangeText={(text) =>
                  setEditLenderData({
                    ...editLenderData,
                    existingLendersCollateral: text,
                  })
                }
              />
              <Text style={styles.question}>
                Is the interest rate on this loan fixed or variable?
              </Text>
              <View style={styles.questionButtonsContainer}>
                <Button
                  style={
                    editLenderData.existingLendersIsVariable === false
                      ? styles.questionButtonSelected
                      : styles.questionButton
                  }
                  appearance={
                    editLenderData.existingLendersIsVariable === false
                      ? "filled"
                      : "outline"
                  }
                  onPress={() =>
                    setEditLenderData({
                      ...editLenderData,
                      existingLendersIsVariable: false,
                    })
                  }
                  children={() => (
                    <Text
                      style={
                        editLenderData.existingLendersIsVariable === false
                          ? styles.buttonTextSelected
                          : styles.buttonText
                      }
                    >
                      Fixed
                    </Text>
                  )}
                />
                <Button
                  style={
                    editLenderData.existingLendersIsVariable === true
                      ? styles.questionButtonSelected
                      : styles.questionButton
                  }
                  appearance={
                    editLenderData.existingLendersIsVariable === true
                      ? "filled"
                      : "outline"
                  }
                  onPress={() =>
                    setEditLenderData({
                      ...editLenderData,
                      existingLendersIsVariable: true,
                    })
                  }
                  children={() => (
                    <Text
                      style={
                        editLenderData.existingLendersIsVariable === true
                          ? styles.buttonTextSelected
                          : styles.buttonText
                      }
                    >
                      Variable
                    </Text>
                  )}
                />
              </View>
              <Text style={styles.inputLabel}>
                What is the amortization on the loan?
              </Text>

              <Input
                ref={amortizationRef}
                style={styles.input}
                textStyle={styles.inputText}
                placeholder="Amount"
                status="basic"
                value={formatNumberWithCommas(
                  editLenderData.existingLendersAmortization
                )}
                keyboardType="decimal-pad"
                onChangeText={(text) => {
                  const number = text.replace(/,/g, "");
                  setEditLenderData({
                    ...editLenderData,
                    existingLendersAmortization:
                      Number.isNaN(Number(number)) || Number(number) > 10 ** 12
                        ? editLenderData.existingLendersAmortization
                        : number,
                  });
                }}
                onKeyPress={(event: any) => {
                  if (event.keyCode === 9) {
                    event.preventDefault();
                    termRef.current?.focus();
                  }
                }}
                onSubmitEditing={() => termRef.current?.focus()}
              />
              <Text style={styles.inputLabel}>
                What is the term on the loan (in months)?
              </Text>

              <Input
                ref={termRef}
                style={styles.input}
                textStyle={styles.inputText}
                placeholder="Amount"
                status="basic"
                value={`${editLenderData.existingLendersTerm}`}
                keyboardType="decimal-pad"
                onChangeText={(text) => {
                  let number = text;
                  setEditLenderData({
                    ...editLenderData,
                    existingLendersTerm: Number.isNaN(Number(number))
                      ? editLenderData.existingLendersTerm
                      : number,
                  });
                }}
              />
              <Text style={styles.question}>Is this debt secured?</Text>
              <View style={styles.questionButtonsContainer}>
                <Button
                  style={
                    editLenderData.existingLendersIsSecured === true
                      ? styles.questionButtonSelected
                      : styles.questionButton
                  }
                  appearance={
                    editLenderData.existingLendersIsSecured === true
                      ? "filled"
                      : "outline"
                  }
                  onPress={() =>
                    setEditLenderData({
                      ...editLenderData,
                      existingLendersIsSecured: true,
                    })
                  }
                  children={() => (
                    <Text
                      style={
                        editLenderData.existingLendersIsSecured === true
                          ? styles.buttonTextSelected
                          : styles.buttonText
                      }
                    >
                      Yes
                    </Text>
                  )}
                />
                <Button
                  style={
                    editLenderData.existingLendersIsSecured === false
                      ? styles.questionButtonSelected
                      : styles.questionButton
                  }
                  appearance={
                    editLenderData.existingLendersIsSecured === false
                      ? "filled"
                      : "outline"
                  }
                  onPress={() =>
                    setEditLenderData({
                      ...editLenderData,
                      existingLendersIsSecured: false,
                    })
                  }
                  children={() => (
                    <Text
                      style={
                        editLenderData.existingLendersIsSecured === false
                          ? styles.buttonTextSelected
                          : styles.buttonText
                      }
                    >
                      No
                    </Text>
                  )}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
    </View>
  );
};
