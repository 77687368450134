import { debounce } from "lodash";
import { onSnapshot, registerRootStore } from "mobx-keystone";
import React from "react";

import AuthStore from "./AuthStore";
import BusinessStore from "./BusinessStore";
import DocumentRequestsStore from "./DocumentRequestsStore";
import LoanStore from "./LoanStore";
import MBOStore from "./MBOStore";
import NotificationsStore from "./NotificationsStore";
import Store from "./Store";

const StoreContext = React.createContext<Store>({} as Store);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

const createStore = () => {
  const authStore = new AuthStore({});
  const loanStore = new LoanStore({});
  const documentRequestsStore = new DocumentRequestsStore({});
  const notificationsStore = new NotificationsStore({});
  const mboStore = new MBOStore({});
  const businessStore = new BusinessStore({});

  const store = new Store({
    authStore,
    loanStore,
    documentRequestsStore,
    notificationsStore,
    mboStore,
    businessStore,
  });

  registerRootStore(store);
  onSnapshot(
    store,
    debounce(() => {
      store.save();
    }, 3000)
  );

  return store;
};

export { Store, StoreProvider, createStore, useStore };
