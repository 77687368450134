import {
  Button,
  Text,
  useStyleSheet,
  Modal,
  Spinner,
  Icon,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Image } from "react-native";
// @ts-ignore
import { WebView as WebViewWeb } from "react-native-web-webview";

import config from "../../config";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

export const BankLinkModal: React.FC<{
  cb: (linkSuccess: boolean, newlyLinkedBank: object) => void;
  linkedBank: any;
  canCancelLink?: boolean;
  cancelLink?: () => void;
}> = ({ cb, linkedBank, canCancelLink = false, cancelLink = () => {} }) => {
  const styles = useStyleSheet(themedStyles);
  const bankImage = require("../../../assets/bank.svg");
  const LoadingIndicator = (props: any) => <Spinner />;
  const IconPlaceholder = (props: any) => <></>;
  const DoneIcon = (props: any) => (
    <Icon {...props} name="checkmark-circle-2-outline" />
  );

  const BankIcon = (props: any) => (
    <Image
      style={{
        width: 24,
        height: 24,
        paddingHorizontal: 10,
        marginHorizontal: 10,
      }}
      source={bankImage}
    />
  );
  const [isBankModalVisible, setIsBankModalVisible] = useState(false);
  const [isPlaidLoading, setIsPlaidLoading] = useState(false);
  const [plaidToken, setPlaidToken] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const { authStore } = useStore();

  useEffect(() => {
    if (isBankModalVisible) setIsPlaidLoading(true);
    else if (!plaidToken) setIsPlaidLoading(false);
  }, [isBankModalVisible]);

  useEffect(() => {
    if (plaidToken)
      api
        .postPlaidToken(plaidToken, authStore?.accessToken || "")
        .then((resp) => {
          console.log(resp);
          setIsPlaidLoading(false);
          setIsBankModalVisible(false);
          cb(true, resp.response.entities);
        })
        .catch((err) => {
          console.error(err);
          setPlaidToken("");
          setIsPlaidLoading(false);
          setIsBankModalVisible(false);
          alert("error exchanging access token with server");
        });
  }, [plaidToken]);

  return (
    <>
      <Button
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={
          isHovering && (linkedBank?.needsReLink || !linkedBank.isLinked)
            ? styles.accountLinkingButtonHover
            : {
                ...styles.accountLinkingButton,
                ...(linkedBank.isLinked ? styles.accountLinkingSuccess : {}),
              }
        }
        appearance={linkedBank.isLinked ? "outline" : "filled"}
        status={
          linkedBank.isLinked && !linkedBank?.needsReLink ? "success" : "basic"
        }
        onPress={() => {
          if (!linkedBank.isLinked || linkedBank?.needsReLink)
            setIsBankModalVisible(true);
        }}
        disabled={isPlaidLoading}
        accessoryLeft={BankIcon}
        accessoryRight={
          isPlaidLoading
            ? LoadingIndicator
            : linkedBank.isLinked
            ? DoneIcon
            : IconPlaceholder
        }
        children={() => (
          <>
            <Text style={styles.accountLinkingButtonContainer}>
              <Text style={styles.accountLinkingButtonHeader}>
                {linkedBank.isLinked ? linkedBank.name : "Link Bank Account"}
              </Text>
              {"\n"}
              <Text style={styles.accountLinkingButtonText}>
                {linkedBank.isLinked
                  ? linkedBank?.needsReLink
                    ? "access token has expired, please re-login to your bank account"
                    : `${linkedBank.accounts.length} Accounts Linked.`
                  : authStore.user?.isInvestor
                  ? "Please connect the account that is funding the loans."
                  : "Your business bank account is required to verify information about your business"}
              </Text>
            </Text>
            {canCancelLink && !isPlaidLoading && (
              <Button
                appearance="ghost"
                status="control"
                onPress={() => cancelLink()}
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: 0,
                  margin: 0,
                }}
              >
                x
              </Button>
            )}
          </>
        )}
      />
      <Modal
        visible={isBankModalVisible}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setIsBankModalVisible(false)}
        style={styles.bankModal}
      >
        <WebViewWeb
          scrollEnabled
          source={{
            uri:
              config.urls.api +
              config.urls.get_plaid_view +
              (linkedBank?.needsReLink
                ? "?relinkInstitutionId=" +
                  linkedBank.id +
                  "&userId=" +
                  linkedBank.userId
                : ""),
          }}
          onMessage={(e: any) => {
            if (e?.nativeEvent?.isTrusted && e?.nativeEvent?.data) {
              try {
                const json = JSON.parse(e.nativeEvent.data || "");
                if (json?.status === "plaid_success") {
                  setPlaidToken(json?.token);
                } else if (json?.status === "plaid_error") {
                  if (json?.error) console.error(json?.error);
                  setIsBankModalVisible(false);
                }
              } catch (e) {
                setIsBankModalVisible(false);
              }
            }
          }}
        />
      </Modal>
    </>
  );
};
