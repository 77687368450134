import {
  model,
  Model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";

@model("oppzo/LoanApplication")
export default class LoanApplication extends Model({
  id: prop<number>(),
  applicantId: prop<number | null>(),
  applicantName: prop<string | null>(),
  applicantPrimaryAccountId: prop<string | null>(),
  business: prop<number>(),
  governmentContractorLevel: prop<string>(),
  contractorLevel: prop<string[] | null>(),
  isPrimeContractor: prop<boolean>(),
  wantsToBePrime: prop<boolean | null>(),
  isTemporary: prop<boolean | null>(),
  prequal1Step: prop<number | null>(),
  prequal2Step: prop<number | null>(),
  status: prop<string | null>(),
  corporationStatus: prop<string | null>(),
  taxIdNumber: prop<string | null>(),
  signed406tForm: prop<File | null>(),
  contractingOfficer: prop<object | null>(),
  contractingOfficerEmail: prop<string | null>(),
  contractingOfficerPhone: prop<string | null>(),
  contractingOfficerFirstName: prop<string | null>(),
  contractingOfficerLastName: prop<string | null>(),
  largestActiveContractsFile: prop<File | null>(),
  linkedIn: prop<string | null>(),
  hasPaidApplicationProcessingFee: prop<boolean | null>(),
  hasConnectedQuickbooks: prop<boolean | null>(),
  needToReconnectQuickbooks: prop<boolean | null>(),
  driversLicense: prop<File | null>(),
  cashFlowProjectionsFile: prop<File | null>(),
  oneYearBudgetProjectionsFile: prop<File | null>(),
  oneYearContractBacklog: prop<File | null>(),
  businessPlan: prop<File | null>(),
  unsigned8821Form: prop<string | null>(),
  signed8821Form: prop<File | null>(),
  companyOrganizationalChart: prop<File | null>(),
  corporateOrAuthorizingResolution: prop<File | null>(),
  operatingAgreement: prop<File | null>(),
  fiscalYearEndProjections: prop<File | null>(),
  financialStatements: prop<File | null>(),
  resumes: prop<File | null>(),
  legalActions: prop<string | null>(),
  incomeStatement: prop<File | null>(),
  balanceSheet: prop<File | null>(),
  byLaws: prop<File | null>(),
  term: prop<string | null>(),
  withdrawalStartDate: prop<Date | null>(),
  isFinalPullEquifaxCommercial: prop<boolean | null>(),
  isPrequal2DoneByPrimaryApplicant: prop<boolean | null>(),
}) {
  @modelAction
  update(data: ModelCreationData<LoanApplication>) {
    Object.assign(this, data);
  }
}
