import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
  },
  adminDashBoard: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginLeft: 50,
    marginRight: 25,
    maxWidth: 1450,
    overflow: "hidden",
  },
  adminDashboardContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
  },
  input: {
    flex: 1,
    fontSize: 22,
    margin: 5,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  filterCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    padding: 5,
  },
  filterSubCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 2,
    padding: 2,
    marginHorizontal: 20,
  },
  LogInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    margin: 2,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 600,
    flex: 1,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#EDF1F7",
  },
  tableHeadersCellText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "bold",
    lineHeight: 24,
    fontSize: 15,
    padding: 1,
    margin: 1,
    alignSelf: "flex-start",
    justifySelf: "center",
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    borderBottom: "1px solid #EDF1F7",
  },
  tableCell: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 0,
  },
  tableCellText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 20,
    fontSize: 13,
    padding: 1,
    margin: 1,
    alignSelf: "flex-start",
    justifySelf: "center",
  },
  tableContainer: {},
  Approve: {
    borderRadius: 5,
    backgroundColor: "#1D84FF29",
    color: "color-info-600",
    border: "1px solid #1D84FF",
    fontFamily: "Montserrat_700Bold",
    textTransform: "uppercase",
  },
  Review: {
    borderRadius: 5,
    backgroundColor: "#8F9BB314",
    color: "color-basic-600",
    border: "1px solid #8F9BB3",
    fontFamily: "Montserrat_700Bold",
    textTransform: "uppercase",
  },
  Decline: {
    borderRadius: 5,
    backgroundColor: "#9B204914",
    color: "color-danger-600",
    border: "1px solid #9B2049",
    fontFamily: "Montserrat_700Bold",
    textTransform: "uppercase",
  },
  WITHDRAWN: {
    borderRadius: 5,
    backgroundColor: "#8F9BB314",
    color: "color-basic-600",
    border: "1px solid #8F9BB3",
    fontFamily: "Montserrat_700Bold",
  },
  "PRE-QUAL 1": {
    borderRadius: 5,
    backgroundColor: "#1D84FF29",
    color: "color-info-600",
    border: "1px solid #1D84FF",
    fontFamily: "Montserrat_700Bold",
  },
  "PRE-QUAL 2": {
    borderRadius: 5,
    backgroundColor: "rgba(255, 170, 0, 0.1)",
    color: "color-warning-600",
    border: "1px solid #8F9BB3",
    fontFamily: "Montserrat_700Bold",
  },
  "FULL APPLICATION": {
    textAlign: "center",
    borderRadius: 5,
    backgroundColor: "#d082e533",
    color: "#d03fe8",
    border: "1px solid #d03fe8",
    fontFamily: "Montserrat_700Bold",
  },
  selectItem: {
    backgroundColor: "white",
    color: "#222B45",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    alignText: "left",
  },
  modalHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  previewModal: {
    padding: 20,
    borderRadius: 5,
    width: 700,
    background: "white",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  cell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  previewModalScroll: {
    maxHeight: 600,
    flex: 1,
  },
  viewFullApplicationButton: {
    background: "#1D84FF29",
    color: "color-info-600",
    border: "3px solid #1D84FF29",
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 30,
    marginHorizontal: 15,
    maxWidth: 230,
  },
  viewFullApplicationButtonText: {
    color: "color-info-600",
    textTransform: "uppercase",
    lineHeight: 16,
    fontSize: 13,
    fontFamily: "Montserrat_700Bold",
  },
  welcomeMessageText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 24,
    fontSize: 13,
    textAlign: "left",
  },
  covenantsSelectText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
  },
  covenantOptionContainer: {
    padding: 5,
    flexDirection: "row",
    maxWidth: 600,
  },
  covenantCheckbox: {},
  covenantCheckboxLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    lineHeight: 24,
    textAlign: "left",
    padding: 5,
    marginHorizontal: 15,
  },
  covenantInput: {
    flex: 1,
  },
  flexRowItemsCenter: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  covenantSubmit: {
    maxWidth: 150,
    marginHorizontal: 10,
    marginVertical: 30,
  },
  covenantSectionLabel: {
    flex: 1,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 26,
    textTransform: "uppercase",
    color: "color-basic-600",
  },
  select: {
    fontSize: 22,
    background: "white",
    flex: 1,
    marginRight: 20,
  },
  previewLoanButton: {
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 13,
    padding: 1,
    margin: 1,
    justifySelf: "center",
  },
});
