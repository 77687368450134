import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Button,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Select,
  SelectItem,
  IndexPath,
  Input,
} from "@ui-kitten/components";
import React, { useState, useCallback, useEffect } from "react";
import { View, ScrollView, Dimensions, TouchableOpacity } from "react-native";

import { useDebounce } from "ahooks";
import Header from "../../components/Header";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import config from "../../config";
import { useInterval } from "../../hooks";
import { getLoansListForServicingMonitoringDashboard } from "../../services/api";
import { useStore } from "../../stores";
import { ThemeContext } from "./../../../theme-context";
import { FilterOptionsModal } from "./FilterOptionsModal";
import { themedStyles } from "./themedStyles";
type Props = object;

export const SORT = {
  NEWEST_MODIFIED: "UPDATED_DEC",
  OLDEST_MODIFIED: "UPDATED_INC",
  APPROVED_INC: "APPROVED_INC",
  APPROVED_DEC: "APPROVED_DEC",
  LOAN_ID_INC: "OLD",
  LOAN_ID_DEC: "NEW",
};

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const displayPerPageOptions = [10, 25, 50, 100];
const ServicingMonitoringDashboard: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { width, height } = Dimensions.get("window");
  const maxWidth = styles.servicingDashboard.maxWidth
    ? Number(styles.servicingDashboard.maxWidth)
    : 1080;

  const LeftArrowIcon = (props: any) => (
    <Icon {...props} fill="rgba(37, 56, 88, 1)" name="arrow-ios-back-outline" />
  );
  const OptionsIcon = (props: any) => (
    <Icon {...props} name="options-2-outline" />
  );
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;
  const RightArrowIcon = (props: any) => (
    <Icon
      {...props}
      fill="rgba(37, 56, 88, 1)"
      name="arrow-ios-forward-outline"
    />
  );

  const [displayPerPage, setDisplayPerPage] = useState(10);
  const [selectedRowsPerPageIndex, setSelectedRowsPerPageIndex]: any = useState(
    new IndexPath(0)
  );

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [availablePages, setAvailablePages] = useState(1);
  const [loansCount, setLoansCount] = useState(0);
  const [searchString, setSearchString] = useState("");
  const debouncedSearch = useDebounce(searchString, { wait: 500 });
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [sortingMode, setSortingMode] = useState(SORT.APPROVED_DEC);
  const tableWidth = width <= maxWidth ? width - 300 : maxWidth - 300;
  const totalColumns = 9;
  const [forceUpdateDashboardLoans, setForceUpdateDashboardLoans] = useState(0);
  const fieldsDesc: { [key: string]: any } = {
    id: {
      width: tableWidth * (0.75 / totalColumns),
      display: "Loan ID",
    },
    applicantName: {
      width: tableWidth * (1.5 / totalColumns),
      display: "Borrower",
    },
    applicantBusinessName: {
      width: tableWidth * (1.75 / totalColumns),
      display: "Business Name",
    },
    finalLoanAmount: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Loan Amount",
      format: "$",
    },
    quickbooksNeedsReconnecting: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Quickbooks account",
      align: "center",
    },
    plaidNeedsReconnecting: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Plaid Account",
      align: "center",
    },
    unusualBankActivity: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Bank Activity",
      align: "center",
    },
    covenantsNotInCompliance: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Covenant Ratios",
      align: "center",
    },
    actions: {
      width: tableWidth * (0.75 / totalColumns),
      display: "Actions",
      align: "center",
    },
  };

  const [loanApplications, setLoanApplications]: any = useState([]);

  const themeContext = React.useContext(ThemeContext);

  useEffect(() => {
    themeContext.resetTheme();
  }, []);

  useInterval(() => {
    if (!loading) {
      setForceUpdateDashboardLoans(forceUpdateDashboardLoans + 1);
    }
  }, 15 * 60 * 1000);

  useFocusEffect(
    useCallback(() => {
      setDisplayPerPage(displayPerPageOptions[selectedRowsPerPageIndex.row]);
    }, [useIsFocused(), selectedRowsPerPageIndex])
  );

  useEffect(() => {
    setLoading(true);
    (async () => {
      const resp = await getLoansListForServicingMonitoringDashboard(
        authStore?.accessToken || "",
        currentPage,
        displayPerPage,
        { ...filterOptions, search: debouncedSearch, sort: sortingMode }
      );
      const response = resp.response.entities;
      setLoanApplications(response.loans);
      setAvailablePages(response.pages || 1);
      setLoansCount(response.count || 0);
      setLoading(false);
    })();
  }, [
    forceUpdateDashboardLoans,
    currentPage,
    displayPerPage,
    filterOptions,
    debouncedSearch,
    sortingMode,
  ]);

  const { authStore } = useStore();

  const formatDisplay = (value: string, format: any) => {
    if (!value) return "";
    if (format === "$")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(value));
    if (format === "%") return value + "%";

    return value;
  };

  const getPagesButtonsNumbers = useCallback(() => {
    const buttons = [];
    for (
      let i = Math.max(0, currentPage - 2);
      i <= Math.min(availablePages, currentPage + 3);
      i++
    )
      buttons.push(i);
    return buttons;
  }, [availablePages, currentPage]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header theme="light" />
        <View style={styles.servicingDashboardContainer}>
          <View style={styles.servicingDashboard}>
            <Text style={styles.header}>Monitoring Dashboard</Text>
            <Text style={styles.welcomeMessageText} appearance="hint">
              View integration connections, bank activity, and covenant ratios.
            </Text>
            <View style={styles.servicingDashboardTableContainer}>
              <View style={styles.requestedDocumentsHeader}>
                <FilterOptionsModal
                  isOptionsModalOpen={isOptionsModalOpen}
                  setIsOptionsModalOpen={setIsOptionsModalOpen}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                  sortingMode={sortingMode}
                  setSortingMode={setSortingMode}
                />
                <View
                  style={{
                    ...styles.flexRow,
                    flex: 1,
                    justifyContent: "flex-end",
                  }}
                >
                  <Input
                    style={{ ...styles.input, maxWidth: 300 }}
                    placeholder="Search"
                    value={searchString}
                    onChangeText={(text: string) => setSearchString(text)}
                    accessoryRight={SearchIcon}
                  />
                  <Button
                    style={styles.optionsButton}
                    status="basic"
                    accessoryRight={OptionsIcon}
                    onPress={() => setIsOptionsModalOpen(true)}
                  />
                </View>
              </View>
              <View
                style={{ height: height - 250, ...(loading && styles.center) }}
              >
                <ScrollView
                  style={{
                    ...styles.scroll,
                    maxHeight: height - 250,
                  }}
                  keyboardShouldPersistTaps="handled"
                >
                  {loading || !loanApplications ? (
                    <LoadingIndicator />
                  ) : (
                    <View style={styles.tableContainer}>
                      <View style={styles.tableHeaders}>
                        {Object.values(fieldsDesc).map((field) => {
                          return (
                            <View
                              key={"header-" + field.display}
                              style={{
                                ...styles.tableCell,
                                ...{ flex: field.width },
                                ...(field.align
                                  ? { justifyContent: field.align }
                                  : {}),
                              }}
                            >
                              <Text style={{ ...styles.tableHeadersCellText }}>
                                {field.display}
                              </Text>
                            </View>
                          );
                        })}
                      </View>
                      {loanApplications.map((doc: any, index: any) => (
                        <View
                          key={doc.id + "-row"}
                          style={{
                            ...styles.tableRow,
                            backgroundColor:
                              index % 2 === 0
                                ? "white"
                                : "rgba(143, 155, 179, 0.08)",
                          }}
                        >
                          {Object.keys(fieldsDesc).map((fieldName: string) => (
                            <View
                              key={
                                doc.id +
                                "-row-data-" +
                                fieldsDesc[fieldName].display
                              }
                              style={{
                                ...{
                                  flex: fieldsDesc[fieldName].width,
                                  flexDirection: "row",
                                },
                                ...styles.tableCell,
                                ...(fieldsDesc[fieldName].align
                                  ? {
                                      justifyContent:
                                        fieldsDesc[fieldName].align,
                                    }
                                  : {}),
                              }}
                            >
                              {fieldName === "actions" ? (
                                <TouchableOpacity
                                  onPress={() => {
                                    const isTestEnv =
                                      config.urls.api.includes("test") ||
                                      config.urls.api.includes("dev") ||
                                      config.urls.api.includes("local") ||
                                      config.urls.api.includes("127.0.0.1") ||
                                      config.urls.api.includes("demo");
                                    const peachBaseUrl = isTestEnv
                                      ? "https://servicing.test.getoppzo.com"
                                      : "https://servicing.getoppzo.com";
                                    const url = `${peachBaseUrl}/borrowers/${doc["peachBorrowerId"]}/loans/${doc["peachLoanId"]}/summary`;
                                    window.open(url, "_blank");
                                  }}
                                  style={{
                                    paddingLeft: 15,
                                  }}
                                >
                                  <Icon
                                    name="external-link-outline"
                                    width={16}
                                    height={16}
                                  />
                                </TouchableOpacity>
                              ) : fieldName ===
                                "quickbooksNeedsReconnecting" ? (
                                <OnMouseClickTooltip
                                  iconName={
                                    doc[fieldName]
                                      ? "close-circle"
                                      : "checkmark-circle-2"
                                  }
                                  iconColor={
                                    doc[fieldName] ? "#9B2049" : "#1566DB"
                                  }
                                  text={`Last checked on ${
                                    doc["quickbooksLastCheckDate"]
                                  }\n${
                                    doc[fieldName]
                                      ? "Last email reminder sent on " +
                                        doc[
                                          "quickbooksLastEmailReminderSentDate"
                                        ]
                                      : ""
                                  }`}
                                />
                              ) : fieldName === "plaidNeedsReconnecting" ? (
                                <OnMouseClickTooltip
                                  iconName={
                                    doc[fieldName]
                                      ? "close-circle"
                                      : "checkmark-circle-2"
                                  }
                                  iconColor={
                                    doc[fieldName] ? "#9B2049" : "#1566DB"
                                  }
                                  text={`Last checked on ${
                                    doc["plaidLastCheckDate"]
                                  }\n${
                                    doc[fieldName]
                                      ? "Last automated email reminder sent on " +
                                        doc["plaidLastEmailReminderSentDate"]
                                      : ""
                                  }`}
                                />
                              ) : fieldName === "unusualBankActivity" ? (
                                <View
                                  style={{
                                    ...styles.flexRow,
                                    paddingRight: 10,
                                  }}
                                >
                                  <OnMouseClickTooltip
                                    iconName={
                                      doc[fieldName]
                                        ? "alert-triangle-outline"
                                        : "checkmark-circle-outline"
                                    }
                                    iconColor={
                                      doc[fieldName] ? "#9B2049" : "#1566DB"
                                    }
                                    text={
                                      doc[fieldName]
                                        ? `The's applicant's bank account shows one or more of the following:\n  • negative balance \n  • large deposits in \n  • overdrafts \n  • balance is less than 1.2x payment\n When suspicious activity is triggered, an email is sent to loan_operations@getoppzo.com`
                                        : "No unusual activity detected. \nWhen suspicious activity is triggered, an email is sent to loan_operations@getoppzo.com"
                                    }
                                  />
                                  <Text style={styles.tableCellTextBold}>
                                    {doc[fieldName] ? "Unusual" : "Normal"}
                                  </Text>
                                </View>
                              ) : fieldName === "covenantsNotInCompliance" ? (
                                <View
                                  style={{
                                    ...styles.flexRow,
                                  }}
                                >
                                  <OnMouseClickTooltip
                                    iconName={
                                      doc[fieldName]
                                        ? "alert-triangle-outline"
                                        : "checkmark-circle-outline"
                                    }
                                    iconColor={
                                      doc[fieldName] ? "#9B2049" : "#1566DB"
                                    }
                                    text={doc["covenantsComplianceSummary"]}
                                  />
                                  <Text style={styles.tableCellTextBold}>
                                    {doc[fieldName]
                                      ? "Non-compliant"
                                      : "Compliant"}
                                  </Text>
                                </View>
                              ) : (
                                <Text
                                  numberOfLines={3}
                                  style={{
                                    ...(fieldsDesc[fieldName].align
                                      ? {
                                          textAlign:
                                            fieldsDesc[fieldName].align,
                                        }
                                      : {}),
                                    ...styles.tableCellText,
                                    ...(fieldName === "finalDecisionStatus" ||
                                    fieldName === "status"
                                      ? {
                                          // @ts-ignore
                                          ...styles[doc[fieldName]],
                                          paddingHorizontal: 5,
                                          textAlign: "center",
                                        }
                                      : {}),
                                    flex: 1,
                                  }}
                                >
                                  {formatDisplay(
                                    doc[fieldName],
                                    fieldsDesc[fieldName].format || ""
                                  )}
                                </Text>
                              )}
                            </View>
                          ))}
                        </View>
                      ))}
                    </View>
                  )}
                </ScrollView>
              </View>

              <View
                style={{
                  ...styles.flexRow,
                  justifyContent: "flex-end",
                  paddingHorizontal: 30,
                  margin: 0,
                  gap: 10,
                }}
              >
                <Text style={styles.subheader}>
                  Rows per page:{" "}
                  <Select
                    size="small"
                    selectedIndex={selectedRowsPerPageIndex}
                    value={displayPerPageOptions[selectedRowsPerPageIndex.row]}
                    onSelect={(index) => setSelectedRowsPerPageIndex(index)}
                  >
                    {displayPerPageOptions.map((option) => (
                      <SelectItem key={option} title={option} />
                    ))}
                  </Select>
                </Text>
                <Text style={styles.subheader} appearance="hint">
                  {`Showing ${(currentPage - 1) * displayPerPage + 1} to ${
                    currentPage < availablePages
                      ? currentPage * displayPerPage
                      : loansCount
                  } of ${loansCount ?? "?"} entries`}
                </Text>
                <View style={styles.flexRow}>
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={LeftArrowIcon}
                    onPress={() => setCurrentPage(Math.max(1, currentPage - 1))}
                  />
                  {getPagesButtonsNumbers().map((idx) => (
                    <Button
                      status="basic"
                      appearance={idx === currentPage ? "filled" : "ghost"}
                      onPress={() => setCurrentPage(idx)}
                      key={"page-btn-" + idx}
                    >
                      {idx}
                    </Button>
                  ))}
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={RightArrowIcon}
                    onPress={() =>
                      setCurrentPage(Math.min(availablePages, currentPage + 1))
                    }
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default ServicingMonitoringDashboard;
