import {
  model,
  Model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";

@model("oppzo/Business")
export default class Business extends Model({
  id: prop<number>(),
  businessOwner: prop<number>(),
  businessType: prop<string>(""),
  businessName: prop<string>(""),
  businessAddress: prop<string>(""),
  businessAddStreetLine1: prop<string>(""),
  businessAddStreetLine2: prop<string>(""),
  businessAddCity: prop<string>(""),
  businessAddState: prop<string>(""),
  stateOfIncorporation: prop<string>(""),
  jurisdictionCounty: prop<string>(""),
  businessAddZipCode: prop<string>(""),
  businessIsAuthorize: prop<boolean | null>(),
  contractorLevel: prop<string[]>(),
  cageCode: prop<string>(),
  dunsNumber: prop<number>(),
  contractNumber: prop<string>(),
  contractPricingType: prop<string>(),
  primaryNaicsCode: prop<string>(),
  primaryNaicsCodeDefinition: prop<string>(),
  contractAmount: prop<number>(),
  unbilledContractAmount: prop<number | null>(),
  annualRevenue: prop<number>(),
  baseContract: prop<File | null>(),
  businessStartDate: prop<string | null>(),
  hasExistingLenders: prop<boolean>(),
  existingLendersSelectedOptions: prop<string[] | null>(),
  einNumber: prop<string | null>(),
  ueiNumber: prop<string | null>(),
  tradeNames: prop<string[] | null>(),
}) {
  @modelAction
  update(data: ModelCreationData<Business>) {
    Object.assign(this, data);
  }
}
