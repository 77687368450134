import add from "date-fns/add";
import isFuture from "date-fns/isFuture";
import parseISO from "date-fns/parseISO";
import * as SecureStore from "expo-secure-store";
import { observable } from "mobx";
import {
  getRoot,
  model,
  Model,
  modelAction,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";
import { Platform } from "react-native";

import config from "../config";
import Business from "../models/Business";
import User from "../models/User";
import * as api from "../services/api";
import Store from "./Store";

type Extra = { [key: string]: any } | null;
type Response = { errors: any; ok: boolean; extra: Extra };

const getError = (error: Error): Response => {
  try {
    return { errors: JSON.parse(error.message), ok: false, extra: null };
  } catch {
    return { errors: { detail: error.message }, ok: false, extra: null };
  }
};

const _success = { errors: { detail: null }, ok: true, extra: null };
const getSuccess = (extra?: Extra): Response =>
  extra ? { ..._success, extra } : _success;

@model("oppzo/AuthStore")
export default class AuthStore extends Model({
  accessToken: prop<string | null>(null), // do not use accessToken for making requests. use getToken instead
  accessTokenExpiration: prop<string | null>(null),
  refreshToken: prop<string | null>(null),
  user: prop<User | null>(null),
  lastReviewedLoanId: prop<number>(1),
}) {
  @observable
  loading = false;

  @modelAction
  setUser = (user: User | null) => {
    this.user = user;
  };

  @modelFlow
  load = _async(function* (this: AuthStore) {
    this.loading = true;
    if (Platform.OS !== "web") {
      this.accessToken = yield* _await(
        SecureStore.getItemAsync(config.accessTokenKey)
      );
      this.refreshToken = yield* _await(
        SecureStore.getItemAsync(config.refreshTokenKey)
      );
    }
    yield* _await(this.tokenRefresh());
    this.loading = false;
  });

  // use this function to obtain a token for making authenticated requests
  @modelFlow
  getToken = _async(function* (this: AuthStore) {
    if (
      !this.accessToken ||
      !this.accessTokenExpiration ||
      !isFuture(add(parseISO(this.accessTokenExpiration), { minutes: -1 }))
    ) {
      // access token is missing, expired, or expiring soon. get a new one
      yield* _await(this.tokenRefresh());
    }
    return this.accessToken;
  });

  @modelFlow
  removeToken = _async(function* (this: AuthStore) {
    this.accessToken = null;
    this.accessTokenExpiration = null;
    this.refreshToken = null;
    if (Platform.OS !== "web") {
      yield* _await(SecureStore.deleteItemAsync(config.accessTokenKey));
      yield* _await(SecureStore.deleteItemAsync(config.refreshTokenKey));
    }
  });

  @modelFlow
  storeToken = _async(function* (
    this: AuthStore,
    accessToken: string | null,
    accessTokenExpiration: string | null,
    refreshToken: string | null
  ) {
    this.accessToken = accessToken;
    this.accessTokenExpiration = accessTokenExpiration;
    this.refreshToken = refreshToken;
    // on web, tokens are automatically stored securely as cookies
    if (Platform.OS !== "web") {
      if (accessToken) {
        yield* _await(
          SecureStore.setItemAsync(config.accessTokenKey, accessToken)
        );
      }
      if (refreshToken) {
        yield* _await(
          SecureStore.setItemAsync(config.refreshTokenKey, refreshToken)
        );
      }
    }
    console.log("[DEBUG] store tokens", {
      accessToken,
      accessTokenExpiration,
      refreshToken,
    });
  });

  @modelFlow
  _logIn = _async(function* (
    this: AuthStore,
    accessToken: string,
    accessTokenExpiration: string | null,
    refreshToken: string,
    user: any
  ) {
    yield* _await(
      this.storeToken(accessToken, accessTokenExpiration, refreshToken)
    );
    this.setUser(new User(user));

    // Fetch initial data asynchronously. So don't wait here.
    getRoot<Store>(this).fetchInitialData();
  });

  @modelFlow
  logIn = _async(function* (
    this: AuthStore,
    data: { email: string; password: string }
  ) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.logIn(data));
      yield* _await(
        this._logIn(
          entities.accessToken,
          entities.accessTokenExpiration,
          entities.refreshToken,
          entities.user
        )
      );
      console.log(entities);
    } catch (error: any) {
      console.warn("[DEBUG] sign in error", error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  logOut = _async(function* (this: AuthStore) {
    if (this.accessToken) {
      try {
        yield* _await(api.logOut(this.accessToken));
      } catch (error) {
        console.warn("[DEBUG] log out error", error);
      }
    }
    yield* _await(this.removeToken());
    const rootStore = getRoot<Store>(this);
    yield* _await(rootStore.reset());
  });

  @modelFlow
  checkBusiness = _async(function* (
    this: AuthStore,
    data: {
      email: string;
      businessRole: string;
      businessName: string;
      businessAddress: string;
      businessAddStreetLine1: string;
      businessAddStreetLine2: string;
      businessAddCity: string;
      businessAddState: string;
      businessAddZipCode: string;
      stateOfIncorporation: string;
      jurisdictionCounty: string;
      businessType: string;
      tradeNames: [];
      businessIsAuthorize: boolean;
      isPrimaryApplicant: boolean;
      otherBusinessOwners: boolean;
      ownTwentyPercent: boolean;
      businessPartners: string[];
      contractorLevel: string[];
    }
  ) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.checkBusiness(data));
    } catch (error: any) {
      console.warn("[DEBUG] sign up error", error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  signUp = _async(function* (
    this: AuthStore,
    data: {
      email: string;
      password1: string;
      password2: string;
      birthday: string;
      firstName: string;
      lastName: string;
      middleName: string;
      suffix: string;
      businessRole: string;
      businessName: string;
      businessAddress: string;
      businessAddStreetLine1: string;
      businessAddStreetLine2: string;
      businessAddCity: string;
      businessAddState: string;
      businessAddZipCode: string;
      stateOfIncorporation: string;
      jurisdictionCounty: string;
      businessType: string;
      phoneNumber: string;
      tradeNames: string[];
      isPrimaryApplicant: boolean;
      otherBusinessOwners: boolean;
      ownTwentyPercent: boolean;
      businessIsAuthorize: boolean;
      contractorLevel: string[];
    }
  ) {
    let businessId: number;
    try {
      const {
        response: { entities },
      } = yield* _await(api.signUp(data));
    } catch (error: any) {
      console.warn("[DEBUG] sign up error", error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  refetchUser = _async(function* (this: AuthStore, id: number) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.fetchUser(this.accessToken || "", this.user?.id));
      console.log(entities);
      this.setUser(new User(entities));
    } catch (error: any) {
      console.warn("[DEBUG] update user error", error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  updateUser = _async(function* (this: AuthStore, data: Partial<User>) {
    try {
      const {
        response: { entities },
      } = yield* _await(
        api.updateUser(this.accessToken || "", this.user?.id, data)
      );
      console.log(entities);
      this.setUser(new User(entities));
    } catch (error: any) {
      console.warn("[DEBUG] update user error", error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  updateBasicAndBusinessInformation = _async(function* (
    this: AuthStore,
    data: {
      email: string;
      firstName: string;
      lastName: string;
      ssnNumber: string;
      businessName: string;
      businessAddress: string;
      businessAddStreetLine1: string;
      businessAddStreetLine2: string;
      businessAddCity: string;
      businessAddState: string;
      businessAddZipCode: string;
      businessRole: string;
      businessType: string;
      phoneNumber: string;
      bypassEditLimit: boolean;
    }
  ) {
    let businessData = null;
    try {
      const {
        response: { entities },
      } = yield* _await(
        api.updateUserBasicAndBusinessInformation(this.accessToken || "", data)
      );
      console.log(entities);
      this.setUser(new User(entities["user"]));
      businessData = entities["business"];
    } catch (error: any) {
      console.warn("[DEBUG] update user basic info error", error);
      return getError(error);
    }
    return getSuccess(businessData);
  });

  @modelFlow
  updatePassword = _async(function* (
    this: AuthStore,
    data: {
      oldPassword: string;
      newPassword1: string;
      newPassword2: string;
    }
  ) {
    try {
      yield* _await(api.updatePassword(this.accessToken || "", data));
    } catch (error: any) {
      console.warn("[DEBUG] update password error", error);
      return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  tokenRefresh = _async(function* (this: AuthStore) {
    try {
      const {
        response: { entities },
      } = yield* _await(api.tokenRefresh({ refreshToken: this.refreshToken }));
      yield* _await(
        this.storeToken(
          entities.access,
          entities.accessTokenExpiration,
          this.refreshToken
        )
      );
    } catch (error) {
      // log out for now.
      return yield* _await(this.logOut());
      // try {
      //   const error_obj = JSON.parse(error.message);
      //   if (error_obj.code === "token_not_valid") {
      //     console.log("[DEBUG] refresh token expired, logging out");
      //     return yield* _await(this.logOut());
      //   }
      // } catch (json_error) {
      //   console.log("[DEBUG] token refresh non-json response", json_error);
      // }
      // console.warn("[DEBUG] token refresh error", error);
      // return getError(error);
    }
    return getSuccess();
  });

  @modelFlow
  reset = _async(function* (this: AuthStore) {
    yield* _await(this.removeToken());
    this.setUser(null);
  });

  @modelFlow
  setLastReviewedLoan = _async(function* (this: AuthStore, id: number) {
    this.lastReviewedLoanId = id;
  });

  @modelFlow
  setPrimaryAccountId = _async(function* (this: AuthStore, id: string) {
    //@ts-ignore
    this.user?.update({ primaryAccountId: id });
  });
}
