import {
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from "@react-navigation/core";
import {
  Button,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useCallback, useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";

import Header from "../../components/Header";
import { getLoanTapeData } from "../../services/api";
import { useStore } from "../../stores";
import { ThemeContext } from "./../../../theme-context";
import { CovenantModal } from "./CovenantModal";
import { themedStyles } from "./themedStyles";
type Props = object;

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const testDataBorrower = ["Business A", "Company B", "Company C"];
const testDataRisk = ["1", "2", "3", "4a", "4b"];
const testDataInterest = [10, 11, 12, 12, 12];
const testDataLoanAmount = [2500000, 5000000, 7500000, 10000000];
const testDataMaturity = [12, 12, 12, 15, 18];
const testDataMonthlyPayment = [2500000, 5000000, 7500000, 10000000];
const testDataLocation = ["FL", "MD", "VA"];
const testDataDesignation = ["Woman Owned", "Minority Owned", "Veteran Owned"];
const testDataLeverage = [0.5, 1.0, 1.5, 2.0, 2.25, 2.5, 2.75, 3.0];
const testDataDSCR = [2.0, 1.5, 1.25, 1.1, 1.0, 0.9];
const testDataTNW = [1.5, 1.1, 1.1, 1.1, 0.9, 0.7];
const testDataCurrentRatio = [3.0, 2.0, 1.5, 1.25, 1.0, 0.85];

const displayPerPageOptions = [10, 25, 50, 100];

const InvestorLoanTape: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { width, height } = Dimensions.get("window");
  const maxWidth = styles.adminDashBoard.maxWidth
    ? Number(styles.adminDashBoard.maxWidth)
    : 1080;

  const navigation = useNavigation();
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;
  const LeftArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-back-outline" />
  );
  const RightArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-forward-outline" />
  );
  const DownloadIcon = (props: any) => (
    <Icon {...props} name="download-outline" />
  );
  const InfoIcon = (props: any) => <Icon {...props} name="info-outline" />;

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [agenciesOrDistributionsIndex, setAgenciesOrDistributionsIndex] =
    useState(new IndexPath(0));
  const [displayPerPage, setDisplayPerPage] = useState(10);
  const [exportLoanTape, setExportLoanTape] = useState(false);
  const [generatePlaceholderData, setGeneratePlaceholderData] = useState(false);
  const [selectedQuarter, setSelectedQuarter]: any = useState(new IndexPath(0));
  const [quarters, setQuarters]: any[] = useState([]);
  const [searchString, setSearchString] = useState(
    new URLSearchParams(window.location.search).get("userName") || ""
  );
  const [loading, setLoading] = useState(false);
  const [isCovenantModalOpen, setIsCovenantModalOpen] = useState(false);
  const [covenants, setCovenants] = useState({
    leverage: 1.0,
    dscr: 1.0,
    tnw: 1.0,
    currentRatio: 1.0,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const tableWidth = width <= maxWidth ? width - 300 : maxWidth - 300;
  const totalColumns = 13;
  const fieldsDesc: { [key: string]: any } = {
    loanId: {
      width: tableWidth * (0.75 / totalColumns),
      display: "ID",
    },
    borrower: {
      width: tableWidth * (1.5 / totalColumns),
      display: "Borrower",
    },
    loanAmount: {
      width: tableWidth * (1.4 / totalColumns),
      display: "Loan Amount",
      format: "$",
      align: "center",
    },
    currentBalance: {
      width: tableWidth * (1.4 / totalColumns),
      display: "Current Balance",
      format: "$",
      align: "center",
    },
    monthlyPayment: {
      width: tableWidth * (1.4 / totalColumns),
      display: "Monthly Payment",
      format: "$",
    },
    maturity: {
      width: tableWidth * (0.6 / totalColumns),
      display: "Term",
    },
    maturityDate: {
      width: tableWidth * (1.1 / totalColumns),
      display: "Maturity Date",
    },
    riskRating: {
      width: tableWidth * (0.65 / totalColumns),
      display: "Risk Rating",
    },
    interest: {
      width: tableWidth * (0.7 / totalColumns),
      display: "Interest",
      format: "%",
    },
    location: {
      width: tableWidth * (0.8 / totalColumns),
      display: "Location",
    },
    agencyOrDesignation: {
      width: tableWidth * (1.75 / totalColumns),
      agencyOrDesignationSelect: true,
      display: "_",
    },
    covenants: {
      width: tableWidth * (1 / totalColumns),
      display: "Covenants",
    },
  };

  const [loanApplications, setLoanApplications]: any = useState([]);
  const [loanApplicationsDisplayList, setLoanApplicationsDisplayList]: any =
    useState([]);

  const themeContext = React.useContext(ThemeContext);

  useEffect(() => {
    themeContext.resetTheme();
  }, []);

  useFocusEffect(
    useCallback(() => {
      setDisplayPerPage(displayPerPageOptions[selectedIndex.row]);
    }, [useIsFocused(), selectedIndex])
  );

  useEffect(() => {
    setLoading(true);
    (async () => {
      const resp = await getLoanTapeData(authStore?.accessToken || "");
      setLoanApplications(resp.response.entities);
      const tmp = resp.response.entities.map((obj: any) => obj.qtr);
      const quarters = tmp
        .filter((obj: any, index: number) => tmp.indexOf(obj) === index)
        .sort();
      setQuarters(quarters);
    })();
    setCurrentPage(0);
    setSelectedQuarter(new IndexPath(0));
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    const tmp = loanApplications.filter(
      (obj: any) => obj.qtr === quarters[selectedQuarter.row]
    );
    const displayList = [];
    let tmpgrp: any = [];
    for (let i = 0; i < tmp.length; i++) {
      if (i % 10 === 0 && i != 0) {
        displayList.push(tmpgrp);
        tmpgrp = [];
      }
      tmpgrp.push(tmp[i]);
    }
    if (tmpgrp.length) {
      displayList.push(tmpgrp);
    }
    setLoanApplicationsDisplayList(displayList);
    setLoading(false);
  }, [selectedQuarter, loanApplications, quarters]);

  useEffect(() => {
    if (generatePlaceholderData) {
      const data = [];
      for (let i = 0; i < 30 + Math.floor(Math.random() * 30); i++) {
        const tmp = {
          qtr: Math.random() > 0.5 ? "2021 - Q3" : "2021 - Q4",
          loanId: Math.floor(Math.random() * 99999),
          borrower:
            testDataBorrower[
              Math.floor(Math.random() * testDataBorrower.length)
            ],
          riskRating:
            testDataRisk[Math.floor(Math.random() * testDataRisk.length)],
          interest:
            testDataInterest[
              Math.floor(Math.random() * testDataInterest.length)
            ],
          loanAmount:
            testDataLoanAmount[
              Math.floor(Math.random() * testDataLoanAmount.length)
            ],
          currentBalance:
            testDataLoanAmount[
              Math.floor(Math.random() * testDataLoanAmount.length)
            ] * 0.02,
          maturity:
            testDataMaturity[
              Math.floor(Math.random() * testDataMaturity.length)
            ],
          maturityDate: "2022-01-01",
          monthlyPayment:
            testDataMonthlyPayment[
              Math.floor(Math.random() * testDataMonthlyPayment.length)
            ],
          location:
            testDataLocation[
              Math.floor(Math.random() * testDataLocation.length)
            ],
          agency: "Dept. of Defense",
          designation:
            testDataDesignation[
              Math.floor(Math.random() * testDataDesignation.length)
            ],
          covenants: {
            leverage:
              testDataLeverage[
                Math.floor(Math.random() * testDataLeverage.length)
              ],
            dscr: testDataDSCR[Math.floor(Math.random() * testDataDSCR.length)],
            tnw: testDataTNW[Math.floor(Math.random() * testDataTNW.length)],
            currentRatio:
              testDataCurrentRatio[
                Math.floor(Math.random() * testDataCurrentRatio.length)
              ],
          },
        };
        data.push(tmp);
      }
      setLoanApplications(data);
      setQuarters(["2021 - Q3", "2021 - Q4"]);
      setCurrentPage(0);
    }
    setGeneratePlaceholderData(false);
  }, [generatePlaceholderData]);

  useEffect(() => {
    if (exportLoanTape) {
      const csvData = [];
      csvData.push([
        "Loan ID",
        "Borrower",
        "Risk Rating",
        "Interest",
        "Loan Amount",
        "Maturity",
        "Monthly Payment",
        "Location",
        // "Line of Business",
        "Agency",
        "Designation",
        "Leverage Ratio",
        "DSCR",
        "Tangible Net Worth",
        "Current Ratio",
      ]);
      for (const i in loanApplications) {
        const tmp = Object.values(loanApplications[i]);
        const quarter = tmp.shift();
        const covenants: any = tmp.pop();
        const ret = [...tmp, ...Object.values(covenants)];
        for (const j in ret) {
          ret[j] = `"${ret[j]}"`;
        }
        csvData.push(ret);
      }
      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "loanTapeExport.csv");
      document.body.appendChild(link);
      link.click();
      setExportLoanTape(false);
    }
  }, [exportLoanTape]);

  const { authStore } = useStore();

  const formatDisplay = (value: string, format: any) => {
    if (!value || value === "n/a") return "n/a";
    if (format === "$")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(value));
    if (format === "%") return value + "%";

    return value;
  };

  const getPagesButtonsNumbers = useCallback(() => {
    const buttons = [];
    for (
      let i = Math.max(0, currentPage - 2);
      i < Math.min(loanApplicationsDisplayList.length, currentPage + 3);
      i++
    )
      buttons.push(i);
    return buttons;
  }, [loanApplicationsDisplayList, currentPage]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header theme="light" />
        <CovenantModal
          isOpen={isCovenantModalOpen}
          close={() => setIsCovenantModalOpen(false)}
          covenants={covenants}
        />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>Loan Tape</Text>
            <Text style={styles.welcomeMessageText}>
              View the repayment schedules of all loans that belong to you
            </Text>
            <View>
              <View style={styles.requestedDocumentsHeader}>
                <View
                  style={{
                    ...styles.flexRow,
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    status="basic"
                    style={{ backgroundColor: "white", borderColor: "white" }}
                    accessoryRight={DownloadIcon}
                    onPress={() => setExportLoanTape(true)}
                    children={() => (
                      <Text style={styles.previewLoanButton}>Tape Export</Text>
                    )}
                  />
                  <Select
                    selectedIndex={selectedQuarter}
                    value={quarters[selectedQuarter.row]}
                    onSelect={(index) => setSelectedQuarter(index)}
                  >
                    {quarters.map((quarter: any, index: number) => (
                      <SelectItem
                        title={quarter}
                        key={`${quarter} - ${index}`}
                      />
                    ))}
                  </Select>
                </View>
              </View>
              <View
                style={{ height: height - 250, ...(loading && styles.center) }}
              >
                <ScrollView
                  style={{
                    ...styles.scroll,
                    maxHeight: height - 250,
                  }}
                  keyboardShouldPersistTaps="handled"
                >
                  {loading || !loanApplications ? (
                    <LoadingIndicator />
                  ) : (
                    <View style={styles.tableContainer}>
                      <View style={styles.tableHeaders}>
                        {Object.values(fieldsDesc).map((field) => {
                          return (
                            <View
                              key={"header-" + field.display}
                              style={{
                                ...{ width: field.width },
                                ...styles.tableCell,
                              }}
                            >
                              {field.agencyOrDesignationSelect ? (
                                <Select
                                  size="small"
                                  selectedIndex={agenciesOrDistributionsIndex}
                                  value={
                                    agenciesOrDistributionsIndex?.row
                                      ? "Designation"
                                      : "Agency"
                                  }
                                  onSelect={(index) =>
                                    setAgenciesOrDistributionsIndex(index)
                                  }
                                >
                                  <SelectItem title="Agency" />
                                  <SelectItem title="Designation" />
                                </Select>
                              ) : (
                                <Text
                                  style={{
                                    ...styles.tableHeadersCellText,
                                    ...{ width: field.width, paddingLeft: 8 },
                                  }}
                                >
                                  {field.display}
                                </Text>
                              )}
                            </View>
                          );
                        })}
                      </View>
                      {(loanApplicationsDisplayList[currentPage] || []).map(
                        (doc: any, index: any) => (
                          <View
                            key={doc.loanId + "-row"}
                            style={{
                              ...styles.tableRow,
                              backgroundColor:
                                index % 2 == 0 ? "white" : "#8F9BB314",
                            }}
                          >
                            {Object.keys(fieldsDesc).map(
                              (fieldName: string) => (
                                <View
                                  key={
                                    doc.loanId +
                                    "-row-data-" +
                                    fieldsDesc[fieldName].display
                                  }
                                  style={{
                                    ...{
                                      width: fieldsDesc[fieldName].width,
                                    },
                                    ...styles.tableCell,
                                  }}
                                >
                                  {fieldName === "loanId" ? (
                                    <Text
                                      style={{
                                        ...(fieldsDesc[fieldName].align
                                          ? {
                                              textAlign:
                                                fieldsDesc[fieldName].align,
                                            }
                                          : {}),
                                        ...styles.tableCellText,
                                      }}
                                      onPress={() => {
                                        navigation.navigate("summary", {
                                          applicationId: doc[fieldName],
                                          isInvestorView: true,
                                        });
                                      }}
                                    >
                                      {doc[fieldName]}
                                    </Text>
                                  ) : fieldName !== "covenants" ? (
                                    <Text
                                      numberOfLines={1}
                                      style={{
                                        ...(fieldsDesc[fieldName].align
                                          ? {
                                              textAlign:
                                                fieldsDesc[fieldName].align,
                                            }
                                          : {}),
                                        ...styles.tableCellText,
                                        ...(fieldName ===
                                          "finalDecisionStatus" ||
                                        fieldName === "status"
                                          ? {
                                              // @ts-ignore
                                              ...styles[doc[fieldName]],
                                              paddingHorizontal: 5,
                                              textAlign: "center",
                                            }
                                          : {}),
                                        flex: 1,
                                      }}
                                    >
                                      {formatDisplay(
                                        fieldsDesc[fieldName]
                                          .agencyOrDesignationSelect
                                          ? !agenciesOrDistributionsIndex?.row
                                            ? doc["agency"]
                                            : doc["designation"]
                                          : doc[fieldName],
                                        fieldsDesc[fieldName].format || ""
                                      )}
                                    </Text>
                                  ) : (
                                    <Button
                                      status="basic"
                                      appearance="ghost"
                                      accessoryRight={InfoIcon}
                                      style={{
                                        width: fieldsDesc[fieldName].width - 20,
                                      }}
                                      onPress={() => {
                                        setCovenants(doc.covenants);
                                        setIsCovenantModalOpen(true);
                                      }}
                                    />
                                  )}
                                </View>
                              )
                            )}
                          </View>
                        )
                      )}
                    </View>
                  )}
                </ScrollView>
              </View>

              <View
                style={{
                  ...styles.flexRow,
                  justifyContent: "space-between",
                  paddingHorizontal: 30,
                  margin: 0,
                }}
              >
                <Text style={styles.subheader} appearance="hint">
                  {`Showing ${currentPage * displayPerPage + 1} to ${
                    loanApplicationsDisplayList.length
                      ? Math.min(
                          (currentPage + 1) * displayPerPage,
                          displayPerPage *
                            (loanApplicationsDisplayList.length - 1) +
                            loanApplicationsDisplayList[
                              loanApplicationsDisplayList.length - 1
                            ].length
                        )
                      : (currentPage + 1) * displayPerPage
                  } of ${
                    loanApplicationsDisplayList.length
                      ? displayPerPage *
                          (loanApplicationsDisplayList.length - 1) +
                        loanApplicationsDisplayList[
                          loanApplicationsDisplayList.length - 1
                        ].length
                      : "?"
                  } entries`}
                </Text>
                <View style={styles.flexRow}>
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={LeftArrowIcon}
                    onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
                  />
                  {getPagesButtonsNumbers().map((idx) => (
                    <Button
                      status="basic"
                      appearance={idx === currentPage ? "filled" : "ghost"}
                      onPress={() => setCurrentPage(idx)}
                      key={"page-btn-" + idx}
                    >
                      {idx + 1}
                    </Button>
                  ))}
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={RightArrowIcon}
                    onPress={() =>
                      setCurrentPage(
                        Math.min(
                          loanApplicationsDisplayList.length - 1,
                          currentPage + 1
                        )
                      )
                    }
                  />
                </View>
              </View>
              <View
                style={{
                  ...styles.flexRow,
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                {__DEV__ || authStore?.user?.isStaff ? (
                  <Button onPress={() => setGeneratePlaceholderData(true)}>
                    Generate Placeholder Data
                  </Button>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default InvestorLoanTape;
