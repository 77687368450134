import * as eva from "@eva-design/eva";
import {
  Layout,
  Text,
  useStyleSheet,
  Button,
  Divider,
  Icon,
  ThemeProvider,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import { Dimensions, View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { SummaryBusinessInfo } from "./SummaryBusinessInfo";
import { SummaryBusinessOwner } from "./SummaryBusinessOwner";
import { SummaryBusinessRole } from "./SummaryBusinessRole";
import { SummaryOtherInfo } from "./SummaryOtherInfo";
import { SummaryPersonalInfo } from "./SummaryPersonalInfo";
import { SummarySSN } from "./SummarySSN";
import { SummaryTaxGuard } from "./SummaryTaxGuard";
import { themedStyles } from "./themedStyles";

export type Props = object;

const SummaryStep: React.FC<any> = ({
  mbo,
  formData,
  setFormData,
  userFormData,
  setUserFormData,
  business,
  goToNextStep,
}) => {
  const { width } = Dimensions.get("window");
  const [isPersonalInfoEdit, setIsPersonalInfoEdit] = useState(false);
  const [isSSNEdit, setIsSSNEdit] = useState(false);
  const [isBusinessRoleEdit, setIsBusinessRoleEdit] = useState(false);
  const [isTaxGuardEdit, setIsTaxGuardEdit] = useState(false);
  const [isOtherInfoEdit, setIsOtherInfoEdit] = useState(false);

  const styles = useStyleSheet(themedStyles);
  const [success, setSuccess] = useState(false);

  const EditIcon = (props: any) => (
    <Icon
      {...props}
      name={theme === "dark" ? "edit-outline" : "edit"}
      fill={theme === "dark" ? "#26DDF1" : "#1D84FF"}
    />
  );

  const { authStore } = useStore();
  const theme =
    authStore?.user?.isStaff || authStore.user?.isUnderwriter
      ? "light"
      : "dark";

  return (
    <View
      style={{
        ...(width > 768 ? styles.profileView : styles.profileViewMobile),
      }}
    >
      {success && (
        <View style={styles.toastSuccess}>
          <View>
            <Text style={styles.toastDescription}>Changes have been saved</Text>
          </View>
        </View>
      )}
      <View style={styles.flexRowBetween}>
        <Text style={styles.summarySubHeader}>Personal Information</Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsPersonalInfoEdit(true);
            setSuccess(false);
          }}
        />
      </View>
      <Divider style={styles.divider} />
      <SummaryPersonalInfo
        user={authStore.user}
        mbo={mbo}
        business={business}
        isEdit={isPersonalInfoEdit}
        setIsEdit={setIsPersonalInfoEdit}
        formData={userFormData}
        setFormData={setUserFormData}
        setSuccess={setSuccess}
        theme={theme}
      />

      <View style={styles.flexRowBetweenSpacer}>
        <Text style={styles.summarySubHeader}>SSN</Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsSSNEdit(true);
            setSuccess(false);
          }}
        />
      </View>
      <Divider style={styles.divider} />
      <SummarySSN
        user={authStore.user}
        mbo={mbo}
        isEdit={isSSNEdit}
        setIsEdit={setIsSSNEdit}
        business={business}
        formData={userFormData}
        setFormData={setUserFormData}
        setSuccess={setSuccess}
        theme={theme}
        width={width}
      />

      <View style={styles.flexRowBetweenSpacer}>
        <Text style={styles.summarySubHeader}>Business Role</Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsBusinessRoleEdit(true);
            setSuccess(false);
          }}
        />
      </View>
      <Divider style={styles.divider} />
      <SummaryBusinessRole
        user={authStore.user}
        mbo={mbo}
        isEdit={isBusinessRoleEdit}
        setIsEdit={setIsBusinessRoleEdit}
        business={business}
        formData={userFormData}
        setFormData={setUserFormData}
        setSuccess={setSuccess}
        theme={theme}
        width={width}
      />
      <View />

      <View style={styles.flexRowBetweenSpacer}>
        <Text style={styles.summarySubHeader}>Tax Guard</Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsTaxGuardEdit(true);
            setSuccess(false);
          }}
        />
      </View>
      <Divider style={styles.divider} />
      <SummaryTaxGuard
        user={authStore.user}
        mbo={mbo}
        isEdit={isTaxGuardEdit}
        setIsEdit={setIsTaxGuardEdit}
        formData={formData}
        setFormData={setFormData}
        setSuccess={setSuccess}
        theme={theme}
      />

      <View style={styles.flexRowBetweenSpacer}>
        <Text style={styles.summarySubHeader}>Other Information</Text>
        <Button
          style={styles.editButton}
          appearance="ghost"
          status="basic"
          accessoryLeft={EditIcon}
          onPress={() => {
            setIsOtherInfoEdit(true);
            setSuccess(false);
          }}
        />
      </View>
      <Divider style={styles.divider} />
      <SummaryOtherInfo
        user={authStore.user}
        mbo={mbo}
        isEdit={isOtherInfoEdit}
        setIsEdit={setIsOtherInfoEdit}
        formData={formData}
        setFormData={setFormData}
        setSuccess={setSuccess}
        theme={theme}
      />

      <View style={styles.flexRowBetweenSpacer}>
        <Text style={{ ...styles.summarySubHeader, marginBottom: 20 }}>
          Business Information
        </Text>
      </View>
      <Divider style={styles.divider} />
      <SummaryBusinessInfo
        user={authStore.user}
        mbo={mbo}
        business={business}
        theme={theme}
      />

      <View style={styles.flexRowBetweenSpacer}>
        <Text style={{ ...styles.summarySubHeader, marginBottom: 20 }}>
          Other Business Owners
        </Text>
      </View>
      <Divider style={styles.divider} />
      <SummaryBusinessOwner
        user={authStore.user}
        businessId={business.id}
        theme={theme}
      />

      {/* TODO: Business Owners */}
      {/* <View style={styles.flexRowBetweenSpacer}>
        <Text style={styles.subHeader}>Other Business Owners</Text>
      </View>
      <Divider style={styles.divider} />
      <SummaryBusinessOwners
        user={authStore.user}
        formData={formData}
        setFormData={setFormData}
        setSuccess={setSuccess}
        theme={theme}
      /> */}
    </View>
  );
};

export default SummaryStep;
