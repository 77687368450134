import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  adminDashBoard: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginLeft: 50,
    marginRight: 25,
    maxWidth: 1450,
    overflow: "hidden",
  },
  adminDashboardContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    flex: 1,
    fontSize: 22,
    margin: 5,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  filterCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    padding: 5,
  },
  filterSubCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 2,
    padding: 2,
    marginHorizontal: 20,
  },
  LogInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    margin: 2,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 600,
    flex: 1,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#EDF1F7",
  },
  tableHeadersCell: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    padding: 1,
    margin: 1,
    alignSelf: "center",
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  tableCell: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 20,
    fontSize: 14,
    padding: 1,
    margin: 1,
    alignSelf: "center",
    textAlign: "center",
    borderRadius: 5,
  },
  tableContainer: {},
  ACTIVE: {
    backgroundColor: "color-success-500",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  NEW: {
    backgroundColor: "color-success-500",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  INACTIVE: {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
    color: "text-color-basic-default",
  },
  "TIMED OUT": {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
    color: "text-color-basic-default",
  },
  COMPLETE: {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
  },
  PENDING: {
    backgroundColor: "color-success-100",
    fontFamily: "Montserrat_700Bold",
  },
  selectItem: {
    backgroundColor: "white",
    color: "#222B45",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
  },
  welcomeMessageText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 24,
    fontSize: 13,
    textAlign: "left",
  },
  notificationIcon: {
    padding: 10,
    marginHorizontal: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tooltipTextDark: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "black",
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
  },
  notificationCircle: {
    position: "absolute",
    width: 9,
    height: 9,
    borderRadius: 5,
    top: 10,
    right: 12,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    zIndex: 1,
  },
  notificationsContainer: {
    width: 250,
    maxHeight: 500,
    flexDirection: "column",
    padding: 5,
  },
  notificationDivider: {
    flex: 1,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    paddingVertical: 1,
    marginVertical: 10,
  },
  flexRowItemsCenter: { flexDirection: "row", alignItems: "center" },
});
