import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/native";
import {
  Layout,
  useStyleSheet,
  ThemeProvider,
  Icon,
  Button,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Image } from "react-native";

import Header from "../../components/Header";
import UnderwriterPortalHeader from "../../components/UnderwriterPortalHeader";
import { useStore } from "../../stores";
import UploadedDocumentsTab from "../UnderwriterPortalCreditFiles/UploadedDocumentsTab";
import { Accounting } from "./Accounting";
import { BusinessEntityReview } from "./BusinessEntity";
import { BusinessPrincipalReview } from "./BusinessPrincipal";
import { ContractAndCollateral } from "./ContractAndCollateral";
import { getLoanApplicationDetails } from "../../services/api";
import { themedStyles } from "./themedStyles";

const Collateral: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const currentTab =
    route?.params?.currentTab ||
    new URLSearchParams(window.location.search).get("currentTab");

  const investorView =
    route?.params?.investorView ||
    new URLSearchParams(window.location.search).get("investorView") ||
    undefined;

  const [selectedSubMenu, setSelectedSubMenu] = useState(
    Number(currentTab) || 0
  );
  const [editTimestampValue, setEditTimestampValue] = useState<Date | null>(
    null
  );

  const factCheckImage = require("../../../assets/FactCheckIcon.svg");
  const businessEntityImage = require("../../../assets/BusinessEntityIcon.svg");
  const financialsImage = require("../../../assets/FinancialsIcon.svg");

  const ContractAndCollateralReviewIcon = (props: any) => (
    //@ts-ignore
    <Image {...props} style={styles.icon} source={factCheckImage} />
  );
  const BusinessEntityReviewIcon = (props: any) => (
    //@ts-ignore
    <Image {...props} style={styles.icon} source={businessEntityImage} />
  );
  const BusinessPrincipalReviewIcon = (props: any) => (
    <Icon
      {...props}
      style={{ ...props.style, fill: "#C5CEE0" }}
      name="briefcase-outline"
    />
  );
  const UploadedDocumentsIcon = (props: any) => (
    <Icon
      {...props}
      style={{ ...props.style, fill: "#C5CEE0" }}
      name="file-outline"
    />
  );
  const FinancialsReviewIcon = (props: any) => (
    //@ts-ignore
    <Image {...props} style={styles.icon} source={financialsImage} />
  );

  const menu = [
    {
      icon: ContractAndCollateralReviewIcon,
      name: "Contract and Collateral Review",
    },
    { icon: BusinessEntityReviewIcon, name: "Business Entity Review" },
    { icon: BusinessPrincipalReviewIcon, name: "Business Principal Review" },
    { icon: FinancialsReviewIcon, name: "Accounting" },
    { icon: UploadedDocumentsIcon, name: "Uploaded Documents" },
  ];

  const { authStore } = useStore();

  // Get current timestamp
  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      getLoanApplicationDetails(authStore?.accessToken, applicationId)
        .then((resp) => {
          setEditTimestampValue(resp.response.entities.modified);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <ThemeProvider theme={eva.light}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme="light" />
          <UnderwriterPortalHeader
            activeTab="collateral"
            applicationId={applicationId}
            investorView={investorView}
            editTimestampValue={editTimestampValue}
          />
          <View style={styles.subMenu}>
            {menu.map((item, idx) => (
              <Button
                key={`sub-menu-${idx}`}
                accessoryLeft={item.icon}
                onPress={() => {
                  setSelectedSubMenu(idx);
                  navigation.navigate(
                    investorView ? "investor" : "underwriter",
                    {
                      screen: "collateral",
                      params: { applicationId, investorView, currentTab: idx },
                    }
                  );
                }}
                appearance="ghost"
                status={selectedSubMenu === idx ? "primary" : "basic"}
              >
                {item.name}
              </Button>
            ))}
          </View>
          {selectedSubMenu === 0 && (
            <ContractAndCollateral
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
              editTimestampValue={editTimestampValue}
              setEditTimestampValue={setEditTimestampValue}
            />
          )}
          {selectedSubMenu === 1 && (
            <BusinessEntityReview
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
              editTimestampValue={editTimestampValue}
              setEditTimestampValue={setEditTimestampValue}
            />
          )}
          {selectedSubMenu === 2 && (
            <BusinessPrincipalReview
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
            />
          )}
          {selectedSubMenu === 3 && (
            <Accounting
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
            />
          )}
          {selectedSubMenu === 4 && (
            <UploadedDocumentsTab
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
            />
          )}
        </ScrollView>
      </Layout>
    </ThemeProvider>
  );
};

export default Collateral;
