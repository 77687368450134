import { useLinkTo, useNavigation } from "@react-navigation/native";
import {
  Layout,
  Text,
  useStyleSheet,
  Divider,
  Icon,
  Button,
  Tooltip,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import StepButton from "../../components/StepButton/StepButton";
import User from "../../models/User";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import { BusinessEntityStep } from "./BusinessEntityStep";
import { BusinessPrincipalStep } from "./BusinessPrincipalStep";
import { ContractHistoryStep } from "./ContractHistoryStep";
import { FinancialInformationStep } from "./FinancialInformationStep";
import { themedStyles } from "./themedStyles";

const FullApplication: React.FC<any> = ({ route }) => {
  const { width } = Dimensions.get("window");
  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const navigation = useNavigation();
  const loanId = Number(id);
  const { loanStore, authStore, businessStore } = useStore();
  const styles = useStyleSheet(themedStyles);
  const linkTo = useLinkTo();
  const [loan, setLoan] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [stepsDone, setStepsDone] = useState<any>(Array(4).fill(false));
  const [currentStep, setCurrentStep] = useState<number>(1);

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isAlreadyAtTheLastStep, setIsAlreadyAtTheLastStep] = useState(false);

  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const ArrowIconLeft = (props: any) => (
    <Icon {...props} fill="white" name="arrow-back-outline" />
  );

  const goToNextStep = (isStepDone = false) => {
    const newStepsDone = [...stepsDone];
    newStepsDone[currentStep] = isStepDone || newStepsDone[currentStep];
    setStepsDone(newStepsDone);
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    updateLoanData({ startedFullApplication: true });
  }, []);

  const updateLoanData = async (data: any) => {
    const res = await loanStore.updateLoanApplication(loanId, data);
    if (res?.ok && data["managementExperience"] && authStore.user) {
      //@ts-ignore
      authStore.user.update({
        managementExperience: `${data["managementExperience"]}`,
      });
    }
    return res;
  };

  const sectionNamesForBackButton = [
    "",
    "BUSINESS ENTITY INFORMATION",
    "CONTRACT HISTORY",
    "FINANCIAL INFORMATION",
    "BUSINESS PRINCIPAL INFORMATION",
  ];
  const getFirstSectionThatsNotFilled = () => {
    let i = 1;
    while (stepsDone[i]) i++;
    return i;
  };

  useEffect(() => {
    if (loanStore.loanApplications.size) {
      let flag = false;
      loanStore.loanApplications.forEach((loan) => {
        if (loan.id === loanId) {
          flag = true;
          if (loan.applicantId === authStore.user?.id) {
            setLoan(loan.$);
          } else {
            navigation.navigate("dashboard");
          }
        }
      });
      if (!flag) {
        navigation.navigate("dashboard");
      }
    }
  }, [loanId]);

  useEffect(() => {
    if (!businessStore.business.size) {
      businessStore.fetchBusinesses();
    } else {
      if (loan.business) {
        let flag = false;
        businessStore.business.forEach((business) => {
          if (Number(business.id) === Number(loan.business)) {
            setBusiness(business.$);
            flag = true;
          }
        });
        if (!flag) {
          navigation.navigate("dashboard");
        }
      }
    }
  }, [id, businessStore.business, loan]);

  useEffect(() => {
    (async () => {
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (!res1.response.entities) {
        navigation.navigate("dashboard");
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (!res2.response.entities) {
        navigation.navigate("dashboard");
      }
    })();
  }, []);

  const areAllRequiredDocumentsUploaded = () =>
    loan.oneYearContractBacklog &&
    loan.contractingOfficerFirstName &&
    loan.contractingOfficerLastName &&
    loan.contractingOfficerEmail &&
    loan.contractingOfficerPhone &&
    (!loan.hasExistingLenders || loan.existingLenders.length) &&
    loan.driversLicense &&
    (loan.linkedIn || loan.resumes) &&
    loan.byLaws &&
    loan.corporateOrAuthorizingResolution;

  useEffect(() => {
    if (currentStep > 4 && areAllRequiredDocumentsUploaded()) {
      loanStore.verifyFullApplication(loanId).catch((error) => {
        console.log(error);
      });
    }
  }, [currentStep]);

  useEffect(() => {
    if (Object.keys(loan).indexOf("status") >= 0)
      if (loan.status !== "full" && loan.status !== "fullInProgress") {
        navigation.navigate("loan-view", { id });
      }
  }, [loan]);

  const steps = [
    "Business Entity Information",
    "Contract History",
    "Financial Information",
    "Business Principal Information",
  ];

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.gradientContainer}>
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={["#D012B2", "#8A1CDB", "#5327FF", "#1D84FF", "#26DDF1"]}
            style={
              width > 768
                ? styles.fullApplicationContainer
                : styles.fullApplicationContainerMobile
            }
          >
            <View
              style={
                width > 768
                  ? styles.fullApplication
                  : styles.fullApplicationMobile
              }
            >
              <Text style={width > 768 ? styles.header : styles.headerMobile}>
                {currentStep <= 3
                  ? "Full Application"
                  : areAllRequiredDocumentsUploaded()
                  ? "Congratulations!"
                  : "Please complete all sections"}
              </Text>
              <Text
                style={width > 768 ? styles.subHeader : styles.subHeaderMobile}
              >
                {currentStep <= 3
                  ? "You're almost there! Please provide a clear copy of each of the documents requested below."
                  : areAllRequiredDocumentsUploaded()
                  ? "Your Application is complete!"
                  : "There are missing required fields."}
              </Text>
              <View
                style={
                  width > 768
                    ? styles.fullApplicationStatusContainer
                    : styles.fullApplicationStatusContainerMobile
                }
              >
                {steps.map((step, idx) => {
                  return (
                    <View key={step}>
                      <StepButton
                        number={idx + 1}
                        label={step}
                        stepsDone={stepsDone}
                        currentStep={currentStep === 5 ? 4 : currentStep}
                        onPress={() => setCurrentStep(idx + 1)}
                      />
                      {idx < steps.length - 1 && (
                        <Divider style={styles.divider} />
                      )}
                    </View>
                  );
                })}
              </View>

              {width < 768 && (
                <View style={styles.fullApplicationLabelContainer}>
                  <Text style={styles.fullApplicationLabel}>
                    {steps[currentStep - 1]}
                  </Text>
                  {currentStep < 4 && (
                    <Text
                      style={styles.fullApplicationSubLabel}
                    >{`Next: ${steps[currentStep]}`}</Text>
                  )}
                </View>
              )}

              {currentStep === 1 && (
                <BusinessEntityStep
                  loan={loan}
                  goToNextStep={goToNextStep}
                  updateLoanData={updateLoanData}
                  hasEmptyRequiredFields={isAlreadyAtTheLastStep}
                />
              )}
              {currentStep === 2 && (
                <ContractHistoryStep
                  loan={loan}
                  goToNextStep={goToNextStep}
                  updateLoanData={updateLoanData}
                  hasEmptyRequiredFields={isAlreadyAtTheLastStep}
                />
              )}
              {currentStep === 3 && (
                <FinancialInformationStep
                  loan={loan}
                  business={business}
                  goToNextStep={goToNextStep}
                  updateLoanData={updateLoanData}
                />
              )}
              {currentStep === 4 && (
                <BusinessPrincipalStep
                  loan={loan}
                  goToNextStep={goToNextStep}
                  updateLoanData={updateLoanData}
                  hasEmptyRequiredFields={isAlreadyAtTheLastStep}
                />
              )}
              {currentStep > 4 && areAllRequiredDocumentsUploaded() && (
                <>
                  <Text style={styles.fullApplicationDoneMessage}>
                    Our team will review your application, and we may reach out
                    for additional information. You will receive an email or
                    notice on your dashboard when your application has been
                    reviewed.
                  </Text>
                  <Button
                    style={styles.nextActionButton}
                    status="primary"
                    appearance="outline"
                    onPress={() => {
                      linkTo("/dashboard");
                    }}
                    accessoryRight={ArrowIcon}
                    children={() => (
                      <Text style={styles.nextActionButtonText}>
                        CONTINUE TO DASHBOARD
                      </Text>
                    )}
                  />
                </>
              )}
              {currentStep > 4 && !areAllRequiredDocumentsUploaded() && (
                <Button
                  style={{
                    ...styles.nextActionButton,
                    marginVertical: 15,
                    maxWidth: "unset",
                  }}
                  status="basic"
                  appearance="outline"
                  onPress={() => {
                    setCurrentStep(getFirstSectionThatsNotFilled());
                    setIsAlreadyAtTheLastStep(true);
                  }}
                  accessoryLeft={ArrowIconLeft}
                  children={() => (
                    <Text style={styles.nextActionButtonText}>
                      {`GO BACK TO ${
                        sectionNamesForBackButton[
                          getFirstSectionThatsNotFilled()
                        ]
                      } SECTION`}
                    </Text>
                  )}
                />
              )}
            </View>
          </LinearGradient>
        </View>
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default FullApplication;
