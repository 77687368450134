import { useNavigation } from "@react-navigation/core";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Card,
  Divider,
  Modal,
} from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import {
  View,
  ScrollView,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";

import config from "../../config";

// @ts-ignore
import { WebView as WebViewWeb } from "react-native-web-webview";
import * as api from "../../services/api";
import {
  createSigningCeremony,
  getDocuSignAppKeys,
  getDocuSignAuthToken,
  retrieveSignedDocument,
} from "../../services/api/docusign";
import { useStore } from "../../stores";
import { uploadDocument } from "../../utils";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const RetrieveSignedDoc: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.state.split(":")[0] ||
    new URLSearchParams(window.location.search).get("state");

  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [embedUrl, setEmbedUrl] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [frontendUrl, setFrontendUrl] = useState("");

  const [accessToken, setAccessToken] = useState("");
  const [uploadedName, setUploadedName] = useState("");
  const [uploadedUri, setUploadedUri] = useState("");

  const IconPlaceholder = (_props: any) => <View />;

  const [formData, setFormData] = useState<any>({
    uploadedDocument: "",
    docType: "Closing Document",
    categoryType: "",
    documentName: "",
  });

  const submit = (item: any) => {
    setUploadLoading(true);

    const data = new FormData();
    Object.keys(formData).forEach((key) => data.append(key, formData[key]));

    api
      .uploadDocumentFromUnderwriterPortal(
        authStore.accessToken || "",
        applicationId,
        data
      )
      .then((resp) => {
        const response = resp.response.entities;
        console.log(response);
        setUploadedUri(response["fileUri"]);
        setUploadLoading(false);
        setUploadDone(true);
      });
  };

  const [docuSignModal, setDocuSignModal] = useState(false);

  useEffect(() => {
    (async () => {
      let basicToken = "";

      const resp = await getDocuSignAppKeys(authStore?.accessToken || "");
      if (resp.response.entities) {
        let buf = Buffer.from(
          `${resp.response.entities["integrationKey"]}:${resp.response.entities["secretKey"]}`
        );
        basicToken = buf.toString("base64");
        setFrontendUrl(resp.response.entities["frontendUrl"]);
      }

      let data = {
        basicToken: basicToken,
        grantType: "authorization_code",
        code: route?.params?.code || "",
      };

      const respAuth = await getDocuSignAuthToken(
        authStore?.accessToken || "",
        data
      );
      if (respAuth.response.entities) {
        setAccessToken(respAuth.response.entities["accessToken"]);
      }
    })();
  }, []);

  useEffect(() => {
    if (accessToken !== "") {
      setLoading(true);
      let data = {
        accessToken: accessToken,
        applicationId: applicationId,
        isLoanTerms: false,
      };
      (async () => {
        const resp = await retrieveSignedDocument(
          authStore?.accessToken || "",
          data
        );
        if (resp.response.entities) {
          setEmbedUrl(
            config.urls.api.replace("/api/", "") +
              resp.response.entities["fileUri"]
          );
        }
        setLoading(false);
      })();
    }
  }, [accessToken]);

  const { authStore } = useStore();

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <WebViewWeb
          scrollEnabled
          source={{
            uri: embedUrl,
          }}
        />
      )}
    </>
  );
};

export default RetrieveSignedDoc;
