import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Spinner,
  Input,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";

import HoverBubble from "../../components/HoverBubble";
import { useStore } from "../../stores";
import { uploadDocument, downloadFileFromURI } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const TaxReturnAuthorizationStep: React.FC<{
  loanId: number;
  unsigned8821Form: string;
  formData: any;
  setFormData: Function;
  goToNextStep: Function;
  disabled: boolean;
}> = ({
  loanId,
  unsigned8821Form,
  formData,
  setFormData,
  goToNextStep,
  disabled,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);
  const { loanStore } = useStore();

  const [loading, setLoading] = useState(false);
  const [unsignedDownloaded, setUnsignedDownloaded] = useState(false);
  const [unsigned8821, setUnsigned8821] = useState(unsigned8821Form);

  const defaultErrors = { detail: "" };
  const [errorMessages, setErrorMessages] = useState(defaultErrors);

  const DownloadIcon = (props: any) => (
    <Icon {...props} fill="white" name="download-outline" />
  );
  const UploadIcon = (props: any) => (
    <Icon {...props} fill="white" name="upload-outline" />
  );
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="tiny" />
  );
  const DoneIcon = () => (
    <Button
      size="small"
      appearance="filled"
      status="basic"
      style={styles.circleButton}
      children={() => (
        <Icon style={styles.iconSmall} name="checkmark-outline" />
      )}
    />
  );

  const getUnsigned8821Form = async (id: number) => {
    setLoading(true);
    const res = await loanStore.getUnsigned8821Form(id);
    if (res?.ok) {
      setUnsigned8821(res?.extra?.unsigned8821Form);
    } else {
      setErrorMessages(res?.errors);
    }
    setLoading(false);
    return res;
  };

  useEffect(() => {
    if (loanId && !unsigned8821Form) getUnsigned8821Form(loanId);
  }, []);

  const toDisableContinue =
    !unsigned8821 || (formData.signed8821Form && disabled) || loading;

  return (
    <View>
      <Text
        style={{ ...styles.title, flexDirection: "row", alignItems: "center" }}
      >
        Tax Guard
        <HoverBubble
          text={
            <Text style={styles.tooltipTextDark}>
              This IRS form authorizes us to look at your tax returns for the
              last 2 years.
            </Text>
          }
          theme="dark"
        />
      </Text>
      <Text style={styles.subTitle}>
        {formData.signed8821Form
          ? "You've successfully uploaded your signed form!"
          : "Please download the 8821 form and re-upload it once you've read and signed it."}
      </Text>
      {errorMessages?.detail && (
        <Text>
          <Text
            category="h6"
            style={width > 576 ? styles.error : styles.errorMobile}
          >
            {errorMessages?.detail}
          </Text>
        </Text>
      )}

      {formData.signed8821Form && (
        <Input
          disabled
          style={{
            ...{ marginTop: 20 },
            ...(width > 576 ? styles.input : styles.inputMobile),
          }}
          textStyle={{
            ...(width > 576 ? styles.inputText : styles.inputTextMobile),
            color: "#55AAFF",
          }}
          autoCorrect={false}
          value={formData.signed8821Form.name}
          accessoryRight={DoneIcon}
        />
      )}

      <View style={styles.taxGuardContainer}>
        <Button
          disabled={toDisableContinue}
          style={{
            ...(toDisableContinue
              ? styles.disabledActionButton
              : styles.nextActionButton),
            ...{ marginRight: 20.5 },
          }}
          status={formData.signed8821Form ? "success" : "basic"}
          accessoryRight={
            loading
              ? LoadingIndicator
              : formData.signed8821Form
              ? ArrowIcon
              : unsignedDownloaded
              ? UploadIcon
              : DownloadIcon
          }
          appearance="outline"
          onPress={() => {
            if (formData.signed8821Form) {
              goToNextStep(true);
            } else if (unsignedDownloaded) {
              uploadDocument((doc: any) =>
                setFormData({ ...formData, signed8821Form: doc.file })
              );
            } else if (unsigned8821) {
              downloadFileFromURI(unsigned8821, "8821 form");
              setUnsignedDownloaded(true);
            }
          }}
          children={() => (
            <Text
              style={
                toDisableContinue
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              {formData.signed8821Form
                ? "Continue"
                : unsignedDownloaded
                ? "Upload"
                : "Download"}
              {loading ? "  " : ""}
            </Text>
          )}
        />
      </View>
    </View>
  );
};
