import {
  Button,
  Text,
  useStyleSheet,
  Divider,
  Modal,
  Layout,
  Icon,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View, Image, ScrollView, Dimensions } from "react-native";

import * as api from "../../services/api";
import { useStore } from "../../stores";
import { BankLinkModal } from "./BankLinkModal";
import { YesNoQuestion } from "./YesNoQuestion";
import { themedStyles } from "./themedStyles";

export const BankLinkStep: React.FC<any> = ({
  formData,
  setFormData,
  onContinue,
  loanId,
  applicantPrimaryAccountId,
}) => {
  const [linkMore, setLinkMore] = useState("");
  const styles = useStyleSheet(themedStyles);
  const [selectPayrollAccModalOpen, setSelectPayrollAccModalOpen] =
    useState(false);
  const [selectPrimaryAccModalOpen, setSelectPrimaryAccModalOpen] =
    useState(false);
  const [primaryAccountId, setPrimaryAccountId] = useState(
    applicantPrimaryAccountId
  );
  const [loading, setLoading] = useState(false);
  const [selectedPayrollAccIds, setSelectedPayrollAccIds] = useState<any>([]);
  const [selectedPrimaryAcc, setSelectedPrimaryAcc] = useState<any>(null);

  const LoadingIndicator = (props: any) => (
    <View style={styles.spinnerContainer}>
      <Spinner size="giant" />{" "}
    </View>
  );

  const SquareIcon = (props: any) => <Icon {...props} name="square-outline" />;
  const SquareDoneIcon = (props: any) => (
    <Icon {...props} name="checkmark-square-outline" />
  );
  const CheckmarkOutline = (props: any) => (
    <Icon {...props} fill="white" name="checkmark-outline" />
  );
  const InfoIcon = (props: any) => <Icon {...props} name="info-outline" />;
  const { authStore } = useStore();

  useEffect(() => {
    setLoading(true);
    api.getLinkedAccounts(authStore.accessToken || "").then((resp) => {
      setLoading(false);
      if (Object.values(resp.response.entities).length)
        setFormData({
          ...formData,
          bankAccounts: Object.values(resp.response.entities).map(
            (bank: any) => ({
              isLinked: true,
              ...bank,
            })
          ),
        });
      Object.values(resp.response.entities).forEach((bank: any) => {
        if (bank.accounts.length) {
          const primaryAccount = bank.accounts.find(
            (acc: any) => acc.id === primaryAccountId
          );
          if (primaryAccount) {
            setPrimaryAccountId(primaryAccount.id);
            setSelectedPrimaryAcc(
              `${bank.name} - ${primaryAccount.name} - ${
                "*".repeat(8) + primaryAccount.mask
              }`
            );
          }
        }
      });
    });
  }, []);

  return (
    <View style={styles.container}>
      <Modal
        visible={selectPayrollAccModalOpen}
        onBackdropPress={() => setSelectPayrollAccModalOpen(false)}
        backdropStyle={styles.backdrop}
        style={styles.payrollModalContainer}
      >
        <Layout
          style={{
            ...styles.payrollModal,
            maxHeight: Dimensions.get("screen").height * 0.75,
          }}
        >
          {loading ? (
            <LoadingIndicator />
          ) : (
            <ScrollView style={styles.payrollScroll}>
              <Text style={styles.payrollModalTitle}>
                Please select accounts used for payroll (if applicable)
              </Text>
              <Divider style={styles.divider} />
              <View>
                <View>
                  {formData.bankAccounts.map((bank: any, idx: number) => {
                    if (!bank.name) return <View key={idx} />;
                    return (
                      <View
                        key={bank.name + "-" + idx}
                        style={styles.payrollBankListContainer}
                      >
                        <Image
                          style={{ width: 75, height: 75, margin: 10 }}
                          source={{ uri: `data:image/png;base64,${bank.logo}` }}
                        />
                        {bank.accounts.map((acc: any, idx: number) => (
                          <View key={acc.name + "-" + idx}>
                            <Button
                              style={styles.accountLinkingButton}
                              appearance="outline"
                              status="control"
                              onPress={() => {
                                if (selectedPayrollAccIds.includes(acc.id)) {
                                  setSelectedPayrollAccIds(
                                    selectedPayrollAccIds.filter(
                                      (id: any) => id !== acc.id
                                    )
                                  );
                                } else
                                  setSelectedPayrollAccIds([
                                    ...selectedPayrollAccIds,
                                    acc.id,
                                  ]);
                              }}
                              accessoryLeft={
                                selectedPayrollAccIds.includes(acc.id)
                                  ? SquareDoneIcon
                                  : SquareIcon
                              }
                              children={() => (
                                <Text>
                                  <Text style={styles.payrollModalTitle}>
                                    {acc.name}
                                    {"\n"}
                                  </Text>
                                  <Text style={styles.payrollModalTitle}>
                                    {"*".repeat(8) + acc.mask}
                                  </Text>
                                </Text>
                              )}
                            />
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </View>
              </View>
              <Divider style={styles.divider} />
              <Button
                disabled={loading}
                appearance="outline"
                status="control"
                onPress={() => {
                  setLoading(true);
                  api
                    .postPayrollAccounts(authStore.accessToken || "", {
                      payrollAccounts: selectedPayrollAccIds,
                      primaryAccountId,
                      loanId,
                    })
                    .then((resp) => {
                      setLoading(false);
                      setSelectPayrollAccModalOpen(false);
                      onContinue(true);
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.error(err);
                    });
                }}
              >
                Confirm
              </Button>
            </ScrollView>
          )}
        </Layout>
      </Modal>
      <Modal
        visible={selectPrimaryAccModalOpen}
        onBackdropPress={() => setSelectPrimaryAccModalOpen(false)}
        backdropStyle={styles.backdrop}
        style={styles.payrollModalContainer}
      >
        <Layout
          style={{
            ...styles.payrollModal,
            maxHeight: Dimensions.get("screen").height * 0.75,
          }}
        >
          {loading ? (
            <LoadingIndicator />
          ) : (
            <ScrollView style={styles.payrollScroll}>
              <Text style={styles.payrollModalTitle}>
                Please select your primary account (the one that will be used to
                repay the loan)
              </Text>
              <Divider style={styles.divider} />
              <View>
                <View>
                  {formData.bankAccounts.map((bank: any, idx: number) => {
                    if (!bank.name) return <View key={idx} />;
                    return (
                      <View
                        key={bank.name + "-" + idx}
                        style={styles.payrollBankListContainer}
                      >
                        <Image
                          style={{ width: 75, height: 75, margin: 10 }}
                          source={{ uri: `data:image/png;base64,${bank.logo}` }}
                        />
                        {bank.accounts.map((acc: any, idx: number) => (
                          <View key={acc.name + "-" + idx}>
                            <Button
                              style={styles.accountLinkingButton}
                              appearance="outline"
                              status="control"
                              onPress={() => setPrimaryAccountId(acc.id)}
                              accessoryLeft={
                                primaryAccountId === acc.id
                                  ? SquareDoneIcon
                                  : SquareIcon
                              }
                              children={() => (
                                <Text>
                                  <Text style={styles.payrollModalTitle}>
                                    {acc.name}
                                    {"\n"}
                                  </Text>
                                  <Text style={styles.payrollModalTitle}>
                                    {"*".repeat(8) + acc.mask}
                                  </Text>
                                </Text>
                              )}
                            />
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </View>
              </View>
              <Divider style={styles.divider} />
              <Button
                disabled={loading || !primaryAccountId}
                appearance="outline"
                status="control"
                onPress={() => {
                  setSelectPayrollAccModalOpen(true);
                }}
              >
                Confirm
              </Button>
            </ScrollView>
          )}
        </Layout>
      </Modal>
      <View>
        {loading && <LoadingIndicator />}
        {!selectedPayrollAccIds.length &&
          !loading &&
          formData.bankAccounts.map((account: any, idx: number) => (
            <BankLinkModal
              key={`bank-acc-${idx}`}
              cb={(linkSuccess, newlyLinkedBank) =>
                setFormData({
                  ...formData,
                  bankAccounts: formData.bankAccounts.map(
                    (acc: any, index: number) => {
                      if (index !== idx) return acc;
                      return {
                        ...Object.values(newlyLinkedBank)[0],
                        isLinked: linkSuccess,
                      };
                    }
                  ),
                })
              }
              linkedBank={formData.bankAccounts[idx]}
            />
          ))}
        {!selectedPayrollAccIds.length &&
          !loading &&
          !formData.bankAccounts.filter((acc: any) => !acc.isLinked).length &&
          linkMore !== "YES" && (
            <YesNoQuestion
              question="Do you bank with another institution?"
              answer={linkMore}
              setAnswer={(answer: string) => {
                setLinkMore(answer);
              }}
            />
          )}
        {linkMore === "YES" && (
          <BankLinkModal
            canCancelLink
            cancelLink={() => setLinkMore("")}
            key={`bank-acc-${formData.bankAccounts.length}`}
            cb={(linkSuccess, newlyLinkedBank) => {
              if (linkSuccess) setLinkMore("UNKNOWN");
              setFormData({
                ...formData,
                bankAccounts: [
                  ...formData.bankAccounts,
                  {
                    ...Object.values(newlyLinkedBank)[0],
                    isLinked: linkSuccess,
                  },
                ],
              });
            }}
            linkedBank={{ isLinked: false }}
          />
        )}
        {!loading && primaryAccountId && (
          <Button
            accessoryLeft={InfoIcon}
            appearance="ghost"
            status="basic"
            disabled={true}
            children={() => (
              <View>
                <Text>{`Selected primary account: ${selectedPrimaryAcc}`}</Text>
              </View>
            )}
          />
        )}
        <Button
          disabled={linkMore !== "NO"}
          style={
            linkMore !== "NO"
              ? styles.disabledActionButton
              : styles.nextActionButton
          }
          status="basic"
          appearance="ghost"
          onPress={() => {
            if (!primaryAccountId) setSelectPrimaryAccModalOpen(true);
            else setSelectPayrollAccModalOpen(true);
          }}
          accessoryRight={CheckmarkOutline}
          children={() => (
            <Text
              style={
                linkMore !== "NO"
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              {"Mark Complete "}
            </Text>
          )}
        />
      </View>
    </View>
  );
};
