import {
  Text,
  Divider,
  useStyleSheet,
  Icon,
  Input,
  Select,
  SelectItem,
  IndexPath,
  Layout,
} from "@ui-kitten/components";
import { WebEventResponderInstance } from "@ui-kitten/components/devsupport";
import { LinearGradient } from "expo-linear-gradient";
import React, { useCallback, useEffect, useState } from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { listAllBusinessOwners } from "../../services/api";
import Collapsible from "react-native-collapsible";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

import { useStore } from "../../stores";
import {
  businessRoleOptions,
  businessTypeOptions,
  listOfStatesOptions,
  countyOptionsByState,
  stateFullNamesToShortNames,
  acceptedBusinessStateOptions,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";

export const SummaryBusinessOwner: React.FC<any> = ({
  user,
  businessId,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const [businessOwnersData, setBusinessOwnersData]: any = useState({});
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const { authStore, businessStore } = useStore();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await listAllBusinessOwners(
        authStore?.accessToken || "",
        { businessId: businessId }
      );
      console.log(response);
      if (response.response.entities) {
        setBusinessOwnersData(response.response.entities);
      }
      setLoading(false);
    })();
  }, []);

  const [loading, setLoading] = useState(false);
  const [isPrimaryOpen, setIsPrimaryOpen] = useState(true);
  const [isSecondaryOpen, setIsSecondaryOpen] = useState(true);

  return (
    <>
      <TouchableWithoutFeedback
        onPress={() => setIsPrimaryOpen(!isPrimaryOpen)}
      >
        <View style={styles.flexRowBetween}>
          <Text style={styles.summarySubSubHeader}>Primary Business Owner</Text>
          <Icon
            style={[
              styles.chevronButton,
              {
                transform: [{ rotate: isPrimaryOpen ? "180deg" : "0deg" }],
              },
            ]}
            name="chevron-down-outline"
            stroke="white"
            fill="white"
          />
        </View>
      </TouchableWithoutFeedback>
      <Divider style={styles.cardDivider} />
      <Collapsible collapsed={!isPrimaryOpen}>
        <View>
          <View style={styles.flexRow}>
            <View style={{ flex: 1 }}>
              <Input
                style={{
                  ...styles.inputNotEditing,
                  backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
                  borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
                }}
                textStyle={{
                  ...styles.inputTextNotEditing,
                  color: "#8F9BB3",
                }}
                textContentType="name"
                autoCompleteType="name"
                autoCapitalize="sentences"
                disabled
                placeholder="First Name"
                value={businessOwnersData.primaryOwner?.firstName || ""}
              />
            </View>
            <View style={{ flex: 1 }}>
              <Input
                style={{
                  ...styles.inputNotEditing,
                  backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
                  borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
                }}
                textContentType="familyName"
                autoCompleteType="off"
                autoCapitalize="sentences"
                textStyle={{
                  ...styles.inputTextNotEditing,
                  color: "#8F9BB3",
                }}
                disabled
                placeholder="Last Name"
                value={businessOwnersData.primaryOwner?.lastName || ""}
              />
            </View>
          </View>
          <TextInputMask
            style={{
              ...styles.inputNotEditing,
              ...styles.inputTextNotEditing,
              ...(width > 576 ? styles.inputMask : styles.inputMaskMobile),
              color: "#8F9BB3",
              backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
              borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
            }}
            placeholder="Business Phone Number"
            autoCorrect={false}
            value={businessOwnersData.primaryOwner?.phoneNumber || ""}
            type="cel-phone"
            options={{
              withDDD: true,
              dddMask: "+1 (999) 999-9999",
            }}
          />
          <Input
            style={{
              ...styles.inputNotEditing,
              backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
              borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
            }}
            textStyle={{
              ...styles.inputTextNotEditing,
              color: "#8F9BB3",
            }}
            disabled
            value={businessOwnersData.primaryOwner?.email || ""}
            textContentType="emailAddress"
            autoCompleteType="email"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="email-address"
            placeholder="Work Email"
            accessoryRight={EmailIcon}
          />
        </View>
      </Collapsible>
      <TouchableWithoutFeedback
        onPress={() => setIsSecondaryOpen(!isSecondaryOpen)}
      >
        <View style={styles.flexRowBetween}>
          <Text style={styles.summarySubSubHeader}>Other Business Owners</Text>
          <Icon
            style={[
              styles.chevronButton,
              {
                transform: [{ rotate: isSecondaryOpen ? "180deg" : "0deg" }],
              },
            ]}
            name="chevron-down-outline"
            stroke="white"
            fill="white"
          />
        </View>
      </TouchableWithoutFeedback>
      <Divider style={styles.cardDivider} />
      <Collapsible collapsed={!isSecondaryOpen}>
        {"secondaryOwner" in businessOwnersData &&
          businessOwnersData.secondaryOwner.map((owner: any, index: number) => (
            <View>
              <View style={styles.flexRow}>
                <View style={{ flex: 1 }}>
                  <Input
                    style={{
                      ...styles.inputNotEditing,
                      backgroundColor:
                        theme === "light" ? "#F7F9FC" : "#222B45",
                      borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
                    }}
                    textStyle={{
                      ...styles.inputTextNotEditing,
                      color: "#8F9BB3",
                    }}
                    textContentType="name"
                    autoCompleteType="name"
                    autoCapitalize="sentences"
                    disabled
                    placeholder="First Name"
                    value={owner.firstName || ""}
                  />
                </View>
                <View style={{ flex: 1 }}>
                  <Input
                    style={{
                      ...styles.inputNotEditing,
                      backgroundColor:
                        theme === "light" ? "#F7F9FC" : "#222B45",
                      borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
                    }}
                    textContentType="familyName"
                    autoCompleteType="off"
                    autoCapitalize="sentences"
                    textStyle={{
                      ...styles.inputTextNotEditing,
                      color: "#8F9BB3",
                    }}
                    disabled
                    placeholder="Last Name"
                    value={owner.lastName || ""}
                  />
                </View>
              </View>
              <TextInputMask
                style={{
                  ...styles.inputNotEditing,
                  ...styles.inputTextNotEditing,
                  ...(width > 576 ? styles.inputMask : styles.inputMaskMobile),
                  color: "#8F9BB3",
                  backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
                  borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
                }}
                placeholder="Business Phone Number"
                autoCorrect={false}
                value={owner.phoneNumber || ""}
                type="cel-phone"
                options={{
                  withDDD: true,
                  dddMask: "+1 (999) 999-9999",
                }}
              />
              <Input
                style={{
                  ...styles.inputNotEditing,
                  backgroundColor: theme === "light" ? "#F7F9FC" : "#222B45",
                  borderColor: theme === "light" ? "#EDF1F7" : "#2E3A59",
                }}
                textStyle={{
                  ...styles.inputTextNotEditing,
                  color: "#8F9BB3",
                }}
                disabled
                value={owner.email || ""}
                textContentType="emailAddress"
                autoCompleteType="email"
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="email-address"
                placeholder="Work Email"
                accessoryRight={EmailIcon}
              />
              {index < businessOwnersData.secondaryOwner.length - 1 && (
                <Divider style={styles.cardDivider} />
              )}
            </View>
          ))}
      </Collapsible>
    </>
  );
};
