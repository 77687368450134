import {
  Text,
  useStyleSheet,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View } from "react-native";

import { VerticalBarChart } from "./VerticalBarChart";
import { themedStyles } from "./themedStyles";

const Legend: React.FC<any> = ({ legend, colors }) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={styles.legend}>
      {legend.map((category: any, idx: number) => (
        <View style={styles.flexRow} key={`legend-${idx}`}>
          <View
            style={{ ...styles.colorCircle, backgroundColor: colors[idx] }}
          />
          <Text>{category}</Text>
        </View>
      ))}
    </View>
  );
};

const RunningBalance: React.FC<any> = ({ data }) => {
  const styles = useStyleSheet(themedStyles);

  const [selectedQuarter, setSelectedQuarter] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<any>(new IndexPath(0));

  const [labels, setLabels] = useState(["January", "February", "March", "May"]);
  const [chartData, setChartData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (!data.length || !data[0].repaid) return;
    const a: any = [];
    let mx = 0;
    data.forEach((obj: any, idx: number) => {
      obj.repaid.forEach((el: any, idx2: number) => {
        mx = Math.max(mx, obj.outstanding[idx2], obj.repaid[idx2]);
      });
      if (idx !== selectedQuarter) return;
      setLabels(
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].slice((obj.quarter - 1) * 3, obj.quarter * 3)
      );
      obj.repaid.forEach((el: any, idx2: number) => {
        a.push([obj.outstanding[idx2], obj.repaid[idx2]]);
      });
    });
    setChartData(a);
    setMaxValue(Math.ceil(mx / 10 ** 6) * 10 ** 6);
  }, [data, selectedQuarter]);

  useEffect(() => {
    if (selectedIndex) {
      setSelectedQuarter(selectedIndex?.row);
    }
  }, [selectedIndex]);

  const colors = ["#1566db", "#55aaff"];

  return (
    <View>
      <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
        <Text style={styles.cardTitleText}>Running balance</Text>
        {!!data.length && (
          <Select
            style={{ margin: 5 }}
            placeholder="Month(s)/Week(s)/Day(s)"
            value={`Quarter - ${data[selectedQuarter].quarter} - ${data[selectedQuarter].year}`}
            selectedIndex={selectedIndex}
            onSelect={(index) => setSelectedIndex(index)}
          >
            {data.map((quarter: any, idx: number) => (
              <SelectItem
                style={styles.selectItem}
                title={() => (
                  <Text
                    style={styles.selectItem}
                  >{`Quarter - ${quarter.quarter} - ${quarter.year}`}</Text>
                )}
                key={`${idx}-quarter-option`}
              />
            ))}
          </Select>
        )}
      </View>
      <Legend
        legend={["Amount repaid", "Amount outstanding"]}
        colors={colors}
      />
      <VerticalBarChart
        chartHeight={300}
        xTitle="Months"
        yTitle="Amount in Dollars"
        colors={colors}
        data={chartData}
        labels={labels}
        dataFormat="$"
        maxYValue={maxValue}
      />
    </View>
  );
};

export default RunningBalance;
