import * as eva from "@eva-design/eva";
import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Modal,
  Text,
  ThemeProvider,
  useStyleSheet,
  Divider,
  Icon,
  Button,
  Input,
  Select,
  SelectItem,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect, useCallback } from "react";
import { View, TouchableOpacity } from "react-native";

import * as api from "../../services/api/index";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const LoanSettingsModal: React.FC<any> = ({
  isOpen,
  setIsOpen,
  applicationId,
}) => {
  const styles = useStyleSheet(themedStyles);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const [data, setData] = useState({
    plaidDataRefreshPeriod: "1",
    plaidDataRefreshUnit: "month(s)",
  });
  const plaidFrequencyOptions = ["month(s)", "week(s)", "day(s)", "never"];
  const [plaidFrequencySelectedIndex, setPlaidFrequencySelectedIndex] =
    useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { authStore } = useStore();

  useEffect(() => {
    if (plaidFrequencySelectedIndex) {
      setData({
        ...data,
        plaidDataRefreshUnit:
          plaidFrequencyOptions[plaidFrequencySelectedIndex.row],
      });
    }
  }, [plaidFrequencySelectedIndex]);

  const updateLoanSettings = () => {
    setLoading(true);
    api
      .updateLoanSettingsData(authStore?.accessToken || "", applicationId, data)
      .then((resp) => {
        console.log(resp.response.entities);
        setLoading(false);
      });
  };

  useFocusEffect(
    useCallback(() => {
      if (applicationId) {
        setLoading(true);
        api
          .getLoanSettingsData(authStore?.accessToken || "", applicationId)
          .then((resp) => {
            console.log(resp.response.entities);
            const { plaidOptions } = resp.response.entities;
            setData({
              ...data,
              plaidDataRefreshPeriod: plaidOptions.dataRefreshPeriod,
              plaidDataRefreshUnit: plaidOptions.dataRefreshUnit,
            });
            setLoading(false);
          });
      }
    }, [useIsFocused()])
  );

  return (
    <Modal
      visible={isOpen}
      onBackdropPress={() => setIsOpen(false)}
      style={styles.settingsModal}
      backdropStyle={styles.backdrop}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.modalHeaderContainer}>
          <Text style={styles.modalHeader}>Loan Settings</Text>
          <TouchableOpacity onPress={() => setIsOpen(false)}>
            <Icon width={24} height={24} name="close-outline" />
          </TouchableOpacity>
        </View>
        <Divider style={styles.cardDivider} />
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "stretch" }}
        >
          {loading ? (
            <View style={{ ...styles.container, alignItems: "center" }}>
              <LoadingIndicator />
            </View>
          ) : (
            <View style={{ width: 600, alignSelf: "center" }}>
              <Text
                style={{
                  fontFamily: "Montserrat500_Normal",
                  fontSize: 18,
                  lineHeight: 32,
                  textAlign: "center",
                  padding: 10,
                }}
              >
                Please indicate how frequent plaid data should be pulled while
                in closing
              </Text>
              <View style={styles.flexRow}>
                <Input
                  style={{ flex: 1, margin: 5 }}
                  value={data.plaidDataRefreshPeriod}
                  onChangeText={(text) =>
                    setData({
                      ...data,
                      plaidDataRefreshPeriod: Number.isNaN(Number(text))
                        ? data.plaidDataRefreshPeriod
                        : text,
                    })
                  }
                />
                <Select
                  style={{ flex: 1, margin: 5 }}
                  placeholder="Month(s)/Week(s)/Day(s)"
                  value={data.plaidDataRefreshUnit}
                  selectedIndex={plaidFrequencySelectedIndex}
                  onSelect={(index) => setPlaidFrequencySelectedIndex(index)}
                >
                  {plaidFrequencyOptions.map((option, idx) => (
                    <SelectItem
                      style={styles.selectItem}
                      title={() => (
                        <Text style={styles.selectItem}>{option}</Text>
                      )}
                      key={`${idx}-plaid-frequency-option`}
                    />
                  ))}
                </Select>
              </View>

              <View style={{ ...styles.flexRow, marginTop: 25 }}>
                <Button
                  style={styles.outlineButton}
                  appearance="outline"
                  status="primary"
                  onPress={() => updateLoanSettings()}
                >
                  SAVE
                </Button>
              </View>
            </View>
          )}
        </View>
      </ThemeProvider>
    </Modal>
  );
};

export default LoanSettingsModal;
