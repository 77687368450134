import { Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState, useCallback } from "react";
import { View } from "react-native";
import {
  saveSignedDocument,
  setDocumentAsCloSigned,
} from "../../services/api/docusign";
import { useStore } from "../../stores";

import { themedStyles } from "./themedStyles";

const LendingDocCloSignModalEnd: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  const applicationId =
    route?.params?.applicationId.split(":")[0] ||
    new URLSearchParams(window.location.search).get("applicationId");

  const [loading, setLoading] = useState(true);
  const [modalText, setModalText] = useState("");

  const { authStore } = useStore();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const respDocSave = await saveSignedDocument(
        authStore?.accessToken || "",
        {
          applicationId: applicationId,
          isHalfSigned: false,
        }
      );
      if (respDocSave.response.entities["fileUri"]) {
        const resp = await setDocumentAsCloSigned(
          authStore?.accessToken || "",
          {
            applicationId: applicationId,
          }
        );
        if (resp.response.entities) {
          if (resp.response.entities["success"]) {
            setModalText(
              "The DocuSign session has finished. You may now close this window."
            );
          } else if (resp.response.entities["expired"] === "expired") {
            setModalText("Document has expired. Please contact OppZo support.");
          } else {
            setModalText("Error saving document. Please try again.");
          }
        }
      } else {
        setModalText("Error saving document. Please try again.");
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <View style={styles.oneText}>
        <Text style={styles.header}>{modalText}</Text>
      </View>
    </>
  );
};

export default LendingDocCloSignModalEnd;
