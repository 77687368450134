import {
  Text,
  useStyleSheet,
  Divider,
  Select,
  SelectItem,
  Spinner,
  Button,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Dimensions, View } from "react-native";
import Collapsible from "react-native-collapsible";

import * as api from "../../services/api";
import { useStore } from "../../stores";
import { PlaidReconnectModal } from "../LoanView/PlaidReconnectModal";
import { BankLinkModal } from "../PreApproval/BankLinkModal";
import { themedStyles } from "./themedStyles";

export const BankAccountInfo: React.FC<any> = ({ theme = "dark", user }) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const LoadingIndicator = (props: any) => <Spinner />;

  const [loading, setLoading] = useState(false);

  const { authStore } = useStore();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [linkedBank, setLinkedBank] = useState({
    isLinked: false,
    accounts: [],
  });
  const [selectedFundingAccount, setSelectedFundingAccount] =
    useState<any>(null);
  const [fundingAccount, setFundingAccount] = useState("");
  const [needToReconnect, setNeedToReconnect] = useState(false);
  const [isReconnectPlaidModalOpen, setIsReconnectPlaidModalOpen] =
    useState(false);

  useEffect(() => {
    setLoading(true);
    api.getLinkedAccounts(user.accessToken || "").then((resp) => {
      setLoading(false);
      console.log(resp.response.entities);
      if (Object.values(resp.response.entities).length)
        setLinkedBank({
          isLinked: true,
          //@ts-ignore
          ...Object.values(resp.response.entities)[0],
        });
      setNeedToReconnect(
        !!Object.values(resp.response.entities).filter((obj) => obj.expired)
          .length
      );
      if (
        user.primaryAccountId &&
        Object.values(resp.response.entities).length
      ) {
        //@ts-ignore
        const acc = Object.values(resp.response.entities)[0].accounts.filter(
          (acc: any) => acc.id === user.primaryAccountId
        )[0];
        setFundingAccount(acc.name + " " + acc.mask);
      }
    });
  }, [linkedBank.isLinked]);

  useEffect(() => {
    if (selectedFundingAccount) {
      setLoading(true);
      api
        .postFundingAccount(
          user?.accessToken || "",
          linkedBank.accounts[selectedFundingAccount.row]
        )
        .then((resp) => {
          if (resp.response) {
            const acc: any = linkedBank.accounts[selectedFundingAccount.row];
            authStore.setPrimaryAccountId(acc.id);
            setFundingAccount(acc.name + " " + acc.mask);
            setLoading(false);
          }
        });
    }
  }, [selectedFundingAccount]);

  return (
    <View>
      <PlaidReconnectModal
        isOpen={isReconnectPlaidModalOpen}
        close={() => setIsReconnectPlaidModalOpen(false)}
      />
      <View style={styles.flexRowBetween}>
        <Text
          onPress={() => setIsCollapsed(!isCollapsed)}
          style={styles.summarySubHeader}
        >
          Funding Bank Account Information
        </Text>
        {!loading && needToReconnect && (
          <Button
            style={styles.cancelButton}
            onPress={() => setIsReconnectPlaidModalOpen(true)}
          >
            Reconnect Plaid
          </Button>
        )}
      </View>
      <Divider style={styles.divider} />
      <Collapsible collapsed={isCollapsed}>
        {loading && <LoadingIndicator />}
        {!loading && (
          <BankLinkModal
            cb={(linkSuccess, newlyLinkedBank) => {
              if (linkSuccess)
                setLinkedBank({
                  ...linkedBank,
                  ...newlyLinkedBank,
                  isLinked: linkSuccess,
                });
            }}
            linkedBank={linkedBank}
          />
        )}
        {!loading && linkedBank.isLinked && (
          <>
            <Text style={styles.inputLabel}>
              Please select the account that is funding the loans.
            </Text>
            <Select
              status={theme === "dark" ? "primary" : "success"}
              size={width > 576 ? "medium" : "small"}
              style={styles.input}
              selectedIndex={selectedFundingAccount}
              value={fundingAccount}
              onSelect={(index) => setSelectedFundingAccount(index)}
            >
              {linkedBank.accounts.map((account: any) => (
                <SelectItem
                  key={account.name}
                  title={account.name + " " + account.mask}
                />
              ))}
            </Select>
          </>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
