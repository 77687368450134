import { StyleService } from "@ui-kitten/components";
import { Dimensions } from "react-native";

export const themedStyles = StyleService.create({
  container: {},
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: 30,
    marginVertical: 10,
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    border: "none",
  },
  UWPortalButton: {
    paddingVertical: 12,
    paddingHorizontal: 18,
    background: "linear-gradient(97.13deg, #C5CEE0 99%, #C5CEE1 100%)",
    border: "none",
  },
  buttonText: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  flexRow: { flexDirection: "row" },
  flexRowItemsCenter: { flexDirection: "row", alignItems: "center" },
  menuContainer: {
    flex: 1,
    flexDirection: "row",
    width: Dimensions.get("window").width,
  },
  menu: {
    padding: 10,
    background: "background-basic-color-1",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    flex: 1,
    height: Dimensions.get("window").height,
  },
  backdrop: {
    height: Dimensions.get("window").height,
    flex: 4,
    backgroundColor: "#0007",
  },
  backdropMedium: {
    height: Dimensions.get("window").height,
    flex: 2,
    backgroundColor: "#0007",
  },
  backdropSmall: {
    height: Dimensions.get("window").height,
    flex: 1,
    backgroundColor: "#0007",
  },
  backdropMobile: {
    height: Dimensions.get("window").height,
    flex: 0.25,
    backgroundColor: "#0007",
  },
  menuTextHeader: {
    fontFamily: "Montserrat_700Bold",
    textAlign: "center",
    lineHeight: 24,
    fontSize: 18,
  },
  menuTextSubheader: {
    fontFamily: "Montserrat_600SemiBold",
    textAlign: "left",
    lineHeight: 30,
    fontSize: 12,
    textTransform: "uppercase",
    marginLeft: 10,
    marginVertical: 10,
  },
  menuTextDescription: {
    fontFamily: "SFProTextRegular",
    lineHeight: 24,
    textAlign: "center",
    fontSize: 13,
  },
  menuUserDescContainer: {
    paddingVertical: 30,
  },
  menuItem: {
    flex: 1,
  },
  notificationIcon: {
    padding: 10,
    marginHorizontal: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tooltipTextDark: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "black",
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
  },
  notificationCircle: {
    position: "absolute",
    width: 9,
    height: 9,
    borderRadius: 5,
    top: 10,
    right: 12,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    zIndex: 1,
  },
  notificationsContainer: {
    width: 250,
    maxHeight: 500,
    flexDirection: "column",
    padding: 5,
  },
  notificationDivider: {
    flex: 1,
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
    paddingVertical: 1,
    marginVertical: 10,
  },
  menuItemHeader: {
    flex: 1,
    paddingLeft: 0,
    color: "#253858",
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 12,
    lineHeight: 25,
  },
  activeNotificationCircle: {
    width: 10,
    height: 10,
    background: "color-success-default",
    borderRadius: 50,
    margin: 1,
    marginRight: 10,
  },
});
