import { useNavigation } from "@react-navigation/native";
import {
  Layout,
  Text,
  useStyleSheet,
  Button,
  Divider,
  Modal,
  Spinner,
  Select,
  SelectItem,
  Input,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import * as WebBrowser from "expo-web-browser";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import config from "../../config";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import { BusinessDetails } from "./BusinessDetails";
import { FullApplicationDetails } from "./FullApplicationDetails";
import { OtherBusinessOwnersDetails } from "./OtherBusinessOwnersDetails";
import { PlaidReconnectModal } from "./PlaidReconnectModal";
import { Prequal1Details } from "./Prequal1Details";
import { Prequal2Details } from "./Prequal2Details";
import { TrackerStep } from "./TrackerStep";
import { themedStyles } from "./themedStyles";
export type Props = object;

const LoanWithdrawFeedbackModal: React.FC<any> = ({
  withdrawApp,
  isOpen,
  close,
}) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();
  const [formData, setFormData] = useState({
    withdrawalReason: "",
    feedback: "",
  });
  const [withdrawalDate, setWithdrawalDate] = useState<null | Date>(null);
  const [selectedIndex, setSelectedIndex]: any = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const withdrawalReasons = [
    "I've received financial help from somewhere else.",
    "Inability to repay the final loan cost.",
    "The application process is confusing.",
    "I can't provide the requested information.",
    "Other",
  ];

  useEffect(() => {
    if (selectedIndex) {
      setFormData({
        ...formData,
        withdrawalReason: withdrawalReasons[selectedIndex.row],
      });
    }
  }, [selectedIndex]);

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.modalBackdrop}
      onBackdropPress={() => close()}
    >
      <View style={styles.modalContainer}>
        {submitted && (
          <>
            <Text style={{ ...styles.modalText, marginVertical: 5 }}>
              Thank you! Your loan application will be withdrawn effective{" "}
              {/* @ts-ignore */}
              {withdrawalDate
                ? withdrawalDate.toLocaleString("en-US").split(",")[0]
                : ""}{" "}
            </Text>
            <Text style={{ ...styles.modalText, marginVertical: 5 }}>
              You can re-apply anytime.{" "}
            </Text>
            <Text style={{ ...styles.modalText, marginVertical: 5 }}>
              Your account information will be saved for 12 months{" "}
            </Text>
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={styles.modalNoButton}
                onPress={() => {
                  close();
                  navigation.navigate("dashboard");
                }}
              >
                <Text style={styles.modalButtonText}>Close</Text>
              </TouchableOpacity>
            </View>
          </>
        )}
        {!submitted && (
          <>
            <Text style={{ ...styles.modalText, marginVertical: 5 }}>
              Tell us why you’re withdrawing your application
            </Text>
            <Select
              style={styles.input}
              placeholder="selection"
              value={formData.withdrawalReason}
              selectedIndex={selectedIndex}
              onSelect={(index: any) => setSelectedIndex(index)}
            >
              {withdrawalReasons.map((option, idx) => (
                <SelectItem
                  title={option}
                  key={`${idx}-withdrawal-reason-option`}
                />
              ))}
            </Select>
            <Text style={{ ...styles.modalText, marginVertical: 5 }}>
              Your feedback matters. Is there anything else you’d like us to
              know?
            </Text>
            <Input
              multiline
              numberOfLines={5}
              style={styles.input}
              placeholder="Input Text"
              status="basic"
              value={formData.feedback}
              onChangeText={(text: any) =>
                setFormData({ ...formData, feedback: text })
              }
            />
            <View style={styles.modalRow}>
              <TouchableOpacity
                style={
                  formData.withdrawalReason
                    ? styles.modalNoButton
                    : styles.modalYesButton
                }
                disabled={!formData.withdrawalReason}
                onPress={async () => {
                  await withdrawApp(formData);
                  const d = new Date();
                  d.setDate(d.getDate() + 3);
                  setWithdrawalDate(d);
                  setSubmitted(true);
                }}
              >
                <Text style={styles.modalButtonText}>Submit</Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </Modal>
  );
};

const LoanActivateModal: React.FC<any> = ({
  activateApp,
  isOpen,
  close,
  loading,
}) => {
  const styles = useStyleSheet(themedStyles);
  const LoadingIndicator = (_props: any) => (
    <Spinner {..._props} status="primary" size="tiny" />
  );

  const { authStore } = useStore();

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.modalBackdrop}
      onBackdropPress={() => close()}
    >
      <View style={styles.modalContainer}>
        <Text style={styles.modalText}>
          {authStore.user?.isPrimaryApplicant
            ? "Are you sure you want to activate your application?"
            : "Only the Primary Applicant can activate the loan application."}
        </Text>
        <View style={styles.modalRow}>
          {authStore.user?.isPrimaryApplicant && (
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => activateApp()}
            >
              <Text style={styles.modalButtonText}>
                Yes {loading ? "   " : ""}
                {loading ? LoadingIndicator(styles.iconSmall) : ""}
              </Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={styles.modalNoButton}
            onPress={() => close()}
          >
            <Text style={styles.modalButtonText}>
              {authStore.user?.isPrimaryApplicant ? "No, Go back" : "Go back"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const LoanView: React.FC<any> = ({ route }) => {
  const { width } = Dimensions.get("window");
  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const navigation = useNavigation();

  const styles = useStyleSheet(themedStyles);
  const middleStepWhite = require("../../../assets/ArrowStepperMiddle.svg");
  const middleStepInProgress = require("../../../assets/ArrowStepperMiddleInProgress.svg");
  const middleStepDone = require("../../../assets/ArrowStepperMiddleDone.svg");
  const leftStepDone = require("../../../assets/ArrowStepperLeftDone.svg");
  const rightStepWhite = require("../../../assets/ArrowStepperRight.svg");
  const rightStepInProgress = require("../../../assets/ArrowStepperRightInProgress.svg");
  const rightStepDone = require("../../../assets/ArrowStepperRightDone.svg");
  const middleStepWhiteMobile = require("../../../assets/ArrowStepperMiddleMobile.svg");
  const middleStepInProgressMobile = require("../../../assets/ArrowStepperMiddleInProgressMobile.svg");
  const middleStepDoneMobile = require("../../../assets/ArrowStepperMiddleDoneMobile.svg");
  const leftStepDonMobile = require("../../../assets/ArrowStepperLeftDoneMobile.svg");
  const rightStepWhiteMobile = require("../../../assets/ArrowStepperRightMobile.svg");
  const rightStepDoneMobile = require("../../../assets/ArrowStepperRightDoneMobile.svg");
  const LoadingIndicator = (_props: any) => (
    <Spinner {..._props} status="primary" size="tiny" />
  );
  const ReconnectQuickbooks = (_props: any) => (
    <TouchableOpacity
      style={styles.quickBooksButton}
      onPress={() => ReconnectQuickbooksAPI(id)}
    >
      <Text style={styles.withdrawButtonText}>Reconnect Quickbooks</Text>
    </TouchableOpacity>
  );

  const [isReconnectPlaidModalOpen, setIsReconnectPlaidModelOpen] =
    useState(false);

  const ReconnectPlaidButton = (_props: any) => (
    <TouchableOpacity
      style={styles.withdrawButton}
      onPress={() => setIsReconnectPlaidModelOpen(true)}
    >
      <Text style={styles.withdrawButtonText}>Reconnect Plaid</Text>
    </TouchableOpacity>
  );

  const possibleStatus = {
    Registered: 0,
    PreQualInProgress: 1,
    PreQualDone: 2,
    PreApprovalInProgress: 3,
    PreApprovalDone: 4,
    FullApplicationInProgress: 5,
    FullApplicationDone: 6,
    Unqualified: 7,
    Approved: 8,
    Rejected: 9,
    SoftRejected: 10,
    Funded: 11,
    PreQualRejected: 12,
    PreApprovalRejected: 13,
    FullRejected: 14,
    Withdrawn: 15,
  };

  const [currentStatus, setCurrentStatus] = useState<number>(
    possibleStatus.Registered
  );
  const { authStore, loanStore, businessStore, mboStore } = useStore();
  const [loan, setLoan] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [reconnectQb, setReconnectQb] = useState(false);

  const ReconnectQuickbooksAPI = async (loanId: string | null) => {
    if (loanId) {
      const query = new URLSearchParams({ loanId });
      try {
        const result = await WebBrowser.openAuthSessionAsync(
          `${config.urls.api}${config.urls.quickbooks.oauth}?${query}`,
          "/qb-redirect"
        );
        setReconnectQb(result.type !== "success");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isContinueBtnHidden, setIsContinueBtnHidden] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  useEffect(() => {
    if (!businessStore.business.size) {
      businessStore.fetchBusinesses();
    }
    if (!mboStore.mboInfo.size) {
      mboStore.fetchMBOInfo();
    }
    if (!loanStore.loanApplications.size) {
      loanStore.fetchLoanApplications();
    } else {
      let flag = false;
      let doesBusinessHaveUnfinishedLoans = false;

      loanStore.loanApplications.forEach((loan) => {
        if (authStore.user?.businesses?.includes(loan.business)) {
          if (
            ![
              "",
              "prequal1InProgress",
              "prequal1",
              "registered",
              "done",
            ].includes(loan?.status || "") &&
            !loan.status?.toLowerCase().includes("rejected")
          )
            doesBusinessHaveUnfinishedLoans = true;
        }
      });

      loanStore.loanApplications.forEach((loan) => {
        if (loan.id === Number(id)) {
          flag = true;
          if (authStore.user?.businesses?.includes(loan.business)) {
            setLoan(loan.$);

            businessStore.business.forEach((business) => {
              if (business.id === loan.business) {
                setBusiness(business.$);
                console.log(business.$);
              }
            });
          } else {
            navigation.navigate("dashboard");
          }
        }
      });

      if (!doesBusinessHaveUnfinishedLoans)
        setCanEditPersonalAndBusinessInfo(true);
      if (!flag) {
        navigation.navigate("dashboard");
      }
    }
  }, [route, loanStore.loanApplications, businessStore.business]);

  useEffect(() => {
    (async () => {
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (!res1.response.entities) {
        navigation.navigate("dashboard");
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (!res2.response.entities) {
        navigation.navigate("dashboard");
      }
    })();
  }, []);

  useEffect(() => {
    loanStore.loanApplications.forEach((loan) => {
      if (loan.id === Number(id)) {
        setLoan({ ...loan.$ });
      }
    });
  }, [loanStore]);

  useEffect(() => {
    if (loan) {
      setReconnectQb(loan.needToReconnectQuickbooks);
      if (loan.isFinalPullEquifaxCommercial)
        setCanEditPersonalAndBusinessInfo(true);
    }
  }, [loan]);

  useEffect(() => {
    switch (loan.status) {
      case "prequal1InProgress":
      case "prequal1":
        setCurrentStatus(possibleStatus.PreQualInProgress);
        break;
      case "prequal2InProgress":
      case "prequal2":
        setCurrentStatus(possibleStatus.PreApprovalInProgress);
        // Applicant already done filling in Prequal2 if signed 8821 form exists since it is the last step
        if (loan.signed8821Form) setIsContinueBtnHidden(true);
        break;
      case "fullInProgress":
      case "full":
        setCurrentStatus(possibleStatus.FullApplicationInProgress);
        // Applicant already done filling in required fields for Full Application step
        if (
          loan.byLaws &&
          loan.corporateOrAuthorizingResolution &&
          loan.oneYearContractBacklog &&
          loan.contractingOfficerEmail &&
          (loan.resumes || loan.linkedIn || authStore.user?.linkedIn) &&
          authStore.user?.managementExperience &&
          loan.driversLicense
        )
          setIsContinueBtnHidden(true);
        break;

      // Rejection status
      case "prequal1Reject":
        setCurrentStatus(possibleStatus.PreQualRejected);
        break;
      case "prequal1SoftReject":
        setCurrentStatus(possibleStatus.PreQualRejected);
        break;
      case "prequal2Reject":
        setCurrentStatus(possibleStatus.PreApprovalRejected);
        break;
      case "prequal2SoftReject":
        setCurrentStatus(possibleStatus.PreApprovalRejected);
        break;
      case "fullReject":
        setCurrentStatus(possibleStatus.FullRejected);
        break;
      case "fullSoftReject":
        setCurrentStatus(possibleStatus.FullRejected);
        break;

      // Other status
      case "unqualified":
        setCurrentStatus(possibleStatus.Unqualified);
        break;
      case "approved":
        setCurrentStatus(possibleStatus.Approved);
        break;
      case "rejected":
        setCurrentStatus(possibleStatus.Rejected);
        break;
      case "softRejection":
        setCurrentStatus(possibleStatus.SoftRejected);
        break;
      case "funded":
        setCurrentStatus(possibleStatus.Funded);
        break;
      case "withdrawn":
        setCurrentStatus(possibleStatus.Withdrawn);
        break;
      default:
        setCurrentStatus(possibleStatus.Registered);
        break;
    }
  }, [loan]);

  const defaultErrors = {
    nonFieldErrors: "",
  };

  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState({
    ...defaultErrors,
  });

  const activateApp = async () => {
    setLoading(true);
    try {
      const res = await loanStore.updateLoanApplication(loan.id, {
        withdrawalStartDate: null,
      });
      if (res?.ok) {
        loanStore.fetchLoanApplications().then(() => {
          setIsActivateApplicationModalOpen(false);
          setLoading(false);
          navigation.navigate("dashboard");
        });
      }
    } catch (err: any) {
      setWithdrawErrorMessage(JSON.parse(err.message));
      setLoading(false);
    }
  };

  const withdrawApp = async (formData: any) => {
    setLoading(true);
    try {
      loanStore.deleteLoanApplication(loan.id, formData).then(() => {
        loanStore.fetchLoanApplications().then(() => {
          setOpenModal(false);
          setLoading(false);
          // navigation.navigate("dashboard");
        });
      });
    } catch (err: any) {
      setWithdrawErrorMessage(JSON.parse(err.message));
      setLoading(false);
    }
  };

  const getLoanTrackerStatusMessage = () => {
    if (authStore.user?.isPrimaryApplicant) {
      switch (currentStatus) {
        case possibleStatus.Registered:
          return (
            <Text style={styles.loanStatusMessage}>
              <Text style={styles.congratsTextMessage}>Congratulations!</Text>
              {"\n"}Based on the information you provided, you are allowed to
              proceed to the next steps. The next step is prequalification.
              Prequalification does not guarantee your loan will be approved or
              the timing of the full application process. Please refer to the
              Terms of Use for more information.
            </Text>
          );
        case possibleStatus.PreQualInProgress:
        case possibleStatus.PreQualDone:
          return (
            <Text style={styles.loanStatusMessage}>
              Your application is currently pending pre-approval. Once you
              receive a pre-approval{" "}
              <Text style={{ ...styles.loanStatusMessage, ...styles.bold }}>
                email or notification
              </Text>
              , you may proceed to the rest of the application process. Please
              check this page for updates. Thank you!
            </Text>
          );
        case possibleStatus.PreApprovalInProgress:
          return (
            <>
              <Text style={styles.congratsTextMessage}>
                Congratulations!{"\n"}
              </Text>
              <Text style={styles.loanStatusMessage}>
                Based on the information you provided,{"\n"}you are allowed to
                proceed to the next steps.{"\n"}
              </Text>
              <Text style={{ ...styles.loanStatusMessage, fontWeight: "700" }}>
                The next step is to complete your loan application.
              </Text>
            </>
          );

        case possibleStatus.PreApprovalDone:
          return (
            <Text style={styles.loanStatusMessage}>
              Your application is currently pending for full application. Once
              you receive a full application letter, you may now proceed to the
              Full application process. You can check on this page to stay
              updated.
            </Text>
          );
        case possibleStatus.FullApplicationInProgress:
          return (
            <>
              <Text style={styles.congratsTextMessage}>
                Congratulations!{"\n"}
              </Text>
              <Text style={styles.loanStatusMessage}>
                Based on the information you provided,{"\n"}you are allowed to
                proceed to the next steps.{"\n"}
                Click on the button below to start filling in the
                Full-Application form.
              </Text>
            </>
          );
        case possibleStatus.FullApplicationDone:
          return <Text style={styles.loanStatusMessage}>Congratulations!</Text>;
        case possibleStatus.Approved:
          return (
            <>
              <Text style={styles.congratsTextMessage}>
                Congratulations, your loan has been approved!{"\n"}
              </Text>
              <Text style={styles.loanStatusMessage}>
                To continue, please review and sign the terms and conditions
                accessible through your OppZo dashboard by clicking the link
                below.
              </Text>
            </>
          );
        case possibleStatus.PreQualRejected:
        case possibleStatus.PreApprovalRejected:
        case possibleStatus.FullRejected:
        case possibleStatus.Rejected:
        case possibleStatus.SoftRejected:
          return (
            <Text style={styles.loanStatusMessage}>
              Based on your application, your business does not qualify for a
              loan at this time.{"\n"}To know more about the details, please
              reach out to our support team.
            </Text>
          );
        case possibleStatus.Funded:
          return (
            <Text style={styles.loanStatusMessage}>*Congratulations!</Text>
          );
        case possibleStatus.Withdrawn:
          return (
            <Text style={styles.loanStatusMessage}>
              Your Loan has been withdrawn.
            </Text>
          );
        default:
          return <Text style={styles.loanStatusMessage}>...</Text>;
      }
    } else {
      switch (currentStatus) {
        case possibleStatus.Registered:
        case possibleStatus.PreQualInProgress:
        case possibleStatus.PreQualDone:
        case possibleStatus.PreApprovalInProgress:
          return (
            <>
              {/* <Text style={styles.loanStatusMessage}>
                Thanks for your information. Your business will receive an email
                or dashboard notice when your application is ready to proceed.
                {"\n"}
                Please stay put as we will soon need information from you. You
                will be able to enter your info once your primary applicant is
                done with the Pre-Approval step. You can check on this page to
                stay updated.
              </Text> */}

              <Text style={styles.loanStatusMessage}>
                Please stay put, as we may need more information from you. Your
                business will receive an email or a dashboard notice when your
                application is ready to proceed.
              </Text>
            </>
          );
        case possibleStatus.PreApprovalDone:
        case possibleStatus.FullApplicationInProgress:
          return (
            <Text style={styles.loanStatusMessage}>
              Your primary applicant has completed the Pre-Approval step, but we
              need additional information from you to proceed to the next steps.
              Please click the button below and fill in the necessary
              information.
            </Text>
          );
        case possibleStatus.FullApplicationDone:
          return <Text style={styles.loanStatusMessage}>Congratulations!</Text>;
        case possibleStatus.Approved:
          return (
            <>
              <Text style={styles.congratsTextMessage}>
                Congratulations, your loan has been approved!{"\n"}
              </Text>
              <Text style={styles.loanStatusMessage}>
                To continue, please review and sign the terms and conditions
                accessible through your OppZo dashboard by clicking the link
                below.
                {/* {{ button_url }} */}
              </Text>
            </>
          );
        case possibleStatus.PreQualRejected:
        case possibleStatus.PreApprovalRejected:
        case possibleStatus.FullRejected:
        case possibleStatus.Rejected:
        case possibleStatus.SoftRejected:
          return (
            <Text style={styles.loanStatusMessage}>
              Based on your application, your business does not qualify for a
              loan at this time.{"\n"}To know more about the details, please
              reach out to our support team.
            </Text>
          );
        case possibleStatus.Funded:
          return (
            <Text style={styles.loanStatusMessage}>*Congratulations!</Text>
          );
        default:
          return <Text style={styles.loanStatusMessage}>...</Text>;
      }
    }
  };

  const defaultScale = { width: 210, height: 53 };
  const defaultScaleRight = { width: 190, height: 53 };
  const mobileScale = { width: 120, height: 45 };
  const mobileScaleRight = { width: 100, height: 45 };

  const RegisterStep = (
    <TrackerStep
      status="done"
      imageDone={width > 768 ? leftStepDone : leftStepDonMobile}
      scale={width > 768 ? defaultScale : mobileScale}
      text="Account Creation "
    />
  );

  const PreQualStep = (
    <TrackerStep
      status={
        currentStatus === possibleStatus.PreQualRejected
          ? "reject"
          : currentStatus >= possibleStatus.PreQualDone
          ? "done"
          : currentStatus === possibleStatus.PreQualInProgress
          ? "inProgress"
          : "-"
      }
      imageDone={width > 768 ? middleStepDone : middleStepDoneMobile}
      imageInProgress={
        width > 768 ? middleStepInProgress : middleStepInProgressMobile
      }
      image={width > 768 ? middleStepWhite : middleStepWhiteMobile}
      scale={width > 768 ? defaultScale : mobileScale}
      text="  Pre-Qualification "
    />
  );

  const PreApprovalStep = (
    <TrackerStep
      status={
        currentStatus === possibleStatus.PreApprovalRejected
          ? "reject"
          : currentStatus >= possibleStatus.PreQualRejected &&
            currentStatus < possibleStatus.PreApprovalRejected
          ? "-"
          : currentStatus >= possibleStatus.PreApprovalDone
          ? "done"
          : currentStatus === possibleStatus.PreApprovalInProgress
          ? "inProgress"
          : "-"
      }
      imageDone={width > 768 ? middleStepDone : middleStepDoneMobile}
      imageInProgress={
        width > 768 ? middleStepInProgress : middleStepInProgressMobile
      }
      image={width > 768 ? middleStepWhite : middleStepWhiteMobile}
      scale={width > 768 ? defaultScale : mobileScale}
      text="  Pre-Approval "
    />
  );

  const FullApplicationStep = (
    <TrackerStep
      status={
        currentStatus === possibleStatus.FullRejected
          ? "reject"
          : currentStatus >= possibleStatus.PreQualRejected &&
            currentStatus < possibleStatus.FullRejected
          ? "-"
          : currentStatus >= possibleStatus.FullApplicationDone
          ? "done"
          : currentStatus === possibleStatus.FullApplicationInProgress
          ? "inProgress"
          : "-"
      }
      imageDone={width > 768 ? rightStepDone : rightStepDoneMobile}
      imageInProgress={width > 768 ? rightStepInProgress : rightStepWhiteMobile}
      image={width > 768 ? rightStepWhite : rightStepWhiteMobile}
      scale={width > 768 ? defaultScaleRight : mobileScaleRight}
      text="  Approval "
    />
  );

  const [openModal, setOpenModal] = useState<any>();
  const rejectedStatus =
    (currentStatus >= possibleStatus.PreQualRejected &&
      currentStatus <= possibleStatus.FullRejected) ||
    currentStatus === possibleStatus.Rejected ||
    currentStatus === possibleStatus.SoftRejected;

  const [isWithdrawApplicationModalOpen, setIsWithdrawApplicationModalOpen] =
    useState(false);
  const [isActivateApplicationModalOpen, setIsActivateApplicationModalOpen] =
    useState(false);
  const [canEditPersonalAndBusinessInfo, setCanEditPersonalAndBusinessInfo] =
    useState(false);

  return (
    <Layout style={styles.container}>
      <LoanWithdrawFeedbackModal
        isOpen={isWithdrawApplicationModalOpen}
        close={() => {
          setIsWithdrawApplicationModalOpen(false);
          setOpenModal(false);
        }}
        withdrawApp={withdrawApp}
      />
      <LoanActivateModal
        isOpen={isActivateApplicationModalOpen}
        close={() => {
          setIsActivateApplicationModalOpen(false);
        }}
        activateApp={activateApp}
        loading={loading}
      />
      <PlaidReconnectModal
        isOpen={isReconnectPlaidModalOpen}
        close={() => setIsReconnectPlaidModelOpen(false)}
      />
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Modal
          visible={openModal}
          backdropStyle={styles.modalBackdrop}
          onBackdropPress={() => setOpenModal(false)}
        >
          <View style={styles.modalContainer}>
            <Text style={styles.modalText}>
              {authStore.user?.isPrimaryApplicant
                ? "Are you sure you want to withdraw your application?"
                : "Only the Primary Applicant can withdraw the loan application."}
            </Text>
            <View style={styles.modalRow}>
              {authStore.user?.isPrimaryApplicant && (
                <TouchableOpacity
                  style={styles.modalYesButton}
                  onPress={() => setIsWithdrawApplicationModalOpen(true)}
                >
                  <Text style={styles.modalButtonText}>
                    Yes
                    {loading ? "   " : ""}
                    {loading ? LoadingIndicator(styles.iconSmall) : ""}
                  </Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={styles.modalNoButton}
                onPress={() => setOpenModal(false)}
              >
                <Text style={styles.modalButtonText}>
                  {authStore.user?.isPrimaryApplicant
                    ? "No, Go back"
                    : "Go back"}
                </Text>
              </TouchableOpacity>
            </View>
            <Text style={styles.error}>
              {withdrawErrorMessage.nonFieldErrors}
            </Text>
          </View>
        </Modal>

        <Header />
        <View style={styles.loanTrackerProgressBarContainer}>
          <View style={styles.loanTrackerProgressBar}>
            <Text style={styles.header}>LOAN TRACKER</Text>
            <View
              style={
                width > 768
                  ? styles.stepsContainer
                  : styles.stepsContainerMobile
              }
            >
              {RegisterStep}
              {width > 768 && PreQualStep}
              {PreApprovalStep}
              {FullApplicationStep}
            </View>
            <View style={styles.loanStatusMessageContainer}>
              {getLoanTrackerStatusMessage()}
              {currentStatus === possibleStatus.Registered &&
                authStore.user?.isPrimaryApplicant && (
                  <Button
                    style={styles.loanTrackerActionButton}
                    onPress={() =>
                      navigation.navigate("pre-qualification", { id })
                    }
                  >
                    APPLY FOR A LOAN NOW
                  </Button>
                )}
              {currentStatus === possibleStatus.PreApprovalInProgress &&
                authStore.user?.isPrimaryApplicant &&
                !isContinueBtnHidden && (
                  <Button
                    style={styles.loanTrackerActionButton}
                    onPress={() => {
                      navigation.navigate("pre-approval", { id });
                    }}
                  >
                    CONTINUE APPLICATION
                  </Button>
                )}
              {currentStatus === possibleStatus.FullApplicationInProgress &&
                authStore.user?.isPrimaryApplicant &&
                !isContinueBtnHidden && (
                  <Button
                    style={styles.loanTrackerActionButton}
                    onPress={() =>
                      navigation.navigate("full-application", { id })
                    }
                  >
                    GO TO FULL APPLICATION PAGE
                  </Button>
                )}
              {currentStatus === possibleStatus.Approved &&
                authStore.user?.isPrimaryApplicant && (
                  <Button
                    style={styles.loanTrackerActionButton}
                    onPress={() => {
                      navigation.navigate("closing-documents");
                    }}
                  >
                    SIGN THE TERMS AND CONDITIONS
                  </Button>
                )}
              {rejectedStatus && (
                <Button
                  style={styles.loanTrackerActionButton}
                  onPress={() => {
                    window.open("mailto:support@getoppzo.com");
                  }}
                >
                  CONTACT OPPZO
                </Button>
              )}
              {!authStore.user?.isPrimaryApplicant &&
                ([
                  possibleStatus.PreApprovalDone,
                  possibleStatus.FullApplicationInProgress,
                ].includes(currentStatus) ||
                  (possibleStatus.PreApprovalInProgress === currentStatus &&
                    loan.isPrequal2DoneByPrimaryApplicant)) && (
                  <Button
                    style={styles.loanTrackerActionButton}
                    onPress={() => {
                      navigation.navigate("mbo-prequal", { id });
                    }}
                  >
                    CONTINUE APPLICATION
                  </Button>
                )}
            </View>
            {__DEV__ && (
              <View style={{ flexDirection: "row" }}>
                <Button
                  onPress={() => {
                    setCurrentStatus(Math.max(0, currentStatus - 1));
                  }}
                >
                  Prev
                </Button>
                <Button
                  onPress={() => {
                    setCurrentStatus(Math.min(currentStatus + 1, 6));
                  }}
                >
                  Nxt
                </Button>
              </View>
            )}
          </View>
        </View>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={["#D012B2", "#8A1CDB", "#5327FF", "#1D84FF", "#26DDF1"]}
          style={
            width > 992
              ? styles.loanViewContainer
              : styles.loanViewContainerMobile
          }
        >
          <View style={width > 992 ? styles.loanView : styles.loanViewMobile}>
            <View
              style={width > 992 ? styles.loanHeader : styles.loanHeaderMobile}
            >
              <View style={styles.loanHeaderText}>
                <Text style={styles.subHeader}>Loan Details</Text>
                <Text style={styles.description}>
                  Here's an overview of your application.
                </Text>
              </View>
              <View style={width > 992 ? styles.flexRow : styles.flexRowMobile}>
                <TouchableOpacity
                  style={styles.withdrawButton}
                  onPress={() => navigation.navigate("request-center", { id })}
                >
                  <Text style={styles.withdrawButtonText}>Request Center</Text>
                </TouchableOpacity>
                <View style={styles.gap} />
                {![
                  possibleStatus.Approved,
                  possibleStatus.Funded,
                  possibleStatus.Withdrawn,
                ].includes(currentStatus) &&
                  !loan.withdrawalStartDate && (
                    <TouchableOpacity
                      style={styles.withdrawButton}
                      onPress={() => setOpenModal(true)}
                    >
                      <Text style={styles.withdrawButtonText}>
                        Withdraw Application
                      </Text>
                    </TouchableOpacity>
                  )}

                {currentStatus !== possibleStatus.Withdrawn &&
                  loan.withdrawalStartDate !== null && (
                    <TouchableOpacity
                      style={styles.withdrawButton}
                      onPress={() => setIsActivateApplicationModalOpen(true)}
                    >
                      <Text style={styles.withdrawButtonText}>
                        Activate Application
                      </Text>
                    </TouchableOpacity>
                  )}
              </View>
            </View>
            <Divider style={styles.divider} />
            <BusinessDetails
              theme="dark"
              business={business}
              canEdit={
                Number(business.businessOwner) === Number(authStore.user?.id) &&
                canEditPersonalAndBusinessInfo
              }
            />
            {currentStatus > possibleStatus.Registered && (
              <Prequal1Details
                business={business}
                loan={loan}
                canEdit={
                  Number(business.businessOwner) ===
                    Number(authStore.user?.id) &&
                  currentStatus < possibleStatus.PreQualDone
                }
              />
            )}
            {currentStatus > possibleStatus.PreQualDone && (
              <Prequal2Details
                business={business}
                loan={loan}
                canEdit={
                  Number(business.businessOwner) ===
                    Number(authStore.user?.id) &&
                  currentStatus < possibleStatus.PreApprovalDone
                }
                buttons={
                  currentStatus >= possibleStatus.PreApprovalInProgress &&
                  Number(business.businessOwner) === Number(authStore.user?.id)
                    ? reconnectQb
                      ? [ReconnectQuickbooks, ReconnectPlaidButton]
                      : [ReconnectPlaidButton]
                    : []
                }
              />
            )}
            {currentStatus > possibleStatus.PreApprovalDone && (
              <FullApplicationDetails
                business={business}
                loan={loan}
                canEdit={
                  Number(business.businessOwner) ===
                    Number(authStore.user?.id) &&
                  currentStatus < possibleStatus.FullApplicationDone
                }
              />
            )}
            <OtherBusinessOwnersDetails business={business} loan={loan} />
            <Text>
              Note: Certain information will be automatically locked once it is
              passed through our checks.
            </Text>
          </View>
        </LinearGradient>
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default LoanView;
