import AsyncStorage from "@react-native-async-storage/async-storage";
import { cloneDeep } from "lodash";
import { observable } from "mobx";
import {
  applySnapshot,
  getSnapshot,
  Model,
  model,
  modelFlow,
  prop,
  _async,
  _await,
} from "mobx-keystone";

import config from "../config";
import AuthStore from "./AuthStore";
import BusinessStore from "./BusinessStore";
import DocumentRequestsStore from "./DocumentRequestsStore";
import LoanStore from "./LoanStore";
import MBOStore from "./MBOStore";
import NotificationsStore from "./NotificationsStore";

@model("oppzo/Store")
export default class Store extends Model({
  authStore: prop<AuthStore>(),
  loanStore: prop<LoanStore>(),
  documentRequestsStore: prop<DocumentRequestsStore>(),
  notificationsStore: prop<NotificationsStore>(),
  mboStore: prop<MBOStore>(),
  businessStore: prop<BusinessStore>(),
}) {
  blacklist = [];

  @observable
  loading = false;

  @observable
  loaded = false;

  @modelFlow
  load = _async(function* (this: Store) {
    this.loading = true;

    let data: any | string | null;
    data = yield* _await(AsyncStorage.getItem(config.storeKey));
    if (data) {
      data = JSON.parse(data);
      this.blacklist.forEach((key) => {
        delete data[key];
      });

      try {
        applySnapshot(this, {
          ...getSnapshot(this),
          ...data,
          $modelId: this.$modelId,
        });
      } catch (error) {
        console.warn("[DEBUG] error while loading from stored data", error);
      }
    }

    // Load auth store separately.
    yield* _await(this.authStore.load());

    this.loading = false;
    this.loaded = true;
  });

  /**
   * This was moved out of load() function so that it won't affect the unit tests.
   */
  @modelFlow
  fetchInitialData = _async(function* (this: Store) {
    this.loading = true;
    this.loading = false;
  });

  @modelFlow
  reset = _async(function* (this: Store) {
    yield* _await(this.authStore.reset());
    yield* _await(AsyncStorage.removeItem(config.storeKey));
  });

  save = async () => {
    const data = cloneDeep(getSnapshot(this));

    // SECURITY: Dont store tokens in AsyncStorage. Persistence is handled by AuthStore.
    data.authStore.accessToken = "";
    data.authStore.refreshToken = "";

    await AsyncStorage.setItem(config.storeKey, JSON.stringify(data));
  };
}
