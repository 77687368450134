import * as WebBrowser from "expo-web-browser";
import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
import {
  checkIfPrivacyIsRead,
  getLatestPrivacy,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import Markdown from "react-native-markdown-display";

type Props = object;

const PrivacyModal: React.FC<any> = ({ style }) => {
  const styles = useStyleSheet(themedStyles);
  const { authStore } = useStore();
  const [mdtext, setMdtext] = useState("");

  useEffect(() => {
    (async () => {
      const res = await getLatestPrivacy(authStore?.accessToken || "");
      if (res.response.entities) {
        setMdtext(res.response.entities.body);
      }
    })();
  }, []);

  return (
    <View style={style}>
      <Markdown style={themedStyles}>{mdtext}</Markdown>
    </View>
  );
};

const PrivacyPolicy: React.FC<Props> = (props) => {
  const styles = useStyleSheet(themedStyles);
  const { authStore } = useStore();
  const [mdtext, setMdtext] = useState("");

  useEffect(() => {
    (async () => {
      const res = await getLatestPrivacy(authStore?.accessToken || "");
      if (res.response.entities) {
        setMdtext(res.response.entities.body);
      }
    })();
  }, []);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.privacyPolicyContainer}>
          <View style={styles.privacyPolicy}>
            <Markdown style={themedStyles}>{mdtext}</Markdown>
            {/* <Text style={styles.header}>Privacy Notice</Text>

            <Text style={styles.paragraph}>
              This Privacy Notice explains what information{" "}
              <Text style={styles.bold}>
                OppZo (“OppZo” “we”, “our”, or “us”)
              </Text>{" "}
              collects about you when you visit this website http://getoppzo.com
              (the “Website”), how we use this information, with whom we share
              it, and the choices you have in connection with this.
            </Text>
            <Text style={styles.subHeader}>
              1. INFORMATION WE COLLECT AND WHY
            </Text>
            <Text style={styles.paragraph}>
              Through your use of our Website, we collect personal information,
              which is information that identifies you as an individual, relates
              to you as an identifiable individual or, that along with other
              information, could identify you (collectively, “Personal
              Information”). We collect the following types of Personal
              Information:
            </Text>
            <Text style={styles.bold}>
              {"\n\n"}
              A. Information You Provide To Us.
              {"\n"}
            </Text>
            <Text style={styles.paragraph}>
              When you engage with certain aspects of our Website we collect
              Personal Information from you. This includes when you:{"\n"}
              <Text style={styles.underline}>Contact us by email.</Text> When
              you contact us or send us any feedback, comments, or suggestions,
              we collect from you any Personal Information you choose to include
              in such communication, as well as your email address if you
              contact us by email. We use this information to respond to your
              questions, feedback, comments, suggestions, or inquiries.{"\n"}
              <Text style={styles.underline}>
                Interact with our Social Media.
              </Text>{" "}
              If you interact with us on our LinkedIn account, we may collect
              your name, email address, photo, list of social media contacts,
              and any other information you make or which is accessible to us
              when you connect with us. We collect this information to allow you
              to follow us and interact with us. questions, feedback, comments,
              suggestions, or inquiries.{"\n"}
              <Text style={styles.underline}>
                Complete a Form “Apply Now”.
              </Text>{" "}
              If you fill out the form “Apply Now” on our Website you will need
              to provide your business’ annual revenue, name of business,
              business zip code, first and last name, select yes or no to answer
              whether you are a minority owned business, select PPP, SBA, both,
              or none to answer whether you have qualified for a PPP or SBA loan
              in the past, and a contact email. We collect this information to
              allow you to apply for a business loan.{"\n"}
              <Text style={styles.underline}>Make a payment.</Text> If you make
              a payment on our Website you will need to login to your account.
              See above for the Personal Information you must provide to create
              an account with us. Further, to make a payment, you shall need to
              provide personal identifiers including your email address, first
              and last name, credit card type, bank, number, expiration date,
              CVC code, and, if distinct, billing address. To make a payment, we
              use a service provider called Stripe. You can review Stripe’s
              privacy policy at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://stripe.com/en-ca/privacy"
                  )
                }
              >
                <Text style={styles.link}>
                  https://stripe.com/en-ca/privacy
                </Text>
              </TouchableOpacity>
              .{"\n"}
              <Text style={styles.underline}>
                Promotional emails or text messages.
              </Text>{" "}
              If you sign up for promotional emails and/or text messages, we
              collect from you your name, email address, and phone number. If
              you apply for a loan, we may also enroll you in promotional emails
              automatically and to this end will collect the same information.
              You may opt out of receiving these email communications from us by
              following the unsubscribe link or instructions provided in any
              email. You may opt out of receiving these text messages from us by
              emailing us at info@getoppzo.com and requesting to no longer
              receive these messages.
            </Text>
            <Text style={styles.bold}>
              {"\n\n"}
              B. Information Collected Automatically
              {"\n"}
            </Text>
            <Text style={styles.paragraph}>
              When you use our Website, we use cookies and other technologies to
              automatically collect certain information about you. Cookies are
              text files that your web browser places on your computer or mobile
              device when you visit a website. Cookies can be “persistent” or
              “session” cookies. Persistent cookies stay on your device for a
              set period of time or until you delete them, while session cookies
              expire when you close your web browser. The cookies and other
              technologies deployed on our Websites collect the following
              information:{"\n"}
              <Text style={styles.underline}>Usage Information.</Text> Such as
              the pages on our Websites you access, the frequency of access,
              what you click on, and when you access the Websites.{"\n"}
              <Text style={styles.underline}>Location Information.</Text>{" "}
              Information about your location, which may be determined through
              your IP address or geolocation.{"\n"}
              <Text style={styles.underline}>Device Information.</Text>{" "}
              Information about the device you are using, such as hardware
              model, operating system, browser, and your Website preferences.
              {"\n"}
              In particular, we utilize the following cookies:{"\n\n"}
              <Text style={styles.underline}>
                Strictly Necessary Cookies.
              </Text>{" "}
              These cookies are required for making use of our Websites. These
              cookies include security cookies to authenticate users, prevent
              fraudulent use of our Websites, and protect user data from
              unauthorized parties. Our strictly necessary cookies allow our
              Websites to function and allow you to use the features of our
              Websites.{"\n"}
              <Text style={styles.underline}>Analytics Cookies.</Text> We use
              Google Analytics to collect information on your use of the Website
              to improve our Website. In order to collect this information,
              Google Analytics may set cookies on your browser, or read cookies
              that are already there. Google Analytics may also receive
              information about you from applications you have downloaded that
              partner with Google. We do not combine the information collected
              through the use of Google Analytics with personally identifiable
              information. Google’s ability to use and share information
              collected by Google Analytics about your visits to our Website or
              to another application which partners with Google is restricted by
              the Google Analytics Terms of Use and the Google Privacy Policy.
              Please review those policies{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://policies.google.com/technologies/partner-sites"
                  )
                }
              >
                <Text style={styles.link}>here </Text>
              </TouchableOpacity>
              for information about how Google uses the information provided to
              Google Analytics and how you can control the information provided
              to Google. To prevent your data from being used by Google
              Analytics, you can download the Google Analytics opt-out browser
              add-on for Google Analytics which can be found{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://tools.google.com/dlpage/gaoptout"
                  )
                }
              >
                <Text style={styles.link}>here</Text>
              </TouchableOpacity>
              .{"\n"}
            </Text>
            <Text style={styles.subHeader}>2. HOW WE USE YOUR INFORMATION</Text>
            <Text style={styles.paragraph}>
              We use the Personal Information we collect about you to:{"\n"}
              Process your payments.{"\n"}
              Respond to your questions, feedback, comments, suggestions, or
              inquiries.{"\n"}
              Respond to your application for employment.{"\n"}
              Interact with you on social media.{"\n"}
              Communicate with you about our Website or to inform you of any
              changes to our Website.{"\n"}
              Send you information on new products and/or services we feel may
              be of interest to you.{"\n"}
              Engage in internet-based advertising.{"\n"}
              Maintain and improve our Website and the services offered thereon.
              {"\n"}
              Diagnose Website problems and engage in fraud identification and
              prevention.{"\n"}
              Monitor user experience.{"\n"}
              Defend our rights and those of others.{"\n"}
              Efficiently maintain our business.{"\n"}
              Comply with the law.{"\n"}
              We also process and share information in an aggregated,
              de-identified manner, where the information is shared as part of a
              statistical report and does not contain personal information.
              {"\n"}
            </Text>

            <Text style={styles.subHeader}>
              3. HOW WE SHARE YOUR INFORMATION
            </Text>
            <Text style={styles.paragraph}>
              We share your Personal Information for the purposes set forth
              below.{"\n\n"}
              <Text style={styles.bold}>Service Providers.</Text> We share
              Personal Information with our service providers, such as payment
              processors, email providers, advertising providers, and analytics
              providers. We limit the information shared with our service
              providers to what is necessary to fulfill the services. We use the
              following providers:{"\n\n"}
              &#8226; Federal Procurement Data System (FPDS.com). Their privacy
              policy can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://www.fpds.gov/fpdsng_cms/index.php/en/?view=article&id=53"
                  )
                }
              >
                <Text style={styles.link}>
                  {
                    "https://www.fpds.gov/fpdsng_cms/index.php/en/?view=article&id=53"
                  }
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; System for Award Management (SAM.gov). Their privacy
              policy can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://sam.gov/content/about/privacy"
                  )
                }
              >
                <Text style={styles.link}>
                  https://sam.gov/content/about/privacy
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; Plaid, Inc. and its subsidiaries (plaid.com). Their
              privacy policy can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://plaid.com/legal/#consumers"
                  )
                }
              >
                <Text style={styles.link}>
                  https://plaid.com/legal/#consumers
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; First Mile Group, Inc. (alloy.com). Their privacy policy
              can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://www.alloy.com/privacy-policy"
                  )
                }
              >
                <Text style={styles.link}>
                  https://www.alloy.com/privacy-policy
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; Intuit, Inc. (quickbooks.intuit.com). Their privacy policy
              can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("https://www.intuit.com/privacy/")
                }
              >
                <Text style={styles.link}>https://www.intuit.com/privacy/</Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; Tax Guard, LLC (tax-guard.com). Their privacy policy can
              be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://tax-guard.com/privacy-policy"
                  )
                }
              >
                <Text style={styles.link}>
                  https://tax-guard.com/privacy-policy
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; GDS Link, LLC (gdslink.com). Their privacy policy can be
              found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://www.gdslink.com/privacy-policy/"
                  )
                }
              >
                <Text style={styles.link}>
                  https://www.gdslink.com/privacy-policy/
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; Equifax, Inc. (Equifax.com). Their privacy policy can be
              found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://www.equifax.com/privacy/"
                  )
                }
              >
                <Text style={styles.link}>
                  https://www.equifax.com/privacy/
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; Fedmine, LLC (fedmine.us). Their privacy policy can be
              found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("https://www.fedmine.us/privacy/")
                }
              >
                <Text style={styles.link}>https://www.fedmine.us/privacy/</Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; Wolters Kluwer N.V. (wolterskluwer.com/en). Their privacy
              policy can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://www.wolterskluwer.com/en/privacy-cookies"
                  )
                }
              >
                <Text style={styles.link}>
                  https://www.wolterskluwer.com/en/privacy-cookies
                </Text>
              </TouchableOpacity>
              .{"\n"}
              &#8226; LoanPro Software, LLC (loanpro.io). Their privacy policy
              can be found at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://loanpro.io/policies-and-plans"
                  )
                }
              >
                <Text style={styles.link}>
                  https://loanpro.io/policies-and-plans
                </Text>
              </TouchableOpacity>
              .{"\n\n"}
              <Text style={styles.bold}>Payment Processor.</Text> To make a
              payment, we use a service provider called Stripe. You can review
              Stripe’s privacy policy at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://stripe.com/en-ca/privacy"
                  )
                }
              >
                <Text style={styles.link}>
                  https://stripe.com/en-ca/privacy
                </Text>
              </TouchableOpacity>
              .{"\n\n"}
              <Text style={styles.bold}>Analytics Providers.</Text> We use
              Google Analytics to collect information on your use of the Website
              to improve our Website. You may view Google Analytics Terms of Use
              and the Google Privacy Policy here for information about how
              Google uses the information provided to Google Analytics and how
              you can control the information provided to Google. To prevent
              your data from being used by Google Analytics, you can download
              the Google Analytics opt-out browser add-on for Google Analytics
              which can be found{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://tools.google.com/dlpage/gaoptout"
                  )
                }
              >
                <Text style={styles.link}>here</Text>
              </TouchableOpacity>
              .{"\n\n"}
              <Text style={styles.bold}>Within OppZo.</Text> We share Personal
              Information within our corporate family, such as with
              subsidiaries, joint ventures, or affiliates, in order to
              efficiently carry out our business and to the extent permitted by
              law.{"\n\n"}
              <Text style={styles.bold}>Corporate Reorganization.</Text> In the
              event that we enter into, or intend to enter into, a transaction
              that alters the structure of our business, such as a
              reorganization, merger, acquisition, sale, joint venture,
              assignment, consolidation, transfer, change of control, or other
              disposition of all or any portion of our business, assets or
              stock, we would share personal information with third parties,
              including the buyer or target (and their agents and advisors) for
              the purpose of facilitating and completing the transaction. We
              would also share Personal Information with third parties if we
              undergo bankruptcy or liquidation, in the course of such
              proceedings.{"\n\n"}
              <Text style={styles.bold}>To Prevent Harm.</Text> We will share
              Personal Information if we believe it is necessary to detect,
              investigate, prevent, or take action against illegal activities,
              fraud, or situations involving potential threats to the rights,
              property, or personal safety of any person.{"\n\n"}
              <Text style={styles.bold}>Legal Purposes.</Text> We will share
              Personal Information where we are legally required to do so, such
              as in response to court orders, law enforcement or legal process,
              including for national security purposes; to establish, protect,
              or exercise our legal rights, as required to enforce our terms of
              service or other contracts; to defend against legal claims or
              demands; or to comply with the requirements of any applicable law.
              {"\n\n"}
              <Text style={styles.bold}>With Your Consent.</Text> We may request
              your permission to share your Personal Information for a specific
              purpose. We will notify you and request consent before you provide
              the Personal Information or before the Personal Information you
              have already provided is shared for such purpose.
              {"\n\n"}
            </Text>

            <Text style={styles.subHeader}>
              4. CHOICES ABOUT YOUR INFORMATION
            </Text>
            <Text style={styles.paragraph}>
              <Text style={styles.bold}>Update Account Information.</Text> You
              can update your account information (including your phone number,
              email address, username, password, and payment methods) at any
              time by logging in to your user account.
              {"\n\n"}
              <Text style={styles.bold}>Online Advertising.</Text> To opt-out of
              interest based advertising generally or to learn more about the
              use of this information you can visit the{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://optout.networkadvertising.org/?c=1"
                  )
                }
              >
                <Text style={styles.link}>Network Advertising Initiative </Text>
              </TouchableOpacity>
              or the{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://optout.aboutads.info/?c=2&lang=EN"
                  )
                }
              >
                <Text style={styles.link}>Digital Advertising Alliance</Text>
              </TouchableOpacity>
              . You can also visit the privacy settings of your Android or iOS
              device and select “limit ad tracking” (Apple iOS) or “opt-out of
              interest-based ads” (Android).
              {"\n\n"}
              <Text style={styles.bold}>Google Analytics.</Text> You can control
              the information provided to Google and opt-out of certain by using
              one of the methods set forth{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://policies.google.com/technologies/partner-sites"
                  )
                }
              >
                <Text style={styles.link}>here</Text>
              </TouchableOpacity>
              . You can also download the Google Analytics opt-out browser
              add-on for Google Analytics which can be found{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://tools.google.com/dlpage/gaoptout/"
                  )
                }
              >
                <Text style={styles.link}>here</Text>
              </TouchableOpacity>
              .{"\n\n"}
              <Text style={styles.bold}>Promotional Emails.</Text> You may
              opt-out of receiving marketing emails from us by clicking the
              “unsubscribe” link provided with each email. Please note that we
              will continue to send you notifications necessary to the Website,
              the services, and your loan(s).
              {"\n\n"}
              <Text style={styles.bold}>Promotional Text Messages.</Text> With
              your consent, we may send you promotional text messages. You can
              opt out of these text messages at any time by emailing us at
              info@getoppzo.com and requesting to no longer receive these
              messages.
              {"\n\n"}
              <Text style={styles.bold}>Cookies.</Text> All session cookies
              expire after you close your browser. Persistent cookies can be
              removed by following your browser’s directions. To find out how to
              see what cookies have been set on your device, and how to reject
              and delete the cookies, please visit:
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("https://www.aboutcookies.org/")
                }
              >
                <Text style={styles.link}>https://www.aboutcookies.org/</Text>
              </TouchableOpacity>
              . Please note that each browser is different. For information on
              reviewing or deleting cookies from specific browsers, click on the
              appropriate browser:{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                  )
                }
              >
                <Text style={styles.link}>Firefox</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.mozilla.org/en-US/kb/clear-browsing-history-firefox-ios"
                  )
                }
              >
                <Text style={styles.link}>Firefox IOS</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.mozilla.org/en-US/kb/clear-your-browsing-history-and-other-personal-data"
                  )
                }
              >
                <Text style={styles.link}>Firefox Android</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                  )
                }
              >
                <Text style={styles.link}>Safari</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.apple.com/en-us/HT201265"
                  )
                }
              >
                <Text style={styles.link}>Safari Mobile</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DDesktop&hl=en"
                  )
                }
              >
                <Text style={styles.link}>Chrome</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                  )
                }
              >
                <Text style={styles.link}>Internet Explorer</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                  )
                }
              >
                <Text style={styles.link}>Microsoft Edge</Text>
              </TouchableOpacity>
              ,{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData"
                  )
                }
              >
                <Text style={styles.link}>Opera</Text>
              </TouchableOpacity>
              . To find information relating to other browsers, visit the
              browser developer’s website. If you reset your browser to refuse
              all cookies or to indicate when a cookie is being sent, some
              features of our Website may not function properly. If you choose
              to opt-out, we will place an “opt-out cookie” on your computer.
              The “opt-out cookie” is browser and device specific and only lasts
              until cookies are cleared from your browser or device. The opt-out
              cookie will not work for strictly necessary cookies. If the cookie
              is removed or deleted, if you upgrade your browser or if you visit
              us from a different computer, you will need to return and update
              your preferences.
              {"\n\n"}
              <Text style={styles.bold}>Location Settings.</Text> You may
              prevent your device from sharing your location data by adjusting
              the permissions on your device.
              {"\n\n"}
            </Text>

            <Text style={styles.subHeader}>5. DO NOT TRACK DISCLOSURE</Text>
            <Text style={styles.paragraph}>
              We do not support Do Not Track (“DNT”). Do Not Track is a
              preference you can set in your web browser to inform websites that
              you do not want to be tracked. You can enable or disable Do Not
              Track by visiting the Preferences or Settings page of your
              browser. For further details, visit{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync(
                    "https://www.eff.org/issues/do-not-track"
                  )
                }
              >
                <Text style={styles.link}>
                  https://www.eff.org/issues/do-not-track
                </Text>
              </TouchableOpacity>
              .
            </Text>

            <Text style={styles.subHeader}>6. INFORMATION SECURITY</Text>
            <Text style={styles.paragraph}>
              We implement and maintain commercially reasonable security
              measures to protect the Personal Information we collect. Our
              security measures include but are not limited to TLS 1.2 and
              AES-256. However, no security measure or modality of data
              transmission over the Internet is 100% secure and we are unable to
              guarantee the absolute security of the Personal Information we
              have collected from you transferred over the Internet.
            </Text>

            <Text style={styles.subHeader}>7. THIRD PARTY LINKS</Text>
            <Text style={styles.paragraph}>
              Our Website may contain links to other sites that are not operated
              by us. This Privacy Notice applies solely to the Personal
              Information collected on our Website. If you click on a
              third-party link, you will be directed to that third-party’s
              website. We strongly advise you to review the privacy notice of
              every website you visit.
            </Text>

            <Text style={styles.subHeader}>
              8. CHANGES TO THIS PRIVACY NOTICE
            </Text>
            <Text style={styles.paragraph}>
              OppZo reserves the right to amend this Privacy Notice at our
              discretion and at any time. Changes to the Privacy Notice will be
              posted on this page and will indicate the date the changes go into
              effect. Please check back and review the Privacy Notice for any
              changes. If we make any changes that materially affect your
              privacy rights, we will announce those changes by posting the
              revised Privacy Notice on our Website and will always indicate the
              date the last changes were published. If changes are significant,
              we will provide notice in an additional means we deem appropriate
              which may include directly notifying you by email.
            </Text>

            <Text style={styles.subHeader}>9. CONTACT</Text>
            <Text style={styles.paragraph}>
              If you have any questions about this Privacy Notice, please
              contact us by email at{" "}
              <TouchableOpacity
                style={styles.link}
                onPress={() =>
                  WebBrowser.openBrowserAsync("mailto: info@getoppzo.com")
                }
              >
                <Text style={styles.link}>info@getoppzo.com</Text>
              </TouchableOpacity>
              .
            </Text>

            <Text style={styles.bold}>
              {"\n\n"}
              This Privacy Notice was last modified on July 15, 2021.
              {"\n\n"}
            </Text> */}
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default PrivacyPolicy;
export { PrivacyModal };
