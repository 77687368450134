import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import { callApi } from "./base";

export const fetchNotifications = async (token: string) =>
  callApi(config.urls.notifications, token, "GET");

export const markNotificationAsRead = async (token: string, id: number) =>
  callApi(
    `${config.urls.notifications}${id}/`,
    token,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys({ isRead: true }))
  );
