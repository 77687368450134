import { Text, useStyleSheet, Icon } from "@ui-kitten/components";
import React from "react";
import { Dimensions, View, Image } from "react-native";

import { themedStyles } from "./themedStyles";

export const TrackerStep: React.FC<any> = ({
  status,
  imageDone,
  imageInProgress,
  image,
  scale,
  text,
}) => {
  const styles = useStyleSheet(themedStyles);
  let { width, height } = Dimensions.get("window");
  const DoneIcon = (props: any) => (
    <Icon {...props} name="checkmark-circle-2-outline" />
  );
  const MeatballsIcon = (props: any) => (
    <Icon {...props} name="more-horizontal-outline" />
  );
  const RejectIcon = (props: any) => (
    <Icon {...props} name="close-circle-outline" />
  );

  return (
    <View style={styles.step}>
      <Image
        source={(() => {
          if (status === "done") return imageDone;
          if (status === "inProgress" || status === "reject")
            return imageInProgress;
          return image;
        })()}
        style={
          status !== "inProgress" && status !== "reject"
            ? scale
            : {
                width: scale.width * 1.1,
                height: scale.height * 1.17,
                marginTop: -5,
                resizeMode: "contain",
              }
        }
      />
      <View
        style={
          width > 768
            ? {
                ...styles.stepTextContainer,
                ...(status === "reject" ? { width: scale.width } : {}),
              }
            : {
                ...styles.stepTextContainerMobile,
                ...(status === "reject" ? { width: scale.width } : {}),
              }
        }
      >
        <Text
          style={
            width > 768
              ? {
                  ...styles.stepText,
                  color: "white",
                }
              : {
                  ...styles.stepTextMobile,
                  color: "white",
                }
          }
        >
          {text}
        </Text>
        {status === "done" &&
          DoneIcon({ width: 20, height: 20, fill: "white" })}
        {status === "inProgress" &&
          MeatballsIcon({ width: 20, height: 20, fill: "white" })}
        {status === "reject" &&
          RejectIcon({ width: 20, height: 20, fill: "white" })}
      </View>
    </View>
  );
};
