import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import AdminDashboard from "../../containers/AdminDashboard";
import AdminLogIn from "../../containers/AdminLogIn";
import AdminRequestCenter from "../../containers/AdminRequestCenter";
import ResetPassword from "../../containers/ResetPassword";
import ConfirmResetPassword from "../../containers/ResetPassword/ConfirmResetPassword";
import ClosingQueue from "../../containers/ClosingQueue";
import { useStore } from "../../stores";
import ManageProfile from "../../containers/ManageProfile";
import Summary from "../../containers/UnderwriterPortalSummary";
import Collateral from "../../containers/UnderwriterPortalCollateral";
import RiskFlags from "../../containers/UnderwriterPortalRiskFlags";
import Decision from "../../containers/UnderwriterPortalDecision";
import CreditFiles from "../../containers/UnderwriterPortalCreditFiles";
import UnderwriterPortalFinalDecision from "../../containers/UnderwriterPortalFinalDecision";
import DocuSignModal from "../../containers/UnderwriterPortalCreditFiles/DocuSignModal";
import DocuSignModalEnd from "../../containers/UnderwriterPortalCreditFiles/DocuSignModalEnd";
import RetrieveSignedDoc from "../../containers/UnderwriterPortalCreditFiles/RetrieveSignedDoc";
import LoanTermsModal from "../../containers/UnderwriterPortalCreditFiles/LoanTermsModal";
import RetrieveSignedLoanTerms from "../../containers/UnderwriterPortalCreditFiles/RetrieveSignedLoanTerms";
import LendingDocCloSignModal from "../../containers/UnderwriterPortalCreditFiles/LendingDocCloSignModal";
import LendingDocCloSignModalEnd from "../../containers/UnderwriterPortalCreditFiles/LendingDocCloSignModalEnd";

export type AdminStackParamList = {
  "log-in": undefined;
  "requested-documents": undefined;
  "password-reset": undefined;
  "password-reset-confirm": undefined;
  requests: undefined;
  summary: undefined;
  collateral: undefined;
  decision: undefined;
  "risk-flags": undefined;
  dashboard: undefined;
  closing: undefined;
  "credit-files": undefined;
  "docusign-modal": undefined;
  "docusign-modal-end": undefined;
  "retrieve-signed-doc-modal": undefined;
  "loan-terms-modal": undefined;
  "loan-terms-modal-end": undefined;
  "retrieve-signed-loan-terms-modal": undefined;
  "lending-doc-clo-sign-modal": undefined;
  "lending-doc-clo-sign-modal-end": undefined;
  "manage-profile": undefined;
};

export type AdminStackNavigation = StackNavigationProp<AdminStackParamList>;

type Props = object;

const Stack = createStackNavigator<AdminStackParamList>();

const AdminStack: React.FC<Props> = (props) => {
  const { authStore } = useStore();

  return authStore.user?.isStaff && authStore.accessToken ? (
    <Stack.Navigator initialRouteName="dashboard" headerMode="none">
      <Stack.Screen
        name="dashboard"
        options={{
          title: "OppZo | Underwriter Portal | Dashboard",
        }}
        component={UnderwriterPortalFinalDecision}
      />
      <Stack.Screen
        name="summary"
        options={{ title: "OppZo | Underwriter Portal | Loan summary" }}
        component={Summary}
      />
      <Stack.Screen
        name="collateral"
        options={{
          title: "OppZo | Underwriter Portal | Collateral & Reports",
        }}
        component={Collateral}
      />
      <Stack.Screen
        name="risk-flags"
        options={{
          title: "OppZo | Underwriter Portal | Risk Flags",
        }}
        component={RiskFlags}
      />
      <Stack.Screen
        name="decision"
        options={{
          title: "OppZo | Underwriter Portal | Decision",
        }}
        component={Decision}
      />
      <Stack.Screen
        name="credit-files"
        options={{
          title: "OppZo | Underwriter Portal | Decision",
        }}
        component={CreditFiles}
      />
      <Stack.Screen
        name="docusign-modal"
        options={{
          title: "OppZo | Underwriter Portal | DocuSign",
        }}
        component={DocuSignModal}
      />
      <Stack.Screen
        name="docusign-modal-end"
        options={{
          title: "OppZo | Underwriter Portal | DocuSign",
        }}
        component={DocuSignModalEnd}
      />
      <Stack.Screen
        name="retrieve-signed-doc-modal"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={RetrieveSignedDoc}
      />
      <Stack.Screen
        name="loan-terms-modal"
        options={{
          title: "OppZo | Underwriter Portal | DocuSign",
        }}
        component={LoanTermsModal}
      />
      <Stack.Screen
        name="retrieve-signed-loan-terms-modal"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={RetrieveSignedLoanTerms}
      />
      <Stack.Screen
        name="lending-doc-clo-sign-modal"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={LendingDocCloSignModal}
      />
      <Stack.Screen
        name="lending-doc-clo-sign-modal-end"
        options={{ title: "OppZo | Retrieve Signed Document" }}
        component={LendingDocCloSignModalEnd}
      />
      <Stack.Screen
        name="requested-documents"
        options={{ title: "OppZo | Admin Requested Documents" }}
        component={AdminDashboard}
      />
      <Stack.Screen
        name="requests"
        options={{ title: "OppZo | Admin Requests Center" }}
        component={AdminRequestCenter}
      />
      <Stack.Screen
        name="closing"
        options={{ title: "OppZo | Closing Queue" }}
        component={ClosingQueue}
      />
      <Stack.Screen
        name="manage-profile"
        options={{ title: "OppZo | Admin Portal | Manage Profile" }}
        component={ManageProfile}
      />
    </Stack.Navigator>
  ) : (
    <Stack.Navigator initialRouteName="log-in" headerMode="none">
      <Stack.Screen
        name="log-in"
        options={{ title: "OppZo | Admin Portal Log In" }}
        component={AdminLogIn}
      />
      <Stack.Screen
        name="password-reset"
        options={{ title: "OppZo | Password Reset" }}
        component={ResetPassword}
      />
      <Stack.Screen
        name="password-reset-confirm"
        options={{ title: "OppZo | Password Reset Confirm" }}
        component={ConfirmResetPassword}
      />
    </Stack.Navigator>
  );
};

export default AdminStack;
