import {
  Button,
  Text,
  useStyleSheet,
  Icon,
  Spinner,
  Divider,
} from "@ui-kitten/components";
import React, { useState, useCallback, useEffect } from "react";
import { Dimensions, View } from "react-native";

import StepButton from "../../components/StepButton/StepButton";
import config from "../../config";
import LoanApplication from "../../models/LoanApplication";
import { getFullUrl } from "../../services/api/base";
import { useStore } from "../../stores";
import { BusinessInformationPrequalStep } from "./BusinessInformationPrequalStep";
import { ContractorInformationStep } from "./ContractorInformationStep";
import { ContractorTypeStep } from "./ContractorTypeStep";
import { LendersStep } from "./LendersStep";
import { themedStyles } from "./themedStyles";

export const PreQualificationForm: React.FC<any> = ({
  business,
  onContinue,
  governmentContractorLevel,
  contractorLevel,
  setIsRejectedModalOpen,
  loan,
  preloadCurrentStep,
}) => {
  const styles = useStyleSheet(themedStyles);
  const LoadingIndicator = (_props: any) => <Spinner status="basic" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const ArrowBackIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-back-outline" />
  );
  const CloudUploadIcon = (props: any) => (
    <Icon {...props} fill="white" name="cloud-upload-outline" />
  );
  const defaultFields = {
    contractorType: "subcontractor",
    wantsToBePrime: "no",
    cageCode: "",
    dunsNumber: "",
    ueiNumber: "",
    contractNumber: [""],
    contractPricingType: "",
    contractAmount: "",
    unbilledContractAmount: "",
    annualRevenue: "",
    businessStartDate: undefined,
    hasExistingLenders: "no",
    baseContract: null,
    existingLendersSelectedOptions: [],
  };
  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
    detail: "",
    baseContract: "",
    contractNumber: "",
  };
  const { width } = Dimensions.get("window");
  const id = new URLSearchParams(window.location.search).get("id") || loan?.id;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFields });
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });
  const [stepsDone, setStepsDone] = useState<any>(Array(5).fill(false));
  const [currentStep, setCurrentStep] = useState<number>(preloadCurrentStep);
  const [farthestStep, setFarthestStep] = useState(0);
  const [loanId, setLoanId] = useState<number>(Number(id));
  const { authStore, loanStore, businessStore } = useStore();

  useEffect(() => {
    if (farthestStep < currentStep) {
      setFarthestStep(currentStep);
    }
  }, [currentStep]);

  const goToNextStep = (isStepDone = false) => {
    const newStepsDone = [...stepsDone];
    newStepsDone[currentStep] = isStepDone || newStepsDone[currentStep];
    setStepsDone(newStepsDone);
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    console.log(errorMessages);
    const newStepsDone = [...stepsDone];
    newStepsDone[2] =
      newStepsDone[2] &&
      !errorMessages?.annualRevenue &&
      !errorMessages?.cageCode &&
      !errorMessages?.contractNumber &&
      !errorMessages?.businessStartDate &&
      !errorMessages?.dunsNumber;
    newStepsDone[3] = newStepsDone[3] && !errorMessages?.contractAmount;
    setStepsDone(newStepsDone);
  }, [errorMessages]);

  useEffect(() => {
    setCurrentStep(1);
    if (!loan) {
      onSubmitForm();
    } else {
      let data = {
        ...formData,
        contractorType: loan.isPrimeContractor ? "prime" : "subcontractor",
        wantsToBePrime: loan.wantsToBePrime ? "yes" : "no",
      };
      businessStore.business.forEach((business) => {
        if (business.id === loan.business) {
          data = {
            ...data,
            cageCode: business.cageCode ? business.cageCode : "",
            dunsNumber:
              Number(business.dunsNumber) > 0
                ? business.dunsNumber.toString()
                : "",
            ueiNumber: business.ueiNumber ? business.ueiNumber : "",
            contractNumber: [business.contractNumber],
            ContractPricingType: business.contractPricingType
              ? business.contractPricingType
              : "",
            contractAmount:
              Number(business.contractAmount) > 0
                ? business.contractAmount.toString()
                : "",
            annualRevenue:
              Number(business.annualRevenue) > 0
                ? business.annualRevenue.toString()
                : "",
            // @ts-ignore
            businessStartDate: business.businessStartDate
              ? new Date(business.businessStartDate)
              : undefined,
            hasExistingLenders: business.hasExistingLenders ? "yes" : "no",
            // @ts-ignore
            baseContract: business.baseContract ? business.baseContract : null,
            // @ts-ignore
            unbilledContractAmount:
              Number(business.unbilledContractAmount) > 0
                ? business.unbilledContractAmount?.toString()
                : "",
          };
        }
      });
      // @ts-ignore
      setFormData(data);

      let newStepsDone = [...stepsDone];
      newStepsDone = newStepsDone.map((step: boolean, currentStep: number) => {
        switch (currentStep) {
          case 1:
            // return !!loan.isPrimeContractor || !!loan.wantsToBePrime;
            return !!loan.contractorType || !!loan.wantsToBePrime;
          case 2:
            return (
              !!loan.cageCode &&
              !!loan.dunsNumber &&
              !!loan.contractNumber &&
              !!loan.contractPricingType &&
              !!loan.baseContract &&
              !!loan.contractAmount &&
              !!loan.unbilledContractAmount
            );
          case 3:
            return !!loan.annualRevenue && !!loan.businessStartDate;
          case 4:
            return !!loan.hasExistingLenders;
          default:
            return true;
        }
      });
      setStepsDone(newStepsDone);
      for (let i = 0; i < newStepsDone.length; i++) {
        if (newStepsDone[i] == false) {
          setCurrentStep(i);
          break;
        }
      }
    }
  }, []);

  const uploadFile = useCallback(
    async (id: number) => {
      const file = formData.baseContract;
      if (file) {
        try {
          setLoading(true);
          const data = new FormData();
          data.append("base_contract", file);

          await fetch(getFullUrl(`${config.urls.loans.application}${id}/`), {
            method: "PATCH",
            credentials: "include",
            headers: { Authorization: `Bearer ${authStore.accessToken}` },
            body: data,
          }).then(() => {
            setLoading(false);
          });
        } catch (error) {
          setLoading(false);
          console.log(error.message);
          setErrorMessages(JSON.parse(error.message));
        }
      }
    },
    [loading, formData]
  );

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    setErrorMessages({ ...defaultErrors });
    const result = await loanStore.addLoanApplication(
      new LoanApplication({
        id: -1,
        applicantId: authStore.user?.id || -1,
        applicantName: "",
        governmentContractorLevel,
        contractorLevel,
        business,
        isPrimeContractor: formData.contractorType === "prime",
        wantsToBePrime: formData.wantsToBePrime === "yes",
        isTemporary: true,
        prequal1Step: currentStep,
        prequal2Step: currentStep,
        status: "registered",
        corporationStatus: "",
        taxIdNumber: "",
        signed406tForm: null,
        contractingOfficer: null,
        contractingOfficerEmail: null,
        contractingOfficerPhone: null,
        contractingOfficerFirstName: null,
        contractingOfficerLastName: null,
        largestActiveContractsFile: null,
        linkedIn: "",
        driversLicense: null,
        cashFlowProjectionsFile: null,
        oneYearBudgetProjectionsFile: null,
        oneYearContractBacklog: null,
        businessPlan: null,
        unsigned8821Form: null,
        hasPaidApplicationProcessingFee: false,
        companyOrganizationalChart: null,
        corporateOrAuthorizingResolution: null,
        operatingAgreement: null,
        fiscalYearEndProjections: null,
        financialStatements: null,
        resumes: null,
        legalActions: null,
        balanceSheet: null,
        incomeStatement: null,
        hasConnectedQuickbooks: false,
        needToReconnectQuickbooks: false,
        byLaws: null,
        term: null,
        withdrawalStartDate: null,
        isFinalPullEquifaxCommercial: null,
      })
    );
    if (!result?.ok) {
      setErrorMessages({ ...defaultErrors, ...result?.errors });
      setLoading(false);
    } else {
      setLoading(false);
      setLoanId(result?.extra?.id);

      const data = {
        id: result?.extra?.id,
      };
      updateLoanData(data);

      loanStore.fetchLoanApplications();
    }
  }, [loading, formData]);

  const updateLoanData = async (data: any) => {
    let res, loanRes;
    console.log("Loan ID is", loanId);
    if (loanId >= 0) {
      setLoading(true);
      setErrorMessages({ ...defaultErrors });
      console.log("Updating business data", { business, data });
      res = await businessStore.updateBusiness(business, data);
      if (!res?.ok) {
        setErrorMessages({ ...defaultErrors, ...res?.errors });
      } else {
        console.log("Updating loan app in updateLoanData", { loanId, data });
        loanRes = await loanStore.updateLoanApplication(loanId, data);
        if (!loanRes?.ok) {
          setErrorMessages({ ...defaultErrors, ...res?.errors });
        }
      }
      setLoading(false);
      return { res, loanRes };
    } else {
      console.error("Loan data update has failed");
      return false;
    }
  };

  const validateFilledForms = useCallback(() => {
    let filledContactType = true;
    let filledContractorInfo = true;
    let filledBusinessInfo = true;
    let filledLenderInfo = true;
    if (!formData.contractorType || !formData.wantsToBePrime) {
      filledContactType = false;
    }
    if (
      !formData.cageCode ||
      !formData.ueiNumber ||
      !formData.dunsNumber ||
      !formData.contractNumber.join(", ") ||
      !formData.contractPricingType ||
      !formData.baseContract ||
      !formData.contractAmount
    ) {
      filledContractorInfo = false;
    }
    if (!formData.annualRevenue || formData.businessStartDate == undefined) {
      filledBusinessInfo = false;
    }

    if (
      !formData.hasExistingLenders ||
      (formData.hasExistingLenders === "yes" &&
        !formData.existingLendersSelectedOptions.length)
    ) {
      filledLenderInfo = false;
    }

    return {
      filledContactType,
      filledBusinessInfo,
      filledContractorInfo,
      filledLenderInfo,
    };
  }, [formData]);

  const {
    filledBusinessInfo,
    filledContactType,
    filledContractorInfo,
    filledLenderInfo,
  } = validateFilledForms();
  const submitButtonIsEnabled =
    filledBusinessInfo &&
    filledContactType &&
    filledContractorInfo &&
    filledLenderInfo;

  useEffect(() => {
    const newStepsDone = [...stepsDone];
    newStepsDone[1] = filledContactType;
    newStepsDone[2] = filledContractorInfo;
    newStepsDone[3] = filledBusinessInfo;
    newStepsDone[4] = filledLenderInfo;
    setStepsDone(newStepsDone);
  }, [
    filledBusinessInfo,
    filledContactType,
    filledContractorInfo,
    filledLenderInfo,
  ]);

  const onSubmit = async () => {
    setLoading(true);
    console.log("FORM DATA", formData);
    let data = {
      isTemporary: true,
      prequal1Step: currentStep + 1,
      isPrimeContractor: formData.contractorType === "prime",
      wantsToBePrime: formData.wantsToBePrime === "yes",
      cageCode: formData.cageCode,
      dunsNumber: Number(formData.dunsNumber),
      ueiNumber: formData.ueiNumber,
      contractNumber: formData.contractNumber.join(","),
      contractPricingType: formData.contractPricingType,
      contractAmount: Number(formData.contractAmount),
      unbilledContractAmount: Number(formData.unbilledContractAmount),
      annualRevenue: Number(formData.annualRevenue),
      businessStartDate: formData.businessStartDate
        ? new Date(formData.businessStartDate || Date.now())
            .toISOString()
            .split("T")[0]
        : null,
      hasExistingLenders: formData.hasExistingLenders === "yes",
      existingLendersSelectedOptions: formData.existingLendersSelectedOptions,
    };
    if (formData.baseContract) {
      // @ts-ignore
      data = { ...data, baseContract: formData.baseContract };
    }
    const updatedLoanData = await updateLoanData(data);
    console.log("Updated loan data after submit", updateLoanData);
    const prequalVerification = await loanStore.verifyPrequal1(loanId);
    console.log("Prequal 1 verification", prequalVerification);
    const status = prequalVerification?.extra?.status;
    if (status) {
      onContinue(status, loanId);
    }

    setLoading(false);
  };

  const steps = [
    "Contractor Type",
    "Contractor Information",
    "Business Information",
    "Lender Information",
  ];

  return (
    <>
      <Text style={width > 768 ? styles.header : styles.headerMobile}>
        Pre-qualification
      </Text>
      <Text style={width > 768 ? styles.subHeader : styles.subHeaderMobile}>
        We just need a few more pieces of information before we can continue
        with your application.
      </Text>

      <View
        style={
          width > 768
            ? styles.preQualStatusContainer
            : styles.preQualStatusContainerMobile
        }
      >
        {steps.map((step, idx) => {
          return (
            <>
              <StepButton
                number={idx + 1}
                label={step}
                stepsDone={stepsDone}
                currentStep={currentStep}
                onPress={() => setCurrentStep(idx + 1)}
                clickable={
                  stepsDone[idx + 1] || farthestStep === idx + 1 || __DEV__
                }
              />
              {idx < steps.length - 1 && <Divider style={styles.divider} />}
            </>
          );
        })}
      </View>

      {width < 768 && (
        <View style={styles.preQualificationLabelContainer}>
          <Text style={styles.preQualificationLabel}>
            {steps[currentStep - 1]}
          </Text>
          {currentStep < 4 && (
            <Text
              style={styles.preQualificationSubLabel}
            >{`Next: ${steps[currentStep]}`}</Text>
          )}
        </View>
      )}

      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Text>
        {errorMessages?.detail && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.detail}
          </Text>
        )}
      </Text>

      {currentStep === 1 && (
        <ContractorTypeStep formData={formData} setFormData={setFormData} />
      )}

      {currentStep === 2 && (
        <ContractorInformationStep
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
        />
      )}

      {currentStep === 3 && (
        <BusinessInformationPrequalStep
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
        />
      )}

      {currentStep === 4 && (
        <LendersStep
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
        />
      )}

      <View style={width > 576 && styles.flexRow}>
        {currentStep > 1 && (
          <Button
            style={styles.nextActionButton}
            onPress={() => setCurrentStep(currentStep - 1)}
            accessoryLeft={ArrowBackIcon}
            children={() => (
              <Text style={styles.nextActionButtonText}>Back{"  "}</Text>
            )}
          />
        )}

        <Button
          disabled={!submitButtonIsEnabled}
          style={
            !submitButtonIsEnabled
              ? styles.disabledActionButton
              : styles.nextActionButton
          }
          onPress={() => onSubmit()}
          accessoryRight={
            loading || !loanStore ? LoadingIndicator : CloudUploadIcon
          }
          children={() => (
            <Text
              style={
                !submitButtonIsEnabled
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Submit{"  "}
            </Text>
          )}
        />

        {currentStep < 4 && (
          <Button
            style={styles.nextActionButton}
            onPress={() => setCurrentStep(currentStep + 1)}
            accessoryRight={ArrowIcon}
            children={() => (
              <Text style={styles.nextActionButtonText}>Next{"  "}</Text>
            )}
          />
        )}
      </View>
    </>
  );
};
