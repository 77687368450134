import * as eva from "@eva-design/eva";
import {
  Button,
  Text,
  useStyleSheet,
  Modal,
  Icon,
  Input,
  List,
  ThemeProvider,
} from "@ui-kitten/components";
import React, { useEffect, useRef, useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import DocumentRequestMessage from "../../models/DocumentRequestMessage";
import { useStore } from "../../stores";
import { uploadDocument } from "../../utils";
import RichTextEditor from "../RichTextEditor";
import { themedStyles } from "./themedStyles";

const RequestModal: React.FC<any> = ({
  applicantId,
  applicationId,
  isOpen,
  setIsOpen,
  applicantName,
  businessName,
  openRequestSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [attachments, setAttachments]: any = useState([]);
  const { authStore, loanStore, documentRequestsStore } = useStore();

  const styles = useStyleSheet(themedStyles);

  const attachFilesIcon = (props: any) => (
    <Icon {...props} name="attach-2-outline" fill="black" />
  );

  const documentTypesBase = [
    "Personal Financial Statements",
    "Tax returns (Personal); last two years",
    "Tax returns (Corporate); last two years",
    "Last two years FYE Financial Statements (Corporate)",
    "Current YTD and Monthly Interim Financial Statements with prior comparable period",
    "Debt Schedule with Copies of Applicable Note(s)",
    "Executed contract with Government, State & Local (Sample Invoices with Corresponding Backup, Copies of contracts, & Modifications)",
    "Accounts Receivable aging (Current Period & Month End A/R Detailed Aging, Aged by Invoice Date)",
    "FYE Financial Projections by Month to Include BS, IS & CF Statement",
    "Contract Backlog Report",
    "Bank Statements for all Operating and Payroll Accounts; last 2 years",
    "Accounts Payable aging (Current Period & Month End A/P Detailed Aging, Aged by Invoice Date)",
    "Good Standing Certificate",
    "Articles of Incorporation and any applicable amendments",
    "Company Organizational Structure Chart",
    "ByLaws",
    "Operating Agreement",
    "Corporate Resolution/Authorizing Resolution",
    "Incumbency of Officers/Incumbency Certificate",
    "Other (Contract and Collateral)",
    "Other (Business Entity)",
    "Other (Business Principal)",
    "Other (Financials)",
  ];

  const [documentTypes, setDocumentTypes] = useState([...documentTypesBase]);
  const [isDocumentTypeModalOpen, setDocumentTypeModalOpen] = useState(false);
  const [isOtherDocumentNameFieldOpen, setIsOtherDocumentNameFieldOpen] =
    useState(false);
  const [isModalExpanded, setIsModalExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const initialFormData = {
    message: "",
    attachment: "",
    userToRequest: "",
    docType: "",
    categoryType: "",
    documentName: "",
  };
  const [formData, setFormData] = useState<any>({ ...initialFormData });
  const [search, setSearch] = useState("");

  const searchRef = useRef<Input>(null);

  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;

  const cleanFieldsAndCloseSelections = () => {
    setFormData({ ...initialFormData });
    setSearch("");
    setErrorMessage("");
    setDocumentTypeModalOpen(false);
    setIsOtherDocumentNameFieldOpen(false);
    setSuccess(false);
    setAttachments([]);
    setIsModalExpanded(false);
  };

  const close = () => {
    cleanFieldsAndCloseSelections();
    setIsOpen(false);
  };

  const addNewRequest = async () => {
    setLoading(true);
    setErrorMessage("");
    if (!formData.message) {
      setErrorMessage("Message is required.");
    } else if (!formData.docType) {
      setErrorMessage("Document type is required.");
    } else if (
      formData.docType
        .split("_")
        .filter((type: string) => type.includes("Other")).length &&
      !formData.documentName
    ) {
      setErrorMessage("Document name is required.");
    } else {
      const res = await documentRequestsStore.addDocumentRequestMessage(
        // @ts-ignore
        new DocumentRequestMessage({
          id: -1,
          loanApplicationId: applicationId,
          applicantBusinessName: businessName,
          applicantName,
          authorId: authStore.user?.id || null,
          body: formData.message,
          documentRequestType: formData.docType,
          documentCategory: formData.categoryType,
          documentName: formData.documentName,
          attachments,
          rootMessageId: null,
          parentMessageId: null,
        })
      );
      if (res?.ok) {
        setSuccess(true);
        close();
        openRequestSuccess(true);
      } else {
        setErrorMessage("An error occured.");
        console.log(res);
      }
    }
    setLoading(false);
  };

  const modalRenderItem: React.FC<any> = ({ item, index }) => (
    <TouchableOpacity
      style={styles.modalDocTypeListItem}
      onPress={() => {
        const docTypes = formData.docType.split("_");

        let newTypes = [];

        if (docTypes.includes(item)) {
          newTypes = docTypes.filter((type: string) => type !== item);
        } else {
          newTypes = [
            ...docTypes.filter(
              (type: string) =>
                !type.includes("Other") || !item.includes("Other")
            ),
            item,
          ];
        }

        newTypes = newTypes.filter((type: string) => type);
        setFormData({
          ...formData,
          docType: newTypes.join("_"),
          categoryType: !newTypes.filter((type: string) =>
            type.includes("Other")
          ).length
            ? ""
            : newTypes
                .filter((type: string) => type.includes("Other"))[0]
                .split("(")[1]
                .replace(")", ""),
          documentName: !newTypes.filter((type: string) =>
            type.includes("Other")
          ).length
            ? ""
            : formData.documentName,
        });
      }}
    >
      <View style={styles.modalRow}>
        {formData.docType.split("_").includes(item) ? (
          <View
            style={{
              ...styles.checkedBox,
              //@ts-ignore
              background:
                "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
            }}
          >
            <Icon
              style={styles.iconInside}
              name="checkmark-outline"
              fill="white"
            />
          </View>
        ) : (
          <View
            style={{
              ...styles.uncheckedBox,
            }}
          />
        )}
        <Text style={styles.modalSubheaderText}>{item}</Text>
      </View>
    </TouchableOpacity>
  );

  useEffect(() => {
    const newList: string[] = [];
    const searchTmp = search;
    let item;
    for (item in documentTypesBase) {
      if (
        documentTypesBase[item].startsWith("Other") ||
        documentTypesBase[item].toLowerCase().includes(searchTmp.toLowerCase())
      ) {
        newList.push(documentTypesBase[item]);
      }
    }
    setDocumentTypes(newList);
  }, [search]);

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.modalBackdrop}
      style={
        isModalExpanded ? styles.modalContainerExpanded : styles.modalContainer
      }
      onBackdropPress={() => close()}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.modalHeaderRow}>
          <Text style={styles.modalHeaderText}>New Request</Text>
          <View style={styles.modalIconRow}>
            <TouchableOpacity
              onPress={() => setIsModalExpanded(!isModalExpanded)}
            >
              <Icon style={styles.icon} name="expand-outline" fill="black" />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => close()}>
              <Icon style={styles.icon} name="close-outline" fill="black" />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.modalRow}>
          <Text style={styles.modalText}>{" To  "}</Text>
          <Text
            style={styles.modalTextSB}
          >{`Loan ID ${applicationId} | ${businessName}`}</Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.modalRow}>
          <TouchableOpacity
            style={styles.modalIconRow}
            onPress={() => {
              setDocumentTypeModalOpen(!isDocumentTypeModalOpen);
            }}
          >
            <Text style={styles.modalText}>
              {" "}
              {formData.docType.split("_").length === 1
                ? formData.docType || "Select Document Type"
                : "Multiple document types"}{" "}
            </Text>
            <Icon
              style={styles.iconLarge}
              name="arrow-ios-downward-outline"
              fill="black"
            />
          </TouchableOpacity>
          {!!formData.docType
            .split("_")
            .filter((type: string) => type.includes("Other")).length && (
            <>
              <Input
                style={styles.searchInput}
                placeholder="Document name"
                value={formData.documentName}
                onChangeText={(text) =>
                  setFormData({ ...formData, documentName: text })
                }
              />
            </>
          )}
        </View>
        <View style={styles.divider} />
        <RichTextEditor
          placeholder="Add message here"
          setText={(text: any) => setFormData({ ...formData, message: text })}
          fileUploadButtonClickCallback={() =>
            uploadDocument((doc: any) => {
              if (doc.size < 10485760) {
                //25mb
                setAttachments([
                  ...attachments,
                  { name: doc.name, uri: doc.uri },
                ]);
              } else {
                setErrorMessage("File too large. Upload limit: 10MB.");
              }
            })
          }
        />
        {!!attachments.length && (
          <ScrollView style={{ maxHeight: 100 }}>
            <Text style={styles.modalText}>Attachments: </Text>
            {attachments.map((attachment: any, idx: number) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: 5,
                }}
              >
                <Button
                  onPress={() =>
                    setAttachments(
                      attachments.filter((_, idx2: number) => idx !== idx2)
                    )
                  }
                  style={{ ...styles.modalButton, flex: 1 }}
                  appearance="outline"
                  children={() => (
                    <Text style={styles.uploadButtonText}>
                      {attachment.name} (click to remove)
                    </Text>
                  )}
                />
                {/* <Button appearance="filled" status="danger">
              X
            </Button> */}
              </View>
            ))}
          </ScrollView>
        )}
        <View style={styles.divider} />
        <View style={styles.modalFooterRow}>
          <Button
            style={{
              ...styles.modalButton,
              //@ts-ignore
              background:
                "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
            }}
            onPress={addNewRequest}
          >
            Submit Request
          </Button>
        </View>
        <Text style={styles.error}>{errorMessage}</Text>
        {isDocumentTypeModalOpen && (
          <View style={styles.modalDocTypeModal}>
            <Input
              ref={searchRef}
              multiline={false}
              textStyle={{ color: "black" }}
              style={styles.searchInput}
              accessoryRight={SearchIcon}
              placeholder="Search"
              status="basic"
              value={search}
              onChangeText={(text) => setSearch(text)}
            />
            <List
              style={styles.modalDocTypeList}
              data={documentTypes}
              renderItem={modalRenderItem}
            />
          </View>
        )}
      </ThemeProvider>
    </Modal>
  );
};

export default RequestModal;
