import { useLinkTo } from "@react-navigation/native";
import {
  Text,
  useStyleSheet,
  Icon,
  IndexPath,
  Input,
  Button,
  Spinner,
  Select,
  SelectItem,
  CheckBox,
  Modal,
} from "@ui-kitten/components";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { View, Dimensions, TouchableOpacity } from "react-native";

import ListInput from "../../components/ListInput";
import {
  acceptedBusinessStateOptions,
  businessRoleOptions,
  businessTypeOptions,
  listOfStatesOptions,
  countyOptionsByState,
  stateFullNamesToShortNames,
} from "../../utils/constants";
import { themedStyles } from "./themedStyles";
import { useStore } from "../../stores";

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

const BusinessInformationStep: React.FC<any> = ({
  currentQuestionID,
  setCurrentQuestionID,
  loading,
  formData,
  setFormData,
  onSubmitForm,
  onContinue,
  errorMessages,
  sameAddressForLoan,
  setSameAddressForLoan,
  tradeNamesNotApplicable,
  setTradeNamesNotApplicable,
  submitActionButtonIsDisabled,
}) => {
  const { width } = Dimensions.get("window");
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);

  const RightArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  const LeftArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-back-outline" />
  );
  const BriefcaseIcon = (props: any) => (
    <Icon {...props} name="briefcase-outline" />
  );
  const CloudUploadIcon = (props: any) => (
    <Icon {...props} fill="white" name="cloud-upload-outline" />
  );
  const LocationIcon = (props: any) => <Icon {...props} name="pin-outline" />;
  const QuestionIcon = (props: any) => (
    <TouchableOpacity
      //@ts-ignore
      onMouseEnter={() => setShowTradeInfo(true)}
      onMouseLeave={() => setShowTradeInfo(false)}
    >
      <Icon {...props} name="question-mark-circle-outline" />
    </TouchableOpacity>
  );

  const [isBusinessNameEditable, setIsBusinessNameEditable] = useState(true);

  const [showTradeInfo, setShowTradeInfo] = useState(false);

  const isNextActionButtonDisabled = useCallback(() => {
    return (
      !formData.businessRole ||
      !formData.businessType ||
      !formData.businessName ||
      !formData.businessAddStreetLine1 ||
      !formData.businessAddCity ||
      !formData.businessAddState ||
      !formData.businessAddZipCode ||
      !formData.stateOfIncorporation ||
      (!formData.jurisdictionCounty &&
        !["Washington DC", "District Of Columbia"].includes(
          formData.businessAddState
        )) ||
      (!formData.tradeNames.length && !tradeNamesNotApplicable)
    );
  }, [formData, tradeNamesNotApplicable]);

  const businessNameRef = useRef<Input>(null);
  const businessAddStreetLine1Ref = useRef<Input>(null);
  const businessAddStreetLine2Ref = useRef<Input>(null);
  const businessAddCityRef = useRef<Input>(null);
  const businessAddZipCodeRef = useRef<Input>(null);
  const workEmailRef = useRef<Input>(null);

  const [isBusinessModal, setIsBusinessModal] = useState(false);
  const [selectedBusinessAddStateIndex, setBusinessAddStateIndex] =
    useState<any>(null);
  const [
    selectedStateOfIncorporationIndex,
    setSelectedStateOfIncorporationIndex,
  ] = useState<any>(null);
  const [selectedBusinessRoleIndex, setSelectedBusinessRoleIndex] =
    useState<any>(null);
  const [selectedBusinessTypeIndex, setSelectedBusinessTypeIndex] =
    useState<any>(null);
  const [jurisdictionCountyIndex, setJurisdictionCountyIndex] =
    useState<any>(null);

  const countyOptions =
    formData.businessAddState && //@ts-ignore
    stateFullNamesToShortNames[formData.businessAddState] && //@ts-ignore
    countyOptionsByState[
      //@ts-ignore
      stateFullNamesToShortNames[formData.businessAddState]
    ]
      ? Object.keys(
          //@ts-ignore
          countyOptionsByState[
            //@ts-ignore
            stateFullNamesToShortNames[formData.businessAddState]
          ]
        )
      : [];

  useEffect(() => {
    if (formData.businessName) {
      setIsBusinessNameEditable(false);
    }
  }, []);

  // TODO: consider removing this (as it causes unnecessary rerender) and just updating from state on form input handlers
  useEffect(() => {
    setFormData({
      ...formData,
      ...(selectedBusinessRoleIndex
        ? {
            businessRole:
              businessRoleOptions[selectedBusinessRoleIndex?.row] || "",
          }
        : {}),
      ...(selectedBusinessTypeIndex
        ? {
            businessType:
              businessTypeOptions[selectedBusinessTypeIndex?.row] || "",
          }
        : {}),
      ...(selectedStateOfIncorporationIndex
        ? {
            stateOfIncorporation:
              listOfStatesOptions[selectedStateOfIncorporationIndex?.row] || "",
          }
        : {}),
      ...(tradeNamesNotApplicable
        ? {
            tradeNames: [],
          }
        : {}),
      ...(jurisdictionCountyIndex
        ? {
            jurisdictionCounty: countyOptions[jurisdictionCountyIndex.row],
          }
        : {}),
      ...(selectedBusinessAddStateIndex
        ? {
            businessAddState:
              acceptedBusinessStateOptions[
                selectedBusinessAddStateIndex?.row
              ] || "",
          }
        : {}),
    });
    if (selectedBusinessAddStateIndex) {
      setJurisdictionCountyIndex(undefined);
    }
  }, [
    selectedBusinessRoleIndex,
    selectedBusinessTypeIndex,
    selectedStateOfIncorporationIndex,
    tradeNamesNotApplicable,
    selectedBusinessAddStateIndex,
    jurisdictionCountyIndex,
  ]);

  return (
    <View style={width > 992 ? styles.register : styles.registerMobile}>
      <Text style={width > 576 ? styles.header : styles.headerMobile}>
        Add your business details
      </Text>
      <Text style={width > 576 ? styles.subHeader : styles.subHeaderMobile}>
        Already registered?{" "}
        <Text
          status="info"
          style={
            width > 576
              ? {
                  ...styles.link,
                  fontSize: 18,
                  fontFamily: "Montserrat_500Medium",
                }
              : {
                  ...styles.link,
                  fontSize: 13,
                  fontFamily: "Montserrat_500Medium",
                }
          }
          onPress={() => linkTo("/auth/log-in")}
        >
          Login
        </Text>
        .
      </Text>

      <Text style={{ marginBottom: 40 }}>
        {errorMessages?.nonFieldErrors && (
          <Text
            category="h6"
            style={width > 576 ? styles.error : styles.errorMobile}
          >
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>

      <View>
        <Text>
          {errorMessages?.businessRole && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessRole}
            </Text>
          )}
        </Text>
        <View>
          {/* status="primary" for modified dark mode styling of Select component */}
          <Select
            status="primary"
            size={width > 576 ? "medium" : "small"}
            style={width > 576 ? styles.input : styles.inputMobile}
            placeholder="Title/Business Role"
            value={formData.businessRole}
            selectedIndex={selectedBusinessRoleIndex}
            onSelect={(index) => setSelectedBusinessRoleIndex(index)}
          >
            {businessRoleOptions.map((option, idx) => (
              <SelectItem title={option} key={`${idx}-business-role-option`} />
            ))}
          </Select>
        </View>
        <Text>
          {errorMessages?.businessType && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessType}
            </Text>
          )}
        </Text>
        <View>
          <Select
            status="primary"
            size={width > 576 ? "medium" : "small"}
            style={width > 576 ? styles.input : styles.inputMobile}
            placeholder="Business Type"
            value={formData.businessType}
            selectedIndex={selectedBusinessTypeIndex}
            onSelect={(index) => setSelectedBusinessTypeIndex(index)}
          >
            {businessTypeOptions.map((option, idx) => (
              <SelectItem title={option} key={`${idx}-business-type-option`} />
            ))}
          </Select>
        </View>
        <Text>
          {errorMessages?.businessName && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessName}
            </Text>
          )}
        </Text>
        <Input
          disabled={!isBusinessNameEditable}
          ref={businessNameRef}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Business Name"
          autoCorrect={false}
          textContentType="organizationName"
          autoCompleteType="off"
          value={formData.businessName}
          accessoryRight={BriefcaseIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, businessName: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddStreetLine1Ref.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddStreetLine1Ref.current?.focus()}
          returnKeyType="next"
          testID="CompanyField"
        />
        <Text>
          {errorMessages?.businessAddress && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessAddress}
            </Text>
          )}
        </Text>
        <Text>
          {errorMessages?.businessAddStreetLine1 && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessAddStreetLine1}
            </Text>
          )}
        </Text>
        <Input
          ref={businessAddStreetLine1Ref}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Street Address Line 1"
          autoCorrect={false}
          maxLength={40}
          value={formData.businessAddStreetLine1}
          textContentType="none"
          autoCompleteType="off"
          accessoryRight={LocationIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, businessAddStreetLine1: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddStreetLine2Ref.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddStreetLine2Ref.current?.focus()}
          returnKeyType="next"
          testID="BusinessAddLine1Field"
        />
        <Text>
          {errorMessages?.businessAddStreetLine2 && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.businessAddStreetLine2}
            </Text>
          )}
        </Text>
        <Input
          ref={businessAddStreetLine2Ref}
          style={width > 576 ? styles.input : styles.inputMobile}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Street Address Line 2"
          autoCorrect={false}
          textContentType="none"
          autoCompleteType="off"
          maxLength={40}
          value={formData.businessAddStreetLine2}
          accessoryRight={LocationIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, businessAddStreetLine2: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddCityRef.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddCityRef.current?.focus()}
          returnKeyType="next"
          testID="BusinessAddLine2Field"
        />
        <View style={width > 992 ? styles.flexRow : {}}>
          <View style={{ flex: 1 }}>
            <Text>
              {errorMessages?.businessAddCity && (
                <Text
                  category="h6"
                  style={width > 576 ? styles.error : styles.errorMobile}
                >
                  {errorMessages?.businessAddCity}
                </Text>
              )}
            </Text>
            <Input
              ref={businessAddCityRef}
              style={width > 576 ? styles.input : styles.inputMobile}
              textStyle={
                width > 576 ? styles.inputText : styles.inputTextMobile
              }
              placeholder="City"
              autoCorrect={false}
              textContentType="none"
              autoCompleteType="off"
              value={formData.businessAddCity}
              onChangeText={(text) =>
                setFormData({ ...formData, businessAddCity: text })
              }
              onKeyPress={(event: any) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  businessAddZipCodeRef.current?.focus();
                }
              }}
              onSubmitEditing={() => businessAddZipCodeRef.current?.focus()}
              returnKeyType="next"
              testID="BusinessAddCityField"
            />
          </View>
          <View style={{ flex: 1 }}>
            <Text>
              {errorMessages?.businessAddState && (
                <Text
                  category="h6"
                  style={width > 576 ? styles.error : styles.errorMobile}
                >
                  {errorMessages?.businessAddState}
                </Text>
              )}
            </Text>
            <Select
              status="primary"
              size={width > 576 ? "medium" : "small"}
              style={width > 576 ? styles.input : styles.inputMobile}
              placeholder="State"
              value={formData.businessAddState}
              selectedIndex={selectedBusinessAddStateIndex}
              onSelect={(index) => setBusinessAddStateIndex(index)}
            >
              {acceptedBusinessStateOptions.map((option, idx) => (
                <SelectItem
                  title={option}
                  key={`${idx}-state-business-option`}
                />
              ))}
            </Select>
          </View>
          <View style={{ flex: 1 }}>
            <Text>
              {errorMessages?.businessAddZipCode && (
                <Text
                  category="h6"
                  style={width > 576 ? styles.error : styles.errorMobile}
                >
                  {errorMessages?.businessAddZipCode}
                </Text>
              )}
            </Text>
            <Input
              ref={businessAddZipCodeRef}
              style={width > 576 ? styles.input : styles.inputMobile}
              textStyle={
                width > 576 ? styles.inputText : styles.inputTextMobile
              }
              placeholder="Zip Code"
              autoCorrect={false}
              textContentType="none"
              autoCompleteType="off"
              value={formData.businessAddZipCode}
              onChangeText={(text) =>
                setFormData({
                  ...formData,
                  businessAddZipCode: /^\d{0,5}$/.test(text)
                    ? text
                    : formData.businessAddZipCode,
                })
              }
              onKeyPress={(event: any) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  workEmailRef.current?.focus();
                }
              }}
              returnKeyType="next"
              testID="BusinessAddZipCodeField"
            />
          </View>
        </View>
        <CheckBox
          style={styles.filterCheckbox}
          checked={sameAddressForLoan}
          onChange={() => setSameAddressForLoan(!sameAddressForLoan)}
          children={() => (
            <Text
              style={
                width > 576
                  ? { ...styles.inputText, paddingLeft: 10 }
                  : { ...styles.inputTextMobile, paddingLeft: 10 }
              }
            >
              Is this the same address for the business applying for the loan?
            </Text>
          )}
        />
        <Text>
          {errorMessages?.stateOfIncorporation && (
            <Text
              category="h6"
              style={width > 576 ? styles.error : styles.errorMobile}
            >
              {errorMessages?.stateOfIncorporation}
            </Text>
          )}
        </Text>
        <Select
          status="primary"
          size={width > 576 ? "medium" : "small"}
          style={width > 576 ? styles.input : styles.inputMobile}
          placeholder="State of Incorporation"
          value={formData.stateOfIncorporation}
          selectedIndex={selectedStateOfIncorporationIndex}
          onSelect={(index) => setSelectedStateOfIncorporationIndex(index)}
        >
          {listOfStatesOptions.map((option, idx) => (
            <SelectItem
              title={option}
              key={`${idx}-state-of-incorporation-option`}
            />
          ))}
        </Select>

        {!["Washington DC", "District Of Columbia"].includes(
          formData.businessAddState
        ) && (
          <Select
            status="primary"
            size={width > 576 ? "medium" : "small"}
            style={width > 576 ? styles.input : styles.inputMobile}
            disabled={!formData.businessAddState}
            selectedIndex={jurisdictionCountyIndex}
            value={formData.jurisdictionCounty}
            placeholder="Jurisdiction county"
            onSelect={(index: IndexPath | IndexPath[]) => {
              if (Array.isArray(index)) {
                index = index[0];
              }
              setJurisdictionCountyIndex(index);
            }}
          >
            {countyOptions.map((title: any) => (
              <SelectItem key={title} title={title} />
            ))}
          </Select>
        )}

        {showTradeInfo && (
          <Text style={styles.hoverHelpText}>
            Enter any Trade or Doing Business As (DBA) names used by the company
            in the past 5 years.
          </Text>
        )}
        <ListInput
          disabled={tradeNamesNotApplicable}
          placeholder="All trade names"
          inputStyle={{ ...styles.input, marginBottom: 15 }}
          accessoryRight={QuestionIcon}
          values={formData.tradeNames}
          setValues={(newValues: any) =>
            setFormData({ ...formData, tradeNames: newValues })
          }
        />
        <CheckBox
          style={styles.checkbox}
          checked={tradeNamesNotApplicable}
          onChange={() => setTradeNamesNotApplicable(!tradeNamesNotApplicable)}
          children={() => (
            <Text style={{ ...styles.inputText, paddingLeft: 5 }}>
              Not Applicable
            </Text>
          )}
        />
      </View>

      <View style={width > 576 && styles.flexRow}>
        <Button
          style={
            width > 576
              ? styles.nextActionButton
              : styles.nextActionButtonMobile
          }
          status="basic"
          onPress={() => setCurrentQuestionID(currentQuestionID + 1)}
          accessoryRight={RightArrowIcon}
          children={() => <Text style={styles.nextActionButtonText}>Next</Text>}
        />
        {!submitActionButtonIsDisabled && (
          <Button
            disabled={submitActionButtonIsDisabled}
            style={
              submitActionButtonIsDisabled
                ? width > 576
                  ? styles.disabledActionButton
                  : styles.disabledActionButtonMobile
                : width > 576
                ? styles.nextActionButton
                : styles.nextActionButtonMobile
            }
            status="basic"
            onPress={() => {
              if (sameAddressForLoan) {
                onContinue();
              } else setIsBusinessModal(true);
            }}
            accessoryRight={loading ? LoadingIndicator : CloudUploadIcon}
            children={() => (
              <Text
                style={
                  submitActionButtonIsDisabled
                    ? styles.disabledActionButtonText
                    : styles.nextActionButtonText
                }
              >
                Submit
              </Text>
            )}
          />
        )}
      </View>
      <Modal
        visible={isBusinessModal}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => setIsBusinessModal(false)}
      >
        <View style={styles.modal}>
          <Text style={{ ...styles.subHeader, marginBottom: 5 }}>
            Please enter the address of the business applying for the loan
          </Text>
          <Button
            style={styles.closeModalButton}
            onPress={() => setIsBusinessModal(false)}
          >
            CLOSE
          </Button>
        </View>
      </Modal>
    </View>
  );
};

export default BusinessInformationStep;
