import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Card,
  Divider,
  Modal,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import { applicationName } from "expo-application";
import * as DocumentPicker from "expo-document-picker";
import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";

import Header from "../../components/Header";
import RequestModal from "../../components/RequestModal";
import RichTextEditor from "../../components/RichTextEditor";
import DocumentRequestMessage from "../../models/DocumentRequestMessage";
import * as api from "../../services/api";
import { getLoanSummary } from "../../services/api";
import { useStore } from "../../stores";
import { NewRequestForm } from "./NewRequestForm";
import { themedStyles } from "./themedStyles";

export const downloadFile = async (
  attachmentId: string,
  name: string,
  token: string
) => {
  const link = document.createElement("a");
  const dataUri = await api
    .fetchDocumentRequestMessageAttachment(token, attachmentId)
    .then((response) => response.response.entities);
  const b64toBlob = (dataUri: string) =>
    fetch(dataUri).then((res) => res.blob());
  link.href = window.URL.createObjectURL(await b64toBlob(dataUri));
  //link.href = window.URL.createObjectURL(new Blob([path], { type }));
  link.download = name;
  document.body.appendChild(link);
  link.click();
};

const LoadingIndicator = (_props: any) => <Spinner status="basic" />;

export const uploadDocument = async (setDocument: any) => {
  try {
    const result = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
    });
    setDocument(result);
    console.log(result);
  } catch (err) {
    throw err;
  }
};

const AdminRequestCenter: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const colorBar = require("../../../assets/colorBar.svg");
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;
  const FileIcon = (props: any) => <Icon {...props} name="file-text-outline" />;
  const SaveIcon = (props: any) => <Icon {...props} name="save-outline" />;

  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentRequestOpen, setCurrentRequestOpen] = useState(-2);
  const [shouldRefetchRequestsCounter, setShouldRefetchRequestsCounter] =
    useState(0);
  const [requestedDocuments, setRequestedDocuments]: any = useState([]);
  const [requestedDocumentsDisplayList, setRequestedDocumentsDisplayList]: any =
    useState([]);

  const [fileToSave, setFileToSave]: any = useState();
  const [categoryToSave, setCategoryToSave] = useState("");
  const [typeToSave, setTypeToSave] = useState("");
  const [typeToSaveOptions, setTypeToSaveOptions] = useState([""]);
  const [typeToSaveCategories, setTypeToSaveCategories] = useState([""]);
  const [typeToSaveIndex, setTypeToSaveIndex] = useState<any>(null);
  const [isSaveFileModalOpen, setIsSaveFileModalOpen] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [businessName, setBusinessName] = useState("");

  const [richText, setRichText] = useState<any>("");

  const { authStore, documentRequestsStore, loanStore } = useStore();

  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      getLoanSummary(authStore?.accessToken, applicationId).then((resp) => {
        console.log(resp);
        const response = resp.response.entities;
        loanStore.createOrUpdateLoanApplication(response);

        setBusinessName(response["businessName"]);
      });
    }
  }, []);

  useEffect(() => {
    if (typeToSaveIndex) {
      setTypeToSave(typeToSaveOptions[typeToSaveIndex.row]);
      setCategoryToSave(typeToSaveCategories[typeToSaveIndex.row]);
    }
  }, [typeToSaveIndex]);

  useEffect(() => {
    setLoading(true);
    const filtered = requestedDocuments.filter(
      (doc: any) =>
        Object.values(doc).filter((field) =>
          `${field}`.toLowerCase().includes(searchString.toLowerCase())
        ).length
    );
    filtered.sort((a: any, b: any) => {
      console.log(new Date(a.created));
      return new Date(a.created) > new Date(b.created) ? -1 : 1;
    });

    setCurrentRequestOpen(filtered.length ? 0 : -2);
    setRequestedDocumentsDisplayList(filtered);
    setLoading(false);
  }, [requestedDocuments, searchString]);

  const getMessageDisplayObj = (docReqMessage: any) => ({
    ...docReqMessage,
    applicationId: docReqMessage.loanApplicationId,
    title: `Request for ${
      docReqMessage.documentRequestType === "Other"
        ? docReqMessage.documentName && docReqMessage.documentName !== null
          ? docReqMessage.documentName
          : `${docReqMessage.documentCategory} File`
        : docReqMessage.documentRequestType
    }`,
    for: docReqMessage.applicantName,
    date: new Date(docReqMessage.created).toLocaleDateString(),
    attachments: docReqMessage.attachments || [],
  });

  const fetchDocumentRequestMessagesForApplication = (applicationId: any) => {
    if (!applicationId || Number.isNaN(Number(applicationId))) return;
    setLoading(true);
    documentRequestsStore
      .fetchDocumentRequestMessagesForApplication(applicationId)
      .then(() => {
        const reqDocuments: any = [];
        const replies: any = {};
        documentRequestsStore.messages.forEach((msg) => {
          console.log("msg", msg);
          if (Number(applicationId) === Number(msg.loanApplicationId)) {
            if (msg.isReply) replies[msg.id] = msg;
            else reqDocuments.push(getMessageDisplayObj(msg.$));
          }
        });
        console.log(reqDocuments);
        reqDocuments.sort((a: any, b: any) =>
          new Date(a) < new Date(b) ? -1 : 1
        );
        reqDocuments.forEach((doc: any) => {
          doc.replies = doc.replies.map((replyId: any) => replies[replyId]);
          doc.doesHaveUnreadReplies = doc.replies.filter(
            (reply: any) =>
              reply.isUnread && reply.underwriterId !== authStore?.user?.id
          ).length;
        });
        setRequestedDocuments(reqDocuments);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchDocumentRequestMessagesForApplication(applicationId);
  }, [applicationId, shouldRefetchRequestsCounter]);

  useEffect(() => {
    fetchDocumentRequestMessagesForApplication(applicationId);
  }, []);

  const addNewRequest = async (req: any) => {
    const res = await documentRequestsStore.addDocumentRequestMessage(
      // @ts-ignore
      new DocumentRequestMessage({
        id: -1,
        loanApplicationId: applicationId,
        applicantBusinessName: businessName,
        authorId: authStore.user?.id || null,
        body: req.body,
        documentRequestType: req.requestedDocument,
        attachments: req.attachments,
        rootMessageId: null,
        parentMessageId: null,
      })
    );
    if (res?.ok)
      setRequestedDocuments([
        getMessageDisplayObj(res?.extra?.newDocumentRequest),
        ...requestedDocuments,
      ]);
  };

  const addNewReply = async (req: any) => {
    const res = await documentRequestsStore.addDocumentRequestMessage(
      // @ts-ignore
      new DocumentRequestMessage({
        id: -1,
        loanApplicationId: applicationId,
        authorId: authStore.user?.id || null,
        body: req.body,
        attachments: req.attachments,
        rootMessageId: req.rootMessageId,
        parentMessageId: req.parentMessageId,
      })
    );
    if (res?.ok)
      setRequestedDocuments(
        requestedDocuments.map((doc: any, idx: number) => {
          if (idx !== currentRequestOpen) return doc;
          return {
            ...doc,
            replies: [
              ...doc.replies,
              getMessageDisplayObj(res?.extra?.newDocumentRequest),
            ],
          };
        })
      );
  };

  const saveFile = async () => {
    const dataUri = await api
      .fetchDocumentRequestMessageAttachment(
        authStore?.accessToken || "",
        fileToSave.id
      )
      .then((response) => response.response.entities);
    const b64toBlob = async (dataUri: string) =>
      fetch(dataUri).then((res) => res.blob());

    const blob = await b64toBlob(dataUri);
    const file = new File([blob], fileToSave.name, {
      lastModified: Date.now(),
    });

    const formData: any = {
      uploadedDocument: file,
      docType: typeToSave,
      categoryType: categoryToSave,
      documentName: categoryToSave
        ? requestedDocumentsDisplayList[currentRequestOpen].documentName
        : "",
      requestAttachmentId: fileToSave.id,
    };
    const data = new FormData();
    data.append("uploadedDocument", formData["uploadedDocument"]);
    data.append("docType", formData["docType"]);
    data.append("categoryType", formData["categoryType"]);
    data.append("documentName", formData["documentName"]);
    data.append("requestAttachmentId", formData["requestAttachmentId"]);

    api
      .uploadDocumentFromUnderwriterPortal(
        authStore.accessToken || "",
        applicationId,
        data
      )
      .then((resp) => {
        console.log(resp.response.entities);
        setIsSaveFileModalOpen(false);
        setShouldRefetchRequestsCounter(shouldRefetchRequestsCounter + 1);
        setOpenSuccessModal(true);
      });
  };

  useEffect(() => {
    if (currentRequestOpen >= 0) {
      const doc = requestedDocumentsDisplayList[currentRequestOpen];
      const body = requestedDocumentsDisplayList[currentRequestOpen].body;
      console.log(body);
      if (body.includes("eyJvcHMiO")) {
        setRichText(JSON.parse(Buffer.from(body, "base64").toString("ascii")));
      } else {
        setRichText(`{"ops":[{"insert":"${body}\n"}]}`);
      }
      doc.replies.forEach(async (reply: any) => {
        if (reply.isUnread && reply.underwriterId !== authStore?.user?.id)
          //@ts-ignore
          await documentRequestsStore.updateDocumentRequestMessage(reply.id, {
            isUnread: false,
          });
      });
    } else {
      setRichText({});
    }
  }, [currentRequestOpen]);

  const renderRichText = () => {
    if (richText.ops !== undefined) {
      const render = require("quill-render");
      const rtArray = richText.ops;
      return render(rtArray);
    }
  };

  return (
    <Layout style={styles.container}>
      <RequestModal
        applicationId={applicationId}
        isOpen={currentRequestOpen === -1}
        setIsOpen={() => {
          setCurrentRequestOpen(-2);
        }}
        applicantName={applicationName}
        businessName={businessName}
        openRequestSuccess={() => {
          setShouldRefetchRequestsCounter(shouldRefetchRequestsCounter + 1);
          setCurrentRequestOpen(-2);
        }}
      />
      <Modal
        visible={isSaveFileModalOpen}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setIsSaveFileModalOpen(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>
            {`Save file ${fileToSave?.name || ""} as `}
            <Select
              style={styles.select}
              placeholder="Requested Document"
              value={typeToSave}
              selectedIndex={typeToSaveIndex}
              onSelect={(index: any) => setTypeToSaveIndex(index)}
            >
              {typeToSaveOptions.map((option, idx) => (
                <SelectItem
                  style={styles.selectItem}
                  title={() => <Text style={styles.selectItem}>{option}</Text>}
                  key={`${idx}-category-to-save-option`}
                />
              ))}
            </Select>

            {` for application ${applicationId}?`}
          </Text>
          <Text>
            {!!fileToSave?.type &&
              `Note: this file was last saved for request document type: "${fileToSave?.type}". \nSaving same attachment for multiple documents will prevent document request status to automatically change to completed (it will need to be done manually in request center).`}
          </Text>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => saveFile()}
              disabled={!typeToSave}
            >
              <Text style={styles.modalButtonText}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.modalNoButton}
              onPress={() => setIsSaveFileModalOpen(false)}
            >
              <Text style={styles.modalButtonText}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <Modal
        visible={openSuccessModal}
        backdropStyle={styles.modalBackdrop}
        onBackdropPress={() => setOpenSuccessModal(false)}
      >
        <View style={styles.modalContainer}>
          <Text style={styles.modalText}>File successfully saved.</Text>
          <View style={styles.modalRow}>
            <TouchableOpacity
              style={styles.modalYesButton}
              onPress={() => setOpenSuccessModal(false)}
            >
              <Text style={styles.modalButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <View style={styles.colorBar}>
          <Image
            style={{
              width: 43,
              height: Dimensions.get("window").height - 68,
            }}
            source={colorBar}
          />
        </View>
        <Header theme="light" />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>Admin Dashboard</Text>
            <Text style={styles.welcomeMessageText} appearance="hint">
              {`Welcome, ${authStore.user?.firstName} ${authStore.user?.lastName}`}
            </Text>
            <View style={styles.requestedDocumentsHeader}>
              <Text style={styles.header}>
                Request Center
                <Text style={styles.subheader} appearance="hint">
                  {applicationId ? ` for application ${applicationId}` : <></>}
                </Text>
              </Text>
              <View style={{ ...styles.flexRow, flex: 1 }}>
                <Input
                  style={styles.input}
                  placeholder="Search"
                  value={searchString}
                  onChangeText={(text) => setSearchString(text)}
                  accessoryRight={SearchIcon}
                />
              </View>
            </View>
            <View style={styles.requestedDocumentsContainer}>
              <ScrollView style={styles.requestedDocumentsSidebar}>
                {loading ? <LoadingIndicator /> : <></>}
                {!loading && applicationId ? (
                  <Card
                    style={
                      // @ts-ignore
                      styles[
                        currentRequestOpen === -1
                          ? "selectedNewRequestDocument"
                          : "newRequestDocument"
                      ]
                    }
                    onPress={() => setCurrentRequestOpen(-1)}
                    appearance="filled"
                  >
                    <View style={styles.flexRow}>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.header}>New Request</Text>
                        <Text style={{ ...styles.header, fontSize: 15 }}>
                          for application {applicationId}
                        </Text>
                        <Text
                          appearance="hint"
                          style={{ ...styles.header, fontSize: 13 }}
                        >
                          {new Date().toLocaleDateString()}
                        </Text>
                      </View>
                    </View>
                  </Card>
                ) : (
                  <></>
                )}
                {!loading ? (
                  requestedDocumentsDisplayList.map((req: any, idx: number) => (
                    <Card
                      style={
                        // @ts-ignore
                        styles[
                          idx === currentRequestOpen
                            ? "selectedRequestDocument"
                            : "requestDocument"
                        ]
                      }
                      key={req.id}
                      onPress={() => setCurrentRequestOpen(idx)}
                      appearance="filled"
                    >
                      <View style={styles.flexRow}>
                        <View>
                          {!!req.doesHaveUnreadReplies && (
                            <View style={styles.activeNotificationCircle} />
                          )}
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text style={styles.header}>{req.for}</Text>
                          <Text style={{ ...styles.header, fontSize: 15 }}>
                            {req.title}
                          </Text>
                          <Text
                            appearance="hint"
                            style={{ ...styles.header, fontSize: 13 }}
                          >
                            {req.date}
                          </Text>
                        </View>
                      </View>
                    </Card>
                  ))
                ) : (
                  <></>
                )}
              </ScrollView>
              <ScrollView style={styles.requestedDocumentContent}>
                {!requestedDocumentsDisplayList ||
                !requestedDocumentsDisplayList[currentRequestOpen] ||
                loading ? (
                  currentRequestOpen !== -2 ? (
                    <LoadingIndicator />
                  ) : (
                    <></>
                    // <NewRequestForm addNewRequest={addNewRequest} />
                  )
                ) : (
                  <>
                    <Text style={styles.requestTitle}>
                      {requestedDocumentsDisplayList[currentRequestOpen].title}
                    </Text>
                    <Text style={styles.requestSubHeader}>
                      {`Loan ID ${applicationId} | ${businessName}`}
                    </Text>
                    <Text style={styles.requestDate} appearance="hint">
                      {requestedDocumentsDisplayList[currentRequestOpen].date}
                    </Text>
                    <Divider />
                    <View style={{ height: 300 }}>
                      <RichTextEditor
                        readOnly
                        initialText={
                          requestedDocumentsDisplayList[currentRequestOpen].body
                        }
                      />
                    </View>
                    {requestedDocumentsDisplayList[currentRequestOpen]
                      .attachmentsMeta.length ? (
                      <>
                        <Divider />
                        <Text style={styles.requestSectionTitle}>
                          Attachments
                        </Text>
                        <View
                          style={{
                            ...styles.flexRow,
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                          }}
                        >
                          {requestedDocumentsDisplayList[
                            currentRequestOpen
                          ].attachmentsMeta.map((file: any) => (
                            <Button
                              key={`file-${file.name}`}
                              style={styles.attachment}
                              appearance="outline"
                              accessoryLeft={FileIcon}
                              onPress={() =>
                                downloadFile(
                                  file.id,
                                  file.name,
                                  authStore?.accessToken || ""
                                )
                              }
                            >
                              {file.name}
                            </Button>
                          ))}
                        </View>
                      </>
                    ) : (
                      <></>
                    )}
                    {requestedDocumentsDisplayList[currentRequestOpen].replies
                      .length ? (
                      <>
                        {requestedDocumentsDisplayList[
                          currentRequestOpen
                        ].replies.map((reply: any) => (
                          <View key={"reply-" + reply.id}>
                            <Divider />
                            <Text style={styles.requestSectionTitle}>
                              Reply
                              {"\n"}
                            </Text>
                            <RichTextEditor readOnly initialText={reply.body} />
                            <Divider />
                            {reply.attachmentsMeta &&
                            reply.attachmentsMeta.length ? (
                              <Text style={styles.requestSectionTitle}>
                                Attachments
                              </Text>
                            ) : (
                              <></>
                            )}
                            <View
                              style={{
                                ...styles.flexRow,
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                              }}
                            >
                              {reply.attachmentsMeta &&
                                reply.attachmentsMeta.map((file: any) => (
                                  <View
                                    key={`file-${file.name}`}
                                    style={styles.flexRow}
                                  >
                                    <Button
                                      style={styles.attachment}
                                      appearance="outline"
                                      accessoryLeft={FileIcon}
                                      onPress={() =>
                                        downloadFile(
                                          file.id,
                                          file.name,
                                          authStore?.accessToken || ""
                                        )
                                      }
                                    >
                                      {file.name}
                                    </Button>
                                    <Button
                                      style={styles.saveButton}
                                      appearance="outline"
                                      accessoryLeft={SaveIcon}
                                      onPress={() => {
                                        setFileToSave(file);
                                        const types =
                                          requestedDocumentsDisplayList[
                                            currentRequestOpen
                                          ].documentRequestType
                                            .split("_")
                                            .map((type: any) => {
                                              if (type.includes("Other")) {
                                                return (
                                                  requestedDocumentsDisplayList[
                                                    currentRequestOpen
                                                  ].documentName ||
                                                  `${requestedDocumentsDisplayList[currentRequestOpen].documentCategory} File`
                                                );
                                              }
                                              return type;
                                            });

                                        const categories =
                                          requestedDocumentsDisplayList[
                                            currentRequestOpen
                                          ].documentRequestType
                                            .split("_")
                                            .map((category: any) => {
                                              if (category.includes("Other")) {
                                                return requestedDocumentsDisplayList[
                                                  currentRequestOpen
                                                ].documentCategory;
                                              }
                                              return "";
                                            });

                                        setTypeToSaveIndex(
                                          types.length !== 1
                                            ? null
                                            : new IndexPath(0)
                                        );
                                        setTypeToSave(
                                          types.length !== 1 ? "" : types[0]
                                        );
                                        setCategoryToSave(
                                          types.length !== 1
                                            ? ""
                                            : categories[0]
                                        );
                                        setTypeToSaveOptions(types);
                                        setTypeToSaveCategories(categories);
                                        setIsSaveFileModalOpen(true);
                                      }}
                                    />
                                  </View>
                                ))}
                            </View>
                          </View>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                    <View>
                      <Divider />
                      <NewRequestForm
                        addNewRequest={addNewReply}
                        rootMessageId={
                          requestedDocumentsDisplayList[currentRequestOpen].id
                        }
                        parentMessageId={
                          requestedDocumentsDisplayList[currentRequestOpen]
                            .replies.length
                            ? requestedDocumentsDisplayList[currentRequestOpen]
                                .replies[
                                requestedDocumentsDisplayList[
                                  currentRequestOpen
                                ].replies.length - 1
                              ].id
                            : requestedDocumentsDisplayList[currentRequestOpen]
                                .id
                        }
                      />
                    </View>
                  </>
                )}
              </ScrollView>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default AdminRequestCenter;
