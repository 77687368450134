import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "auto",
  },
  input: {
    flex: 1,
    fontSize: 22,
    margin: 5,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  filterCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    padding: 5,
  },
  filterSubCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 2,
    padding: 2,
    marginHorizontal: 20,
  },
  LogInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_500Normal",
    lineHeight: 24,
    fontSize: 13,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    margin: 2,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 600,
    flex: 1,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#EDF1F7",
  },
  tableHeadersCellText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "bold",
    lineHeight: 24,
    fontSize: 15,
    padding: 1,
    margin: 1,
    alignSelf: "flex-start",
    justifySelf: "center",
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    borderBottom: "1px solid #EDF1F7",
  },
  tableCell: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 0,
  },
  tableCellText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 20,
    fontSize: 13,
    padding: 1,
    margin: 1,
    alignSelf: "flex-start",
    justifySelf: "center",
  },
  tableContainer: {},
  APPROVED: {
    borderRadius: 5,
    backgroundColor: "#1D84FF29",
    color: "color-info-600",
    border: "1px solid #1D84FF",
    fontFamily: "Montserrat_700Bold",
  },
  PENDING: {
    borderRadius: 5,
    backgroundColor: "#8F9BB314",
    color: "color-basic-600",
    border: "1px solid #8F9BB3",
    fontFamily: "Montserrat_700Bold",
  },
  REJECTED: {
    borderRadius: 5,
    backgroundColor: "#9B204914",
    color: "color-danger-600",
    border: "1px solid #9B2049",
    fontFamily: "Montserrat_700Bold",
  },
  WITHDRAWN: {
    borderRadius: 5,
    backgroundColor: "#8F9BB314",
    color: "color-basic-600",
    border: "1px solid #8F9BB3",
    fontFamily: "Montserrat_700Bold",
  },
  "PRE-QUAL 1": {
    borderRadius: 5,
    backgroundColor: "#1D84FF29",
    color: "color-info-600",
    border: "1px solid #1D84FF",
    fontFamily: "Montserrat_700Bold",
  },
  "PRE-QUAL 2": {
    borderRadius: 5,
    backgroundColor: "rgba(255, 170, 0, 0.1)",
    color: "color-warning-600",
    border: "1px solid #8F9BB3",
    fontFamily: "Montserrat_700Bold",
  },
  "FULL APPLICATION": {
    textAlign: "center",
    borderRadius: 5,
    backgroundColor: "#d082e533",
    color: "#d03fe8",
    border: "1px solid #d03fe8",
    fontFamily: "Montserrat_700Bold",
  },
  selectItem: {
    backgroundColor: "white",
    color: "#222B45",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    alignText: "left",
  },
  modalHeaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  previewModal: {
    padding: 20,
    borderRadius: 5,
    width: 700,
    background: "white",
  },
  cardHeader: {
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    margin: 20,
  },
  cardDivider: {
    height: 2,
    margin: 5,
  },
  cell: {
    flex: 1,
    color: "color-basic-800",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    textAlign: "left",
  },
  previewModalScroll: {
    maxHeight: 600,
    flex: 1,
  },
  viewFullApplicationButton: {
    background: "#1D84FF29",
    color: "color-info-600",
    border: "3px solid #1D84FF29",
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginVertical: 30,
    marginHorizontal: 15,
    maxWidth: 230,
  },
  viewFullApplicationButtonText: {
    color: "color-info-600",
    textTransform: "uppercase",
    lineHeight: 16,
    fontSize: 13,
    fontFamily: "Montserrat_700Bold",
  },
  welcomeMessageText: {
    fontFamily: "SFProTextRegular",
    fontWeight: "500",
    lineHeight: 24,
    fontSize: 13,
    textAlign: "left",
  },
  covenantsSelectText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
  },
  covenantOptionContainer: {
    padding: 5,
    flexDirection: "row",
    maxWidth: 600,
  },
  covenantCheckbox: {},
  covenantCheckboxLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 15,
    lineHeight: 24,
    textAlign: "left",
    padding: 5,
    marginHorizontal: 15,
  },
  covenantInput: {
    flex: 1,
  },
  flexRowItemsCenter: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  covenantSubmit: {
    maxWidth: 150,
    marginHorizontal: 10,
    marginVertical: 30,
  },
  covenantSectionLabel: {
    flex: 1,
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 26,
    textTransform: "uppercase",
    color: "color-basic-600",
  },
  select: {
    fontSize: 22,
    background: "white",
    flex: 1,
    marginRight: 20,
  },
  previewLoanButton: {
    color: "color-basic-600",
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 13,
    padding: 1,
    margin: 1,
    justifySelf: "center",
  },
  investorDashboardContainer: {
    flexDirection: "column",
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    background: "#f7f9fc",
    padding: 25,
  },
  overviewText: {
    textAlign: "left",
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 24,
    lineHeight: 36,
  },
  cardTitleText: {
    textAlign: "left",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  quickStatNameText: {
    textAlign: "center",
    fontFamily: "SFProTextRegular",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 32,
    color: "rgba(66, 82, 110, 0.86)",
  },
  quickStatValueText: {
    textAlign: "center",
    fontFamily: "SFProTextRegular",
    fontWeight: "600",
    fontSize: 24,
    lineHeight: 24,
  },
  positiveChangeText: {
    color: "rgba(0, 200, 113, 1)",
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50",
    fontSize: 13,
    lineHeight: 20,
    fontFamily: "SFProTextRegular",
    borderRadius: 16,
    margin: 5,
    paddingVertical: 3,
    paddingHorizontal: 10,
  },
  negativeChangeText: {
    color: "color-danger-500",
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FFA8B4",
    fontSize: 13,
    lineHeight: 20,
    fontFamily: "SFProTextRegular",
    borderRadius: 16,
    margin: 5,
    paddingVertical: 3,
    paddingHorizontal: 10,
  },
  lastUpdatedText: {
    textAlign: "left",
    fontFamily: "Montserrat_500Normal",
    fontSize: 14,
    lineHeight: 24,
  },
  investorHeaderText: {
    margin: 25,
  },
  quickStatsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "white",
    alignSelf: "stretch",
    borderRadius: 25,
    flexGrow: 0,
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    border: "1px solid #e7e7e7",
  },
  quickStatBlock: {
    padding: 25,
    borderRight: "1px solid #e7e7e7",
    flex: 1,
  },
  flexRowStretch: {
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "stretch",
    alignItems: "stretch",
  },
  runningBalanceContainer: {
    flex: 3,
    backgroundColor: "white",
    borderRadius: 16,
    padding: 10,
    paddingHorizontal: 15,
    margin: 5,
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
  },
  marketsContainer: {
    flex: 2,
    backgroundColor: "white",
    borderRadius: 16,
    padding: 10,
    paddingHorizontal: 15,
    margin: 5,
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
  },
  agencyDistributionContainer: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 16,
    padding: 10,
    paddingHorizontal: 15,
    margin: 5,
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
  },
  loanDistributionContainer: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 16,
    padding: 10,
    paddingHorizontal: 15,
    margin: 5,
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
  },
  legend: {
    flexDirection: "row",
    alignItems: "center",
    justifySelf: "flex-start",
  },
  colorCircle: {
    width: 10,
    height: 10,
    borderRadius: 25,
    margin: 10,
  },
  mapContainer: {
    padding: 10,
    margin: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  marketsTableContainer: {
    padding: 10,
    margin: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  marketsTable: {
    flex: 1,
    maxWidth: 400,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },
  marketsTableHeaderText: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 12,
    lineHeight: 16,
    margin: 0,
    padding: 0,
    textTransform: "uppercase",
    marginVertical: 3,
    marginLeft: 5,
  },
  marketsTableText: {
    fontFamily: "Montserrat_600SemiBold",
    flex: 1,
    fontSize: 14,
    lineHeight: 22,
    margin: 0,
    paddingVertical: 7,
  },
  agenciesTableContainer: {
    flex: 1,
    padding: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  agenciesTable: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },
  agenciesTableHeaderText: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 0,
    padding: 0,
    marginVertical: 3,
    paddingVertical: 15,
  },
  agenciesTableText: {
    fontFamily: "Montserrat_400Regular",
    flex: 1,
    fontSize: 15,
    lineHeight: 20,
    margin: 0,
    paddingVertical: 15,
  },
  chartContainer: {
    padding: 5,
    flexDirection: "row",
    flex: 1,
  },
  labelsRow: {
    paddingVertical: 5,
    flexDirection: "row",
    flex: 1,
  },
  chartBarContainer: {
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 2,
  },
  chartBar: {
    width: "75%",
    zIndex: 2,
  },
  xTitle: {
    textAlign: "center",
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(143, 155, 179, 1)",
  },
  chartLabelText: {
    textAlign: "center",
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(163, 163, 163, 1)",
  },
  yTitle: {
    zIndex: 2,
    whiteSpace: "nowrap",
    transform: [{ rotate: "-90deg" }],
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(143, 155, 179, 1)",
  },
  yTitleContainer: {
    width: 10,
    margin: 0,
    flexDirection: "row",
    justifyContent: "center",
  },
  chartTicks: {
    flex: 1,
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "flex-start",
    marginHorizontal: 5,
  },
  tick: {
    flex: 1,
  },
  tickText: {
    position: "absolute",
    bottom: -7,
    left: 0,
    textAlign: "left",
    fontFamily: "Montserrat_600SemiBold",
    fontSize: 12,
    lineHeight: 14,
    color: "rgba(143, 155, 179, 1)",
    zIndex: 4,
  },
  gridBox: {
    backgroundColor: "transparent",
    flex: 1,
    border: "1px solid #F0F0F0",
    width: "100%",
    height: "100%",
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    color: "white",
  },
  printButton: {
    padding: 5,
    margin: 5,
  },
});
