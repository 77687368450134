import { useNavigation } from "@react-navigation/native";
import { Layout, Spinner, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, TouchableOpacity, Image } from "react-native";

import Header from "../../components/Header";
import { useStore } from "../../stores";
import { MboForm } from "./mboForm";
import { themedStyles } from "./themedStyles";
import { Dimensions } from "react-native";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { ContactUsPopup } from "../../components/ContactUs";

const MBOPrequal: React.FC<any> = ({ route }) => {
  let { width } = Dimensions.get("window");
  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const navigation = useNavigation();
  const { authStore, mboStore, loanStore, businessStore } = useStore();
  const styles = useStyleSheet(themedStyles);
  if (!authStore || !authStore.accessToken) return <Text>Register</Text>;
  const [currentStep, setCurrentStep] = useState<number>(0);

  const updateMboData = async (data: any) => {
    const res = await mboStore.updateMBOInfo(mbo.id, data);
    return res;
  };
  const updateUserData = async (data: any) => {
    const res = await authStore.updateBasicAndBusinessInformation(data);
    return res;
  };
  const [mbo, setMbo] = useState<any>({});
  const [loan, setLoan] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  useEffect(() => {
    if (!mboStore.mboInfo.size) {
      mboStore.fetchMBOInfo();
    } else {
      let flag = false;
      mboStore.mboInfo.forEach((mbo) => {
        if (Number(mbo.user) === Number(authStore.user?.id)) {
          setMbo(mbo.$);
          flag = true;
        } else {
          navigation.navigate("dashboard");
        }
      });
      if (!flag) {
        navigation.navigate("dashboard");
      }
    }
  }, [id, mboStore, mboStore.mboInfo]);

  useEffect(() => {
    if (!loanStore.loanApplications.size) {
      loanStore.fetchLoanApplications();
    } else {
      let flag = false;
      loanStore.loanApplications.forEach((loan) => {
        if (Number(loan.id) === Number(id)) {
          setLoan(loan.$);
          flag = true;
        } else {
          navigation.navigate("dashboard");
        }
      });
      if (!flag) {
        navigation.navigate("dashboard");
      }
    }
  }, [id, loanStore.loanApplications]);

  useEffect(() => {
    if (!businessStore.business.size) {
      businessStore.fetchBusinesses();
    } else {
      if (loan.business) {
        let flag = false;
        businessStore.business.forEach((business) => {
          if (Number(business.id) === Number(loan.business)) {
            setBusiness(business.$);
            flag = true;
          }
        });
        if (!flag) {
          navigation.navigate("dashboard");
        }
      }
    }
  }, [id, businessStore.business, loan]);

  useEffect(() => {
    setLoading(true);
    if (
      Object.keys(mbo).length > 0 &&
      Object.keys(loan).length > 0 &&
      Object.keys(business).length > 0
    )
      setLoading(false);
  }, [mbo, loan, business]);

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.preApprovalContainer}>
          <View
            style={
              width > 768
                ? {
                    ...styles.gradientContainer,
                    ...(currentStep === 5 ? { width: "100%" } : {}),
                  }
                : {
                    ...styles.gradientContainerMobile,
                    ...(currentStep === 5 ? { width: "100%" } : {}),
                  }
            }
          />
          <View
            style={width > 768 ? styles.preApproval : styles.preApprovalMobile}
          >
            {!loading ? (
              <MboForm
                mbo={mbo}
                mboId={mbo.id}
                user={authStore.user}
                loan={loan}
                loanId={id}
                business={business}
                businessId={business.id}
                onContinue={() => {
                  navigation.navigate("loan-view", { id });
                }}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                updateMboData={updateMboData}
                updateUserData={updateUserData}
              />
            ) : (
              <LoadingIndicator />
            )}
          </View>
        </View>
      </ScrollView>
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default MBOPrequal;
