import {
  Text,
  useStyleSheet,
  Input,
  Datepicker,
  Icon,
  CalendarViewModes,
} from "@ui-kitten/components";
import React, { createRef, useRef, useState } from "react";
import { View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import HoverBubble from "../../components/HoverBubble";
import { ApplicationFeeModal } from "./ApplicationFeeModal";
import { themedStyles } from "./themedStyles";

export const PaymentApplicationStep: React.FC<any> = ({
  id,
  formData,
  setFormData,
  showEINSSNFields,
  setShowEINSSNFields,
  paymentStatus,
  setPaymentStatus,
}) => {
  const styles = useStyleSheet(themedStyles);
  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar-outline" />
  );

  const [formattedSSNNumber, setFormattedSSNNumber] = useState(
    formData.ssnNumber
  );
  const [formattedEINNumber, setFormattedEINNumber] = useState(
    formData.einNumber
  );

  const EINRef = createRef<any>();
  const organizationIdRef = useRef<Input>(null);

  return (
    <View>
      {!showEINSSNFields && (
        <ApplicationFeeModal
          id={id}
          setShowEINSSNFields={setShowEINSSNFields}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
        />
      )}

      {showEINSSNFields && (
        <View style={styles.applicationFeeContainer}>
          <Text style={styles.inputLabel}>
            SSN
            <HoverBubble
              text={
                <Text style={styles.tooltipTextDark}>
                  Please enter your social security number.
                </Text>
              }
              theme="light"
            />
          </Text>
          <TextInputMask
            style={styles.inputMask}
            placeholder="input your SSN"
            autoCorrect={false}
            value={formattedSSNNumber}
            type="custom"
            options={{ mask: "999-99-9999" }}
            onChangeText={(text) => {
              if (text.length <= 11) {
                setFormattedSSNNumber(text);
                setFormData({
                  ...formData,
                  ssnNumber: text.replace(/[^0-9\+]/g, ""),
                });
              }
            }}
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                EINRef.current?._inputElement.focus();
              }
            }}
            onSubmitEditing={() => EINRef.current?._inputElement.focus()}
          />
          <Text style={styles.inputLabel}>
            EIN
            <HoverBubble
              text={
                <Text style={styles.tooltipTextDark}>
                  Employer Identification Number (a.k.a. Federal Tax
                  Identification Number) is assigned by the IRS to identify a
                  business entity. You can find this number on old tax returns,
                  or check out the{" "}
                  <Text
                    status="primary"
                    onPress={() => {
                      window.open(
                        "https://www.irs.gov/businesses/small-businesses-self-employed/employer-id-numbers",
                        "_blank"
                      );
                    }}
                  >
                    {" "}
                    IRS website.
                  </Text>
                </Text>
              }
              theme="light"
            />
          </Text>
          <TextInputMask
            ref={EINRef}
            style={styles.inputMask}
            placeholder="Input your EIN"
            autoCorrect={false}
            value={formattedEINNumber}
            type="custom"
            options={{ mask: "99-9999999" }}
            onChangeText={(text) => {
              if (text.length <= 10) {
                setFormattedEINNumber(text);
                setFormData({
                  ...formData,
                  einNumber: text.replace(/[^0-9\+]/g, ""),
                });
              }
            }}
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                organizationIdRef.current?.focus();
              }
            }}
            onSubmitEditing={() => organizationIdRef.current?.focus()}
          />
          <Text style={styles.inputLabel}>
            Organization ID
            <HoverBubble
              text={
                <Text style={styles.tooltipTextDark}>
                  This unique number (a.k.a. Charter Number or Corporate Number)
                  is assigned when an entity is registered and organized with
                  the secretary of state. It should have eight digits and a
                  letter.{" "}
                </Text>
              }
              theme="dark"
            />
          </Text>
          <Input
            ref={organizationIdRef}
            style={styles.input}
            textStyle={styles.inputText}
            placeholder="input your Organization ID"
            autoCapitalize="none"
            autoCorrect={false}
            value={formData.organizationId}
            onChangeText={(text) =>
              setFormData({
                ...formData,
                organizationId: text.slice(0, 18),
              })
            }
            returnKeyType="next"
            testID="organizationIdField"
          />
        </View>
      )}
    </View>
  );
};
