import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
import * as api from "../../services/api";
import { useStore } from "../../stores";

const QuickbooksBalanceSheet: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  const tableWidth = Dimensions.get("window").width - 300;

  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const { authStore } = useStore();
  const defaultItems: any = [];
  const [items, setItems] = useState(defaultItems);

  const addSpaceInName = (name: string) => {
    // Adding space between strings
    const item = name.replace(/([A-Z])/g, " $1");
    // Converting first character to uppercase and join it to the final string
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  const emptyCol = (key: string) => (
    <Text
      key={key}
      style={{
        ...styles.tableCell,
        width: (tableWidth * 1.25) / 12,
      }}
    >
      {" "}
    </Text>
  );

  const getBalanceSheet = async () => {
    try {
      const results = await api.getBalanceSheet(
        authStore?.accessToken || "",
        Number(id)
      );
      if ("entities" in results.response) {
        let data = [];
        let rowData = {};
        const { entities } = results.response;
        for (const key in entities) {
          if (key.toLowerCase() !== "assets") {
            for (const item in entities[key]) {
              rowData = {
                ...entities[key][item],
                rowName: addSpaceInName(item),
              };
              data.push(rowData);
            }
          } else {
            rowData = { ...entities[key], rowName: addSpaceInName(key) };
            data.push(rowData);
          }
        }
        setItems(data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const fieldsDesc: { [key: string]: any } = {
    title: {
      flex: 7,
      display: "Balance Sheet",
    },
    currentYear: {
      flex: 1,
      display: new Date().getFullYear(),
    },
    previousYear: {
      flex: 1,
      display: new Date().getFullYear() - 1,
    },
  };

  useEffect(() => {
    getBalanceSheet();
  }, []);

  useEffect(() => {
    console.log(`items:-`);
    console.log(items);
  }, [items]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <View style={styles.tableContainer}>
              <View style={styles.tableHeaders}>
                {Object.values(fieldsDesc).map((field, idx) => (
                  <View
                    key={"header-" + field.display}
                    style={{
                      ...styles.tableHeadersCell,
                      ...{
                        flex: field.flex,
                      },
                    }}
                  >
                    <Text
                      // @ts-ignore
                      style={{
                        ...styles.tableHeadersCell,
                        ...{
                          textAlign: idx === 0 ? "left" : "center",
                          alignSelf: idx === 0 ? "left" : "center",
                        },
                      }}
                    >
                      {field.display}
                    </Text>
                  </View>
                ))}
              </View>
              {!!items &&
                items.map((row: any) => (
                  <View key={row.rowName + "-row"}>
                    <Text style={styles.tableCellName}>{row.rowName}</Text>
                    {/* ASSETS */}
                    {!!row &&
                      row.rowName.toLowerCase() === "assets" &&
                      Object.keys(row).map(
                        (col, i) =>
                          col !== "rowName" &&
                          col.toLowerCase() !== "total" && (
                            <>
                              <View
                                key={`content-${col}-${row.rowName}`}
                                style={styles.tableRow}
                              >
                                <Text
                                  key={`content-${col}-${row.rowName}-${i}`}
                                  style={{
                                    ...styles.tableCellItemTitle,
                                    flex: 7,
                                    textAlign: "left",
                                  }}
                                >
                                  {addSpaceInName(col)}
                                </Text>
                              </View>
                              {!!row[col] &&
                                Object.keys(row[col]).map((item: any, i) => (
                                  <>
                                    <View
                                      key={`${item}-${row.rowName}`}
                                      style={styles.tableRow}
                                    >
                                      <Text
                                        key={`title-${item}-${row.rowName}-${i}`}
                                        style={styles.tableCellColItemTitle}
                                      >
                                        {addSpaceInName(item)}
                                      </Text>
                                    </View>
                                    {!!row[col][item] &&
                                      Object.keys(row[col][item]).map(
                                        (subItem: any, i) => (
                                          <>
                                            <View
                                              key={`${item}-${subItem}`}
                                              style={styles.tableRow}
                                            >
                                              <Text
                                                key={`title-${item}-${subItem}-${i}`}
                                                style={
                                                  styles.tableCellSubItemTitle
                                                }
                                              >
                                                {addSpaceInName(subItem)}
                                              </Text>
                                              <Text
                                                key={`content-${col}-${row.rowName}`}
                                                style={styles.tableCellItem}
                                              >
                                                {row[col][item][subItem]}
                                              </Text>
                                              <Text
                                                key={`content-${col}-${row.rowName}`}
                                                style={styles.tableCellItem}
                                              >
                                                {row[col][item][subItem]}
                                              </Text>
                                            </View>
                                          </>
                                        )
                                      )}
                                  </>
                                ))}
                            </>
                          )
                      )}
                    {!!row &&
                      row.rowName.toLowerCase() === "assets" &&
                      Object.keys(row).map(
                        (col, i) =>
                          col !== "rowName" &&
                          col.toLowerCase() === "total" && (
                            <View
                              key={`content-${col}-${row.rowName}`}
                              style={
                                col.toLowerCase() === "total"
                                  ? { ...styles.tableRow, ...styles.itemTotal }
                                  : styles.tableRow
                              }
                            >
                              <Text
                                key={`content-${col}-${row.rowName}-${i}`}
                                style={
                                  col.toLowerCase() === "total"
                                    ? {
                                        ...styles.tableCellItemTitle,
                                        marginTop: 20,
                                      }
                                    : styles.tableCellItemTitle
                                }
                              >
                                {addSpaceInName(col)}
                              </Text>
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={
                                  col.toLowerCase() === "total"
                                    ? {
                                        ...styles.tableCellItem,
                                        marginTop: 20,
                                      }
                                    : styles.tableCellItem
                                }
                              >
                                {row[col]}
                              </Text>
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={
                                  col.toLowerCase() === "total"
                                    ? {
                                        ...styles.tableCellItem,
                                        marginTop: 20,
                                      }
                                    : styles.tableCellItem
                                }
                              >
                                {row[col]}
                              </Text>
                            </View>
                          )
                      )}
                    {/* Liabilities */}
                    {!!row &&
                      row.rowName.toLowerCase() === "liabilities" &&
                      Object.keys(row).map((col, i) =>
                        col !== "rowName" && col.toLowerCase() !== "total" ? (
                          <>
                            <View
                              key={`content-${col}-${row.rowName}`}
                              style={styles.tableRow}
                            >
                              <Text
                                key={`content-${col}-${row.rowName}-${i}`}
                                style={{
                                  ...styles.tableCellItemTitle,
                                  flex: 7,
                                  textAlign: "left",
                                }}
                              >
                                {addSpaceInName(col)}
                              </Text>
                            </View>
                            {!!row[col] &&
                              Object.keys(row[col]).map((item: any, i) => (
                                <>
                                  <View key={item} style={styles.tableRow}>
                                    <Text
                                      key={`title-${item}-${i}`}
                                      style={styles.tableCellColItemTitle}
                                    >
                                      {addSpaceInName(item)}
                                    </Text>
                                    <Text
                                      key={`content1-${item}-${row.rowName}`}
                                      style={styles.tableCellItem}
                                    >
                                      {row[col][item]}
                                    </Text>
                                    <Text
                                      key={`content2-${item}-${row.rowName}`}
                                      style={styles.tableCellItem}
                                    >
                                      {row[col][item]}
                                    </Text>
                                  </View>
                                </>
                              ))}
                          </>
                        ) : (
                          col.toLowerCase() === "total" && (
                            <View
                              key={`content-${col}-${row.rowName}`}
                              style={{
                                ...styles.tableRow,
                                ...styles.itemTotal,
                              }}
                            >
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={{
                                  ...styles.tableCellItemTitle,
                                  marginTop: 20,
                                }}
                              >
                                {addSpaceInName(col)}
                              </Text>
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={{
                                  ...styles.tableCellItem,
                                  marginTop: 20,
                                }}
                              >
                                {row[col]}
                              </Text>
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={{
                                  ...styles.tableCellItem,
                                  marginTop: 20,
                                }}
                              >
                                {row[col]}
                              </Text>
                            </View>
                          )
                        )
                      )}
                    {/* EQUITY */}
                    {!!row &&
                      row.rowName.toLowerCase() === "equity" &&
                      Object.keys(row).map(
                        (col, i) =>
                          col !== "rowName" && (
                            <View
                              key={`content-${col}-${row.rowName}`}
                              style={
                                col.toLowerCase() === "total"
                                  ? { ...styles.tableRow, ...styles.itemTotal }
                                  : styles.tableRow
                              }
                            >
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={
                                  col.toLowerCase() === "total"
                                    ? {
                                        ...styles.tableCellItemTitle,
                                        marginTop: 20,
                                      }
                                    : styles.tableCellItemTitle
                                }
                              >
                                {addSpaceInName(col)}
                              </Text>
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={
                                  col.toLowerCase() === "total"
                                    ? {
                                        ...styles.tableCellItem,
                                        marginTop: 20,
                                      }
                                    : styles.tableCellItem
                                }
                              >
                                {row[col]}
                              </Text>
                              <Text
                                key={`content-${col}-${row.rowName}`}
                                style={
                                  col.toLowerCase() === "total"
                                    ? {
                                        ...styles.tableCellItem,
                                        marginTop: 20,
                                      }
                                    : styles.tableCellItem
                                }
                              >
                                {row[col]}
                              </Text>
                            </View>
                          )
                      )}
                  </View>
                ))}
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );

  // return (
  //   <Layout style={styles.container}>
  //     <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
  //       <Header />
  //       <View style={styles.adminDashboardContainer}>
  //         <View style={styles.adminDashBoard}>
  //           <Text style={styles.header}>Balance Sheet</Text>
  //           <View style={styles.tableContainer}>
  //             <View>
  //               {!!items &&
  //                 items.map((row: any) => (
  //                   <View key={`item-${row.rowName}`} style={{ marginTop: 20 }}>
  //                     <View
  //                       key={`row-${row.rowName}`}
  //                       style={styles.tableHeaders}
  //                     >
  //                       <View
  //                         key={`header-${row.rowName}`}
  //                         style={{
  //                           ...styles.tableHeadersCell,
  //                           flexDirection: "row",
  //                           justifyContent: "space-between",
  //                         }}
  //                       >
  //                         <Text
  //                           key={`text-${row.rowName}`}
  //                           style={styles.tableHeadersCell}
  //                         >
  //                           {row.rowName}
  //                         </Text>
  //                         <Text
  //                           key={`text-${row.rowName}`}
  //                           style={styles.tableHeadersCell}
  //                         >
  //                           2021
  //                         </Text>
  //                         <Text
  //                           key={`text-${row.rowName}`}
  //                           style={styles.tableHeadersCell}
  //                         >
  //                           2020
  //                         </Text>
  //                       </View>
  //                     </View>
  //                     {!!row &&
  //                       row.rowName.toLowerCase() === "assets" &&
  //                       Object.keys(row).map((col, i) =>
  //                         col !== "rowName" && col !== "total" ? (
  //                           <View key={`content-${col}-${row.rowName}-${i}`}>
  //                             <View
  //                               key={`content-${col}-${row.rowName}`}
  //                               style={styles.tableRow}
  //                             >
  //                               <Text
  //                                 key={`content-${col}-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 3,
  //                                   textAlign: "left",
  //                                 }}
  //                               >
  //                                 {addSpaceInName(col)}
  //                               </Text>
  //                             </View>
  //                             {!!row &&
  //                               Object.keys(row[col]).map((item, i) => (
  //                                 <>
  //                                   <View
  //                                     key={`content-${item}-${row.rowName}-${i}`}
  //                                     style={styles.tableRow}
  //                                   >
  //                                     <Text
  //                                       key={`content-${item}-${row.rowName}-${i}`}
  //                                       style={{
  //                                         ...styles.tableCell,
  //                                         width: (tableWidth * 1.25) / 3,
  //                                         textAlign: "left",
  //                                         paddingLeft: 10,
  //                                       }}
  //                                     >
  //                                       {addSpaceInName(item)}
  //                                     </Text>
  //                                   </View>
  //                                   {!!row &&
  //                                     Object.keys(row[col][item]).map(
  //                                       (subitem, i) => (
  //                                         <View
  //                                           key={`content-${subitem}-${row.rowName}-${i}`}
  //                                           style={styles.tableRow}
  //                                         >
  //                                           <Text
  //                                             key={`content-${subitem}-${row.rowName}-${i}`}
  //                                             style={{
  //                                               ...styles.tableCell,
  //                                               width: (tableWidth * 1.25) / 3,
  //                                               textAlign: "left",
  //                                               paddingLeft: 30,
  //                                             }}
  //                                           >
  //                                             {addSpaceInName(subitem)}
  //                                           </Text>
  //                                           <Text
  //                                             key={`content-${item}-${i}`}
  //                                             style={{
  //                                               ...styles.tableCell,
  //                                               width: (tableWidth * 1.25) / 3,
  //                                               textAlign: "left",
  //                                             }}
  //                                           >
  //                                             {row[col][item][subitem]}
  //                                           </Text>
  //                                           {emptyCol(`empty-${item}-${i}`)}
  //                                         </View>
  //                                       )
  //                                     )}
  //                                 </>
  //                               ))}
  //                           </View>
  //                         ) : (
  //                           col === "total" && (
  //                             <View
  //                               key={`content-${col}`}
  //                               style={styles.tableRow}
  //                             >
  //                               <Text
  //                                 key={`content-title-${col}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 7,
  //                                   textAlign: "left",
  //                                 }}
  //                               >
  //                                 Total
  //                               </Text>
  //                               <Text
  //                                 key={`content-value-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 12,
  //                                 }}
  //                               >
  //                                 {row[col]}
  //                               </Text>
  //                               {/* current year */}
  //                               <Text
  //                                 key={`content-value-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 12,
  //                                 }}
  //                               >
  //                                 {row[col]}
  //                               </Text>
  //                               {emptyCol(`empty-${row.rowName}`)}
  //                             </View>
  //                           )
  //                         )
  //                       )}
  //                     {!!row &&
  //                       row.rowName.toLowerCase() === "liabilities" &&
  //                       Object.keys(row).map((col, i) =>
  //                         col !== "rowName" && col !== "total" ? (
  //                           <View key={`content-${col}-${row.rowName}-${i}`}>
  //                             <View
  //                               key={`content-${col}-${row.rowName}`}
  //                               style={styles.tableRow}
  //                             >
  //                               <Text
  //                                 key={`content-${col}-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 3,
  //                                   textAlign: "left",
  //                                 }}
  //                               >
  //                                 {addSpaceInName(col)}
  //                               </Text>
  //                             </View>
  //                             {!!row &&
  //                               Object.keys(row[col]).map((item, i) => (
  //                                 <View
  //                                   key={`content-${item}-${row.rowName}-${i}`}
  //                                   style={styles.tableRow}
  //                                 >
  //                                   <Text
  //                                     key={`content-${item}-${row.rowName}-${i}`}
  //                                     style={{
  //                                       ...styles.tableCell,
  //                                       width: (tableWidth * 1.25) / 3,
  //                                       textAlign: "left",
  //                                     }}
  //                                   >
  //                                     {"     " + addSpaceInName(item)}
  //                                   </Text>
  //                                   <Text
  //                                     key={`content-${item}-${i}`}
  //                                     style={{
  //                                       ...styles.tableCell,
  //                                       width: (tableWidth * 1.25) / 3,
  //                                       textAlign: "left",
  //                                     }}
  //                                   >
  //                                     {row[col][item]}
  //                                   </Text>
  //                                   {/* current year */}
  //                                   <Text
  //                                     key={`content-${item}-${i}`}
  //                                     style={{
  //                                       ...styles.tableCell,
  //                                       width: (tableWidth * 1.25) / 3,
  //                                       textAlign: "left",
  //                                     }}
  //                                   >
  //                                     {row[col][item]}
  //                                   </Text>
  //                                   {emptyCol(`empty-${item}-${i}`)}
  //                                 </View>
  //                               ))}
  //                           </View>
  //                         ) : (
  //                           col === "total" && (
  //                             <View
  //                               key={`content-${col}`}
  //                               style={{ ...styles.tableRow, marginTop: 30 }}
  //                             >
  //                               <Text
  //                                 key={`content-title-${col}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 7,
  //                                   textAlign: "left",
  //                                 }}
  //                               >
  //                                 Total
  //                               </Text>
  //                               <Text
  //                                 key={`content-value-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 12,
  //                                 }}
  //                               >
  //                                 {row[col]}
  //                               </Text>
  //                               {/* current year */}
  //                               <Text
  //                                 key={`content-value-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 12,
  //                                 }}
  //                               >
  //                                 {row[col]}
  //                               </Text>
  //                               {emptyCol(`empty-${row.rowName}`)}
  //                             </View>
  //                           )
  //                         )
  //                       )}
  //                     {!!row &&
  //                       row.rowName.toLowerCase() === "equity" &&
  //                       Object.keys(row).map(
  //                         (item) =>
  //                           item !== "rowName" && (
  //                             <View
  //                               key={`content-${item}`}
  //                               // style={styles.tableRow}
  //                               // style={{ ...styles.tableRow, marginTop: 30 }}
  //                               style={
  //                                 item === "total"
  //                                   ? { ...styles.tableRow, marginTop: 30 }
  //                                   : { ...styles.tableRow }
  //                               }
  //                             >
  //                               <Text
  //                                 key={`content-title-${item}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 7,
  //                                   textAlign: "left",
  //                                   fontWeight:
  //                                     item === "total" ? "700" : "normal",
  //                                 }}
  //                               >
  //                                 {addSpaceInName(item)}
  //                               </Text>
  //                               <Text
  //                                 key={`content-value-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 12,
  //                                   fontWeight:
  //                                     item === "total" ? "700" : "normal",
  //                                 }}
  //                               >
  //                                 {row[item]}
  //                               </Text>
  //                               {/* current year */}
  //                               <Text
  //                                 key={`content-value-${row.rowName}`}
  //                                 style={{
  //                                   ...styles.tableCell,
  //                                   width: (tableWidth * 1.25) / 12,
  //                                   fontWeight:
  //                                     item === "total" ? "700" : "normal",
  //                                 }}
  //                               >
  //                                 {row[item]}
  //                               </Text>
  //                               {emptyCol(`empty-${row.rowName}`)}
  //                             </View>
  //                           )
  //                       )}
  //                   </View>
  //                 ))}
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //     </ScrollView>
  //   </Layout>
  // );
};

export default QuickbooksBalanceSheet;
