import { useStyleSheet, Modal, Spinner, Layout } from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { Dimensions } from "react-native";
import { ScrollView } from "react-native-gesture-handler";

import * as api from "../../services/api";
import { useStore } from "../../stores";
import { BankLinkModal } from "../PreApproval/BankLinkModal";
import { themedStyles } from "./themedStyles";

export const PlaidReconnectModal: React.FC<any> = ({ isOpen, close }) => {
  const styles = useStyleSheet(themedStyles);
  const LoadingIndicator = (props: any) => <Spinner />;

  const { authStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({ bankAccounts: [] });

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      api.getLinkedAccounts(authStore.accessToken || "").then((resp) => {
        setLoading(false);
        console.log(resp.response.entities);
        if (Object.values(resp.response.entities).length)
          setData({
            ...data,
            bankAccounts: Object.values(resp.response.entities).map(
              (bank: any) => ({
                isLinked: true,
                needsReLink: bank.expired,
                userId: bank.user_id,
                ...bank,
              })
            ),
          });
      });
    }
  }, [isOpen]);

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => close()}
      style={styles.bankModal}
    >
      <Layout
        style={{
          ...styles.payrollModal,
          maxHeight: Dimensions.get("screen").height * 0.75,
        }}
      >
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ScrollView>
            {!loading && data.bankAccounts ? (
              data.bankAccounts.map((account: any, idx: number) => (
                <BankLinkModal
                  key={`bank-acc-${idx}`}
                  cb={(linkSuccess) =>
                    setData({
                      ...data,
                      bankAccounts: data.bankAccounts.map(
                        (acc: any, index: number) => {
                          if (index !== idx) return acc;
                          return {
                            ...acc,
                            isLinked: linkSuccess,
                            needsReLink: !linkSuccess,
                          };
                        }
                      ),
                    })
                  }
                  linkedBank={data.bankAccounts[idx]}
                />
              ))
            ) : (
              <></>
            )}
          </ScrollView>
        )}
      </Layout>
    </Modal>
  );
};
