import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Button,
  Input,
  Layout,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useCallback, useEffect } from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";

import Header from "../../components/Header";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { acceptedBusinessStateOptions } from "../../utils/constants";
import { ThemeContext } from "./../../../theme-context";
import { FilterOptionsModal } from "./FilterOptionsModal";
import { LoanPreviewModal } from "./LoanPreviewModal";
import { themedStyles } from "./themedStyles";
import { useDebounce } from "ahooks";

type Props = object;

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

export const SORT = {
  NEW: "NEW",
  OLD: "OLD",
  UPDATED_INC: "UPDATED_INC",
  UPDATED_DEC: "UPDATED_DEC",
  ALPHABET_INC: "ALPHA_INC",
  ALPHABET_DEC: "ALPHA_DEC",
};

export const possibleLocations = acceptedBusinessStateOptions;
export const possibleStatus = [
  "PRE-QUAL 1",
  "PRE-QUAL 2",
  "FULL APPLICATION",
  "WITHDRAWN",
];
export const possibleDecisionStatus = [
  "Approved",
  "Pending",
  "Soft Rejected",
  "Rejected",
];
export const possibleTypes = [
  "S Corporation",
  "C Corporation",
  "Nonprofit Corporation",
  "Limited Liability Corporation (LLC)",
  "Limited Liability Partnership (LLP)",
  "Partnership",
];

const displayPerPageOptions = [10, 25, 50, 100];

const UnderwriterPortalFinalDecision: { isClosingQueue?: boolean } = ({
  isClosingQueue = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width, height } = Dimensions.get("window");
  const maxWidth = styles.adminDashBoard.maxWidth
    ? Number(styles.adminDashBoard.maxWidth)
    : 1080;

  const colorBar = require("../../../assets/colorBar.svg");
  const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;
  const LeftArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-back-outline" />
  );
  const RightArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-ios-forward-outline" />
  );
  const OptionsIcon = (props: any) => (
    <Icon {...props} name="options-2-outline" />
  );
  const EyeIcon = (props: any) => <Icon {...props} name="eye-outline" />;
  const EditIcon = (props: any) => <Icon {...props} name="edit-outline" />;

  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [displayPerPage, setDisplayPerPage] = useState(10);
  const [searchString, setSearchString] = useState(
    new URLSearchParams(window.location.search).get("userName") || ""
  );
  const debouncedSearch = useDebounce(searchString, { wait: 500 });
  const [loading, setLoading] = useState(false);
  const [sortingMode, setSortingMode] = useState(SORT.NEW);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [isLoanPreviewModalOpen, setIsLoanPreviewModalOpen] = useState(false);
  const [loanPreviewApplicationId, setLoanPreviewApplicationId] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [availablePages, setAvailablePages] = useState(1);
  const [loansCount, setLoansCount] = useState(0);
  const tableWidth = width <= maxWidth ? width - 300 : maxWidth - 300;
  const totalColumns = 13;
  const [underwriterAssignmentOptions, setUnderwriterAssignmentOptions] =
    useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [underwriterDropdownIndexes, setUnderwriterDropdownIndexes] =
    useState<any>({});
  const [lastChangedLoanId, setLastChangedLoanId] = useState(-1);
  const [underwriterDropdownLoading, setUnderwriterDropdownLoading] =
    useState<any>({});

  const fieldsDesc: { [key: string]: any } = {
    loanApplicationId: {
      width: tableWidth * (0.5 / totalColumns),
      display: "ID",
    },
    status: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Status",
    },
    businessName: {
      width: tableWidth * (2.5 / totalColumns),
      display: "Business Name",
    },
    contractNumber: {
      width: tableWidth * (1.4 / totalColumns),
      display: "Contract Number",
    },
    loanAmount: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Loan Amount",
      format: "$",
      align: "center",
    },
    interest: {
      width: tableWidth * (0.8 / totalColumns),
      display: "Interest",
      format: "%",
      align: "center",
    },
    term: {
      width: tableWidth * (0.9 / totalColumns),
      display: "Term (months)",
      align: "center",
    },
    dateUpdated: {
      width: tableWidth * (0.95 / totalColumns),
      display: "Date Updated",
    },
    hasPaidApplicationProcessingFee: {
      width: tableWidth * (1.6 / totalColumns),
      display: "Application Fee Status?",
      align: "center",
    },
    finalDecisionStatus: {
      width: tableWidth * (1.25 / totalColumns),
      display: "Decision",
    },
    assignedUser: {
      width: tableWidth * (1.5 / totalColumns),
      display: "Assignee",
    },
    actions: {
      width: tableWidth * (1.5 / totalColumns),
      display: "Actions",
    },
  };

  const [loanApplications, setLoanApplications]: any = useState([]);

  const [filterOptions, setFilterOptions]: any = useState({
    byStatus: false,
    byDecisionStatus: false,
    byLocation: false,
    byType: false,
    decisionStatus: [],
    status: [],
    location: [],
    type: [],
    additionalQueryString: isClosingQueue ? "&status__in=approved,funded" : "",
  });

  const themeContext = React.useContext(ThemeContext);

  const { authStore } = useStore();

  useEffect(() => {
    themeContext.resetTheme();
  }, []);

  useEffect(() => {
    if (underwriterDropdownIndexes[lastChangedLoanId]) {
      const newUW: any =
        underwriterAssignmentOptions[
          underwriterDropdownIndexes[lastChangedLoanId].row
        ];
      const loanId = lastChangedLoanId;
      setUnderwriterDropdownLoading({
        ...underwriterDropdownLoading,
        [loanId]: true,
      });
      api
        .updateAssignedUserForLoan(authStore.accessToken || "", loanId, {
          assignedUser: newUW.id,
        })
        .then((resp) => {
          setLoanApplications(
            loanApplications.map((loan: any) => {
              if (loan.loanApplicationId === loanId) {
                return {
                  ...loan,
                  assignedUser: newUW.name,
                  assignedUserId: newUW.id,
                };
              }
              return loan;
            })
          );
          setUnderwriterDropdownLoading({
            ...underwriterDropdownLoading,
            [loanId]: false,
          });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [underwriterDropdownIndexes, lastChangedLoanId]);

  useEffect(() => {
    setLoading(true);
    api
      .getLoansListForFinalDecision(
        authStore.accessToken || "",
        currentPage,
        displayPerPage,
        { ...filterOptions, search: debouncedSearch, sort: sortingMode }
      )
      .then((resp) => {
        const response = resp.response.entities;
        console.log(response);
        setLoanApplications(response.loans);
        setUnderwriterAssignmentOptions(response.underwriters);
        setAvailablePages(response.pages || 1);
        setLoansCount(response.count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [
    displayPerPage,
    currentPage,
    filterOptions,
    debouncedSearch,
    sortingMode,
  ]);

  useFocusEffect(
    useCallback(() => {
      setDisplayPerPage(displayPerPageOptions[selectedIndex.row]);
    }, [useIsFocused(), selectedIndex])
  );

  const formatDisplay = (value: string, format: any) => {
    if (!value) return "";
    if (format === "$")
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(Number(value));
    if (format === "%") return value + "%";

    return value;
  };

  const getPagesButtonsNumbers = useCallback(() => {
    const buttons = [];
    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(availablePages, currentPage + 3);
      i++
    )
      buttons.push(i);
    return buttons;
  }, [availablePages, currentPage]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <View style={styles.colorBar}>
          <Image
            style={{
              width: 43,
              height: height - 68,
            }}
            source={colorBar}
          />
        </View>
        <Header theme="light" />
        <FilterOptionsModal
          isOptionsModalOpen={isOptionsModalOpen}
          setIsOptionsModalOpen={setIsOptionsModalOpen}
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          sortingMode={sortingMode}
          setSortingMode={setSortingMode}
        />
        <LoanPreviewModal
          isOpen={isLoanPreviewModalOpen}
          close={() => setIsLoanPreviewModalOpen(false)}
          applicationId={loanPreviewApplicationId}
        />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>Underwriter Portal</Text>
            <Text style={styles.welcomeMessageText} appearance="hint">
              {`Welcome, ${authStore.user?.firstName} ${authStore.user?.lastName}`}
            </Text>
            <Divider />
            <View>
              <View style={styles.requestedDocumentsHeader}>
                <Text style={styles.header}>
                  {isClosingQueue ? "Closing Queue" : "Final Approval Portal"}
                </Text>
                <View style={{ ...styles.flexRow, flex: 1 }}>
                  <Input
                    style={styles.input}
                    placeholder="Search"
                    value={searchString}
                    onChangeText={(text) => setSearchString(text)}
                    accessoryRight={SearchIcon}
                  />
                  <Button
                    style={styles.optionsButton}
                    status="basic"
                    accessoryRight={OptionsIcon}
                    onPress={() => setIsOptionsModalOpen(true)}
                  />
                  <Text style={styles.flexRow} appearance="hint">
                    Show&nbsp;&nbsp;
                    <Select
                      selectedIndex={selectedIndex}
                      //@ts-ignore
                      onSelect={(index) => setSelectedIndex(index)}
                      value={displayPerPage}
                    >
                      {displayPerPageOptions.map((option) => (
                        <SelectItem
                          style={styles.selectItem}
                          key={"display-per-page-" + option}
                          title={() => (
                            <Text style={styles.selectItem}>{option}</Text>
                          )}
                        />
                      ))}
                    </Select>
                    &nbsp;&nbsp;entries
                  </Text>
                </View>
              </View>
              <View style={{ ...(loading && styles.center) }}>
                <View>
                  {loading || !loanApplications ? (
                    <LoadingIndicator />
                  ) : (
                    <View style={styles.tableContainer}>
                      <View style={styles.tableHeaders}>
                        {Object.values(fieldsDesc).map((field) => (
                          <View
                            key={"header-" + field.display}
                            style={{
                              ...{ width: field.width },
                              ...styles.tableCell,
                            }}
                          >
                            <Text
                              style={{
                                ...styles.tableHeadersCellText,
                                ...{ width: field.width },
                              }}
                            >
                              {field.display}
                            </Text>
                            {field.display === "Assignee" && (
                              <Button
                                status="basic"
                                appearance="ghost"
                                accessoryRight={EditIcon}
                                style={{ width: 10 }}
                                onPress={() => setIsEditing(!isEditing)}
                              />
                            )}
                          </View>
                        ))}
                      </View>
                      <ScrollView
                        style={{
                          ...styles.scroll,
                          maxHeight: height - 300,
                        }}
                        keyboardShouldPersistTaps="handled"
                      >
                        {(loanApplications || []).map(
                          (doc: any, idx: number) => (
                            <View
                              key={doc.loanApplicationId + "-row"}
                              style={styles.tableRow}
                            >
                              {Object.keys(fieldsDesc).map(
                                (fieldName: string) => (
                                  <View
                                    key={
                                      doc.loanApplicationId +
                                      "-row-data-" +
                                      fieldsDesc[fieldName].display
                                    }
                                    style={{
                                      ...{
                                        width: fieldsDesc[fieldName].width,
                                      },
                                      ...styles.tableCell,
                                    }}
                                  >
                                    {fieldName === "assignedUser" &&
                                      underwriterDropdownLoading[
                                        doc.loanApplicationId
                                      ] && <LoadingIndicator />}
                                    {fieldName === "assignedUser" &&
                                      isEditing &&
                                      !underwriterDropdownLoading[
                                        doc.loanApplicationId
                                      ] && (
                                        <>
                                          <Select
                                            size="small"
                                            style={styles.select}
                                            placeholder="User assignment"
                                            value={() => (
                                              <Text numberOfLines={2}>
                                                {doc.assignedUser}
                                              </Text>
                                            )}
                                            selectedIndex={
                                              underwriterDropdownIndexes[
                                                doc.loanApplicationId
                                              ]
                                            }
                                            onSelect={(newIndex) => {
                                              setLastChangedLoanId(
                                                doc.loanApplicationId
                                              );
                                              setUnderwriterDropdownIndexes({
                                                ...underwriterDropdownIndexes,
                                                [doc.loanApplicationId]:
                                                  newIndex,
                                              });
                                            }}
                                          >
                                            {underwriterAssignmentOptions.map(
                                              (user: any, idx: number) => (
                                                <SelectItem
                                                  style={styles.selectItem}
                                                  title={() => user.name}
                                                  key={`${idx}-user-assignment-option`}
                                                />
                                              )
                                            )}
                                          </Select>
                                        </>
                                      )}
                                    {fieldName !== "actions" &&
                                      !(
                                        (isEditing ||
                                          underwriterDropdownLoading[
                                            doc.loanApplicationId
                                          ]) &&
                                        fieldName === "assignedUser"
                                      ) && (
                                        <Text
                                          numberOfLines={3}
                                          style={{
                                            ...(fieldsDesc[fieldName].align
                                              ? {
                                                  textAlign:
                                                    fieldsDesc[fieldName].align,
                                                }
                                              : {}),
                                            ...styles.tableCellText,
                                            ...(fieldName ===
                                              "finalDecisionStatus" ||
                                            fieldName === "status" ||
                                            fieldName ===
                                              "hasPaidApplicationProcessingFee"
                                              ? {
                                                  // @ts-ignore
                                                  ...styles[doc[fieldName]],
                                                  paddingHorizontal: 5,
                                                  textAlign: "center",
                                                }
                                              : {}),
                                            ...{
                                              width:
                                                fieldsDesc[fieldName].width,
                                            },
                                          }}
                                        >
                                          {formatDisplay(
                                            doc[fieldName],
                                            fieldsDesc[fieldName].format || ""
                                          )}
                                        </Text>
                                      )}
                                    {fieldName === "actions" && (
                                      <Button
                                        status="basic"
                                        appearance="ghost"
                                        accessoryRight={EyeIcon}
                                        style={{
                                          width:
                                            fieldsDesc[fieldName].width - 20,
                                        }}
                                        onPress={() => {
                                          setLoanPreviewApplicationId(
                                            doc.loanApplicationId
                                          );
                                          setIsLoanPreviewModalOpen(true);
                                        }}
                                        children={() => (
                                          <Text
                                            style={styles.previewLoanButton}
                                          >
                                            Preview
                                          </Text>
                                        )}
                                      />
                                    )}
                                  </View>
                                )
                              )}
                            </View>
                          )
                        )}
                      </ScrollView>
                    </View>
                  )}
                </View>
              </View>
              <View
                style={{
                  ...styles.flexRow,
                  justifyContent: "space-between",
                  paddingHorizontal: 30,
                  margin: 0,
                }}
              >
                <Text style={styles.subheader} appearance="hint">
                  {`Showing ${(currentPage - 1) * displayPerPage + 1} to ${
                    currentPage < availablePages
                      ? currentPage * displayPerPage
                      : loansCount
                  } of ${loansCount ?? "?"} entries`}
                </Text>
                <View style={styles.flexRow}>
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={LeftArrowIcon}
                    onPress={() => setCurrentPage(Math.max(1, currentPage - 1))}
                  />
                  {getPagesButtonsNumbers().map((idx) => (
                    <Button
                      status="basic"
                      appearance={idx === currentPage ? "filled" : "ghost"}
                      onPress={() => setCurrentPage(idx)}
                      key={"page-btn-" + idx}
                    >
                      {idx}
                    </Button>
                  ))}
                  <Button
                    status="basic"
                    appearance="ghost"
                    accessoryRight={RightArrowIcon}
                    onPress={() =>
                      setCurrentPage(Math.min(availablePages, currentPage + 1))
                    }
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default UnderwriterPortalFinalDecision;
