import {
  Text,
  useStyleSheet,
  Icon,
  Button,
  Input,
  Divider,
  Datepicker,
  CalendarViewModes,
  Select,
  SelectItem,
  Spinner,
} from "@ui-kitten/components";
import React, { useState, useEffect, useRef, createRef } from "react";
import { Dimensions, View } from "react-native";
import Collapsible from "react-native-collapsible";
import { TextInputMask } from "react-native-masked-text";

import { useStore } from "../../stores";
import {
  businessRoleOptions,
  managementExperienceOptions,
} from "../../utils/constants";
import { UploadButtonWithView } from "./UploadButtonWithView";
import { themedStyles } from "./themedStyles";

export const PersonalDetails: React.FC<any> = ({
  canEdit = false,
  user,
  noHeader = false,
  theme = "dark",
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const LoadingIndicator = (props: any) => <Spinner />;
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const PhoneIcon = (props: any) => <Icon {...props} name="phone-outline" />;
  const LinkedInIcon = (props: any) => (
    <Icon
      {...props}
      name="linkedin"
      fill={theme === "dark" ? "white" : "black"}
    />
  );
  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar-outline" />
  );

  const EditIcon = (props: any) => (
    <Icon {...props} name="edit-outline" fill="#26DDF1" />
  );

  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authStore } = useStore();

  const [formattedSSNNumber, setFormattedSSNNumber] = useState(user?.ssnNumber);

  const [formData, setFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    birthday: user?.birthday,
    phoneNumber: user?.phoneNumber,
    email: user?.email,
    ssnNumber: user?.ssnNumber,
    businessRole: user?.businessRole,
    managementExperience: user?.managementExperience,
    linkedIn: user?.linkedIn,
    resumes: user?.resumes,
  });

  const ErrorDescription = (fieldName: string) => {
    if (!errors[fieldName]) return <></>;
    return <View style={styles.error}>{errors[fieldName]}</View>;
  };

  useEffect(() => {
    setFormData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      birthday: user?.birthday,
      phoneNumber: user?.phoneNumber,
      email: user?.email,
      ssnNumber: user?.ssnNumber,
      businessRole: user?.businessRole,
      managementExperience: user?.managementExperience,
      linkedIn: user?.linkedIn,
      resumes: user?.resumes,
    });
    setFormattedSSNNumber(user?.ssnNumber);
  }, [user]);

  const firstNameRef = useRef<Input>(null);
  const lastNameRef = useRef<Input>(null);
  const workEmailRef = useRef<Input>(null);
  const phoneNumberRef = createRef<any>();
  const ssnNumberRef = useRef<TextInputMask>(null);
  const linkedInRef = useRef<Input>(null);

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [selectedBusinessRoleIndex, setSelectedBusinessRoleIndex] =
    useState<any>(null);
  const [
    selectedManagementExperienceIndex,
    setSelectedManagementExperienceIndex,
  ] = useState<any>(null);

  const [errors, setErrors] = useState<any>({});
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (selectedBusinessRoleIndex) {
      setFormData({
        ...formData,
        businessRole: businessRoleOptions[selectedBusinessRoleIndex.row],
      });
    }
  }, [selectedBusinessRoleIndex]);

  useEffect(() => {
    if (selectedManagementExperienceIndex)
      setFormData({
        ...formData,
        managementExperience:
          managementExperienceOptions[selectedManagementExperienceIndex?.row] ||
          "",
      });
  }, [selectedManagementExperienceIndex]);

  useEffect(() => {
    const num = formData.phoneNumber || "";
    let prt = "";
    if (num.length > 0) {
      if (num.length <= 3) {
        prt = `(${num})`;
      } else if (num.length <= 6) {
        prt = `(${num.slice(0, 3)}) ${num.slice(3, -1)}${num.slice(-1)}`;
      } else {
        prt = `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(
          6,
          -1
        )}${num.slice(-1)}`;
      }
    }
    setFormattedPhoneNumber(prt);
  }, [formData.phoneNumber]);

  return (
    <View>
      {!noHeader && (
        <>
          <View style={styles.flexRowBetween}>
            <Text
              onPress={() => setIsCollapsed(!isCollapsed)}
              style={styles.summarySubHeader}
            >
              Personal Information
            </Text>
            {!!canEdit && (
              <Button
                style={styles.editButton}
                appearance="ghost"
                status="basic"
                accessoryLeft={EditIcon}
                onPress={() => {
                  setIsEditing(!isEditing);
                }}
              />
            )}
          </View>
          <Divider style={styles.divider} />
        </>
      )}
      <Collapsible collapsed={isCollapsed}>
        {ErrorDescription("firstName")}
        {ErrorDescription("lastName")}
        <View style={styles.flexRow}>
          <Input
            ref={firstNameRef}
            style={styles.input}
            textStyle={{
              ...(isEditing
                ? width > 576
                  ? styles.inputText
                  : styles.inputTextMobile
                : styles.inputTextNotEditing),
              color: isEditing
                ? theme === "light"
                  ? "black"
                  : "white"
                : "#4c556d",
            }}
            disabled={!isEditing}
            value={formData.firstName}
            onChangeText={(text) =>
              setFormData({ ...formData, firstName: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                lastNameRef.current?.focus();
              }
            }}
            onSubmitEditing={() => lastNameRef.current?.focus()}
            placeholder="First Name"
          />
          <Input
            ref={lastNameRef}
            style={styles.input}
            textStyle={{
              ...(isEditing
                ? width > 576
                  ? styles.inputText
                  : styles.inputTextMobile
                : styles.inputTextNotEditing),
              color: isEditing
                ? theme === "light"
                  ? "black"
                  : "white"
                : "#4c556d",
            }}
            disabled={!isEditing}
            value={formData.lastName}
            onChangeText={(text) =>
              setFormData({ ...formData, lastName: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                phoneNumberRef.current?.focus();
              }
            }}
            onSubmitEditing={() => phoneNumberRef.current?.focus()}
            placeholder="Last Name"
          />
        </View>
        {ErrorDescription("birthday")}
        <Datepicker
          style={styles.input}
          accessoryRight={CalendarIcon}
          placeholder={() => (
            <Text
              style={{
                ...(isEditing
                  ? width > 576
                    ? styles.inputText
                    : styles.inputTextMobile
                  : styles.inputTextNotEditing),
                color: isEditing
                  ? theme === "light"
                    ? "black"
                    : "white"
                  : "#4c556d",
                paddingHorizontal: 10,
              }}
            >
              {formData.birthday
                ? formData.birthday
                : isEditing
                ? "Birthday"
                : "n/a"}
            </Text>
          )}
          disabled={!isEditing}
          startView={CalendarViewModes.YEAR}
          date={formData.birthday ? new Date(formData.birthday) : ""}
          min={new Date("1900-01-01")}
          onSelect={(nextDate) =>
            setFormData({
              ...formData,
              //@ts-ignore
              birthday: `${1900 + nextDate.getYear()}-${
                //@ts-ignore
                nextDate.getMonth() + 1
                //@ts-ignore
              }-${nextDate.getDate()}`,
            })
          }
        />

        {ErrorDescription("phoneNumber")}
        <Input
          ref={phoneNumberRef}
          style={styles.input}
          textStyle={{
            ...(isEditing
              ? width > 576
                ? styles.inputText
                : styles.inputTextMobile
              : styles.inputTextNotEditing),
            color: isEditing
              ? theme === "light"
                ? "black"
                : "white"
              : "#4c556d",
          }}
          disabled={!isEditing}
          placeholder="Business Phone Number"
          autoCorrect={false}
          value={formattedPhoneNumber}
          //@ts-ignore
          type="cel-phone"
          options={{
            withDDD: true,
            dddMask: "+1 (999) 999-9999",
          }}
          onChangeText={(text) => {
            if (text.length <= 17) {
              setFormData({
                ...formData,
                phoneNumber: text.replace(/[^0-9\+]/g, ""),
              });
            }
          }}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              workEmailRef.current?.focus();
            }
          }}
          onSubmitEditing={() => workEmailRef.current?.focus()}
          accessoryRight={PhoneIcon}
        />

        {ErrorDescription("email")}
        <Input
          ref={workEmailRef}
          style={styles.input}
          textStyle={{
            ...(isEditing
              ? width > 576
                ? styles.inputText
                : styles.inputTextMobile
              : styles.inputTextNotEditing),
            color: isEditing
              ? theme === "light"
                ? "black"
                : "white"
              : "#4c556d",
          }}
          disabled={!isEditing}
          value={formData.email}
          onChangeText={(text) => setFormData({ ...formData, email: text })}
          placeholder="Email"
          accessoryRight={EmailIcon}
        />

        {ErrorDescription("ssnNumber")}
        <TextInputMask
          ref={ssnNumberRef}
          style={{
            ...styles.inputMask,
            ...(!isEditing ? { color: "rgba(143, 155, 179, 0.48)" } : {}),
          }}
          placeholder="Social Security Number"
          autoCorrect={false}
          value={formattedSSNNumber}
          type="custom"
          disabled={!isEditing}
          options={{ mask: "999-99-9999" }}
          onChangeText={(text: string) => {
            if (text.length <= 11) {
              setFormattedSSNNumber(text);
              setFormData({
                ...formData,
                ssnNumber: text.replace(/[^0-9\+]/g, ""),
              });
            }
          }}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              linkedInRef.current?.focus();
            }
          }}
          onSubmitEditing={() => linkedInRef.current?.focus()}
        />

        {ErrorDescription("businessRole")}
        <Select
          disabled={!isEditing}
          status={theme === "dark" ? "primary" : "success"}
          size={width > 576 ? "medium" : "small"}
          style={styles.input}
          placeholder="Title/Business Role"
          value={formData.businessRole}
          selectedIndex={selectedBusinessRoleIndex}
          onSelect={(index) => setSelectedBusinessRoleIndex(index)}
        >
          {businessRoleOptions.map((option, idx) => (
            <SelectItem
              style={{
                ...(isEditing
                  ? width > 576
                    ? styles.inputText
                    : styles.inputTextMobile
                  : styles.inputTextNotEditing),
                color: isEditing
                  ? theme === "light"
                    ? "black"
                    : "white"
                  : "#8F9BB3",
              }}
              title={option}
              key={`${idx}-business-role-option`}
            />
          ))}
        </Select>

        {ErrorDescription("managementExperience")}
        <Select
          disabled={!isEditing}
          status={theme === "dark" ? "primary" : "success"}
          size={width > 576 ? "medium" : "small"}
          style={styles.input}
          placeholder="Years of management experience"
          value={formData.managementExperience || ""}
          selectedIndex={selectedManagementExperienceIndex}
          onSelect={(index) => setSelectedManagementExperienceIndex(index)}
        >
          {managementExperienceOptions.map((option, idx) => (
            <SelectItem
              style={{
                ...(isEditing
                  ? width > 576
                    ? styles.inputText
                    : styles.inputTextMobile
                  : styles.inputTextNotEditing),
                color: isEditing
                  ? theme === "light"
                    ? "black"
                    : "white"
                  : "#8F9BB3",
              }}
              title={option}
              key={`${idx}-business-role-option`}
            />
          ))}
        </Select>

        {ErrorDescription("linkedIn")}
        <Input
          ref={linkedInRef}
          style={styles.input}
          textStyle={{
            ...(isEditing
              ? width > 576
                ? styles.inputText
                : styles.inputTextMobile
              : styles.inputTextNotEditing),
            color: isEditing
              ? theme === "light"
                ? "black"
                : "white"
              : "#4c556d",
          }}
          disabled={!isEditing}
          value={formData.linkedIn}
          onChangeText={(text) => setFormData({ ...formData, linkedIn: text })}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              firstNameRef.current?.focus();
            }
          }}
          onSubmitEditing={() => firstNameRef.current?.focus()}
          placeholder="LinkedIn"
          accessoryRight={LinkedInIcon}
        />

        {ErrorDescription("resumes")}
        <UploadButtonWithView
          formData={formData}
          setFormData={setFormData}
          fieldName="resumes"
          isEditing={isEditing}
          setErrors={setErrors}
        />

        {isEditing && (
          <Button
            style={{ ...styles.saveButton, width: 200, alignSelf: "center" }}
            onPress={async () => {
              setLoading(true);
              const res = await authStore.updateUser(formData);
              setLoading(false);
              if (!res?.ok) {
                setErrors(res.errors);
              } else {
                setErrors({});
                setIsEditing(false);
              }
            }}
            accessoryRight={loading ? LoadingIndicator : <></>}
          >
            Save
          </Button>
        )}
        <Divider style={styles.divider} />
      </Collapsible>
    </View>
  );
};
