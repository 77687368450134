import Constants from "expo-constants";

const getConfig = () => {
  const version = Constants.manifest.version || "0.0.0";
  const build =
    Constants.manifest.ios?.buildNumber ||
    Constants.manifest.android?.versionCode ||
    "0";
  const config = {
    sentry_dsn: process.env.SENTRY_DSN,
    sentry_environment: process.env.SENTRY_ENVIRONMENT,
    gtm_id: process.env.GTM_ID,
    gtag_id: process.env.GTAG_ID,
    gtag_conversion_id: process.env.GTAG_CONVERSION_ID,
    facebook_domain_verification: process.env.FACEBOOK_DOMAIN_VERIFICATION,
    facebook_pixel_id: process.env.FACEBOOK_PIXEL_ID,
    linkedin_insight_id: process.env.LINKEDIN_INSIGHT_ID,
    linkedin_conversion_id: process.env.LINKEDIN_CONVERSION_ID,
    demo_loan_application_report:
      process.env.demo_loan_report ||
      "https://api.test.getoppzo.com/media/loans/one_year_contract_backlogs/Contract.pdf",
    plaidClientId: process.env.PLAID_CLIENT_ID || "plaidClientID",
    stripePublicKey: process.env.STRIPE_PUBLIC_KEY || "stripePublicKey",
    debug: process.env.REACT_DEBUG === "True",
    storeKey: process.env.REACT_STORE_KEY! || "store",
    accessTokenKey: process.env.REACT_ACCESS_TOKEN_KEY! || "accessToken",
    refreshTokenKey: process.env.REACT_REFRESH_TOKEN_KEY! || "refreshToken",
    buildVersion: `v${version} build ${build}`,
    urls: {
      api: process.env.API_URL!,
      frontend: process.env.FRONTEND_URL,
      auth: {
        logIn: "auth/login/",
        logOut: "auth/logout/",
        signUp: "auth/registration/",
        tokenRefresh: "auth/token/refresh/",
        changePassword: "auth/password/change/",
        resetPassword: "auth/password-reset/",
        confirmResetPassword: "auth/password-reset-confirm/",
      },
      postBusinessInfo: "users/post_business_info",
      users: "users/",
      updatePassword: "users/update_password/",
      updateBasicAndBusinessInformation:
        "users/update_basic_and_business_information/",
      checkBusiness: "users/check_business_exists/",
      get_plaid_token: "users/get_plaid_link_token",
      get_plaid_view: "users/get_plaid_view",
      get_rich_text_editor_view: "users/get_rich_text_editor_view",
      get_linked_accounts: "users/get_linked_accounts",
      post_plaid_token: "users/exchange_plaid_public_token_for_access_token/",
      documentRequests: "document_requests/messages/",
      notifications: "notifications/",
      loanDocumentRequests:
        "document_requests/messages/get_application_requests",
      userDocumentRequests: "document_requests/messages/get_user_requests",
      documentRequestsAttachments: "document_requests/messages/get_attachment",
      post_payroll_accounts: "users/post_payroll_accounts/",
      post_funding_account: "users/post_funding_account/",
      loans: {
        application: "loans/applications/",
      },
      business: "businesses/",
      support_send_emails: "users/support/support_send_emails/",
      quickbooks: {
        oauth: "loans/qb/oauth/",
        callback: "loans/qb/callback/",
        disconnect: "loans/qb/disconnect/",
      },
      docusign: {
        get_closing_documents_data: "loans/ds/get_closing_documents_data/",
        generate_loan_terms: "loans/ds/generate_loan_terms/",
        generate_closing_document: "loans/ds/generate_closing_document/",
        get_credit_file_data: "loans/ds/get_credit_file_data/",
        get_template_ids: "loans/ds/get_docusign_template_list/",
        add_template_id: "loans/ds/add_docusign_template/",
        send_document: "loans/ds/send_docusign_document/",
        get_sent_template_id: "loans/ds/get_sent_template_id_for_application/",
        check_if_template_is_owned: "loans/ds/check_if_template_is_owned/",
        get_keys: "loans/ds/get_docusign_app_keys/",
        get_auth_token: "loans/ds/get_docusign_auth_token/",
        create_signing_ceremony: "loans/ds/create_signing_ceremony/",
        create_lending_document_signing:
          "loans/ds/create_lending_document_signing/",
        create_lending_document_clo_signing:
          "loans/ds/create_lending_document_clo_signing/",
        set_document_as_signed: "loans/ds/set_document_as_signed/",
        set_document_as_clo_signed: "loans/ds/set_document_as_clo_signed/",
        save_signed_document: "loans/ds/save_signed_document/",
        retrieve_signed_document: "loans/ds/retrieve_signed_document/",
        check_matching_lender_data: "loans/ds/check_matching_lender_data/",
      },
      mbo: {
        mbo: "users/secondary_business_owner_view/",
        store_mbo_emails: "users/sbo_utility/store_mbo_emails/",
        send_mbo_emails: "users/sbo_utility/send_mbo_emails/",
        check_mbo_validity: "users/sbo_utility/check_mbo_validity/",
        check_mbo_existence: "users/sbo_utility/check_mbo_existence/",
        check_mbo_list_existence: "users/sbo_utility/check_mbo_list_existence/",
        send_mbo_email_reminders: "users/sbo_utility/send_mbo_email_reminders/",
        list_all_business_owners:
          "users/secondary_business_owner_view/list_all_business_owners/",
      },
      investor: {
        get_loan_tape_data: "loans/investor/get_loan_tape_data/",
        get_investor_loans_list: "loans/investor/get_investor_loans_list/",
      },
    },
  };

  console.log({ config });

  return config;
};

export default getConfig();
