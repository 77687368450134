import { useNavigation } from "@react-navigation/native";
import { Layout, Spinner, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
import { Dimensions } from "react-native";
import { sendMboEmailReminders } from "../../services/api";

const MBOReminder: React.FC<any> = ({ route }) => {
  let { width } = Dimensions.get("window");
  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await sendMboEmailReminders({ userId: Number(id) });
      if (response.response.entities) {
        if ("error" in response.response.entities) {
          console.log("error");
          navigation.navigate("register");
        }
        setSuccess(response.response.entities["success"]);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.preApprovalContainer}>
          <View
            style={
              width > 768
                ? {
                    ...styles.gradientContainer,
                  }
                : {
                    ...styles.gradientContainerMobile,
                  }
            }
          />
          <View
            style={width > 768 ? styles.preApproval : styles.preApprovalMobile}
          >
            {!loading ? (
              <>
                <Text style={styles.header}>
                  {success ? "Thank you!" : "Sorry!"}
                </Text>
                <Text style={styles.subHeader}>
                  {success
                    ? "We have sent a reminder to your business partners. We will notify you when they have completed their information."
                    : "You have already sent out an email reminder to your business partners. Please try again after 2 days."}
                </Text>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default MBOReminder;
