import {
  Text,
  useStyleSheet,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View, Dimensions } from "react-native";

import { VerticalBarChart } from "./VerticalBarChart";
import { themedStyles } from "./themedStyles";

const LoanBreakdownAndDesignation: React.FC<any> = ({
  breakdownData,
  distributionData,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");

  const [selectedIndex, setSelectedIndex] = useState<any>(new IndexPath(0));

  const charts = ["Loan Amount Distribution", "Business Ownership Designation"];

  const [distributionChartData, setDistributionChartData] = useState(
    breakdownData.map((item: any) => [item])
  );
  const [designationChartData, setDesignationChartData] = useState(
    distributionData.map((obj: any) => [obj.value])
  );

  const colors = ["#6A1DB8"];
  const [currentChart, setCurrentChart] = useState(charts[0]);

  useEffect(() => {
    setDistributionChartData(breakdownData.map((item: any) => [item]));
  }, [breakdownData]);

  useEffect(() => {
    if (selectedIndex) {
      setCurrentChart(charts[selectedIndex.row]);
    }
  }, [selectedIndex]);

  return (
    <View>
      <View style={{ ...styles.flexRow, justifyContent: "space-between" }}>
        <Select
          style={{ flex: 1, margin: 5 }}
          placeholder="Month(s)/Week(s)/Day(s)"
          value={currentChart}
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
        >
          {charts.map((title, idx) => (
            <SelectItem
              key={`chart-option-${idx}`}
              style={styles.selectItem}
              title={() => <Text style={styles.selectItem}>{title}</Text>}
            />
          ))}
        </Select>
      </View>
      {currentChart === charts[0] && (
        <VerticalBarChart
          chartHeight={300}
          xTitle="Amount in dollars (1000)"
          yTitle="Percentage"
          colors={colors}
          data={distributionChartData}
          labels={["0-200K", "200-400K", "400-600K", "600-800K", "800-1M"]}
          maxYValue={100}
        />
      )}
      {currentChart === charts[1] && (
        <VerticalBarChart
          chartHeight={300}
          xTitle="Business Ownership"
          yTitle="Percentage"
          colors={colors}
          data={designationChartData}
          labels={distributionData.map((obj: any) => obj.label)}
          dataFormat="%"
          maxYValue={100}
        />
      )}
    </View>
  );
};

export default LoanBreakdownAndDesignation;
