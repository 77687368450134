import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Datepicker,
  CalendarViewModes,
  Button,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { TextInputMask } from "react-native-masked-text";

import { useStore } from "../../stores";
import MBOStore from "../../stores/MBOStore";
import { uploadDocument } from "../../utils";
import { themedStyles } from "./themedStyles";

export const SummaryTaxGuard: React.FC<any> = ({
  user,
  mbo,
  isEdit,
  setIsEdit,
  formData,
  setFormData,
  setSuccess,
  theme,
}) => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore, mboStore } = useStore();

  const defaultFields = {
    signed8821Form: "",
  };

  const defaultErrors = {
    ...defaultFields,
    nonFieldErrors: "",
  };

  const [localFormData, setLocalFormData] = useState<any>({ ...defaultFields });

  useEffect(() => {
    formDataReset();
    setSuccess(false);
  }, []);

  const formDataReset = () => {
    setLocalFormData({
      signed8821Form: formData.signed8821Form,
    });
  };

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ ...defaultErrors });

  const onSubmitForm = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setFormData({
      ...formData,
      ...localFormData,
    });
    setErrorMessages({ ...defaultErrors });
    const result = await mboStore.updateMBOInfo(mbo.id, {
      ...localFormData,
    });
    if (!result?.ok) {
      console.log(result?.errors);
      setErrorMessages(result?.errors);
    } else {
      setIsEdit(false);
      setSuccess(true);
    }
    setLoading(false);
  }, [loading, localFormData]);

  return (
    <View>
      <Text>
        {errorMessages?.nonFieldErrors && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.nonFieldErrors}
          </Text>
        )}
      </Text>
      <Text>
        {errorMessages?.signed8821Form && (
          <Text category="h6" style={styles.error}>
            {errorMessages?.signed8821Form}
          </Text>
        )}
      </Text>
      <Button
        disabled={!isEdit}
        style={
          localFormData.signed8821Form
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={localFormData.signed8821Form ? "success" : "basic"}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setLocalFormData({
                ...localFormData,
                signed8821Form: doc.file,
              });
              setErrorMessages({ ...errorMessages, signed8821Form: "" });
            } else {
              setErrorMessages({
                ...errorMessages,
                signed8821Form: "File too large. Upload limit: 10MB.",
              });
            }
          })
        }
        children={() => <Text style={styles.uploadButtonText}>Upload</Text>}
      />
      {isEdit && (
        <View style={styles.flexRow}>
          <TouchableOpacity
            style={styles.saveButton}
            onPress={() => {
              onSubmitForm();
            }}
          >
            <LinearGradient
              style={styles.saveButtonGradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={["#D012B2", "#8A1CDB", "#5327FF"]}
            >
              <Text style={styles.saveButtonText}>Save</Text>
              <Icon
                style={styles.saveButtonIcon}
                name="checkmark-circle-2"
                fill="white"
              />
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.cancelButton,
              ...(theme === "light" ? { backgroundColor: "#F7F9FC" } : {}),
            }}
            onPress={() => {
              setIsEdit(false);
              formDataReset();
            }}
          >
            <Text
              style={{
                ...styles.cancelButtonText,
                ...(theme === "light" ? { color: "#222B45" } : {}),
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
