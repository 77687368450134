import { observable } from "mobx";
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  modelAction,
  prop,
  objectMap,
  ModelCreationData,
} from "mobx-keystone";

import Business from "../models/Business";
import * as api from "../services/api";
import Store from "./Store";

type Extra = { [key: string]: any } | null;
type Response = { errors: any; ok: boolean; extra: Extra };

const getError = (error: Error): Response => {
  try {
    return { errors: JSON.parse(error.message), ok: false, extra: null };
  } catch {
    return { errors: { detail: error.message }, ok: false, extra: null };
  }
};

const _success = { errors: { detail: null }, ok: true, extra: null };
const getSuccess = (extra?: Extra): Response =>
  extra ? { ..._success, extra } : _success;

@model("oppzo/BusinessStore")
export default class BusinessStore extends Model({
  business: prop(() => objectMap<Business>()),
}) {
  @observable
  loading = false;

  @modelAction
  createOrUpdateBusiness(data: ModelCreationData<Business>) {
    const id = `${data.id}`;
    if (this.business.has(id)) {
      this.business.get(id)!.update(data);
    } else {
      const condition = new Business(data);
      this.business.set(id, condition);
      condition.update(data);
    }
  }

  @modelAction
  removeBusiness(id: number) {
    this.business.delete(`${id}`);
  }

  @modelFlow
  addbusiness = _async(function* (this: BusinessStore, data: Business) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let entities: ModelCreationData<Business>;
    try {
      ({
        response: { entities },
      } = yield* _await(api.addBusiness(data)));
    } catch (error: any) {
      console.warn("[DEBUG] error adding condition", error);
      return getError(error);
    }

    this.createOrUpdateBusiness(entities);

    this.loading = false;
    return getSuccess({ id: entities.id });
  });

  @modelFlow
  fetchBusinesses = _async(function* (this: BusinessStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;

    this.loading = true;

    let results: ModelCreationData<Business>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.fetchBusiness(token)));
    } catch (error: any) {
      console.warn("[DEBUG] error fetching Businesses", error);
      return getError(error);
    }
    console.log("[DEBUG] fetch results", results);
    results.forEach((data) => this.createOrUpdateBusiness(data));

    this.business.forEach((business) => {
      let flag = false;
      results.forEach((rbusiness) => {
        if (business.id === rbusiness.id) {
          flag = true;
        }
      });
      if (!flag) {
        this.removeBusiness(business.id);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateBusiness = _async(function* (
    this: BusinessStore,
    id: number,
    data: Partial<Business>
  ) {
    const rootStore = getRoot<Store>(this);
    console.log("Fetching root store in update business in Business Store");
    if (!rootStore.authStore) return;
    const token = yield* _await(rootStore.authStore.getToken());
    if (!token) return;
    console.log("Successfully found token in BusinessStore update business fn");
    this.loading = true;

    const filesData = {};
    const nonFilesData = {};
    const fileFieldsNames = ["baseContract"];
    Object.keys(data).forEach((key: string) => {
      //@ts-ignore
      if (data[key] instanceof File) {
        //@ts-ignore
        filesData[key] = data[key];
      } else if (!fileFieldsNames.includes(key)) {
        //@ts-ignore
        nonFilesData[key] = data[key];
      }
    });

    let entities: ModelCreationData<Business>;
    if (Object.keys(nonFilesData).length > 0) {
      try {
        ({
          response: { entities },
        } = yield* _await(api.updateBusiness(token, id, nonFilesData)));
        this.createOrUpdateBusiness(entities);
      } catch (error: any) {
        console.warn("[DEBUG] error updating Business", error);
        return getError(error);
      }
    }
    if (Object.keys(filesData).length > 0) {
      try {
        ({
          response: { entities },
        } = yield* _await(api.updateBusinessFiles(token, id, filesData)));
        this.createOrUpdateBusiness(entities);
      } catch (error: any) {
        console.warn("[DEBUG] error updating Business", error);
        return getError(error);
      }
    }

    this.loading = false;
    return getSuccess();
  });
}
