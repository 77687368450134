import { Text, useStyleSheet, Icon, Tooltip } from "@ui-kitten/components";
import React, { useState } from "react";
import { TouchableOpacity } from "react-native";

import { themedStyles } from "./themedStyles";

export const OnMouseClickTooltip: React.FC<any> = ({
  text,
  theme = "light",
  fontSize = 14,
  iconName = "question-mark-circle-outline",
  iconColor = "#8F9BB3",
}) => {
  const styles = useStyleSheet(themedStyles);
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Tooltip
      anchor={() => (
        <TouchableOpacity onPress={() => setShowInfo(true)}>
          <Icon style={styles.infoIcon} name={iconName} fill={iconColor} />
        </TouchableOpacity>
      )}
      visible={showInfo}
      onBackdropPress={() => setShowInfo(false)}
      children={() => (
        <Text
          style={{
            ...(theme === "light"
              ? styles.tooltipTextLight
              : styles.tooltipTextDark),
            fontSize,
            maxWidth: 300,
          }}
        >
          {text}
        </Text>
      )}
    />
  );
};
