import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  flexRow: { flexDirection: "row" },
  flexColumn: { flexDirection: "column" },
  flexAlignCenter: { alignItems: "center" },
  scroll: {
    flex: 1,
  },
  gradientContainerYesOrNo: {
    bottom: 21,
    top: "none",
    width: "80%",
  },
  gradientContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "60%",
    width: "90%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
    marginBottom: 34,
  },
  subHeader: {
    fontFamily: "Montserrat_400Regular",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 70,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 18,
    fontSize: 12,
    marginBottom: 34,
  },
  question: {
    fontFamily: "Montserrat_600SemiBold",
    color: "white",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 40,
  },
  questionButtonSelected: {
    flex: 1,
    maxWidth: "49%",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
  },
  questionButton: {
    flex: 1,
    maxWidth: "49%",
    borderColor: "#55AAFF",
  },
  buttonTextSelected: {
    fontFamily: "Montserrat_700Bold",
    color: "#101426",
    textTransform: "uppercase",
  },
  buttonText: {
    fontFamily: "Montserrat_700Bold",
    color: "#55AAFF",
    textTransform: "uppercase",
  },
  questionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 80,
  },
  formQuestionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between",
    marginBottom: 15,
  },
  formQuestionButtonsContainerMobile: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    alignItems: "flex-start",
    marginBottom: 15,
  },
  preQualificationContainer: {
    flex: 1,
    alignItems: "center",
  },
  preQualification: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    padding: 100,
    marginBottom: 50,
    backgroundColor: "#101426",
  },
  preQualificationMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    padding: 32,
    marginBottom: 50,
    marginLeft: 36,
    backgroundColor: "#101426",
  },
  preQualificationFormMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    padding: 32,
    marginHorizontal: 36,
    marginVertical: 22,
    backgroundColor: "#101426",
    width: "80%",
  },
  preQualificationLabelContainer: {
    flex: 1,
    textAlign: "center",
    marginBottom: 34,
  },
  preQualificationLabel: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 12,
    lineHeight: 16,
    color: "#1D84FF",
  },
  preQualificationSubLabel: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 10,
    lineHeight: 16,
    color: "white",
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
  },
  disabledActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "color-basic-600",
    fontSize: 14,
    textTransform: "capitalize",
  },
  inputLabel: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  datePickerPlaceholderText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginHorizontal: 8,
  },
  input: {
    fontSize: 22,
    marginBottom: 22,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
  },
  dropdown: {
    fontSize: 22,
    marginBottom: 22,
    backgroundColor: "none",
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
  },
  preQualStatusContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 86,
    width: "100%",
  },
  preQualStatusContainerMobile: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    width: "100%",
  },
  divider: {
    height: 2,
    color: "#C5CEE0",
    maxWidth: 40,
    minWidth: 10,
    flex: 1,
  },
  uploadButtonText: {
    fontFamily: "Montserrat_400Regular",
    fontStyle: "normal",
    fontSize: 15,
    lineHeight: 20,
    color: "#8F9BB3",
  },
  uploadButton: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
    backgroundColor: "#222B45",
  },
  uploadButtonSuccess: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    border: 0,
    backgroundColor: "rgba(0, 224, 150, 0.08)",
  },
  uploadButtonError: {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    paddingVertical: 18,
    marginBottom: 25,
    border: 0,
    backgroundColor: "rgba(224, 0, 0, 0.08)",
  },
  selectedFile: {
    color: "color-success-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
    marginBottom: 25,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    marginBottom: 10,
    padding: 5,
  },
  modalBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalContainer: {
    width: "50%",
    marginLeft: "25%",
    backgroundColor: "#101426",
    padding: 50,
  },
  modalContainerMobile: {
    width: "90%",
    marginLeft: "5%",
    backgroundColor: "#101426",
    padding: 10,
  },
  modalText: {
    color: "white",
    justifyContent: "center",
    fontFamily: "Montserrat_700Bold",
    fontSize: 18,
    lineHeight: 24,
  },
  modalRow: {
    marginTop: 50,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  modalYesButton: {
    backgroundColor: "#1D84FF",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalNoButton: {
    backgroundColor: "#222B45",
    flex: 0.4,
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 4,
  },
  modalButtonText: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    lineHeight: 16,
  },
  rejectionModal: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "color-basic-1100",
  },
  rejectionModalText: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 22,
    lineHeight: 32,
    color: "white",
    maxWidth: 550,
    alignText: "center",
    textAlign: "center",
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
  select: {
    fontSize: 22,
    flex: 1,
    marginRight: 20,
  },
  selectItem: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 15,
    alignText: "left",
  },
});
