import { useLinkTo } from "@react-navigation/native";
import {
  Button,
  Text,
  useStyleSheet,
  Icon,
  Layout,
} from "@ui-kitten/components";
import React from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";

const NotQualified: React.FC<object> = () => {
  const linkTo = useLinkTo();
  const styles = useStyleSheet(themedStyles);
  const ExitIcon = (props: any) => (
    <Icon {...props} name="arrow-forward-outline" />
  );

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.preQualificationContainer}>
          <View style={styles.preQualification}>
            <Text style={styles.header}>Sorry</Text>
            <Text style={styles.subHeader}>
              You do not qualify to proceed to the next steps.
            </Text>
            <Button
              style={styles.nextActionButton}
              status="basic"
              onPress={() => {
                linkTo("/dashboard");
              }}
              accessoryRight={ExitIcon}
            >
              Exit
            </Button>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default NotQualified;
