import * as eva from "@eva-design/eva";
import {
  Layout,
  useStyleSheet,
  ThemeProvider,
  Button,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { View, ScrollView } from "react-native";

import Header from "../../components/Header";
import UnderwriterPortalHeader from "../../components/UnderwriterPortalHeader";
import { useStore } from "../../stores";
import ClosingDocumentsTab from "./ClosingDocumentsTab";
import FundsTrackingTab from "./FundsTrackingTab";
import { getLoanApplicationDetails } from "../../services/api";
import { themedStyles } from "./themedStyles";

const CreditFiles: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const investorView =
    route?.params?.investorView ||
    new URLSearchParams(window.location.search).get("investorView");

  useEffect(() => {
    console.log("params", route?.params);
  }, []);

  const [selectedSubMenu, setSelectedSubMenu] = useState(investorView ? 1 : 0);
  const [editTimestampValue, setEditTimestampValue] = useState<Date | null>(
    null
  );

  const { authStore } = useStore();

  const menu = [
    !investorView && { name: "Closing Documents" },
    ...(authStore.user?.showFundsTrackingTab
      ? [{ name: "Funds Tracking" }]
      : []),
  ];

  // Get current timestamp
  useEffect(() => {
    if (authStore?.accessToken && applicationId) {
      getLoanApplicationDetails(authStore?.accessToken, applicationId)
        .then((resp) => {
          console.log(resp);
          setEditTimestampValue(resp.response.entities.modified);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <ThemeProvider theme={eva.light}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme="light" />
          <UnderwriterPortalHeader
            activeTab="credit-files"
            applicationId={applicationId}
            investorView={investorView}
            editTimestampValue={editTimestampValue}
          />
          <View style={styles.subMenu}>
            {menu.map((item, idx) => (
              <Button
                key={`sub-menu-${idx}`}
                onPress={() => setSelectedSubMenu(idx)}
                appearance="ghost"
                status={selectedSubMenu === idx ? "primary" : "basic"}
              >
                {item.name}
              </Button>
            ))}
          </View>
          {menu[selectedSubMenu].name === "Closing Documents" && (
            <ClosingDocumentsTab
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
            />
          )}
          {menu[selectedSubMenu].name === "Funds Tracking" && (
            <FundsTrackingTab
              canEdit={authStore.user?.isStaff}
              applicationId={applicationId}
            />
          )}
        </ScrollView>
      </Layout>
    </ThemeProvider>
  );
};

export default CreditFiles;
