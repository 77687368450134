/* eslint-disable no-console */

import camelCaseKeys from "camelcase-keys";

import config from "../../config";

function offlineResponse() {
  console.log("[DEBUG: API]", "You are offline.");
  return "You are offline";
}

export type TokenType = string;
export type APIResponse = { response: { entities?: any } };

export function getFullUrl(endpoint: string): string {
  if (endpoint.indexOf("https://") >= 0 || endpoint.indexOf("http://") >= 0) {
    return endpoint;
  }
  return endpoint.indexOf(config.urls.api) === -1
    ? `${config.urls.api}${endpoint}`
    : endpoint;
}

export const getMediaFile = async (uri: string, token: string) => {
  const resp = await callApi(uri, token || "", "GET", {}, "", "blob");
  const file = resp.response.entities;
  const a = document.createElement("a");
  a.href = file;
  a.download = uri.split("/").slice(-1)[0];
  a.click();
};

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export async function callApi(
  endpoint: string,
  token: TokenType = "",
  method: string = "GET",
  headers: HeadersInit = {},
  body: BodyInit = "",
  responseType = "json"
): Promise<APIResponse> {
  const fullUrl = getFullUrl(endpoint);

  const requestHeaders = new Headers(headers);

  if (token) {
    requestHeaders.append("Authorization", `Bearer ${token}`);
  }

  const params: RequestInit = {
    method,
    credentials: "include",
    headers: requestHeaders,
  };

  if (
    method === "PATCH" ||
    method === "POST" ||
    method === "PUT" ||
    method === "DELETE"
  ) {
    if (body) {
      params.body = body;
    }
  }

  console.log("[DEBUG:API]", fullUrl, method, requestHeaders, body, params);

  const response = await fetch(fullUrl, params);
  if (response.status < 100 || response.status >= 500) {
    throw new Error(offlineResponse());
  }

  if (responseType === "blob") {
    const blob = await response.blob();
    const file = window.URL.createObjectURL(blob);
    return {
      response: {
        entities: file,
      },
    };
  } else {
    if (response.status === 204) {
      return { response: {} };
    }

    const json = camelCaseKeys(await response.json(), { deep: true });
    if (!response.ok) {
      throw new Error(JSON.stringify(json));
    }

    return {
      response: {
        entities: json,
      },
    };
  }
}
