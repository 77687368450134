import { default as snakeCaseKeys } from "snakecase-keys";

import config from "../../config";
import Business from "../../models/Business";
import { camelCaseToSnakeCase } from "../../utils";
import { callApi, getFullUrl } from "./base";

export const addBusiness = async (data: Partial<Business>) =>
  callApi(
    `${config.urls.business}sign_up_business/`,
    "",
    "POST",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data.$ || {}))
  );

export const fetchBusiness = async (token: string) =>
  callApi(config.urls.business, token, "GET");

export const updateBusinessFiles = async (
  token: string,
  id: number,
  data: object
) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) =>
    //@ts-ignore
    formData.append(camelCaseToSnakeCase(key), data[key])
  );

  return callApi(`${config.urls.business}${id}/`, token, "PATCH", {}, formData);
};

export const updateBusiness = async (
  token: string,
  id: number,
  data: Partial<Business>
) =>
  callApi(
    `${config.urls.business}${id}/`,
    token,
    "PATCH",
    { "Content-Type": "application/json" },
    JSON.stringify(snakeCaseKeys(data))
  );
