import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  adminDashBoard: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginLeft: 50,
    marginRight: 25,
    maxWidth: 1450,
    overflow: "hidden",
  },
  adminDashboardContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    flex: 1,
    fontSize: 22,
    margin: 5,
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 1,
    padding: 1,
  },
  filterCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    padding: 5,
  },
  filterSubCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 2,
    padding: 2,
    marginHorizontal: 20,
  },
  LogInButton: {
    maxWidth: 125,
    marginTop: 40,
    paddingVertical: 10,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    paddingBottom: 20,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
  },
  optionsHeader: {
    fontFamily: "Montserrat_700Bold",
    lineHeight: 24,
    fontSize: 18,
    flex: 1,
    marginBottom: 30,
  },
  subheader: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 13,
  },
  colorBar: {
    position: "absolute",
    left: 0,
    top: 68,
    overflow: "hidden",
  },
  requestedDocumentsHeader: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2,
    margin: 2,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsButton: {
    padding: 5,
    margin: 5,
    marginHorizontal: 10,
    width: 30,
    height: 30,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  optionsModal: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  verticalDivider: {
    backgroundColor: "#0002",
    width: 1,
    height: 600,
    flex: 1,
  },
  optionsModalSection: {
    padding: 10,
    margin: 10,
  },
  tableHeaders: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#EDF1F7",
  },
  tableHeadersCell: {
    fontFamily: "Montserrat_700Bold",
    color: "black",
    lineHeight: 24,
    fontSize: 15,
    paddingHorizontal: 10,
    margin: 1,
  },
  tableRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },
  tableCellName: {
    fontFamily: "Montserrat_500Medium",
    lineHeight: 20,
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 10,
    margin: 1,
    textTransform: "uppercase",
  },
  tableCellItemTitle: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 20,
    fontSize: 14,
    paddingRight: 10,
    paddingLeft: 20,
    flex: 7,
    margin: 1,
  },
  tableCellColItemTitle: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 20,
    fontSize: 14,
    paddingRight: 10,
    paddingLeft: 40,
    flex: 7,
    margin: 1,
  },
  tableCellSubItemTitle: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 20,
    fontSize: 14,
    paddingRight: 10,
    paddingLeft: 60,
    flex: 7,
    margin: 1,
  },
  tableCellItem: {
    fontFamily: "Montserrat_400Regular",
    lineHeight: 20,
    fontSize: 14,
    paddingHorizontal: 10,
    textAlign: "center",
    flex: 1,
    margin: 1,
  },
  itemTotal: {
    borderTopWidth: 3,
    borderTopColor: "#000",
    marginVertical: 20,
    marginLeft: 20,
  },
  tableContainer: {},
  ACTIVE: {
    backgroundColor: "color-success-default",
    color: "white",
    fontFamily: "Montserrat_700Bold",
  },
  INACTIVE: {
    backgroundColor: "color-basic-default",
    fontFamily: "Montserrat_700Bold",
  },
  DENIED: {
    backgroundColor: "color-danger-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
  PENDING: {
    backgroundColor: "color-warning-default",
    fontFamily: "Montserrat_700Bold",
    color: "white",
  },
});
