import { Button, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useState } from "react";
import { View } from "react-native";

import { themedStyles } from "./themedStyles";

export const YesNoQuestion: React.FC<any> = ({
  answer,
  setAnswer,
  option1 = "YES",
  option2 = "NO",
  question,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [onHover, setOnHover] = useState("");

  return (
    <>
      <Text style={styles.inputLabel}>{question}</Text>
      <View style={styles.formQuestionButtonsContainer}>
        <Button
          style={
            onHover === option1 || answer === option1
              ? styles.questionButtonSelected
              : styles.questionButton
          }
          appearance={
            onHover === option1 || answer === option1 ? "filled" : "outline"
          }
          onMouseEnter={() => setOnHover(option1)}
          onMouseLeave={() => setOnHover("")}
          onPress={() => setAnswer(option1)}
          children={() => (
            <Text
              style={
                onHover === option1 || answer === option1
                  ? styles.buttonTextSelected
                  : styles.buttonText
              }
            >
              {option1}
            </Text>
          )}
        />
        <Button
          style={
            onHover === option2 || answer === option2
              ? styles.questionButtonSelected
              : styles.questionButton
          }
          appearance={
            onHover === option2 || answer === option2 ? "filled" : "outline"
          }
          onMouseEnter={() => setOnHover(option2)}
          onMouseLeave={() => setOnHover("")}
          onPress={() => setAnswer(option2)}
          children={() => (
            <Text
              style={
                onHover === option2 || answer === option2
                  ? styles.buttonTextSelected
                  : styles.buttonText
              }
            >
              {option2}
            </Text>
          )}
        />
      </View>
    </>
  );
};
