import * as eva from "@eva-design/eva";
import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Layout,
  useStyleSheet,
  ThemeProvider,
  Spinner,
  Text,
  Divider,
  Input,
  Icon,
} from "@ui-kitten/components";
import React, { useState, useCallback } from "react";
import { View, ScrollView, TouchableWithoutFeedback } from "react-native";
import Collapsible from "react-native-collapsible";
import { OnMouseClickTooltip } from "../../components/Tooltip";

import Header from "../../components/Header";
import { TableDisplay } from "../../components/TableDisplay";
import UnderwriterPortalHeader from "../../components/UnderwriterPortalHeader";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const RiskFlags: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const investorView =
    route?.params?.investorView ||
    new URLSearchParams(window.location.search).get("investorView");

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  const { authStore } = useStore();
  const [loading, setLoading] = useState(false);

  const [flags, setFlags] = useState<any>([]);
  const categories = [
    "Entity",
    "Other",
    "Financial",
    "Contract",
    "Fraud and Background Check (Individual)",
    "Fraud and Background Check (Entity)",
  ];

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api.getLoanRisks(authStore?.accessToken, applicationId).then((resp) => {
          const response = resp.response.entities;
          setFlags(response);
          setLoading(false);
        });
      }
    }, [useIsFocused()])
  );

  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean[]>(
    Array(6).fill(true)
  );

  return (
    <ThemeProvider theme={eva.light}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme="light" />
          <UnderwriterPortalHeader
            activeTab="risk-flags"
            applicationId={applicationId}
            investorView={investorView}
          />
          {loading ? (
            <View style={{ ...styles.container, alignItems: "center" }}>
              <LoadingIndicator />
            </View>
          ) : (
            <View style={styles.cardsContainer}>
              {categories.map((category: any, index: number) => (
                <View style={styles.card} key={category}>
                  <TouchableWithoutFeedback
                    onPress={() =>
                      setIsCollapsibleOpen(
                        isCollapsibleOpen.map((val, idx) =>
                          idx === index ? !val : val
                        )
                      )
                    }
                  >
                    <View style={styles.flexRowBetween}>
                      <Text style={styles.cardHeader}>
                        {category +
                          (applicationId ? "" : "(no application selected)")}
                      </Text>
                      <Icon
                        style={[
                          styles.chevronButton,
                          {
                            transform: [
                              {
                                rotate: isCollapsibleOpen[index]
                                  ? "180deg"
                                  : "0deg",
                              },
                            ],
                          },
                        ]}
                        name="chevron-down-outline"
                      />
                    </View>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <Collapsible collapsed={!isCollapsibleOpen[index]}>
                    <TableDisplay
                      data={flags
                        .filter((flag: any) => flag.category === category)
                        .filter((flag: any) => flag.status)
                        .map((flag: any) => ({
                          header: (
                            <View style={styles.flagHeader}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  appearance="hint"
                                  style={styles.headerCell}
                                >
                                  {flag.name}
                                </Text>
                                <OnMouseClickTooltip
                                  text={
                                    <Text style={styles.tooltipTextLight}>
                                      <table>
                                        <tr>
                                          <td>Updated At: {"\t"}</td>
                                          <td>
                                            {flag.lastUpdatedAt
                                              ? new Date(
                                                  flag.lastUpdatedAt
                                                ).toLocaleString("US")
                                              : "N/A"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Clarification: </td>
                                          <td>
                                            {flag.additionalDetails
                                              ? flag.additionalDetails
                                              : "N/A"}
                                          </td>
                                        </tr>
                                      </table>
                                    </Text>
                                  }
                                />
                              </View>
                              <Text style={styles.yesFlag}>YES</Text>
                            </View>
                          ),
                          displayValue: (
                            <Input
                              style={styles.flagDescription}
                              textStyle={styles.flagDescriptionText}
                              numberOfLines={4}
                              value={
                                flag.description +
                                "\n\n" +
                                flag.additionalDetails
                              }
                              multiline
                              disabled
                            />
                          ),
                        }))}
                      itemsPerRow={4}
                    />
                    {flags
                      .filter((flag: any) => flag.category === category)
                      .filter((flag: any) => flag.status).length &&
                    flags
                      .filter((flag: any) => flag.category === category)
                      .filter((flag: any) => !flag.status).length ? (
                      <Divider style={styles.cardDivider} />
                    ) : (
                      <></>
                    )}
                    <TableDisplay
                      data={flags
                        .filter((flag: any) => flag.category === category)
                        .filter((flag: any) => !flag.status)
                        .map((flag: any) => ({
                          header: (
                            <View style={styles.flagHeader}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  appearance="hint"
                                  style={styles.headerCell}
                                >
                                  {flag.name}
                                </Text>
                                <OnMouseClickTooltip
                                  text={
                                    <Text style={styles.tooltipTextLight}>
                                      <table>
                                        <tr>
                                          <td>Updated At: {"\t"}</td>
                                          <td>
                                            {flag.lastUpdatedAt
                                              ? new Date(
                                                  flag.lastUpdatedAt
                                                ).toLocaleString("US")
                                              : "N/A"}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Clarification: </td>
                                          <td>
                                            {flag.additionalDetails
                                              ? flag.additionalDetails
                                              : "N/A"}
                                          </td>
                                        </tr>
                                      </table>
                                    </Text>
                                  }
                                />
                              </View>
                              {flag.status !== null && (
                                <Text style={styles.noFlag}>NO</Text>
                              )}
                              {flag.status === null && (
                                <Text style={styles.unknownFlag}>N/A</Text>
                              )}
                            </View>
                          ),
                          displayValue: "",
                        }))}
                      itemsPerRow={4}
                      horizontalDividerBetweenRows
                    />
                  </Collapsible>
                </View>
              ))}
            </View>
          )}
        </ScrollView>
      </Layout>
    </ThemeProvider>
  );
};

export default RiskFlags;
