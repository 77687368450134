import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/core";
import {
  Text,
  useStyleSheet,
  Modal,
  Divider,
  Icon,
  ThemeProvider,
  Spinner,
  Button,
  Input,
  Select,
  SelectItem,
  IndexPath,
} from "@ui-kitten/components";
import React, { useState, useEffect } from "react";
import { TouchableOpacity, View, ScrollView, Dimensions } from "react-native";

import { TableDisplay } from "../../components/TableDisplay";
import { OnMouseClickTooltip } from "../../components/Tooltip";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

export const CovenantModal: React.FC<any> = ({ isOpen, close, covenants }) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const [covenantData, setCovenantData] = useState({
    leverage: 1.0,
    dscr: 1.0,
    tnw: 1.0,
    currentRatio: 1.0,
  });

  const LoadingIndicator = (_props: any) => (
    <Spinner status="primary" size="giant" />
  );

  useEffect(() => {
    setCovenantData(covenants);
  }, [covenants]);

  return (
    <Modal
      visible={isOpen}
      backdropStyle={styles.backdrop}
      onBackdropPress={() => close()}
      style={styles.previewModal}
    >
      <ThemeProvider theme={eva.light}>
        <View style={styles.modalHeaderContainer}>
          <Text style={styles.header}>Covenants</Text>
          <TouchableOpacity onPress={() => close()}>
            <Icon width={24} height={24} name="close-outline" />
          </TouchableOpacity>
        </View>

        {loading ? (
          <View style={styles.center}>
            <LoadingIndicator />
          </View>
        ) : (
          <ScrollView
            style={{
              ...styles.previewModalScroll,
              maxHeight: Dimensions.get("window").height * 0.75,
            }}
          >
            <>
              <View style={styles.flexRowItemsCenter}>
                <Text style={styles.covenantSectionLabel}>
                  {"- Leverage (Debt/Equity)"}
                </Text>
                <Text style={styles.covenantSectionLabel}>
                  {covenantData.leverage}
                </Text>
              </View>
              <View style={styles.flexRowItemsCenter}>
                <Text style={styles.covenantSectionLabel}>
                  {"- DSCR (EBITDA/Debt Service)"}
                </Text>
                <Text style={styles.covenantSectionLabel}>
                  {covenantData.dscr}
                </Text>
              </View>
              <View style={styles.flexRowItemsCenter}>
                <Text style={styles.covenantSectionLabel}>
                  {"- Tangible Net Worth"}
                </Text>
                <Text style={styles.covenantSectionLabel}>
                  {covenantData.tnw}
                </Text>
              </View>
              <View style={styles.flexRowItemsCenter}>
                <Text style={styles.covenantSectionLabel}>
                  {"- Current Ratio"}
                </Text>
                <Text style={styles.covenantSectionLabel}>
                  {covenantData.currentRatio}
                </Text>
              </View>
            </>
          </ScrollView>
        )}
      </ThemeProvider>
    </Modal>
  );
};
