import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  gradientContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    height: 589,
    width: "90%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  gradientContainerMobile: {
    position: "absolute",
    left: 0,
    top: 0,
    height: 589,
    width: "95%",
    background:
      "linear-gradient(90.11deg, #D012B2 0.12%, #8A1CDB 23.28%, #5327FF 48.75%, #1D84FF 75.79%, #26DDF1 98.97%)",
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 24,
    marginBottom: 70,
  },
  summarySubHeader: {
    fontFamily: "Montserrat_400Regular",
    color: "white",
    lineHeight: 24,
    fontSize: 24,
    paddingTop: 10,
  },
  summarySubSubHeader: {
    fontFamily: "Montserrat_600SemiBold",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 12,
    paddingTop: 10,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 32,
    fontSize: 22,
    marginBottom: 34,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 18,
    fontSize: 12,
    marginBottom: 34,
  },
  title: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 26,
    color: "white",
    lineHeight: 32,
    marginBottom: 20,
  },
  subTitle: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "500",
    fontSize: 13,
    lineHeight: 24,
    color: "white",
  },
  preApproval: {
    flex: 1,
    backgroundColor: "#101426",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 100,
    marginVertical: 50,
    maxWidth: 800,
  },
  preApprovalMobile: {
    flex: 1,
    backgroundColor: "#101426",
    alignItems: "stretch",
    justifyContent: "center",
    padding: 32,
    marginHorizontal: 36,
    marginVertical: 22,
    width: "80%",
  },
  preApprovalContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
});
