import {
  model,
  Model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";

@model("oppzo/Notification")
export default class Notification extends Model({
  id: prop<number>(),
  userId: prop<number>(),
  text: prop<string>(),
  created: prop<Date>(),
  isRead: prop<boolean>(),
  data: prop<object | null>(),
}) {
  @modelAction
  update(data: ModelCreationData<Notification>) {
    Object.assign(this, data);
  }
}
