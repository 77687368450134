import {
  Button,
  Text,
  useStyleSheet,
  Icon,
  Spinner,
} from "@ui-kitten/components";
import * as WebBrowser from "expo-web-browser";
import React, { useState, useEffect } from "react";

import config from "../../config";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

export const ApplicationFeeModal: React.FC<any> = ({
  id,
  setShowEINSSNFields,
  paymentStatus,
  setPaymentStatus,
}) => {
  const styles = useStyleSheet(themedStyles);
  const CreditCardIcon = (props: any) => <Icon {...props} name="credit-card" />;
  const LoadingIndicator = (_props: any) => <Spinner status="basic" />;
  const IconPlaceholder = (_props: any) => <></>;

  const { loanStore } = useStore();

  useEffect(() => {
    loanStore.verifyApplicationFeePayment(id).then((res) => {
      if (res?.extra?.isPaid) setPaymentStatus("success");
      else setPaymentStatus("unpaid");
    });
  }, []);

  const [isHovering, setIsHovering] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Button
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        style={
          isHovering && paymentStatus !== "success"
            ? styles.accountLinkingButtonHover
            : styles.accountLinkingButton
        }
        appearance={paymentStatus ? "outline" : "filled"}
        status={
          paymentStatus === "success"
            ? "success"
            : paymentStatus === "failure"
            ? "danger"
            : "basic"
        }
        disabled={loading}
        onPress={async () => {
          if (paymentStatus === "success" || paymentStatus === "unknown")
            return;
          setLoading(true);
          await WebBrowser.openAuthSessionAsync(
            `${config.urls.api}${config.urls.loans.application}${id}/get_stripe_view/`,
            "/stripe-redirect"
          );
          const res = await loanStore.verifyApplicationFeePayment(id);
          if (res?.extra?.isPaid) setPaymentStatus("success");
          setLoading(false);
        }}
        accessoryRight={
          paymentStatus === "unknown" || loading
            ? LoadingIndicator
            : IconPlaceholder
        }
        accessoryLeft={CreditCardIcon}
        children={() => (
          <Text style={styles.accountLinkingButtonContainer}>
            <Text style={styles.accountLinkingButtonHeader}>
              Pay Application processing fee
            </Text>
            {"\n"}
            <Text style={styles.accountLinkingButtonText}>
              To continue, you need to pay an application processing fee which
              is used to cover the cost of our API integrations. This fee is
              non-refundable.
            </Text>
          </Text>
        )}
      />
    </>
  );
};
