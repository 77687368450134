import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Inter_700Bold",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Inter_500Medium",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 70,
  },
  question: {
    fontFamily: "Inter_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 40,
  },
  questionButton: {
    flex: 1,
    maxWidth: "49%",
  },
  questionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 80,
  },
  formQuestionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  preQualification: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
  },
  preQualificationContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
  },
  inputLabel: {
    fontFamily: "Inter_600SemiBold",

    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  input: {
    fontSize: 22,
    marginBottom: 22,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
    marginVertical: 0,
    paddingVertical: 0,
  },
});
