import {
  Text,
  Spinner,
  useStyleSheet,
  Icon,
  Button,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";

import { ConfirmPrompt } from "../../components/ConfirmPrompt";
import { uploadDocument } from "../../utils/index";
import { themedStyles } from "./themedStyles";

export const BusinessEntityStep: React.FC<any> = ({
  loan,
  goToNextStep,
  updateLoanData,
  hasEmptyRequiredFields = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { width } = Dimensions.get("window");
  const UploadIcon = (props: any) => <Icon {...props} name="upload-outline" />;
  const ArrowIcon = (props: any) => (
    <Icon {...props} name="arrow-forward-outline" />
  );
  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="tiny" />
  );
  const [formData, setFormData] = useState<any>({
    companyOrganizationalChart: loan.companyOrganizationalChart || "",
    operatingAgreement: loan.operatingAgreement || "",
    corporateOrAuthorizingResolution:
      loan.corporateOrAuthorizingResolution || "",
    byLaws: loan.byLaws || "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>("");

  useEffect(() => {
    const newFormData = { ...formData };
    Object.keys(formData).forEach(
      (key) => (newFormData[key] = loan[key] || formData[key])
    );
    setFormData(newFormData);
  }, [loan]);

  const [isConfirmPromptOpen, setIsConfirmPromptOpen] = useState(false);

  const allRequiredFieldFilled = () =>
    formData.byLaws && formData.corporateOrAuthorizingResolution;

  const clickSave = () => {
    if (!allRequiredFieldFilled()) setIsConfirmPromptOpen(true);
    else saveData();
  };

  const saveData = async () => {
    setLoading(true);
    const res = await updateLoanData(formData);
    setLoading(false);
    if (res?.ok) goToNextStep(allRequiredFieldFilled());
    else setErrors(res?.errors);
  };

  return (
    <View>
      <ConfirmPrompt
        isOpen={isConfirmPromptOpen}
        close={() => setIsConfirmPromptOpen(false)}
        text="Are you sure? You still have some mandatory fields remaining which you will need to fill"
        cb={() => saveData()}
      />
      <Text category="h6" style={styles.error}>
        {errors ? JSON.stringify(errors) : ""}
      </Text>

      <Text style={styles.inputLabel}>
        {" "}
        Company Organizational Chart <Text appearance="hint">(Optional)</Text>
      </Text>

      <Button
        style={
          formData.companyOrganizationalChart
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.companyOrganizationalChart ? "success" : "basic"}
        accessoryRight={UploadIcon}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                companyOrganizationalChart: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>
            Upload Company Organizational Chart
          </Text>
        )}
      />

      <Text style={styles.inputLabel}>
        {" "}
        Operating Agreement <Text appearance="hint">(Optional)</Text>{" "}
      </Text>

      <Button
        style={
          formData.operatingAgreement
            ? styles.uploadButtonSuccess
            : styles.uploadButton
        }
        status={formData.operatingAgreement ? "success" : "basic"}
        accessoryRight={UploadIcon}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                operatingAgreement: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>
            Upload Operating Agreement
          </Text>
        )}
      />

      <Text style={styles.inputLabel}>
        {" "}
        Corporate or Authorizing Resolution{" "}
      </Text>

      <Button
        style={
          formData.corporateOrAuthorizingResolution
            ? styles.uploadButtonSuccess
            : hasEmptyRequiredFields
            ? styles.uploadButtonEmpty
            : styles.uploadButton
        }
        status={
          formData.corporateOrAuthorizingResolution
            ? "success"
            : hasEmptyRequiredFields
            ? "danger"
            : "basic"
        }
        accessoryRight={UploadIcon}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                corporateOrAuthorizingResolution: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>
            Upload Corporate or Authorizing Resolution
          </Text>
        )}
      />

      <Text style={styles.inputLabel}>Bylaws</Text>

      <Button
        style={
          formData.byLaws
            ? styles.uploadButtonSuccess
            : hasEmptyRequiredFields
            ? styles.uploadButtonEmpty
            : styles.uploadButton
        }
        status={
          formData.byLaws
            ? "success"
            : hasEmptyRequiredFields
            ? "danger"
            : "basic"
        }
        accessoryRight={UploadIcon}
        appearance="outline"
        onPress={() =>
          uploadDocument((doc: any) => {
            if (doc.size < 10485760) {
              setFormData({
                ...formData,
                byLaws: doc.file,
              });
              setErrors("");
            } else {
              setErrors("File too large. Upload limit: 10MB.");
            }
          })
        }
        children={() => (
          <Text style={styles.uploadButtonText}>Upload Bylaws</Text>
        )}
      />

      <View style={width > 768 && styles.flexRow}>
        <Button
          style={
            loading ? styles.disabledActionButton : styles.nextActionButton
          }
          onPress={async () => {
            clickSave();
          }}
          disabled={loading}
          accessoryRight={loading ? LoadingIndicator : ArrowIcon}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Save and Continue{loading ? "  " : ""}
            </Text>
          )}
        />
        <Button
          style={loading ? styles.disabledActionButton : styles.skipButton}
          status="basic"
          disabled={loading}
          onPress={() => {
            goToNextStep();
          }}
          children={() => (
            <Text
              style={
                loading
                  ? styles.disabledActionButtonText
                  : styles.nextActionButtonText
              }
            >
              Skip for now
            </Text>
          )}
        />
      </View>
    </View>
  );
};
