import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  optionsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 15,
  },
  optionsContainerMobile: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    alignItems: "flex-start",
    marginBottom: 15,
  },
  question: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  input: {
    marginBottom: 22,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
    paddingLeft: 10,
    fontSize: 22,
    textTransform: "capitalize",
  },
  inputTextMobile: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
    paddingLeft: 10,
    fontSize: 16,
    textTransform: "capitalize",
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    marginBottom: 10,
    padding: 5,
  },
  nextActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
  },
  disabledActionButton: {
    maxWidth: 200,
    marginTop: 20,
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "color-basic-600",
    fontSize: 14,
    textTransform: "capitalize",
  },
});
