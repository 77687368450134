import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/native";
import {
  Layout,
  Text,
  useStyleSheet,
  ThemeProvider,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";

import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import { useStore } from "../../stores";
import { BusinessDetails } from "../LoanView/BusinessDetails";
import { PersonalDetails } from "../LoanView/PersonalDetails";
import { LoadingIndicator } from "../ProfileEdit";
import { BankAccountInfo } from "./BankAccountInfo";
import { ProfileBasicInfo } from "./ProfileBasicInfo";
import { ProfileBusinessInfo } from "./ProfileBusinessInfo";
import { ProfileSecurity } from "./ProfileSecurity";
import { ProfileSettings } from "./ProfileSettings";
import { themedStyles } from "./themedStyles";

export type Props = object;

const ManageProfile: React.FC<any> = ({ route }) => {
  const { width } = Dimensions.get("window");
  const [loading, setLoading] = useState(true);
  const [business, setBusiness] = useState<any>({});
  const navigation = useNavigation();

  const styles = useStyleSheet(themedStyles);
  const [success, setSuccess] = useState(false);
  const [canEditPersonalAndBusinessInfo, setCanEditPersonalAndBusinessInfo] =
    useState(false);

  const { authStore, businessStore, loanStore } = useStore();
  const getTheme = () =>
    authStore?.user?.isStaff ||
    authStore.user?.isUnderwriter ||
    authStore.user?.isLoanAgent ||
    authStore.user?.isInvestor
      ? "light"
      : "dark";
  const theme = getTheme();

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  useEffect(() => {
    setLoading(true);
    businessStore.fetchBusinesses();
    let flag = false;
    businessStore.business.forEach((business) => {
      if (authStore.user?.businesses?.includes(business.id)) {
        setBusiness(business.$);
        flag = true;
      }
    });
    if (
      !flag &&
      !(
        authStore?.user?.isStaff ||
        authStore.user?.isUnderwriter ||
        authStore.user?.isLoanAgent ||
        authStore.user?.isReferralPartner ||
        authStore.user?.isInvestor
      )
    ) {
      navigation.navigate("dashboard");
    }
    setLoading(false);
  }, [businessStore.business]);

  useEffect(() => {
    let flag = false;
    if (!businessStore.business.size) {
      businessStore.fetchBusinesses();
    }
    if (!loanStore.loanApplications.size) {
      loanStore.fetchLoanApplications();
    } else {
      loanStore.loanApplications.forEach((loan) => {
        if (authStore.user?.businesses?.includes(loan.business)) {
          if (
            ![
              "",
              "prequal1InProgress",
              "prequal1",
              "registered",
              "done",
            ].includes(loan?.status || "") &&
            !loan.status?.toLowerCase().includes("rejected")
          )
            flag = true;
        }
      });
    }
    if (!flag || authStore.user?.isFinalPullEquifaxConsumer)
      setCanEditPersonalAndBusinessInfo(true);
  }, [route, loanStore.loanApplications, businessStore.business]);

  return (
    <ThemeProvider theme={getTheme() === "light" ? eva.light : eva.dark}>
      <Layout style={styles.container}>
        <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
          <Header theme={getTheme() === "light" ? "light" : "dark"} />
          <Text style={styles.header}>MANAGE PROFILE</Text>
          <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={
              theme === "dark"
                ? ["#D012B2", "#8A1CDB", "#5327FF", "#1D84FF", "#26DDF1"]
                : ["#5332FF", "#2CABF8"]
            }
            style={
              width > 768
                ? styles.manageProfileContainer
                : styles.manageProfileContainerMobile
            }
          >
            {loading ? (
              <View style={{ ...styles.container, alignItems: "center" }}>
                <LoadingIndicator />
              </View>
            ) : (
              <View
                style={{
                  ...(width > 768
                    ? styles.profileView
                    : styles.profileViewMobile),
                  backgroundColor: theme === "light" ? "white" : "#101426",
                }}
              >
                {success && (
                  <View style={styles.toastSuccess}>
                    <View>
                      <Text style={styles.toastDescription}>
                        Changes have been saved
                      </Text>
                    </View>
                  </View>
                )}
                <PersonalDetails
                  canEdit={canEditPersonalAndBusinessInfo}
                  user={authStore?.user}
                  theme={theme}
                  setSuccess={setSuccess}
                />

                {authStore.user?.isInvestor && (
                  <BankAccountInfo user={authStore.user} theme={theme} />
                )}

                <ProfileSecurity
                  user={authStore.user}
                  setSuccess={setSuccess}
                  theme={theme}
                />

                <ProfileSettings
                  user={authStore.user}
                  setSuccess={setSuccess}
                  theme={theme}
                />
              </View>
            )}
          </LinearGradient>
        </ScrollView>
        {isContactUsOpen && (
          <View style={styles.contactUsPopupContainer}>
            <ContactUsPopup />
          </View>
        )}
        <ContactUsAnchor />
      </Layout>
    </ThemeProvider>
  );
};

export default ManageProfile;
