import {
  model,
  Model,
  modelAction,
  ModelCreationData,
  prop,
} from "mobx-keystone";

@model("oppzo/MBOInfo")
export default class MBOInfo extends Model({
  id: prop<number>(),
  user: prop<number | null>(),
  loanApplication: prop<number | null>(),
  firstName: prop<string | null>(),
  lastName: prop<string | null>(),
  birthday: prop<string | null>(),
  email: prop<string | null>(),
  phoneNumber: prop<string | null>(""),
  infoStep: prop<number | null>(),
  unsigned8821Form: prop<string | null>(),
  signed8821Form: prop<string | null>(),
  resumes: prop<File | null>(),
  linkedIn: prop<string | null>(),
  driversLicense: prop<File | null>(),
  personalFinancialStatements: prop<File | null>(),
  legalActions: prop<string | null>(),
  status: prop<string | null>(),
}) {
  @modelAction
  update(data: ModelCreationData<MBOInfo>) {
    Object.assign(this, data);
  }
}
