import * as eva from "@eva-design/eva";
import { useNavigation } from "@react-navigation/core";
import {
  Text,
  useStyleSheet,
  Modal,
  Divider,
  Icon,
  ThemeProvider,
  Spinner,
  Button,
  Input,
  Select,
  SelectItem,
  IndexPath,
  StyleService,
  List,
} from "@ui-kitten/components";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState, useEffect, useRef } from "react";
import { TouchableOpacity, View, ScrollView, Dimensions } from "react-native";
import { supportSendEmails } from "../../services/api";
import { useStore } from "../../stores";
import { purposeOptions } from "../../utils/constants";

import { themedStyles } from "./themedStyles";

export const ContactUsPopup: React.FC<any> = ({ props }) => {
  const styles = useStyleSheet(themedStyles);
  const EmailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const SendIcon = (props: any) => (
    <Icon {...props} name="paper-plane-outline" />
  );

  let { width } = Dimensions.get("window");
  const [loading, setLoading] = useState(false);

  const LoadingIndicator = (_props: any) => (
    <Spinner status="basic" size="medium" />
  );

  const emailRef = useRef<Input>(null);
  const textRef = useRef<Input>(null);

  const { authStore } = useStore();

  const defaultFields = {
    email: "",
    purpose: "",
    text: "",
  };

  const defaultErrors = { ...defaultFields, nonFieldErrors: "" };

  const [formData, setFormData] = useState({ ...defaultFields });
  const [errorMessages, setErrorMessages] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [selectedPurposeIndex, setSelectedPurposeIndex] = useState<any>(null);

  useEffect(() => {
    if (selectedPurposeIndex)
      setFormData({
        ...formData,
        purpose: purposeOptions[selectedPurposeIndex?.row] || "",
      });
  }, [selectedPurposeIndex]);

  const submitTicket = async () => {
    setErrorMessages("");
    setSubmitLoading(true);
    const response = await supportSendEmails(
      authStore.accessToken || "",
      formData
    );
    if (response.response.entities) {
      if (response.response.entities["success"]) {
        setSuccess(true);
      } else {
        setErrorMessages(response.response.entities["error"]);
      }
    }
    setSubmitLoading(false);
  };

  const modalRenderItem: React.FC<any> = ({ item, index }) => (
    <TouchableOpacity
      style={styles.modalDocTypeListItem}
      onPress={() => {
        setFormData({ ...formData, purpose: item });
        setIsSelectOpen(false);
      }}
    >
      <Text style={styles.modalHeaderText}>{item}</Text>
      <Text style={styles.modalSubheaderText}>
        {item === "Get Support"
          ? "I have a question on my loan application"
          : item === "Help"
          ? "I have a technical problem."
          : ""}
      </Text>
    </TouchableOpacity>
  );

  return (
    <>
      {success ? (
        <View style={styles.contactUsContainer}>
          <Text style={styles.header}>
            Your ticket has been sent. You may now close this popup.
          </Text>
        </View>
      ) : (
        <View>
          {isSelectOpen && (
            <View style={styles.modalCategoryTypeModal}>
              <List
                style={styles.modalDocTypeList}
                data={purposeOptions}
                renderItem={modalRenderItem}
              />
            </View>
          )}
          <View
            style={
              width > 768
                ? {
                    ...styles.gradientContainer,
                  }
                : {
                    ...styles.gradientContainerMobile,
                  }
            }
          >
            <Text style={styles.header}>{"Need help?"}</Text>
            <Text style={styles.subHeader}>
              {"Don't worry... we're here for you."}
            </Text>
          </View>
          <View style={styles.contactUsContainer}>
            <Text>
              {errorMessages && (
                <Text
                  category="h6"
                  style={width > 576 ? styles.error : styles.errorMobile}
                >
                  {errorMessages}
                </Text>
              )}
            </Text>
            <Input
              ref={emailRef}
              style={width > 576 ? styles.input : styles.inputMobile}
              textStyle={
                width > 576 ? styles.inputText : styles.inputTextMobile
              }
              placeholder="Email address"
              autoCorrect={false}
              textContentType="emailAddress"
              autoCompleteType="email"
              value={formData.email}
              accessoryRight={EmailIcon}
              onChangeText={(text) => setFormData({ ...formData, email: text })}
              onKeyPress={(event: any) => {
                if (event.keyCode === 9) {
                  event.preventDefault();
                  textRef.current?.focus();
                }
              }}
              onSubmitEditing={() => textRef.current?.focus()}
              returnKeyType="next"
              testID="WorkEmailField"
            />
            <View>
              {/* <Select
                status="primary"
                size={width > 576 ? "medium" : "small"}
                style={width > 576 ? styles.input : styles.inputMobile}
                placeholder="Purpose"
                value={formData.purpose}
                selectedIndex={selectedPurposeIndex}
                onSelect={(index) => setSelectedPurposeIndex(index)}
              >
                {purposeOptions.map((option: any, idx: number) => (
                  <SelectItem title={option} key={`${idx}-purpose-option`} />
                ))}
              </Select> */}
              <TouchableOpacity
                style={styles.modalIconRow}
                onPress={() => {
                  setIsSelectOpen(!isSelectOpen);
                }}
              >
                <Text
                  style={{
                    ...styles.modalText,
                    color: formData.purpose !== "" ? "black" : "#8F9BB3",
                  }}
                >
                  {" "}
                  {formData.purpose || "Select Purpose"}{" "}
                </Text>
                <Icon
                  style={styles.iconLarge}
                  name="arrow-ios-downward-outline"
                  fill="black"
                />
              </TouchableOpacity>
            </View>
            <View style={styles.gradientDivider} />
            <Input
              ref={textRef}
              style={width > 576 ? styles.input : styles.inputMobile}
              textStyle={
                width > 576 ? styles.inputText : styles.inputTextMobile
              }
              placeholder="What's your question, comment or issue?"
              autoCorrect={false}
              value={formData.text}
              multiline={true}
              onChangeText={(text) => setFormData({ ...formData, text: text })}
            />
            <LinearGradient
              colors={["#D012B2", "#8A1CDB", "#5327FF", "#1D84FF", "#26DDF1"]}
              style={{ height: 40, marginHorizontal: 10 }}
              start={{ x: 0.0, y: 0.5 }}
              end={{ x: 1.0, y: 0.5 }}
            >
              <TouchableOpacity
                style={styles.sendButtonContainer}
                onPress={() => submitTicket()}
              >
                <SendIcon
                  style={{ width: 24, height: 24 }}
                  stroke="white"
                  fill="white"
                />
                <Text style={styles.subHeader}>Send Message</Text>
                {submitLoading && (
                  <LoadingIndicator style={{ height: 24, width: 24 }} />
                )}
              </TouchableOpacity>
            </LinearGradient>
          </View>
        </View>
      )}
    </>
  );
};
