import { useLinkTo, useNavigation } from "@react-navigation/native";
import {
  Layout,
  Text,
  useStyleSheet,
  Modal,
  Button,
  Icon,
  Spinner,
} from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";

import { ContactUsPopup } from "../../components/ContactUs";
import Header from "../../components/Header";
import MultiSelectCheckBoxes from "../../components/MultiSelectCheckBoxes";
import { sendMboEmails } from "../../services/api";
import {
  checkIfPrivacyIsRead,
  checkIfTosIsRead,
} from "../../services/api/policies";
import { useStore } from "../../stores";
import { PreQualificationForm } from "./PreQualificationForm";
import { themedStyles } from "./themedStyles";

const RejectionModal: React.FC<any> = ({ isOpen }) => {
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);
  const ArrowIcon = (props: any) => (
    <Icon {...props} fill="white" name="arrow-forward-outline" />
  );
  return (
    <Modal visible={isOpen} style={styles.rejectionModal}>
      <Text style={styles.rejectionModalText}>
        OppZo is currently only accepting applications from prime federal
        government contractors.
      </Text>
      <Button
        style={styles.nextActionButton}
        accessoryRight={ArrowIcon}
        onPress={() => {
          navigation.navigate("dashboard");
        }}
      >
        I understand
      </Button>
    </Modal>
  );
};

const PreQualification: React.FC<any> = ({ route }) => {
  const { width } = Dimensions.get("window");
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const { authStore, loanStore, businessStore } = useStore();
  const styles = useStyleSheet(themedStyles);
  const loanId =
    route?.params?.id ||
    new URLSearchParams(window.location.search).get("id") ||
    "";
  const questions = [
    {
      question: "What is your government contractor level?",
      options: ["federal", "state", "local"],
    },
  ];

  const ContactUsImg = require("../../../assets/ContactUs.png");
  const ContactUsClose = require("../../../assets/ContactUsClose.png");

  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [currentQuestionID, setCurrentQuestionID] = useState(0);
  const [qualified, setQualified] = useState(false);
  const [isRejectedModalOpen, setIsRejectedModalOpen] = useState(false);
  const [governmentContractorLevel, setGovernmentContractorLevel] =
    useState("");
  const [contractorLevel, setContractorLevel] = useState<string[]>([]);
  const [initialContractorLevel, setInitialContractorLevel] = useState<
    string[]
  >([]);
  const [loan, setLoan] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  if (!authStore || !authStore.accessToken) return <Text>Register</Text>;

  const [openModal, setOpenModal] = useState(false);
  const [currentBusiness, setCurrentBusiness] = useState(-1);
  const LoadingIndicator = () => <Spinner status="basic" />;

  const ContactUsAnchor = () => (
    <TouchableOpacity
      style={styles.floatingButtonContainer}
      onPress={() => setIsContactUsOpen(!isContactUsOpen)}
    >
      <Image
        source={isContactUsOpen ? ContactUsClose : ContactUsImg}
        style={{ width: 60, height: 60 }}
      />
    </TouchableOpacity>
  );

  useEffect(() => {
    setLoading(true);
    loanStore.fetchLoanApplications();
    businessStore.fetchBusinesses();
    if (loanId) {
      if (!loanStore.loanApplications.size) {
        loanStore.fetchLoanApplications();
      } else {
        let flag = false;
        loanStore.loanApplications.forEach((loan) => {
          if (loan.id === Number(loanId)) {
            flag = true;
            if (loan.applicantId === authStore.user?.id) {
              setLoan(loan.$);
              setQualified(true);
            } else {
              navigation.navigate("dashboard");
            }
          }
        });
        if (!flag) {
          navigation.navigate("dashboard");
        }
      }
    } else {
      loanStore.loanApplications.forEach((loan) => {
        if (authStore.user?.id === loan.applicantId && loan.isTemporary) {
          setLoan(loan.$);
          setQualified(true);
        }
      });
    }
    businessStore.business.forEach((business) => {
      console.log(business);
      if (business.businessOwner === authStore.user?.id) {
        setCurrentBusiness(business.id);
        setInitialContractorLevel([...business.contractorLevel]);
      }
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      const res1 = await checkIfPrivacyIsRead(authStore?.accessToken || "");
      if (!res1.response.entities) {
        navigation.navigate("dashboard");
      }

      const res2 = await checkIfTosIsRead(authStore?.accessToken || "");
      if (!res2.response.entities) {
        navigation.navigate("dashboard");
      }
    })();
  }, []);

  useEffect(() => {
    if (loading) setQualified(false);
  }, [loading]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        {loading ? (
          <LoadingIndicator />
        ) : (
          <View style={styles.preQualificationContainer}>
            <View
              style={{
                ...styles.gradientContainer,
                ...(!qualified ? styles.gradientContainerYesOrNo : {}),
              }}
            />
            <View
              style={
                width > 576
                  ? styles.preQualification
                  : qualified
                  ? styles.preQualificationFormMobile
                  : styles.preQualificationMobile
              }
            >
              {!qualified &&
                currentQuestionID >= questions.length &&
                linkTo("/not-qualified")}
              {!qualified && currentQuestionID < questions.length && (
                <>
                  <Text
                    style={width > 576 ? styles.header : styles.headerMobile}
                  >
                    Let's start your application.
                  </Text>
                  <Text
                    style={
                      width > 576 ? styles.subHeader : styles.subHeaderMobile
                    }
                  >
                    Please answer truthfully to proceed to the next steps.
                  </Text>
                  <MultiSelectCheckBoxes
                    question={questions[currentQuestionID].question}
                    options={questions[currentQuestionID].options}
                    initial={initialContractorLevel}
                    answer={contractorLevel}
                    setAnswer={(ans: any) => {
                      setContractorLevel(ans);
                    }}
                    displayNextActionButton
                    disabledNextActionButton={!contractorLevel.length}
                    onContinue={(answer: string[]) => {
                      console.log(answer);
                      if (!answer.includes("federal")) {
                        setIsRejectedModalOpen(true);
                      } else {
                        answer.length === 1 && answer[0] === "federal"
                          ? setQualified(true)
                          : setOpenModal(true);
                      }
                    }}
                  />
                  <Modal
                    visible={openModal}
                    backdropStyle={styles.modalBackdrop}
                    onBackdropPress={() => setOpenModal(false)}
                  >
                    <View
                      style={
                        width > 576
                          ? styles.modalContainer
                          : styles.modalContainerMobile
                      }
                    >
                      <Text style={styles.modalText}>
                        We are only funding federal government contractors at
                        this time, if you want to fund your federal government
                        contract, click continue.
                      </Text>
                      <View style={styles.modalRow}>
                        <TouchableOpacity
                          style={styles.modalYesButton}
                          onPress={() => {
                            setQualified(true);
                            setOpenModal(false);
                          }}
                        >
                          <Text style={styles.modalButtonText}>Continue</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={styles.modalNoButton}
                          onPress={() => {
                            navigation.navigate("dashboard");
                            setOpenModal(false);
                          }}
                        >
                          <Text style={styles.modalButtonText}>Cancel</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </Modal>
                </>
              )}
              {qualified && (
                <PreQualificationForm
                  loan={loan}
                  business={currentBusiness}
                  preloadCurrentStep={loan?.prequal1Step || 1}
                  setIsRejectedModalOpen={setIsRejectedModalOpen}
                  governmentContractorLevel={governmentContractorLevel}
                  contractorLevel={contractorLevel}
                  onContinue={async (status: string, loanId: number) => {
                    await loanStore.updateLoanApplication(loanId, {
                      isTemporary: false,
                    });
                    if (
                      status === "prequal1" ||
                      status === "prequal1InProgress" ||
                      status === "prequal2InProgress"
                    ) {
                      sendMboEmails();
                      navigation.navigate("loan-view", {
                        id: loanId,
                      });
                    } else {
                      linkTo("/not-qualified");
                    }
                  }}
                />
              )}
            </View>
          </View>
        )}
      </ScrollView>
      <RejectionModal isOpen={isRejectedModalOpen} />
      {isContactUsOpen && (
        <View style={styles.contactUsPopupContainer}>
          <ContactUsPopup />
        </View>
      )}
      <ContactUsAnchor />
    </Layout>
  );
};

export default PreQualification;
