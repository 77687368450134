import { StyleService } from "@ui-kitten/components";

export const themedStyles = StyleService.create({
  flexRow: { flexDirection: "row" },
  flexColumn: { flexDirection: "column" },
  flexAlignCenter: { alignItems: "center" },
  container: {
    flex: 1,
    backgroundColor: "#101426",
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 48,
    fontSize: 48,
    marginBottom: 15,
  },
  subHeader: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 30,
  },
  headerMobile: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 24,
    marginBottom: 0,
  },
  subHeaderMobile: {
    fontFamily: "Montserrat_500Medium",
    color: "white",
    lineHeight: 16,
    fontSize: 13,
    marginBottom: 70,
  },
  question: {
    fontFamily: "Montserrat_600SemiBold",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 40,
  },
  questionButtonSelected: {
    flex: 1,
    maxWidth: "49%",
    background: "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)",
  },
  questionButton: {
    flex: 1,
    maxWidth: "49%",
    borderColor: "#55AAFF",
  },
  buttonTextSelected: {
    fontFamily: "Montserrat_700Bold",
    color: "#101426",
    textTransform: "uppercase",
  },
  buttonText: {
    fontFamily: "Montserrat_700Bold",
    color: "#55AAFF",
    textTransform: "uppercase",
  },
  questionButtonsContainer: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  register: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 50,
    marginVertical: 50,
    maxWidth: 800,
  },
  registerMobile: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: 20,
    marginVertical: 50,
    width: "100%",
  },
  registerContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  registerContainerMobile: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  nextActionButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 70,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonMobile: {
    flex: 1,
    maxWidth: 200,
    marginTop: 30,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  nextActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "white",
    fontSize: 14,
  },
  disabledActionButton: {
    flex: 1,
    maxWidth: 200,
    marginTop: 70,
    marginHorizontal: 10,
    border: "none",
  },
  disabledActionButtonMobile: {
    flex: 1,
    maxWidth: 200,
    marginTop: 30,
    marginHorizontal: 10,
    border: "none",
  },
  plusButton: {
    flex: 1,
    maxWidth: 200,
    marginHorizontal: 10,
    marginBottom: 40,
    border: "none",
  },
  closeButton: {
    flex: 1,
    maxWidth: 200,
    borderRadius: 100,
    marginHorizontal: 10,
    marginBottom: 40,
    border: "none",
  },
  disabledActionButtonText: {
    fontFamily: "Montserrat_700Bold",
    textTransform: "capitalize",
    color: "color-basic-600",
    fontSize: 14,
  },
  hoverHelpText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#222B45",
    fontSize: 12,
    position: "absolute",
    bottom: -45,
    right: 5,
    zIndex: 999,
    lineHeight: 24,
    maxWidth: 200,
    padding: 10,
    borderRadius: 4,
  },
  icon: {
    width: 15,
    height: 15,
    fill: "white",
    position: "relative",
    top: 2.5,
  },
  input: {
    marginBottom: 36,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputMobile: {
    marginBottom: 16,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
  },
  inputMask: {
    marginBottom: 36,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "99%",
    padding: 15,
  },
  inputMaskMobile: {
    marginBottom: 16,
    marginHorizontal: 5,
    flex: 1,
    backgroundColor: "#222B45",
    borderColor: "#2E3A59",
    borderRadius: 4,
    border: "solid",
    borderWidth: 1,
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    fontSize: 12,
    color: "white",
    width: "99%",
    padding: 15,
  },
  inputLabel: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    lineHeight: 24,
    fontSize: 15,
    marginBottom: 15,
  },
  inputText: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    color: "white",
    width: "100%",
  },
  inputTextMobile: {
    fontFamily: "Montserrat_400Regular",
    fontWeight: "bold",
    fontSize: 12,
    color: "white",
    width: "100%",
  },
  formContainerFlexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nonFieldErrorsContainer: {
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 22,
  },
  nonFieldErrors: {
    color: "color-danger-default",
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 12,
  },
  errorMobile: {
    alignSelf: "center",
    textAlign: "center",
    color: "color-danger-default",
    fontSize: 10,
  },
  terms: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 13,
    marginTop: 71,
    textAlign: "justify",
  },
  termsMobile: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 18,
    fontSize: 12,
    marginTop: 30,
    textAlign: "left",
  },
  termsMBO: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 13,
    marginTop: 30,
    textAlign: "justify",
  },
  termsMBOMobile: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 18,
    fontSize: 12,
    marginTop: 15,
    textAlign: "left",
  },
  helptext: {
    color: "white",
    fontFamily: "Montserrat_400Regular",
    lineHeight: 24,
    fontSize: 12,
    textAlign: "justify",
    marginLeft: 5,
    marginRight: 5,
  },
  link: {
    fontFamily: "Montserrat_400Regular",
    color: "#26D4F3",
    lineHeight: 24,
    fontSize: 13,
  },
  arrow: {
    fontSize: 15,
    marginBottom: 36,
    marginHorizontal: 5,
    flex: 0.25,
  },
  filterCheckbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    // marginBottom: 36,
    padding: 5,
  },
  modal: {
    flex: 1,
    maxWidth: 800,
  },
  backdrop: {
    backgroundColor: "#0007",
  },
  closeModalButton: {
    backgroundColor: "#222B45",
    border: "none",
  },
  checkbox: {
    fontFamily: "Montserrat_700Bold",
    flex: 1,
    fontSize: 15,
    lineHeight: 24,
    margin: 5,
    marginBottom: 10,
    padding: 5,
  },
  datePickerPlaceholderText: {
    color: "color-basic-600",
    fontFamily: "Montserrat_400Regular",
    fontSize: 15,
    lineHeight: 20,
    marginHorizontal: 8,
  },
  rejectionModal: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "color-basic-1100",
  },
  rejectionModalText: {
    fontFamily: "Montserrat_400Regular",
    fontSize: 22,
    lineHeight: 32,
    color: "white",
    maxWidth: 550,
    alignText: "center",
    textAlign: "center",
  },
  nextActionModalButton: {
    maxWidth: 200,
    marginVertical: 30,
    marginHorizontal: 10,
    border: "none",
    background:
      "linear-gradient(97.45deg, #D012B2 5.79%, #8A1CDB 56.7%, #5327FF 106.03%)",
  },
  goBackModalButton: {
    maxWidth: 200,
    marginVertical: 30,
    marginHorizontal: 10,
    border: "none",
    backgroundColor: "#2e2837",
  },
  nextActionModalButtonText: {
    fontFamily: "Montserrat_700Bold",
    color: "white",
    fontSize: 14,
    textTransform: "capitalize",
  },
  modalContentContainer: {
    paddingHorizontal: 50,
  },
  modalContent: {
    paddingTop: 20,
    paddingBottom: 50,
  },
  floatingButtonContainer: {
    position: "absolute",
    width: 60,
    height: 60,
    alignItems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 30,
  },
  contactUsPopupContainer: {
    position: "absolute",
    maxWidth: 393,
    maxHeight: 458,
    alignitems: "center",
    justifyContent: "flex-end",
    right: 40,
    bottom: 100,
    borderRadius: 20,
  },
  tooltipTextLight: {
    fontFamily: "Montserrat_700Bold",
    fontSize: 14,
    wordBreak: "break",
    color: "white",
  },
});
