import { useFocusEffect, useIsFocused } from "@react-navigation/core";
import {
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  Divider,
} from "@ui-kitten/components";
import { TouchableWithoutFeedback } from "@ui-kitten/components/devsupport";
import React, { useState, useCallback } from "react";
import { View, ScrollView } from "react-native";

import { TableDisplay } from "../../components/TableDisplay";
import * as api from "../../services/api";
import { useStore } from "../../stores";
import { themedStyles } from "./themedStyles";

const LoadingIndicator = (_props: any) => (
  <Spinner status="primary" size="giant" />
);

const FundsTrackingStep: React.FC<any> = ({
  text,
  done = false,
  date = "",
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View
      style={{
        ...styles.flexRow,
        padding: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {done ? (
        <View style={styles.circleGradientFill}>
          <Icon name="checkmark" fill="#FFF" width={24} height={24} />
        </View>
      ) : (
        <View style={styles.circleGrayOutline}>
          <Icon name="checkmark" fill="#777" width={24} height={24} />
        </View>
      )}
      <View style={{ flexDirection: "column" }}>
        <Text
          style={{
            ...styles.fundsTrackingMainText,
            ...(!done ? { color: "#00000077" } : {}),
          }}
        >
          {text}
        </Text>
        {!!date && (
          <Text
            style={styles.fundsTrackingAlternateText}
            appearance="alternate"
          >
            {date}
          </Text>
        )}
      </View>
    </View>
  );
};

const FundsTrackingTab: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);

  const applicationId =
    route?.params?.applicationId ||
    new URLSearchParams(window.location.search).get("applicationId");

  const [loading, setLoading] = useState(false);

  const [totalProcessingTime, setTotalProcessingTime] = useState("?");
  const [fundsSentDate, setFundsSentDate] = useState("?");
  const [fundsReceivedDate, setFundsReceivedDate] = useState("?");

  const [fundingAccountDetails, setFundingAccountDetails] = useState([
    {
      header: "Funding Account",
      displayValue: "?",
      fieldNameInResponse: "fundingAccount",
    },
    {
      header: "Reference Number",
      displayValue: "?",
      fieldNameInResponse: "referenceNumber",
    },
    {
      header: "Funds Amount",
      prefix: "$",
      displayValue: "?",
      fieldNameInResponse: "fundsAmount",
    },
  ]);

  const [borrowerDetails, setBorrowerDetails] = useState([
    {
      header: "Borrower Account Number",
      displayValue: "?",
      fieldNameInResponse: "accountNumber",
    },
    {
      header: "Routing Number",
      displayValue: "?",
      fieldNameInResponse: "routingNumber",
    },
    {
      header: "ApplicationID",
      displayValue: applicationId,
      fieldNameInResponse: "applicationId",
    },
  ]);

  const { authStore } = useStore();

  useFocusEffect(
    useCallback(() => {
      if (authStore?.accessToken && applicationId) {
        setLoading(true);
        api
          .getFundsTracking(authStore?.accessToken, applicationId)
          .then((resp) => {
            console.log(resp);
            const response = resp.response.entities;
            setBorrowerDetails(
              borrowerDetails.map((obj: any) => {
                if (obj.fieldNameInResponse in response.borrowerDetails) {
                  return {
                    ...obj,
                    displayValue:
                      response.borrowerDetails[obj.fieldNameInResponse],
                  };
                } else return obj;
              })
            );

            setFundingAccountDetails(
              fundingAccountDetails.map((obj: any) => {
                if (obj.fieldNameInResponse in response.fundingAccountDetails) {
                  return {
                    ...obj,
                    displayValue:
                      response.fundingAccountDetails[obj.fieldNameInResponse],
                  };
                } else return obj;
              })
            );

            setFundsSentDate(response.fundsSentDate);
            setFundsReceivedDate(response.fundsReceivedDate);
            if (response.fundsSentDate) {
              const start = new Date(response.fundsSentDate);
              const end = response.fundsReceivedDate
                ? new Date(response.fundsReceivedDate)
                : new Date();
              const days = Math.floor(
                (end.getTime() - start.getTime()) / 1000 / 24 / 60 / 60
              );
              setTotalProcessingTime(`${days} days`);
            }

            setLoading(false);
          });
      }
    }, [useIsFocused()])
  );

  return (
    <>
      <View style={styles.adminDashboardContainer}>
        <View style={styles.requestedDocumentsContainer}>
          <ScrollView style={styles.requestedDocumentContent}>
            <Divider />
            {loading ? (
              <View style={styles.center}>
                <LoadingIndicator />
              </View>
            ) : (
              <>
                <View style={styles.card}>
                  <TouchableWithoutFeedback style={styles.flexRow}>
                    <Text style={styles.cardHeader}>
                      Release of Funds status
                    </Text>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <View
                    style={{
                      ...styles.flexRowBetween,
                      alignItems: "center",
                      paddingVertical: 20,
                      marginBottom: 40,
                    }}
                  >
                    <View style={{ flex: 2 }}>
                      <FundsTrackingStep
                        text="Funds Sent"
                        date={
                          fundsSentDate
                            ? fundsSentDate
                            : fundsReceivedDate
                            ? "N/A"
                            : "waiting"
                        }
                        done={!!fundsSentDate || !!fundsReceivedDate}
                      />
                    </View>
                    <View
                      style={{
                        //@ts-ignore
                        background: fundsSentDate
                          ? "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)"
                          : "gray",
                        height: 2,
                        flex: 3,
                      }}
                    />
                    <View style={{ flex: 2 }}>
                      <FundsTrackingStep
                        text="Funds in Transit"
                        done={!!fundsSentDate || !!fundsReceivedDate}
                      />
                    </View>
                    <View
                      style={{
                        //@ts-ignore
                        background: fundsReceivedDate
                          ? "linear-gradient(97.13deg, #1D84FF 8.56%, #26DDF1 98.84%)"
                          : "gray",
                        height: 2,
                        flex: 3,
                      }}
                    />
                    <View style={{ flex: 2 }}>
                      <FundsTrackingStep
                        text="Funds Received"
                        date={fundsReceivedDate ? fundsReceivedDate : "waiting"}
                        done={!!fundsReceivedDate}
                      />
                    </View>
                  </View>
                  {!!fundsSentDate && (
                    <TableDisplay
                      data={[
                        {},
                        {},
                        {
                          header: "Total Processing Time",
                          displayValue: totalProcessingTime,
                        },
                      ]}
                      itemsPerRow={5}
                      itemStyle={{ textAlign: "center" }}
                    />
                  )}
                </View>
                <View style={styles.card}>
                  <TouchableWithoutFeedback style={styles.flexRow}>
                    <Text style={styles.cardHeader}>
                      Funding account details
                    </Text>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <TableDisplay data={fundingAccountDetails} itemsPerRow={5} />
                </View>
                <View style={styles.card}>
                  <TouchableWithoutFeedback style={styles.flexRow}>
                    <Text style={styles.cardHeader}>Borrower details</Text>
                  </TouchableWithoutFeedback>
                  <Divider style={styles.cardDivider} />
                  <TableDisplay data={borrowerDetails} itemsPerRow={5} />
                </View>
              </>
            )}
          </ScrollView>
        </View>
      </View>
    </>
  );
};

export default FundsTrackingTab;
