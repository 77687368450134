import { Layout, Text, useStyleSheet } from "@ui-kitten/components";
import React, { useEffect, useState } from "react";
import { View, ScrollView, Dimensions } from "react-native";

import Header from "../../components/Header";
import { themedStyles } from "./themedStyles";
import * as api from "../../services/api";
import { useStore } from "../../stores";

const QuickbooksIncomeStatement: React.FC<any> = ({ route }) => {
  const styles = useStyleSheet(themedStyles);
  const tableWidth = Dimensions.get("window").width - 300;

  const id =
    route?.params?.id || new URLSearchParams(window.location.search).get("id");
  const { authStore } = useStore();
  const defaultItems: any = [];
  const [items, setItems] = useState(defaultItems);

  const addSpaceInName = (name: string) => {
    // Adding space between strings
    const item = name.replace(/([A-Z])/g, " $1");
    // Converting first character to uppercase and join it to the final string
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  const emptyCol = (key: string) => (
    <Text
      key={key}
      style={{
        ...styles.tableCell,
        width: (tableWidth * 1.25) / 12,
      }}
    >
      {" "}
    </Text>
  );

  const getIncomeStatement = async () => {
    try {
      const results = await api.getIncomeStatement(
        authStore?.accessToken || "",
        Number(id)
      );
      if ("entities" in results.response) {
        let data = [];
        let rowData = {};
        const { entities } = results.response;
        console.log(`data:-`);
        console.log(entities);
        for (const key in entities) {
          if (key.toLowerCase() !== "assets") {
            for (const item in entities[key]) {
              rowData = {
                ...entities[key][item],
                rowName: addSpaceInName(item),
              };
              data.push(rowData);
            }
          } else {
            rowData = { ...entities[key], rowName: addSpaceInName(key) };
            data.push(rowData);
          }
        }
        setItems(data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getIncomeStatement();
  }, []);

  useEffect(() => {
    console.log(`-items-`);
    console.log(items);
  }, [items]);

  return (
    <Layout style={styles.container}>
      <ScrollView style={styles.scroll} keyboardShouldPersistTaps="handled">
        <Header />
        <View style={styles.adminDashboardContainer}>
          <View style={styles.adminDashBoard}>
            <Text style={styles.header}>Income Statement</Text>
            <View style={styles.tableContainer}>
              <View>
                {!!items &&
                  items.map((row: any) => (
                    <View key={`item-${row.rowName}`} style={{ marginTop: 20 }}>
                      <View
                        key={`row-${row.rowName}`}
                        style={styles.tableHeaders}
                      >
                        <View
                          key={`header-${row.rowName}`}
                          style={styles.tableHeadersCell}
                        >
                          <Text
                            key={`text-${row.rowName}`}
                            style={styles.tableHeadersCell}
                          >
                            {row.rowName}
                          </Text>
                        </View>
                      </View>
                      {!!row &&
                        Object.keys(row).map(
                          (col, i) =>
                            col !== "rowName" && (
                              <View key={`content-${col}-${row.rowName}-${i}`}>
                                <View
                                  key={`content-${col}-${row.rowName}`}
                                  style={styles.tableRow}
                                >
                                  <Text
                                    key={`content-${col}-${row.rowName}`}
                                    style={{
                                      ...styles.tableCell,
                                      width: (tableWidth * 1.25) / 3,
                                      textAlign: "left",
                                    }}
                                  >
                                    {addSpaceInName(col)}
                                  </Text>
                                </View>
                                {!!row &&
                                  Object.keys(row[col]).map((item, i) => (
                                    <View
                                      key={`content-${item}-${row.rowName}-${i}`}
                                      style={styles.tableRow}
                                    >
                                      <Text
                                        key={`content-${item}-${row.rowName}-${i}`}
                                        style={{
                                          ...styles.tableCell,
                                          width: (tableWidth * 1.25) / 3,
                                          textAlign: "left",
                                        }}
                                      >
                                        {"     " + addSpaceInName(item)}
                                      </Text>
                                      <Text
                                        key={`content-${item}-${i}`}
                                        style={{
                                          ...styles.tableCell,
                                          width: (tableWidth * 1.25) / 3,
                                          textAlign: "left",
                                        }}
                                      >
                                        {row[col][item]}
                                      </Text>
                                      {emptyCol(`empty-${item}-${i}`)}
                                    </View>
                                  ))}
                              </View>
                            )
                        )}
                    </View>
                  ))}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

export default QuickbooksIncomeStatement;
