import {
  Text,
  useStyleSheet,
  Icon,
  Input,
  Button,
  Divider,
} from "@ui-kitten/components";
import React, { useRef, useState, useCallback } from "react";
import { Dimensions, View } from "react-native";

import { useStore } from "../../stores";
import { LoadingIndicator } from "./index";
import { themedStyles } from "./themedStyles";

export const BasicInfoEditForm: React.FC<any> = () => {
  const { width } = Dimensions.get("window");
  const styles = useStyleSheet(themedStyles);

  const { authStore } = useStore();
  const user = authStore.user;

  const BriefcaseIcon = (props: any) => (
    <Icon {...props} name="briefcase-outline" />
  );
  const MailIcon = (props: any) => <Icon {...props} name="email-outline" />;
  const PhoneIcon = (props: any) => <Icon {...props} name="phone-outline" />;
  const LocationIcon = (props: any) => <Icon {...props} name="pin-outline" />;

  const lastNameRef = useRef<Input>(null);
  const businessNameRef = useRef<Input>(null);
  const businessAddressRef = useRef<Input>(null);
  const workEmailRef = useRef<Input>(null);
  const phoneNumberRef = useRef<Input>(null);

  const defaultFields = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    businessName: user?.businessName,
    businessAddress: user?.businessAddress,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFields });
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmitForm = useCallback(
    async (data) => {
      if (loading) {
        return;
      }
      setLoading(true);
      setErrorMessage("");
      console.log(data);
      console.log(authStore.user);
      const result = await authStore.updateBasicAndBusinessInformation(data);
      setLoading(false);
      if (!result.ok) {
        console.log(result.errors);
        setErrorMessage(result.errors["message"]);
      }
    },
    [loading]
  );

  const SaveIcon = (props: any) => <Icon {...props} name="save-outline" />;

  return (
    <View style={styles.sectionForm}>
      <Text>
        {errorMessage && (
          <Text category="h6" style={styles.error}>
            {errorMessage}
          </Text>
        )}
      </Text>

      <Text style={styles.inputLabel}>Basic Information</Text>
      <Divider />
      <View>
        <View style={styles.formContainerFlexRow}>
          <Input
            style={styles.input}
            textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
            placeholder="First Name"
            autoCapitalize="words"
            autoCorrect={false}
            value={formData.firstName}
            onChangeText={(text) =>
              setFormData({ ...formData, firstName: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                lastNameRef.current?.focus();
              }
            }}
            onSubmitEditing={() => lastNameRef.current?.focus()}
            returnKeyType="next"
            testID="FirstNameField"
          />
          <Input
            ref={lastNameRef}
            style={styles.input}
            textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
            placeholder="Last Name"
            autoCapitalize="words"
            autoCorrect={false}
            value={formData.lastName}
            onChangeText={(text) =>
              setFormData({ ...formData, lastName: text })
            }
            onKeyPress={(event: any) => {
              if (event.keyCode === 9) {
                event.preventDefault();
                businessNameRef.current?.focus();
              }
            }}
            onSubmitEditing={() => businessNameRef.current?.focus()}
            returnKeyType="next"
            testID="LastNameField"
          />
        </View>
        <Input
          ref={businessNameRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Business Name"
          autoCorrect={false}
          value={formData.businessName}
          accessoryRight={BriefcaseIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, businessName: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              businessAddressRef.current?.focus();
            }
          }}
          onSubmitEditing={() => businessAddressRef.current?.focus()}
          returnKeyType="next"
          testID="CompanyField"
        />
        <Input
          ref={businessAddressRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Business Address"
          autoCorrect={false}
          value={formData.businessAddress}
          accessoryRight={LocationIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, businessAddress: text })
          }
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              workEmailRef.current?.focus();
            }
          }}
          onSubmitEditing={() => workEmailRef.current?.focus()}
          returnKeyType="next"
          testID="BusinessAddressField"
        />
        <Input
          ref={workEmailRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Work email address"
          autoCorrect={false}
          value={formData.email}
          accessoryRight={MailIcon}
          onChangeText={(text) => setFormData({ ...formData, email: text })}
          onKeyPress={(event: any) => {
            if (event.keyCode === 9) {
              event.preventDefault();
              phoneNumberRef.current?.focus();
            }
          }}
          onSubmitEditing={() => phoneNumberRef.current?.focus()}
          returnKeyType="next"
          testID="WorkEmailField"
        />
        <Input
          ref={phoneNumberRef}
          style={styles.input}
          textStyle={width > 576 ? styles.inputText : styles.inputTextMobile}
          placeholder="Phone Number"
          autoCorrect={false}
          value={formData.phoneNumber}
          accessoryRight={PhoneIcon}
          onChangeText={(text) =>
            setFormData({ ...formData, phoneNumber: text })
          }
          returnKeyType="next"
          testID="phoneNumberField"
        />
      </View>
      <Button
        style={styles.nextActionButton}
        status="primary"
        appearance="outline"
        onPress={() => onSubmitForm(formData)}
        accessoryRight={loading ? LoadingIndicator : SaveIcon}
      >
        Save changes
      </Button>
    </View>
  );
};
